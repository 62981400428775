import React, { useEffect, useMemo, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

//redux
import { useSelector, useDispatch } from 'react-redux'
import { createSelector } from 'reselect'

import { Col, Row, UncontrolledTooltip, Card, CardBody } from 'reactstrap'
import Spinners from 'components/Common/Spinner'
import { toast } from 'react-toastify'
import TableContainer from 'components/Common/TableContainer'
import { ProductVN } from 'constants/vn'
import ConfirmModal from 'components/Common/ConfirmModal'
import {
  deleteProductAPI,
  importFileAPI,
  exportProductAPI,
  downloadTemplateAPI,
  checkUploadFileAPI,
  exportFileError,
  checkExportFileAPI
} from 'helpers/backend_helper'
import {
  ProductNo,
  ProductIndustrySector,
  ProductIndustry,
  ProductCategory,
  ProductModel,
  Serial,
  WarrantyCode,
  Channel,
  Zone,
  StatusProduct
} from './ManageProductCol'
import AddEditProduct from './components/AddEditProduct'
import {
  getProductDetail,
  getProductList,
  setFilter,
  setTypePopupProduct,
  getProductDetailSuccess
} from 'store/actions'
import ImportModal from 'components/Common/ImportModal'
import * as url from 'helpers/url_helper'
import moment from 'moment'
import { debounce } from 'lodash'
import { PRODUCT_MANAGEMENT_CODE, SCREEN_CODE } from 'constants/permission-code'
import PermissionWrapper from 'components/Common/PermissionWrapper'
import usePermission from 'hooks/usePermission'
import fileDownload from 'js-file-download'
import { getStorage, setStorage } from 'helpers/global_helper'

function ManageProduct() {
  //meta title
  document.title = 'Product List | HPG'
  const screenCode = useMemo(() => SCREEN_CODE.PRODUCT_MANAGEMENT, [])
  const hasPermission = usePermission({ screenCode, code: PRODUCT_MANAGEMENT_CODE.PRODUCT_DETAIL_PRODUCT })
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const selectProductsState = (state) => state.ProductReducer
  const ProductsProperties = createSelector(selectProductsState, (reducer) => ({
    products: reducer.products,
    loading: reducer.loading,
    total: reducer.total
  }))

  const selectProfileState = (state) => state.Profile
  const ProfileProperties = createSelector(selectProfileState, (profile) => ({
    profile: profile.profile
  }))

  const { profile } = useSelector(ProfileProperties)

  const { products, loading, total } = useSelector(ProductsProperties)

  const selectLayoutState = (state) => state.Layout
  const LayoutProperties = createSelector(selectLayoutState, (layout) => ({
    filter: layout.filter
  }))
  const { filter } = useSelector(LayoutProperties)

  const [isLoading, setLoading] = useState(loading)
  const [isLoadingImport, setLoadingImport] = useState(false)
  const [isLoadingImportUpdate, setLoadingImportUpdate] = useState(false)

  const [showDeleteAccount, setShowDeleteAccount] = useState(false)
  const [selected, setSelected] = useState()
  const [showForm, setShowForm] = useState(false)
  const [showImport, setShowImport] = useState(false)
  const [showImportUpdate, setShowImportUpdate] = useState(false)

  const [page, setPage] = useState(1)

  useEffect(() => {
    const filterMangeProduct = getStorage('filterManageProduct')
    dispatch(setFilter({ page: 1 }))
    dispatch(getProductList({ ...filterMangeProduct, page: 1 }))
  }, [dispatch])

  const onClickDelete = (item) => {
    setSelected(item)
    setShowDeleteAccount(true)
  }

  const onClickItem = async (item, type) => {
    setSelected(item)
    dispatch(setTypePopupProduct(type))
    dispatch(getProductDetail(item))
    setShowForm(true)
  }

  const columns = useMemo(
    () => [
      {
        Header: ProductVN.table.STT,
        accessor: 'syncStatus',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <ProductNo {...cellProps} page={page} />
        }
      },
      {
        Header: ProductVN.table.industry,
        accessor: 'industry',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <ProductIndustry {...cellProps} />
        }
      },
      {
        Header: ProductVN.table.industrySector,
        accessor: 'industrySector',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <ProductIndustrySector {...cellProps} />
        }
      },
      {
        Header: ProductVN.table.category,
        accessor: 'category',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <ProductCategory {...cellProps} />
        }
      },
      {
        Header: ProductVN.table.type,
        accessor: 'type',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return !cellProps.value ? '--' : cellProps.value.toUpperCase() == 'HOT' ? 'Cục nóng' : 'Cục lạnh'
        }
      },
      {
        Header: ProductVN.table.model,
        accessor: 'model',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <ProductModel {...cellProps} />
        }
      },
      {
        Header: ProductVN.table.serial,
        accessor: 'serial',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <Serial {...cellProps} />
        }
      },
      {
        Header: ProductVN.table.warrantyCode,
        accessor: 'warrantyCode',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <WarrantyCode {...cellProps} />
        }
      },
      {
        Header: ProductVN.table.channel,
        accessor: 'channel',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <Channel {...cellProps} />
        }
      },
      {
        Header: ProductVN.table.subZone,
        accessor: 'zone',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <Zone {...cellProps} />
        }
      },
      {
        Header: ProductVN.table.status,
        accessor: 'status',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <StatusProduct {...cellProps} />
        }
      },
      {
        Header: ProductVN.table.action,
        accessor: 'action',
        disableSortBy: true,
        hideSort: true,
        disableSortBy: true,
        hideSort: true,
        show: hasPermission,
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <ul className='list-unstyled hstack gap-1 mb-0'>
              <li data-bs-toggle='tooltip' data-bs-placement='top' title='View'>
                <Link
                  to={`#`}
                  className='btn btn-sm btn-soft-primary'
                  id={`viewtooltip-${cellProps.row.original.id}`}
                  onClick={() => {
                    onClickItem(cellProps.row.original, 'view')
                  }}
                >
                  <i className='mdi mdi-eye-outline' />
                  <UncontrolledTooltip
                    trigger='hover'
                    placement='top'
                    target={`viewtooltip-${cellProps.row.original.id}`}
                  >
                    Xem
                  </UncontrolledTooltip>
                </Link>
              </li>
              <PermissionWrapper screenCode={screenCode} code={PRODUCT_MANAGEMENT_CODE.PRODUCT_UPDATE_PRODUCT}>
                <li data-bs-toggle='tooltip' data-bs-placement='top' title='View'>
                  <Link
                    to={`#`}
                    className={`btn btn-sm btn-soft-info ${
                      (cellProps.row.original.status != 'active' || cellProps.row.original.syncStatus) && 'opacity-50'
                    }`}
                    id={`edittooltip-${cellProps.row.original.id}`}
                    onClick={debounce(() => {
                      if (cellProps.row.original.status == 'active' && !cellProps.row.original.syncStatus) {
                        onClickItem(cellProps.row.original, 'edit')
                      }
                    }, 600)}
                  >
                    <i className='mdi mdi-pencil-outline' />
                    <UncontrolledTooltip
                      trigger='hover'
                      placement='top'
                      target={`edittooltip-${cellProps.row.original.id}`}
                    >
                      {cellProps.row.original.syncStatus ? (
                        'Dữ liệu được import từ Bravo không thể chỉnh sửa'
                      ) : (
                        <>
                          {cellProps.row.original.status == 'activated' && 'Đã kích hoạt không thể chỉnh sửa'}
                          {cellProps.row.original.status == 'accumulated' && 'Đã tích lũy không thể chỉnh sửa'}
                          {cellProps.row.original.status == 'active' && 'Chỉnh sửa'}
                        </>
                      )}
                    </UncontrolledTooltip>
                  </Link>
                </li>
              </PermissionWrapper>
              <PermissionWrapper screenCode={screenCode} code={PRODUCT_MANAGEMENT_CODE.PRODUCT_DELETE_PRODUCT}>
                <li>
                  <Link
                    to='#'
                    className={`btn btn-sm btn-soft-danger ${
                      (cellProps.row.original.syncStatus || cellProps.row.original.status != 'active') && 'opacity-50'
                    }`}
                    onClick={() => {
                      if (!cellProps.row.original.syncStatus && cellProps.row.original.status == 'active') {
                        onClickDelete(cellProps.row.original)
                      }
                    }}
                    id={`deletetooltip-${cellProps.row.original.id}`}
                    disabled={cellProps.row.original.syncStatus || cellProps.row.original.status != 'active'}
                  >
                    <i className='mdi mdi-delete-outline' />
                    <UncontrolledTooltip
                      trigger='hover'
                      placement='top'
                      target={`deletetooltip-${cellProps.row.original.id}`}
                    >
                      {cellProps.row.original.syncStatus ? (
                        'Dữ liệu được import từ Bravo không thể xóa'
                      ) : (
                        <>
                          {cellProps.row.original.status == 'activated' && 'Đã kích hoạt không thể xóa'}
                          {cellProps.row.original.status == 'accumulated' && 'Đã tích lũy không thể xóa'}
                          {cellProps.row.original.status == 'active' && 'Xóa'}
                        </>
                      )}
                    </UncontrolledTooltip>
                  </Link>
                </li>
              </PermissionWrapper>
            </ul>
          )
        }
      }
    ],
    [filter, page, hasPermission]
  )

  const setGlobalFilterUser = async (data) => {
    setStorage('filterManageProduct', data)
    dispatch(
      setFilter({
        ...data,
        page: page
      })
    )

    dispatch(
      getProductList({
        ...data,
        meta: {
          Ngành: data?.industry_names,
          'Ngành hàng': data?.industry_sector_names,
          'Nhóm sản phẩm': data?.category_names,
          'Nhập Model': data?.model_names,
          'Nhập số Serial': data?.search,
          Miền: data?.zone_names,
          'Trạng thái': data?.status_name
        }
      })
    )

    setPage(1)
  }

  const goToPage = (page) => {
    dispatch(getProductList({ ...filter, page }))
    setPage(page)
    dispatch(setFilter({ ...filter, page: page }))
  }

  const onDeleteAccount = async () => {
    if (isLoading) return
    setLoading(true)
    if (isLoading) return
    setLoading(true)
    try {
      const res = await deleteProductAPI({
        id: selected.id
      })
      if (res) {
        toast('Sản phẩm được xóa thành công.', { type: 'success' })
        setShowDeleteAccount(false)
        dispatch(getProductList({ ...filter, page }))
      }
    } catch (error) {
      toast(error.response.data.message, { type: 'error' })
    }
    setLoading(false)
    setLoading(false)
  }

  const uploadFile = async (files) => {
    setLoadingImport(true)
    let formData = new FormData()
    formData.append('file', files[0])

    try {
      const res = await importFileAPI(url.IMPORT_PRODUCT, formData)
      if (res) {
        handleCheckUpload(res.data.data)
      }
    } catch (error) {
      toast(error.response.data.message, { type: 'error' })
      setLoadingImport(false)
    }
  }

  const uploadFileUpdate = async (files) => {
    setLoadingImportUpdate(true)
    let formData = new FormData()
    formData.append('file', files[0])

    try {
      const res = await importFileAPI(url.IMPORT_PRODUCT_UPDATE, formData)
      if (res) {
        handleCheckUploadUpdate(res.data.data)
      }
    } catch (error) {
      toast(error.response.data.message, { type: 'error' })
      setLoadingImport(false)
    }
  }

  const handleCheckUpload = async (data) => {
    try {
      const res = await checkUploadFileAPI(data)

      if (!res.data.queueFinish) {
        setTimeout(() => {
          handleCheckUpload(data)
        }, 2000)
      } else {
        if (res.data.countFailed > 0) {
          // handleExportFileError(data)
        }
        toast('Hệ thống đang xử lý. Vui lòng kiểm tra thông tin tại màn hình Lịch sử import', { type: 'success' })
        dispatch(getProductList())
        setLoadingImport(false)
        setShowImport(false)
      }
    } catch (error) {
      toast(error.response.data.message, { type: 'error' })
      setLoadingImport(false)
    }
  }

  const handleCheckUploadUpdate = async (data) => {
    try {
      const res = await checkUploadFileAPI(data)
      if (!res.data.queueFinish) {
        setTimeout(() => {
          handleCheckUploadUpdate(data)
        }, 2000)
      } else {
        if (res.data.countFailed > 0) {
          // handleExportFileError(data)
        }
        toast('Hệ thống đang xử lý. Vui lòng kiểm tra thông tin tại màn hình Lịch sử import', { type: 'success' })
        dispatch(getProductList())
        setLoadingImportUpdate(false)
        setShowImportUpdate(false)
      }
    } catch (error) {
      toast(error.response.data.message, { type: 'error' })
      setLoadingImportUpdate(false)
    }
  }

  const handleExportFileError = async (data) => {
    try {
      const res = await exportFileError(data)

      if (res) {
        const nameFile = `${moment().format('yyMMDD')}_FileError.xlsx`
        const url = window.URL.createObjectURL(new Blob([res]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', nameFile) //or any other extension
        document.body.appendChild(link)
        link.click()
      }
    } catch (error) {
      toast(error.response.data.message, { type: 'error' })
      setShowImport(false)
      setLoadingImport(false)
    }
  }

  const handleClickExport = async () => {
    setLoading(true)
    try {
      const res = await exportProductAPI(filter)
      if (res?.data?.success === false) {
        toast(res?.message, { type: 'error' })
      } else {
        toast('Hệ thống đang xử lý. Tệp của bạn đã được gửi tới email ' + profile?.email + ' trong vài phút', {
          type: 'success'
        })
      }
    } catch (error) {
      toast(error.response.data.message, { type: 'error' })
    }
    setLoading(false)
  }

  const handleClickDownloadTemplate = async () => {
    try {
      const res = await downloadTemplateAPI({ type: 'product' })
      const nameFile = `Bieu_mau_import_DSSP.xlsx`
      const url = window.URL.createObjectURL(new Blob([res]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', nameFile) //or any other extension
      document.body.appendChild(link)
      link.click()
      toast('Tải biểu mẫu thành công!', { type: 'success' })
    } catch (error) {
      toast(error.response.data.message, { type: 'error' })
    }
  }

  const handleClickDownloadTemplateUpdate = async () => {
    try {
      const res = await downloadTemplateAPI({ type: 'update_product' })
      const nameFile = `Bieu_mau_import_DSSP.xlsx`
      const url = window.URL.createObjectURL(new Blob([res]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', nameFile) //or any other extension
      document.body.appendChild(link)
      link.click()
      toast('Tải biểu mẫu thành công!', { type: 'success' })
    } catch (error) {
      toast(error.response.data.message, { type: 'error' })
    }
  }

  const permissionColumns = useMemo(() => columns.filter((column) => column.show !== false), [columns])

  return (
    <React.Fragment>
      <div className='page-content'>
        <div className='page-title-box container-fluid'>
          <h4 className=' mb-sm-0 font-size-18 pb-4'>{ProductVN.title}</h4>
          {isLoading ? (
            <Spinners setLoading={setLoading} />
          ) : (
            <Row>
              <Col lg='12'>
                <Card>
                  <CardBody className='border-bottom'>
                    <div className='d-flex align-items-center'>
                      <h5 className='mb-0 card-title flex-grow-1'>{ProductVN.list}</h5>
                      <div className='btn-group flex-wrap' data-toggle='buttons'>
                        <div className='flex-row-0'>
                          <PermissionWrapper
                            screenCode={screenCode}
                            code={PRODUCT_MANAGEMENT_CODE.PRODUCT_IMPORT_PRODUCT}
                          >
                            <button
                              onClick={() => setShowImportUpdate(true)}
                              className='btn btn-primary me-2 pe-4 mb-2'
                            >
                              <i className='mdi mdi-database-import me-1 ms-2' />
                              {'Import cập nhật sản phẩm'}
                            </button>{' '}
                            <button onClick={() => setShowImport(true)} className='btn btn-primary me-2 pe-4 mb-2'>
                              <i className='mdi mdi-database-import me-1 ms-2' />
                              {ProductVN.import}
                            </button>
                          </PermissionWrapper>

                          <PermissionWrapper
                            screenCode={screenCode}
                            code={PRODUCT_MANAGEMENT_CODE.PRODUCT_EXPORT_PRODUCT}
                          >
                            <button onClick={() => handleClickExport()} className='btn btn-primary me-2 pe-4 mb-2'>
                              <i className='mdi mdi-database-export me-1 ms-2' />
                              {ProductVN.export}
                            </button>
                          </PermissionWrapper>

                          <PermissionWrapper
                            screenCode={screenCode}
                            code={PRODUCT_MANAGEMENT_CODE.PRODUCT_CREATE_PRODUCT}
                          >
                            <button
                              onClick={() => {
                                setShowForm(true)
                                dispatch(setTypePopupProduct('create'))
                                dispatch(getProductDetailSuccess({}))
                              }}
                              className='btn btn-primary me-2 pe-4 mb-2'
                            >
                              <i className='mdi mdi-plus me-1' />
                              {ProductVN.add}
                            </button>

                            <button
                              onClick={() => {
                                navigate('/manage-import-history')
                              }}
                              className='btn btn-primary me-2 pe-4 mb-2'
                            >
                              <i className='mdi mdi-history me-1' />
                              Lịch sử import
                            </button>
                          </PermissionWrapper>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                  <CardBody>
                    <TableContainer
                      columns={permissionColumns}
                      data={products}
                      isGlobalFilter={true}
                      setGlobalFilterUser={setGlobalFilterUser}
                      isProductFilter={true}
                      placeholderSearch={ProductVN.filter.placeholderSearch}
                      isPagination={true}
                      iscustomPageSizeOptions={false}
                      isShowingPageLength={false}
                      customPageSize={10}
                      tableClass='table-bordered align-middle nowrap mt-2'
                      paginationDiv='col-sm-12 col-md-7'
                      pagination='pagination justify-content-end pagination-rounded'
                      useAPIChangePaging
                      currentPage={page}
                      gotoPageCustom={goToPage}
                      total={total}
                      isSearchInput={false}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </div>
      </div>
      <ConfirmModal
        show={showDeleteAccount}
        icon={
          <div className='avatar-title rounded-circle bg-danger text-light bg-opacity-60 font-size-20 rounded-3'>
            <i className='mdi mdi-delete-alert'></i>
          </div>
        }
        onConfirmClick={onDeleteAccount}
        onCloseClick={() => setShowDeleteAccount(false)}
        title='Xóa thông tin sản phẩm'
        description={`Bạn có chắc chắn muốn xóa thông tin sản phẩm?`}
      />
      {showForm && (
        <AddEditProduct
          onCloseClick={() => {
            setShowForm(false)
          }}
          show={showForm}
          setPage={setPage}
        />
      )}
      {showImport && (
        <ImportModal
          onCloseClick={() => {
            setShowImport(false)
          }}
          show={showImport}
          onConfirmClick={uploadFile}
          loading={isLoadingImport}
          title={ProductVN.importModal.title}
          onDownloadClick={handleClickDownloadTemplate}
        />
      )}
      {showImportUpdate && (
        <ImportModal
          onCloseClick={() => {
            setShowImportUpdate(false)
          }}
          show={showImportUpdate}
          onConfirmClick={uploadFileUpdate}
          loading={isLoadingImportUpdate}
          title={'Import cập nhật sản phẩm'}
          onDownloadClick={handleClickDownloadTemplateUpdate}
        />
      )}
    </React.Fragment>
  )
}

export default ManageProduct
