export const GET_MANAGE_BANK = "GET_BANK_LIST"
export const GET_MANAGE_BANK_FAIL = "GET_MANAGE_BANK_FAIL"
export const GET_MANAGE_BANK_SUCCESS = "GET_MANAGE_BANK_SUCCESS"

export const GET_BANK_DETAIL = "GET_BANK_DETAIL"
export const GET_BANK_DETAIL_SUCCESS = "GET_BANK_DETAIL_SUCCESS"
export const GET_BANK_DETAIL_FAIL = "GET_BANK_DETAIL_FAIL"

export const UPDATE_MANAGE_BANK = "UPDATE_MANAGE_BANK"
export const UPDATE_MANAGE_BANK_SUCCESS = "UPDATE_MANAGE_BANK_SUCCESS"
export const UPDATE_MANAGE_BANK_FAIL = "UPDATE_MANAGE_BANK_FAIL"

export const SET_TYPE_POPUP = "SET_TYPE_POPUP_BANK"
