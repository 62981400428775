import {
    GET_MANAGE_ACTIVATION,
    GET_MANAGE_ACTIVATION_FAIL,
    GET_MANAGE_ACTIVATION_SUCCESS,
    GET_ACTIVATION_DETAIL,
    GET_ACTIVATION_DETAIL_SUCCESS,
    GET_ACTIVATION_DETAIL_FAIL,
    SET_TYPE_POPUP,
} from "./actionTypes"

export const getActivationList = params => ({
    type: GET_MANAGE_ACTIVATION,
    payload: params,
})

export const getManageActivationSuccess = data => ({
    type: GET_MANAGE_ACTIVATION_SUCCESS,
    payload: data,
})

export const getManageActivationFail = error => ({
    type: GET_MANAGE_ACTIVATION_FAIL,
    payload: error,
})

export const getActivationDetail = data => ({
    type: GET_ACTIVATION_DETAIL,
    payload: data,
})

export const getActivationDetailSuccess = data => ({
    type: GET_ACTIVATION_DETAIL_SUCCESS,
    payload: data,
})

export const getActivationDetailFail = error => ({
    type: GET_ACTIVATION_DETAIL_FAIL,
    payload: error,
})

export const setTypePopupActivation = params => ({
    type: SET_TYPE_POPUP,
    payload: params,
})
