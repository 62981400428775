import {
    GET_MANAGE_BANK_FAIL,
    GET_MANAGE_BANK_SUCCESS,
    GET_BANK_DETAIL_FAIL,
    UPDATE_MANAGE_BANK_SUCCESS,
    UPDATE_MANAGE_BANK_FAIL,
    SET_TYPE_POPUP,
    GET_BANK_DETAIL_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
    banks: [],
    bankDetail: undefined,
    type: "create",
    loading: true,
    total: 0,
}

const BanksReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_MANAGE_BANK_SUCCESS:
            return {
                ...state,
                banks: action.payload.data,
                total: action.payload.meta.totalItems,
                loading: false,
            }

        case GET_MANAGE_BANK_FAIL:
            return {
                ...state,
                error: action.payload,
            }

        case SET_TYPE_POPUP:
            return {
                ...state,
                type: action.payload,
            }
        case GET_BANK_DETAIL_SUCCESS:
            return {
                ...state,
                bankDetail: action.payload,
            }

        case GET_BANK_DETAIL_FAIL:
            return {
                ...state,
                error: action.payload,
            }

        case UPDATE_MANAGE_BANK_SUCCESS:
            return {
                ...state,
                shops: state.shops.map(manageProduct =>
                    manageProduct.id.toString() === action.payload.id.toString() ? { manageProduct, ...action.payload } : manageProduct
                ),
            }

        case UPDATE_MANAGE_BANK_FAIL:
            return {
                ...state,
                error: action.payload,
            }

        default:
            return state
    }
}

export default BanksReducer
