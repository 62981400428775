import {
    GET_MANAGE_PRODUCT,
    GET_MANAGE_PRODUCT_FAIL,
    GET_MANAGE_PRODUCT_SUCCESS,
    GET_PRODUCT_DETAIL,
    GET_PRODUCT_DETAIL_SUCCESS,
    GET_PRODUCT_DETAIL_FAIL,
    SET_TYPE_POPUP,
} from "./actionTypes"

export const getProductList = params => ({
    type: GET_MANAGE_PRODUCT,
    payload: params,
})

export const getManageProductSuccess = data => ({
    type: GET_MANAGE_PRODUCT_SUCCESS,
    payload: data,
})

export const getManageProductFail = error => ({
    type: GET_MANAGE_PRODUCT_FAIL,
    payload: error,
})

export const getProductDetail = data => ({
    type: GET_PRODUCT_DETAIL,
    payload: data,
})

export const getProductDetailSuccess = data => ({
    type: GET_PRODUCT_DETAIL_SUCCESS,
    payload: data,
})

export const getProductDetailFail = error => ({
    type: GET_PRODUCT_DETAIL_FAIL,
    payload: error,
})

export const setTypePopupProduct = params => ({
    type: SET_TYPE_POPUP,
    payload: params,
})
