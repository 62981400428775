import React from "react"
import { ConfigProvider } from "antd"

const ThemeProvider = ({ children }) => (
    <ConfigProvider
        theme={{
            components: {
                DatePicker: {
                    controlHeightLG: 45,
                },
            },
            token: {
                fontFamily: "Avenir",
                fontSizeLG: 13,
            },
        }}
    >
        {children}
    </ConfigProvider>
)

export default ThemeProvider
