import {
    GET_MANAGE_HISTORY,
    GET_MANAGE_HISTORY_FAIL,
    GET_MANAGE_HISTORY_SUCCESS,
    GET_HISTORY_DETAIL,
    GET_HISTORY_DETAIL_SUCCESS,
    GET_HISTORY_DETAIL_FAIL,
    SET_TYPE_POPUP,
} from "./actionTypes"

export const getHistoryList = params => ({
    type: GET_MANAGE_HISTORY,
    payload: params,
})

export const getManageHistorySuccess = data => ({
    type: GET_MANAGE_HISTORY_SUCCESS,
    payload: data,
})

export const getManageHistoryFail = error => ({
    type: GET_MANAGE_HISTORY_FAIL,
    payload: error,
})

export const getHistoryDetail = data => ({
    type: GET_HISTORY_DETAIL,
    payload: data,
})

export const getHistoryDetailSuccess = data => ({
    type: GET_HISTORY_DETAIL_SUCCESS,
    payload: data,
})

export const getHistoryDetailFail = error => ({
    type: GET_HISTORY_DETAIL_FAIL,
    payload: error,
})

export const setTypePopupHistory = params => ({
    type: SET_TYPE_POPUP,
    payload: params,
})
