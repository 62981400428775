import { GET_REPORT_USER_STATISTIC_FAIL, GET_REPORT_USER_STATISTIC_SUCCESS } from './actionTypes'

const INIT_STATE = {
  notifications: [],
  error: {},
  loading: true,
  total: 0
}

const ReportUserStatisticReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_REPORT_USER_STATISTIC_SUCCESS:
      return {
        ...state,
        notifications: action.payload.data,
        total: action.payload.meta.totalItems,
        loading: true
      }

    case GET_REPORT_USER_STATISTIC_FAIL:
      return {
        ...state,
        error: action.payload
      }

    default:
      return state
  }
}

export default ReportUserStatisticReducer
