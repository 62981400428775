import { call, put, takeEvery } from "redux-saga/effects"

// Login Redux States
import { CHANGE_PASSWORD } from "./actionTypes"
import { changePasswordApiError, changePasswordSuccess } from "./actions"
import { putChangePassword } from "helpers/backend_helper"

function* changePassword({ payload: { form, history } }) {
  try {
    const response = yield call(putChangePassword, {
      old_password: form.oldPassword,
      password: form.password,
      confirm_password: form.confirmPassword,
    })
    yield put(changePasswordSuccess(response))
    history("/")
  } catch (error) {
    yield put(changePasswordApiError(error.response.data.message))
  }
}

function* changePasswordSaga() {
  yield takeEvery(CHANGE_PASSWORD, changePassword)
}

export default changePasswordSaga
