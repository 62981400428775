import {
    GET_MANAGE_ACCUMULATION,
    GET_MANAGE_ACCUMULATION_FAIL,
    GET_MANAGE_ACCUMULATION_SUCCESS,
    GET_ACCUMULATION_DETAIL,
    GET_ACCUMULATION_DETAIL_SUCCESS,
    GET_ACCUMULATION_DETAIL_FAIL,
    SET_TYPE_POPUP,
} from "./actionTypes"

export const getAccumulationList = params => ({
    type: GET_MANAGE_ACCUMULATION,
    payload: params,
})

export const getManageAccumulationSuccess = data => ({
    type: GET_MANAGE_ACCUMULATION_SUCCESS,
    payload: data,
})

export const getManageAccumulationFail = error => ({
    type: GET_MANAGE_ACCUMULATION_FAIL,
    payload: error,
})

export const getAccumulationDetail = data => ({
    type: GET_ACCUMULATION_DETAIL,
    payload: data,
})

export const getAccumulationDetailSuccess = data => ({
    type: GET_ACCUMULATION_DETAIL_SUCCESS,
    payload: data,
})

export const getAccumulationDetailFail = error => ({
    type: GET_ACCUMULATION_DETAIL_FAIL,
    payload: error,
})

export const setTypePopupAccumulation = params => ({
    type: SET_TYPE_POPUP,
    payload: params,
})
