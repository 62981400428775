import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Alert, Card, CardBody, Col, Container, Input, Label, Row, UncontrolledTooltip } from 'reactstrap'

//redux
import { useDispatch } from 'react-redux'
import withRouter from 'components/Common/withRouter'

//Import Breadcrumb
import Breadcrumb from '../../components/Common/Breadcrumb'
import { ManageContentVN } from 'constants/vn'

// styles
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css'
import { getContentDetail } from 'store/actions'
import { createSelector } from 'reselect'
import { useSelector } from 'react-redux'
import {
  lockAccountAPI,
  putRequestAPI,
  putContentDetailAPI,
  resetPasswordAPI,
  postContentAPI,
  getProgramListAPI,
  getProgramDetailsAPI,
  getRankAllAPI,
  postImage,
  patchStatusContentAPI
} from 'helpers/backend_helper'
import { checkFileSizeValid, checkIsLocked, formateDate } from 'helpers/global_helper'
import { toast } from 'react-toastify'
import { MAX_LENGTH_NAME, typeAccountProgramOptions, TypeContentOptions, TypeProgramOptions } from 'constants/constants'

import { debounce, isEqual } from 'lodash'
import { Form, Image, Upload, Button, Spin, Tooltip } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import { FormSelect } from 'components/Common/form-select/FormSelect'
import { FormInput } from 'components/Common/form-input/FormInput'

import { FormDateRange } from 'components/Common/form-date-range/FormDateRange'
import dayjs from 'dayjs'

import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { ContentState, convertFromHTML, convertToRaw, EditorState } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import ConfirmModal from 'components/Common/ConfirmModal'
import moment from 'moment'
import EditorCustom from 'components/Common/EditorCustom/EditorCustom'

const ContentDetail = (props) => {
  //meta title
  document.title = 'Content Detail | HPG'

  const isEdit = props.router.location.pathname.includes('edit') || props.router.location.pathname.includes('create')
  const isCreate = props.router.location.pathname.includes('create')
  const params = props.router.params
  const navigate = props.router.navigate
  const uploadRef = useRef()

  const [form] = Form.useForm()
  const watchForm = Form.useWatch([], form)

  const [programOptions, setProgramOptions] = useState([])
  const [rank, setRank] = useState([])

  const dispatch = useDispatch()

  const selectContentDetailState = (state) => state.ContentReducer
  const ContentDetailProperties = createSelector(selectContentDetailState, (content) => ({
    contentDetail: content.contentDetail
  }))

  const { contentDetail } = useSelector(ContentDetailProperties)

  const selectLayoutState = (state) => state.Layout
  const LayoutProperties = createSelector(selectLayoutState, (app) => ({
    province: app.province,
    district: app.district,
    ward: app.ward,
    zone: app.zone,
    subzone: app.subzone
  }))
  const { province, district, ward, zone, subzone } = useSelector(LayoutProperties)

  const [showReject, setShowReject] = useState(false)
  const [submittable, setSubmittable] = useState(false)
  const [initData, setInitData] = useState()
  const [showApprove, setShowApprove] = useState(false)
  const [editorState, setEditorState] = useState('')
  const [imageUrl, setImageUrl] = useState()
  const [programDetail, setProgramDetail] = useState({})
  const [onCancel, setOnCancel] = useState(false)
  const [showPause, setShowPause] = useState(false)
  const [showPlay, setShowPlay] = useState(false)
  const [loading, setLoading] = useState(true)

  const closePopup = () => {
    setShowReject(false)
    setShowApprove(false)
    setShowPause(false)
    setShowPlay(false)
  }

  const onUpdateRequest = debounce(async (status, note) => {
    try {
      const res = await patchStatusContentAPI({
        id: parseInt(params.id, 10),
        status,
        node: note
      })
      if (res) {
        toast(status === 'rejected' ? 'Từ chối bài viết thành công' : ' Phê duyệt bài viết thành công', {
          type: 'success'
        })
        dispatch(getContentDetail(params.id))
      }
    } catch (error) {
      console.log(error)
      toast(error.response.data.message, { type: 'error' })
    }
    closePopup()
  }, 500)

  const mapsData = (data) => {
    setEditorState(data?.content)
    const technicianRankIds = data?.announceRanks
      ?.filter((r) => r?.technicianRankId != 'all')
      ?.map((e) => e?.technicianRankId)
      .filter((e) => e != null)
    const userRankIds = data?.announceRanks
      ?.filter((r) => !!r?.userRankId)
      ?.map((e) => e?.userRankId)
      .filter((e) => e != null)
    return {
      name: data?.name,
      type: data?.type,
      image: data?.image,
      applyClientTypes: data?.applyClientTypes,
      // programmingIds: data?.announceProgrammings?.[0]?.programmingId,
      programmingId: data?.programmingId,
      displayDate: [dayjs(data?.startDate), dayjs(data?.endDate)],
      technicianRankIds: technicianRankIds,
      userRankIds: userRankIds,
      zone: data?.subIds,
      subzone: data?.subZoneIds,
      province: data?.provinceIds,
      district: data?.districtIds,
      wardIds: data?.wardIds,
      content: data?.content
    }
  }

  const onFinish = async (values) => {
    try {
      if (isCreate) {
        const res = await postContentAPI({
          ...values,
          // programmingIds: [values?.programmingIds],
          programmingId: values?.programmingId,
          content: editorState,
          startDate: values?.displayDate?.[0],
          endDate: values?.displayDate?.[1],
          technicianRankIds: values?.technicianRankIds?.filter((e) => e !== 'all'),
          userRankIds: values?.userRankIds?.filter((e) => e !== 'all'),
          image: imageUrl.url
        })
        if (res) {
          toast('Thêm mới bài viết thành công', { type: 'success' })
          form.resetFields()

          // dispatch(getContentDetail(params.id))
          navigate('/manage-content')
        }
      } else {
        const res = await putContentDetailAPI({
          ...values,
          // programmingIds: [values?.programmingIds],
          programmingId: values?.programmingId,
          content: editorState,
          startDate: values?.displayDate?.[0],
          endDate: values?.displayDate?.[1],
          id: parseInt(params.id, 10),
          image: imageUrl.url
        })
        if (res) {
          toast('Cập nhật bài viết thành công', { type: 'success' })
          form.resetFields()

          // dispatch(getContentDetail(params.id))
          navigate('/manage-content')
        }
      }
      // navigate("/manage-content")
    } catch (error) {
      console.log(error)
      toast(error.response.data.message, { type: 'error' })
    }
  }

  const beforeUpload = async (file) => {
    return new Promise((resolve, reject) => {
      if (
        file.type == 'image/png' ||
        file.type == 'image/jpeg' ||
        file.type == 'image/jpg' ||
        file.type == 'image/jpe' ||
        file.type == 'image/hiec' ||
        file.type == 'image/heif'
      ) {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.addEventListener('load', (event) => {
          const _loadedImageUrl = event.target.result
          const image = document.createElement('img')
          image.src = _loadedImageUrl
          image.addEventListener('load', () => {
            const { width, height } = image
            // set image width and height to your state here
            // if (width < 670 || height < 360) {
            //   toast('Kích thước ảnh tối thiểu: 670x360 pixels.', {
            //     type: 'error'
            //   })
            //   reject()
            // } else if (width / height < 1.7 || width / height > 1.9) {
            //   toast('Tỉ lệ chiều dài / chiều rộng tiêu chuẩn từ 1.7 đến 1.9.', {
            //     type: 'error'
            //   })
            //   reject()
            // } else if (file.size / 1024 >= 5 * 1024) {
            //   toast('Dung lượng ảnh không được vượt quá 5MB', {
            //     type: 'error'
            //   })
            //   reject()
            // } else {
            //   resolve(file)
            // }
            resolve(file)
          })
        })
      } else {
        toast('Vui lòng chọn file ảnh có định dạng jpg, jpeg, png, jpe, hiec, heif', {
          type: 'error'
        })
        reject()
      }
    })
  }

  const hanleFileChange = ({ file, onSuccess, onError }) => {
    console.log('file', file)
    const fileValid = checkFileSizeValid(file)
    if (fileValid) {
      handleAcceptedFiles(file, onSuccess, onError)
    } else {
      toast('Ảnh vượt quá dung lượng cho phép (5MB). Vui lòng chọn ảnh khác', {
        type: 'error'
      })
    }
  }
  const handleAcceptedFiles = async (file, onSuccess, onError) => {
    let formData = new FormData()
    formData.append('file', file)

    try {
      const res = await postImage(formData)
      const nameFile = handleGetNameFile(res.data.data?.path)
      if (res) {
        setImageUrl({
          uid: '-1',
          name: nameFile,
          status: 'done',
          url: res.data.data?.pre + res.data.data?.path,
          thumbUrl: res.data.data?.pre + res.data.data?.path
        })
        toast(res.message, { type: 'success' })
        onSuccess()
      }
    } catch (error) {
      onError()
      toast(error.response.data.message, { type: 'error' })
    }
  }
  const handleGetNameFile = (path) => {
    const pathSplit = path.split('/')
    return pathSplit[pathSplit.length - 1]
  }

  useEffect(() => {
    if (contentDetail) {
      const nameFile = handleGetNameFile(contentDetail?.image)
      setImageUrl({
        uid: '-1',
        name: nameFile,
        status: 'done',
        url: contentDetail?.image,
        thumbUrl: contentDetail?.image
      })
      setInitData(JSON.parse(JSON.stringify(mapsData(contentDetail))))
      form.setFieldsValue(mapsData(contentDetail))
      setLoading(false)
    } else {
      if (isCreate) {
        setLoading(false)
      }
    }
  }, [contentDetail])

  useEffect(() => {
    console.log('params', params)
    if (params.id) {
      dispatch(getContentDetail(params.id))
    }
  }, [dispatch])
  // console.log(validation.touched, "touched", Object.keys(validation.touched)?.length > 0, validation)

  useEffect(() => {
    form.validateFields({ validateOnly: true }).then(
      () => {
        setSubmittable(true)
      },
      () => {
        setSubmittable(false)
      }
    )
  }, [watchForm])

  const rankKTVOptions = useMemo(
    () =>
      [{ label: 'Tất cả', value: 'all' }].concat(
        rank?.filter((r) => r.client === 'technician')?.map((e) => ({ label: e.name, value: e.id }))
      ),
    [rank]
  )
  const rankShopOptions = useMemo(
    () =>
      [{ label: 'Tất cả', value: 'all' }].concat(
        rank?.filter((r) => r.client === 'user')?.map((e) => ({ label: e.name, value: e.id }))
      ),
    [rank]
  )
  const zoneOptions = useMemo(
    () => [{ label: 'Tất cả', value: 'all' }].concat(zone?.map((e) => ({ label: e.name, value: e.id }))),
    [zone]
  )

  const subzoneOptions = useMemo(() => {
    const listFilter = subzone
      ?.filter((e) => watchForm?.zone?.includes(e.zoneId))
      ?.map((e) => ({ label: e.name, value: e.id }))
    return listFilter?.length > 0 ? [{ label: 'Tất cả', value: 'all' }].concat(listFilter) : []
  }, [subzone, watchForm])
  const provinceOptions = useMemo(() => {
    const listFilter = province
      ?.filter((e) => watchForm?.subzone?.includes(e.subZoneId))
      ?.map((e) => ({ label: e.name, value: e.id }))
    return listFilter.length > 0 ? [{ label: 'Tất cả', value: 'all' }].concat(listFilter) : []
  }, [province, watchForm])
  const districtOptions = useMemo(() => {
    const listFilter = district
      ?.filter((e) => watchForm?.province?.includes(e.parentId))
      ?.map((e) => ({ label: e.name, value: e.id }))
    return listFilter.length > 0 ? [{ label: 'Tất cả', value: 'all' }].concat(listFilter) : []
  }, [district, watchForm])
  const wardOptions = useMemo(() => {
    const listFilter = ward
      ?.filter((e) => watchForm?.district?.includes(e.parentId))
      ?.map((e) => ({ label: e.name, value: e.id }))
    return listFilter.length > 0 ? [{ label: 'Tất cả', value: 'all' }].concat(listFilter) : []
  }, [ward, watchForm])

  const getDataOptions = async () => {
    try {
      const resRank = await getRankAllAPI()
      setRank(resRank?.data)
    } catch (error) {
      console.log(error)
      // toast(error.response.data.message, { type: "error" })
    }
  }
  const getProgramOptions = async () => {
    const res = await getProgramListAPI({
      type: watchForm?.type,
      status: 'active',
      startDate: formateDate(Date.now(), 'YYYY-MM-DD')
    })
    setProgramOptions(res.data)
  }

  const getProgramDetail = async (id) => {
    const res = await getProgramDetailsAPI(id)
    if (res) {
      console.log('res', res)
      setProgramDetail(res.data)
    }
  }
  useEffect(() => {
    if (watchForm?.type) {
      console.log(1111)
      getProgramOptions()
    }
  }, [watchForm?.type])

  useEffect(() => {
    if (programDetail) {
      form.setFieldsValue({
        applyClientTypes: programDetail?.applyClientTypes,
        zone: programDetail?.zones?.map((e) => e.id),
        subzone: programDetail?.subZones?.map((e) => e.id),
        province: programDetail?.provinces?.map((e) => e.id),
        district: programDetail?.districts?.map((e) => e.id),
        wardIds: programDetail?.wards?.map((e) => e.id),
        technicianRankIds: programDetail?.ranks?.map((e) => e.id),
        userRankIds: programDetail?.ranks?.map((e) => e.id)
      })
    }
  }, [programDetail])

  useEffect(() => {
    getDataOptions()
  }, [])

  const onClickCancel = () => {
    setOnCancel(false)
    navigate('/manage-content')
  }

  const checkClickCancel = () => {
    navigate('/manage-content')

    // if (
    //   !isEqual(
    //     { ...initData, displayDate: initData.displayDate.map((e) => dayjs(e)) },
    //     {
    //       ...watchForm,
    //       image: imageUrl ? imageUrl?.url : '',
    //       content: editorState,
    //       technicianRankIds: watchForm?.technicianRankIds ? watchForm?.technicianRankIds : [],
    //       userRankIds: watchForm?.userRankIds ? watchForm?.userRankIds : [],
    //       displayDate: watchForm?.displayDate?.map((e) => dayjs(e))
    //     }
    //   )
    // ) {
    //   setOnCancel(true)
    // } else {
    //   navigate('/manage-content')
    // }
  }

  const onUpdateRequestPause = async (status, note) => {
    try {
      const res = await patchStatusContentAPI({
        id: parseInt(params.id, 10),
        status,
        node: note
      })
      if (res) {
        toast(status === 'pause' ? 'Tạm ngưng bài viết thành công' : 'Hiển thị lại bài viết thành công', {
          type: 'success'
        })
        // dispatch(getContentDetail(params.id))
        // dispatch(onGetManageContent({ ...filter, page: page }))
        dispatch(getContentDetail(params.id))
      }
    } catch (error) {
      console.log(error)
      toast(error.response.data.message, { type: 'error' })
    }
    closePopup()
  }

  return (
    <React.Fragment>
      <div className='page-content'>
        <Spin spinning={loading}>
          <Form onFinish={onFinish} autoComplete='off' form={form} name='contentForm'>
            <Container fluid>
              {/* Render Breadcrumb */}
              <Breadcrumb
                title={ManageContentVN.title}
                breadcrumbItem={
                  !isEdit
                    ? ManageContentVN.view
                    : props.router.location.pathname.includes('create')
                    ? ManageContentVN.add
                    : ManageContentVN.edit
                }
              />

              <Row>
                <Col lg='9'>
                  <Card>
                    <CardBody>
                      <div className='form-group '>
                        <Row className='mt-4'>
                          <Col sm='8'>
                            <Label className='form-label'>
                              {ManageContentVN.generalInfor.form.contentName}
                              {isEdit && <a className='text-danger'>*</a>}
                            </Label>
                            <Form.Item name='name' rules={[{ required: true, message: 'Vui lòng nhập tên bài viết' }]}>
                              <FormInput
                                placeholder={`Nhập ${ManageContentVN.generalInfor.form.contentName}`}
                                maxLength={MAX_LENGTH_NAME}
                                disabled={!isEdit}
                              />
                            </Form.Item>
                          </Col>
                          <Col sm='4'>
                            <Label className='form-label'>
                              {ManageContentVN.generalInfor.form.contentDuration}
                              {isEdit && <a className='text-danger'>*</a>}
                            </Label>
                            <Form.Item
                              name='displayDate'
                              rules={[{ required: true, message: 'Vui lòng chọn thời gian hiển thị' }]}
                            >
                              {/* <FormDateRange disabledDate={current => current && current < moment().endOf('day')} disabled={!isEdit} />    */}
                              <FormDateRange
                                disabledDate={(current) => dayjs().isAfter(current, 'day')}
                                disabled={!isEdit}
                              />
                            </Form.Item>
                          </Col>
                          <Col sm='4'>
                            <Label className='form-label'>
                              {ManageContentVN.generalInfor.form.contentType}
                              {isEdit && <a className='text-danger'>*</a>}
                            </Label>
                            <Form.Item name='type'>
                              <FormSelect
                                options={[
                                  {
                                    label: 'Tích lũy',
                                    value: 'accumulation'
                                  },
                                  {
                                    label: 'Kích hoạt',
                                    value: 'activation'
                                  },
                                  {
                                    label: 'Trưng bày',
                                    value: 'display'
                                  },
                                  {
                                    label: 'Tin tức',
                                    value: 'news'
                                  },
                                  {
                                    label: 'Khác',
                                    value: 'other'
                                  }
                                ]}
                                placeholder={`Nhập ${ManageContentVN.generalInfor.form.contentType}`}
                                disabled={!isEdit}
                                onChange={() => {
                                  form.setFieldValue('programmingId', undefined)
                                }}
                              />
                            </Form.Item>
                          </Col>
                          {watchForm?.type && watchForm?.type != 'news' && watchForm?.type != 'other' && (
                            <Col sm='8'>
                              <Label className='form-label'>
                                {ManageContentVN.generalInfor.form.program}
                                {isEdit && <a className='text-danger'>*</a>}
                              </Label>
                              <Form.Item
                                name='programmingId'
                                rules={[{ required: true, message: 'Vui lòng nhập chương trình liên kết' }]}
                              >
                                <FormSelect
                                  options={programOptions?.map((e) => ({ label: e.name, value: e.id }))}
                                  placeholder={`Nhập ${ManageContentVN.generalInfor.form.program}`}
                                  disabled={!isEdit}
                                  onChange={(value) => value && getProgramDetail(value)}
                                />
                              </Form.Item>
                            </Col>
                          )}
                        </Row>
                        <Row className='mt-4'>
                          <h5>{ManageContentVN.detailInfor.title}</h5>
                          <Col sm='4'>
                            <Label className='form-label '>
                              {ManageContentVN.detailInfor.form.accountType}
                              {isEdit && <a className='text-danger'>*</a>}
                            </Label>
                            <Form.Item
                              name='applyClientTypes'
                              rules={[{ required: true, message: 'Vui lòng chọn loại tài khoản' }]}
                            >
                              <FormSelect
                                mode='multiple'
                                allowClear
                                maxTagTextLength={10}
                                maxTagCount={2}
                                options={typeAccountProgramOptions}
                                onSelect={(value) => {
                                  const all = ['user', 'technician']
                                  if (value && value === 'all') {
                                    form.setFieldValue('applyClientTypes', [...all])
                                  }
                                  // form.setFieldValue("technicianRankIds", undefined)
                                  // form.setFieldValue("userRankIds", undefined)
                                }}
                                placeholder={`Chọn ${ManageContentVN.detailInfor.form.accountType}`}
                                disabled={!isEdit}
                              />
                            </Form.Item>
                          </Col>
                          {watchForm?.applyClientTypes?.includes('technician') && (
                            <Col sm='4'>
                              <Label className='form-label'>{ManageContentVN.detailInfor.form.accountKTVRank}</Label>
                              <Form.Item
                                name='technicianRankIds'
                                // rules={[{ required: true, message: "Vui lòng chọn hạng tài khoản" }]}
                              >
                                <FormSelect
                                  mode='multiple'
                                  allowClear
                                  maxTagTextLength={10}
                                  maxTagCount={2}
                                  disabled={!isEdit}
                                  options={rankKTVOptions}
                                  onSelect={(value) => {
                                    const all = rankKTVOptions?.filter((i) => i.value != 'all').map((e) => e.value)
                                    if (value && value === 'all') {
                                      return form.setFieldValue('technicianRankIds', [...all])
                                    }
                                  }}
                                  placeholder={`Chọn ${ManageContentVN.detailInfor.form.accountKTVRank}`}
                                />
                              </Form.Item>
                            </Col>
                          )}
                          {watchForm?.applyClientTypes?.includes('user') && (
                            <Col sm='4'>
                              <Label className='form-label'>{ManageContentVN.detailInfor.form.accountShopRank}</Label>
                              <Form.Item
                                name='userRankIds'
                                // rules={[{ required: true, message: "Vui lòng chọn hạng tài khoản" }]}
                              >
                                <FormSelect
                                  mode='multiple'
                                  allowClear
                                  maxTagTextLength={10}
                                  maxTagCount={2}
                                  disabled={!isEdit}
                                  options={rankShopOptions}
                                  onSelect={(value) => {
                                    const all = rankShopOptions?.filter((i) => i.value != 'all').map((e) => e.value)
                                    if (value && value === 'all') {
                                      return form.setFieldValue('userRankIds', [...all])
                                    }
                                  }}
                                  placeholder={`Chọn ${ManageContentVN.detailInfor.form.accountShopRank}`}
                                />
                              </Form.Item>
                            </Col>
                          )}
                        </Row>
                        <Row>
                          <Col sm='4'>
                            <Label className='form-label'>
                              {ManageContentVN.detailInfor.form.zone}
                              {isEdit && <a className='text-danger'>*</a>}
                            </Label>
                            <Form.Item name='zone' rules={[{ required: true, message: 'Vui lòng chọn miền' }]}>
                              <FormSelect
                                mode='multiple'
                                showSearch
                                allowClear
                                maxTagTextLength={10}
                                maxTagCount={2}
                                options={zoneOptions}
                                onChange={() => {
                                  form.setFieldValue('subzone', undefined)
                                  form.setFieldValue('province', undefined)
                                  form.setFieldValue('district', undefined)
                                  form.setFieldValue('wardIds', undefined)
                                }}
                                onSelect={(value) => {
                                  const all = zone?.map((e) => e.id)
                                  if (value && value === 'all') {
                                    return form.setFieldValue('zone', [...all])
                                  }
                                }}
                                placeholder={'Chọn ' + ManageContentVN.detailInfor.form.zone}
                                disabled={!isEdit}
                              />
                            </Form.Item>
                          </Col>
                          <Col sm='4'>
                            <Label className='form-label'>
                              {ManageContentVN.detailInfor.form.region}
                              {isEdit && <a className='text-danger'>*</a>}
                            </Label>
                            <Form.Item name='subzone' rules={[{ required: true, message: 'Vui lòng chọn vùng' }]}>
                              <FormSelect
                                mode='multiple'
                                showSearch
                                allowClear
                                maxTagTextLength={10}
                                maxTagCount={2}
                                options={subzoneOptions}
                                onChange={() => {
                                  form.setFieldValue('province', undefined)
                                  form.setFieldValue('district', undefined)
                                  form.setFieldValue('wardIds', undefined)
                                }}
                                onSelect={(value) => {
                                  const all = subzone
                                    ?.filter((e) => watchForm.zone?.includes(e.zoneId))
                                    ?.map((e) => e.id)
                                  if (value && value === 'all') {
                                    return form.setFieldValue('subzone', [...all])
                                  }
                                }}
                                placeholder={'Chọn ' + ManageContentVN.detailInfor.form.region}
                                disabled={!isEdit || !watchForm?.zone?.length > 0}
                              />
                            </Form.Item>
                          </Col>
                          <Col sm='4'>
                            <Label className='form-label'>
                              {ManageContentVN.detailInfor.form.city}
                              {isEdit && <a className='text-danger'>*</a>}
                            </Label>
                            <Form.Item
                              name='province'
                              rules={[{ required: true, message: 'Vui lòng chọn tỉnh/thành phố' }]}
                            >
                              <FormSelect
                                mode='multiple'
                                showSearch
                                allowClear={<i className='mdi mdi-close-circle-outline mdi-24px ' />}
                                maxTagTextLength={10}
                                maxTagCount={2}
                                options={provinceOptions}
                                onChange={() => {
                                  form.setFieldValue('district', undefined)
                                  form.setFieldValue('wardIds', undefined)
                                }}
                                onSelect={(value) => {
                                  const all = province
                                    ?.filter((e) => watchForm.subzone?.includes(e.subZoneId))
                                    ?.map((e) => e.id)
                                  if (value && value === 'all') {
                                    return form.setFieldValue('province', [...all])
                                  }
                                }}
                                placeholder={'Chọn ' + ManageContentVN.detailInfor.form.city}
                                disabled={!isEdit || !watchForm?.subzone?.length > 0}
                              />
                            </Form.Item>
                          </Col>
                          <Col sm='4'>
                            <Label className='form-label'>
                              {ManageContentVN.detailInfor.form.district}
                              {isEdit && <a className='text-danger'>*</a>}
                            </Label>
                            <Form.Item
                              name='district'
                              rules={[{ required: true, message: 'Vui lòng chọn quận/huyện' }]}
                            >
                              <FormSelect
                                mode='multiple'
                                showSearch
                                allowClear
                                maxTagTextLength={10}
                                maxTagCount={2}
                                options={districtOptions}
                                onChange={() => {
                                  form.setFieldValue('wardIds', undefined)
                                }}
                                onSelect={(value) => {
                                  const all = district
                                    ?.filter((e) => watchForm.province?.includes(e.parentId))
                                    ?.map((e) => e.id)
                                  if (value && value === 'all') {
                                    return form.setFieldValue('district', [...all])
                                  }
                                }}
                                placeholder={'Chọn ' + ManageContentVN.detailInfor.form.district}
                                disabled={!isEdit || !watchForm?.province?.length > 0}
                              />
                            </Form.Item>
                          </Col>
                          <Col sm='4'>
                            <Label className='form-label'>
                              {ManageContentVN.detailInfor.form.ward}
                              {isEdit && <a className='text-danger'>*</a>}
                            </Label>
                            <Form.Item name='wardIds' rules={[{ required: true, message: 'Vui lòng chọn phường/xã' }]}>
                              <FormSelect
                                mode='multiple'
                                showSearch
                                allowClear
                                maxTagTextLength={10}
                                maxTagCount={2}
                                options={wardOptions}
                                onSelect={(value) => {
                                  const all = ward
                                    ?.filter((e) => watchForm.district?.includes(e.parentId))
                                    ?.map((e) => e.id)
                                  if (value && value === 'all') {
                                    return form.setFieldValue('wardIds', [...all])
                                  }
                                }}
                                placeholder={'Chọn ' + ManageContentVN.detailInfor.form.ward}
                                disabled={!isEdit || !watchForm?.district?.length > 0}
                              />
                            </Form.Item>
                          </Col>
                        </Row>

                        <Row className='mt-4'>
                          <h5>{ManageContentVN.articleContent}</h5>
                          <Col sm='12'>
                            <EditorCustom data={editorState} setData={setEditorState} disabled={!isEdit} />
                          </Col>
                        </Row>
                      </div>

                      <div className='d-flex justify-content-between mt-4'>
                        <div></div>
                        {isEdit ? (
                          <div className='hstack gap-2 justify-content-center mt-2'>
                            <span
                              onClick={() => {
                                checkClickCancel()
                              }}
                              className='btn btn-soft-primary'
                            >
                              {ManageContentVN.cancel}
                            </span>
                            <button
                              disabled={
                                isEqual(initData, {
                                  ...watchForm,
                                  image: imageUrl ? imageUrl?.url : '',
                                  technicianRankIds: watchForm?.technicianRankIds,
                                  userRankIds: watchForm?.userRankIds
                                }) || !imageUrl
                              }
                              type='button'
                              className='btn btn-primary'
                              onClick={debounce(() => {
                                form.submit()
                              }, 500)}
                            >
                              {ManageContentVN.save}
                            </button>
                          </div>
                        ) : (
                          <div className='hstack gap-2 justify-content-center mt-2'>
                            <span
                              onClick={() => {
                                checkClickCancel()
                              }}
                              className='btn btn-soft-primary'
                            >
                              {ManageContentVN.cancel}
                            </span>
                            <button
                              type='button'
                              className='btn btn-primary'
                              onClick={debounce(() => {
                                navigate(`/manage-content/edit/${params.id}`)
                              }, 500)}
                            >
                              Chỉnh sửa
                            </button>
                          </div>
                        )}
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg='3' className='pb-4 pe-4'>
                  <Card>
                    <CardBody className='d-flex flex-column'>
                      <Label className='form-label'>
                        {ManageContentVN.image}
                        {isEdit && <a className='text-danger'>*</a>}
                        <Tooltip
                          placement='topLeft'
                          title={
                            <ul style={{ padding: '0 0 0 8px', margin: 0 }}>
                              <li>Kích thước ảnh tối thiểu: 670x360pixels.</li>
                              <li>Tỉ lệ chiều dài / chiều rộng tiêu chuẩn: 1.86.</li>
                              <li>Dung lượng ảnh tối đa: 5MB.</li>
                              <li>Định dạng ảnh: JPEG, PNG, JPG, JPE, HIEC, HEIF. </li>
                            </ul>
                          }
                        >
                          <i
                            className='bx bxs-info-circle'
                            style={{ color: '#03bdd6', fontSize: '16px', marginLeft: '4px' }}
                          />
                        </Tooltip>
                      </Label>
                      <Upload
                        beforeUpload={beforeUpload}
                        customRequest={hanleFileChange}
                        listType='picture'
                        accept='image/png, image/jpeg, image/jpg, image/jpe, image/hiec, image/heif'
                        fileList={imageUrl ? [imageUrl] : []}
                        onRemove={() => {
                          if (isEdit) {
                            setImageUrl(undefined)
                          }
                        }}
                        data-bs-toggle='tooltip'
                        data-bs-placement='top'
                        title='View'
                      >
                        {isEdit && (
                          <>
                            <Button id='uploadFile' icon={<UploadOutlined />}>
                              Upload
                            </Button>
                          </>
                        )}
                      </Upload>
                    </CardBody>
                  </Card>
                  {!isEdit && (
                    <Card>
                      <CardBody>
                        <h5>{ManageContentVN.adminChange.title}</h5>
                        <div className=''>
                          <Row className='mt-2 '>
                            <Label className='form-label'>{ManageContentVN.adminChange.status}</Label>
                            <div>{TypeContentOptions?.find((e) => e.value === contentDetail?.status)?.label}</div>
                          </Row>
                          {contentDetail?.noteFromAdmin && (
                            <Row className='mt-2 '>
                              <Label className='form-label'>{ManageContentVN.adminChange.note}</Label>
                              <div>{contentDetail?.noteFromAdmin}</div>
                            </Row>
                          )}
                          {(contentDetail?.status === 'rejected' || contentDetail?.status === 'active') && (
                            <Row className='mt-2 '>
                              <Label className='form-label'>Thời gian phê duyệt</Label>
                              <div>{moment(contentDetail?.updatedAt).format('DD/MM/YYYY')}</div>
                            </Row>
                          )}
                        </div>
                      </CardBody>
                    </Card>
                  )}
                  {contentDetail?.status === 'pending' && !isEdit && (
                    <div className='hstack gap-2 justify-content-center mt-2'>
                      <button
                        onClick={(e) => {
                          e.preventDefault()
                          setShowReject(true)
                        }}
                        className='btn btn-danger w-50'
                      >
                        {ManageContentVN.reject}
                      </button>
                      <button
                        type='button'
                        className='btn btn-success w-50'
                        onClick={(e) => {
                          e.preventDefault()
                          setShowApprove(true)
                        }}
                      >
                        {ManageContentVN.accept}
                      </button>
                    </div>
                  )}
                  {contentDetail?.status === 'active' && (
                    <div className='hstack gap-2 justify-content-center mt-2'>
                      <button
                        onClick={(e) => {
                          e.preventDefault()
                          setShowPause(true)
                        }}
                        className='btn btn-danger w-50'
                      >
                        Tạm ngưng
                      </button>
                    </div>
                  )}
                  {contentDetail?.status === 'pause' && (
                    <div className='hstack gap-2 justify-content-center mt-2'>
                      <button
                        onClick={(e) => {
                          e.preventDefault()
                          setShowPlay(true)
                        }}
                        className='btn btn-success w-50'
                      >
                        Hiển thị lại
                      </button>
                    </div>
                  )}
                </Col>
              </Row>
              <ConfirmModal
                show={showReject}
                onConfirmClick={(note) => {
                  onUpdateRequest('rejected', note)
                }}
                onCloseClick={closePopup}
                hasInput
                title={'Từ chối bài viết'}
                description='Bạn có chắc chắn muốn từ chối phê duyệt bài viết?'
              />
              <ConfirmModal
                show={showApprove}
                onConfirmClick={(note) => onUpdateRequest('active')}
                onCloseClick={closePopup}
                title={'Xác nhận phê duyệt bài viết'}
                description='Bạn có chắc chắn muốn phê duyệt bài viết này?'
              />
              <ConfirmModal
                show={onCancel}
                onConfirmClick={onClickCancel}
                onCloseClick={() => setOnCancel(false)}
                title={`Xác nhận`}
                description={`Thay đổi sẽ không được lưu. Bạn có chắc chắn muốn thoát không?`}
              />
              <ConfirmModal
                show={showPause}
                icon={
                  <div className='avatar-title rounded-circle bg-danger text-light bg-opacity-60 font-size-20 rounded-3'>
                    <i className='mdi mdi-delete-outline'></i>
                  </div>
                }
                hasInput
                onConfirmClick={(note) => {
                  onUpdateRequestPause('pause', note)
                }}
                onCloseClick={() => setShowPause(false)}
                title='Xác nhận tạm ngưng bài viết'
                description={`Bài viết sẽ không còn được hiển thị ở các module liên quan trên Mobile App. Bạn có chắc chắn muốn tạm ngưng hiển thị bài viết?`}
              />
              <ConfirmModal
                show={showPlay}
                onConfirmClick={(note) => {
                  onUpdateRequestPause('active')
                }}
                onCloseClick={() => setShowPlay(false)}
                // hasInput
                title={'Xác nhận hiển thị lại bài viết'}
                description='Bài viết sẽ được hiển thị ở các module liên quan trên Mobile App. Bạn có chắc chắn muốn hiển thị lại bài viết?'
              />
            </Container>
          </Form>
        </Spin>
      </div>
    </React.Fragment>
  )
}

export default withRouter(ContentDetail)
