import React, { useState } from 'react'
import { Col, Label } from 'reactstrap'

//flatpickr
import 'flatpickr/dist/themes/material_blue.css'
import { PortfolioVN, ManageShopVN } from 'constants/vn'
import { createSelector } from 'reselect'
import { useSelector } from 'react-redux'
import { TypePortfolioOptions } from 'constants/constants'
import { FormSelect } from './form-select/FormSelect'
import { FormInput } from './form-input/FormInput'
import { MAX_LENGTH_SEARCH } from 'constants/constants'

const PortfolioGlobalFilter = ({ setGlobalFilter, search, setSearch }) => {
  const selectLayoutState = (state) => state.Layout
  const LayoutProperties = createSelector(selectLayoutState, (app) => ({
    province: app.province,
    district: app.district,
    ward: app.ward
  }))
  const { province } = useSelector(LayoutProperties)

  const [filter, setFilter] = useState({})

  const handleSelect = (value, key) => {
    setFilter((e) => ({ ...e, [key]: value }))
  }

  return (
    <React.Fragment>
      <Col xxl={2} lg={2} className='mt-3'>
        <Label className='form-label'>{PortfolioVN.filter.typePortfolio.label}</Label>
        <FormSelect
          name='type'
          options={TypePortfolioOptions?.map((item) => ({
            value: item.value,
            label: item.label
          }))}
          value={filter.type}
          onChange={(value, label) => {
            handleSelect(value, 'type')
            handleSelect(label?.label, 'type_name')
          }}
          placeholder='Tất cả'
        />
      </Col>

      <Col xxl={8} lg={10} className='d-flex align-items-end justify-content-end mt-3 '>
        <div className='mb-xxl-0 me-2'>
          <button
            type='button'
            className='btn btn-soft-danger w-100'
            onClick={() => {
              setFilter({
                type: undefined,
                type_name: undefined
              })
              setSearch('')
              setGlobalFilter({})
            }}
          >
            Xóa tìm kiếm
          </button>
        </div>
        <div className='mb-xxl-0'>
          <button
            type='button'
            className='btn btn-primary w-100'
            onClick={() => {
              const _filter = !filter
                ? filter
                : Object.fromEntries(Object.entries(filter).filter(([key, value]) => value !== undefined))
              setGlobalFilter({ ..._filter, search })
            }}
          >
            Tìm kiếm
          </button>
        </div>
      </Col>
    </React.Fragment>
  )
}
export default PortfolioGlobalFilter
