import React, { useEffect, useState } from "react"
import { Col, Label, Modal, ModalBody, Row } from "reactstrap"
import { getUserHistoryChangeApi } from "helpers/backend_helper"
import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Space, Table, Pagination } from 'antd';
import moment from "moment";
import { Badge } from 'reactstrap'

const ModalHistoryRequest = ({ id, show, onCloseClick }) => {
  const [dataTable, setDataTable] = useState([])
  const [total, setTotal] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)

  useEffect(() => {
    handleGetHistoryRequest()
  }, [currentPage])

  const handleGetHistoryRequest = async () => {
    const res = await getUserHistoryChangeApi({ id: id, page: currentPage })

    if (res.data) {
      const data = res.data.map((item, index) => {
        return {
          ...item,
          key: index
        }
      })
      setDataTable(data)
    }

    if (res.meta) {
      setTotal(res.meta.totalItems)
    }
  }

  const expandedRowRender = (value) => {
    const columns = [
      {
        title: 'Loại thông tin',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Thông tin cũ',
        dataIndex: 'oldValue',
        key: 'oldValue',
        render: (_, record) => {
          if (record.key == "identity_images" || record.key == "shop_images") {
            return <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
              {record.oldValue.map((item, index) => {
                return <img key={index} src={item} style={{ width: "40px", height: "40px" }} />
              })}
            </div>
          } else {
            return record.oldValue
          }
        }
      },
      {
        title: 'Thông tin mới',
        dataIndex: 'value',
        key: 'value',
        render: (_, record) => {
          if (record.key == "identity_images" || record.key == "shop_images") {
            return <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
              {record.value.map((item, index) => {
                return <img key={index} src={item} style={{ width: "40px", height: "40px" }} />
              })}
            </div>
          } else {
            return record.value
          }
        }
      },
    ];
    return <Table columns={columns} dataSource={value.dataChanges} pagination={false} />;
  };
  const columns = [
    {
      title: 'Thời gian yêu cầu',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (_, record) => {
        return moment(record.createdAt).format('HH:mm - DD/MM/YYYY')
      }
    },
    {
      title: 'Thời gian phê duyệt',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (_, record) => {
        return moment(record.updatedAt).format('HH:mm - DD/MM/YYYY')
      }
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      key: 'status',
      render: (_, record) => {
        switch (record.status) {
          case "approve":
            return <Badge className="badge-soft-success">Đã phê duyệt</Badge>
          case "pending":
            return <Badge className="badge-soft-warning">Chờ phê duyệt</Badge>
          case "admin_update":
            return <Badge className="badge-soft-secondary">Admin cập nhật</Badge>
          case "reject":
            return <Badge className="badge-soft-danger">Từ chối</Badge>
        }
      },
    }
  ];

  return (
    <Modal
      size="lg"
      isOpen={show}
      toggle={() => {
        onCloseClick()
      }}
      centered={true}
    >
      <div className="modal-content">
        <ModalBody className="p-4 text-start">
          <button type="button" onClick={onCloseClick} className="btn-close position-absolute end-0 top-0 m-3" />
          <h2></h2>
          <Table
            className="mt-4"
            columns={columns}
            expandable={{
              expandedRowRender,
            }}
            dataSource={dataTable}
            pagination={false}
          />
          <div className="mt-4" style={{ display: "flex", justifyContent: "right" }}>
            <Pagination total={total} current={currentPage} showSizeChanger={false} onChange={(page) => setCurrentPage(page)} />
          </div>
        </ModalBody>
      </div>
    </Modal>
  )
}

export default ModalHistoryRequest;
