import { call, put, takeEvery } from "redux-saga/effects"

import { GET_MANAGE_BANER, GET_BANER_DETAIL } from "./actionTypes"
import { getManageBanerSuccess, getManageBanerFail, getBanerDetailSuccess } from "./actions"

import { getBanerDetailsAPI, getBanerListAPI } from "helpers/backend_helper"

function* fetchBanersList({ payload: params }) {
    try {
        const response = yield call(getBanerListAPI, { ...params, sort_field: 'priority', sort_order: 'asc'})
        yield put(getManageBanerSuccess(response))
    } catch (error) {
        yield put(getManageBanerFail(error?.data?.message))
    }
}

function* fetchBanerDetail({ payload: data }) {
    try {
        const response = yield call(getBanerDetailsAPI, { id: data })
        yield put(getBanerDetailSuccess(response.data))
    } catch (error) {
        // console.log(error)
        yield put(getManageBanerFail(error?.data?.message))
    }
}

function* BanersSaga() {
    yield takeEvery(GET_MANAGE_BANER, fetchBanersList)
    // yield takeEvery(GET_KTVS, fetchKTVsList)
    yield takeEvery(GET_BANER_DETAIL, fetchBanerDetail)
}

export default BanersSaga
