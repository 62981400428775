import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"

import { Row, Col, CardBody, Card, Alert, Container, FormFeedback, Label } from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import { Link } from "react-router-dom"
import withRouter from "components/Common/withRouter"

// actions
import { lockAccount, loginUser } from "../../store/actions"

// import images
import profile from "assets/images/profile-img.png"
import logo from "assets/images/logo-3.png"
import { signInVN } from "constants/vn"
import { MAX_LENGTH_50 } from "constants/constants"
import { Checkbox, Form, Input } from "antd"
import { FormInput } from "components/Common/form-input/FormInput"

import { sha256Password } from "helpers/global_helper"
import ConfirmModal from "components/Common/ConfirmModal"

const Login = props => {
    //meta title
    document.title = "Login | HPG"

    const dispatch = useDispatch()

    const [form] = Form.useForm()
    const watchForm = Form.useWatch([], form)
    const [submittable, setSubmittable] = useState(false)

    // const validation = useFormik({
    //     // enableReinitialize : use this  flag when initial values needs to be changed
    //     enableReinitialize: true,
    //     validateOnChange: false,
    //     validateOnBlur: false,
    //     initialValues: {
    //         username: "",
    //         password: "",
    //         remember: false,
    //     },
    //     validationSchema: Yup.object({
    //         username: Yup.string().required("Vui lòng nhập tên người dùng"),
    //         password: Yup.string().required("Vui lòng nhập mật khẩu"),
    //     }),
    //     onSubmit: values => {
    //         dispatch(loginUser(values, props.router.navigate))
    //     },
    // })

    const selectLoginState = state => state.Login
    const LoginProperties = createSelector(selectLoginState, login => ({
        error: login.error,
        isLock: login.isLock,
    }))

    const { error, isLock } = useSelector(LoginProperties)
    // const [showLock, setShowLock] = useState(isLock)

    const onFinish = async values => {
        const encryptPassword = sha256Password(values.password)
        dispatch(loginUser({ ...values, password: encryptPassword }, props.router.navigate))
    }
    useEffect(() => {
        form.validateFields({ validateOnly: true }).then(
            () => {
                setSubmittable(true)
            },
            () => {
                setSubmittable(false)
            }
        )
    }, [watchForm])

    return (
        <React.Fragment>
            <div className="account-pages my-5 pt-sm-5">
                <Container>
                    <Row className="justify-content-center">
                        <Col md={8} lg={6} xl={5}>
                            <Card className="overflow-hidden">
                                <div style={{ background: "#fff", padding: "20px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <img src={logo} alt="logo" style={{ width: "75%" }} />
                                </div>
                                <CardBody className="pt-0">
                                    <div>
                                        <Link to="/" className="logo-light-element">
                                            <div className="avatar-md profile-user-wid mb-4">
                                                <span className="avatar-title rounded-circle bg-light">
                                                    <img src={logo} alt="" className="rounded-circle" height="34" />
                                                </span>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="p-2">
                                        <Form onFinish={onFinish} autoComplete="off" form={form} name="loginForm">
                                            {error ? <Alert color="danger">{error}</Alert> : null}

                                            <div className="mb-3">
                                                <Label className="form-label">{signInVN.inputDetails[0].title}</Label>
                                                <Form.Item name="username" rules={[{ required: true, message: "Vui lòng nhập tên người dùng" }]}>
                                                    <FormInput
                                                        placeholder={signInVN.inputDetails[0].placeholder}
                                                        maxLength={MAX_LENGTH_50}
                                                        onBlur={value => {
                                                            form.setFieldValue(
                                                                "username",
                                                                value?.target?.value ? value?.target?.value?.trim() : value?.target?.value
                                                            )
                                                        }}
                                                    />
                                                </Form.Item>
                                            </div>

                                            <div className="mb-3">
                                                <Label className="form-label">{signInVN.inputDetails[1].title}</Label>
                                                <Form.Item name="password" rules={[{ required: true, message: `Vui lòng nhập mật khẩu` }]}>
                                                    <Input.Password
                                                        size="large"
                                                        maxLength={MAX_LENGTH_50}
                                                        placeholder={signInVN.inputDetails[1].placeholder}
                                                    />
                                                </Form.Item>
                                            </div>

                                            <div className="form-check">
                                                <Form.Item name="remember" valuePropName="checked">
                                                    <Checkbox className="fw-normal">{signInVN.inputDetails[2].title}</Checkbox>
                                                </Form.Item>
                                            </div>
                                            <div className="mt-3 d-grid">
                                                <button className="btn btn-primary btn-block" type="submit" disabled={!submittable}>
                                                    {signInVN.button}
                                                </button>
                                            </div>
                                        </Form>
                                    </div>
                                </CardBody>
                            </Card>
                            <div className=" text-center">
                                <p>© {new Date().getFullYear()} Hòa Phát.</p>
                            </div>
                        </Col>
                    </Row>
                    <ConfirmModal
                        show={isLock}
                        onConfirmClick={() => {
                            dispatch(lockAccount(false))
                        }}
                        onCloseClick={() => {
                            dispatch(lockAccount(false))
                        }}
                        icon={
                            <div className="avatar-title rounded-circle bg-danger text-light bg-opacity-60 font-size-20 rounded-3">
                                <i className="mdi mdi-alert"></i>
                            </div>
                        }
                        showOneButton
                        buttonConfirmText="Đã hiểu"
                        title="Tài khoản đang bị tạm khóa"
                        description={"Tài khoản của bạn đang bị tạm khóa. Vui lòng liên hệ Admin hệ thống để biết thêm chi tiết."}
                    />
                </Container>
            </div>
        </React.Fragment>
    )
}

export default withRouter(Login)

Login.propTypes = {
    history: PropTypes.object,
}
