import React, { useState } from 'react'
import { Col, Label } from 'reactstrap'

//flatpickr
import 'flatpickr/dist/themes/material_blue.css'
import { ManageShopVN } from 'constants/vn'
import { createSelector } from 'reselect'
import { useSelector } from 'react-redux'
import { FormSelect } from './form-select/FormSelect'
import { FormInput } from './form-input/FormInput'
import { MAX_LENGTH_SEARCH } from 'constants/constants'
import { includes } from 'lodash'
import { getStorage } from 'helpers/global_helper'

const ShopListGlobalFilter = ({ setGlobalFilter, search, setSearch, isKTV }) => {
  const selectLayoutState = (state) => state.Layout
  const LayoutProperties = createSelector(selectLayoutState, (app) => ({
    zone: app.zone,
    subzone: app.subzone,
    province: app.province,
    district: app.district,
    ward: app.ward,
    myLocationManagement: app.myLocationManagement
  }))
  const { zone, subzone, province, district, ward, myLocationManagement } = useSelector(LayoutProperties)

  const [filter, setFilter] = useState({})

  const handleSelect = (value, key) => {
    setFilter((e) => ({ ...e, [key]: value }))
  }

  const lstDistrict = myLocationManagement

  ////Lấy danh sách ward
  // Get all unique parentId values from lstDistrict
  const wardParentIds = myLocationManagement?.map((district) => district.id)

  //Từ wardParentIds, lấy ra những ward có id trong wardParentIds
  const lstWard = ward.filter((ward) => wardParentIds.includes(ward.parentId))

  ////Lấy danh sách province
  // Get all unique parentId values from lstDistrict
  const districtParentIds = lstDistrict?.map((district) => district.parentId)
  //Từ districtParentIds, lấy ra những district có id trong districtParentIds
  const lstProvionce = province.filter((province) => districtParentIds.includes(province.id))

  ////Lấy danh sách subZone
  // Get all unique subZoneId(parentId) values from lstProvionce
  const provinceParentIds = lstProvionce?.map((province) => province.subZoneId)
  //Từ provinceParentIds, lấy ra những subZone có id trong provinceParentIds
  const lstSubZone = subzone?.filter((subZone) => provinceParentIds.includes(subZone.id))

  ////Lấy danh sách zone
  // Get all unique zoneId(parentId) values from lstProvionce
  const subZoneParentIds = lstProvionce?.map((province) => province.zoneId) // trong lstProvionce có lưu zoneId
  //Từ provinceParentIds, lấy ra những subZone có id trong provinceParentIds
  const lstZone = zone.filter((zone) => subZoneParentIds.includes(zone.id))

  return (
    <React.Fragment>
      <Col xxl={6} lg={6} xs={6} className='mt-2'>
        <Label className='form-label'>Nhập tên</Label>
        <FormInput
          className='w-100'
          maxLength={MAX_LENGTH_SEARCH}
          placeholder='Nhập tên'
          value={filter?.search || ''}
          name='search'
          id={`input-filter`}
          onChange={(value) => {
            handleSelect(value.target.value, 'search')
          }}
          onBlur={(value) => {
            handleSelect(value.target.value, 'search')
          }}
        />
      </Col>

      <Col xxl={6} lg={6} xs={6} className='mt-2'>
        <Label className='form-label'>Nhập số điện thoại</Label>
        <FormInput
          className='w-100'
          maxLength={MAX_LENGTH_SEARCH}
          placeholder='Nhập SĐT '
          value={filter?.phone || ''}
          name='phone'
          id={`input-filter`}
          onChange={(value) => {
            handleSelect(value.target.value, 'phone')
          }}
          onBlur={(value) => {
            handleSelect(value.target.value, 'phone')
          }}
        />
      </Col>

      <Col xxl={6} lg={6} xs={6} className='mt-5'>
        <Label className='form-label'>{'Tỉnh/ Thành phố'}</Label>
        <FormSelect
          options={[{ label: 'Tất cả', value: 'all' }].concat(
            lstProvionce?.map((item) => ({
              value: item.id,
              label: item.name
            }))
          )}
          value={filter.province_ids}
          onChange={(value, label) => {
            handleSelect(value, 'province_ids')
            handleSelect(
              label.map((e) => e.label),
              'province_names'
            )
            handleSelect([], 'district_ids')
            handleSelect([], 'district_names')
            handleSelect([], 'ward_ids')
            handleSelect([], 'ward_names')
          }}
          onSelect={(value) => {
            const all = lstProvionce?.map((item) => item.id)
            const all_names = lstProvionce?.map((item) => item.name)
            if (value && value === 'all') {
              handleSelect([...all], 'province_ids')
              handleSelect([...all_names], 'province_names')
            }
          }}
          placeholder='Tất cả'
          mode='multiple'
        />
      </Col>
      <Col xxl={6} lg={6} xs={6} className='mt-5'>
        <Label className='form-label'>{'Quận/ Huyện'}</Label>
        <FormSelect
          options={
            lstDistrict?.filter((e) => filter.province_ids?.includes(e.parentId))?.length > 0
              ? [{ label: 'Tất cả', value: 'all' }].concat(
                  lstDistrict
                    ?.filter((e) => filter.province_ids?.includes(e.parentId))
                    ?.map((item) => ({
                      value: item.id,
                      label: item.name
                    }))
                )
              : []
          }
          value={filter.district_ids}
          onChange={(value, label) => {
            handleSelect(value, 'district_ids')
            handleSelect(
              label.map((e) => e.label),
              'district_names'
            )
            handleSelect([], 'ward_ids')
            handleSelect([], 'ward_names')
          }}
          onSelect={(value) => {
            const all = lstDistrict?.filter((e) => filter.province_ids?.includes(e.parentId))?.map((item) => item.id)
            const all_names = lstDistrict
              ?.filter((e) => filter.province_ids?.includes(e.parentId))
              ?.map((item) => item.name)

            if (value && value === 'all') {
              handleSelect([...all], 'district_ids')
              handleSelect([...all_names], 'district_names')
            }
          }}
          placeholder='Tất cả'
          mode='multiple'
        />
      </Col>
      <Col xxl={6} lg={6} xs={6} className='mt-5'>
        <Label className='form-label'>{'Phường xã'}</Label>
        <FormSelect
          options={
            lstWard?.filter((e) => filter.district_ids?.includes(e.parentId))?.length > 0
              ? [{ label: 'Tất cả', value: 'all' }].concat(
                  lstWard
                    ?.filter((e) => filter.district_ids?.includes(e.parentId))
                    ?.map((item) => ({
                      value: item.id,
                      label: item.name
                    }))
                )
              : []
          }
          value={filter.ward_ids}
          onChange={(value, label) => {
            handleSelect(value, 'ward_ids')
            handleSelect(
              label.map((e) => e.label),
              'ward_names'
            )
          }}
          onSelect={(value) => {
            const all = lstWard?.filter((e) => filter.district_ids?.includes(e.parentId))?.map((item) => item.id)
            const all_names = lstWard
              ?.filter((e) => filter.district_ids?.includes(e.parentId))
              ?.map((item) => item.name)
            if (value && value === 'all') {
              handleSelect([...all], 'ward_ids')
              handleSelect([...all_names], 'ward_names')
            }
          }}
          placeholder='Tất cả'
          mode='multiple'
        />
      </Col>
      <Col xxl={6} lg={6} xs={6} className='mt-5'>
        <Label className='form-label'>{ManageShopVN.filters.status.title}</Label>
        <FormSelect
          options={ManageShopVN.statusOptions?.map((item) => ({
            value: item.value,
            label: item.label
          }))}
          value={filter.status}
          onChange={(value, label) => {
            handleSelect(value, 'status')
            handleSelect(label?.label, 'status_name')
          }}
          placeholder='Tất cả'
        />
      </Col>
      {!isKTV && (
        <Col xxl={6} lg={6} xs={6} className='mt-5'>
          <>
            <Label className='form-label'>{ManageShopVN.filters.statusAccount.title}</Label>
            <FormSelect
              options={ManageShopVN.statusAccountOptions?.map((item) => ({
                value: item.value,
                label: item.label
              }))}
              value={filter.member}
              onChange={(value, label) => {
                handleSelect(value, 'member')
                handleSelect(label.label, 'member_name')
              }}
              placeholder='Tất cả'
            />
          </>
        </Col>
      )}
      <Col xxl={4} lg={6} xs={6} className='d-flex align-items-end justify-content mt-5'>
        <div className='mb-xxl-0 me-2'>
          <button
            type='button'
            className='btn btn-soft-danger w-100'
            onClick={() => {
              setFilter({
                search: '',
                phone: '',
                province_ids: undefined,
                province_names: undefined,

                district_ids: undefined,
                district_names: undefined,

                ward_ids: undefined,
                ward_names: undefined,

                status: undefined,
                status_name: undefined,

                member: undefined,
                member_name: undefined
              })
              setGlobalFilter({})
            }}
          >
            Xóa tìm kiếm
          </button>
        </div>
        <div className='mb-xxl-0'>
          <button
            type='button'
            className='btn btn-primary w-100'
            onClick={() => {
              const _filter = !filter
                ? filter
                : Object.fromEntries(Object.entries(filter).filter(([key, value]) => value !== undefined))
              console.log('filter :>> ', filter)
              setGlobalFilter({ ..._filter })
            }}
          >
            Tìm kiếm
          </button>
        </div>
      </Col>
    </React.Fragment>
  )
}
export default ShopListGlobalFilter
