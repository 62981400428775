import {
  GET_REPORT_USER_STATISTIC,
  GET_REPORT_USER_STATISTIC_FAIL,
  GET_REPORT_USER_STATISTIC_SUCCESS
} from './actionTypes'

export const getReportUserStatistic = (params) => ({
  type: GET_REPORT_USER_STATISTIC,
  payload: params
})

export const getReportUserStatisticSuccess = (jobs) => ({
  type: GET_REPORT_USER_STATISTIC_SUCCESS,
  payload: jobs
})

export const getReportUserStatisticFail = (error) => ({
  type: GET_REPORT_USER_STATISTIC_FAIL,
  payload: error
})
