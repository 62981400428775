import { call, put, takeEvery } from "redux-saga/effects"

import { GET_MANAGE_SHOP, GET_SHOP_DETAIL, GET_KTVS } from "./actionTypes"
import { getManageShopSuccess, getManageShopFail, getShopDetailSuccess } from "./actions"

import { getKTVsAPI, getShopDetailAPI, getShopsAPI } from "helpers/backend_helper"

function* fetchShopsList({ payload: params }) {
    try {
        const response = yield call(getShopsAPI, params)
        yield put(getManageShopSuccess(response))
    } catch (error) {
        yield put(getManageShopFail(error?.data?.message))
    }
}

function* fetchShopDetail({ payload: data }) {
    try {
        const response = yield call(getShopDetailAPI, { user_id: data })
        yield put(getShopDetailSuccess(response.data))
    } catch (error) {
        console.log(error)
        // yield put(getShopDetailFail(error))
    }
}

function* fetchKTVsList({ payload: params }) {
    try {
        const response = yield call(getKTVsAPI, params)
        yield put(getManageShopSuccess(response))
    } catch (error) {
        yield put(getManageShopFail(error?.data?.message))
    }
}

function* ShopsSaga() {
    yield takeEvery(GET_MANAGE_SHOP, fetchShopsList)
    yield takeEvery(GET_KTVS, fetchKTVsList)
    yield takeEvery(GET_SHOP_DETAIL, fetchShopDetail)
}

export default ShopsSaga
