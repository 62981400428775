import { Result } from 'antd'
import VerticalLayout from 'components/VerticalLayout'
import React from 'react'

function Forbidden() {
  return (
    <VerticalLayout>
      <div style={{ marginTop: '100px' }}>
        <Result status='403' title='403' subTitle='Sorry, you are not authorized to access this page.' />
      </div>
    </VerticalLayout>
  )
}

export default Forbidden
