import {
  GET_PROGRAM_BONUS_TEMP_FAIL,
  GET_PROGRAM_BONUS_TEMP_SUCCESS,
  GET_PROGRAM_BY_AREA_AND_REWARD_TYPE_FAIL,
  GET_PROGRAM_BY_AREA_AND_REWARD_TYPE_SUCCESS,
  GET_RECONCILE_PROGRAM_BY_AREA_FAIL,
  GET_RECONCILE_PROGRAM_BY_AREA_SUCCESS
  // GET_ACTIVATION_DETAIL_FAIL,
  // UPDATE_MANAGE_ACTIVATION_SUCCESS,
  // UPDATE_MANAGE_ACTIVATION_FAIL,
  // SET_TYPE_POPUP,
  // GET_ACTIVATION_DETAIL_SUCCESS,
} from './actionTypes'

const INIT_STATE = {
  contents: [],
  reconcileProgramByAreas: [],
  programByAreaAndRewardTypes: [],
  programBonusTempDetail: undefined,
  value: undefined,
  meta: undefined,
  type: 'create',
  loading: true,
  total: 0
}

const ProgramBonusTempReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PROGRAM_BONUS_TEMP_SUCCESS:
      return {
        ...state,
        contents: action.payload.data,
        total: action.payload.meta.totalItems,
        loading: true
      }

    case GET_PROGRAM_BONUS_TEMP_FAIL:
      return {
        ...state,
        error: action.payload
      }

    case GET_RECONCILE_PROGRAM_BY_AREA_SUCCESS:
      return {
        ...state,
        reconcileProgramByAreas: action.payload.data,
        total: action.payload.meta.totalItems,
        value: action.payload.value,
        meta: action.payload.meta,
        loading: true
      }

    case GET_RECONCILE_PROGRAM_BY_AREA_FAIL:
      return {
        ...state,
        error: action.payload
      }

    case GET_PROGRAM_BY_AREA_AND_REWARD_TYPE_SUCCESS:
      return {
        ...state,
        programByAreaAndRewardTypes: action.payload.data,
        total: action.payload.meta.totalItems,
        value: action.payload.value,
        meta: action.payload.meta,
        loading: true
      }

    case GET_PROGRAM_BY_AREA_AND_REWARD_TYPE_FAIL:
      return {
        ...state,
        error: action.payload
      }

    // case UPDATE_MANAGE_ACTIVATION_SUCCESS:
    //     return {
    //         ...state,
    //         shops: state.shops.map(manageActivation =>
    //             manageActivation.id.toString() === action.payload.id.toString() ? { manageActivation, ...action.payload } : manageActivation
    //         ),
    //     }

    // case UPDATE_MANAGE_ACTIVATION_FAIL:
    //     return {
    //         ...state,
    //         error: action.payload,
    //     }

    default:
      return state
  }
}

export default ProgramBonusTempReducer
