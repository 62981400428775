import React from "react"
import { Badge, UncontrolledTooltip } from "reactstrap"
import { Link } from "react-router-dom"
import { UserVN } from "constants/vn"
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"

const convertLocationTypeToMaxLength = (type) => {
    switch (type) {
        case 'zone':
            return 1;
        case 'subzone':
            return 2;
        case 'province':
            return 2;
        case 'district':
            return 3;
        case 'ward':
            return 5;
        default:
            return 1;
    }
}
const handleFormat = (value, viewLength) => {
    return (value).toLocaleString('en-US', { minimumIntegerDigits: viewLength, useGrouping: false })
}

const UserNo = cell => {
    return (
        <Link to="#" className="text-body fw-bold">
            {cell?.row ? cell.row.index + 1 : ""}
        </Link>
    )
}

const Code = (props) => {
    return props.value ? handleFormat(props.value, convertLocationTypeToMaxLength(props.row.original.type)) : "--"
}

const Name = cell => {
    return cell.value ? cell.value : "--"
}

const GeographicalLevel = cell => {
    switch (cell.value) {
        case "zone":
            return 'Miền'
        case "subzone":
            return 'Vùng'
        case "province":
            return 'Tỉnh/Thành phố'
        case "district":
            return 'Quận/Huyện'
        case "ward":
            return 'Phường/Xã'
        default:
            return "--"
    }
}

const Domain = cell => {
    return cell.value ? cell.value.name : "--"
}

const Zone = cell => {
    return cell.value ? cell.value.name : "--"
}

const City = cell => {
    return cell.value ? cell.value.name : "--"
}

const District = cell => {
    return cell.value ? cell.value.name : "--"
}




export { UserNo, Code, Name, GeographicalLevel, Domain, Zone, City, District }
