import {
  GET_PROGRAM_BONUS_TEMP,
  GET_PROGRAM_BONUS_TEMP_FAIL,
  GET_PROGRAM_BONUS_TEMP_SUCCESS,
  GET_RECONCILE_PROGRAM_BY_AREA,
  GET_RECONCILE_PROGRAM_BY_AREA_SUCCESS,
  GET_RECONCILE_PROGRAM_BY_AREA_FAIL,
  GET_PROGRAM_BY_AREA_AND_REWARD_TYPE,
  GET_PROGRAM_BY_AREA_AND_REWARD_TYPE_SUCCESS,
  GET_PROGRAM_BY_AREA_AND_REWARD_TYPE_FAIL

  // SET_TYPE_POPUP,
} from './actionTypes'

export const getProgramBonusTempList = (params) => ({
  type: GET_PROGRAM_BONUS_TEMP,
  payload: params
})

export const getProgramBonusTempSuccess = (data) => ({
  type: GET_PROGRAM_BONUS_TEMP_SUCCESS,
  payload: data
})

export const getProgramBonusTempFail = (error) => ({
  type: GET_PROGRAM_BONUS_TEMP_FAIL,
  payload: error
})

export const getReconcileProgramByArea = (params) => ({
  type: GET_RECONCILE_PROGRAM_BY_AREA,
  payload: params
})

export const getReconcileProgramByAreaSuccess = (data) => ({
  type: GET_RECONCILE_PROGRAM_BY_AREA_SUCCESS,
  payload: data
})

export const getReconcileProgramByAreaFail = (error) => ({
  type: GET_RECONCILE_PROGRAM_BY_AREA_FAIL,
  payload: error
})

export const getProgramByAreaAndRewardType = (params) => ({
  type: GET_PROGRAM_BY_AREA_AND_REWARD_TYPE,
  payload: params
})

export const getProgramByAreaAndRewardTypeSuccess = (data) => ({
  type: GET_PROGRAM_BY_AREA_AND_REWARD_TYPE_SUCCESS,
  payload: data
})

export const getProgramByAreaAndRewardTypeFail = (error) => ({
  type: GET_PROGRAM_BY_AREA_AND_REWARD_TYPE_FAIL,
  payload: error
})

// export const setTypePopupActivation = params => ({
//     type: SET_TYPE_POPUP,
//     payload: params,
// })
