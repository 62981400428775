import React, { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'

//import components
import Breadcrumbs from 'components/Common/Breadcrumb'

import { getManageBaner as onGetManageBaner } from 'store/actions'

import { Priority, AnnounceName, AnnounceDuration } from './ManageBanerCol'

//redux
import { useSelector, useDispatch } from 'react-redux'
import { createSelector } from 'reselect'

import { Col, Row, UncontrolledTooltip, Card, CardBody } from 'reactstrap'
import Spinners from 'components/Common/Spinner'
import { toast } from 'react-toastify'
import TableContainer from 'components/Common/TableContainer'
import { ManageContentVN } from 'constants/vn'
import { Image } from 'antd'
import { useNavigate } from 'react-router-dom'
import { BANNER_MANAGEMENT_CODE, SCREEN_CODE } from 'constants/permission-code'
import PermissionWrapper from 'components/Common/PermissionWrapper'
import { getStorage, setStorage } from 'helpers/global_helper'

function ManageBaner() {
  //meta title
  document.title = 'Banner List | HPG'
  const screenCode = useMemo(() => SCREEN_CODE.BANNER_MANAGEMENT, [])

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const selectShopsState = (state) => state.BanerReducer
  const ShopsProperties = createSelector(selectShopsState, (banerReducer) => ({
    shops: banerReducer.shops,
    loading: banerReducer.loading,
    total: banerReducer.total
  }))

  const { shops, loading, total } = useSelector(ShopsProperties)
  const [isLoading, setLoading] = useState(loading)
  const [filter, setFilter] = useState()
  const [page, setPage] = useState(1)

  useEffect(() => {
    const filterManageBanner = getStorage('filterManageBanner')
    dispatch(onGetManageBaner({ ...filterManageBanner }))
  }, [dispatch])

  const columns = useMemo(
    () => [
      {
        Header: 'Thứ tự hiển thị',
        accessor: 'priority',
        filterable: false,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <Priority {...cellProps} />
        }
      },
      {
        Header: 'Tên bài viết',
        accessor: 'announce',
        id: 'AnnounceName',
        filterable: false,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <AnnounceName {...cellProps} />
        }
      },
      {
        Header: 'Ảnh',
        accessor: 'announce',
        id: 'AnnounceImage',
        filterable: false,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          if (cellProps?.value?.image) {
            return <Image width={120} height={60} style={{ objectFit: 'cover' }} src={cellProps.value.image} />
          } else {
            return '--'
          }
        }
      },
      {
        Header: 'Thời gian hiển thị',
        accessor: 'announce',
        id: 'AnnounceDuration',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <AnnounceDuration {...cellProps} />
        }
      },
      {
        Header: 'Thao tác',
        accessor: 'action',
        disableFilters: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return (
            <ul className='list hstack gap-1 mb-0'>
              <PermissionWrapper screenCode={screenCode} code={BANNER_MANAGEMENT_CODE.ADMIN_DETAIL_BANNER}>
                <li data-bs-toggle='tooltip' data-bs-placement='top' title='View'>
                  <Link
                    to={`/manage-content/view/${cellProps.row.original.announceId}`}
                    className='btn btn-sm btn-soft-primary'
                    id={`viewtooltip-${cellProps.row.original.id}`}
                  >
                    <i className='mdi mdi-eye-outline' />
                    <UncontrolledTooltip placement='top' target={`viewtooltip-${cellProps.row.original.id}`}>
                      Xem
                    </UncontrolledTooltip>
                  </Link>
                </li>
              </PermissionWrapper>
            </ul>
          )
        }
      }
    ],
    []
  )

  const setGlobalFilterUser = async (data) => {
    data.start_date = data.start_date?.startOf('day')?.toDate()
    data.end_date = data.end_date?.endOf('day')?.toDate()

    setStorage('filterManageBanner', data)

    setFilter({ ...data, page: 1 })
    dispatch(onGetManageBaner(data))
    setPage(1)
  }

  const goToPage = (page) => {
    dispatch(onGetManageBaner({ ...filter, page }))
    setPage(page)
  }

  return (
    <React.Fragment>
      <div className='page-content'>
        <div className='page-title-box container-fluid'>
          <h4 className=' mb-sm-0 font-size-18 pb-4'>{'Quản lý Banner'}</h4>
          {isLoading ? (
            <Spinners setLoading={setLoading} />
          ) : (
            <Row>
              <Col lg='12'>
                <Card>
                  <CardBody className='border-bottom'>
                    <div className='d-flex align-items-center'>
                      <h5 className='mb-0 card-title flex-grow-1'>{'Danh sách banner'}</h5>
                      <div className='flex-shrink-0'>
                        <PermissionWrapper screenCode={screenCode} code={BANNER_MANAGEMENT_CODE.ADMIN_UPDATE_BANNER}>
                          <button
                            onClick={() => {
                              navigate('/manage-banner/config')
                            }}
                            className='btn btn-primary'
                          >
                            {/* <i className="mdi mdi-plus me-1" /> */}
                            {'Chỉnh sửa'}
                          </button>
                        </PermissionWrapper>
                      </div>
                    </div>
                  </CardBody>
                  <CardBody>
                    <TableContainer
                      columns={columns}
                      data={shops}
                      isGlobalFilter={true}
                      setGlobalFilterUser={setGlobalFilterUser}
                      placeholderSearch={'Nhập tên bài viết'}
                      isManageBanerGlobalFilter={true}
                      isPagination={true}
                      iscustomPageSizeOptions={false}
                      isShowingPageLength={false}
                      customPageSize={10}
                      tableClass='table-bordered align-middle nowrap mt-2'
                      paginationDiv='col-sm-12 col-md-7'
                      pagination='pagination justify-content-end pagination-rounded'
                      useAPIChangePaging
                      currentPage={page}
                      gotoPageCustom={goToPage}
                      total={total}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </div>
      </div>
    </React.Fragment>
  )
}

export default ManageBaner
