import PropsTypes from 'prop-types'
import { useCallback, useMemo, useState } from 'react'
import Carousel, { ModalGateway, Modal as ModalLightBox } from 'react-images'
import Gallery from 'react-photo-gallery'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import React from 'react'

ImageGalleryModal.propsType = {
  show: PropsTypes.bool.isRequired,
  onClose: PropsTypes.func.isRequired,
  photos: PropsTypes.string.isRequired
}

function ImageGalleryModal({ show, onClose, photos }) {
  const [currentImage, setCurrentImage] = useState(0)
  const [viewerIsOpen, setViewerIsOpen] = useState(false)
  const photoTransformed = useMemo(
    () =>
      photos.map((photo) => ({
        src: photo,
        width: 1,
        height: 1
      })),
    [, [photos]]
  )

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index)
    setViewerIsOpen(true)
  }, [])

  const closeLightbox = () => {
    setCurrentImage(0)
    setViewerIsOpen(false)
  }

  return (
    <Modal size='lg' scrollable isOpen={show} toggle={onClose} centered={true}>
      <ModalHeader className='p-4 font-weight-normal' toggle={onClose}>
        Danh sách ảnh trưng bày
      </ModalHeader>
      <div className='modal-content'>
        <ModalBody className='p-4 text-start'>
          <div>
            <Gallery photos={photoTransformed} onClick={openLightbox} />
            <ModalGateway>
              {viewerIsOpen ? (
                <ModalLightBox onClose={closeLightbox}>
                  <Carousel
                    currentIndex={currentImage}
                    views={photoTransformed.map((x) => ({
                      ...x,
                      srcset: x.srcSet,
                      caption: x.title
                    }))}
                  />
                </ModalLightBox>
              ) : null}
            </ModalGateway>
          </div>
        </ModalBody>
      </div>
    </Modal>
  )
}

export default ImageGalleryModal
