import {
    GET_MANAGE_CONTENT,
    GET_MANAGE_CONTENT_FAIL,
    GET_MANAGE_CONTENT_SUCCESS,
    GET_CONTENT_DETAIL,
    GET_CONTENT_DETAIL_SUCCESS,
    GET_CONTENT_DETAIL_FAIL,
    GET_KTVS,
} from "./actionTypes"

export const getManageContent = params => ({
    type: GET_MANAGE_CONTENT,
    payload: params,
})

// export const getKTVList = params => ({
//     type: GET_KTVS,
//     payload: params,
// })

export const getManageContentSuccess = jobs => ({
    type: GET_MANAGE_CONTENT_SUCCESS,
    payload: jobs,
})

export const getManageContentFail = error => ({
    type: GET_MANAGE_CONTENT_FAIL,
    payload: error,
})

export const getContentDetail = data => ({
    type: GET_CONTENT_DETAIL,
    payload: data,
})

export const getContentDetailSuccess = job => ({
    type: GET_CONTENT_DETAIL_SUCCESS,
    payload: job,
})

export const getContentDetailFail = error => ({
    type: GET_CONTENT_DETAIL_FAIL,
    payload: error,
})
