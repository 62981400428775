import React, { useEffect, useState } from 'react'
import { Col, Label } from 'reactstrap'

//flatpickr
import 'flatpickr/dist/themes/material_blue.css'
import { GuestVN } from 'constants/vn'
import { FormSelect } from './form-select/FormSelect'
import { getProvinceAPI, getDistrictAPI, getWardAPI } from 'helpers/backend_helper'
import { MAX_LENGTH_SEARCH } from 'constants/constants'
import { FormInput } from './form-input/FormInput'

const GuestListGlobalFilter = ({ setGlobalFilter, search, setSearch }) => {
  const [filter, setFilter] = useState({})
  const [subZone, setSubZone] = useState([])
  const [district, setDistrict] = useState([])
  const [ward, setWard] = useState([])

  useEffect(() => {
    handleGetSubZone()
    handleGetDistrict()
    handleGetWard()
  }, [])

  const handleSelect = (value, key) => {
    setFilter((e) => ({ ...e, [key]: value }))
  }

  const handleGetSubZone = async () => {
    const res = await getProvinceAPI()

    if (res.data) {
      setSubZone(res.data)
    }
  }

  const handleGetDistrict = async () => {
    const res = await getDistrictAPI()

    if (res.data) {
      setDistrict(res.data)
    }
  }

  const handleGetWard = async () => {
    const res = await getWardAPI()

    if (res.data) {
      setWard(res.data)
    }
  }

  return (
    <React.Fragment>
      <Col xxl={2} lg={2} className='mt-3'>
        <Label className='form-label'>Nhập tên</Label>
        <FormInput
          className='w-100'
          maxLength={MAX_LENGTH_SEARCH}
          placeholder='Nhập tên '
          value={filter?.search || ''}
          name='search'
          id={`input-filter`}
          onChange={(e) => handleSelect(e.target.value, 'search')}
          onBlur={(e) => handleSelect(e.target.value, 'search')}
        />
      </Col>

      <Col xxl={2} lg={2} className='mt-3'>
        <Label className='form-label'>Nhập số điện thoại</Label>
        <FormInput
          className='w-100'
          maxLength={MAX_LENGTH_SEARCH}
          placeholder='Nhập tên '
          value={filter?.phone || ''}
          name='phone'
          id={`input-filter`}
          onChange={(e) => handleSelect(e.target.value, 'phone')}
          onBlur={(e) => handleSelect(e.target.value, 'phone')}
        />
      </Col>
      <Col xxl={2} lg={2} className='mt-3'>
        <Label className='form-label'>{GuestVN.filters.city.label}</Label>
        <FormSelect
          options={subZone.map((e) => ({ label: e.name, value: e.id }))}
          value={filter.province_id}
          onChange={(value) => {
            handleSelect(value, 'province_id')
            handleSelect(undefined, 'district_id')
            handleSelect(undefined, 'ward_id')
          }}
          placeholder={GuestVN.filters.city.placeholder}
        />
      </Col>
      <Col xxl={2} lg={2} className='mt-3'>
        <Label className='form-label'>{GuestVN.filters.district.label}</Label>
        <FormSelect
          options={district
            .filter((e) => e.parentId == filter.province_id)
            .map((e) => ({ label: e.name, value: e.id }))}
          value={filter.district_id}
          onChange={(value) => {
            handleSelect(value, 'district_id')
            handleSelect(undefined, 'ward_id')
          }}
          placeholder={GuestVN.filters.district.placeholder}
          disabled={!filter.province_id}
        />
      </Col>
      <Col xxl={2} lg={2} className='mt-3'>
        <Label className='form-label'>{GuestVN.filters.ward.label}</Label>
        <FormSelect
          options={ward.filter((e) => e.parentId == filter.district_id).map((e) => ({ label: e.name, value: e.id }))}
          value={filter.ward_id}
          onChange={(value) => {
            handleSelect(value, 'ward_id')
          }}
          placeholder={GuestVN.filters.ward.placeholder}
          disabled={!filter.province_id}
        />
      </Col>
      <Col xxl={2} lg={4} className='d-flex align-items-end justify-content mt-3 '>
        <div className='mb-xxl-0 me-2'>
          <button
            type='button'
            className='btn btn-soft-danger w-100'
            onClick={() => {
              setFilter({
                status: undefined,
                province_id: undefined,
                search: '',
                phone: ''
              })
              setSearch('')
              setGlobalFilter({})
            }}
          >
            Xóa tìm kiếm
          </button>
        </div>
        <div className='mb-xxl-0'>
          <button
            type='button'
            className='btn btn-primary w-100'
            onClick={() => {
              const _filter = !filter
                ? filter
                : Object.fromEntries(Object.entries(filter).filter(([key, value]) => value !== undefined))
              setGlobalFilter({ ..._filter, search })
            }}
          >
            Tìm kiếm
          </button>
        </div>
      </Col>
    </React.Fragment>
  )
}
export default GuestListGlobalFilter
