import { CommonVN, ProductVN } from 'constants/vn'
import React, { useEffect, useMemo, useState } from 'react'
import { Col, Label, Modal, ModalBody, Row } from 'reactstrap'

// Formik validation
import { createSelector } from 'reselect'
import { useSelector } from 'react-redux'
import { filterDataHaveValue } from 'helpers/global_helper'
import { getProductList, setTypePopupProduct } from 'store/actions'
import {
  getChannelAPI,
  getPortfolioAllAPI,
  getPortfolioDetailsAPI,
  postProductAPI,
  updateProductAPI,
  checkSerialAPI,
  postPointConfigByTimeAPI,
  getRankConfigDetailsAPI,
  getPointConfigByTimeDetailAPI,
  updatePointConfigByTimeAPI
} from 'helpers/backend_helper'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { FormSelect } from 'components/Common/form-select/FormSelect'
import { DatePicker, Form, Spin } from 'antd'
import { FormInput } from 'components/Common/form-input/FormInput'
import { isEqual } from 'lodash'
import dayjs from 'dayjs'
import { MAX_LENGTH_50 } from 'constants/constants'
import { FormDate } from 'components/Common/form-date/FormDate'
import ConfirmModal from 'components/Common/ConfirmModal'
import { InputNumber } from 'antd'
import { FormInputNumber } from 'components/Common/form-input-number/FormInputNumber'
import { FormDateRange } from 'components/Common/form-date-range/FormDateRange'

const AddEditConfigPointByTime = ({ show, onCloseClick, selected, setPage, type, currentData, handleGetListTable }) => {
  const [form] = Form.useForm()
  const watchModel = Form.useWatch('model', form)
  const watchForm = Form.useWatch([], form)
  const [submittable, setSubmittable] = useState()
  const [model, setModel] = useState()
  const [channel, setChannel] = useState()
  const [initData, setInitData] = useState({})
  const [loading, setLoading] = useState(false)
  const [onCancel, setOnCancel] = useState(false)
  const [industry, setIndustry] = useState([])
  const [industrySector, setIndustrysector] = useState([])
  const [category, setCategory] = useState([])
  const [onCheckSeriral, setOnCheckSerial] = useState(false)
  const [industryOptions, setIndustryOptions] = useState()
  const [industrySelected, setIndustrySelected] = useState([])
  const [industrySectorSelected, setIndustrySectorSelected] = useState([])
  const [categorySelected, setCategorySelected] = useState([])

  const dispatch = useDispatch()

  const selectProductsState = (state) => state.ProductReducer
  const ProductsProperties = createSelector(selectProductsState, (reducer) => ({
    productDetail: reducer.productDetail
  }))
  const { productDetail } = useSelector(ProductsProperties)

  const selectLayoutState = (state) => state.Layout
  const LayoutProperties = createSelector(selectLayoutState, (layout) => ({
    filter: layout.filter,
    zone: layout.zone
  }))
  const { filter, zone } = useSelector(LayoutProperties)

  const isEdit = type !== 'view'
  useEffect(() => {
    getDataOptions()
  }, [])

  useEffect(() => {
    if (currentData) {
      handleGetDetail()
    }
  }, [type])

  // const data = useMemo(() => {
  //   if (type !== 'create') {
  //     return productDetail
  //   } else {
  //     return undefined
  //   }
  // }, [type, productDetail])

  const mapsData = (data) => {
    return {
      serial: data.serial,
      zone: data.zoneId,
      industry: data?.industry?.name ?? undefined,
      industrySector: data?.industrySector?.name ?? undefined,
      category: data?.category?.name ?? undefined,
      model: data?.modelId,
      channel: data?.channelId,
      exportDate: dayjs(data?.exportDate),
      type: data?.type ? data?.type : undefined
    }
  }

  const getDataOptions = async () => {
    try {
      const resModel = await getPortfolioAllAPI({ type: 'model' })
      const resChannel = await getChannelAPI({ sort_order: 'ASC' })
      setModel(resModel?.data)
      setChannel(resChannel?.data)
      const resIndustry = await getPortfolioAllAPI({ type: 'industry' })
      setIndustry(resIndustry?.data)
      const resIndustrysector = await getPortfolioAllAPI({ type: 'industrySector' })
      setIndustrysector(resIndustrysector?.data)
      const resCategory = await getPortfolioAllAPI({ type: 'category' })
      setCategory(resCategory?.data)

      if (resIndustry?.data?.length > 0) {
        setIndustryOptions(
          resIndustry?.data?.map((item) => ({
            value: item.id,
            label: item.name
          }))
        )
      } else {
        setIndustryOptions([])
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleGetDetail = async () => {
    try {
      if (type !== 'create') {
        const res = currentData
        if (res) {
          form.setFieldsValue({
            point: res?.point,
            model: res?.modelId,
            modelName: res?.model?.name,
            category: res?.category?.id,
            categoryName: res?.category?.name,
            industrySector: res?.industrySector?.id,
            industrySectorName: res?.industrySector?.name,
            industry: res?.industry?.id,
            industryName: res?.industry?.name,
            applyDate: [dayjs(res?.startDate), dayjs(res?.endDate)]
          })
          setIndustrySelected([res?.industry?.id])
          setIndustrySectorSelected([res?.industrySector?.id])
          setCategorySelected([res?.category?.id])
        }
      } else {
        form.resetFields()
      }
    } catch (error) {
      toast(error.response.data.message, { type: 'error' })
    }
  }

  const onFinish = async () => {
    const values = form.getFieldsValue()
    setLoading(true)
    if (type !== 'view') {
      try {
        if (type === 'edit') {
          const res = await updatePointConfigByTimeAPI({
            id: currentData.id,
            point: values?.point,
            model_id: values?.model,
            start_date: values?.applyDate?.[0],
            end_date: values?.applyDate?.[1]
          })
          if (res) {
            toast('Thông tin điểm được cập nhật thành công', { type: 'success' })
            handleGetListTable()
            // dispatch(getProductList(filter))
          }
        }
        if (type === 'create') {
          const res = await postPointConfigByTimeAPI({
            point: values?.point,
            model_id: values?.model,
            start_date: values?.applyDate?.[0],
            end_date: values?.applyDate?.[1]
          })
          if (res) {
            toast('Điểm được tạo mới thành công', { type: 'success' })
            // dispatch(getProductList())
            handleGetListTable()
            setPage(1)
          }
        }
        onCloseClick()
      } catch (error) {
        toast(error.response.data.message, { type: 'error' })
      }
      setLoading(false)
    } else {
      // dispatch(setTypePopupProduct('edit'))
      setLoading(false)
    }
    setOnCheckSerial(false)
  }

  const getModelDetail = async () => {
    const res = await getPortfolioDetailsAPI({ id: watchModel, type: 'model' })
    form.setFieldValue('industry', res.data.industry.name)
    form.setFieldValue('industrySector', res.data.industrySector.name)
    form.setFieldValue('category', res.data.category.name)
  }

  useEffect(() => {
    if (watchModel) {
      getModelDetail()
    }
  }, [watchModel])

  useEffect(() => {
    form.validateFields({ validateOnly: true }).then(
      () => {
        setSubmittable(true)
      },
      () => {
        setSubmittable(false)
      }
    )
  }, [watchForm])

  // useEffect(() => {
  // if (show) {
  //   getDataOptions()
  // }
  // form.resetFields()
  // }, [show, type])

  const onClickCancel = () => {
    setOnCancel(false)
    onCloseClick()
  }

  const checkClickCancel = () => {
    if (type != 'view') {
      if (type == 'create') {
        if (form.isFieldsTouched()) {
          setOnCancel(true)
        } else {
          onCloseClick()
        }
      } else {
        if (!isEqual(initData, watchForm)) {
          setOnCancel(true)
        } else {
          onCloseClick()
        }
      }
    } else {
      onCloseClick()
    }
  }

  return (
    <Modal
      size='lg'
      isOpen={show}
      toggle={() => {
        checkClickCancel()
      }}
      centered={true}
    >
      <div className='modal-content'>
        <ModalBody className='p-4 text-start'>
          <Spin spinning={loading}>
            <button type='button' onClick={checkClickCancel} className='btn-close position-absolute end-0 top-0' />
            <h2>
              {type === 'create'
                ? 'Thêm mới cấu hình điểm'
                : type === 'edit'
                ? 'Chỉnh sửa cấu hình điểm'
                : 'Thông tin cấu hình điểm'}
            </h2>
            <Form onFinish={onFinish} autoComplete='off' form={form} name='addEditProductForm'>
              <div className='form-group pt-4'>
                <Row>
                  <Col sm='6'>
                    <Label className='form-label'>
                      {'Ngành'}
                      {type !== 'view' && <a className='text-danger'>*</a>}
                    </Label>
                    <Form.Item name='industry' rules={[{ required: true, message: 'Vui lòng chọn ngành' }]}>
                      <FormSelect
                        options={industryOptions}
                        value={filter.industry_ids}
                        placeholder='Chọn Ngành'
                        onChange={(value) => {
                          setIndustrySelected([value])
                          setIndustrySectorSelected([])
                          setCategorySelected([])
                          form.setFieldsValue({
                            model: undefined,
                            modelName: undefined,
                            category: undefined,
                            categoryName: undefined,
                            industrySector: undefined,
                            industrySectorName: undefined
                          })
                          form.setFieldValue('industry', value)
                        }}
                        disabled={!isEdit}
                      />
                    </Form.Item>
                  </Col>
                  <Col sm='6'>
                    <Label className='form-label'>
                      {'Ngành hàng'}
                      {type !== 'view' && <a className='text-danger'>*</a>}
                    </Label>
                    <Form.Item name='industrySector' rules={[{ required: true, message: 'Vui lòng chọn ngành hàng' }]}>
                      <FormSelect
                        showSearch
                        options={
                          industrySector?.filter((e) => industrySelected?.includes(e.parentId))?.length > 0
                            ? industrySector
                                ?.filter((e) => industrySelected?.includes(e.parentId))
                                ?.map((item) => ({
                                  value: item.id,
                                  label: item.name
                                }))
                            : []
                        }
                        onChange={(value) => {
                          setIndustrySectorSelected([value])
                          setCategorySelected([])
                          form.setFieldsValue({
                            model: undefined,
                            modelName: undefined,
                            category: undefined,
                            categoryName: undefined
                          })
                          form.setFieldValue('industrySector', value)
                        }}
                        disabled={!isEdit}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col sm='6'>
                    <Label className='form-label'>
                      {'Nhóm sản phẩm'}
                      {type !== 'view' && <a className='text-danger'>*</a>}
                    </Label>
                    <Form.Item name='category' rules={[{ required: true, message: 'Vui lòng chọn ngành hàng' }]}>
                      <FormSelect
                        options={
                          category?.filter((e) => industrySectorSelected?.includes(e?.parentId))?.length > 0
                            ? category
                                ?.filter((e) => industrySectorSelected?.includes(e?.parentId))
                                .map((item) => ({
                                  value: item.id,
                                  label: item.name
                                }))
                            : []
                        }
                        onChange={(value) => {
                          setCategorySelected([value])
                          form.setFieldsValue({
                            model: undefined,
                            modelName: undefined
                          })
                          form.setFieldValue('category', value)
                        }}
                        disabled={!isEdit}
                      />
                    </Form.Item>
                  </Col>
                  <Col sm='6'>
                    <Label className='form-label'>
                      {ProductVN.form.model.label}
                      <a className='text-danger'>*</a>
                    </Label>
                    <Form.Item name='model' rules={[{ required: true, message: 'Vui lòng chọn model' }]}>
                      <FormSelect
                        placeholder={ProductVN.form.model.placeholder}
                        options={
                          model?.filter((e) => categorySelected?.includes(e.parentId))?.length > 0
                            ? model
                                ?.filter((e) => categorySelected?.includes(e.parentId))
                                ?.map((item) => ({
                                  value: item.id,
                                  label: item.name
                                }))
                            : []
                        }
                        onChange={(value) => {
                          form.setFieldValue('model', value)
                        }}
                        disabled={!isEdit}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col sm='6'>
                    <Label className='form-label'>
                      {'Thời gian áp dụng'}
                      <a className='text-danger'>*</a>
                    </Label>
                    <Form.Item name='applyDate' rules={[{ required: true, message: 'Vui lòng chọn thời áp dụng' }]}>
                      <FormDateRange disabledDate={(current) => dayjs().isAfter(current, 'day')} disabled={!isEdit} />
                    </Form.Item>
                  </Col>
                  <Col sm='6'>
                    <Label className='form-label'>
                      {'Điểm'}
                      <a className='text-danger'>*</a>
                    </Label>
                    <Form.Item name='point' rules={[{ required: true, message: 'Vui lòng nhập điểm' }]}>
                      <FormInputNumber disabled={!isEdit} placeholder={'Nhập số điểm'} />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
              <div className='hstack gap-2 justify-content-center mt-4'>
                <button type='button' className='btn btn-soft-secondary w-25 ' onClick={checkClickCancel}>
                  Hủy
                </button>
                {type == 'view' ? (
                  <button
                    disabled={productDetail?.status != 'active' || productDetail?.extTransId}
                    type={'submit'}
                    className='btn btn-primary w-25'
                  >
                    {'Chỉnh sửa'}
                  </button>
                ) : (
                  <button
                    disabled={type !== 'view' && (!submittable || isEqual(initData, watchForm))}
                    type={'submit'}
                    className='btn btn-primary w-25'
                  >
                    {isEdit ? 'Lưu' : 'Chỉnh sửa'}
                  </button>
                )}
              </div>
            </Form>
          </Spin>
        </ModalBody>
        <ConfirmModal
          show={onCancel}
          onConfirmClick={onClickCancel}
          onCloseClick={() => setOnCancel(false)}
          title={`Xác nhận`}
          description={`Thay đổi sẽ không được lưu. Bạn có chắc chắn muốn thoát không?`}
        />
        <ConfirmModal
          show={onCheckSeriral}
          onConfirmClick={onFinish}
          // onCloseClick={() => setOnCheckSerial(false)}
          title={`Xác nhận trùng serial`}
          description={`Serial sản phẩm đã tồn tại trên hệ thống.`}
        />
      </div>
    </Modal>
  )
}
export default AddEditConfigPointByTime
