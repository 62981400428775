import { call, put, takeEvery } from 'redux-saga/effects'

import { GET_MANAGE_CONTACT, GET_CONTACT_DETAIL } from './actionTypes'
import {
  getManageBankSuccess,
  getManageBankFail,
  getBankDetailSuccess,
  getManageContactSuccess,
  getManageContactFail,
  getContactDetailSuccess,
  getContactDetailFail
} from './actions'

import { getContactDetailAPI, getContactListAPI } from 'helpers/backend_helper'

function* fetchContactList({ payload: params }) {
  try {
    const response = yield call(getContactListAPI, { ...params })
    console.log(response)
    yield put(getManageContactSuccess(response))
  } catch (error) {
    yield put(getManageContactFail(error?.data?.message))
  }
}

function* fetchContactDetail({ payload: data }) {
  try {
    const response = yield call(getContactDetailAPI, { id: data.id, type: data.type })
    yield put(getContactDetailSuccess(response?.data))
  } catch (error) {
    console.log(error)
    yield put(getContactDetailFail(error))
  }
}

function* ContactsSaga() {
  yield takeEvery(GET_MANAGE_CONTACT, fetchContactList)
  yield takeEvery(GET_CONTACT_DETAIL, fetchContactDetail)
}

export default ContactsSaga
