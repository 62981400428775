import { Card, CardBody, Col, Container, Label, Row } from 'reactstrap'
import Breadcrumb from 'components/Common/Breadcrumb'
import { ManageContentVN, UnitVN, UserVN } from 'constants/vn'
import { FormSelect } from 'components/Common/form-select/FormSelect'

import { Button, Form, Input, Popconfirm, Table, Tooltip, Select } from 'antd'
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { ClassStructureVN } from 'constants/vn'
import {
  getPortfolioAllAPI,
  getRankAllAPI,
  postCreateRankConfigAPI,
  updateRankConfigAPI,
  getRankConfigDetailsAPI,
  deleteRankItemConfigAPI
} from 'helpers/backend_helper'
import { OptionTypeAccount } from '../../constants/index'
import { toast } from 'react-toastify'
import withRouter from 'components/Common/withRouter'
import { v4 as uuidv4 } from 'uuid'
import ConfirmModal from 'components/Common/ConfirmModal'
import { isEqual, set } from 'lodash'
import { FormDateRange } from 'components/Common/form-date-range/FormDateRange'
import dayjs from 'dayjs'
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'

const AddEditClassStructure = (props) => {
  const selectLayoutState = (state) => state.Layout
  const LayoutProperties = createSelector(selectLayoutState, (app) => ({
    province: app.province,
    district: app.district,
    ward: app.ward,
    zone: app.zone,
    subzone: app.subzone
  }))
  const { province, district, ward, zone, subzone } = useSelector(LayoutProperties)

  document.title = 'Class Structure | HPG'

  const params = props.router.params
  const navigate = props.router.navigate

  const [form] = Form.useForm()
  const watchForm = Form.useWatch([], form)
  const [type, setType] = useState('create')
  const [status, setStatus] = useState('create')
  const [dataSource, setDataSource] = useState([
    {
      key: 0,
      rank_id: 'Chưa đạt hạng',
      point: 0
    },
    {
      key: 1,
      rank_id: '',
      point: 0
    },
    {
      key: 2,
      rank_id: '',
      point: 0
    },
    {
      key: 3,
      rank_id: '',
      point: 0
    }
  ])
  const [count, setCount] = useState(4)
  const [optionsPortfolio, setOptionsPortfolio] = useState([])
  const [optionsRank, setOptionsRank] = useState([])
  const [valueType, setValueType] = useState()
  const [dataTable, setDataTable] = useState([
    {
      key: 1,
      rank_id: '',
      point: 0,
      isChange: false
    },
    {
      key: 2,
      rank_id: '',
      point: 0,
      isChange: false
    },
    {
      key: 3,
      rank_id: '',
      point: 0,
      isChange: false
    }
  ])
  const [dataDetail, setDataDetail] = useState()
  const [disabledSubmit, setDisabledSubmit] = useState(true)
  const [showBlockEdit, setShowBlockEdit] = useState(false)
  const [deleteItem, setDeleteItem] = useState()
  const [showSubmitCancel, setShowSubmitCancel] = useState(false)
  const [initData, setInitData] = useState()
  const [errDataTable, setErrDataTable] = useState(true)

  useEffect(() => {
    if (props.router.location.pathname) {
      const split = props.router.location.pathname.split('/')
      if (split) {
        setType(split[2])
      }
    }
  }, [props])

  useEffect(() => {
    if (params.id) {
      handleGetDetail()
    }
  }, [type])

  useEffect(() => {
    handleGetOptionsPortfolio()
  }, [])

  useEffect(() => {
    if (valueType) {
      handleGetRank()
    }
  }, [valueType])

  useEffect(() => {
    form.validateFields({ validateOnly: true }).then(
      () => {
        setDisabledSubmit(false)
      },
      () => {
        setDisabledSubmit(true)
      }
    )
  }, [watchForm])

  useEffect(() => {
    if (dataTable) {
      handleCheckRankIdAll()
      handleCheckPointAll()
      checkErrDataTable()
    }
  }, [dataTable, watchForm])

  const zoneOptions = useMemo(
    () => [{ label: 'Tất cả', value: 'all' }].concat(zone?.map((e) => ({ label: e.name, value: e.id }))),
    [zone]
  )

  const subzoneOptions = useMemo(() => {
    const listFilter = subzone
      ?.filter((e) => watchForm?.zone?.includes(e.zoneId))
      ?.map((e) => ({ label: e.name, value: e.id }))
    return listFilter?.length > 0 ? [{ label: 'Tất cả', value: 'all' }].concat(listFilter) : []
  }, [subzone, watchForm])

  const provinceOptions = useMemo(() => {
    const listFilter = province
      ?.filter((e) => watchForm?.subzone?.includes(e.subZoneId))
      ?.map((e) => ({ label: e.name, value: e.id }))
    return listFilter.length > 0 ? [{ label: 'Tất cả', value: 'all' }].concat(listFilter) : []
  }, [province, watchForm])

  const districtOptions = useMemo(() => {
    const listFilter = district
      ?.filter((e) => watchForm?.province?.includes(e.parentId))
      ?.map((e) => ({ label: e.name, value: e.id }))
    return listFilter.length > 0 ? [{ label: 'Tất cả', value: 'all' }].concat(listFilter) : []
  }, [district, watchForm])

  const wardOptions = useMemo(() => {
    const listFilter = ward
      ?.filter((e) => watchForm?.district?.includes(e.parentId))
      ?.map((e) => ({ label: e.name, value: e.id }))
    return listFilter.length > 0 ? [{ label: 'Tất cả', value: 'all' }].concat(listFilter) : []
  }, [ward, watchForm])

  const getDataOptions = async () => {
    try {
      const resRank = await getRankAllAPI()
      setRank(resRank?.data)
    } catch (error) {
      console.log(error)
      // toast(error.response.data.message, { type: "error" })
    }
  }

  const handleGetDetail = async () => {
    try {
      const res = await getRankConfigDetailsAPI({ id: params.id })

      if (res) {
        setDataDetail(res.data)
        form.setFieldsValue({
          client: res.data.client,
          industry_sector_id: res.data.industrySector.id,
          rankDate: [dayjs(res?.data?.tasks[0]?.startDate), dayjs(res?.data?.tasks[0]?.endDate)],
          zone: res.data?.zones.map((e) => e.id),
          subzone: res.data?.subZones.map((e) => e.id),
          province: res.data?.provinces.map((e) => e.id),
          district_ids: res.data?.districts.map((e) => e.id)
        })
        setValueType(res.data.client)
        setStatus(res?.data?.tasks[0]?.status)

        const list_source = []
        const list_table = []

        res.data.tasks.map((item, index) => {
          list_source.push({
            key: index + 1,
            rank_id: item.rankId,
            point: item.point,
            id: item.id
          })

          list_table.push({
            key: index + 1,
            rank_id: item.rankId,
            point: item.point,
            id: item.id
          })

          setInitData({
            client: res.data.client,
            industry_sector_id: res.data.industrySector.id,
            tasks: list_table
          })

          if (type == 'edit') {
            form.setFieldsValue({
              [`rank_id-${index + 1}`]: item.rankId,
              [`point-${index + 1}`]: item.point
            })
          }
        })

        setCount(list_source.length + 1)

        setDataSource([
          {
            key: 0,
            rank_id: 'Chưa đạt hạng',
            point: 0
          },
          ...list_source
        ])
        setDataTable(list_table)

        // check cho phep sua
        console.log('type', type)
        if (res.data.isUsing && type == 'edit') {
          setShowBlockEdit(true)
        }
      }
    } catch (error) {
      toast(error.response.data.message, { type: 'error' })
    }
  }

  const handleGetOptionsPortfolio = async () => {
    try {
      const res = await getPortfolioAllAPI({ type: 'industrySector' })

      if (res.data) {
        const list = []
        res.data.map((item) => {
          list.push({
            label: item.name,
            value: item.id
          })
        })

        setOptionsPortfolio(list)
      }
    } catch (error) {
      toast(error.response.data.message, { type: 'error' })
    }
  }

  const handleGetRank = async () => {
    try {
      const res = await getRankAllAPI({ client: valueType })

      if (res.data) {
        const list = []
        res.data.map((item) => {
          list.push({
            label: item.name,
            value: item.id
          })
        })

        setOptionsRank(list)
      }
    } catch (error) {
      toast(error.response.data.message, { type: 'error' })
    }
  }

  const handleAdd = () => {
    if (count < 10) {
      const newData = {
        key: uuidv4(),
        key: uuidv4(),
        rank_id: '',
        point: '',
        id: '',
        isChange: false
      }
      setDataSource([...dataSource, newData])
      setDataTable([...dataTable, newData])
      setCount(count + 1)
    }
  }

  const handleDelete = async (keyItem, idItem) => {
    let keySelect = ''
    let idSelect = ''
    if (keyItem || idItem) {
      keySelect = keyItem
      idSelect = idItem
    } else {
      const { key, id } = deleteItem
      keySelect = key
      idSelect = id
    }

    const newData = dataSource.filter((item) => item.key !== keySelect)
    setDataSource(newData)
    setDataTable(newData)
    setCount(count - 1)
  }

  const handleUpdateRank = (value, key, name) => {
    const findIndex = dataSource.findIndex((item) => item.key == key)

    if (findIndex != -1) {
      const newData = [...dataSource]
      newData[findIndex].rank_id = value
      setDataTable(newData)
      setDataSource(newData)
    }

    // handleCheckRankIdAll()
  }

  const handleUpdatePoint = (value, key, name) => {
    if (value > 0) {
      const find = dataTable.find((item) => item.point == value)
      if (find) {
        form.setFields([{ name: name, errors: ['Điểm tối thiểu đạt hạng không được trùng'] }])
      } else {
        form.setFields([{ name: name, errors: null }])
      }
    } else {
      form.setFields([{ name: name, errors: ['Điểm phải là số nguyên dương.'] }])
    }

    const findIndex = dataSource.findIndex((item) => item.key == key)

    if (findIndex != -1) {
      const newData = [...dataSource]
      newData[findIndex].point = value
      newData[findIndex].isChange = true
      setDataTable(newData)
      setDataSource(newData)
    }

    // handleCheckPointAll()
  }

  const checkErrDataTable = () => {
    if (dataTable.length < 2) {
      setErrDataTable(true)
      return
    }

    const dataError = form.getFieldsError()
    const dataValue = form.getFieldsValue()

    const check = []

    dataError.map((item) => {
      if (dataValue[item.name[0]] == undefined || item.errors.length > 0) {
        check.push(false)
      } else {
        check.push(true)
      }
    })

    if (!check.includes(false)) {
      setErrDataTable(false)
    } else {
      setErrDataTable(true)
    }
  }

  const handleCheckRankIdAll = () => {
    const list = layItemTrungRankId(dataTable)
    const list_khong_trung = dataTable.filter((item) => !list.includes(item))

    list.map((item) => {
      form.setFields([{ name: `rank_id-${item.key}`, errors: ['Loại hạng không được trùng'] }])
    })

    list_khong_trung.map((item) => {
      form.setFields([{ name: `rank_id-${item.key}`, errors: null }])
    })
  }

  function layItemTrungRankId(data) {
    const itemsTrungNhau = []

    for (let i = 0; i < data.length - 1; i++) {
      for (let j = i + 1; j < data.length; j++) {
        if (data[i].rank_id == '') {
          continue
        }
        if (data[i].rank_id === data[j].rank_id) {
          itemsTrungNhau.push(data[i])
          itemsTrungNhau.push(data[j])
        }
      }
    }

    return itemsTrungNhau
  }

  const handleCheckPointAll = () => {
    const list = layItemTrungPoint(dataTable)
    const list_khong_trung = dataTable.filter((item) => !list.includes(item))

    list.map((item) => {
      form.setFields([{ name: `point-${item.key}`, errors: ['Điểm tối thiểu đạt hạng không được trùng'] }])
    })
    list_khong_trung.map((item) => {
      console.log('item.point', form.getFieldValue(`point-${item.key}`))
      if (form.getFieldValue(`point-${item.key}`)) {
        if ((item.point < 1 || isNaN(item.point)) && item.isChange == true) {
          form.setFields([{ name: `point-${item.key}`, errors: ['Điểm phải là số nguyên dương.'] }])
        } else {
          form.setFields([{ name: `point-${item.key}`, errors: null }])
        }
      } else {
        if (item.isChange == true) {
          form.setFields([{ name: `point-${item.key}`, errors: ['Vui lòng nhập điểm tối thiểu đạt hạng'] }])
        }
      }
    })
  }

  function layItemTrungPoint(data) {
    const itemsTrungNhau = []

    for (let i = 0; i < data.length - 1; i++) {
      for (let j = i + 1; j < data.length; j++) {
        if (data[i].point == 0) {
          continue
        }
        if (data[i].point === data[j].point) {
          itemsTrungNhau.push(data[i])
          itemsTrungNhau.push(data[j])
        }
      }
    }

    return itemsTrungNhau
  }

  const columns =
    type == 'view'
      ? [
          {
            title: ClassStructureVN.form.table.type,
            dataIndex: 'rank_id',
            width: '30%',
            render: (_, record) => {
              if (record.key === 0 || type == 'view') {
                const find = optionsRank.find((item) => item.value == record.rank_id)

                if (find) {
                  return find.label
                } else {
                  return record.rank_id
                }
              } else {
                return (
                  <Form.Item name={`rank_id-${record.key}`}>
                    <FormSelect
                      onChange={(e) => handleUpdateRank(e, record.key, `rank_id-${record.key}`)}
                      options={optionsRank}
                    />
                  </Form.Item>
                )
              }
            }
          },
          {
            title: ClassStructureVN.form.table.point,
            dataIndex: 'point',
            render: (_, record) => {
              if (record.key === 0 || type == 'view') {
                return record.point
              } else {
                return (
                  <Form.Item name={`point-${record.key}`}>
                    <Input
                      onChange={(e) => handleUpdatePoint(Number(e.target.value), record.key, `point-${record.key}`)}
                      style={{ width: '100%' }}
                      min={0}
                      maxLength={10}
                      size='large'
                    />
                  </Form.Item>
                )
              }
            }
          }
        ]
      : [
          {
            title: ClassStructureVN.form.table.type,
            dataIndex: 'rank_id',
            width: '30%',
            render: (_, record) => {
              if (record.key === 0 || type == 'view') {
                const find = optionsRank.find((item) => item.value == record.rank_id)

                if (find) {
                  return find.label
                } else {
                  return record.rank_id
                }
              } else {
                return (
                  <Form.Item style={{ margin: 0, height: '62px' }} name={`rank_id-${record.key}`}>
                    <FormSelect
                      placeholder='Chọn loại hạng'
                      onChange={(e) => handleUpdateRank(e, record.key, `rank_id-${record.key}`)}
                      options={optionsRank}
                    />
                  </Form.Item>
                )
              }
            }
          },
          {
            title: ClassStructureVN.form.table.point,
            dataIndex: 'point',
            render: (_, record) => {
              if (record.key === 0 || type == 'view') {
                return record.point
              } else {
                return (
                  <Form.Item style={{ margin: 0, height: '62px' }} name={`point-${record.key}`}>
                    <Input
                      maxLength={10}
                      placeholder='Nhập điểm tối thiểu đạt hạng'
                      onChange={(e) => handleUpdatePoint(Number(e.target.value), record.key, `point-${record.key}`)}
                      style={{ width: '100%' }}
                      min={0}
                      size='large'
                    />
                  </Form.Item>
                )
              }
            }
          },
          {
            title: ClassStructureVN.form.table.action,
            dataIndex: 'action',
            render: (_, record) => {
              if (type == 'view') {
                return
              }
              if (record.key === 0) {
                return
              } else {
                return (
                  <div style={{ height: '62px' }}>
                    <Button
                      size='large'
                      onClick={() => {
                        setDeleteItem(record)
                        handleDelete(record.key, record.id)
                      }}
                    >
                      <i className='mdi mdi-delete-outline' />
                    </Button>
                  </div>
                )
              }
            }
          }
        ]

  const handleSave = async () => {
    const value = form.getFieldsValue()
    const tasks = dataTable.map((item, index) => {
      if (item.key == 0) {
        return
      }

      if (item.id == '') {
        return {
          rank_id: item.rank_id,
          point: item.point
        }
      } else {
        return {
          rank_id: item.rank_id,
          point: item.point,
          id: item.id
        }
      }
    })
    const list_taks = tasks.filter((item) => item != null)

    const payload = {
      client: value?.client,
      industry_sector_id: value?.industry_sector_id,
      district_ids: value?.district_ids,
      tasks: list_taks,
      startDate: value?.rankDate?.[0],
      endDate: value?.rankDate?.[1],
      status: 'prepare'
    }

    if (type == 'create') {
      try {
        const res = await postCreateRankConfigAPI(payload)

        if (res) {
          toast('Thêm mới cơ cấu hạng thành công', { type: 'success' })
          navigate('/manage-class-structure')
        }
      } catch (error) {
        toast(error.response.data.message, { type: 'error' })
      }
    } else {
      try {
        const res = await updateRankConfigAPI({ id: params.id, ...payload, tasks: list_taks })

        if (res) {
          toast('Cập nhật cơ cấu hạng thành công', { type: 'success' })
          navigate('/manage-class-structure')
        }
      } catch (error) {
        toast(error.response.data.message, { type: 'error' })
      }
    }
  }

  const textAccount = (
    <ul>
      <li>Chu kỳ xét hạng: 01/01 đến 31/12.</li>
      <li>Sau 31/12, tất cả các hạng tài khoản giảm 1 bậc, điểm giảm về số điểm tối thiểu của hạng giảm về.</li>
      <li>Với tài khoản cửa hàng, nếu không phát sinh giao dịch tích luỹ trong vòng 6 tháng. Điểm reset về 0.</li>
      <li>Với tài khoản kỹ thuật viên, nếu không phát sinh giao dịch kích hoạt trong vòng 6 tháng. Điểm reset về 0.</li>
    </ul>
  )

  const text = (
    <ul>
      <li>Với tài khoản cửa hàng, điểm xét hạng được tính dựa trên giao dịch tích luỹ sản phẩm.</li>
      <li>Với tài khoản kỹ thuật viên, điểm xét hạng được tính dựa trên giao dịch kích hoạt sản phẩm.</li>
    </ul>
  )

  const onCheckCancel = () => {
    if (type != 'view') {
      if (type == 'create') {
        if (form.isFieldsTouched()) {
          setShowSubmitCancel(true)
        } else {
          navigate('/manage-class-structure')
        }
      } else {
        if (
          !isEqual(initData, {
            client: valueType,
            industry_sector_id: form.getFieldValue('industry_sector_id'),
            tasks: dataTable
          })
        ) {
          setShowSubmitCancel(true)
        } else {
          navigate('/manage-class-structure')
        }
      }
    } else {
      navigate('/manage-class-structure')
    }
  }

  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          <Breadcrumb
            title={'Quản lý cơ cấu hạng tài khoản'}
            breadcrumbItem={
              type == 'create'
                ? ClassStructureVN.addModal
                : type == 'view'
                ? ClassStructureVN.viewModal
                : ClassStructureVN.editModal
            }
          />
          <Card>
            <CardBody>
              <Form form={form}>
                <Row>
                  <Col xl={4}>
                    <div className='form-label d-flex algin-items-center' style={{ fontWeight: 600 }}>
                      {'Loại tài khoản'}
                      <a className='text-danger'>*</a>
                      <Tooltip placement='topLeft' title={textAccount} style={{ width: '700px' }}>
                        <i
                          className='bx bxs-info-circle'
                          style={{ color: '#03bdd6', fontSize: '16px', marginLeft: '4px' }}
                        ></i>
                      </Tooltip>
                    </div>
                    <Form.Item name='client' required={[{ required: true, message: 'Vui lòng chọn loại tài khoản' }]}>
                      <FormSelect
                        onChange={(e) => {
                          setValueType(e)
                          form.setFields([{ name: 'client', errors: null }])
                        }}
                        disabled={type == 'view' || type == 'edit'}
                        options={OptionTypeAccount}
                        placeholder={ClassStructureVN.form.accountType.placeholder}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <div className='d-flex mt-2 algin-items-center'>
                  <h5 style={{ fontSize: '15px' }}>Cơ cấu hạng theo ngành hàng</h5>
                  <Tooltip placement='topLeft' title={text} style={{ width: '700px' }}>
                    <i
                      className='bx bxs-info-circle'
                      style={{ color: '#03bdd6', fontSize: '16px', marginLeft: '4px' }}
                    ></i>
                  </Tooltip>
                </div>
                <Row className='d-flex align-items-center'>
                  <Col xl={4}>
                    <Label className='form-label'>
                      {ClassStructureVN.form.industry.label}
                      <a className='text-danger'>*</a>
                    </Label>
                    <Form.Item
                      name='industry_sector_id'
                      required={[{ required: true, message: 'Vui lòng chọn ngành hàng' }]}
                    >
                      <FormSelect
                        options={optionsPortfolio}
                        onChange={() => {
                          form.setFields([{ name: 'industry_sector_id', errors: null }])
                        }}
                        disabled={type == 'view' || type == 'edit'}
                        placeholder={ClassStructureVN.form.industry.placeholder}
                      />
                    </Form.Item>
                  </Col>

                  <Col xl={4}>
                    <Label className='form-label'>
                      {'Miền'}
                      <a className='text-danger'>*</a>
                    </Label>
                    <Form.Item name='zone' rules={[{ required: true, message: 'Vui lòng chọn miền' }]}>
                      <FormSelect
                        mode='multiple'
                        showSearch
                        allowClear
                        maxTagTextLength={10}
                        maxTagCount={2}
                        options={zoneOptions}
                        onChange={() => {
                          form.setFieldValue('subzone', undefined)
                          form.setFieldValue('province', undefined)
                          form.setFieldValue('district', undefined)
                          form.setFieldValue('wardIds', undefined)
                        }}
                        onSelect={(value) => {
                          const all = zone?.map((e) => e.id)
                          if (value && value === 'all') {
                            return form.setFieldValue('zone', [...all])
                          }
                        }}
                        placeholder={'Chọn miền'}
                        disabled={type === 'view' || (type === 'edit' && status !== 'prepare')}
                      />
                    </Form.Item>
                  </Col>

                  <Col sm='4'>
                    <Label className='form-label'>
                      {'Vùng'}
                      {type !== 'view' && <a className='text-danger'>*</a>}
                    </Label>
                    <Form.Item name='subzone' rules={[{ required: true, message: 'Vui lòng chọn vùng' }]}>
                      <FormSelect
                        mode='multiple'
                        showSearch
                        allowClear
                        maxTagTextLength={10}
                        maxTagCount={2}
                        options={subzoneOptions}
                        onChange={() => {
                          form.setFieldValue('province', undefined)
                          form.setFieldValue('district', undefined)
                          form.setFieldValue('wardIds', undefined)
                        }}
                        onSelect={(value) => {
                          const all = subzone?.filter((e) => watchForm.zone?.includes(e.zoneId))?.map((e) => e.id)
                          if (value && value === 'all') {
                            return form.setFieldValue('subzone', [...all])
                          }
                        }}
                        placeholder={'Chọn ' + ManageContentVN.detailInfor.form.region}
                        disabled={type === 'view' || (type === 'edit' && status !== 'prepare')}
                      />
                    </Form.Item>
                  </Col>
                  <Col sm='4'>
                    <Label className='form-label'>
                      {'Tỉnh/thành phố'}
                      {type !== 'view' && <a className='text-danger'>*</a>}
                    </Label>
                    <Form.Item name='province' rules={[{ required: true, message: 'Vui lòng chọn tỉnh/thành phố' }]}>
                      <FormSelect
                        mode='multiple'
                        showSearch
                        allowClear={<i className='mdi mdi-close-circle-outline mdi-24px ' />}
                        maxTagTextLength={10}
                        maxTagCount={2}
                        options={provinceOptions}
                        onChange={() => {
                          form.setFieldValue('district', undefined)
                          form.setFieldValue('wardIds', undefined)
                        }}
                        onSelect={(value) => {
                          const all = province
                            ?.filter((e) => watchForm.subzone?.includes(e.subZoneId))
                            ?.map((e) => e.id)
                          if (value && value === 'all') {
                            return form.setFieldValue('province', [...all])
                          }
                        }}
                        placeholder={'Chọn ' + ManageContentVN.detailInfor.form.city}
                        disabled={type === 'view' || (type === 'edit' && status !== 'prepare')}
                      />
                    </Form.Item>
                  </Col>
                  <Col sm='4'>
                    <Label className='form-label'>
                      {'Quận/huyện'}
                      {type !== 'view' && <a className='text-danger'>*</a>}
                    </Label>
                    <Form.Item name='district_ids' rules={[{ required: true, message: 'Vui lòng chọn quận/huyện' }]}>
                      <FormSelect
                        mode='multiple'
                        showSearch
                        allowClear
                        maxTagTextLength={10}
                        maxTagCount={2}
                        options={districtOptions}
                        onChange={() => {
                          form.setFieldValue('wardIds', undefined)
                        }}
                        onSelect={(value) => {
                          const all = district
                            ?.filter((e) => watchForm.province?.includes(e.parentId))
                            ?.map((e) => e.id)
                          if (value && value === 'all') {
                            return form.setFieldValue('district_ids', [...all])
                          }
                        }}
                        placeholder={'Chọn ' + ManageContentVN.detailInfor.form.district}
                        disabled={type === 'view' || (type === 'edit' && status !== 'prepare')}
                      />
                    </Form.Item>
                  </Col>
                  <Col xl={4}>
                    <Label className='form-label'>
                      {'Thời gian hạng'}
                      <a className='text-danger'>*</a>
                    </Label>
                    <Form.Item name='rankDate' rules={[{ required: true, message: 'Vui lòng chọn thời gian hạng' }]}>
                      <FormDateRange
                        disabledDate={(current) => dayjs().isAfter(current, 'day')}
                        disabled={type === 'view' || (type === 'edit' && status !== 'prepare')}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row className='mt-4'>
                  <Col xl={12}>
                    <Table
                      rowClassName={() => 'editable-row'}
                      bordered
                      dataSource={dataSource}
                      columns={columns}
                      pagination={false}
                    />
                    {count < 10 && type != 'view' && (
                      <Button
                        className='mt-1 d-flex align-items-center gap-1'
                        onClick={handleAdd}
                        style={{
                          marginBottom: 16,
                          backgroundColor: 'none',
                          border: 'none',
                          color: '#4069e5'
                        }}
                      >
                        <i className='bx bxs-plus-circle font-size-20' /> <span>Thêm hạng tài khoản</span>
                      </Button>
                    )}
                  </Col>
                </Row>
              </Form>
              <Row>
                <Col xl={12} className='d-flex gap-2 justify-content-end mt-4'>
                  <button onClick={() => onCheckCancel()} type='button' className='btn btn-soft-secondary w-14 '>
                    Hủy
                  </button>
                  <button
                    disabled={status !== 'prepare' && type !== 'create'}
                    type={'submit'}
                    className='btn btn-primary w-14'
                    onClick={() => {
                      if (type == 'view') {
                        navigate(`/manage-class-structure/edit/${params.id}`)
                      } else {
                        handleSave()
                      }
                    }}
                  >
                    {type == 'view' ? 'Chỉnh sửa' : 'Lưu'}
                  </button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
        {showBlockEdit && (
          <ConfirmModal
            show={showBlockEdit}
            icon={
              <div className='avatar-title rounded-circle bg-danger text-light bg-opacity-60 font-size-20 rounded-3'>
                <i className='mdi mdi-alert'></i>
              </div>
            }
            onConfirmClick={() => navigate(`/manage-class-structure`)}
            onCloseClick={() => navigate(`/manage-class-structure`)}
            title={`Không thể chỉnh sửa cấu hình hạng`}
            description={`Không thể chỉnh sửa cấu hình hạng đang được sử dụng trong chương trình`}
            showOneButton={true}
            buttonConfirmText='Đã hiểu'
          />
        )}
        <ConfirmModal
          show={showSubmitCancel}
          onConfirmClick={() => navigate('/manage-class-structure')}
          onCloseClick={() => setShowSubmitCancel(false)}
          icon={undefined}
          title='Xác nhận hủy'
          description={`Thay đổi sẽ không được lưu. Bạn có chắc chắn muốn thoát không?`}
        />
      </div>
    </React.Fragment>
  )
}

export default withRouter(AddEditClassStructure)
