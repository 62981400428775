import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'

const selectorProfile = (state) => state.Profile
const profileProperties = createSelector(selectorProfile, ({ profile }) => ({
  permissionUser: profile?.permissions?.map((x) => x.code)
}))

export default function usePermission({ code, condition = 'AND' }) {
  const { permissionUser } = useSelector(profileProperties)
  const hasPermission = useMemo(() => {
    if (code === undefined) return true
    if (typeof code === 'string') return permissionUser?.find((item) => item === code)

    if (condition === 'AND') return code?.every((x) => permissionUser.includes(x))
    return code.some((x) => permissionUser?.includes(x))
  }, [permissionUser, code])

  return hasPermission
}
