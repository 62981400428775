import { call, put, takeEvery } from "redux-saga/effects"

import { GET_MANAGE_ACCUMULATION, GET_ACCUMULATION_DETAIL, GET_KTVS } from "./actionTypes"
import { getManageAccumulationSuccess, getManageAccumulationFail, getAccumulationDetailSuccess } from "./actions"

import { getAccumulationListAPI, getAccumulationDetailsAPI } from "helpers/backend_helper"

function* fetchAccumulationList({ payload: params }) {
    try {
        const response = yield call(getAccumulationListAPI, { ...params })
        yield put(getManageAccumulationSuccess(response))
    } catch (error) {
        yield put(getManageAccumulationFail(error?.data?.message))
    }
}

function* fetchAccumulationDetail({ payload: data }) {
    try {
        const response = yield call(getAccumulationDetailsAPI, { product_id: data?.productId, user_id: data?.userRewardUserId })
        yield put(getAccumulationDetailSuccess(response.data))
    } catch (error) {
        console.log(error)
        // yield put(getAccumulationDetailFail(error))
    }
}

function* AccumulationsSaga() {
    yield takeEvery(GET_MANAGE_ACCUMULATION, fetchAccumulationList)
    yield takeEvery(GET_ACCUMULATION_DETAIL, fetchAccumulationDetail)
}

export default AccumulationsSaga
