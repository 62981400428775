import {
    GET_MANAGE_ACCOUNT_CLASS,
    GET_MANAGE_ACCOUNT_CLASS_FAIL,
    GET_MANAGE_ACCOUNT_CLASS_SUCCESS,
    GET_ACCOUNT_CLASS_DETAIL,
    GET_ACCOUNT_CLASS_DETAIL_SUCCESS,
    GET_ACCOUNT_CLASS_DETAIL_FAIL,
    SET_TYPE_POPUP,
} from "./actionTypes"

export const getAccountClassList = params => ({
    type: GET_MANAGE_ACCOUNT_CLASS,
    payload: params,
})

export const getManageAccountClassSuccess = data => ({
    type: GET_MANAGE_ACCOUNT_CLASS_SUCCESS,
    payload: data,
})

export const getManageAccountClassFail = error => ({
    type: GET_MANAGE_ACCOUNT_CLASS_FAIL,
    payload: error,
})

export const getAccountClassDetail = data => ({
    type: GET_ACCOUNT_CLASS_DETAIL,
    payload: data,
})

export const getAccountClassDetailSuccess = data => ({
    type: GET_ACCOUNT_CLASS_DETAIL_SUCCESS,
    payload: data,
})

export const getAccountClassDetailFail = error => ({
    type: GET_ACCOUNT_CLASS_DETAIL_FAIL,
    payload: error,
})

export const setTypePopupAccountClass = params => ({
    type: SET_TYPE_POPUP,
    payload: params,
})
