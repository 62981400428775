export const GET_MANAGE_BANER = "GET_BANERLIST"
export const GET_MANAGE_BANER_FAIL = "GET_MANAGE_BANER_FAIL"
export const GET_MANAGE_BANER_SUCCESS = "GET_MANAGE_BANER_SUCCESS"

export const GET_BANER_DETAIL = "GET_BANER_DETAIL"
export const GET_BANER_DETAIL_SUCCESS = "GET_BANER_DETAIL_SUCCESS"
export const GET_BANER_DETAIL_FAIL = "GET_BANER_DETAIL_FAIL"

export const UPDATE_MANAGE_BANER = "UPDATE_MANAGE_BANER"
export const UPDATE_MANAGE_BANER_SUCCESS = "UPDATE_MANAGE_BANER_SUCCESS"
export const UPDATE_MANAGE_BANER_FAIL = "UPDATE_MANAGE_BANER_FAIL"

export const GET_KTVS = "GET_KTV_LIST"
