import React, { useEffect, useMemo, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

//import components

import {
  getBankList,
  setTypePopupBank,
  getBankDetail,
  getBankDetailSuccess,
  getContactList,
  setTypePopupContact,
  getContactDetail,
  getContactDetailSuccess
} from 'store/actions'

// import { Name, Abbreviation } from '././ManageBankCol'

//redux
import { useSelector, useDispatch } from 'react-redux'
import { createSelector } from 'reselect'

import { Col, Row, UncontrolledTooltip, Card, CardBody } from 'reactstrap'
import Spinners from 'components/Common/Spinner'
import { toast } from 'react-toastify'
import TableContainer from 'components/Common/TableContainer'
// import { BankVN } from 'constants/vn'
import ConfirmModal from 'components/Common/ConfirmModal'
import {
  deleteBankAPI,
  deleteContactAPI,
  exportByProductAPI,
  exportSMSBrandnameAPI,
  getProductBonusTempListAPI,
  getSMSBrandnameListAPI
} from 'helpers/backend_helper'
import AddEditContact from './components/addEditContactModal'
// import { AREA_MANAGEMENT_CODE, SCREEN_CODE } from 'constants/permission-code'
import PermissionWrapper from 'components/Common/PermissionWrapper'
import { Space } from 'antd'
import { BankVN } from 'constants/vn'
import { AREA_MANAGEMENT_CODE, SCREEN_CODE } from 'constants/permission-code'
import fileDownload from 'js-file-download'
import moment from 'moment'
import { Status, Type } from './components/ManageSMSBrandnameCol'
import { TableRowSpan } from 'components/Common/TableCustom'
import ManageSMSBrandnameFilter from './components/ManageSMSBrandnameFilter'

function ManageSMSBrandname() {
  document.title = 'Manage SMS Brandname CMS List'
  const screenCode = useMemo(() => SCREEN_CODE.AREA_MANAGEMENT, [])

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const selectContactsState = (state) => state.ContactsReducer
  // const ContactsProperties = createSelector(selectContactsState, (contactReducer) => ({
  //   contacts: contactReducer.contacts,
  //   loading: contactReducer.loading,
  //   total: contactReducer.total
  // }))

  // const { contacts, loading, total } = useSelector(ContactsProperties)

  const [isLoading, setLoading] = useState(false)
  const [onDelete, setOnDelete] = useState(false)
  const [selected, setSelected] = useState()
  const [filter, setFilter] = useState()
  const [openModal, setOpenModal] = useState(false)
  const [page, setPage] = useState(1)
  const [listTable, setListTable] = useState([])
  const [total, setTotal] = useState()
  const [pagination, setPagination] = useState({
    pageIndex: page,
    pageSize: 10,
    totalRows: total
  })

  useEffect(() => {
    dispatch(getContactList())
  }, [dispatch])

  useEffect(() => {
    handleGetListTable()
  }, [filter])

  const handleGetListTable = async () => {
    try {
      // const res = await getPointConfigListAPI(filter)
      const res = await getSMSBrandnameListAPI(filter)

      if (res.data) {
        setListTable(res.data)
      }

      if (res.meta) {
        setTotal(res.meta.totalItems)
      }
    } catch (error) {
      toast(error.response.data.message, { type: 'error' })
    }
  }

  const columns = useMemo(
    () => [
      // {
      //   Header: 'STT',
      //   accessor: 'stt',
      //   filterable: true,
      //   disableSortBy: true,
      //   enableRowSpan: true,
      //   hideSort: true,
      //   Cell: (cellProps) => {
      //     return (page - 1) * 10 + cellProps.row.index + 1
      //   }
      // },
      {
        Header: 'Số điện thoại',
        accessor: 'phone',
        filterable: true,
        disableSortBy: true,
        hideSort: true
      },
      {
        Header: 'Thời gian',
        accessor: 'sendDate',
        filterable: true,
        disableSortBy: true,
        hideSort: true
      },
      {
        Header: 'Loại SMS',
        accessor: 'type',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <Type {...cellProps} />
        }
      },
      {
        Header: 'Trạng thái',
        accessor: 'status',
        filterable: true,
        // disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <Status {...cellProps} />
        }
      }
    ],
    []
  )

  const setGlobalFilterUser = async (data) => {
    data.start_date = data.start_date1?.startOf('day')?.toDate()
    data.end_date = data.end_date1?.endOf('day')?.toDate()
    setFilter(data)
    handleGetListTable()

    setPage(1)
  }

  const onClickDelete = async () => {
    try {
      const res = await deleteContactAPI({ id: selected.id, type: selected.type })
      if (res) {
        toast('Xoá thông tin liên hệ thành công', { type: 'success' })
        dispatch(getContactList(filter))
        setOnDelete(false)
      }
    } catch (error) {
      toast.error(error.response.data.message, { type: 'error' })
    }
  }

  const goToPage = (page) => {
    dispatch(getContactList({ ...filter, page }))
    setFilter({ ...filter, page })
    setPage(page)
  }

  const handleClickExport = async () => {
    const res = await exportSMSBrandnameAPI({ ...filter })
    fileDownload(res, `${moment().format('yyMMDD')}_Danh sach SMS Brandname.xlsx`)
  }

  const handleResetFilter = () => {
    setFilter([])
    setPage(1)
  }

  return (
    <React.Fragment>
      <div className='page-content'>
        <div className='page-title-box container-fluid'>
          <h4 className=' mb-sm-0 font-size-18 pb-4'>{'SMS Brandname'}</h4>
          {isLoading ? (
            <Spinners setLoading={setLoading} />
          ) : (
            <Row>
              <Col lg='12'>
                <Card>
                  <CardBody className='border-bottom'>
                    <div className='d-flex align-items-center'>
                      <h5 className='mb-0 card-title flex-grow-1'>{'Danh sách SMS'}</h5>
                      <div className='flex-shrink-0'>
                        <PermissionWrapper screenCode={screenCode} code={AREA_MANAGEMENT_CODE.ADMIN_CREATE_AREA}>
                          {/* <button
                            onClick={() => {
                              setOpenModal(true)
                              dispatch(setTypePopupContact('create'))
                            }}
                            className='btn btn-primary'
                          >
                            <i className='mdi mdi-plus me-1' />
                            {'Thêm mới liên hệ'}
                          </button> */}
                          <button onClick={() => handleClickExport()} className='btn btn-primary me-2 pe-4'>
                            <i className='mdi mdi-database-export me-1 ms-2' />
                            {'Export'}
                          </button>
                        </PermissionWrapper>
                      </div>
                    </div>
                  </CardBody>
                  <CardBody>
                    {/* <TableContainer
                      columns={columns}
                      data={listTable}
                      isGlobalFilter={true}
                      setGlobalFilterUser={setGlobalFilterUser}
                      isSMSBrandnameFilter={true}
                      isPagination={true}
                      iscustomPageSizeOptions={false}
                      isShowingPageLength={false}
                      customPageSize={10}
                      tableClass='table-bordered align-middle nowrap mt-2'
                      paginationDiv='col-sm-12 col-md-7'
                      pagination='pagination justify-content-end pagination-rounded'
                      placeholderSearch={'Nhập tên, số điện thoại'}
                      useAPIChangePaging
                      currentPage={page}
                      gotoPageCustom={goToPage}
                      total={total}
                    /> */}
                    <Space size='middle' direction='vertical' className='w-100'>
                      <ManageSMSBrandnameFilter
                        handleFilter={setGlobalFilterUser}
                        handleResetFilter={handleResetFilter}
                      />
                      <TableRowSpan
                        columns={columns}
                        data={listTable}
                        pagination={pagination}
                        setPagination={setPagination}
                        gotoPageCustom={goToPage}
                        currentPage={page}
                        total={total}
                        isShowTotal={true}
                      />
                    </Space>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </div>
      </div>
      {openModal && (
        <AddEditContact
          show={openModal}
          filter={filter}
          onCloseClick={() => {
            setOpenModal(false)
            dispatch(getContactDetailSuccess(null))
          }}
          setPage={setPage}
        />
      )}
      <ConfirmModal
        show={onDelete}
        icon={
          <div className='avatar-title rounded-circle bg-danger text-light bg-opacity-60 font-size-20 rounded-3'>
            <i className='mdi mdi-delete-outline'></i>
          </div>
        }
        onConfirmClick={onClickDelete}
        onCloseClick={() => setOnDelete(false)}
        title={`Xác nhận xóa ngân hàng `}
        description={`Bạn có chắc chắn muốn xóa ngân hàng ?`}
      />
    </React.Fragment>
  )
}

export default ManageSMSBrandname
