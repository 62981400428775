import PropsType from 'prop-types'
import { useEffect, useMemo, useState } from 'react'
import { Label } from 'reactstrap'
import React from 'react'
import { Row, Col, Space, Flex } from 'antd'
import { FormSelect } from 'components/Common/form-select/FormSelect'
import { createSelector } from 'reselect'
import { useSelector } from 'react-redux'
import { getPortfolioAllAPI } from 'helpers/backend_helper'
import { ProductVN } from 'constants/vn'

ReportAccountRankFilter.propTypes = {
  handleFilter: PropsType.func,
  handleExport: PropsType.func,
  handleResetFilter: PropsType.func
}

function ReportAccountRankFilter({ handleFilter, handleExport, handleResetFilter }) {
  const [filter, setFilter] = useState({})
  const selectLayoutState = (state) => state.Layout
  const LayoutProperties = createSelector(selectLayoutState, (app) => ({
    zone: app.zone,
    subzone: app.subzone,
    province: app.province,
    district: app.district,
    ward: app.ward,
    myLocationManagement: app.myLocationManagement
  }))
  const { zone, subzone, province, district, myLocationManagement } = useSelector(LayoutProperties)
  const [industry, setIndustry] = useState()
  const [industryOptions, setIndustryOptions] = useState()
  const [industrySector, setIndustrysector] = useState()
  const [category, setCategory] = useState()

  const onFilterChange = (name, value) =>
    setFilter((prev) => ({
      ...prev,
      [name]: value
    }))

  const ColProps = useMemo(() => ({
    xs: { span: 24 },
    xxl: { span: 8 }
  }))

  const onResetFilter = () =>
    setFilter({
      province_ids: undefined,
      industry_ids: undefined,
      industry_sector_ids: undefined,
      category_ids: undefined,
      zone_ids: undefined,
      sub_zone_ids: undefined,
      location: undefined,
      district_ids: undefined,
      ward_ids: undefined,
      province_names: undefined,
      industry_names: undefined,
      industry_sector_names: undefined,
      category_names: undefined,
      zone_names: undefined,
      sub_zone_names: undefined,
      location_name: undefined,
      district_names: undefined,
      ward_names: undefined
    })

  ////Lấy danh sách district
  // Get all unique parentId values from myLocationManagement
  // const myLocationManagementParentIds = myLocationManagement?.map((ward) => ward.parentId)
  //Từ myLocationManagementParentIds, lấy ra những district có id trong myLocationManagementParentIds
  const lstDistrict = myLocationManagement

  ////Lấy danh sách province
  // Get all unique parentId values from lstDistrict
  const districtParentIds = lstDistrict?.map((district) => district.parentId)
  //Từ districtParentIds, lấy ra những district có id trong districtParentIds
  const lstProvionce = province.filter((province) => districtParentIds.includes(province.id))

  ////Lấy danh sách subZone
  // Get all unique subZoneId(parentId) values from lstProvionce
  const provinceParentIds = lstProvionce?.map((province) => province.subZoneId)
  //Từ provinceParentIds, lấy ra những subZone có id trong provinceParentIds
  const lstSubZone = subzone?.filter((subZone) => provinceParentIds.includes(subZone.id))

  ////Lấy danh sách zone
  // Get all unique zoneId(parentId) values from lstProvionce
  const subZoneParentIds = lstProvionce?.map((province) => province.zoneId) // trong lstProvionce có lưu zoneId
  //Từ provinceParentIds, lấy ra những subZone có id trong provinceParentIds
  const lstZone = zone.filter((zone) => subZoneParentIds.includes(zone.id))

  const getIndustryDataOptions = async () => {
    try {
      const resIndustry = await getPortfolioAllAPI({ type: 'industry' })
      const resIndustrysector = await getPortfolioAllAPI({ type: 'industrySector' })
      const resCategory = await getPortfolioAllAPI({ type: 'category' })
      setIndustry(resIndustry?.data)
      if (resIndustry?.data?.length > 0) {
        setIndustryOptions(
          [{ label: 'Tất cả', value: 'all' }].concat(
            resIndustry?.data?.map((item) => ({
              value: item.id,
              label: item.name
            }))
          )
        )
      } else {
        setIndustryOptions([])
      }
      setIndustrysector(resIndustrysector?.data)
      setCategory(resCategory?.data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getIndustryDataOptions()
  }, [])

  return (
    <Row gutter={[10, 10]} align='bottom'>
      <Col {...ColProps} className='w-100'>
        <Label className='form-label'>Ngành</Label>
        <FormSelect
          options={industryOptions}
          onChange={(value, label) => {
            onFilterChange('industry_ids', value)
            onFilterChange(
              'industry_names',
              label.map((e) => e.label)
            )
            onFilterChange('industry_sector_ids', [])
            onFilterChange('category_ids', [])
            onFilterChange('model_ids', [])
            onFilterChange('industry_sector_names', [])
            onFilterChange('category_names', [])
            onFilterChange('model_names', [])
          }}
          onSelect={(value) => {
            const all = industry?.map((item) => item.id)
            const all_names = industry?.map((item) => item.name)
            if (value && value === 'all') {
              onFilterChange('industry_ids', [...all])
              onFilterChange('industry_names', [...all_names])
            }
          }}
          value={filter.industry_ids}
          placeholder='Chọn Ngành'
          mode='multiple'
        />
      </Col>

      <Col {...ColProps} className='w-100'>
        <Label className='form-label'>Ngành hàng</Label>
        <FormSelect
          options={
            industrySector?.filter((e) => filter.industry_ids?.includes(e.parentId))?.length > 0
              ? [{ label: 'Tất cả', value: 'all' }].concat(
                  industrySector
                    ?.filter((e) => filter.industry_ids?.includes(e.parentId))
                    ?.map((item) => ({
                      value: item.id,
                      label: item.name
                    }))
                )
              : []
          }
          onChange={(value, label) => {
            onFilterChange('industry_sector_ids', value)
            onFilterChange(
              'industry_sector_names',
              label.map((e) => e.label)
            )

            onFilterChange('category_ids', [])
            onFilterChange('model_ids', [])

            onFilterChange('category_names', [])
            onFilterChange('model_names', [])
          }}
          onSelect={(value) => {
            const all = industrySector?.filter((e) => filter.industry_ids?.includes(e.parentId))?.map((item) => item.id)
            const all_names = industrySector
              ?.filter((e) => filter.industry_ids?.includes(e.parentId))
              ?.map((item) => item.name)
            if (value && value === 'all') {
              onFilterChange('industry_sector_ids', [...all])
              onFilterChange('industry_sector_names', [...all_names])
            }
          }}
          value={filter.industry_sector_ids}
          placeholder='Chọn Ngành hàng'
          mode='multiple'
        />
      </Col>

      <Col {...ColProps} className='w-100'>
        <Label className='form-label'>Nhóm sản phẩm</Label>
        <FormSelect
          options={
            category?.filter((e) => filter.industry_sector_ids?.includes(e?.parentId))?.length > 0
              ? [{ label: 'Tất cả', value: 'all' }].concat(
                  category
                    ?.filter((e) => filter.industry_sector_ids?.includes(e?.parentId))
                    ?.map((item) => ({
                      value: item.id,
                      label: item.name
                    }))
                )
              : []
          }
          onChange={(value, label) => {
            onFilterChange('category_ids', value)
            onFilterChange(
              'category_names',
              label.map((e) => e.label)
            )
            onFilterChange('model_ids', [])
            onFilterChange('model_names', [])
          }}
          onSelect={(value) => {
            const all = category
              ?.filter((e) => filter.industry_sector_ids?.includes(e?.parentId))
              ?.map((item) => item.id)
            const all_names = category
              ?.filter((e) => filter.industry_sector_ids?.includes(e?.parentId))
              ?.map((item) => item.name)
            if (value && value === 'all') {
              onFilterChange('category_ids', [...all])
              onFilterChange('category_names', [...all_names])
            }
          }}
          value={filter.category_ids}
          placeholder='Chọn nhóm sản phẩm'
          mode='multiple'
        />
      </Col>

      <Col {...ColProps}>
        <Label className='form-label'>Miền</Label>
        <FormSelect
          options={[{ label: 'Tất cả', value: 'all' }].concat(
            lstZone?.map((item) => ({
              value: item.id,
              label: item.name
            }))
          )}
          value={filter.zone_ids}
          onChange={(value, label) => {
            onFilterChange('zone_ids', value)
            onFilterChange(
              'zone_names',
              label.map((e) => e.label)
            )

            onFilterChange('sub_zone_ids', [])
            onFilterChange('province_ids', [])
            onFilterChange('district_ids', [])
            onFilterChange('ward_ids', [])
            onFilterChange('sub_zone_names', [])
            onFilterChange('province_names', [])
            onFilterChange('district_names', [])
            onFilterChange('ward_names', [])
          }}
          onSelect={(value) => {
            const all = lstZone?.map((item) => item.id)
            const all_names = lstZone?.map((item) => item.name)
            if (value && value === 'all') {
              onFilterChange('zone_ids', [...all])
              onFilterChange('zone_names', [...all_names])
            }
          }}
          placeholder={ProductVN.filter.zone.placeholder}
          mode='multiple'
        />
      </Col>
      <Col {...ColProps}>
        <Label className='form-label'>Vùng</Label>
        <FormSelect
          options={
            lstSubZone?.filter((e) => filter.zone_ids?.includes(e.zoneId))?.length > 0
              ? [{ label: 'Tất cả', value: 'all' }].concat(
                  lstSubZone
                    ?.filter((e) => filter.zone_ids?.includes(e.zoneId))
                    ?.map((item) => ({
                      value: item.id,
                      label: item.name
                    }))
                )
              : []
          }
          value={filter.sub_zone_ids}
          onChange={(value, label) => {
            onFilterChange('sub_zone_ids', value)
            onFilterChange(
              'sub_zone_names',
              label.map((e) => e.label)
            )
            onFilterChange('province_ids', [])
            onFilterChange('district_ids', [])
            onFilterChange('ward_ids', [])
            onFilterChange('province_names', [])
            onFilterChange('district_names', [])
            onFilterChange('ward_names', [])
          }}
          onSelect={(value) => {
            const all = lstSubZone?.filter((e) => filter.zone_ids?.includes(e.zoneId))?.map((item) => item.id)
            const all_names = lstSubZone?.filter((e) => filter.zone_ids?.includes(e.zoneId))?.map((item) => item.name)
            if (value && value === 'all') {
              onFilterChange('sub_zone_ids', [...all])
              onFilterChange('sub_zone_names', [...all_names])
            }
          }}
          placeholder={ProductVN.filter.zone.placeholder}
          mode='multiple'
        />
      </Col>
      <Col {...ColProps}>
        <Label className='form-label'>Tỉnh/thành phố</Label>
        <FormSelect
          options={
            lstProvionce?.filter((e) => filter.sub_zone_ids?.includes(e.subZoneId))?.length > 0
              ? [{ label: 'Tất cả', value: 'all' }].concat(
                  lstProvionce
                    ?.filter((e) => filter.sub_zone_ids?.includes(e.subZoneId))
                    ?.map((item) => ({
                      value: item.id,
                      label: item.name
                    }))
                )
              : []
          }
          value={filter.province_ids}
          onChange={(value, label) => {
            onFilterChange('province_ids', value)
            onFilterChange(
              'province_names',
              label.map((e) => e.label)
            )
            onFilterChange('district_ids', [])
            onFilterChange('ward_ids', [])
            onFilterChange('district_names', [])
            onFilterChange('ward_names', [])
          }}
          onSelect={(value) => {
            const all = lstProvionce?.filter((e) => filter.sub_zone_ids?.includes(e.subZoneId))?.map((item) => item.id)
            const all_names = lstProvionce
              ?.filter((e) => filter.sub_zone_ids?.includes(e.subZoneId))
              ?.map((item) => item.name)
            if (value && value === 'all') {
              onFilterChange('province_ids', [...all])
              onFilterChange('province_names', [...all_names])
            }
          }}
          placeholder='Tất cả'
          mode='multiple'
        />
      </Col>
      <Col {...ColProps}>
        <Label className='form-label'>Quận/huyện</Label>
        <FormSelect
          options={
            lstDistrict?.filter((e) => filter.province_ids?.includes(e.parentId))?.length > 0
              ? [{ label: 'Tất cả', value: 'all' }].concat(
                  lstDistrict
                    ?.filter((e) => filter.province_ids?.includes(e.parentId))
                    ?.map((item) => ({
                      value: item.id,
                      label: item.name
                    }))
                )
              : []
          }
          value={filter.district_ids}
          onChange={(value, label) => {
            onFilterChange('district_ids', value)
            onFilterChange(
              'district_names',
              label.map((e) => e.label)
            )
            onFilterChange('ward_ids', [])
            onFilterChange('ward_names', [])
          }}
          onSelect={(value) => {
            const all = lstDistrict?.filter((e) => filter.province_ids?.includes(e.parentId))?.map((item) => item.id)
            const all_names = lstDistrict
              ?.filter((e) => filter.province_ids?.includes(e.parentId))
              ?.map((item) => item.name)
            if (value && value === 'all') {
              onFilterChange('district_ids', [...all])
              onFilterChange('district_names', [...all_names])
            }
          }}
          placeholder='Tất cả'
          mode='multiple'
        />
      </Col>
      <Col {...ColProps}>
        <Space>
          <button
            type='button'
            className='btn btn-soft-danger'
            onClick={() => {
              onResetFilter()
              handleResetFilter()
            }}
          >
            Xóa tìm kiếm
          </button>
          <button type='button' className='btn btn-primary' onClick={() => handleFilter(filter)}>
            Tìm kiếm
          </button>
        </Space>
      </Col>
      <Col flex='1'>
        <Flex gap='small' justify='flex-end'>
          <button className='btn btn-primary me-2 pe-4' onClick={() => handleExport(filter)}>
            <i className='mdi mdi-database-export me-1 ms-2' />
            Export
          </button>
        </Flex>
      </Col>
    </Row>
  )
}

export default ReportAccountRankFilter
