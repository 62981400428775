import { call, put, takeEvery } from "redux-saga/effects"

import { GET_MANAGE_ACTIVATION, GET_ACTIVATION_DETAIL, GET_KTVS } from "./actionTypes"
import { getManageActivationSuccess, getManageActivationFail, getActivationDetailSuccess } from "./actions"

import { getActivationListAPI, getActivationDetailsAPI } from "helpers/backend_helper"

function* fetchActivationList({ payload: params }) {
    try {
        const response = yield call(getActivationListAPI, { ...params })
        yield put(getManageActivationSuccess(response))
    } catch (error) {
        yield put(getManageActivationFail(error?.data?.message))
    }
}

function* fetchActivationDetail({ payload: data }) {
    try {
        const response = yield call(getActivationDetailsAPI, { product_id: data?.productId, user_id: data?.userRewardUserId })
        yield put(getActivationDetailSuccess(response.data))
    } catch (error) {
        console.log(error)
        // yield put(getActivationDetailFail(error))
    }
}

function* ActivationsSaga() {
    yield takeEvery(GET_MANAGE_ACTIVATION, fetchActivationList)
    yield takeEvery(GET_ACTIVATION_DETAIL, fetchActivationDetail)
}

export default ActivationsSaga
