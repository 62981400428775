import React, { useEffect, useState } from 'react'
import { Col, Label } from 'reactstrap'

//flatpickr
import 'flatpickr/dist/themes/material_blue.css'
import { LocalityVN } from 'constants/vn'
import { FormSelect } from './form-select/FormSelect'
import { optionsGeographicalLevel, AREA_TYPE } from 'pages/ManageLocality/constants'
import { getZoneAPI, getAreaManagementOptionsAPI } from 'helpers/backend_helper'

const BankListGlobalFilter = ({ setGlobalFilter, search, setSearch }) => {
  const [filter, setFilter] = useState({})
  const [zone, setZone] = useState([])
  const [subZone, setSubZone] = useState([])
  const [selectedZone, setSelectedZone] = useState()

  useEffect(() => {
    handleGetAllZone()
  }, [])

  useEffect(() => {
    if (selectedZone) {
      handleGetSubZone()
    }
  }, [selectedZone])

  const handleSelect = (value, key) => {
    setFilter((e) => ({ ...e, [key]: value }))
  }

  const handleGetAllZone = async () => {
    const res = await getZoneAPI()

    if (res.data) {
      res.data.map((item) => {
        setZone((e) => [...e, { value: item.id, label: item.name }])
      })
    }
  }

  const handleGetSubZone = async () => {
    const res = await getAreaManagementOptionsAPI({ type: AREA_TYPE.SUBZONE, parent_id: selectedZone })

    if (res.data) {
      const list_data = res.data.map((item) => {
        return {
          value: item.id,
          label: item.name
        }
      })

      setSubZone(list_data)
    }
  }

  return (
    <React.Fragment>
      <Col xxl={2} lg={2} className='mt-3'></Col>
      <Col xxl={2} lg={2} className='mt-3'></Col>

      <Col xxl={2} lg={2} className='mt-3'></Col>

      <Col xxl={4} lg={4} className='d-flex align-items-end justify-content-end mt-3 '>
        <div className='mb-xxl-0 me-2'>
          <button
            type='button'
            className='btn btn-soft-danger w-100'
            onClick={() => {
              setFilter({
                status: undefined,
                province_id: undefined
              })
              setSearch('')
              setGlobalFilter({})
            }}
          >
            Xóa tìm kiếm
          </button>
        </div>
        <div className='mb-xxl-0'>
          <button
            type='button'
            className='btn btn-primary w-100'
            onClick={() => {
              const _filter = !filter
                ? filter
                : Object.fromEntries(Object.entries(filter).filter(([key, value]) => value !== undefined))
              setGlobalFilter({ ..._filter, search })
            }}
          >
            Tìm kiếm
          </button>
        </div>
      </Col>
    </React.Fragment>
  )
}
export default BankListGlobalFilter
