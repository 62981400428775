export const AREA_TYPE = {
  ZONE: 'zone',
  SUBZONE: 'subzone',
  PROVINCE: 'province',
  DISTRICT: 'district',
  WARD: 'ward',
}

export const optionsGeographicalLevel = [
  {
    label: 'Miền',
    value: AREA_TYPE.ZONE,
  },
  {
    label: 'Vùng',
    value: AREA_TYPE.SUBZONE,
  },
  {
    label: 'Tỉnh/Thành phố',
    value: AREA_TYPE.PROVINCE,
  },
  {
    label: 'Quận/Huyện',
    value: AREA_TYPE.DISTRICT,
  },
  {
    label: 'Phường/Xã',
    value: AREA_TYPE.WARD,
  },
]