import { PortfolioVN } from "constants/vn"
import React from "react"
import { useSelector } from "react-redux"
import { Badge, Input, UncontrolledTooltip } from "reactstrap"
import { createSelector } from "reselect"
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"

const PortfolioNo = cell => {
    return (
        <div className="d-flex align-items-center">
            {!!cell?.value ? <i className="mdi mdi-18px mdi-database-import" /> : <div />}
            <div className="text-body fw-bold">{cell?.row ? cell.row.index + 1 + (cell.page - 1) * 10 : ""}</div>
        </div>
    )
}

const PortfolioType = cell => {
    return cell.value ? PortfolioVN.typeOptions[cell.value].label : "--"
}

const PortfolioName = cell => {
    return cell.value ? (
        <div style={{ maxWidth: 430 }}>
            <span
                style={{ maxWidth: 480 }}
                className="d-inline-block text-truncate"
                id={`nameshop-tooltip-${cell.row.original.id + cell.row.original.type}`}
            >
                {cell.value}
            </span>
            <UncontrolledTooltip trigger="hover" placement="top" target={`nameshop-tooltip-${cell.row.original.id + cell.row.original.type}`}>
                {cell.value}
            </UncontrolledTooltip>
        </div>
    ) : (
        "--"
    )
}

const ParentPortfolio = cell => {
    return cell.value ? (
        <div style={{ maxWidth: 480 }}>
            <span
                style={{ maxWidth: 540 }}
                className="d-inline-block text-truncate"
                id={`name-parent-tooltip-${cell.row.original.id + cell.row.original.type}`}
            >
                {cell.value}
            </span>
            <UncontrolledTooltip trigger="hover" placement="top" target={`name-parent-tooltip-${cell.row.original.id + cell.row.original.type}`}>
                {cell.value}
            </UncontrolledTooltip>
        </div>
    ) : (
        "--"
    )
}

const CodePortfolio = cell => {
    return cell?.row?.original?.type === "model" ? (
        cell?.value ? (
            <Badge className="badge-soft-info">{PortfolioVN.haveCode}</Badge>
        ) : (
            <Badge className="badge-soft-warning">{PortfolioVN.noCode}</Badge>
        )
    ) : (
        "--"
    )
}

const AccumulatePortfolio = cell => {
    return cell?.row?.original?.type === "model" ? <Input type="checkbox" defaultChecked={cell?.value} disabled /> : "--"
}

const ActivedPortfolio = cell => {
    return cell?.row?.original?.type === "model" ? <Input type="checkbox" defaultChecked={cell?.value} disabled /> : "--"
}

export { PortfolioNo, PortfolioType, ParentPortfolio, PortfolioName, CodePortfolio, AccumulatePortfolio, ActivedPortfolio }
