import { ProductActivationVN } from "constants/vn"
import { formateDate } from "helpers/global_helper"
import React from "react"
import { Badge, Input } from "reactstrap"
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"

const ProductActivationCode = cell => {
    return cell.value
}

const ProductActivationIndustry = cell => {
    return cell.value ? cell.value.name : "--"
}

const ProductActivationIndustrySector = cell => {
    return cell.value ? cell.value.name : "--"
}

const ProductActivationTime = cell => {
    return cell.value ? formateDate(cell.value) + " - " + formateDate(cell.row.original.endApplyDate) : "--"
}

const StatusProductActivation = cell => {
    switch (cell?.value) {
        case "activate":
            return <Badge className="badge-soft-info">{ProductActivationVN.active}</Badge>
        case "inactivate":
            return <Badge className="badge-soft-warning">{ProductActivationVN.inActive}</Badge>
        case "temporary_deleted":
            return <Badge className="badge-soft-danger">{ProductActivationVN.locked}</Badge>
        default:
            return <Badge className="badge-soft-info">{ProductActivationVN.active}</Badge>
    }
}

export { ProductActivationTime, ProductActivationIndustrySector, ProductActivationCode, ProductActivationIndustry, StatusProductActivation }
