import { call, put, takeEvery } from 'redux-saga/effects'

import { GET_MANAGE_HISTORY, GET_HISTORY_DETAIL, GET_KTVS } from './actionTypes'
import { getManageHistorySuccess, getManageHistoryFail, getHistoryDetailSuccess } from './actions'

import { getHistoryListAPI } from 'helpers/backend_helper'

function* fetchHistoryList({ payload: params }) {
  try {
    const response = yield call(getHistoryListAPI, { ...params })
    yield put(getManageHistorySuccess(response))
  } catch (error) {
    yield put(getManageHistoryFail(error?.data?.message))
  }
}

function* HistorysSaga() {
  yield takeEvery(GET_MANAGE_HISTORY, fetchHistoryList)
}

export default HistorysSaga
