import React, { useEffect, useState } from 'react'
import { Col, Label } from 'reactstrap'

//flatpickr
import 'flatpickr/dist/themes/material_blue.css'
import { ProductVN, ManageShopVN } from 'constants/vn'
import { createSelector } from 'reselect'
import { useSelector } from 'react-redux'
import { StatusActivationOptions, TypePortfolioOptions } from 'constants/constants'
import { FormSelect } from './form-select/FormSelect'
import { getPortfolioAllAPI } from 'helpers/backend_helper'
import { FormInput } from './form-input/FormInput'

const ProductActivationFilter = ({ setGlobalFilter, search, setSearch }) => {
  const selectLayoutState = (state) => state.Layout
  const LayoutProperties = createSelector(selectLayoutState, (app) => ({
    zone: app.zone
  }))
  const { zone } = useSelector(LayoutProperties)

  const [filter, setFilter] = useState({})

  const handleSelect = (value, key) => {
    setFilter((e) => ({ ...e, [key]: value }))
  }
  const [industrySector, setIndustrysector] = useState()
  const [industrySectorOptions, setIndustrySectorOptions] = useState()

  const getDataOptions = async () => {
    try {
      const resIndustrysector = await getPortfolioAllAPI({ type: 'industrySector' })
      setIndustrysector(resIndustrysector?.data)

      if (resIndustrysector?.data?.length > 0) {
        setIndustrySectorOptions(
          [{ label: 'Tất cả', value: 'all' }].concat(
            resIndustrysector?.data?.map((item) => ({
              value: item.id,
              label: item.name
            }))
          )
        )
      } else {
        setIndustrySectorOptions([])
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getDataOptions()
  }, [])

  return (
    <React.Fragment>
      <Col xxl={3} lg={4} xs={6} className='mt-5'>
        <Label className='form-label'>Nhập từ khóa tìm kiếm</Label>
        <FormInput
          className='w-100'
          // maxLength={MAX_LENGTH_SEARCH}
          placeholder='Nhập từ khóa tìm kiếm'
          value={filter?.search_key || ''}
          name='search_key'
          id={`input-filter`}
          onChange={(e) => handleSelect(e.target.value, 'search_key')}
          onBlur={(e) => handleSelect(e.target.value, 'search_key')}
        />
      </Col>

      <Col xxl={3} lg={4} xs={6} className='mt-5'>
        <Label className='form-label'>{ProductVN.filter.industrySector.label}</Label>
        <FormSelect
          options={industrySectorOptions}
          value={filter.industry_sector_ids}
          onChange={(value, label) => {
            handleSelect(value, 'industry_sector_ids')
            handleSelect(
              label.map((e) => e.label),
              'industry_sector_names'
            )
          }}
          onSelect={(value) => {
            const all = industrySector?.map((item) => item.id)
            const all_names = industrySector?.map((item) => item.name)

            if (value && value === 'all') {
              handleSelect([...all], 'industry_sector_ids')
              handleSelect([...all_names], 'industry_sector_names')
            }
          }}
          placeholder={ProductVN.filter.industrySector.placeholder}
          mode='multiple'
        />
      </Col>

      <Col xxl={3} lg={4} xs={6} className='mt-5'>
        <Label className='form-label'>{ProductVN.filter.status.label}</Label>
        <FormSelect
          options={StatusActivationOptions?.map((item) => ({
            value: item.value,
            label: item.label
          }))}
          value={filter.status}
          onChange={(value, label) => {
            handleSelect(value, 'status')
            handleSelect(label?.label, 'status_name')
          }}
          placeholder={ProductVN.filter.status.placeholder}
        />
      </Col>

      <Col xxl={2} lg={4} xs={6} className='d-flex align-items-end justify-content mt-5'>
        <div className='mb-xxl-0 me-2'>
          <button
            type='button'
            className='btn btn-soft-danger w-100'
            onClick={() => {
              setFilter({
                industry_sector_ids: undefined,
                status: undefined
              })
              setSearch(undefined)
              setGlobalFilter({})
            }}
          >
            Xóa tìm kiếm
          </button>
        </div>
        <div className='mb-xxl-0'>
          <button
            type='button'
            className='btn btn-primary w-100'
            onClick={() => {
              const _filter = !filter
                ? filter
                : Object.fromEntries(Object.entries(filter).filter(([key, value]) => value !== undefined))
              setGlobalFilter({ ..._filter, search })
            }}
          >
            Tìm kiếm
          </button>
        </div>
      </Col>
    </React.Fragment>
  )
}
export default ProductActivationFilter
