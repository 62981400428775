import React from "react"
import ReactDOM from "react-dom/client"
import App from "./App"
import * as serviceWorker from "./serviceWorker"
import { BrowserRouter } from "react-router-dom"
import { Provider } from "react-redux"

import store from "./store"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import ThemeProvider from "ThemeProvider"

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
    <Provider store={store}>
        <React.Fragment>
            <BrowserRouter>
                <ThemeProvider>
                    <App />
                </ThemeProvider>
                <ToastContainer theme="colored" />
            </BrowserRouter>
        </React.Fragment>
    </Provider>
)

serviceWorker.unregister()
