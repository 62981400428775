
import React, { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import PropTypes from "prop-types"

//actions
import { changeLayout, changeTopbarTheme, changeLayoutWidth, changeLayoutMode } from "../../store/actions"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"

//components
import Navbar from "./Navbar"
import Header from "./Header"
import Footer from "./Footer"

const Layout = props => {
    const dispatch = useDispatch()
    const selectLayoutState = state => state.Layout

    const selectLayoutProperties = createSelector(selectLayoutState, layout => ({
        topbarTheme: layout.topbarTheme,
        layoutWidth: layout.layoutWidth,
        isPreloader: layout.isPreloader,
        layoutModeType: layout.layoutModeType,
    }))
    const { topbarTheme, layoutModeType, layoutWidth, isPreloader } = useSelector(selectLayoutProperties)

    /*
  document title
  */

    const pathName = useLocation()

    useEffect(() => {
        const title = pathName.pathname
        let currentage = title.charAt(1).toUpperCase() + title.slice(2)

        document.title = currentage + " | HPG"
    }, [pathName.pathname])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    /*
  layout settings
  */
    useEffect(() => {
        dispatch(changeLayout("horizontal"))
    }, [dispatch])

    useEffect(() => {
        //init body click event fot toggle rightbar
        if (isPreloader === true) {
            document.getElementById("preloader").style.display = "block"
            document.getElementById("status").style.display = "block"

            setTimeout(function () {
                document.getElementById("preloader").style.display = "none"
                document.getElementById("status").style.display = "none"
            }, 2500)
        } else {
            document.getElementById("preloader").style.display = "none"
            document.getElementById("status").style.display = "none"
        }
    }, [isPreloader])

    useEffect(() => {
        if (layoutModeType) {
            dispatch(changeLayoutMode(layoutModeType))
        }
    }, [dispatch, layoutModeType])

    useEffect(() => {
        if (topbarTheme) {
            dispatch(changeTopbarTheme(topbarTheme))
        }
    }, [dispatch, topbarTheme])

    useEffect(() => {
        if (layoutWidth) {
            dispatch(changeLayoutWidth(layoutWidth))
        }
    }, [dispatch, layoutWidth])

    const [isMenuOpened, setIsMenuOpened] = useState(false)
    const openMenu = () => {
        setIsMenuOpened(!isMenuOpened)
    }

    return (
        <React.Fragment>
            <div id="preloader">
                <div id="status">
                    <div className="spinner-chase">
                        <div className="chase-dot" />
                        <div className="chase-dot" />
                        <div className="chase-dot" />
                        <div className="chase-dot" />
                        <div className="chase-dot" />
                        <div className="chase-dot" />
                    </div>
                </div>
            </div>

            <div id="layout-wrapper">
                <Header theme={topbarTheme} isMenuOpened={isMenuOpened} openLeftMenuCallBack={openMenu} />
                <Navbar menuOpen={isMenuOpened} />
                <div className="main-content">{props.children}</div>
                <Footer />
            </div>
        </React.Fragment>
    )
}

Layout.propTypes = {
    changeLayout: PropTypes.func /*  */,
    changeLayoutWidth: PropTypes.func,
    changeTopbarTheme: PropTypes.func,
    children: PropTypes.any,
    isPreloader: PropTypes.any,
    layoutWidth: PropTypes.any,
    location: PropTypes.object,
    topbarTheme: PropTypes.any,
}

export default withRouter(Layout)
