import { call, put, takeEvery } from "redux-saga/effects"

import { GET_MANAGE_LOCALITY, GET_LOCALITY_DETAIL } from "./actionTypes"
import { getManageLocalitySuccess, getManageLocalityFail, getLocalityDetailSuccess } from "./actions"

import { getLocalityListAPI, getLocalityDetailsAPI } from "helpers/backend_helper"

function* fetchLocalityList({ payload: params }) {
    try {
        const response = yield call(getLocalityListAPI, { ...params })
        yield put(getManageLocalitySuccess(response))
    } catch (error) {
        yield put(getManageLocalityFail(error?.data?.message))
    }
}

function* fetchLocalityDetail({ payload: data }) {
    try {
        const response = yield call(getLocalityDetailsAPI, { id: data.id, type: data.type })
        yield put(getLocalityDetailSuccess(response.data))
    } catch (error) {
        console.log(error)
        // yield put(getProductDetailFail(error))
    }
}

function* LocalitysSaga() {
    yield takeEvery(GET_MANAGE_LOCALITY, fetchLocalityList)
    yield takeEvery(GET_LOCALITY_DETAIL, fetchLocalityDetail)
}

export default LocalitysSaga
