import { call, put, takeEvery } from 'redux-saga/effects'

import {
  GET_MANAGE_CONTENT,
  GET_CONTENT_DETAIL,
  GET_REPORT_USER_STATISTIC,
  GET_NOTIFICATION_DETAIL
} from './actionTypes'
import { getReportUserStatisticSuccess, getReportUserStatisticFail } from './actions'

import { getNotificationDetailAPI, getNotificationsAPI, getReportUserStatisticAPI } from 'helpers/backend_helper'

function* fetchReportUserStatisticList({ payload: params }) {
  try {
    const response = yield call(getReportUserStatisticAPI, params)
    yield put(getReportUserStatisticSuccess(response))
  } catch (error) {
    yield put(getReportUserStatisticFail(error?.data?.message))
  }
}

function* ReportUserStatisticSaga() {
  yield takeEvery(GET_REPORT_USER_STATISTIC, fetchReportUserStatisticList)
}

export default ReportUserStatisticSaga
