import {
    GET_MANAGE_PRODUCT_ACTIVATION,
    GET_MANAGE_PRODUCT_ACTIVATION_FAIL,
    GET_MANAGE_PRODUCT_ACTIVATION_SUCCESS,
    GET_PRODUCT_ACTIVATION_DETAIL,
    GET_PRODUCT_ACTIVATION_DETAIL_SUCCESS,
    GET_PRODUCT_ACTIVATION_DETAIL_FAIL,
    SET_TYPE_POPUP,
} from "./actionTypes"

export const getProductActivationList = params => ({
    type: GET_MANAGE_PRODUCT_ACTIVATION,
    payload: params,
})

export const getManageProductActivationSuccess = data => ({
    type: GET_MANAGE_PRODUCT_ACTIVATION_SUCCESS,
    payload: data,
})

export const getManageProductActivationFail = error => ({
    type: GET_MANAGE_PRODUCT_ACTIVATION_FAIL,
    payload: error,
})

export const getProductActivationDetail = data => ({
    type: GET_PRODUCT_ACTIVATION_DETAIL,
    payload: data,
})

export const getProductActivationDetailSuccess = data => ({
    type: GET_PRODUCT_ACTIVATION_DETAIL_SUCCESS,
    payload: data,
})

export const getProductActivationDetailFail = error => ({
    type: GET_PRODUCT_ACTIVATION_DETAIL_FAIL,
    payload: error,
})

export const setTypePopupProductActivation = params => ({
    type: SET_TYPE_POPUP,
    payload: params,
})
