import React from 'react'
import { Input } from 'antd'

export const FormInput = ({ ...props }) => {
  const { disabled, onChange, ...rest } = props
  // const [field, meta, helpers] = useField({ name })

  return (
    <Input
      autoFocus
      style={{ width: '100%' }}
      disabled={disabled}
      size='large'
      onChange={onChange}
      onBlur={(value) => {
        onChange?.(value?.target?.value ? value?.target?.value?.trim() : value?.target?.value)
      }}
      {...rest}
    />
  )
}
