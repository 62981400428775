import { GET_REPORT_ACCOUNT_RANK, GET_REPORT_ACCOUNT_RANK_FAIL, GET_REPORT_ACCOUNT_RANK_SUCCESS } from './actionTypes'

export const getReportAccountRank = (params) => ({
  type: GET_REPORT_ACCOUNT_RANK,
  payload: params
})

export const getReportAccountRankSuccess = (jobs) => ({
  type: GET_REPORT_ACCOUNT_RANK_SUCCESS,
  payload: jobs
})

export const getReportAccountRankFail = (error) => ({
  type: GET_REPORT_ACCOUNT_RANK_FAIL,
  payload: error
})
