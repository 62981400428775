import React, { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'

//redux
import { useSelector, useDispatch } from 'react-redux'
import { createSelector } from 'reselect'

import { Col, Row, UncontrolledTooltip, Card, CardBody, Label } from 'reactstrap'
import Spinners from 'components/Common/Spinner'
import { toast } from 'react-toastify'
import TableContainer from 'components/Common/TableContainer'
import { PermissionVN } from 'constants/vn'
import ConfirmModal from 'components/Common/ConfirmModal'
import { deletePermissionAPI } from 'helpers/backend_helper'
import { PermissionNo, PermissionRightsGroup, PermissionDescribe, PermissionUpdateDate } from './ManagePermissionCol'
import AddEditPermission from './components/AddEditPermission'
import { getPermissionDetail, getPermissionList, setFilter, setTypePopupPermission } from 'store/actions'
import ImportModal from 'components/Common/ImportModal'
import { FormInput } from 'components/Common/form-input/FormInput'
import { ROLE_MANAGEMENT_CODE, SCREEN_CODE } from 'constants/permission-code'
import usePermission from 'hooks/usePermission'
import PermissionWrapper from 'components/Common/PermissionWrapper'

function ManagePermission() {
  //meta title
  document.title = 'Permission List | HPG'
  const screenCode = useMemo(() => SCREEN_CODE.ROLE_MANAGEMENT, [])
  const hasPermission = usePermission({ screenCode, code: ROLE_MANAGEMENT_CODE.ADMIN_DETAIL_ROLE })

  const dispatch = useDispatch()

  const selectPermissionsState = (state) => state.PermissionReducer
  const PermissionsProperties = createSelector(selectPermissionsState, (reducer) => ({
    permissions: reducer.permissions,
    loading: reducer.loading,
    total: reducer.total
  }))

  const { permissions, loading, total } = useSelector(PermissionsProperties)
  const [valueFilter, setValueFilter] = useState()
  const [isLoading, setLoading] = useState(loading)
  const [showDeleteAccount, setShowDeleteAccount] = useState(false)
  const [selected, setSelected] = useState()
  const [showForm, setShowForm] = useState(false)
  const [showImport, setShowImport] = useState(false)
  const [page, setPage] = useState(1)

  useEffect(() => {
    dispatch(getPermissionList())
  }, [])

  const onClickDelete = (item) => {
    setSelected(item)
    setShowDeleteAccount(true)
  }

  const onClickItem = async (item, type) => {
    setSelected(item)
    dispatch(setTypePopupPermission(type))
    dispatch(getPermissionDetail(item))
    setShowForm(true)
  }
  const columns = useMemo(
    () => [
      {
        Header: PermissionVN.table.STT,
        accessor: 'extTransId',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return (page - 1) * 10 + cellProps.row.index + 1
        }
      },
      {
        Header: PermissionVN.table.rightsGroup,
        accessor: 'name',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <PermissionRightsGroup {...cellProps} />
        }
      },
      {
        Header: PermissionVN.table.describe,
        accessor: 'description',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <PermissionDescribe {...cellProps} />
        }
      },
      {
        Header: PermissionVN.table.updateDate,
        accessor: 'updatedAt',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <PermissionUpdateDate {...cellProps} />
        }
      },
      {
        Header: PermissionVN.table.action,
        accessor: 'action',
        disableSortBy: true,
        hideSort: true,
        show: hasPermission,
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <ul className='list-unstyled hstack gap-1 mb-0 '>
              <li data-bs-toggle='tooltip' data-bs-placement='top' title='View'>
                <Link
                  to={`#`}
                  className='btn btn-sm btn-soft-primary'
                  id={`viewtooltip-${cellProps.row.original.id}`}
                  onClick={() => {
                    onClickItem(cellProps.row.original, 'view')
                  }}
                >
                  <i className='mdi mdi-eye-outline' />
                  <UncontrolledTooltip
                    trigger='hover'
                    placement='top'
                    target={`viewtooltip-${cellProps.row.original.id}`}
                  >
                    {'Xem'}
                  </UncontrolledTooltip>
                </Link>
              </li>
              <PermissionWrapper screenCode={screenCode} code={ROLE_MANAGEMENT_CODE.ADMIN_UPDATE_ROLE}>
                <li data-bs-toggle='tooltip' data-bs-placement='top' title='View'>
                  <button
                    // disabled={!!cellProps.row.original?.extTransId}
                    className={`btn btn-sm btn-soft-info ${!!cellProps.row.original?.extTransId && 'opacity-50'}`}
                    id={`edittooltip-${cellProps.row.original.code}`}
                    onClick={() => {
                      if (!cellProps.row.original?.extTransId) {
                        onClickItem(cellProps.row.original, 'edit')
                      }
                    }}
                  >
                    <i className='mdi mdi-pencil-outline' />
                    <UncontrolledTooltip
                      trigger='hover'
                      placement='top'
                      target={`edittooltip-${cellProps.row.original.code}`}
                    >
                      {!cellProps.row.original.extTransId ? 'Sửa' : 'Dữ liệu được import từ Bravo không thể sửa'}
                    </UncontrolledTooltip>
                  </button>
                </li>
              </PermissionWrapper>
              <PermissionWrapper screenCode={screenCode} code={ROLE_MANAGEMENT_CODE.ADMIN_DELETE_ROLE}>
                <li>
                  <button
                    // disabled={!!cellProps.row.original?.extTransId}
                    className={`btn btn-sm btn-soft-danger ${!!cellProps.row.original?.extTransId && 'opacity-50'}`}
                    onClick={() => {
                      if (!cellProps.row.original?.extTransId) {
                        const item = cellProps.row.original
                        onClickDelete(item)
                      }
                    }}
                    id={`deletetooltip-${cellProps.row.original.code}`}
                  >
                    <i className='mdi mdi-delete-outline' />
                    <UncontrolledTooltip
                      trigger='hover'
                      placement='top'
                      target={`deletetooltip-${cellProps.row.original.code}`}
                    >
                      {!cellProps.row.original.extTransId ? 'Xóa' : 'Dữ liệu được import từ Bravo không thể xóa'}
                    </UncontrolledTooltip>
                  </button>
                </li>
              </PermissionWrapper>
            </ul>
          )
        }
      }
    ],
    [page, hasPermission]
  )

  const permissionColumns = useMemo(() => columns.filter((column) => column.show !== false), [columns])

  const goToPage = (page) => {
    dispatch(getPermissionList({ search: valueFilter, page }))
    setPage(page)
  }

  const onDeleteAccount = async () => {
    try {
      const res = await deletePermissionAPI({
        id: selected.id
      })
      if (res) {
        toast('Xóa nhóm quyền thành công', { type: 'success' })
        setShowDeleteAccount(false)
        dispatch(getPermissionList({ search: valueFilter, page }))
        dispatch(getPermissionList({ search: valueFilter, page }))
      }
    } catch (error) {
      toast(error.response.data.message, { type: 'error' })
    }
  }

  const handleSearch = (value) => {
    dispatch(getPermissionList({ search: value }))
  }

  return (
    <React.Fragment>
      <div className='page-content'>
        <div className='page-title-box container-fluid'>
          <h4 className=' mb-sm-0 font-size-18 pb-4'>{PermissionVN.title}</h4>
          {isLoading ? (
            <Spinners setLoading={setLoading} />
          ) : (
            <Row>
              <Col lg='12'>
                <Card>
                  <CardBody className='border-bottom'>
                    <div className='d-flex align-items-center'>
                      <h5 className='mb-0 card-title flex-grow-1'>{PermissionVN.list}</h5>
                      <div className='flex-shrink-0'>
                        <PermissionWrapper screenCode={screenCode} code={ROLE_MANAGEMENT_CODE.ADMIN_CREATE_ROLE}>
                          <button
                            onClick={() => {
                              setShowForm(true)
                              dispatch(setTypePopupPermission('create'))
                            }}
                            className='btn btn-primary'
                          >
                            <i className='mdi mdi-plus me-1' />
                            {PermissionVN.add}
                          </button>
                        </PermissionWrapper>
                      </div>
                    </div>
                  </CardBody>
                  <CardBody>
                    <Row className='align-items-end' style={{ justifyContent: 'space-between' }}>
                      <Col className='mb-2' xxl={3} lg={6}>
                        <Label className='form-label'>{PermissionVN.filter.title}</Label>

                        <FormInput
                          maxLength={50}
                          value={valueFilter || ''}
                          placeholder={PermissionVN.filter.placeholderSearch}
                          onChange={(e) => {
                            if (e.target.value != '') {
                              setValueFilter(e.target.value)
                            } else {
                              if (valueFilter.length == 1) {
                                setValueFilter(e.target.value)
                              } else {
                                setValueFilter(e.target.value)
                                handleSearch('')
                              }
                            }
                          }}
                          onBlur={(e) => {
                            setValueFilter(e.target.value.trim())
                          }}
                        />
                      </Col>
                      <Col xxl={2} xl={2} lg={3}>
                        <button
                          type='button'
                          className='btn btn-primary w-100 mb-2'
                          onClick={() => handleSearch(valueFilter)}
                        >
                          Tìm kiếm
                        </button>
                      </Col>
                    </Row>
                    <TableContainer
                      columns={permissionColumns}
                      data={permissions}
                      isGlobalFilter={false}
                      isPagination={true}
                      iscustomPageSizeOptions={false}
                      isShowingPageLength={false}
                      customPageSize={10}
                      tableClass='table-bordered align-middle nowrap mt-2'
                      paginationDiv='col-sm-12 col-md-7'
                      pagination='pagination justify-content-end pagination-rounded'
                      useAPIChangePaging
                      gotoPageCustom={goToPage}
                      currentPage={page}
                      total={total}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </div>
      </div>
      <ConfirmModal
        show={showDeleteAccount}
        icon={
          <div className='avatar-title rounded-circle bg-danger text-light bg-opacity-60 font-size-20 rounded-3'>
            <i className='mdi mdi-delete-outline'></i>
          </div>
        }
        onConfirmClick={onDeleteAccount}
        onCloseClick={() => setShowDeleteAccount(false)}
        title='Xóa nhóm quyền'
        description={`Bạn có chắc chắn muốn xóa nhóm quyền?`}
      />
      {showForm && (
        <AddEditPermission
          onCloseClick={() => {
            setShowForm(false)
          }}
          selected={selected}
          show={showForm}
          valueFilter={valueFilter}
          setPage={setPage}
        />
      )}
      <ImportModal
        onCloseClick={() => {
          setShowImport(false)
        }}
        show={showImport}
      />
    </React.Fragment>
  )
}
export default ManagePermission
