import { call, put, takeEvery } from "redux-saga/effects"

import { GET_MANAGE_GUEST, GET_GUEST_DETAIL } from "./actionTypes"
import { getManageGuestSuccess, getManageGuestFail, getGuestDetailSuccess } from "./actions"

import { getGuestListAPI, getGuestDetailsAPI } from "helpers/backend_helper"

function* fetchGuestList({ payload: params }) {
    try {
        const response = yield call(getGuestListAPI, { ...params })
        yield put(getManageGuestSuccess(response))
    } catch (error) {
        yield put(getManageGuestFail(error?.data?.message))
    }
}

function* fetchGuestDetail({ payload: data }) {
    try {
        const response = yield call(getGuestDetailsAPI, { id: data.id })
        yield put(getGuestDetailSuccess(response.data))
    } catch (error) {
        console.log(error)
        // yield put(getProductDetailFail(error))
    }
}

function* GuestsSaga() {
    yield takeEvery(GET_MANAGE_GUEST, fetchGuestList)
    yield takeEvery(GET_GUEST_DETAIL, fetchGuestDetail)
}

export default GuestsSaga
