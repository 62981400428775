import PropsType from 'prop-types'
import { useEffect, useMemo, useState } from 'react'
import { Label, UncontrolledTooltip } from 'reactstrap'
import React from 'react'
import { Row, Col, Space, Flex, Form } from 'antd'

//flatpickr
import { ActivationVN, CommonVN, ProductVN } from 'constants/vn'

import { DatePicker } from 'antd'
import { FormInput } from 'components/Common/form-input/FormInput'
import { FormSelect } from 'components/Common/form-select/FormSelect'
import { MAX_LENGTH_SEARCH, TypeProgramOptions } from 'constants/constants'
import { createSelector } from 'reselect'
import { useSelector } from 'react-redux'
import { FormDateRange } from 'components/Common/form-date-range/FormDateRange'
import { getPortfolioAllAPI } from 'helpers/backend_helper'

const { RangePicker } = DatePicker

ManageGuestFilter.propTypes = {
  handleFilter: PropsType.func,
  handleConfirm: PropsType.func,
  handleResetFilter: PropsType.func,
  handleExport: PropsType.func
}

function ManageGuestFilter({ handleFilter, handleConfirm, handleResetFilter, handleExport }) {
  const selectLayoutState = (state) => state.Layout
  const LayoutProperties = createSelector(selectLayoutState, (app) => ({
    zone: app.zone,
    subzone: app.subzone,
    province: app.province,
    district: app.district,
    ward: app.ward,
    myLocationManagement: app.myLocationManagement
  }))
  const { zone, subzone, province, district, myLocationManagement, ward } = useSelector(LayoutProperties)

  const [filter, setFilter] = useState({})
  const [industry, setIndustry] = useState()
  const [industrySector, setIndustrysector] = useState()
  const [category, setCategory] = useState()
  const [form] = Form.useForm()
  const watchType = Form.useWatch('type', form)
  const watchForm = Form.useWatch([], form)
  const [model, setModel] = useState()

  const onFilterChange = (name, value) =>
    setFilter((prev) => ({
      ...prev,
      [name]: value
    }))

  const ColProps = useMemo(() => ({
    xs: { span: 24 },
    xxl: { span: 4 }
  }))

  const onResetFilter = () =>
    setFilter({
      serial: '',
      model_name: '',
      shop_name: '',
      phone: '',
      zone_ids: undefined,
      sub_zone_ids: undefined,
      province_ids: undefined,
      district_ids: undefined,
      ward_ids: undefined,
      client: undefined,
      start_date1: undefined,
      end_date1: undefined,
      industry_ids: undefined,
      industry_sector_ids: undefined,
      category_ids: undefined,
      zone_names: undefined,
      sub_zone_names: undefined,
      province_names: undefined,
      district_names: undefined,
      ward_names: undefined
    })

  const lstDistrict = myLocationManagement

  ////Lấy danh sách ward
  // Get all unique parentId values from lstDistrict
  const wardParentIds = myLocationManagement?.map((district) => district.id)

  //Từ wardParentIds, lấy ra những ward có id trong wardParentIds
  const lstWard = ward.filter((ward) => wardParentIds.includes(ward.parentId))

  ////Lấy danh sách province
  // Get all unique parentId values from lstDistrict
  const districtParentIds = lstDistrict?.map((district) => district.parentId)
  //Từ districtParentIds, lấy ra những district có id trong districtParentIds
  const lstProvionce = province.filter((province) => districtParentIds.includes(province.id))

  ////Lấy danh sách subZone
  // Get all unique subZoneId(parentId) values from lstProvionce
  const provinceParentIds = lstProvionce?.map((province) => province.subZoneId)
  //Từ provinceParentIds, lấy ra những subZone có id trong provinceParentIds
  const lstSubZone = subzone?.filter((subZone) => provinceParentIds.includes(subZone.id))

  ////Lấy danh sách zone
  // Get all unique zoneId(parentId) values from lstProvionce
  const subZoneParentIds = lstProvionce?.map((province) => province.zoneId) // trong lstProvionce có lưu zoneId
  //Từ provinceParentIds, lấy ra những subZone có id trong provinceParentIds
  const lstZone = zone.filter((zone) => subZoneParentIds.includes(zone.id))

  const getIndustryDataOptions = async () => {
    try {
      const resIndustry = await getPortfolioAllAPI({ type: 'industry' })
      const resIndustrysector = await getPortfolioAllAPI({ type: 'industrySector' })
      const resCategory = await getPortfolioAllAPI({ type: 'category' })
      const resModel = await getPortfolioAllAPI({ type: 'model' })
      setIndustry(resIndustry?.data)
      setIndustrysector(resIndustrysector?.data)
      setCategory(resCategory?.data)
      setModel(resModel?.data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getIndustryDataOptions()
  }, [])

  return (
    <Row gutter={[10, 10]} align='bottom'>
      <Col {...ColProps} className='w-100'>
        <Label className='form-label'>Nhập tên</Label>
        <FormInput
          className='w-100'
          maxLength={MAX_LENGTH_SEARCH}
          placeholder='Nhập tên'
          value={filter?.search || ''}
          name='search'
          id={`input-serial-filter`}
          onChange={(e) => onFilterChange('search', e.target.value)}
          onBlur={(e) => onFilterChange('search', e.target.value)}
        />
      </Col>

      <Col {...ColProps} className='w-100'>
        <Label className='form-label'>Nhập số điện thoại</Label>
        <FormInput
          className='w-100'
          maxLength={MAX_LENGTH_SEARCH}
          placeholder='Nhập số điện thoại'
          value={filter?.phone || ''}
          name='phone'
          id='input-serial-filter'
          onChange={(e) => onFilterChange('phone', e.target.value)}
          onBlur={(e) => onFilterChange('phone', e.target.value)}
        />
      </Col>
      <Col {...ColProps} className='w-100'>
        <Label className='form-label'>Tỉnh/ Thành phố</Label>
        <FormSelect
          options={[{ label: 'Tất cả', value: 'all' }].concat(
            lstProvionce?.map((item) => ({
              value: item.id,
              label: item.name
            }))
          )}
          value={filter.province_ids}
          onChange={(value, label) => {
            onFilterChange('province_ids', value)
            onFilterChange(
              'province_names',
              label.map((e) => e.label)
            )

            onFilterChange('district_ids', [])
            onFilterChange('district_names', [])

            onFilterChange('ward_ids', [])
            onFilterChange('ward_names', [])
          }}
          onSelect={(value) => {
            const all = lstProvionce?.map((item) => item.id)
            const all_names = lstProvionce?.map((item) => item.name)

            if (value && value === 'all') {
              onFilterChange('province_ids', [...all])
              onFilterChange('province_ids', [...all_names])
            }
          }}
          placeholder='Tất cả'
          mode='multiple'
        />
      </Col>
      <Col {...ColProps} className='w-100'>
        <Label className='form-label'>Quận/ Huyện</Label>
        <FormSelect
          options={
            lstDistrict?.filter((e) => filter.province_ids?.includes(e.parentId))?.length > 0
              ? [{ label: 'Tất cả', value: 'all' }].concat(
                  lstDistrict
                    ?.filter((e) => filter.province_ids?.includes(e.parentId))
                    ?.map((item) => ({
                      value: item.id,
                      label: item.name
                    }))
                )
              : []
          }
          value={filter.district_ids}
          onChange={(value, label) => {
            onFilterChange('district_ids', value)
            onFilterChange(
              'district_names',
              label.map((e) => e.label)
            )

            onFilterChange('ward_ids', [])
            onFilterChange('ward_names', [])
          }}
          onSelect={(value) => {
            const all = lstDistrict?.filter((e) => filter.province_ids?.includes(e.parentId))?.map((item) => item.id)
            const all_names = lstDistrict
              ?.filter((e) => filter.province_ids?.includes(e.parentId))
              ?.map((item) => item.name)

            if (value && value === 'all') {
              onFilterChange('district_ids', [...all])
              onFilterChange('district_names', [...all_names])
            }
          }}
          placeholder='Tất cả'
          mode='multiple'
        />
      </Col>
      <Col {...ColProps} className='w-100'>
        <Label className='form-label'>Phường xã</Label>
        <FormSelect
          options={
            lstWard?.filter((e) => filter.district_ids?.includes(e.parentId))?.length > 0
              ? [{ label: 'Tất cả', value: 'all' }].concat(
                  lstWard
                    ?.filter((e) => filter.district_ids?.includes(e.parentId))
                    ?.map((item) => ({
                      value: item.id,
                      label: item.name
                    }))
                )
              : []
          }
          value={filter.ward_ids}
          onChange={(value, label) => {
            onFilterChange('ward_ids', value)
            onFilterChange(
              'ward_names',
              label.map((e) => e.label)
            )
          }}
          onSelect={(value) => {
            const all = lstWard?.filter((e) => filter.district_ids?.includes(e.parentId))?.map((item) => item.id)
            const all_names = lstWard
              ?.filter((e) => filter.district_ids?.includes(e.parentId))
              ?.map((item) => item.name)

            if (value && value === 'all') {
              onFilterChange('ward_ids', [...all])
              onFilterChange('ward_names', [...all_names])
            }
          }}
          placeholder='Tất cả'
          mode='multiple'
        />
      </Col>

      <Col xxl={6} className='w-100'>
        <Space>
          <button
            type='button'
            className='btn btn-soft-danger'
            onClick={() => {
              onResetFilter()
              handleResetFilter()
            }}
          >
            Xóa tìm kiếm
          </button>

          <button type='button' className='btn btn-primary' onClick={() => handleFilter(filter)}>
            Tìm kiếm
          </button>
        </Space>
      </Col>
    </Row>
  )
}

export default ManageGuestFilter
