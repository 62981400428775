import { combineReducers } from 'redux'

// Front
import Layout from './layout/reducer'

// Authentication
import Login from './auth/login/reducer'
import Profile from './auth/profile/reducer'

// Change password
import changePassword from './change-password/reducer'

//Dashboard
import Dashboard from './dashboard/reducer'

//Account mobile
import ShopReducer from './manage-shop/reducer'

//Portfolio
import PortfolioReducer from './manage-portfolio/reducer'

//Product
import ProductReducer from './manage-product/reducer'

//Unit
import UnitReducer from './manage-unit/reducer'

//Product Activation
import ProductActivationReducer from './manage-product-activation/reducer'

//Permission
import PermissionReducer from './manage-permission/reducer'

//User
import UserReducer from './manage-user/reducer'

//Program
import ProgramReducer from './manage-program/reducer'

//Locality
import LocalityReducer from './manage-locality/reducer'

//Locality
import AccountClassReducer from './manage-account-class/reducer'

//Accumulation
import AccumulationReducer from './manage-accumulation/reducer'

//Activation
import ActivationReducer from './manage-activation/reducer'

//Guest
import GuestReducer from './manage-guest/reducer'

//Content
import ContentReducer from './manage-content/reducer'

//Baner
import BanerReducer from './manage-baner/reducer'

//Report Program Bonus Temp
import ProgramBonusTempReducer from './report-program-bonus-temp/reducer'

//Bank
import BanksReducer from './manage-bank/reducer'

//Contact
import ContactsReducer from './manage-contact/reducer'

//Manage history
import HistoryReducer from './manage-history/reducer'

//Notification
import NotificationReducer from './manage-notification/reducer'

//Report User Statistic
import ReportUserStatisticReducer from './report-user-statistic/reducer'

//Report User Statistic
import ReportAccountRankReducer from './report-account-rank/reducer'

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  changePassword,
  Profile,
  Dashboard,
  ShopReducer,
  PortfolioReducer,
  ProductReducer,
  UnitReducer,
  PermissionReducer,
  ProductActivationReducer,
  UserReducer,
  ProgramReducer,
  LocalityReducer,
  AccountClassReducer,
  AccumulationReducer,
  ActivationReducer,
  GuestReducer,
  ContentReducer,
  BanerReducer,
  ProgramBonusTempReducer,
  BanksReducer,
  ContactsReducer,
  HistoryReducer,
  NotificationReducer,
  ReportUserStatisticReducer,
  ReportAccountRankReducer
})

export default rootReducer
