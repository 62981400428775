import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Link } from 'react-router-dom'

//import components

//redux
import { useSelector, useDispatch } from 'react-redux'
import { createSelector } from 'reselect'

import { Col, Row, UncontrolledTooltip, Card, CardBody, Label } from 'reactstrap'
import Spinners from 'components/Common/Spinner'
import { toast } from 'react-toastify'
import TableContainer from 'components/Common/TableContainer'
import { UnitVN, ManageShopVN } from 'constants/vn'
import ConfirmModal from 'components/Common/ConfirmModal'
import { deleteUnitAPI } from 'helpers/backend_helper'
import { UnitCode, UnitName, UnitUpdateDate, UnitLevel } from './ManageUnitCol'
import AddEditProduct from './components/AddEditUnit'
import {
  getUnitDetail,
  getUnitList,
  setTypePopupUnit,
  getDepartmentAll,
  getDepartmentAllList,
  setFiltersUnit
} from 'store/actions'
import ImportModal from 'components/Common/ImportModal'
import { MAX_LENGTH_SEARCH } from 'constants/constants'
import { Tree } from 'antd'
import { FormInput } from 'components/Common/form-input/FormInput'
import { DEPARTMENT_MANAGEMENT_CODE, SCREEN_CODE } from 'constants/permission-code'
import PermissionWrapper from 'components/Common/PermissionWrapper'
import usePermission from 'hooks/usePermission'

function ManageUni() {
  //meta title
  document.title = 'Unit List | HPG'
  const screenCode = useMemo(() => SCREEN_CODE.DEPARTMENT_MANAGEMENT, [])

  const debounceRef = useRef()
  const dispatch = useDispatch()

  const selectUnitsState = (state) => state.UnitReducer
  const UnitsProperties = createSelector(selectUnitsState, (reducer) => ({
    units: reducer.units,
    departments: reducer.departments,
    loading: reducer.loading,
    department_list: reducer.department_list,
    total: reducer.total,
    filters: reducer.filters
  }))

  const { units, departments, department_list, loading, total, filters } = useSelector(UnitsProperties)
  const [valueFilter, setValueFilter] = useState()
  const [isLoading, setIsLoading] = useState(loading)
  const [showDeleteAccount, setShowDeleteAccount] = useState(false)
  const [selected, setSelected] = useState()
  const [showForm, setShowForm] = useState(false)
  const [showImport, setShowImport] = useState(false)
  const [treeExpand, setTreeExpand] = useState()
  const [treeSelect, setTreeSelect] = useState()
  const [page, setPage] = useState(1)
  const hasPermission = usePermission({ screenCode, code: DEPARTMENT_MANAGEMENT_CODE.ADMIN_DETAIL_DEPARTMENT })

  useEffect(() => {
    dispatch(getDepartmentAll())
  }, [])

  useEffect(() => {
    if (!showForm) {
      handleDataTreeData()
    }
  }, [departments])

  useEffect(() => {
    dispatch(getUnitList({ ...filters }))
  }, [filters])

  const handleDataTreeData = () => {
    if (treeExpand && department_list.length > 0) {
      const key_split = treeExpand.split('-')
      console.log('key_split', key_split)
      const list = departments.map((item) => {
        return {
          id: item.id,
          title: item.name,
          key: `${treeExpand}-${item.id}`,
          value: `${treeExpand}-${item.id}`,
          isLeaf: !item.haveChildren,
          selectable: !(key_split.length > 1)
        }
      })
      const update = updateTreeData(department_list, treeExpand, list)
      dispatch(getDepartmentAllList(update))
    } else {
      const list = departments.map((item) => {
        return {
          id: item.id,
          title: item.name,
          key: `${item.id}`,
          value: `${item.id}`,
          isLeaf: !item.haveChildren
        }
      })
      dispatch(
        getDepartmentAllList([
          {
            id: 0,
            title: (
              <span style={{ fontFamily: 'Avenir' }} className='mb-0 card-title flex-grow-1 font-weight-bold'>
                {UnitVN.tree}
              </span>
            ),
            key: '0',
            value: '0',
            children: [...list]
          }
        ])
      )
    }
  }

  const updateTreeData = (list, key, children) => {
    return list.map((node) => {
      if (node.key === key) {
        return {
          ...node,
          children
        }
      }
      if (node.children) {
        return {
          ...node,
          children: updateTreeData(node.children, key, children)
        }
      }
      return node
    })
  }

  const onLoadData = ({ key, children }) => {
    let key_expand
    const key_split = key.split('-')
    if (key_split.length > 1) {
      key_expand = key_split[key_split.length - 1]
    } else {
      key_expand = key
    }
    return new Promise((resolve) => {
      if (children) {
        resolve()
        return
      }

      dispatch(getDepartmentAll({ parent_id: key_expand }))
      setTreeExpand(key)
      resolve()
    })
  }

  const onClickDelete = (item) => {
    setSelected(item)
    setShowDeleteAccount(true)
  }

  const onClickItem = async (item, type) => {
    setSelected(item)
    dispatch(setTypePopupUnit(type))
    dispatch(getUnitDetail(item))
    setShowForm(true)
  }

  const columns = useMemo(
    () => [
      {
        Header: UnitVN.table.code,
        accessor: 'id',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <UnitCode {...cellProps} />
        }
      },
      {
        Header: UnitVN.table.name,
        accessor: 'name',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <UnitName {...cellProps} />
        }
      },
      {
        Header: UnitVN.table.rank,
        accessor: 'treeName',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <UnitLevel {...cellProps} />
        }
      },
      {
        Header: UnitVN.table.updateDate,
        accessor: 'updatedAt',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <UnitUpdateDate {...cellProps} />
        }
      },
      {
        Header: UnitVN.table.action,
        accessor: 'action',
        disableFilters: true,
        disableSortBy: true,
        hideSort: true,
        show: hasPermission,
        Cell: (cellProps) => {
          return (
            <ul className='list-unstyled hstack gap-1 mb-0'>
              <li data-bs-toggle='tooltip' data-bs-placement='top' title='View'>
                <Link
                  to={`#`}
                  className='btn btn-sm btn-soft-primary'
                  id={`viewtooltip-${cellProps.row.original.id}`}
                  onClick={() => {
                    onClickItem(cellProps.row.original, 'view')
                  }}
                >
                  <i className='mdi mdi-eye-outline' />
                  <UncontrolledTooltip
                    trigger='hover'
                    placement='top'
                    target={`viewtooltip-${cellProps.row.original.id}`}
                  >
                    Xem
                  </UncontrolledTooltip>
                </Link>
              </li>

              <PermissionWrapper screenCode={screenCode} code={DEPARTMENT_MANAGEMENT_CODE.ADMIN_UPDATE_DEPARTMENT}>
                <li data-bs-toggle='tooltip' data-bs-placement='top' title='View'>
                  <Link
                    to={`#`}
                    className='btn btn-sm btn-soft-info'
                    id={`edittooltip-${cellProps.row.original.id}`}
                    onClick={() => {
                      onClickItem(cellProps.row.original, 'edit')
                    }}
                  >
                    <i className='mdi mdi-pencil-outline' />
                    <UncontrolledTooltip
                      trigger='hover'
                      placement='top'
                      target={`edittooltip-${cellProps.row.original.id}`}
                    >
                      Sửa
                    </UncontrolledTooltip>
                  </Link>
                </li>
              </PermissionWrapper>
              <PermissionWrapper screenCode={screenCode} code={DEPARTMENT_MANAGEMENT_CODE.ADMIN_DELETE_DEPARTMENT}>
                <li>
                  <Link
                    to='#'
                    className='btn btn-sm btn-soft-danger'
                    onClick={() => {
                      const item = cellProps.row.original
                      onClickDelete(item)
                    }}
                    id={`deletetooltip-${cellProps.row.original.id}`}
                  >
                    <i className='mdi mdi-delete-outline' />
                    <UncontrolledTooltip
                      trigger='hover'
                      placement='top'
                      target={`deletetooltip-${cellProps.row.original.id}`}
                    >
                      Xóa
                    </UncontrolledTooltip>
                  </Link>
                </li>
              </PermissionWrapper>
            </ul>
          )
        }
      }
    ],
    [hasPermission]
  )

  const permissionColumns = useMemo(() => columns.filter((column) => column.show !== false), [columns])

  const handleSelectTree = (value) => {
    let parent_id
    if (value) {
      const key_select = value.split('-')
      parent_id = key_select[key_select.length - 1]
    }
    setTreeSelect(value)
    setValueFilter()
    dispatch(setFiltersUnit({ ...filters, parent_id: parent_id, search: '' }))
  }

  const handleSearch = () => {
    dispatch(setFiltersUnit({ ...filters, search: valueFilter }))
    setPage(1)
  }

  const goToPage = (page) => {
    dispatch(getUnitList({ ...filters, page: page }))
    setPage(page)
  }

  const handleSort = (sortBy) => {
    if (sortBy.length > 0) {
      dispatch(getUnitList({ ...filters, sort_field: sortBy[0].id, sort_order: sortBy[0].desc ? 'desc' : 'asc' }))
    } else {
      dispatch(getUnitList({ ...filters, sort_field: '', sort_order: '' }))
    }
  }

  const onDeleteUnit = async () => {
    try {
      const res = await deleteUnitAPI({
        id: selected.id
      })
      if (res) {
        const list = removeChildById(department_list, selected.parentId ? selected.parentId : 0, selected.id)
        toast('Đơn vị bộ phận được xóa thành công', { type: 'success' })
        setShowDeleteAccount(false)
        dispatch(getUnitList({ ...filters }))
        dispatch(getDepartmentAll())
        dispatch(getDepartmentAllList([]))
        setTreeExpand(null)
      }
    } catch (error) {
      toast(error.response.data.message, { type: 'error' })
    }
  }

  function removeChildById(data, id, childIdToRemove) {
    const data_new = [...data]

    //HP
    data_new.map((itemLevel1) => {
      if (itemLevel1.id == id) {
        if (itemLevel1.children) {
          itemLevel1.children = itemLevel1.children.filter((child) => child.id !== childIdToRemove)
          return
        }
      }
      // Cap1
      if (itemLevel1.children) {
        itemLevel1.children.map((itemLevel2) => {
          if (itemLevel2.id == id) {
            if (itemLevel2.children) {
              itemLevel2.children = itemLevel2.children.filter((child) => child.id !== childIdToRemove)
              return
            }
          }
          //Cap2
          if (itemLevel2.children) {
            itemLevel2.children.map((itemLevel3) => {
              if (itemLevel3.id == id) {
                if (itemLevel3.children) {
                  itemLevel3.children = itemLevel3.children.filter((child) => child.id !== childIdToRemove)
                  return
                }
              }
              //Cap3
              if (itemLevel3.children) {
                itemLevel3.children.map((itemLevel4) => {
                  if (itemLevel4.id == id) {
                    if (itemLevel4.children) {
                      itemLevel4.children = itemLevel4.children.filter((child) => child.id !== childIdToRemove)
                      return
                    }
                  }
                })
              }
            })
          }
        })
      }
    })

    return data_new
  }

  return (
    <React.Fragment>
      <div className='page-content'>
        <div className='page-title-box container-fluid'>
          <h4 className=' mb-sm-0 font-size-18 pb-4'>{UnitVN.title}</h4>
          {isLoading ? (
            <Spinners setLoading={setIsLoading} />
          ) : (
            <Row>
              <Col lg='12'>
                <Card>
                  <CardBody className='border-bottom'>
                    <div className='d-flex align-items-center'>
                      <h5 className='mb-0 card-title flex-grow-1'>{UnitVN.list}</h5>
                      <div className='flex-shrink-0'>
                        <PermissionWrapper
                          screenCode={screenCode}
                          code={DEPARTMENT_MANAGEMENT_CODE.ADMIN_CREATE_DEPARTMENT}
                        >
                          <button
                            onClick={() => {
                              setShowForm(true)
                              dispatch(setTypePopupUnit('create'))
                            }}
                            className='btn btn-primary'
                          >
                            <i className='mdi mdi-plus me-1' />
                            {UnitVN.add}
                          </button>
                        </PermissionWrapper>
                      </div>
                    </div>
                  </CardBody>
                  <CardBody>
                    <Row className='align-items-end' style={{ justifyContent: 'space-between' }}>
                      <Col className='mb-4' xxl={3} lg={6}>
                        <Label className='form-label'>{UnitVN.filter.title}</Label>
                        <FormInput
                          maxLength={MAX_LENGTH_SEARCH}
                          value={valueFilter || ''}
                          placeholder={UnitVN.filter.placeholderSearch}
                          onChange={(e) => {
                            setValueFilter(e.target.value)
                          }}
                          onBlur={(e) => {
                            setValueFilter(e.target.value.trim())
                          }}
                        />
                      </Col>
                      <Col xxl={2} xl={2} lg={2}>
                        <div className='mb-xxl-0 me-2'>
                          <button type='button' className='btn btn-primary w-100 mb-4' onClick={() => handleSearch()}>
                            Tìm kiếm
                          </button>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col xxl={2} xl={3} lg={6}>
                        {/* <h5 className="mb-2 card-title flex-grow-1">{UnitVN.tree}</h5> */}
                        {department_list && department_list.length > 0 && (
                          <Tree
                            showLine={true}
                            treeData={department_list}
                            loadData={onLoadData}
                            onSelect={(e) => handleSelectTree(e[0])}
                            blockNode
                            defaultExpandedKeys={['0']}
                          />
                        )}
                      </Col>
                      <Col xxl={8} xl={9}>
                        <TableContainer
                          columns={permissionColumns}
                          data={units}
                          isGlobalFilter={false}
                          // setGlobalFilterUser={setGlobalFilterUser}
                          isProductGlobalFilter={true}
                          placeholderSearch={UnitVN.filter.placeholderSearch}
                          isPagination={true}
                          iscustomPageSizeOptions={false}
                          isShowingPageLength={false}
                          customPageSize={10}
                          tableClass='table-bordered align-middle nowrap mt-2'
                          paginationDiv='col-sm-12 col-md-7'
                          pagination='pagination justify-content-end pagination-rounded'
                          total={total}
                          gotoPageCustom={goToPage}
                          currentPage={page}
                          useAPIChangePaging
                          setSortBy={handleSort}
                        />
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </div>
      </div>
      <ConfirmModal
        show={showDeleteAccount}
        icon={
          <div className='avatar-title rounded-circle bg-danger text-light bg-opacity-60 font-size-20 rounded-3'>
            <i className='mdi mdi-delete-outline'></i>
          </div>
        }
        onConfirmClick={onDeleteUnit}
        onCloseClick={() => setShowDeleteAccount(false)}
        title='Xóa đơn vị bộ phận'
        description={`Bạn có chắc chắn muốn xóa đơn vị bộ phận?`}
      />
      {showForm && (
        <AddEditProduct
          onCloseClick={() => {
            setShowForm(false)
          }}
          show={showForm}
          setTreeExpandProd={setTreeExpand}
          setPage={setPage}
        />
      )}
      <ImportModal
        onCloseClick={() => {
          setShowImport(false)
        }}
        show={showImport}
      />
    </React.Fragment>
  )
}

export default ManageUni
