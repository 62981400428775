//REFRESH
export const POST_REFRESH = process.env.REACT_APP_APIVERSION + '/auth/refresh-session'

//LOCATION
export const GET_PROVINCE = process.env.REACT_APP_APIVERSION + '/province/all'
export const GET_DISTRICT = process.env.REACT_APP_APIVERSION + '/district/all'
export const GET_WARD = process.env.REACT_APP_APIVERSION + '/ward/all'
export const GET_ZONE = process.env.REACT_APP_APIVERSION + '/zone/all'
export const GET_SUBZONE = process.env.REACT_APP_APIVERSION + '/sub-zone/all'
export const GET_BANKS = process.env.REACT_APP_APIVERSION + '/bank'

//UPLOAD
export const POST_IMAGE = process.env.REACT_APP_APIVERSION + '/upload/image'

// DOWNLOAD TEMPLATE
export const GET_TEMPLATE = process.env.REACT_APP_APIVERSION + '/taskqueue/template'
export const CHECK_UPLOAD = process.env.REACT_APP_APIVERSION + '/taskqueue/queue-status'
export const EXPORT_FILE_ERROR = process.env.REACT_APP_APIVERSION + '/taskqueue/export-queue-result'
export const CHECK_EXPORT = process.env.REACT_APP_APIVERSION + '/taskqueue/task-queue-export-status'

//AUTH
export const POST_JWT_LOGIN = process.env.REACT_APP_APIVERSION + '/admin/login'
export const POST_JWT_LOGOUT = process.env.REACT_APP_APIVERSION + '/auth/logout'
export const PUT_CHANGE_PASSWORD = process.env.REACT_APP_APIVERSION + '/admin/change-password'

//PROFILE
export const PUT_PROFILE = process.env.REACT_APP_APIVERSION + '/admin'
export const GET_PROFILE = process.env.REACT_APP_APIVERSION + '/users/profile'
export const UPDATE_PROFILE = process.env.REACT_APP_APIVERSION + '/admin/update-profile'

//SHOP
export const GET_SHOPS = process.env.REACT_APP_APIVERSION + '/admin/user/search'
export const GET_SHOPS_TECHNICIAN = process.env.REACT_APP_APIVERSION + '/admin/technician/search'

export const GET_SHOP_DETAIL = process.env.REACT_APP_APIVERSION + '/admin/user/profile'
export const POST_RESET_PASSWORD = process.env.REACT_APP_APIVERSION + '/users/reset-password'
export const POST_LOCK_ACCOUNT = process.env.REACT_APP_APIVERSION + '/admin/set-status'
export const PATCH_ACCEPT_MEMBER = process.env.REACT_APP_APIVERSION + '/admin/user/member'
export const GET_EXPORT_LIST = process.env.REACT_APP_APIVERSION + '/admin/user/export'
export const GET_EXPORT_TECHNICIAN_LIST = process.env.REACT_APP_APIVERSION + '/admin/technician/export'
export const POST_TECHNICIAN_RESET_PASSWORD = process.env.REACT_APP_APIVERSION + '/technician/reset-password'
export const POST_SHOP_LOCK_ACCOUNT = process.env.REACT_APP_APIVERSION + '/admin/set-status-user'
export const POST_TECHNICIAN_LOCK_ACCOUNT = process.env.REACT_APP_APIVERSION + '/admin/set-status-technician'
export const SWITCH_TECHNICIAN_TO_USER = process.env.REACT_APP_APIVERSION + '/admin/switch-technician-to-user'
export const SWITCH_USER_TO_TECHNICIAN = process.env.REACT_APP_APIVERSION + '/admin/switch-user-to-technician'

export const GET_USER_RANK = process.env.REACT_APP_APIVERSION + '/admin/user/ranks'
export const GET_USER_HISTORY_CHANGE = process.env.REACT_APP_APIVERSION + '/update-history/admin/search'

//PORTFOLIO
export const GET_PORTFOLIO_ALL = process.env.REACT_APP_APIVERSION + '/categories/all'
export const GET_PORTFOLIO_LIST = process.env.REACT_APP_APIVERSION + '/categories/search'
export const POST_PORTFOLIO_CREATE = process.env.REACT_APP_APIVERSION + '/categories/create'
export const GET_PORTFOLIO_DETAILS = process.env.REACT_APP_APIVERSION + '/categories/details'
export const PUT_PORTFOLIO = process.env.REACT_APP_APIVERSION + '/categories/update/'
export const DELETE_PORTFOLIO = process.env.REACT_APP_APIVERSION + '/categories/delete/'
export const IMPORT_PORTFOLIO = process.env.REACT_APP_APIVERSION + '/taskqueue/import-category'
export const EXPORT_PORTFOLIO = process.env.REACT_APP_APIVERSION + '/categories/export'

//PRODUCT
export const GET_PRODUCT_ALL = process.env.REACT_APP_APIVERSION + '/product/all'
export const GET_PRODUCT_LIST = process.env.REACT_APP_APIVERSION + '/product/search'
export const POST_PRODUCT_CREATE = process.env.REACT_APP_APIVERSION + '/product/create'
export const GET_PRODUCT_DETAILS = process.env.REACT_APP_APIVERSION + '/product/details'
export const PUT_PRODUCT = process.env.REACT_APP_APIVERSION + '/product/update/'
export const DELETE_PRODUCT = process.env.REACT_APP_APIVERSION + '/product/delete/'
export const GET_CHANNEL = process.env.REACT_APP_APIVERSION + '/channel/search'
export const IMPORT_PRODUCT = process.env.REACT_APP_APIVERSION + '/taskqueue/import-product'
export const EXPORT_PRODUCT = process.env.REACT_APP_APIVERSION + '/product/export'
export const CHECK_SERIAL_PRODUCT = process.env.REACT_APP_APIVERSION + '/product/check-serial'
export const IMPORT_PRODUCT_UPDATE = process.env.REACT_APP_APIVERSION + '/taskqueue/update-product'

//PRODUCT ACTIVATION
export const GET_PRODUCT_ACTIVATION_ALL = process.env.REACT_APP_APIVERSION + '/product-activation/all'
export const GET_PRODUCT_ACTIVATION_LIST = process.env.REACT_APP_APIVERSION + '/product-activation/search'
export const POST_PRODUCT_ACTIVATION_CREATE = process.env.REACT_APP_APIVERSION + '/product-activation'
export const GET_PRODUCT_ACTIVATION_DETAILS = process.env.REACT_APP_APIVERSION + '/product-activation/details'
export const PUT_PRODUCT_ACTIVATION = process.env.REACT_APP_APIVERSION + '/product-activation/'
export const DELETE_PRODUCT_ACTIVATION = process.env.REACT_APP_APIVERSION + '/product-activation/'
export const PATCH_PRODUCT_ACTIVATION = process.env.REACT_APP_APIVERSION + '/product-activation/'
export const IMPORT_PRODUCT_ACTIVATION = process.env.REACT_APP_APIVERSION + '/taskqueue/import-product-activation'
export const IMPORT_UPDATE_PRODUCT_ACTIVATION =
  process.env.REACT_APP_APIVERSION + '/taskqueue/import-update-product-activation'
export const EXPORT_PRODUCT_ACTIVATION = process.env.REACT_APP_APIVERSION + '/product-activation/export'

//UNIT
export const GET_UNIT_LIST = process.env.REACT_APP_APIVERSION + '/department/search'
export const POST_UNIT_CREATE = process.env.REACT_APP_APIVERSION + '/department/create'
export const GET_UNIT_DETAILS = process.env.REACT_APP_APIVERSION + '/department/details'
export const PUT_UNIT = process.env.REACT_APP_APIVERSION + '/department/update/'
export const DELETE_UNIT = process.env.REACT_APP_APIVERSION + '/department/delete/'
export const GET_DEPARTMENT_LIST = process.env.REACT_APP_APIVERSION + '/department/all'

//PERMISSION
export const GET_PERMISSION_LIST = process.env.REACT_APP_APIVERSION + '/role/search'
export const POST_PERMISSION_CREATE = process.env.REACT_APP_APIVERSION + '/role/create'
export const GET_PERMISSION_DETAILS = process.env.REACT_APP_APIVERSION + '/role/details'
export const PUT_PERMISSION = process.env.REACT_APP_APIVERSION + '/role/update/'
export const DELETE_PERMISSION = process.env.REACT_APP_APIVERSION + '/role/delete/'
export const GET_ROLE_LIST = process.env.REACT_APP_APIVERSION + '/role/screen-permission'

//USER
export const GET_USER_LIST = process.env.REACT_APP_APIVERSION + '/admin/search-admin'
export const POST_USER_CREATE = process.env.REACT_APP_APIVERSION + '/admin/create'
export const GET_USER_DETAILS = process.env.REACT_APP_APIVERSION + '/admin/details'
export const PUT_USER = process.env.REACT_APP_APIVERSION + '/admin/update/'
export const GET_ROLE_LIST_ALL = process.env.REACT_APP_APIVERSION + '/role/all'
export const GET_ZONE_LIST_ALL = process.env.REACT_APP_APIVERSION + '/zone/all'
export const GET_SUB_ZONE_LIST_ALL = process.env.REACT_APP_APIVERSION + '/sub-zone/all'
export const GET_PROVINCE_LIST_ALL = process.env.REACT_APP_APIVERSION + '/province/all'
export const CHECK_ROLE_MANAGEMENT = process.env.REACT_APP_APIVERSION + '/admin/check-role-management'
export const POST_LOCK_ACCOUNT_ADMIN = process.env.REACT_APP_APIVERSION + '/admin/set-status-admin/'
export const POST_CHANGE_PASSWORD_ACCOUNT_ADMIN = process.env.REACT_APP_APIVERSION + '/admin/reset-password'
export const MY_LOCATION_MANAGEMENT = process.env.REACT_APP_APIVERSION + '/users/my-location-management'

//LOCALITY
export const GET_LOCALITY_LIST = process.env.REACT_APP_APIVERSION + '/area-management/search'
export const POST_LOCALITY_CREATE = process.env.REACT_APP_APIVERSION + '/area-management/create'
export const GET_LOCALITY_DETAILS = process.env.REACT_APP_APIVERSION + '/area-management/details'
export const PUT_LOCALITY = process.env.REACT_APP_APIVERSION + '/area-management/update/'
export const DELETE_LOCALITY = process.env.REACT_APP_APIVERSION + '/area-management/delete/'
export const GET_AREA_MANAGEMENT_LIST = process.env.REACT_APP_APIVERSION + '/area-management/all'

//PROGRAM
export const GET_PROGRAM_LIST = process.env.REACT_APP_APIVERSION + '/admin/programming/search'
export const POST_PROGRAM_CREATE = process.env.REACT_APP_APIVERSION + '/admin/programming'
export const POST_PROGRAM_CONFIG = process.env.REACT_APP_APIVERSION + '/admin/programming/config/'
export const GET_PROGRAM_DETAILS = process.env.REACT_APP_APIVERSION + '/admin/programming/'
export const PUT_PROGRAM = process.env.REACT_APP_APIVERSION + '/admin/programming/'
export const DELETE_PROGRAM = process.env.REACT_APP_APIVERSION + '/admin/programming/delete/'
export const PATCH_STATUS_PROGRAM = process.env.REACT_APP_APIVERSION + '/admin/programming/screen-permission'
export const IMPORT_PROGRAM_PACKAGE = process.env.REACT_APP_APIVERSION + '/convert-file/dockerToPDF'
export const GET_PARALLEL_PROGRAM_LIST = process.env.REACT_APP_APIVERSION + '/admin/programming/search-all'

//RANK
export const GET_RANK_ALL = process.env.REACT_APP_APIVERSION + '/rank/all'
export const GET_RANK_LIST = process.env.REACT_APP_APIVERSION + '/rank/search'
export const POST_RANK_CREATE = process.env.REACT_APP_APIVERSION + '/rank/create'
export const GET_RANK_DETAILS = process.env.REACT_APP_APIVERSION + '/rank/details'
export const PUT_RANK = process.env.REACT_APP_APIVERSION + '/rank/update/'
export const DELETE_RANK = process.env.REACT_APP_APIVERSION + '/rank/delete/'

// RANK CONFIG
export const GET_RANK_CONFIG_LIST = process.env.REACT_APP_APIVERSION + '/rank-config/search'
export const POST_RANK_CONFIG_CREATE = process.env.REACT_APP_APIVERSION + '/rank-config/create'
export const GET_RANK_CONFIG_DETAILS = process.env.REACT_APP_APIVERSION + '/rank-config/details'
export const PUT_RANK_CONFIG = process.env.REACT_APP_APIVERSION + '/rank-config/update/'
export const DELETE_RANK_CONFIG = process.env.REACT_APP_APIVERSION + '/rank-config/delete/'
export const DELETE_RANK_ITEM_CONFIG = process.env.REACT_APP_APIVERSION + '/rank-config/delete-item/'
export const GET_ACCOUNT_RANK_LIST = process.env.REACT_APP_APIVERSION + '/rank-config/search-applly-for-programming'

// POINT CONFIG
export const GET_POINT_CONFIG_LIST = process.env.REACT_APP_APIVERSION + '/default-point-config/search'
export const PUT_POINT_CONFIG = process.env.REACT_APP_APIVERSION + '/default-point-config/update'

// POINT CONFIG BY TIME
export const GET_POINT_CONFIG_BY_TIME_LIST = process.env.REACT_APP_APIVERSION + '/point-config/search'
export const CREATE_POINT_CONFIG_BY_TIME = process.env.REACT_APP_APIVERSION + '/point-config/create'
export const PUT_POINT_CONFIG_BY_TIME = process.env.REACT_APP_APIVERSION + '/point-config/update/'
export const GET_POINT_CONFIG_BY_TIME_DETAILS = process.env.REACT_APP_APIVERSION + '/point-config/search-by-model/'
export const DELETE_POINT_CONFIG_BY_TIME = process.env.REACT_APP_APIVERSION + '/point-config/delete/'

// POINT HISTORY
export const GET_POINT_HISTORY_LIST = process.env.REACT_APP_APIVERSION + '/admin/history-point-reward/search'

//ACCUMULATION
export const GET_ACCUMULATION_LIST = process.env.REACT_APP_APIVERSION + '/admin/accumulation/search'
export const GET_ACCUMULATION_DETAILS = process.env.REACT_APP_APIVERSION + '/admin/activation/product/'
export const PUT_ACCUMULATION = process.env.REACT_APP_APIVERSION + '/product/update/'
export const DELETE_ACCUMULATION = process.env.REACT_APP_APIVERSION + '/admin/activation/'
export const EXPORT_ACCUMULATION = process.env.REACT_APP_APIVERSION + '/admin/accumulation/export'
export const GET_MODEL_BY_SERIAL = process.env.REACT_APP_APIVERSION + '/product/modelsBySerial/'

//ACTIVATION
export const GET_ACTIVATION_LIST = process.env.REACT_APP_APIVERSION + '/admin/activation/search'
export const GET_ACTIVATION_DETAILS = process.env.REACT_APP_APIVERSION + '/admin/activation/product/'
export const PUT_ACTIVATION = process.env.REACT_APP_APIVERSION + '/product/update/'
export const DELETE_ACTIVATION = process.env.REACT_APP_APIVERSION + '/admin/activation/'
export const EXPORT_ACTIVATION = process.env.REACT_APP_APIVERSION + '/admin/activation/export'

//GUEST
export const GET_GUEST_LIST = process.env.REACT_APP_APIVERSION + '/admin/activation/customer'
export const GET_GUEST_DETAILS = process.env.REACT_APP_APIVERSION + '/admin/activation/customer-details'
export const PUT_GUEST = process.env.REACT_APP_APIVERSION + '/admin/activation/customer-update/'
export const DELETE_GUEST = process.env.REACT_APP_APIVERSION + '/admin/activation/customer-delete/'
export const EXPORT_GUEST = process.env.REACT_APP_APIVERSION + '/admin/activation/customer-export'
//CONTENT
export const GET_CONTENT_LIST = '/v1/admin/announce/search'
export const GET_CONTENT_DETAILS = '/v1/admin/announce/'
export const POST_CONTENT = '/v1/admin/announce'
export const PUT_CONTENT = '/v1/admin/announce/'
export const DELETE_CONTENT = '/v1/admin/announce/'
export const PATCH_STATUS_CONTENT = '/v1/admin/announce/'

//NOTIFICATION
export const GET_NOTIFICATION_LIST = process.env.REACT_APP_APIVERSION + '/system-notifications'
export const GET_NOTIFICATION_DETAILS = process.env.REACT_APP_APIVERSION + '/system-notifications/'
export const POST_NOTIFICATION = process.env.REACT_APP_APIVERSION + '/system-notifications'
export const PATCH_NOTIFICATION = process.env.REACT_APP_APIVERSION + '/system-notifications/'
export const GET_USER_ACCOUNT = process.env.REACT_APP_APIVERSION + '/users/list'
export const CANCEL_NOTIFICATION = process.env.REACT_APP_APIVERSION + '/system-notifications/'

/*FAKE BE URL*/
//REGISTER
export const POST_FAKE_REGISTER = '/post-fake-register'

//LOGIN
export const POST_FAKE_LOGIN = '/post-fake-login'
export const POST_FAKE_JWT_LOGIN = '/post-jwt-login'
export const POST_FAKE_PASSWORD_FORGET = '/fake-forget-pwd'
export const POST_FAKE_JWT_PASSWORD_FORGET = '/jwt-forget-pwd'
export const SOCIAL_LOGIN = '/social-login'

//PROFILE
export const POST_EDIT_JWT_PROFILE = '/post-jwt-profile'
export const POST_EDIT_PROFILE = '/post-fake-profile'

//PRODUCTS
export const GET_PRODUCTS = '/products'
export const GET_PRODUCTS_DETAIL = '/product'

//Mails
export const GET_MAILS_LIST = '/mailslists'
export const SELECT_FOLDER = '/folders'
export const GET_SELECTED_MAILS = '/selectedmails'
export const SET_FOLDER_SELECTED_MAILS = '/setfolderonmail'
export const UPDATE_MAIL = '/update/mail'

//CALENDER
export const GET_EVENTS = '/events'
export const ADD_NEW_EVENT = '/add/event'
export const UPDATE_EVENT = '/update/event'
export const DELETE_EVENT = '/delete/event'
export const GET_CATEGORIES = '/categories'

//CHATS
export const GET_CHATS = '/chats'
export const GET_GROUPS = '/groups'
export const GET_CONTACTS = '/contacts'
export const GET_MESSAGES = '/messages'
export const ADD_MESSAGE = '/add/messages'
export const DELETE_MESSAGE = '/delete/message'

//ORDERS
export const GET_ORDERS = '/orders'
export const ADD_NEW_ORDER = '/add/order'
export const UPDATE_ORDER = '/update/order'
export const DELETE_ORDER = '/delete/order'

//CART DATA
export const GET_CART_DATA = '/cart'

//CUSTOMERS
export const GET_CUSTOMERS = '/customers'
export const ADD_NEW_CUSTOMER = '/add/customer'
export const UPDATE_CUSTOMER = '/update/customer'
export const DELETE_CUSTOMER = '/delete/customer'

//SHOPS
// export const GET_SHOPS = "/shops"

//CRYPTO
export const GET_WALLET = '/wallet'
export const GET_CRYPTO_ORDERS = '/crypto/orders'
export const GET_CRYPTO_PRODUCTS = '/crypto-products'

//INVOICES
export const GET_INVOICES = '/invoices'
export const GET_INVOICE_DETAIL = '/invoice'

// JOBS
export const GET_JOB_LIST = '/jobs'
export const ADD_NEW_JOB_LIST = '/add/job'
export const UPDATE_JOB_LIST = '/update/job'
export const DELETE_JOB_LIST = '/delete/job'

//Apply Jobs
export const GET_APPLY_JOB = '/jobApply'
export const DELETE_APPLY_JOB = 'add/applyjob'

//PROJECTS
export const GET_PROJECTS = '/projects'
export const GET_PROJECT_DETAIL = '/project'
export const ADD_NEW_PROJECT = '/add/project'
export const UPDATE_PROJECT = '/update/project'
export const DELETE_PROJECT = '/delete/project'

//TASKS
export const GET_TASKS = '/tasks'
export const DELETE_KANBAN = '/delete/tasks'
export const ADD_CARD_DATA = '/add/tasks'
export const UPDATE_CARD_DATA = '/update/tasks'

//CONTACTS
export const GET_USERS = '/users'
export const GET_USER_PROFILE = '/user'
export const ADD_NEW_USER = '/add/user'
export const UPDATE_USER = '/update/user'
export const DELETE_USER = '/delete/user'

//Blog
export const GET_VISITOR_DATA = '/visitor-data'

//dashboard charts data
export const GET_WEEKLY_DATA = '/weekly-data'
export const GET_YEARLY_DATA = '/yearly-data'
export const GET_MONTHLY_DATA = '/monthly-data'

export const TOP_SELLING_DATA = '/top-selling-data'

//dashboard crypto
export const GET_WALLET_DATA = '/wallet-balance-data'

//dashboard jobs
export const GET_STATISTICS_DATA = '/Statistics-data'

export const GET_EARNING_DATA = '/earning-charts-data'

export const GET_PRODUCT_COMMENTS = '/comments-product'

export const ON_LIKNE_COMMENT = '/comments-product-action'

export const ON_ADD_REPLY = '/comments-product-add-reply'

export const ON_ADD_COMMENT = '/comments-product-add-comment'

//BANER
export const GET_BANER_ALL = process.env.REACT_APP_APIVERSION + '/admin/user/search'
export const GET_BANER_LIST = process.env.REACT_APP_APIVERSION + '/banner/search'
export const GET_CONTENT_ALL = process.env.REACT_APP_APIVERSION + '/admin/announce/all'
export const POST_BANER_CREATE = process.env.REACT_APP_APIVERSION + '/banner/create'
export const GET_BANER_DETAILS = process.env.REACT_APP_APIVERSION + '/admin/user/profile'
export const PUT_BANER = process.env.REACT_APP_APIVERSION + '/banner/update/'
export const DELETE_BANER = process.env.REACT_APP_APIVERSION + '/banner/delete/'

//REPORT ĐỐI SOÁT
export const GET_PROGRAM_BONUS_TEMP_LIST = process.env.REACT_APP_APIVERSION + '/report/search-by-programming'
export const GET_PRODUCT_BONUS_TEMP_LIST = process.env.REACT_APP_APIVERSION + '/report/search-by-product'
export const EXPORT_BY_PRODUCT = process.env.REACT_APP_APIVERSION + '/report/export-by-product'
export const GET_RECONCILE_PROGRAM_BY_AREA = process.env.REACT_APP_APIVERSION + '/report/search-programming-by-area'
export const GET_PROGRAM_BY_AREA_AND_REWARD_TYPE =
  process.env.REACT_APP_APIVERSION + '/report/search-programming-by-area-and-reward-type'
export const SET_PRIORITY_RECONCILE_PRODUCT =
  process.env.REACT_APP_APIVERSION + '/report/set-priority-reconcile-product'

export const CONFIRM_RECONCILE_BY_PRODUCT = process.env.REACT_APP_APIVERSION + '/report/confirm-reconcile-by-product/'
export const CONFIRM_RECONCILE_BY_PACKAGE = process.env.REACT_APP_APIVERSION + '/report/confirm-reconcile-by-package/'
export const EXPORT_BY_PROGRAMMING = process.env.REACT_APP_APIVERSION + '/report/export-by-programming'
export const EXPORT_PROGRAMMING_BY_AREA_AND_REWARD_TYPE =
  process.env.REACT_APP_APIVERSION + '/report/export-programming-by-area-and-reward-type'
export const IMPORT_RECONCILE = process.env.REACT_APP_APIVERSION + '/taskqueue/import-reconcile'
export const IMPORT_RECONCILE_ACCOUNTING = process.env.REACT_APP_APIVERSION + '/taskqueue/import-reconcile-accounting'

// DOWNLOAD TEMPLATE REPORT
export const GET_REPORT_TEMPLATE = process.env.REACT_APP_APIVERSION + '/taskqueue/template-reconcile'

// DOWNLOAD TEMPLATE LOG KẾ TOÁN
export const GET_TEMPLATE_RECONCILE_ACCOUNTING =
  process.env.REACT_APP_APIVERSION + '/taskqueue/template-reconcile-accounting'

//REPORT LOG KẾ TOÁN
export const GET_REPORT_ACCOUNTING_SEARCH_BY_PROGRAMING =
  process.env.REACT_APP_APIVERSION + '/report-accounting/search-by-programming'
export const GET_REPORT_ACCOUNTING_SEARCH_PROGRAMING_BY_REWARD_TYPE =
  process.env.REACT_APP_APIVERSION + '/report-accounting/search-programming-by-reward-type'
export const CONFIRM_ACCOUNT_RECONCILE_BY_PRODUCT =
  process.env.REACT_APP_APIVERSION + '/report-accounting/confirm-reconcile-by-product/'
export const EXPORT_REPORT_ACCOUNTING_PROGRAMMING_BY_REWARD_TYPE =
  process.env.REACT_APP_APIVERSION + '/report-accounting/export-programming-by-reward-type'

//LOCALITY
export const GET_BANK_LIST = process.env.REACT_APP_APIVERSION + '/bank/search'
export const POST_BANK_CREATE = process.env.REACT_APP_APIVERSION + '/bank/create'
export const GET_BANK_DETAILS = process.env.REACT_APP_APIVERSION + '/bank/details'
export const PUT_BANK = process.env.REACT_APP_APIVERSION + '/bank/update/'
export const DELETE_BANK = process.env.REACT_APP_APIVERSION + '/bank/delete/'
export const CONFIRM_ACCOUNT_RECONCILE_BY_PACKAGE =
  process.env.REACT_APP_APIVERSION + '/report-accounting/confirm-reconcile-by-package/'

//CONTACT
export const GET_CONTACT = process.env.REACT_APP_APIVERSION + '/contacts'
export const GET_CONTACT_DETAIL = process.env.REACT_APP_APIVERSION + '/contacts/'
export const POST_CONTACT = process.env.REACT_APP_APIVERSION + '/contacts'
export const PATCH_CONTACT = process.env.REACT_APP_APIVERSION + '/contacts/'
export const DELETE_CONTACT = process.env.REACT_APP_APIVERSION + '/contacts/'

//SMS BRANDNAME
export const GET_SMS_STATISTICS = process.env.REACT_APP_APIVERSION + '/report/sms-statistics'
export const EXPORT_SMS_STATISTICS = process.env.REACT_APP_APIVERSION + '/report/export-sms-statistics'

//HISTORY
export const GET_HISTORY_LIST = process.env.REACT_APP_APIVERSION + '/admin/activation/search-history-change'
export const EXPORT_HISTORY = process.env.REACT_APP_APIVERSION + '/admin/activation/export-history-change'

//User statistic
export const GET_REPORT_USER_STATISTIC = process.env.REACT_APP_APIVERSION + '/report/statistic-user-active-by-area'
export const EXPORT_REPORT_USER_STATISTIC =
  process.env.REACT_APP_APIVERSION + '/report/export-statistic-user-active-by-area'

//Account Rank
export const GET_REPORT_ACCOUNT_RANK = process.env.REACT_APP_APIVERSION + '/report/account-rank-type-accumulation'
export const EXPORT_REPORT_ACCOUNT_RANK =
  process.env.REACT_APP_APIVERSION + '/report/account-rank-type-accumulation/export'

//IMPORT HISTORY
export const GET_IMPORT_HISTORY = process.env.REACT_APP_APIVERSION + '/taskqueue/search-history'
export const GET_IMPORT_HISTORY_DETAIL = process.env.REACT_APP_APIVERSION + '/taskqueue/details-history'
export const GET_IMPORT_HISTORY_STATUS = process.env.REACT_APP_APIVERSION + '/taskqueue/queue-status'

//EXPORT HISTORY
export const GET_EXPORT_HISTORY = process.env.REACT_APP_APIVERSION + '/taskqueue/task-queue-export-history'

//RANK HISTORY
export const GET_RANK_HISTORY = process.env.REACT_APP_APIVERSION + '/rank-config/history-rank'
