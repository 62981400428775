import { GET_PROFILE } from "./actionTypes"
import {
  PROFILE_ERROR,
  PROFILE_SUCCESS,
  EDIT_PROFILE,
  RESET_PROFILE_FLAG,
} from "./actionTypes"

const initialState = {
  error: "",
  success: "",
  profile: {
    email: "",
    phone: "",
    image: "",
    zone: "",
    position: "",
    department: "",
    code: "",
  },
}

const profile = (state = initialState, action) => {
  switch (action.type) {
    case GET_PROFILE:
      state = { ...state }
      break
    case EDIT_PROFILE:
      state = { ...state }
      break
    case PROFILE_SUCCESS:
      state = { ...state, profile: action.payload }
      break
    case PROFILE_ERROR:
      state = { ...state, error: action.payload }
      break
    case RESET_PROFILE_FLAG:
      state = { ...state, success: null }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default profile
