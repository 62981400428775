import { StatusAccumulationOptions } from 'constants/constants'
import { HistoryVN, ManageShopVN } from 'constants/vn'
import { formateDate } from 'helpers/global_helper'
import React from 'react'
import { Badge, Input, UncontrolledTooltip } from 'reactstrap'
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css'

const Serial = (cell) => {
  return (
    <>
      <span>{cell.value ? cell.value : '--'}</span>
    </>
  )
}

const HistoryCode = (cell) => {
  return cell.value ? cell.value : '--'
}

const HistoryCategory = (cell) => {
  return cell.value ? cell.value : '--'
}

const HistoryModel = (cell) => {
  return cell.value ? cell.value : '--'
}

const AccountType = (cell) => {
  return cell.value ? cell.value : '--'
}

const Store = (cell) => {
  return (
    <div>
      <p className='m-0'>{cell.value?.name}</p>
      <span style={{ fontSize: '11px' }}>{cell.value?.phone}</span>
    </div>
  )
}
const Customer = (cell) => {
  return cell.value ? cell.value : '--'
}

const ActivatedDate = (cell) => {
  return cell.value ? formateDate(cell.value) : '--'
}

const Action = (cell) => {
  return cell?.value?.deletedAt ? 'Xóa' : '--'
}

const StatusHistory = (cell) => {
  switch (cell.value) {
    case 'no_reward':
      return <Badge className='badge-soft-secondary'>{StatusAccumulationOptions[0].label}</Badge>
    case 'waiting_for_control':
      return <Badge className='badge-soft-info'>{StatusAccumulationOptions[1].label}</Badge>
    case 'for_control':
      return <Badge className='badge-soft-success'>{StatusAccumulationOptions[2].label}</Badge>
    case 'payed':
      return <Badge className='badge-soft-warning'>{StatusAccumulationOptions[3].label}</Badge>
  }
}

export {
  HistoryModel,
  HistoryCategory,
  AccountType,
  Store,
  HistoryCode,
  StatusHistory,
  Serial,
  ActivatedDate,
  Customer,
  Action
}
