import React, { useEffect, useState } from 'react'
import { Col, Label, Row } from 'reactstrap'

//flatpickr
import 'flatpickr/dist/themes/material_blue.css'
import { ActivationVN, CommonVN, LocalityVN, ManageContentVN } from 'constants/vn'
import { FormSelect } from './form-select/FormSelect'
import { AREA_TYPE } from 'pages/ManageLocality/constants'
import { getZoneAPI, getAreaManagementOptionsAPI } from 'helpers/backend_helper'
import { DatePicker } from 'antd'
import { createSelector } from 'reselect'
import { useSelector } from 'react-redux'
import { MAX_LENGTH_SEARCH, TypeContentOptions, TypeProgramOptions } from 'constants/constants'
import { FormInput } from './form-input/FormInput'
const { RangePicker } = DatePicker

const ReportUserStatisticGlobalFilter = ({ setGlobalFilter, search, setSearch }) => {
  const [filter, setFilter] = useState({})
  const [zone, setZone] = useState([])
  const [subZone, setSubZone] = useState([])
  const [valueFilter, setValueFilter] = useState()
  const [selectedZone, setSelectedZone] = useState()
  const selectLayoutState = (state) => state.Layout
  const LayoutProperties = createSelector(selectLayoutState, (app) => ({
    province: app.province,
    district: app.district,
    ward: app.ward
  }))
  const { province } = useSelector(LayoutProperties)

  useEffect(() => {
    handleGetAllZone()
  }, [])

  useEffect(() => {
    if (selectedZone) {
      handleGetSubZone()
    }
  }, [selectedZone])

  const handleSelect = (value, key) => {
    setFilter((e) => ({ ...e, [key]: value }))
  }

  const handleGetAllZone = async () => {
    const res = await getZoneAPI()

    if (res.data) {
      res.data.map((item) => {
        setZone((e) => [...e, { value: item.id, label: item.name }])
      })
    }
  }

  const handleGetSubZone = async () => {
    const res = await getAreaManagementOptionsAPI({ type: AREA_TYPE.SUBZONE, parent_id: selectedZone })

    if (res.data) {
      const list_data = res.data.map((item) => {
        return {
          value: item.id,
          label: item.name
        }
      })

      setSubZone(list_data)
    }
  }

  return (
    <React.Fragment>
      <Col xxl={2} lg={2} className='mt-3'>
        <Label className='form-label'>{'Khu vực'}</Label>
        <FormSelect
          options={province.map((item) => ({ value: item.id, label: item.name })) || []}
          value={filter.province_id}
          onChange={(value) => {
            handleSelect(value, 'province_id')
          }}
          placeholder='Chọn khu vực'
        />
      </Col>

      <Col xxl={2} lg={2} className='mt-3'>
        <Label className='form-label'>{'Loại tài khoản'}</Label>
        <FormSelect
          options={[
            {
              label: 'Cửa hàng',
              value: 'user'
            },
            {
              label: 'Kĩ thuật viên',
              value: 'technician'
            }
          ]}
          value={filter.type}
          onChange={(value) => {
            handleSelect(value, 'client')
          }}
          placeholder='Tất cả'
        />
      </Col>

      <Col xxl={2} lg={2} className='mt-3'>
        <Label className='form-label'>{'Thời gian'}</Label>
        <FormSelect
          options={[
            {
              label: 'Tháng 1',
              value: '1'
            },
            {
              label: 'Tháng 2',
              value: '2'
            },
            {
              label: 'Tháng 3',
              value: '3'
            },
            {
              label: 'Tháng 4',
              value: '4'
            },
            {
              label: 'Tháng 5',
              value: '5'
            },
            {
              label: 'Tháng 6',
              value: '6'
            },
            {
              label: 'Tháng 7',
              value: '7'
            },
            {
              label: 'Tháng 8',
              value: '8'
            },
            {
              label: 'Tháng 9',
              value: '9'
            },
            {
              label: 'Tháng 10',
              value: '10'
            },
            {
              label: 'Tháng 11',
              value: '11'
            },
            {
              label: 'Tháng 12',
              value: '12'
            }
          ]}
          value={filter.type}
          onChange={(value) => {
            handleSelect(value, 'month')
          }}
          defaultValue={'Tháng 1'}
        />
      </Col>

      <Col xxl={4} lg={2} className='d-flex align-items-end justify-content-end mt-3 '>
        <div className='mb-xxl-0 me-2'>
          <button
            type='button'
            className='btn btn-soft-danger w-100'
            onClick={() => {
              setFilter({
                client: undefined,
                province_id: undefined,
                month: 1
              })
              setSearch('')
              setGlobalFilter({})
            }}
          >
            Xóa tìm kiếm
          </button>
        </div>
        <div className='mb-xxl-0'>
          <button
            type='button'
            className='btn btn-primary w-100'
            onClick={() => {
              const _filter = !filter
                ? filter
                : Object.fromEntries(Object.entries(filter).filter(([key, value]) => value !== undefined))
              setGlobalFilter({ ..._filter, search })
            }}
          >
            Tìm kiếm
          </button>
        </div>
      </Col>
    </React.Fragment>
  )
}
export default ReportUserStatisticGlobalFilter
