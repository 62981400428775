import React from 'react'
import { Badge, UncontrolledTooltip } from 'reactstrap'
import { Link } from 'react-router-dom'
import { ManageKTVVN } from 'constants/vn'
import '../../../../node_modules/bootstrap/dist/css/bootstrap.min.css'
import { formateDate } from 'helpers/global_helper'

// const Email = (cell) => {
//   return cell.value ? cell.value : '--'
// }

// const CreatedDate = (cell) => {
//   return cell.value ? formateDate(cell.value) : '--'
// }

const Status = (cell) => {
  switch (cell.value) {
    case 'pending':
      return <Badge className='badge-soft-warning'>{'Đang chờ'}</Badge>
    case 'processing':
      return <Badge className='badge-soft-primary'>{'Đang xử lý'}</Badge>
    case 'success':
      return <Badge className='badge-soft-success'>{'Thành công'}</Badge>
    case 'failed':
      return <Badge className='badge-soft-danger'>{'Thất bại'}</Badge>
  }
}

const Type = (cell) => {
  const type = [
    {
      value: 'register',
      label: 'Đăng ký tài khoản'
    },
    {
      value: 'recoverpassword',
      label: 'Khôi phục mật khẩu'
    },
    {
      value: 'changepassword',
      label: 'Đổi mật khẩu'
    },
    {
      value: 'deleteaccount',
      label: 'Xoá tài khoản'
    },
    {
      value: 'resetpassword',
      label: 'Đặt lại mật khẩu'
    }
  ]

  return cell?.value ? type.find((e) => e.value === cell?.value)?.label : '--'
}

export { Status, Type }
