import { PermissionVN, LocalityVN, AccountClassVN } from "constants/vn"
import React, { useEffect, useState } from "react"
import { Col, Label, Modal, ModalBody, Row } from "reactstrap"
import { createSelector } from "reselect"
import { useSelector } from "react-redux"
import { getPermissionList, getRoleAll, setTypePopupPermission } from "store/actions"
import { postCreateRankAPI, updateRankAPI } from "helpers/backend_helper"
import { useDispatch } from "react-redux"
import { toast } from "react-toastify"
import { Form, Tree, Switch, Divider, Input, Spin } from "antd"
import { FormInput } from "components/Common/form-input/FormInput"
import { FormSelect } from "components/Common/form-select/FormSelect"
import { OptionTypeAccount } from "../../constants/index"
import ConfirmModal from "components/Common/ConfirmModal"
import { isEqual, debounce } from "lodash"

const AddEditAccountClass = ({ show, onCloseClick, selected, type, refresh }) => {
    const dispatch = useDispatch()

    const isEdit = type !== "view"
    const [form] = Form.useForm()
    const watchForm = Form.useWatch([], form)
    const [disabledSubmit, setDisabledSubmit] = useState(true)
    const [loading, setLoading] = useState(false)
    const [onCancel, setOnCancel] = useState(false)
    const [initData, setInitData] = useState()

    useEffect(() => {
        if (type != "create" && selected) {
            form.setFieldsValue({
                name: selected.name,
                accountType: selected.client,
            })

            setInitData({
                name: selected.name,
                accountType: selected.client,
            })
        }
    }, [type, selected])

    useEffect(() => {
        form.validateFields({ validateOnly: true }).then(
            () => {
                setDisabledSubmit(false)
            },
            () => {
                setDisabledSubmit(true)
            }
        )
    }, [watchForm])

    const onFinish = async values => {
        if (type == "view") {
            dispatch(setTypePopupPermission("edit"))
            return
        }
        setLoading(true)
        try {
            const payload = {
                name: values.name,
                client: values.accountType,
            }

            if (type == "create") {
                const res = await postCreateRankAPI({ ...payload })

                if (res) {
                    toast("Thêm mới loại hạng thành công", { type: "success" })
                    onCloseClick()
                    refresh("create")
                }
            } else {
                const res = await updateRankAPI({ ...payload, id: selected.id })

                if (res) {
                    toast("Cập nhật tên hạng thành công", { type: "success" })
                    onCloseClick()
                    refresh("edit")
                }
            }
        } catch (error) {
            toast(error.response.data.message, { type: "error" })
        }
        setLoading(false)
    }

    const onClickCancel = () => {
        setOnCancel(false)
        onCloseClick()
    }

    const checkClickCancel = () => {
        if (type != "view") {
            if (type == "create") {
                if (form.isFieldsTouched()) {
                    setOnCancel(true)
                } else {
                    onCloseClick()
                }
            } else {
                if (!isEqual(initData, watchForm)) {
                    setOnCancel(true)
                } else {
                    onCloseClick()
                }
            }
        } else {
            onCloseClick()
        }
    }
    return (
        <Modal
            size="lg"
            isOpen={show}
            toggle={() => {
                checkClickCancel()
            }}
            centered={true}
        >
            <div className="modal-content">
                <ModalBody className="p-4 text-start">
                    <Spin spinning={loading}>
                        <button type="button" onClick={checkClickCancel} className="btn-close position-absolute end-0 top-0" />
                        {type == "view" && <h2>{AccountClassVN.viewModal}</h2>}
                        {type == "create" && <h2>{AccountClassVN.addModal}</h2>}
                        {type == "edit" && <h2>{AccountClassVN.editModal}</h2>}
                        <Form layout="vertical" onFinish={onFinish} form={form}>
                            <div className="form-group">
                                <Form.Item
                                    label={AccountClassVN.form.accountType.label}
                                    name={AccountClassVN.form.accountType.name}
                                    rules={[{ required: true, message: AccountClassVN.form.accountType.required }]}
                                >
                                    <FormSelect
                                        label={AccountClassVN.form.accountType.label}
                                        placeholder={AccountClassVN.form.accountType.placeholder}
                                        disabled={!isEdit || type == "edit"}
                                        options={OptionTypeAccount}
                                    />
                                </Form.Item>
                                <Form.Item
                                    className="mt-2"
                                    label={AccountClassVN.form.name.label}
                                    name={AccountClassVN.form.name.name}
                                    rules={[{ required: true, message: AccountClassVN.form.name.required }]}
                                >
                                    <FormInput
                                        label={AccountClassVN.form.name.label}
                                        placeholder={AccountClassVN.form.name.placeholder}
                                        disabled={!isEdit}
                                        maxLength={50}
                                    />
                                </Form.Item>
                            </div>
                            <div className="hstack gap-2 justify-content-center mt-4">
                                <button type="button" className="btn btn-soft-secondary w-25 " onClick={checkClickCancel}>
                                    Hủy
                                </button>
                                <button type={"submit"} className="btn btn-primary w-25" disabled={disabledSubmit || isEqual(initData, watchForm)}
                                    onClick={debounce(() => {
                                        console.log('isEqual(initData, watchForm)', initData, watchForm)
                                        // form.submit()
                                    }, 500)}>
                                    {isEdit ? "Lưu" : "Chỉnh sửa"}
                                </button>
                            </div>
                        </Form>
                    </Spin>
                </ModalBody>
                <ConfirmModal
                    show={onCancel}
                    onConfirmClick={onClickCancel}
                    onCloseClick={() => setOnCancel(false)}
                    title={`Xác nhận`}
                    description={`Thay đổi sẽ không được lưu. Bạn có chắc chắn muốn thoát không?`}
                />
            </div>
        </Modal>
    )
}
export default AddEditAccountClass
