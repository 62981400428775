import {
    GET_MANAGE_PERMISSION,
    GET_MANAGE_PERMISSION_FAIL,
    GET_MANAGE_PERMISSION_SUCCESS,
    GET_PERMISSION_DETAIL,
    GET_PERMISSION_DETAIL_SUCCESS,
    GET_PERMISSION_DETAIL_FAIL,
    SET_TYPE_POPUP,
    GET_ROLE_ALL,
    GET_ROLE_ALL_FAIL,
    GET_ROLE_ALL_SUCCESS,
    GET_ROLE_ALL_LIST,
} from "./actionTypes"

export const getPermissionList = params => ({
    type: GET_MANAGE_PERMISSION,
    payload: params,
})

export const getManagePermissionSuccess = data => ({
    type: GET_MANAGE_PERMISSION_SUCCESS,
    payload: data,
})

export const getManagePermissionFail = error => ({
    type: GET_MANAGE_PERMISSION_FAIL,
    payload: error,
})

export const getPermissionDetail = data => ({
    type: GET_PERMISSION_DETAIL,
    payload: data,
})

export const getPermissionDetailSuccess = data => ({
    type: GET_PERMISSION_DETAIL_SUCCESS,
    payload: data,
})

export const getPermissionDetailFail = error => ({
    type: GET_PERMISSION_DETAIL_FAIL,
    payload: error,
})

export const setTypePopupPermission = params => ({
    type: SET_TYPE_POPUP,
    payload: params,
})

export const getRoleAll = params => ({
    type: GET_ROLE_ALL,
    payload: params,
})

export const getRoleAllSuccess = params => ({
    type: GET_ROLE_ALL_SUCCESS,
    payload: params,
})

export const getRoleAllFail = params => ({
    type: GET_ROLE_ALL_FAIL,
    payload: params,
})

export const getRoleAllList = params => ({
    type: GET_ROLE_ALL_LIST,
    payload: params,
})
