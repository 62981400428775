import {
  GET_MANAGE_NOTIFICATION_FAIL,
  GET_MANAGE_NOTIFICATION_SUCCESS,
  GET_NOTIFICATION_DETAIL_SUCCESS,
  GET_NOTIFICATION_DETAIL_FAIL,
  UPDATE_MANAGE_NOTIFICATION_SUCCESS,
  UPDATE_MANAGE_NOTIFICATION_FAIL
} from './actionTypes'

const INIT_STATE = {
  notifications: [],
  notificationDetail: undefined,
  error: {},
  manageNotificationApply: [],
  loading: true,
  total: 0
}

const NotificationReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_MANAGE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        notifications: action.payload.data,
        total: action.payload.meta.totalItems,
        loading: true
      }

    case GET_MANAGE_NOTIFICATION_FAIL:
      return {
        ...state,
        error: action.payload
      }

    case GET_NOTIFICATION_DETAIL_SUCCESS:
      return {
        ...state,
        notificationDetail: action.payload
      }

    case GET_NOTIFICATION_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload
      }

    case UPDATE_MANAGE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        notifications: state.notifications.map((manageNotification) =>
          manageNotification.id.toString() === action.payload.id.toString()
            ? { manageNotification, ...action.payload }
            : manageNotification
        )
      }

    case UPDATE_MANAGE_NOTIFICATION_FAIL:
      return {
        ...state,
        error: action.payload
      }

    default:
      return state
  }
}

export default NotificationReducer
