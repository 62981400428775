export const GET_MANAGE_ACTIVATION = "GET_ACTIVATIONLIST"
export const GET_MANAGE_ACTIVATION_FAIL = "GET_MANAGE_ACTIVATION_FAIL"
export const GET_MANAGE_ACTIVATION_SUCCESS = "GET_MANAGE_ACTIVATION_SUCCESS"

export const GET_ACTIVATION_DETAIL = "GET_ACTIVATION_DETAIL"
export const GET_ACTIVATION_DETAIL_SUCCESS = "GET_ACTIVATION_DETAIL_SUCCESS"
export const GET_ACTIVATION_DETAIL_FAIL = "GET_ACTIVATION_DETAIL_FAIL"

export const UPDATE_MANAGE_ACTIVATION = "UPDATE_MANAGE_ACTIVATION"
export const UPDATE_MANAGE_ACTIVATION_SUCCESS = "UPDATE_MANAGE_ACTIVATION_SUCCESS"
export const UPDATE_MANAGE_ACTIVATION_FAIL = "UPDATE_MANAGE_ACTIVATION_FAIL"

export const SET_TYPE_POPUP = "SET_TYPE_POPUP_ACTIVATION"
