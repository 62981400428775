import React from "react"

const ImageEmty = () => {
  return (
    <div className="bg-secondary-subtle rounded-2 w-100 d-flex justify-content-center p-4 text-body-tertiary text-opacity-25">
      <i className="mdi mdi-48px mdi-image-filter-hdr "></i>
    </div>
  )
}

export default ImageEmty
