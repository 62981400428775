import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"
// Redux
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import withRouter from "components/Common/withRouter"

// users
import user1 from "../../../assets/images/users/avatar-1.jpg"
import { profileMenuVN } from "constants/vn"
import ConfirmModal from "components/Common/ConfirmModal"

const ProfileMenu = props => {
    // Declare a new state variable, which we'll call "menu"
    const navigate = props.router.navigate
    const [menu, setMenu] = useState(false)
    const [showLogout, setShowLogout] = useState(false)

    const [username, setusername] = useState("Admin")

    useEffect(() => {
        if (localStorage.getItem("authUser")) {
            if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
                const obj = JSON.parse(localStorage.getItem("authUser"))
                setusername(obj.displayName)
            } else if (process.env.REACT_APP_DEFAULTAUTH === "fake" || process.env.REACT_APP_DEFAULTAUTH === "jwt") {
                const obj = JSON.parse(localStorage.getItem("authUser"))
                setusername(obj.username)
            }
        }
    }, [props.success])

    return (
        <React.Fragment>
            <Dropdown isOpen={menu} toggle={() => setMenu(!menu)} className="d-inline-block">
                <DropdownToggle className="btn header-item " id="page-header-user-dropdown" tag="button">
                    <img className="rounded-circle header-profile-user" src={props?.profile?.image ? props?.profile?.image : "https://pixfi.s3.ap-southeast-1.amazonaws.com/hpg/1705561583968_unnamed.png"} alt="Header Avatar" />
                    <span className="d-none d-xl-inline-block ms-2 me-1">{props?.profile?.name}</span>
                    <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                    <DropdownItem tag="a" href={profileMenuVN.profile.link}>
                        <i className="bx bx-user font-size-16 align-middle me-1" />
                        {profileMenuVN.profile.title}
                    </DropdownItem>
                    <DropdownItem tag="a" href={profileMenuVN.changePassword.link}>
                        <i className="bx bx-wallet font-size-16 align-middle me-1" />
                        {profileMenuVN.changePassword.title}
                    </DropdownItem>
                    <div className="dropdown-divider" />
                    <Link to={"#"} onClick={() => setShowLogout(true)} className="dropdown-item">
                        <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
                        <span>{profileMenuVN.logout.title}</span>
                    </Link>
                </DropdownMenu>
            </Dropdown>
            <ConfirmModal
                buttonConfirmText="Đăng xuất"
                show={showLogout}
                icon={undefined}
                hasIcon={false}
                onConfirmClick={() => navigate(profileMenuVN.logout.link)}
                onCloseClick={() => setShowLogout(false)}
                title="Đăng xuất"
                description="Bạn có chắc chắn muốn đăng xuất ?"
            />
        </React.Fragment>
    )
}

ProfileMenu.propTypes = {
    success: PropTypes.any,
    t: PropTypes.any,
}

const mapStatetoProps = state => {
    const { error, success, profile } = state.Profile
    return { error, success, profile }
}

export default withRouter(connect(mapStatetoProps, {})(withTranslation()(ProfileMenu)))
