import PropsType from 'prop-types'
import { useMemo, useState } from 'react'
import { Label, UncontrolledTooltip } from 'reactstrap'
import React from 'react'
import { Row, Col, Space, Flex } from 'antd'

//flatpickr
import { CommonVN } from 'constants/vn'

import { DatePicker } from 'antd'
import { FormInput } from 'components/Common/form-input/FormInput'
import { FormSelect } from 'components/Common/form-select/FormSelect'
import { MAX_LENGTH_SEARCH, TypeProgramOptions } from 'constants/constants'
import { Form, useNavigate } from 'react-router-dom'

const { RangePicker } = DatePicker

ConfirmProgramByRewardTypeFilter.propTypes = {
  handleFilter: PropsType.func,
  handleConfirm: PropsType.func,
  handleResetFilter: PropsType.func,
  handleExport: PropsType.func,
  handleImport: PropsType.func
}

function ConfirmProgramByRewardTypeFilter({
  handleFilter,
  handleConfirm,
  handleResetFilter,
  handleExport,
  handleImport
}) {
  const [filter, setFilter] = useState({
    search: '',
    status: '',
    time: []
  })

  const onFilterChange = (name, value) =>
    setFilter((prev) => ({
      ...prev,
      [name]: value
    }))

  const ColProps = useMemo(() => ({
    xs: { span: 24 },
    xxl: { span: 4 }
  }))

  const navigate = useNavigate()
  const onResetFilter = () => setFilter({ search: '', status: '', time: [] })

  return (
    <Row gutter={[10, 10]} align='bottom'>
      <Col {...ColProps} className='w-100'>
        <Label className='form-label'>Nhập tên tài khoản</Label>
        <FormInput
          className='w-100'
          maxLength={MAX_LENGTH_SEARCH}
          placeholder='Nhập tên tài khoản'
          value={filter?.search || ''}
          name='search'
          id={`input-filter`}
          onChange={(e) => onFilterChange('search', e.target.value)}
          onBlur={(e) => onFilterChange('search', e.target.value)}
        />
      </Col>

      <Col {...ColProps} className='w-100'>
        <Label className='form-label'>Nhập số điện thoại</Label>
        <FormInput
          className='w-100'
          maxLength={MAX_LENGTH_SEARCH}
          placeholder='Nhập số điện thoại'
          value={filter?.phone || ''}
          name='phone'
          id={`input-filter`}
          onChange={(e) => onFilterChange('phone', e.target.value)}
          onBlur={(e) => onFilterChange('phone', e.target.value)}
        />
      </Col>

      <Col {...ColProps} className='w-100'>
        <Label className='form-label'>Nhập Serial</Label>
        <FormInput
          className='w-100'
          maxLength={MAX_LENGTH_SEARCH}
          placeholder='Nhập Serial'
          value={filter?.serial || ''}
          name='serial'
          id={`input-filter`}
          onChange={(e) => onFilterChange('serial', e.target.value)}
          onBlur={(e) => onFilterChange('serial', e.target.value)}
        />
      </Col>

      <Col {...ColProps}>
        <Space>
          <button
            type='button'
            className='btn btn-soft-danger me-2 pe-4'
            onClick={() => {
              onResetFilter()
              handleResetFilter()
            }}
          >
            Xoá tìm kiếm
          </button>

          <button type='button' className='btn btn-primary me-2 pe-4' onClick={() => handleFilter(filter)}>
            Tìm kiếm
          </button>
        </Space>
      </Col>
      <Col flex='1'>
        <Flex gap='small' justify='flex-end'>
          <button className='btn btn-primary me-2 pe-4' onClick={() => handleImport()}>
            <i className='mdi mdi-database-import me-1 ms-2' />
            Import
          </button>
          <button className='btn btn-primary me-2 pe-4' onClick={() => handleExport(filter)}>
            <i className='mdi mdi-database-export me-1 ms-2' />
            Export
          </button>
          <button className='btn btn-primary me-2 pe-4' onClick={() => handleConfirm()}>
            <i className='mdi mdi-content-save-outline me-1 ms-2' />
            Xác nhận trả thưởng
          </button>
          <button
            onClick={() => {
              navigate('/manage-import-history')
            }}
            className='btn btn-primary me-2 pe-4'
          >
            <i className='mdi mdi-history me-1 ms-2' />
            {'Lịch sử import'}
          </button>
        </Flex>
      </Col>
    </Row>
  )
}

export default ConfirmProgramByRewardTypeFilter
