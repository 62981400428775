import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import usePermission from 'hooks/usePermission'

PermissionWrapper.propTypes = {
  // screenCode: [PropTypes.string.isRequired, PropTypes.array.isRequired],
  code: [PropTypes.string.isRequired, PropTypes.array.isRequired],
  children: PropTypes.node.isRequired
}

export default function PermissionWrapper({ code, children, condition }) {
  const hasPermission = usePermission({ code, children, condition })
  if (!hasPermission) return null

  return <>{children}</>
}
