export const GET_MANAGE_LOCALITY = "GET_LOCALITY_LIST"
export const GET_MANAGE_LOCALITY_FAIL = "GET_MANAGE_LOCALITY_FAIL"
export const GET_MANAGE_LOCALITY_SUCCESS = "GET_MANAGE_LOCALITY_SUCCESS"

export const GET_LOCALITY_DETAIL = "GET_LOCALITY_DETAIL"
export const GET_LOCALITY_DETAIL_SUCCESS = "GET_LOCALITY_DETAIL_SUCCESS"
export const GET_LOCALITY_DETAIL_FAIL = "GET_LOCALITY_DETAIL_FAIL"

export const UPDATE_MANAGE_LOCALITY = "UPDATE_MANAGE_LOCALITY"
export const UPDATE_MANAGE_LOCALITY_SUCCESS = "UPDATE_MANAGE_LOCALITY_SUCCESS"
export const UPDATE_MANAGE_LOCALITY_FAIL = "UPDATE_MANAGE_LOCALITY_FAIL"

export const SET_TYPE_POPUP = "SET_TYPE_POPUP_LOCALITY"
