import { MAX_LENGTH_NAME } from 'constants/constants'
import { PortfolioVN } from 'constants/vn'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import Dropzone from 'react-dropzone'
import { Link } from 'react-router-dom'
import { Card, CardBody, Col, Input, Modal, ModalBody, Row } from 'reactstrap'
import { Spin } from 'antd'

const defaultBodyNoti = (
  <Card className='bg-secondary-subtle'>
    <CardBody className=' p-2'>
      <p className='text-muted font-size-16 mb-1'>{PortfolioVN.importModal.note1}</p>
      <p className='text-muted font-size-16 mb-1'>{PortfolioVN.importModal.note2}</p>
      <p className='text-muted font-size-16 mb-0'>{PortfolioVN.importModal.note3}</p>
    </CardBody>
  </Card>
)

const ImportModal = ({
  show,
  onConfirmClick,
  onCloseClick,
  onDownloadClick,
  title = PortfolioVN.importModal.title,
  description = '',
  buttonConfirmText = 'Tải lên',
  loading,
  bodyNoti = defaultBodyNoti
}) => {
  const [selectedFiles, setselectedFiles] = useState([])

  useEffect(() => {
    setselectedFiles([])
  }, [])

  function handleAcceptedFiles(files) {
    console.log('files', files)
    // files.map(file =>
    //     Object.assign(file, {
    //         preview: URL.createObjectURL(file),
    //         formattedSize: formatBytes(file.size),
    //     })
    // )
    setselectedFiles(files)
  }

  const close = () => {
    onCloseClick()
    setselectedFiles([])
  }

  return (
    <Modal size='lg' isOpen={show} toggle={close} centered={true}>
      <Spin spinning={loading}>
        <div className='modal-content'>
          <ModalBody className='p-4 text-start'>
            <button type='button' onClick={close} className='btn-close position-absolute end-0 top-0 m-3'></button>
            <h3>{title}</h3>
            <Row>
              <Col sm='10'>
                {/* <Card className="bg-secondary-subtle">
                                    <CardBody className=" p-2">
                                        <p className="text-muted font-size-16 mb-1">{PortfolioVN.importModal.note1}</p>
                                        <p className="text-muted font-size-16 mb-1">{PortfolioVN.importModal.note2}</p>
                                        <p className="text-muted font-size-16 mb-0">{PortfolioVN.importModal.note3}</p>
                                    </CardBody>
                                </Card> */}
                {bodyNoti}
              </Col>
              <Col sm='2 d-flex align-items-end mb-4'>
                <a onClick={onDownloadClick} className='link-offset-1 text-decoration-underline'>
                  {PortfolioVN.importModal.download}
                </a>
              </Col>
            </Row>
            <Dropzone
              onDrop={(acceptedFiles) => {
                handleAcceptedFiles(acceptedFiles)
              }}
            >
              {({ getRootProps, getInputProps }) => (
                <div className='dropzone bg-secondary-subtle '>
                  <div className='dz-message needsclick mt-2' {...getRootProps()}>
                    <input {...getInputProps()} />
                    {/* <div className="mb-3">
                              <i className="display-4 text-muted bx bxs-cloud-upload" />
                            </div> */}
                    {selectedFiles.length > 0 ? (
                      <h5 className='fw-semibold'>{selectedFiles[0].name}</h5>
                    ) : (
                      <>
                        <h5 className='fw-semibold'>{PortfolioVN.importModal.dndHere}</h5>
                        <h6 className='fw-light'>{PortfolioVN.importModal.or}</h6>
                        <h5 className='text-decoration-underline fw-semibold'>{PortfolioVN.importModal.chooseHere}</h5>
                      </>
                    )}
                  </div>
                </div>
              )}
            </Dropzone>
            <div className='hstack gap-2 justify-content-center mb-0 mt-4'>
              <button
                type='button'
                className='btn btn-soft-secondary w-25 '
                onClick={() => {
                  close()
                }}
              >
                Hủy
              </button>
              <button
                disabled={selectedFiles.length == 0}
                type='button'
                className='btn btn-primary w-25'
                onClick={() => onConfirmClick(selectedFiles)}
              >
                {buttonConfirmText}
              </button>
            </div>
          </ModalBody>
        </div>
      </Spin>
    </Modal>
  )
}

ImportModal.propTypes = {
  onCloseClick: PropTypes.func,
  onConfirmClick: PropTypes.func,
  show: PropTypes.any,
  title: PropTypes.any,
  buttonConfirmText: PropTypes.string,
  onDownloadClick: PropTypes.func
}

export default ImportModal
