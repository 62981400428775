import PropsType from 'prop-types'
import { useEffect, useMemo, useState } from 'react'
import { Label } from 'reactstrap'
import React from 'react'
import { Row, Col, Space, Flex } from 'antd'
import { CommonVN, ProductVN } from 'constants/vn'
import { FormInput } from 'components/Common/form-input/FormInput'
import { FormSelect } from 'components/Common/form-select/FormSelect'
import { MAX_LENGTH_SEARCH, TypeProgramOptions, accountTypeOptions } from 'constants/constants'
import { createSelector } from 'reselect'
import { useSelector } from 'react-redux'
import { FormDateRange } from 'components/Common/form-date-range/FormDateRange'
import { getPortfolioAllAPI } from 'helpers/backend_helper'

ProductBonusTempListFilter.propTypes = {
  handleFilter: PropsType.func,
  handleExport: PropsType.func
}

function ProductBonusTempListFilter({ handleFilter, handleExport }) {
  const selectLayoutState = (state) => state.Layout
  const LayoutProperties = createSelector(selectLayoutState, (app) => ({
    zone: app.zone,
    subzone: app.subzone,
    province: app.province,
    district: app.district,
    ward: app.ward,
    myLocationManagement: app.myLocationManagement
  }))
  const { zone, subzone, province, district, myLocationManagement, ward } = useSelector(LayoutProperties)

  const [filter, setFilter] = useState({})
  const [model, setModel] = useState()

  const onFilterChange = (name, value) =>
    setFilter((prev) => ({
      ...prev,
      [name]: value
    }))

  const ColProps = useMemo(() => ({
    xs: { span: 24 },
    xxl: { span: 8 }
  }))

  const lstDistrict = myLocationManagement

  ////Lấy danh sách ward
  // Get all unique parentId values from lstDistrict
  const wardParentIds = myLocationManagement?.map((district) => district.id)

  //Từ wardParentIds, lấy ra những ward có id trong wardParentIds
  const lstWard = ward.filter((ward) => wardParentIds.includes(ward.parentId))

  ////Lấy danh sách province
  // Get all unique parentId values from lstDistrict
  const districtParentIds = lstDistrict?.map((district) => district.parentId)
  //Từ districtParentIds, lấy ra những district có id trong districtParentIds
  const lstProvionce = province.filter((province) => districtParentIds.includes(province.id))

  ////Lấy danh sách subZone
  // Get all unique subZoneId(parentId) values from lstProvionce
  const provinceParentIds = lstProvionce?.map((province) => province.subZoneId)
  //Từ provinceParentIds, lấy ra những subZone có id trong provinceParentIds
  const lstSubZone = subzone?.filter((subZone) => provinceParentIds.includes(subZone.id))

  ////Lấy danh sách zone
  // Get all unique zoneId(parentId) values from lstProvionce
  const subZoneParentIds = lstProvionce?.map((province) => province.zoneId) // trong lstProvionce có lưu zoneId
  //Từ provinceParentIds, lấy ra những subZone có id trong provinceParentIds
  const lstZone = zone.filter((zone) => subZoneParentIds.includes(zone.id))
  const onResetFilter = () =>
    setFilter({
      zone: undefined,

      zone_ids: undefined,
      sub_zone_ids: undefined,
      province_ids: undefined,
      district_ids: undefined,
      location_ids: undefined,
      search: '',
      phone: '',
      name: '',
      serial: '',
      user_client_type: undefined,
      user_client_type_name: undefined,
      start_date1: undefined,
      end_date1: undefined,
      model_ids: undefined,
      model_names: undefined,
      type: undefined,
      type_name: undefined,
      zone_names: undefined,
      sub_zone_names: undefined,
      province_names: undefined,
      district_names: undefined,
      location_names: undefined
    })

  const getIndustryDataOptions = async () => {
    try {
      const resModel = await getPortfolioAllAPI({ type: 'model' })
      setModel(resModel?.data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getIndustryDataOptions()
  }, [])

  return (
    <Row gutter={[10, 10]} align='bottom'>
      <Col {...ColProps} className='w-100'>
        <Label className='form-label'>Nhập Serial</Label>
        <FormInput
          className='w-100'
          maxLength={MAX_LENGTH_SEARCH}
          placeholder='Nhập serial '
          value={filter?.serial || ''}
          name='serial'
          id={`input-filter`}
          onChange={(e) => onFilterChange('serial', e.target.value)}
          onBlur={(e) => onFilterChange('serial', e.target.value)}
        />
      </Col>

      <Col {...ColProps} className='w-100'>
        <Label className='form-label'>Nhập Model</Label>

        <FormSelect
          placeholder={'Nhập Model'}
          options={
            model?.length > 0
              ? [{ label: 'Tất cả', value: 'all' }].concat(
                  model?.map((item) => ({
                    value: item.id,
                    label: item.name
                  }))
                )
              : []
          }
          onChange={(value, label) => {
            onFilterChange('model_ids', value)
            onFilterChange(
              'model_names',
              label.map((e) => e.label)
            )
          }}
          onSelect={(value) => {
            const all = model?.map((item) => item.id)
            const all_names = model?.map((item) => item.name)

            if (value && value === 'all') {
              onFilterChange('model_ids', [...all])
              onFilterChange('model_names', [...all_names])
            }
          }}
          value={filter.model_ids}
          mode='multiple'
        />
      </Col>

      <Col {...ColProps} className='w-100'>
        <Label className='form-label'>Nhập tên cửa hàng/ KTV</Label>
        <FormInput
          className='w-100'
          maxLength={MAX_LENGTH_SEARCH}
          placeholder='Nhập tên '
          value={filter?.search || ''}
          name='search'
          id={`input-filter`}
          onChange={(e) => onFilterChange('search', e.target.value)}
          onBlur={(e) => onFilterChange('search', e.target.value)}
        />
      </Col>

      <Col {...ColProps} className='w-100'>
        <Label className='form-label'>Nhập số điện thoại</Label>
        <FormInput
          className='w-100'
          maxLength={MAX_LENGTH_SEARCH}
          placeholder='Nhập SĐT '
          value={filter?.phone || ''}
          name='phone'
          id={`input-filter`}
          onChange={(e) => onFilterChange('phone', e.target.value)}
          onBlur={(e) => onFilterChange('phone', e.target.value)}
        />
      </Col>
      <Col {...ColProps}>
        <Label className='form-label'>Loại chương trình</Label>
        <FormSelect
          options={TypeProgramOptions}
          value={filter.type}
          onChange={(e, label) => {
            onFilterChange('type', e)
            onFilterChange('type_name', label.label)
          }}
          placeholder={'Loại chương trình'}
        />
      </Col>
      <Col {...ColProps}>
        <Label className='form-label '>Loại tài khoản</Label>
        <FormSelect
          allowClear
          options={accountTypeOptions}
          value={filter.user_client_type}
          onChange={(e, label) => {
            onFilterChange('user_client_type', e)
            onFilterChange('user_client_type_name', label.label)
          }}
          placeholder={'Chọn loại tài khoản'}
        />
      </Col>
      <Col {...ColProps}>
        <Label className='form-label'>Thời gian áp dụng</Label>
        <FormDateRange
          className='w-100'
          size='large'
          format={CommonVN.datePlaceholder.toUpperCase()}
          placeholder={[CommonVN.datePlaceholder, CommonVN.datePlaceholder]}
          value={[filter.start_date1, filter.end_date1]}
          onChange={(value) => {
            if (value) {
              onFilterChange('start_date1', value[0])
              onFilterChange('end_date1', value[1])
            } else {
              onFilterChange('start_date1', undefined)
              onFilterChange('end_date1', undefined)
            }
          }}
        />
      </Col>
      <Col {...ColProps} className='w-100'>
        <Label className='form-label'>Miền</Label>
        <FormSelect
          options={[{ label: 'Tất cả', value: 'all' }].concat(
            lstZone?.map((item) => ({
              value: item.id,
              label: item.name
            }))
          )}
          value={filter.zone_ids}
          onChange={(value, label) => {
            onFilterChange('zone_ids', value)
            onFilterChange(
              'zone_names',
              label.map((e) => e.label)
            )

            onFilterChange('sub_zone_ids', [])
            onFilterChange('province_ids', [])
            onFilterChange('district_ids', [])
            onFilterChange('ward_ids', [])
            onFilterChange('sub_zone_names', [])
            onFilterChange('province_names', [])
            onFilterChange('district_names', [])
            onFilterChange('ward_names', [])
          }}
          onSelect={(value) => {
            const all = lstZone?.map((item) => item.id)
            const all_names = lstZone?.map((item) => item.name)

            if (value && value === 'all') {
              onFilterChange('zone_ids', [...all])
              onFilterChange('zone_names', [...all_names])
            }
          }}
          placeholder={ProductVN.filter.zone.placeholder}
          mode='multiple'
        />
      </Col>
      <Col {...ColProps} className='w-100'>
        <Label className='form-label'>Vùng</Label>
        <FormSelect
          options={
            lstSubZone?.filter((e) => filter.zone_ids?.includes(e.zoneId))?.length > 0
              ? [{ label: 'Tất cả', value: 'all' }].concat(
                  lstSubZone
                    ?.filter((e) => filter.zone_ids?.includes(e.zoneId))
                    ?.map((item) => ({
                      value: item.id,
                      label: item.name
                    }))
                )
              : []
          }
          value={filter.sub_zone_ids}
          onChange={(value, label) => {
            onFilterChange('sub_zone_ids', value)
            onFilterChange(
              'sub_zone_names',
              label.map((e) => e.label)
            )

            onFilterChange('province_ids', [])
            onFilterChange('district_ids', [])
            onFilterChange('ward_ids', [])
            onFilterChange('province_names', [])
            onFilterChange('district_names', [])
            onFilterChange('ward_names', [])
          }}
          onSelect={(value) => {
            const all = lstSubZone?.filter((e) => filter.zone_ids?.includes(e.zoneId))?.map((item) => item.id)
            const all_names = lstSubZone?.filter((e) => filter.zone_ids?.includes(e.zoneId))?.map((item) => item.name)

            if (value && value === 'all') {
              onFilterChange('sub_zone_ids', [...all])
              onFilterChange('sub_zone_names', [...all_names])
            }
          }}
          placeholder={ProductVN.filter.zone.placeholder}
          mode='multiple'
        />
      </Col>

      <Col {...ColProps} className='w-100'>
        <Label className='form-label'>Tỉnh/ Thành phố</Label>
        <FormSelect
          options={
            lstProvionce?.filter((e) => filter.sub_zone_ids?.includes(e.subZoneId))?.length > 0
              ? [{ label: 'Tất cả', value: 'all' }].concat(
                  lstProvionce
                    ?.filter((e) => filter.sub_zone_ids?.includes(e.subZoneId))
                    ?.map((item) => ({
                      value: item.id,
                      label: item.name
                    }))
                )
              : []
          }
          value={filter.province_ids}
          onChange={(value, label) => {
            onFilterChange('province_ids', value)
            onFilterChange(
              'province_names',
              label.map((e) => e.label)
            )

            onFilterChange('district_ids', [])
            onFilterChange('ward_ids', [])
            onFilterChange('district_names', [])
            onFilterChange('ward_names', [])
          }}
          onSelect={(value) => {
            const all = lstProvionce?.filter((e) => filter.sub_zone_ids?.includes(e.subZoneId))?.map((item) => item.id)
            const all_names = lstProvionce
              ?.filter((e) => filter.sub_zone_ids?.includes(e.subZoneId))
              ?.map((item) => item.name)

            if (value && value === 'all') {
              onFilterChange('province_ids', [...all])
              onFilterChange('province_names', [...all_names])
            }
          }}
          placeholder='Tất cả'
          mode='multiple'
        />
      </Col>
      <Col {...ColProps} className='w-100'>
        <Label className='form-label'>Quận/ Huyện</Label>
        <FormSelect
          options={
            lstDistrict?.filter((e) => filter.province_ids?.includes(e.parentId))?.length > 0
              ? [{ label: 'Tất cả', value: 'all' }].concat(
                  lstDistrict
                    ?.filter((e) => filter.province_ids?.includes(e.parentId))
                    ?.map((item) => ({
                      value: item.id,
                      label: item.name
                    }))
                )
              : []
          }
          value={filter.district_ids}
          onChange={(value, label) => {
            onFilterChange('district_ids', value)
            onFilterChange(
              'district_names',
              label.map((e) => e.label)
            )
            onFilterChange('ward_ids', [])
            onFilterChange('ward_names', [])
          }}
          onSelect={(value) => {
            const all = lstDistrict?.filter((e) => filter.province_ids?.includes(e.parentId))?.map((item) => item.id)
            const all_names = lstDistrict
              ?.filter((e) => filter.province_ids?.includes(e.parentId))
              ?.map((item) => item.name)

            if (value && value === 'all') {
              onFilterChange('district_ids', [...all])
              onFilterChange('district_names', [...all_names])
            }
          }}
          placeholder='Tất cả'
          mode='multiple'
        />
      </Col>
      <Col {...ColProps} className='w-100'>
        <Label className='form-label'>Phường xã</Label>
        <FormSelect
          options={
            lstWard?.filter((e) => filter.district_ids?.includes(e.parentId))?.length > 0
              ? [{ label: 'Tất cả', value: 'all' }].concat(
                  lstWard
                    ?.filter((e) => filter.district_ids?.includes(e.parentId))
                    ?.map((item) => ({
                      value: item.id,
                      label: item.name
                    }))
                )
              : []
          }
          value={filter.ward_ids}
          onChange={(value, label) => {
            onFilterChange('ward_ids', value)
            onFilterChange(
              'ward_names',
              label.map((e) => e.label)
            )
          }}
          onSelect={(value) => {
            const all = lstWard?.filter((e) => filter.district_ids?.includes(e.parentId))?.map((item) => item.id)
            const all_names = lstWard
              ?.filter((e) => filter.district_ids?.includes(e.parentId))
              ?.map((item) => item.name)
            if (value && value === 'all') {
              onFilterChange('ward_ids', [...all])
              onFilterChange('ward_names', [...all_names])
            }
          }}
          mode='multiple'
          placeholder='Tất cả'
        />
      </Col>
      <Col xxl={6} lg={6} className='mt-3'>
        <Space>
          <button type='button' className='btn btn-soft-danger' onClick={onResetFilter}>
            Xóa tìm kiếm
          </button>

          <button type='button' className='btn btn-primary' onClick={() => handleFilter(filter)}>
            Tìm kiếm
          </button>
        </Space>
      </Col>
      <Col flex='1'>
        <Flex gap='small' justify='flex-end'>
          <button className='btn btn-primary' onClick={() => handleExport(filter)}>
            <i className='mdi mdi-database-export me-1 ms-2' />
            Export
          </button>
        </Flex>
      </Col>
    </Row>
  )
}

export default ProductBonusTempListFilter
