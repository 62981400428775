import React from "react"
import { Badge, UncontrolledTooltip } from "reactstrap"
import { Link } from "react-router-dom"
import { UserVN } from "constants/vn"
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"

const Name = cell => {
    return cell.value ? cell.value : "--"
}
const Abbreviation = cell => {
    return cell.value ? cell.value : "--"

}
export { Name, Abbreviation }
