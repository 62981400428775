import {
    GET_MANAGE_UNIT_FAIL,
    GET_MANAGE_UNIT_SUCCESS,
    GET_UNIT_DETAIL_FAIL,
    UPDATE_MANAGE_UNIT_SUCCESS,
    UPDATE_MANAGE_UNIT_FAIL,
    SET_TYPE_POPUP,
    GET_UNIT_DETAIL_SUCCESS,
    GET_DEPARTMENT_ALL_FAIL,
    GET_DEPARTMENT_ALL_SUCCESS,
    GET_DEPARTMENT_ALL_LIST,
    SET_FILTERS_UNIT
} from "./actionTypes"

const INIT_STATE = {
    units: [],
    unitDetail: undefined,
    type: "create",
    loading: true,
    total: 0,
    departments: [],
    department_list: [],
    filters: {}
}

const UnitReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_MANAGE_UNIT_SUCCESS:
            return {
                ...state,
                units: action.payload.data,
                total: action.payload.meta.totalItems,
                loading: false,
            }

        case GET_MANAGE_UNIT_FAIL:
            return {
                ...state,
                error: action.payload,
            }

        case SET_TYPE_POPUP:
            return {
                ...state,
                type: action.payload,
            }
        case SET_FILTERS_UNIT:
            return {
                ...state,
                filters: action.payload,
            }
        case GET_UNIT_DETAIL_SUCCESS:
            return {
                ...state,
                unitDetail: action.payload,
            }

        case GET_UNIT_DETAIL_FAIL:
            return {
                ...state,
                error: action.payload,
            }

        case UPDATE_MANAGE_UNIT_SUCCESS:
            return {
                ...state,
                shops: state.shops.map(manageProduct =>
                    manageProduct.id.toString() === action.payload.id.toString() ? { manageProduct, ...action.payload } : manageProduct
                ),
            }

        case UPDATE_MANAGE_UNIT_FAIL:
            return {
                ...state,
                error: action.payload,
            }

        case GET_DEPARTMENT_ALL_SUCCESS:
            return {
                ...state,
                departments: action.payload.data,
                loading: false,
            }

        case GET_DEPARTMENT_ALL_FAIL:
            return {
                ...state,
                error: action.payload,
            }

        case GET_DEPARTMENT_ALL_LIST:
            return {
                ...state,
                department_list: action.payload,
            }

        default:
            return state
    }
}

export default UnitReducer
