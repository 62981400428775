export const GET_MANAGE_ACCUMULATION = "GET_ACCUMULATIONLIST"
export const GET_MANAGE_ACCUMULATION_FAIL = "GET_MANAGE_ACCUMULATION_FAIL"
export const GET_MANAGE_ACCUMULATION_SUCCESS = "GET_MANAGE_ACCUMULATION_SUCCESS"

export const GET_ACCUMULATION_DETAIL = "GET_ACCUMULATION_DETAIL"
export const GET_ACCUMULATION_DETAIL_SUCCESS = "GET_ACCUMULATION_DETAIL_SUCCESS"
export const GET_ACCUMULATION_DETAIL_FAIL = "GET_ACCUMULATION_DETAIL_FAIL"

export const UPDATE_MANAGE_ACCUMULATION = "UPDATE_MANAGE_ACCUMULATION"
export const UPDATE_MANAGE_ACCUMULATION_SUCCESS = "UPDATE_MANAGE_ACCUMULATION_SUCCESS"
export const UPDATE_MANAGE_ACCUMULATION_FAIL = "UPDATE_MANAGE_ACCUMULATION_FAIL"

export const SET_TYPE_POPUP = "SET_TYPE_POPUP_ACCUMULATION"
