import PropsType from 'prop-types'
import { useMemo, useState } from 'react'
import { Label, UncontrolledTooltip } from 'reactstrap'
import React from 'react'
import { Row, Col, Space, Flex } from 'antd'

//flatpickr
import { CommonVN } from 'constants/vn'

import { DatePicker } from 'antd'
import { FormInput } from 'components/Common/form-input/FormInput'
import { FormSelect } from 'components/Common/form-select/FormSelect'
import { MAX_LENGTH_SEARCH, TypeProgramOptions } from 'constants/constants'

const { RangePicker } = DatePicker

ProgramByAreaAndRewardTypeDisplayFilter.propTypes = {
  handleFilter: PropsType.func,
  handleExport: PropsType.func,
  handleResetFilter: PropsType.func
}

function ProgramByAreaAndRewardTypeDisplayFilter({ handleFilter, handleExport, handleResetFilter }) {
  console.log(handleResetFilter)

  const [filter, setFilter] = useState({})

  const onFilterChange = (name, value) =>
    setFilter((prev) => ({
      ...prev,
      [name]: value
    }))

  const ColProps = useMemo(() => ({
    xs: { span: 24 },
    xxl: { span: 4 }
  }))

  const onResetFilter = () =>
    setFilter({ search: '', phone: '', serial: '', status: undefined, time: [], status_name: undefined })

  return (
    <Row gutter={[10, 10]} align='bottom'>
      <Col {...ColProps} className='w-100'>
        <Label className='form-label'>Nhập tên tài khoản</Label>
        <FormInput
          className='w-100'
          maxLength={MAX_LENGTH_SEARCH}
          placeholder='Nhập tên tài khoản'
          value={filter?.search || ''}
          name='search'
          id={`input-filter`}
          onChange={(e) => onFilterChange('search', e.target.value)}
          onBlur={(e) => onFilterChange('search', e.target.value)}
        />
      </Col>

      <Col {...ColProps} className='w-100'>
        <Label className='form-label'>Nhập số điện thoại</Label>
        <FormInput
          className='w-100'
          maxLength={MAX_LENGTH_SEARCH}
          placeholder='Nhập số điện thoại'
          value={filter?.phone || ''}
          name='phone'
          id={`input-filter`}
          onChange={(e) => onFilterChange('phone', e.target.value)}
          onBlur={(e) => onFilterChange('phone', e.target.value)}
        />
      </Col>

      <Col {...ColProps} className='w-100'>
        <Label className='form-label'>Nhập Serial</Label>
        <FormInput
          className='w-100'
          maxLength={MAX_LENGTH_SEARCH}
          placeholder='Nhập Serial'
          value={filter?.serial || ''}
          name='serial'
          id={`input-filter`}
          onChange={(e) => onFilterChange('serial', e.target.value)}
          onBlur={(e) => onFilterChange('serial', e.target.value)}
        />
      </Col>

      <Col xxl={4} className='mt-3' lg={4}>
        <Label className='form-label'>{'Trạng thái'}</Label>
        <FormSelect
          options={[
            {
              label: 'Chờ đối soát',
              value: 0
            },
            {
              label: 'Đã đối soát',
              value: 1
            },
            {
              label: 'Đã từ chối',
              value: 2
            },
            {
              label: 'Tự động từ chối',
              value: 3
            }
          ]}
          value={filter?.status}
          name='status'
          placeholder={'Chọn trạng thái đối soát'}
          onChange={(value, label) => {
            onFilterChange('status', value)
            onFilterChange('status_name', label.label)
          }}
        />
      </Col>
      <Col {...ColProps}>
        <Space>
          <button
            type='button'
            className='btn btn-soft-danger'
            onClick={() => {
              onResetFilter()
              handleResetFilter()
            }}
          >
            Xóa tìm kiếm
          </button>
          <button type='button' className='btn btn-primary' onClick={() => handleFilter(filter)}>
            Tìm kiếm
          </button>
        </Space>
      </Col>
      <Col flex='1'>
        <Flex gap='small' justify='flex-end'>
          <button className='btn btn-primary me-2 pe-4' onClick={() => handleExport(filter)}>
            <i className='mdi mdi-database-export me-1 ms-2' />
            Export
          </button>
        </Flex>
      </Col>
    </Row>
  )
}

export default ProgramByAreaAndRewardTypeDisplayFilter
