import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Alert, Badge, Card, CardBody, Col, Container, Input, Label, Row, UncontrolledTooltip } from 'reactstrap'

//redux
import { useDispatch } from 'react-redux'
import withRouter from 'components/Common/withRouter'

//Import Breadcrumb
import Breadcrumb from '../../components/Common/Breadcrumb'
import { ManageContentVN } from 'constants/vn'

// styles
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css'
import { getContentDetail, getProgramByAreaAndRewardType, getReconcileProgramByArea } from 'store/actions'
import { createSelector } from 'reselect'
import { useSelector } from 'react-redux'
import {
  putContentDetailAPI,
  postContentAPI,
  getProgramListAPI,
  getProgramDetailsAPI,
  getRankAllAPI,
  postImage,
  patchStatusContentAPI,
  confirmReconcileByProduct,
  confirmReconcileByProductAPI,
  exportProgrammingByAreaAndRewardTypeAPI,
  importFileAPI,
  checkUploadFileAPI,
  exportFileError,
  downloadTemplateReportAPI,
  checkExportFileAPI
} from 'helpers/backend_helper'
import { checkFileSizeValid, checkIsLocked, formateDate } from 'helpers/global_helper'
import { toast } from 'react-toastify'
import { MAX_LENGTH_NAME, typeAccountProgramOptions, TypeContentOptions, TypeProgramOptions } from 'constants/constants'

import { debounce, isEqual } from 'lodash'
import { Form, Image, Upload, Button, Spin, Tooltip, Space } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import { FormSelect } from 'components/Common/form-select/FormSelect'
import { FormInput } from 'components/Common/form-input/FormInput'

import { FormDateRange } from 'components/Common/form-date-range/FormDateRange'
import dayjs from 'dayjs'

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import ConfirmModal from 'components/Common/ConfirmModal'
import moment from 'moment'
import * as url from 'helpers/url_helper'
import EditorCustom from 'components/Common/EditorCustom/EditorCustom'

import TableContainer from 'components/Common/TableContainer'
import Spinners from 'components/Common/Spinner'
import { Name, ReportCode, TotalCompleted, TotalUser } from './ReconcileProgramByAreaCol'
import { Link } from 'react-router-dom'
import {
  Checked,
  Date,
  ListCheckBox,
  ListDate,
  ListModel,
  ListMoney,
  ListReasonFormInput,
  ListSerial,
  Model,
  Money,
  Phone,
  PhoneGrp,
  Serial,
  Status,
  TotalMoney,
  TotalReward,
  Type
} from './ProgramByAreaAndRewardTypeCol'
import { TableRowSpan } from 'components/Common/TableCustom'
import { ImageGalleryModal } from 'pages/RecordPayment/components'
import Checkbox from 'antd/es/checkbox/Checkbox'
import ProgramByAreaAndRewardTypeFilter from './components/ProgramByAreaAndRewardTypeFilter'
import { CONFIRM_RECONCILE_BY_PRODUCT } from 'helpers/url_helper'
import fileDownload from 'js-file-download'
import ImportModal from 'components/Common/ImportModal'
import { BaseString } from 'pages/ManageNotification/ManageNotificationCol'
import { GrString } from 'pages/ReportTemporaryReconciliation/components/ManageAccountClass/ManageAccountCol'

const ProgramByAreaAndRewardType = (props) => {
  //meta title
  document.title = 'Content Detail | HPG'
  const params = props.router.params
  var lstTask = []
  const stringUrl = params.id
  // Initialize an empty object to store key-value stringUrl
  const paramsFromUrl = {}

  // Split the string by '&' to get individual key-value pairs
  const urlSplit = stringUrl.split('&')

  // Loop through each key-value pair
  urlSplit.forEach((pair) => {
    // Split each property by '=' to separate key and value
    const [key, value] = pair.split('=')
    // Store the key-value pair in the params object
    paramsFromUrl[key] = value
  })

  const [descriptionState, setDescriptionState] = useState({})
  useEffect(() => {
    console.log('descriptionStateUseEffect', descriptionState)
  }, [descriptionState])

  const provinceId = paramsFromUrl['province_id']
  const id = paramsFromUrl['id']

  const [valueFilter, setValueFilter] = useState()
  const [form] = Form.useForm()
  const watchForm = Form.useWatch([], form)
  const dispatch = useDispatch()
  const [checked, setChecked] = useState(false)

  const programByAreaAndRewardTypeState = (state) => state.ProgramBonusTempReducer
  const ContentDetailProperties = createSelector(programByAreaAndRewardTypeState, (contentReducer) => ({
    contents: contentReducer.programByAreaAndRewardTypes,
    value: contentReducer.value,
    meta: contentReducer.meta,
    loading: contentReducer.loading,
    total: contentReducer.total,
    pageIndex: contentReducer?.meta?.currentPage,
    pageSize: contentReducer?.meta?.itemsPerPage,
    totalRows: contentReducer?.meta?.totalItems
  }))

  const { contents, loading, value, total, meta, pageIndex, pageSize, totalRows } = useSelector(ContentDetailProperties)
  const selectProfileState = (state) => state.Profile
  const ProfileProperties = createSelector(selectProfileState, (profile) => ({
    profile: profile.profile
  }))
  const { profile } = useSelector(ProfileProperties)
  const selectLayoutState = (state) => state.Layout
  const LayoutProperties = createSelector(selectLayoutState, (app) => ({
    province: app.province,
    district: app.district,
    ward: app.ward,
    zone: app.zone,
    subzone: app.subzone
  }))

  const { province, district, ward, zone, subzone } = useSelector(LayoutProperties)
  const [showReject, setShowReject] = useState(false)
  const [submittable, setSubmittable] = useState(false)
  const [initData, setInitData] = useState()
  const [showApprove, setShowApprove] = useState(false)
  const [editorState, setEditorState] = useState('')
  const [imageUrl, setImageUrl] = useState()
  const [programDetail, setProgramDetail] = useState({})
  const [onCancel, setOnCancel] = useState(false)
  const [showPause, setShowPause] = useState(false)
  const [showPlay, setShowPlay] = useState(false)
  //   const [loading, setLoading] = useState(true)
  const [programOptions, setProgramOptions] = useState([])
  const [rank, setRank] = useState([])
  const [filter, setFilter] = useState()
  const [page, setPage] = useState(1)
  const [isLoading, setLoading] = useState(loading)
  const [clientTotals, setClientTotals] = useState({})
  const [queryParams, setQueryParams] = useState({
    search: '',
    sortBy: []
  })
  const [pagination, setPagination] = useState({
    pageIndex: page,
    pageSize: 10,
    totalRows: total
  })
  const [tasks, setTasks] = useState([])
  const [flatData, setFlatData] = useState([])
  const [constanceFlatData, setconstanceFlatData] = useState([])
  const [showImport, setShowImport] = useState(false)
  const [isLoadingImport, setLoadingImport] = useState(false)

  useEffect(() => {
    console.log('tasksUseEffect', tasks)
  }, [tasks])

  useEffect(() => {
    // Flatten the products and push them to an array
    const flatDatas = []
    const totals = {}
    let stt = 0
    contents?.forEach((clientData) => {
      let total = 0
      stt += 1
      clientData.products?.forEach((product) => {
        const flattenedProduct = {
          id: clientData?.id,
          clientType: clientData?.client,
          clientName: clientData?.shopName ? clientData?.shopName : clientData?.name,
          clientPhone: clientData?.phone,
          totalReward: clientData?.reward,
          reward: product?.reward,
          productId: product?.id,
          productType: product?.type,
          productModelId: product?.modelId,
          productName: product?.name,
          productSerial: product?.serial,
          productPrice: product?.price,
          productCreatedAt: product?.createdAt,
          productUserId: product?.userId,
          productFirstProductId: product?.firstProductId,
          product_model_id: product?.model?.id,
          product_model_code: product?.model?.code,
          product_model_name: product?.model?.name,
          uuid: product?.uuid,
          checked: checked,
          description: '',
          stt: stt,
          industrySectorName: product?.industrySector?.name,
          industrySectorId: product?.industrySector?.id,
          industrySectorCode: product?.industrySector?.code,
          industrySectorParentId: product?.industrySector?.parentId,
          categoryName: product?.category?.name,
          categoryId: product?.category?.id,
          categoryCode: product?.category?.code,
          categoryParentId: product?.category?.parentId
        }
        total += product?.price
        totals[clientData.id] = total
        flatDatas.push(flattenedProduct)
      })
    })
    setFlatData(flatDatas)
    setconstanceFlatData(flatDatas)

    console.log('flatDataUseEffect', flatData)
  }, [contents])

  const curProvince = province?.find((item) => item?.id == provinceId)

  const columns = useMemo(
    () => [
      {
        Header: 'STT',
        accessor: 'stt',
        filterable: true,
        disableSortBy: true,
        enableRowSpan: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <BaseString {...cellProps} />
        }
      },
      {
        Header: 'Loại TK',
        accessor: (cell) => {
          return `${cell.id}-${cell.clientType}`
        },
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        enableRowSpan: true,
        Cell: (cellProps) => {
          return <Type {...cellProps} />
        }
      },
      {
        Header: 'Tên cửa hàng/KTV',
        accessor: (cell) => {
          // console.log(cell)
          return `${cell.id}-${cell.clientName}`
        },
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        enableRowSpan: true,
        Cell: (cellProps) => {
          return <PhoneGrp {...cellProps} />
        }
      },
      {
        Header: 'SĐT',
        accessor: 'clientPhone',
        enableRowSpan: true,
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <Phone {...cellProps} />
        }
      },
      {
        Header: 'Tổng tiền',
        enableRowSpan: true,
        accessor: (cell) => {
          // console.log(cell)
          return `${cell.id}-${cell.totalReward}`
        },
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <TotalReward {...cellProps} />
        }
      },
      {
        Header: 'Serial',
        accessor: 'productSerial',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <Serial {...cellProps} />
        }
      },
      {
        Header: 'Model',
        accessor: 'product_model_name',
        // enableRowSpan: true,
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <Model {...cellProps} />
        }
      },
      {
        Header: 'Nhóm sản phẩm',
        // accessor: 'modelName',
        accessor: (cell) => {
          return `${cell.productSerial}-${cell.categoryName}`
        },
        // enableRowSpan: true,
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <GrString {...cellProps} />
        }
      },
      {
        Header: 'Ngành hàng',
        accessor: (cell) => {
          return `${cell.id}-${cell.industrySectorName}`
        },
        // enableRowSpan: true,
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <GrString {...cellProps} />
        }
      },
      {
        Header: 'Ngày quét',
        accessor: 'productCreatedAt',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <Date {...cellProps} />
        }
      },
      {
        Header: 'Tạm tính',
        accessor: 'reward',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <Money {...cellProps} />
        }
      },
      {
        Header: 'Trạng thái',
        accessor: 'status',
        disableFilters: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <Badge className='badge-soft-info'>{'Chờ đối soát'}</Badge>
        }
      },
      {
        Header: 'Từ chối',
        accessor: (cell) => {
          return `${cell.id},${cell.productId},${cell.uuid},${cell.checked}`
        },
        Cell: (cellProps) => {
          return (
            <Checkbox
              checked={JSON.parse(cellProps?.value?.split(',')[3])}
              onChange={() => handleUpdateData(cellProps.value, cellProps)}
            />
          )
        }
      },
      {
        Header: 'Lý do từ chối',
        accessor: (cell) => {
          return `${cell.id},${cell.productId},${cell.uuid},${cell.checked}`
        },
        Cell: (cellProps) => {
          return (
            <FormInput
              maxLength={50}
              // value={valueFilter || ''}
              value={descriptionState[cellProps.row.id] || ''}
              disabled={!JSON.parse(cellProps?.value?.split(',')[3])}
              onChange={(e) => {
                handleUpdateDescription(cellProps.value, e.target.value)
                HandleSetDescription(cellProps.row.id, e.target.value.trim())
              }}
              onBlur={(e) => {
                HandleSetDescription(cellProps.row.id, e.target.value.trim())
              }}
            />
          )
        }
      }
    ],
    [filter, page, contents, tasks, flatData, checked, descriptionState]
  )

  const HandleSetDescription = (cell, description) => {
    setDescriptionState({ ...descriptionState, [cell]: description })
  }

  useEffect(() => {
    if (id) {
      dispatch(getProgramByAreaAndRewardType({ location_id: provinceId, id: id }))
    }
  }, [dispatch])

  const setGlobalFilterUser = async (data) => {
    setFilter({ ...data, sort_field: '', sort_order: '' })
    dispatch(
      getProgramByAreaAndRewardType({
        ...data,
        location_id: provinceId,
        id: id,
        sort_field: '',
        sort_order: ''
      })
    )
    setPage(1)
  }

  const handleUpdateData = useCallback(
    (cellProp, rowdata) => {
      const cellPropvalue = cellProp?.split(',')

      //lấy giá trị hiện tại được check trong flatData
      const currentRow = flatData.filter(
        (item) =>
          item.uuid.includes(cellPropvalue[2]) &&
          item.id == Number(cellPropvalue[0]) &&
          item.productId == Number(cellPropvalue[1])
      )

      //update lại giá trị checked của flatData
      flatData.forEach((item) => {
        if (
          item.id == Number(cellPropvalue[0]) &&
          item.productId == Number(cellPropvalue[1]) &&
          item.uuid == cellPropvalue[2]
        ) {
          item.checked = !JSON.parse(currentRow[0]?.checked)
        }
      })
      //xoá hoặc thêm vào tasks
      if (JSON.parse(currentRow[0]?.checked) == true) {
        setTasks((prevTasks) => [
          ...prevTasks,
          {
            user_id: Number(cellPropvalue[0]),
            product_id: Number(cellPropvalue[1]),
            uuid: cellPropvalue[2],
            description: currentRow[0]?.description
          }
        ])
      } else {
        setTasks((prevTasks) => {
          return prevTasks.filter(
            (item) =>
              item.uuid !== cellPropvalue[2] ||
              item.user_id !== Number(cellPropvalue[0]) ||
              item.product_id !== Number(cellPropvalue[1])
          )
        })
      }
    },
    [tasks, flatData, checked]
  )

  const handleUpdateDescription = useCallback(
    (cellProp, data) => {
      const cellPropvalue = cellProp?.split(',')
      const currentRow = flatData.filter(
        (item) =>
          item.uuid.includes(cellPropvalue[2]) &&
          item.id == Number(cellPropvalue[0]) &&
          item.productId == Number(cellPropvalue[1])
      )

      //update lại giá trị description của flatData
      flatData?.forEach((item) => {
        if (
          item.id == Number(cellPropvalue[0]) &&
          item.productId == Number(cellPropvalue[1]) &&
          item.uuid == cellPropvalue[2]
        ) {
          item.description = data
        }
      })

      const existedTask = tasks.filter(
        (item) =>
          item.user_id == Number(cellPropvalue[0]) &&
          item.product_id == Number(cellPropvalue[1]) &&
          item.uuid == cellPropvalue[2]
      )
      if (existedTask.length > 0) {
        existedTask.forEach((item) => {
          item.description = data
        })
      }
      console.log('flatData_handleUpdateDescription', flatData)
      console.log('tasks_handleUpdateDescription', tasks)
    },
    [tasks, flatData]
  )

  const handleConfirm = async () => {
    try {
      const res = await confirmReconcileByProductAPI({ id: id, province_id: provinceId, tasks: tasks })

      if (res) {
        toast('Xác nhận đối soát thành công', { type: 'success' })
      }
    } catch (error) {
      toast(error.response.data.message, { type: 'error' })
    }
  }

  const handleFilter = (filterValue) => {
    // setFilter({ ...filterValue, sort_field: '', sort_order: '' })
    const keyWord = filterValue?.search?.toLowerCase() || ''
    const phone = filterValue?.phone?.toLowerCase() || ''
    const serial = filterValue?.serial?.toLowerCase() || ''

    if (filterValue.search.length > 0) {
      const filteredResults = constanceFlatData.filter(
        (obj) =>
          obj.clientName.toLowerCase().includes(keyWord) &&
          obj.clientPhone.toLowerCase().includes(phone) &&
          obj.productSerial.toLowerCase().includes(serial)
      )

      setFlatData(filteredResults)
    } else {
      dispatch(
        getProgramByAreaAndRewardType({
          ...filter,
          meta: JSON.stringify({
            'Nhập tên tài khoản': filterValue?.search,
            'Nhập số điện thoại': filterValue?.phone,
            'Nhập Serial': filterValue?.serial
          }),
          location_id: provinceId,
          id: id,
          sort_field: '',
          sort_order: ''
        })
      )
    }
    setPage(1)
  }

  const handleSort = (sortBy) => {
    if (sortBy.length > 0) {
      setFilter({ ...filter, sort_field: sortBy[0].id, sort_order: sortBy[0].desc ? 'desc' : 'asc' })
      dispatch(
        getProgramByAreaAndRewardType({
          ...filter,
          location_id: provinceId,
          id: id,
          sort_field: sortBy[0].id,
          sort_order: sortBy[0].desc ? 'desc' : 'asc'
        })
      )
    } else {
      setFilter({ ...filter, sort_field: '', sort_order: '' })
      dispatch(
        getProgramByAreaAndRewardType({
          ...filter,
          location_id: provinceId,
          id: id,
          sort_field: '',
          sort_order: ''
        })
      )
    }
  }

  const goToPage = (page) => {
    // setPage(page)
    // handleGetListTable({ ...filter, page })
    // setFilter({ ...filter, page })
    setFilter({ ...filter, page: page })
    setPage(page)
    dispatch(
      getProgramByAreaAndRewardType({ ...filter, location_id: provinceId, id: id, viewonly: 'true', page: page })
    )
  }

  const handleResetFilter = () => {
    dispatch(
      getProgramByAreaAndRewardType({
        ...filter,
        location_id: provinceId,
        id: id,
        sort_field: '',
        sort_order: ''
      })
    )
    setPage(1)
  }

  const handleClickExport = async (filterValue) => {
    setLoading(true)
    try {
      const res = await exportProgrammingByAreaAndRewardTypeAPI({
        search: filterValue,
        location_id: provinceId,
        id: id
      })
      if (res) {
        if (res?.data?.success === false) {
          toast(res?.message, { type: 'error' })
        } else {
          toast('Hệ thống đang xử lý. Tệp của bạn đã được gửi tới email ' + profile?.email + ' trong vài phút', {
            type: 'success'
          })
        }
      }
    } catch (error) {
      toast(error, { type: 'error' })
    }
    setLoading(false)
  }

  // const handleCheckExport = async (data) => {
  //   try {
  //     const res = await checkExportFileAPI(data)

  //     if (res?.data?.status === 'pending') {
  //       setTimeout(() => {
  //         handleCheckExport(data)
  //       }, 2000)
  //     } else {
  //       const nameFile = `${moment().format('yyMMDD')}_Doi soat theo san pham.xlsx`
  //       const link = document.createElement('a')
  //       link.href = res?.data?.fileName
  //       link.setAttribute('download', nameFile)
  //       document.body.appendChild(link)
  //       link.click()
  //       toast('Export file thành công!', { type: 'success' })
  //     }
  //   } catch (error) {
  //     toast(error.response.data.message, { type: 'error' })
  //     setLoadingImport(false)
  //   }
  // }

  const handleImport = async () => {
    setShowImport(true)
  }

  const uploadFile = async (files) => {
    setLoadingImport(true)
    let formData = new FormData()
    let type = ''
    if (value?.configType === 'product') {
      type = 'reconcile_by_product'
    } else if (value?.configType === 'revenue') {
      type = 'reconcile_by_revenue'
    }
    formData.append('file', files[0])
    formData.append('location_id', provinceId)
    formData.append('id', id)
    formData.append('type', type)

    try {
      const res = await importFileAPI(url.IMPORT_RECONCILE, formData)
      if (res) {
        handleCheckUpload(res.data.data)
      }
    } catch (error) {
      toast(error.response.data.message, { type: 'error' })
      setLoadingImport(false)
    }
  }

  const handleCheckUpload = async (data) => {
    try {
      const res = await checkUploadFileAPI(data)

      if (!res.data.queueFinish) {
        setTimeout(() => {
          handleCheckUpload(data)
        }, 2000)
      } else {
        if (res.data.countFailed > 0) {
          // handleExportFileError(data)
          setShowImport(false)
          setLoadingImport(false)
        }
        toast('Hệ thống đang xử lý . Vui lòng kiểm tra thông tin tại màn hình Lịch sử', { type: 'success' })
        dispatch(
          getProgramByAreaAndRewardType({
            ...filter,
            location_id: provinceId,
            id: id,
            sort_field: '',
            sort_order: ''
          })
        )
        setPage(1)
        setLoadingImport(false)
        setShowImport(false)
      }
    } catch (error) {
      toast(error.response.data.message, { type: 'error' })
      setLoadingImport(false)
    }
  }

  const handleExportFileError = async (data) => {
    try {
      const res = await exportFileError(data)

      if (res) {
        const nameFile = `${moment().format('yyMMDD')}_FileError.xlsx`
        const url = window.URL.createObjectURL(new Blob([res]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', nameFile) //or any other extension
        document.body.appendChild(link)
        link.click()
      }
    } catch (error) {
      toast(error.response.data.message, { type: 'error' })
      setShowImport(false)
      setLoadingImport(false)
    }
  }

  const handleClickDownloadTemplate = async () => {
    try {
      let type = ''
      if (value?.configType === 'product') {
        type = 'reconcile_by_product'
      } else if (value?.configType === 'revenue') {
        type = 'reconcile_by_revenue'
      }
      const res = await downloadTemplateReportAPI({ location_id: provinceId, id: id, type: type })
      const nameFile = `Bieu_mau_import_Doi_soat_thuong.xlsx`
      const url = window.URL.createObjectURL(new Blob([res]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', nameFile) //or any other extension
      document.body.appendChild(link)
      link.click()
      toast('Tải biểu mẫu thành công!', { type: 'success' })
    } catch (error) {
      toast(error.response.data.message, { type: 'error' })
    }
  }

  return (
    <>
      <div className='page-content'>
        <div className='page-title-box container-fluid'>
          <Breadcrumb title={'Đối soát thưởng'} breadcrumbItem={'Đối soát thưởng'} />
          {isLoading ? (
            <Spinners setLoading={setLoading} />
          ) : (
            <Row>
              <Col lg='12'>
                <Card>
                  <CardBody>
                    <Space size='middle' direction='vertical' className='w-100'>
                      <ProgramByAreaAndRewardTypeFilter
                        handleFilter={handleFilter}
                        handleConfirm={handleConfirm}
                        handleResetFilter={handleResetFilter}
                        handleExport={handleClickExport}
                        handleImport={handleImport}
                      />
                      <div className='d-flex flex-row '>
                        <div className='mb-xxl-0 me-3 mt-2'>
                          <h5 className='mb-0 card-title flex-grow-1'>{'Danh sách báo cáo'}</h5>
                        </div>
                        <div className='mb-xxl-0'>
                          <button className='btn btn-info btn-rounded me-2'>{value?.code}</button>
                        </div>
                      </div>
                      <TableRowSpan
                        columns={columns}
                        data={flatData}
                        pagination={pagination}
                        setPagination={setPagination}
                        gotoPageCustom={goToPage}
                        currentPage={page}
                        total={total}
                        isShowTotal={true}
                      />
                    </Space>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </div>
      </div>
      {showImport && (
        <ImportModal
          onCloseClick={() => {
            setShowImport(false)
          }}
          show={showImport}
          onConfirmClick={uploadFile}
          loading={isLoadingImport}
          title={'Import đối soát'}
          onDownloadClick={handleClickDownloadTemplate}
        />
      )}
    </>
  )
}

export default withRouter(ProgramByAreaAndRewardType)
