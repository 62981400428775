import PropsType from 'prop-types'
import { useMemo, useState } from 'react'
import { Label, UncontrolledTooltip } from 'reactstrap'
import React from 'react'
import { Row, Col, Space, Flex } from 'antd'

//flatpickr
import { CommonVN, ProductVN } from 'constants/vn'

import { DatePicker } from 'antd'
import { FormInput } from 'components/Common/form-input/FormInput'
import { FormSelect } from 'components/Common/form-select/FormSelect'
import { MAX_LENGTH_SEARCH, TypeProgramOptions } from 'constants/constants'
import { createSelector } from 'reselect'
import { useSelector } from 'react-redux'
import { myLocationManagementAPI } from 'helpers/backend_helper'

const { RangePicker } = DatePicker

ReconcileProgramByAreaFilter.propTypes = {
  handleFilter: PropsType.func
}

function ReconcileProgramByAreaFilter({ handleFilter }) {
  const selectLayoutState = (state) => state.Layout
  const LayoutProperties = createSelector(selectLayoutState, (app) => ({
    zone: app.zone,
    subzone: app.subzone,
    province: app.province,
    district: app.district,
    ward: app.ward,
    bank: app.bank,
    myLocationManagement: app.myLocationManagement
  }))
  const { zone, subzone, province, district, myLocationManagement } = useSelector(LayoutProperties)
  const [filter, setFilter] = useState({})

  const onFilterChange = (name, value) =>
    setFilter((prev) => ({
      ...prev,
      [name]: value
    }))

  const ColProps = useMemo(() => ({
    xs: { span: 24 },
    xxl: { span: 8 }
  }))

  const onResetFilter = () =>
    setFilter({
      zone_ids: undefined,
      sub_zone_ids: undefined,
      province_ids: undefined,
      district_ids: undefined,
      ward_ids: undefined,
      location_ids: undefined
    })

  ////Lấy danh sách district
  // Get all unique parentId values from myLocationManagement
  // const myLocationManagementParentIds = myLocationManagement?.map((ward) => ward.parentId)
  //Từ myLocationManagementParentIds, lấy ra những district có id trong myLocationManagementParentIds
  const lstDistrict = myLocationManagement

  ////Lấy danh sách province
  // Get all unique parentId values from lstDistrict
  const districtParentIds = lstDistrict?.map((district) => district.parentId)
  //Từ districtParentIds, lấy ra những district có id trong districtParentIds
  const lstProvionce = province.filter((province) => districtParentIds.includes(province.id))

  ////Lấy danh sách subZone
  // Get all unique subZoneId(parentId) values from lstProvionce
  const provinceParentIds = lstProvionce?.map((province) => province.subZoneId)
  //Từ provinceParentIds, lấy ra những subZone có id trong provinceParentIds
  const lstSubZone = subzone?.filter((subZone) => provinceParentIds.includes(subZone.id))

  ////Lấy danh sách zone
  // Get all unique zoneId(parentId) values from lstProvionce
  const subZoneParentIds = lstProvionce?.map((province) => province.zoneId) // trong lstProvionce có lưu zoneId
  //Từ provinceParentIds, lấy ra những subZone có id trong provinceParentIds
  const lstZone = zone.filter((zone) => subZoneParentIds.includes(zone.id))

  return (
    <Row gutter={[10, 10]} align='bottom'>
      <Col {...ColProps} className='w-100'>
        <Label className='form-label'>{'Miền'}</Label>
        <FormSelect
          options={[{ label: 'Tất cả', value: 'all' }].concat(
            lstZone?.map((item) => ({
              value: item.id,
              label: item.name
            }))
          )}
          value={filter.zone_ids}
          onChange={(value) => {
            onFilterChange('zone_ids', value)
            onFilterChange('sub_zone_ids', [])
            onFilterChange('province_ids', [])
            onFilterChange('district_ids', [])
            onFilterChange('ward_ids', [])
          }}
          onSelect={(value) => {
            const all = lstZone?.map((item) => item.id)
            if (value && value === 'all') {
              return onFilterChange('zone_ids', [...all])
            }
          }}
          placeholder={ProductVN.filter.zone.placeholder}
          mode='multiple'
        />
      </Col>
      <Col {...ColProps} className='w-100'>
        <Label className='form-label'>{'Vùng'}</Label>
        <FormSelect
          options={
            lstSubZone?.filter((e) => filter.zone_ids?.includes(e.zoneId))?.length > 0
              ? [{ label: 'Tất cả', value: 'all' }].concat(
                  lstSubZone
                    ?.filter((e) => filter.zone_ids?.includes(e.zoneId))
                    ?.map((item) => ({
                      value: item.id,
                      label: item.name
                    }))
                )
              : []
          }
          value={filter.sub_zone_ids}
          onChange={(value) => {
            onFilterChange('sub_zone_ids', value)
            onFilterChange('province_ids', [])
            onFilterChange('district_ids', [])
            onFilterChange('ward_ids', [])
          }}
          onSelect={(value) => {
            const all = lstSubZone?.filter((e) => filter.zone_ids?.includes(e.zoneId))?.map((item) => item.id)
            if (value && value === 'all') {
              return onFilterChange('sub_zone_ids', [...all])
            }
          }}
          placeholder={ProductVN.filter.zone.placeholder}
          mode='multiple'
        />
      </Col>

      <Col {...ColProps} className='w-100'>
        <Label className='form-label'>{'Tỉnh/ Thành phố'}</Label>
        <FormSelect
          options={
            lstProvionce?.filter((e) => filter.sub_zone_ids?.includes(e.subZoneId))?.length > 0
              ? [{ label: 'Tất cả', value: 'all' }].concat(
                  lstProvionce
                    ?.filter((e) => filter.sub_zone_ids?.includes(e.subZoneId))
                    ?.map((item) => ({
                      value: item.id,
                      label: item.name
                    }))
                )
              : []
          }
          value={filter.province_ids}
          onChange={(value) => {
            onFilterChange('province_ids', value)
            onFilterChange('district_ids', [])
            onFilterChange('ward_ids', [])
          }}
          onSelect={(value) => {
            const all = lstProvionce?.filter((e) => filter.sub_zone_ids?.includes(e.subZoneId))?.map((item) => item.id)
            if (value && value === 'all') {
              return onFilterChange('province_ids', [...all])
            }
          }}
          placeholder='Tất cả'
          mode='multiple'
        />
      </Col>
      <Col {...ColProps} className='w-100'>
        <Label className='form-label'>{'Quận/ Huyện'}</Label>
        <FormSelect
          options={
            lstDistrict?.filter((e) => filter.province_ids?.includes(e.parentId))?.length > 0
              ? [{ label: 'Tất cả', value: 'all' }].concat(
                  lstDistrict
                    ?.filter((e) => filter.province_ids?.includes(e.parentId))
                    ?.map((item) => ({
                      value: item.id,
                      label: item.name
                    }))
                )
              : []
          }
          value={filter.district_ids}
          onChange={(value) => {
            onFilterChange('district_ids', value)
            onFilterChange('ward_ids', [])
          }}
          onSelect={(value) => {
            const all = lstDistrict?.filter((e) => filter.province_ids?.includes(e.parentId))?.map((item) => item.id)
            if (value && value === 'all') {
              return onFilterChange('district_ids', [...all])
            }
          }}
          placeholder='Tất cả'
          mode='multiple'
        />
      </Col>
      {/* <Col {...ColProps}>
        <Label className='form-label'>{'Phường xã'}</Label>
        <FormSelect
          options={myLocationManagement?.map((item) => ({
            value: item.id,
            label: item.name
          }))}
          value={filter.location_id}
          onChange={(value) => onFilterChange('location_id', value)}
          placeholder={`Chọn tỉnh thành phố`}
        />
      </Col> */}
      <Col {...ColProps}>
        <Space>
          <button type='button' className='btn btn-soft-danger' onClick={onResetFilter}>
            Xóa tìm kiếm
          </button>

          <button type='button' className='btn btn-primary' onClick={() => handleFilter(filter)}>
            Tìm kiếm
          </button>

          {/* <button className='btn btn-primary me-2 pe-4'>
            <i className='mdi mdi-database-export me-1 ms-2' />
            Export
          </button> */}
        </Space>
      </Col>
      <Col flex='1'>
        <Flex gap='small' justify='flex-end'>
          {/* <button className='btn btn-primary me-2 pe-4'>
            <i className='mdi mdi-database-import me-1 ms-2' />
            Import
          </button> */}
          {/* <button className='btn btn-primary me-2 pe-4'>
            <i className='mdi mdi-database-export me-1 ms-2' />
            Export
          </button> */}
          {/* <button type='button' className='button-primary'>
            <i className='mdi mdi-content-save-outline' />
            Xác nhận đối soát
          </button> */}
        </Flex>
      </Col>
    </Row>
  )
}

export default ReconcileProgramByAreaFilter
