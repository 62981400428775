import { call, put, takeEvery } from 'redux-saga/effects'

import {
  GET_PROGRAM_BONUS_TEMP,
  GET_PROGRAM_BY_AREA_AND_REWARD_TYPE,
  GET_RECONCILE_PROGRAM_BY_AREA
} from './actionTypes'
import {
  getProgramBonusTempSuccess,
  getProgramBonusTempFail,
  getReconcileProgramByAreaSuccess,
  getReconcileProgramByAreaFail,
  getProgramByAreaAndRewardTypeSuccess,
  getProgramByAreaAndRewardTypeFail
} from './actions'

import {
  getProgramBonusTempListAPI,
  getProgramByAreaAndRewardTypeAPI,
  getReconcileProgramByAreaAPI
} from 'helpers/backend_helper'

function* fetchProgramBonusTempList({ payload: params }) {
  try {
    const response = yield call(getProgramBonusTempListAPI, { ...params })
    yield put(getProgramBonusTempSuccess(response))
  } catch (error) {
    yield put(getProgramBonusTempFail(error?.data?.message))
  }
}

function* fetchReconcileProgramByArea({ payload: params }) {
  try {
    const response = yield call(getReconcileProgramByAreaAPI, { ...params })
    yield put(getReconcileProgramByAreaSuccess(response))
  } catch (error) {
    // console.log(error)
    yield put(getReconcileProgramByAreaFail(error?.data?.message))
  }
}

function* fetchProgramByAreaAndRewardType({ payload: params }) {
  try {
    const response = yield call(getProgramByAreaAndRewardTypeAPI, { ...params })
    yield put(getProgramByAreaAndRewardTypeSuccess(response))
  } catch (error) {
    // console.log(error)
    yield put(getProgramByAreaAndRewardTypeFail(error?.data?.message))
  }
}

function* ProgramBonusTempsSaga() {
  yield takeEvery(GET_PROGRAM_BONUS_TEMP, fetchProgramBonusTempList)
  yield takeEvery(GET_RECONCILE_PROGRAM_BY_AREA, fetchReconcileProgramByArea)
  yield takeEvery(GET_PROGRAM_BY_AREA_AND_REWARD_TYPE, fetchProgramByAreaAndRewardType)
}

export default ProgramBonusTempsSaga
