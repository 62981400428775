import React, { useState } from 'react'
import { Col, Label } from 'reactstrap'

//flatpickr
import 'flatpickr/dist/themes/material_blue.css'
import { CommonVN, ManageShopVN } from 'constants/vn'
import { createSelector } from 'reselect'
import { useSelector } from 'react-redux'
import { FormSelect } from './form-select/FormSelect'
import { DatePicker } from 'antd'
import { MAX_LENGTH_SEARCH } from 'constants/constants'
import { FormInput } from './form-input/FormInput'
const { RangePicker } = DatePicker

const ShopListGlobalFilter = ({ setGlobalFilter, search, setSearch, isKTV }) => {
  const selectLayoutState = (state) => state.Layout
  const LayoutProperties = createSelector(selectLayoutState, (app) => ({
    province: app.province,
    district: app.district,
    ward: app.ward
  }))
  const { province } = useSelector(LayoutProperties)

  const [filter, setFilter] = useState({})

  const handleSelect = (value, key) => {
    setFilter((e) => ({ ...e, [key]: value }))
  }

  return (
    <React.Fragment>
      {/* <Col xxl={2} lg={2} className='mt-3'>
        <Label className='form-label'>Nhập tên</Label>
        <FormInput
          className='w-100'
          maxLength={MAX_LENGTH_SEARCH}
          placeholder='Nhập tên '
          value={filter?.search || ''}
          name='search'
          id={`input-filter`}
          onChange={(e) => handleSelect(e.target.value, 'search')}
          onBlur={(e) => handleSelect(e.target.value, 'search')}
        />
      </Col> */}

      <Col xxl={2} lg={2} className='mt-3'>
        <Label className='form-label'>Nhập số điện thoại</Label>
        <FormInput
          className='w-100'
          maxLength={MAX_LENGTH_SEARCH}
          placeholder='Nhập SĐT '
          value={filter?.phone || ''}
          name='phone'
          id={`input-filter`}
          onChange={(e) => handleSelect(e.target.value, 'phone')}
          onBlur={(e) => handleSelect(e.target.value, 'phone')}
        />
      </Col>
      <Col xxl={2} lg={2} className='mt-3'>
        <Label className='form-label'>{'Trạng thái'}</Label>
        <FormSelect
          options={ManageShopVN.statusSMSBrandnameOptions?.map((item) => ({
            value: item.value,
            label: item.label
          }))}
          value={filter.state}
          onChange={(value) => {
            handleSelect(value, 'state')
          }}
          placeholder='Tất cả'
        />
      </Col>
      <Col xxl={2} lg={2} className='mt-3'>
        <Label className='form-label'>{'Loại SMS'}</Label>
        <FormSelect
          options={ManageShopVN.typeSMSBrandnameOptions?.map((item) => ({
            value: item.value,
            label: item.label
          }))}
          value={filter.type}
          onChange={(value) => {
            handleSelect(value, 'type')
          }}
          placeholder='Tất cả'
        />
      </Col>
      <Col xxl={3} className='mt-3' lg={5}>
        <Label className='form-label'>{'Thời gian'}</Label>
        <RangePicker
          className='w-100'
          size='large'
          format={CommonVN.datePlaceholder.toUpperCase()}
          placeholder={[CommonVN.datePlaceholder, CommonVN.datePlaceholder]}
          value={[filter.start_date, filter.end_date]}
          onChange={(value) => {
            if (value) {
              handleSelect(value[0], 'start_date')
              handleSelect(value[1], 'end_date')
            } else {
              handleSelect(undefined, 'start_date')
              handleSelect(undefined, 'end_date')
            }
          }}
        />
      </Col>

      <Col xxl={3} lg={6} className='d-flex align-items-end justify-content mt-3 '>
        <div className='mb-xxl-0 me-2'>
          <button
            type='button'
            className='btn btn-soft-danger w-100'
            onClick={() => {
              setFilter({
                state: undefined,
                type: undefined,
                start_date: undefined,
                end_date: undefined,
                search: '',
                phone: ''
              })
              setSearch('')
              setGlobalFilter({})
            }}
          >
            Xóa tìm kiếm
          </button>
        </div>
        <div className='mb-xxl-0'>
          <button
            type='button'
            className='btn btn-primary w-100'
            onClick={() => {
              const _filter = !filter
                ? filter
                : Object.fromEntries(Object.entries(filter).filter(([key, value]) => value !== undefined))
              setGlobalFilter({ ..._filter, search })
            }}
          >
            Tìm kiếm
          </button>
        </div>
      </Col>
    </React.Fragment>
  )
}
export default ShopListGlobalFilter
