import React, { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'

//redux
import { useSelector, useDispatch } from 'react-redux'
import { createSelector } from 'reselect'

import { Col, Row, UncontrolledTooltip, Card, CardBody } from 'reactstrap'
import Spinners from 'components/Common/Spinner'
import { toast } from 'react-toastify'
import TableContainer from 'components/Common/TableContainer'
import { GuestVN } from 'constants/vn'
import ConfirmModal from 'components/Common/ConfirmModal'
import { deleteGuestAPI, exportGuestAPI } from 'helpers/backend_helper'
import { getGuestDetail, getGuestList, setFilter, setTypePopupGuest } from 'store/actions'
import moment from 'moment'
import AddEditGuest from './components/AddEditGuest'
import PermissionWrapper from 'components/Common/PermissionWrapper'
import { CUSTOMER_MANAGEMENT_CODE, SCREEN_CODE } from 'constants/permission-code'
import ManageGuestFilter from './components/ManageGuestFilter'
import { TableRowSpan } from 'components/Common/TableCustom'
import { Space } from 'antd'

function ManageGuest() {
  //meta title
  document.title = 'Manage Guest List | HPG'

  const dispatch = useDispatch()
  const screenCode = useMemo(() => SCREEN_CODE.CUSTOMER_MANAGEMENT, [])

  const selectGuestsState = (state) => state.GuestReducer
  const GuestsProperties = createSelector(selectGuestsState, (reducer) => ({
    guests: reducer.guests,
    loading: reducer.loading,
    total: reducer.total
  }))

  const { guests, loading, total } = useSelector(GuestsProperties)

  const selectLayoutState = (state) => state.Layout
  const LayoutProperties = createSelector(selectLayoutState, (layout) => ({
    filter: layout.filter
  }))
  const { filter } = useSelector(LayoutProperties)

  const [isLoading, setLoading] = useState(loading)
  const [showDeleteAccount, setShowDeleteAccount] = useState(false)
  const [selected, setSelected] = useState()
  const [showForm, setShowForm] = useState(false)
  const [page, setPage] = useState(1)
  const [pagination, setPagination] = useState({
    pageIndex: page,
    pageSize: 10,
    totalRows: total
  })

  useEffect(() => {
    dispatch(setFilter({ page: 1 }))
    dispatch(getGuestList({ page: 1 }))
  }, [dispatch])

  const onClickDelete = (item) => {
    setSelected(item)
    setShowDeleteAccount(true)
  }

  const onClickItem = async (item, type) => {
    setSelected(item)
    dispatch(setTypePopupGuest(type))
    dispatch(getGuestDetail(item))
    setShowForm(true)
  }

  const columns = useMemo(
    () => [
      {
        Header: GuestVN.table.stt,
        accessor: 'stt',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return (page - 1) * 10 + cellProps.row.index + 1
        }
      },
      {
        Header: GuestVN.table.name,
        accessor: 'name',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return cellProps.value ? cellProps.value : '--'
        }
      },
      {
        Header: GuestVN.table.phone,
        accessor: 'phone',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return cellProps.value ? cellProps.value : '--'
        }
      },
      {
        Header: GuestVN.table.city,
        accessor: 'province',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return cellProps.value ? cellProps.value.name : '--'
        }
      },
      {
        Header: GuestVN.table.district,
        accessor: 'district',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return cellProps.value ? cellProps.value.name : '--'
        }
      },

      {
        Header: GuestVN.table.ward,
        accessor: 'ward',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return cellProps.value ? cellProps.value.name : '--'
        }
      },

      {
        Header: GuestVN.table.address,
        accessor: 'address',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return cellProps.value ? cellProps.value : '--'
        }
      },
      {
        Header: GuestVN.table.action,
        accessor: 'action',
        disableFilters: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return (
            <ul className='list-unstyled hstack gap-1 mb-0'>
              <PermissionWrapper
                screenCode={screenCode}
                code={[CUSTOMER_MANAGEMENT_CODE.ADMIN_DETAIL_CUSTOMER, CUSTOMER_MANAGEMENT_CODE.ADMIN_UPDATE_CUSTOMER]}
              >
                <li data-bs-toggle='tooltip' data-bs-placement='top' title='View'>
                  <Link
                    to={`#`}
                    className='btn btn-sm btn-soft-info'
                    id={`edittooltip-${cellProps.row.original.id}`}
                    onClick={() => {
                      onClickItem(cellProps.row.original, 'edit')
                    }}
                  >
                    <i className='mdi mdi-pencil-outline' />
                    <UncontrolledTooltip
                      trigger='hover'
                      placement='top'
                      target={`edittooltip-${cellProps.row.original.id}`}
                    >
                      Sửa
                    </UncontrolledTooltip>
                  </Link>
                </li>
              </PermissionWrapper>

              <PermissionWrapper screenCode={screenCode} code={CUSTOMER_MANAGEMENT_CODE.ADMIN_DELETE_CUSTOMER}>
                <li>
                  <Link
                    to='#'
                    className='btn btn-sm btn-soft-danger'
                    onClick={() => {
                      onClickDelete(cellProps.row.original)
                    }}
                    id={`deletetooltip-${cellProps.row.original.id}`}
                  >
                    <i className='mdi mdi-delete-outline' />
                    <UncontrolledTooltip
                      trigger='hover'
                      placement='top'
                      target={`deletetooltip-${cellProps.row.original.id}`}
                    >
                      Xóa
                    </UncontrolledTooltip>
                  </Link>
                </li>
              </PermissionWrapper>
            </ul>
          )
        }
      }
    ],
    [filter, page]
  )

  const setGlobalFilterUser = async (data) => {
    dispatch(
      getGuestList({
        ...data,
        meta: {
          'Nhập tên': data?.search,
          'Nhập số điện thoại': data?.phone,
          'Tỉnh/ Thành phố': data?.province_names,
          'Quận/ Huyện': data?.district_names,
          'Phường xã': data?.ward_names
        }
      })
    )

    dispatch(setFilter({ ...data, page: 1 }))
  }

  const goToPage = (page) => {
    dispatch(getGuestList({ ...filter, page }))
    setPage(page)
  }

  const onDeleteAccount = async () => {
    try {
      const res = await deleteGuestAPI({
        id: selected.id
      })
      if (res) {
        toast('Xóa thành công thông tin người tiêu dùng.', { type: 'success' })
        setShowDeleteAccount(false)
        dispatch(getGuestList({ ...filter }))
      }
    } catch (error) {
      console.log(error)
      toast(error.response.data.message, { type: 'error' })
    }
  }

  const handleClickExport = async () => {
    setLoading(true)
    try {
      const res = await exportGuestAPI(filter)
      if (res) {
        const nameFile = `${moment().format('dd/mm/yyyy')}_QuanLyNguoiTieuDung.xlsx`
        const url = window.URL.createObjectURL(new Blob([res]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', nameFile) //or any other extension
        document.body.appendChild(link)
        link.click()
        toast('Export file thành công!', { type: 'success' })
      }
    } catch (error) {
      toast(error.response.data.message, { type: 'error' })
    }
    setLoading(false)
  }

  const handleResetFilter = () => {
    dispatch(setFilter({ page: 1 }))
    dispatch(getGuestList({ page: 1 }))
  }

  return (
    <React.Fragment>
      <div className='page-content'>
        <div className='page-title-box container-fluid'>
          <h4 className=' mb-sm-0 font-size-18 pb-4'>{GuestVN.title}</h4>
          {isLoading ? (
            <Spinners setLoading={setLoading} />
          ) : (
            <Row>
              <Col lg='12'>
                <Card>
                  <CardBody className='border-bottom'>
                    <div className='d-flex align-items-center'>
                      <h5 className='mb-0 card-title flex-grow-1'>{GuestVN.list}</h5>
                      <div className='flex-shrink-0'>
                        <PermissionWrapper
                          screenCode={screenCode}
                          code={CUSTOMER_MANAGEMENT_CODE.ADMIN_EXPORT_CUSTOMER}
                        >
                          <button onClick={() => handleClickExport()} className='btn btn-primary me-2 pe-4'>
                            <i className='mdi mdi-database-export me-1 ms-2' />
                            {GuestVN.export}
                          </button>
                        </PermissionWrapper>
                      </div>
                    </div>
                  </CardBody>
                  <CardBody>
                    <Space size='middle' direction='vertical' className='w-100'>
                      <ManageGuestFilter handleFilter={setGlobalFilterUser} handleResetFilter={handleResetFilter} />
                      <TableRowSpan
                        columns={columns}
                        data={guests}
                        pagination={pagination}
                        setPagination={setPagination}
                        gotoPageCustom={goToPage}
                        currentPage={page}
                        total={total}
                        isShowTotal={true}
                      />
                    </Space>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </div>
      </div>
      <ConfirmModal
        show={showDeleteAccount}
        icon={
          <div className='avatar-title rounded-circle bg-danger text-light bg-opacity-60 font-size-20 rounded-3'>
            <i className='mdi mdi-delete-alert'></i>
          </div>
        }
        onConfirmClick={onDeleteAccount}
        onCloseClick={() => setShowDeleteAccount(false)}
        title='Xoá thông tin người tiêu dùng'
        description={`Bạn có chắc chắn muốn xoá người tiêu dùng?`}
      />
      <AddEditGuest
        onCloseClick={() => {
          setShowForm(false)
        }}
        show={showForm}
      />
    </React.Fragment>
  )
}

export default ManageGuest
