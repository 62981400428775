import React, { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { getProgramBonusTempList as onGetManageContent } from 'store/actions'

import { Status, Type, ContentName, DisplayDate, ReportCode, TotalUser, AvgTotal } from './ManageContentCol'
import { useSelector, useDispatch } from 'react-redux'
import { createSelector } from 'reselect'
import { Col, Row, UncontrolledTooltip, Card, CardBody } from 'reactstrap'
import Spinners from 'components/Common/Spinner'
import { toast } from 'react-toastify'
import TableContainer from 'components/Common/TableContainer'
import ConfirmModal from 'components/Common/ConfirmModal'
import {
  checkExportFileAPI,
  deleteContentAPI,
  exportByProgrammingAPI,
  patchStatusContentAPI
} from 'helpers/backend_helper'
import fileDownload from 'js-file-download'
import moment from 'moment'
import { getStorage, setStorage } from 'helpers/global_helper'

function ProgramBonusTempList() {
  //meta title
  document.title = 'Content List | HPG'

  const dispatch = useDispatch()
  const selectContentsState = (state) => state.ProgramBonusTempReducer
  const ContentsProperties = createSelector(selectContentsState, (contentReducer) => ({
    contents: contentReducer.contents,
    loading: contentReducer.loading,
    total: contentReducer.total
  }))

  const { contents, loading, total } = useSelector(ContentsProperties)

  const selectProfileState = (state) => state.Profile
  const ProfileProperties = createSelector(selectProfileState, (profile) => ({
    profile: profile.profile
  }))
  const { profile } = useSelector(ProfileProperties)

  const [isLoading, setLoading] = useState(loading)
  const [showDelete, setShowDelete] = useState(false)
  const [showPause, setShowPause] = useState(false)
  const [showPlay, setShowPlay] = useState(false)
  const [selected, setSelected] = useState()
  const [filter, setFilter] = useState()
  const [page, setPage] = useState(1)

  useEffect(() => {
    const filterProgramBonusTempList = getStorage('filterProgramBonusTempList')

    dispatch(onGetManageContent({ ...filterProgramBonusTempList, page: 1 }))
  }, [dispatch])

  const columns = useMemo(
    () => [
      {
        Header: 'Mã CT',
        accessor: 'code',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <ReportCode {...cellProps} />
        }
      },
      {
        Header: 'Loại chương trình',
        accessor: 'type',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <Type {...cellProps} />
        }
      },
      {
        Header: 'Tên chương trình',
        accessor: 'name',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <ContentName {...cellProps} />
        }
      },
      {
        Header: 'Thời gian áp dụng',
        accessor: 'startDate',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <DisplayDate {...cellProps} />
        }
      },
      {
        Header: 'Tài khoản tham gia',
        accessor: 'totalUser',
        disableFilters: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <TotalUser {...cellProps} />
        }
      },
      {
        Header: 'Mã đối soát/ Tổng số mã',
        accessor: 'totalCompleted',
        disableFilters: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <AvgTotal {...cellProps} />
        }
      },
      {
        Header: 'Trạng thái',
        accessor: 'status',
        disableFilters: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <Status {...cellProps} />
        }
      },

      {
        Header: 'Thao tác',
        accessor: 'action',
        disableFilters: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          // console.log(cellProps)
          return (
            <ul className='list-unstyled hstack gap-1 mb-0'>
              <li data-bs-toggle='tooltip' data-bs-placement='top' title='View'>
                <Link
                  // to={`/reconcile-programming-by-area/${cellProps.row.original.id}`}
                  to={`/reconcile-programming-by-area/id=${cellProps.row.original.id}&type=${cellProps.row.original.type}`}
                  className='btn btn-sm btn-soft-primary'
                  id={`viewtooltip-${cellProps.row.original.id}`}
                >
                  <i className='mdi mdi-eye-outline' />
                  <UncontrolledTooltip placement='top' target={`viewtooltip-${cellProps.row.original.id}`}>
                    Xem
                  </UncontrolledTooltip>
                </Link>
              </li>

              <li data-bs-toggle='tooltip' data-bs-placement='top' title='View'>
                <Link to={`#`} className='btn btn-sm btn-soft-info' id={`edittooltip-${cellProps.row.original.id}`}>
                  <i className='mdi mdi-download' />
                  <UncontrolledTooltip placement='top' target={`edittooltip-${cellProps.row.original.id}`}>
                    Download
                  </UncontrolledTooltip>
                </Link>
              </li>
            </ul>
          )
        }
      }
    ],
    [filter, page, contents]
  )

  const handleSort = (sortBy) => {
    if (sortBy.length > 0) {
      setFilter({ ...filter, sort_field: sortBy[0].id, sort_order: sortBy[0].desc ? 'desc' : 'asc' })
      dispatch(onGetManageContent({ ...filter, sort_field: sortBy[0].id, sort_order: sortBy[0].desc ? 'desc' : 'asc' }))
    } else {
      setFilter({ ...filter, sort_field: '', sort_order: '' })
      dispatch(onGetManageContent({ ...filter, sort_field: '', sort_order: '' }))
    }
  }

  const onDelete = async () => {
    try {
      const res = await deleteContentAPI({
        id: selected.id
      })
      if (res) {
        toast('Xóa bài viết thành công', { type: 'success' })
        setShowDelete(false)
      }
    } catch (error) {
      console.log(error)
      toast(error.response.data.message, { type: 'error' })
    }
  }

  const onUpdateRequest = async (status, note) => {
    try {
      const res = await patchStatusContentAPI({
        id: selected.id,
        status,
        node: note
      })
      if (res) {
        toast(status === 'pause' ? 'Tạm ngưng bài viết thành công' : 'Hiển thị lại bài viết thành công', {
          type: 'success'
        })
        dispatch(onGetManageContent({ ...filter, page: page }))
      }
    } catch (error) {
      console.log(error)
      toast(error.response.data.message, { type: 'error' })
    }
    closePopup()
  }

  const closePopup = () => {
    setShowPause(false)
    setShowPlay(false)
  }

  const setGlobalFilterUser = async (data) => {
    data.start_date = data.start_date?.startOf('day')?.toDate()
    data.end_date = data.end_date?.endOf('day')?.toDate()

    setStorage('filterProgramBonusTempList', data)

    setFilter({
      ...data,
      meta: JSON.stringify({
        'Nhập từ khóa tìm kiếm': data?.search,
        'Loại chương trình': data?.type_name,
        'Thời gian áp dụng': data?.start_date + ' - ' + data?.end_date
      }),
      page: 1
    })

    dispatch(
      onGetManageContent({
        ...data,
        meta: JSON.stringify({
          'Nhập từ khóa tìm kiếm': data?.search,
          'Loại chương trình': data?.type_name,
          'Thời gian áp dụng': data?.start_date + ' - ' + data?.end_date
        })
      })
    )
    setPage(1)
  }

  const goToPage = (page) => {
    setFilter({ ...filter, page: page })
    setPage(page)
    dispatch(onGetManageContent({ ...filter, page: page }))
  }

  const handleClickExport = async () => {
    setLoading(true)
    try {
      const res = await exportByProgrammingAPI({ ...filter })
      if (res?.data?.success === false) {
        toast(res?.message, { type: 'error' })
      } else {
        toast('Hệ thống đang xử lý. Tệp của bạn đã được gửi tới email ' + profile?.email + ' trong vài phút', {
          type: 'success'
        })
      }
    } catch (error) {
      toast(error.response.data.message, { type: 'error' })
    }
    setLoading(false)
  }

  return (
    <React.Fragment>
      <div className='page-content'>
        <div className='page-title-box container-fluid'>
          <h4 className=' mb-sm-0 font-size-18 pb-4'>{'Đối soát thưởng'}</h4>
          {isLoading ? (
            <Spinners setLoading={setLoading} />
          ) : (
            <Row>
              <Col lg='12'>
                <Card>
                  <CardBody className='border-bottom'>
                    <div className='d-flex align-items-center'>
                      <h5 className='mb-0 card-title flex-grow-1'>{''}</h5>
                      <div className='flex-shrink-0'>
                        <button onClick={() => handleClickExport()} className='btn btn-primary me-2 pe-4'>
                          <i className='mdi mdi-database-export me-1 ms-2' />
                          {'Export'}
                        </button>
                      </div>
                    </div>
                  </CardBody>
                  <CardBody>
                    <TableContainer
                      columns={columns}
                      data={contents}
                      isGlobalFilter={true}
                      setGlobalFilterUser={setGlobalFilterUser}
                      isProgramBonusTempFilter={true}
                      placeholderSearch={'Nhập tên chương trình'}
                      isPagination={true}
                      iscustomPageSizeOptions={false}
                      isShowingPageLength={false}
                      customPageSize={10}
                      tableClass='table-bordered align-middle nowrap mt-2'
                      paginationDiv='col-sm-12 col-md-7'
                      pagination='pagination justify-content-end pagination-rounded'
                      setSortBy={handleSort}
                      useAPIChangePaging
                      currentPage={page}
                      gotoPageCustom={goToPage}
                      total={total}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </div>
      </div>
      <ConfirmModal
        show={showDelete}
        icon={
          <div className='avatar-title rounded-circle bg-danger text-light bg-opacity-60 font-size-20 rounded-3'>
            <i className='mdi mdi-delete-outline'></i>
          </div>
        }
        onConfirmClick={onDelete}
        onCloseClick={() => setShowDelete(false)}
        title='Xác nhận xóa bài viết'
        description='Bài viết đã xóa không thể khôi phục. Bạn có chắc muốn xóa bài viết?'
      />
      <ConfirmModal
        show={showPause}
        icon={
          <div className='avatar-title rounded-circle bg-danger text-light bg-opacity-60 font-size-20 rounded-3'>
            <i className='mdi mdi-delete-outline'></i>
          </div>
        }
        hasInput
        onConfirmClick={(note) => {
          onUpdateRequest('pause', note)
        }}
        onCloseClick={() => setShowPause(false)}
        title='Xác nhận tạm ngưng bài viết'
        description={`Bài viết sẽ không còn được hiển thị ở các module liên quan trên Mobile App. Bạn có chắc chắn muốn tạm ngưng hiển thị bài viết?`}
      />
      <ConfirmModal
        show={showPlay}
        onConfirmClick={(note) => {
          onUpdateRequest('active')
        }}
        onCloseClick={() => setShowPlay(false)}
        // hasInput
        title={'Xác nhận hiển thị lại bài viết'}
        description='Bài viết sẽ được hiển thị ở các module liên quan trên Mobile App. Bạn có chắc chắn muốn hiển thị lại bài viết?'
      />
    </React.Fragment>
  )
}

export default ProgramBonusTempList
