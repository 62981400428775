import React, { useRef } from 'react'
import { CommonVN } from 'constants/vn'
import { MAX_DATE, MIN_DATE } from 'constants/constants'
import DatePicker from 'react-multi-date-picker'
import { DayPicker } from 'react-day-picker'
import DatePanel from 'react-multi-date-picker/plugins/date_panel'
import './FormDateMultiple.scss'
import { CalendarOutlined } from '@ant-design/icons'
import { ConfigProvider } from 'antd'
import vn from 'antd/lib/locale/vi_VN'
import dayjs from 'dayjs'
import 'dayjs/locale/vi'
dayjs.locale('vn')

const months = [
  ['Th01', 'Th01'], //[["name","shortName"], ... ]
  ['Th02', 'Th02'],
  ['Th03', 'Th03'],
  ['Th04', 'Th04'],
  ['Th05', 'Th05'],
  ['Th06', 'Th06'],
  ['Th07', 'Th07'],
  ['Th08', 'Th08'],
  ['Th09', 'Th09'],
  ['Th10', 'Th10'],
  ['Th11', 'Th11'],
  ['Th12', 'Th12']
]
const weekDays = [
  ['CN', 'CN'], //[["name","shortName"], ... ]
  ['T2', 'T2'],
  ['T3', 'T3'],
  ['T4', 'T4'],
  ['T5', 'T5'],
  ['T6', 'T6'],
  ['T7', 'T7']
]

export const FormDateMultiple = ({ ...props }) => {
  const dateRef = useRef()
  const { disabled, disabledDate, ...rest } = props
  const [value, setValue] = React.useState()

  return (
    <div className='position-relative'>
      <ConfigProvider locale={vn}>
        <DatePicker
          //locale={vn}
          months={months}
          weekDays={weekDays}
          ref={dateRef}
          multiple
          className='w-100'
          containerClassName='w-100'
          disabled={disabled}
          placeholder={CommonVN.datePlaceholder}
          value={value}
          onChange={setValue}
          format={CommonVN.datePlaceholder.toUpperCase()}
          minDate={new Date()}
          //maxDate={MAX_DATE}
          plugins={[<DatePanel key={'datepanel'} />]}
          {...rest}
        />
      </ConfigProvider>
      <CalendarOutlined
        onClick={() => dateRef.current.openCalendar()}
        className='position-absolute top-50 translate-middle end-0 icon'
      />
    </div>
  )
}
