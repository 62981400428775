import React, { useEffect, useRef, useState } from 'react'
import { Row, Col, Card, CardBody, Label, Input } from 'reactstrap'

// actions
import { ProductActivationVN, UserVN, profileVN } from 'constants/vn'
import './profile.scss'
import { checkFileSizeValid } from 'helpers/global_helper'
import { toast } from 'react-toastify'

// Ligh box
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'
import { Form, Select, Tree, Input as InputAntd, Spin, Image } from 'antd'
import {
  getDistrictListAll,
  getPortfolioAllAPI,
  getProvinceListAll,
  getSubZoneListAll,
  getWardListAll,
  getZoneListAll,
  updateProfileAPI
} from 'helpers/backend_helper'
import { REGEX_EMAIL, REGEX_NUMBER_VIET_NAM } from 'constants/regex'
import { FormSelect } from 'components/Common/form-select/FormSelect'

const UserProfileView = ({ data, myLocationManagement, onUpdateImage, onRemoveImage, isEdit, navigate }) => {
  const [isGalleryZoom, setisGalleryZoom] = useState(false)
  const uploadRef = useRef(null)
  // tree data zone
  const [treeDataZone, setTreeDataZone] = useState([])
  const [treeCheck, setTreeCheck] = useState([])
  const [treeCheckId, setTreeCheckId] = useState([])
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [industry, setIndustry] = useState()
  // const navigate = props.router.navigate

  // Detail
  const [userDetail, setUserDetail] = useState()

  let provinces = data?.provinceManagers?.map((element) => element.provinceName)?.join(', ')

  const handleUploadClick = () => {
    uploadRef.current?.click()
  }

  useEffect(() => {
    handleGetZoneListAll()
  }, [])

  useEffect(() => {
    getDataOptions()
  }, [])

  useEffect(() => {
    const detailProvinceId = myLocationManagement?.map((item) => item.id)
    const province_key = getKeysByProvinceIds(treeDataZone, detailProvinceId)
    setTreeCheck(province_key)

    setTreeCheckId(detailProvinceId)
  }, [userDetail, treeDataZone])

  const hanleFileChange = (e) => {
    // console.log(33333, e)
    const file = e?.target?.files[0]
    const fileValid = checkFileSizeValid(file)
    console.log(fileValid, file.size)
    if (fileValid) {
      onUpdateImage(file)
    } else {
      toast('Ảnh vượt quá dung lượng cho phép (5MB). Vui lòng chọn ảnh khác', {
        type: 'error'
      })
    }
  }

  const getDataOptions = async () => {
    try {
      const resIndustry = await getPortfolioAllAPI({ type: 'industry' })
      setIndustry(resIndustry?.data)
    } catch (error) {
      console.log(error)
    }
  }

  function getKeysByProvinceIds(data1, locationIds) {
    const keys = []

    data1.map((item_zone) => {
      if (item_zone.children) {
        item_zone.children.map((item_subZone) => {
          if (item_subZone.children) {
            item_subZone.children.map((item_province) => {
              if (item_province.children) {
                item_province.children.map((item_district) => {
                  locationIds?.map((item_id) => {
                    if (item_id == item_district.district_id) {
                      keys.push(item_district.key)
                    }
                  })
                })
              }
            })
          }
        })
      }
    })
    return keys
  }

  // func zone
  const handleGetZoneListAll = async () => {
    try {
      const res = await getZoneListAll()
      const list_data = []

      const processZoneItem = (item) => ({
        title: item.name,
        key: item.id,
        children: [],
        selectable: false
      })

      const processSubZoneItem = (item) => ({
        title: item.name,
        key: `${item.zoneId}-${item.id}`,
        children: [],
        selectable: false
      })

      const processProvinceItem = (item) => ({
        title: item.name,
        key: `${item.zoneId}-${item.subZoneId}-${item.id}`,
        province_id: item.id,
        children: [],
        selectable: false
      })

      const processDistrictItem = (item) => ({
        title: item.name,
        key: `${item.zoneId}-${item.subZoneId}-${item.provinceId}-${item.id}`,
        district_id: item.id,
        children: [],
        selectable: false
      })

      const processWardItem = (item) => ({
        title: item.name,
        key: `${item.zoneId}-${item.subZoneId}-${item.provinceId}-${item.districtId}-${item.id}`,
        ward_id: item.id,
        selectable: false
        // children: []
      })

      if (res.data) {
        res.data.forEach((item_zone) => {
          list_data.push(processZoneItem(item_zone))
        })

        const resSubZone = await getSubZoneListAll()

        if (resSubZone.data) {
          resSubZone.data.forEach((item_subZone) => {
            const index_zone = list_data.findIndex((item) => item.key === item_subZone.zoneId)

            if (index_zone >= 0) {
              list_data[index_zone].children.push(processSubZoneItem(item_subZone))
            }
          })

          const resProvince = await getProvinceListAll()

          if (resProvince.data) {
            resProvince.data.forEach((item_province) => {
              const index_zone = list_data.findIndex((item) => item.key === item_province.zoneId)
              const index_sub_zone = list_data[index_zone]?.children.findIndex(
                (item) => item.key === `${item_province.zoneId}-${item_province.subZoneId}`
              )

              if (index_zone >= 0 && index_sub_zone >= 0) {
                list_data[index_zone].children[index_sub_zone].children.push(processProvinceItem(item_province))
              }
            })

            const resDistrict = await getDistrictListAll()

            if (resDistrict.data) {
              const districtList = resDistrict.data.map((district) => {
                const province = resProvince.data.find((prov) => prov.id === district.parentId)
                return {
                  ...district,
                  zoneId: province?.zoneId,
                  subZoneId: province?.subZoneId,
                  provinceId: province?.id
                }
              })

              districtList?.forEach((item_district) => {
                const index_zone = list_data.findIndex((item) => item.key === item_district?.zoneId)

                const index_sub_zone = list_data[index_zone]?.children.findIndex(
                  (item) => item.key === `${item_district?.zoneId}-${item_district?.subZoneId}`
                )
                const index_province = list_data[index_zone]?.children[index_sub_zone]?.children.findIndex(
                  (item) =>
                    item.key === `${item_district?.zoneId}-${item_district?.subZoneId}-${item_district?.provinceId}`
                )

                if (index_zone >= 0 && index_sub_zone >= 0 && index_province >= 0) {
                  list_data[index_zone].children[index_sub_zone].children[index_province].children.push(
                    processDistrictItem(item_district)
                  )
                }
              })
            }
          }
        }
      }
      setTreeDataZone(list_data)
    } catch (error) {
      toast(error.response.data.message, { type: 'error' })
    }
  }

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        name: data.name,
        phone: data.phone,
        email: data.email,
        code: data.employeeCode,
        industry_id: data?.industry?.id
        // permissionGroup: data.roleId
      })
    }
  }, [userDetail, treeDataZone])

  const onFinish = async (value) => {
    setLoading(true)
    // const split = treeSelect.split('-')
    // const department_id = split[split.length - 1]

    const payload = {
      name: value.name,
      phone: value.phone,
      email: value.email,
      employee_code: value.code,
      industry_id: value.industry_id
    }

    try {
      const res = await updateProfileAPI({ ...payload })

      if (res) {
        toast('Thông tin tài khoản được cập nhật thành công', { type: 'success' })
        // window.location.href = '/profile'
      }
    } catch (error) {
      toast(error.response.data.message, { type: 'error' })
    }

    setLoading(false)
  }

  return (
    <Row>
      <Col lg='3'>
        <Card className=''>
          <CardBody>
            <div className='d-flex flex-column justify-content-center'>
              <div className='avatar '>
                {data?.image ? (
                  <img
                    src={data?.image}
                    alt='avatar'
                    onClick={() => {
                      setisGalleryZoom(true)
                    }}
                    className='avatar-xxl rounded-circle img-thumbnail image-avatar'
                  />
                ) : (
                  <img
                    src={'https://pixfi.s3.ap-southeast-1.amazonaws.com/hpg/1705561583968_unnamed.png'}
                    alt='avatar'
                    onClick={() => {
                      setisGalleryZoom(true)
                    }}
                    className='avatar-xxl rounded-circle img-thumbnail image-avatar'
                  />
                )}

                <div className='action'>
                  <button className='btn btn-link text-white' type='button' onClick={handleUploadClick}>
                    <i className='mdi mdi-pencil-outline font-size-22' />
                  </button>
                  <button className='btn btn-link text-white ms-2' type='button' onClick={onRemoveImage}>
                    <i className='mdi mdi-delete-outline font-size-22 ' />
                  </button>
                </div>
                <input
                  id='exampleFile'
                  type='file'
                  ref={uploadRef}
                  accept='image/png, image/jpeg, image/jpg, image/jpe, image/hiec, image/heif'
                  onChange={hanleFileChange}
                  className='upload-avatar'
                />
              </div>
              <Label className='form-label text-center mt-4'>{data?.name}</Label>
              <div className='text-center'>{data?.email}</div>
            </div>
          </CardBody>
        </Card>
      </Col>
      <Col lg='9'>
        <Form layout='vertical' onFinish={onFinish} autoComplete='off' form={form} name='UserForm'>
          <Card className=' h-100 mb-4'>
            <CardBody>
              <div className='d-flex flex-wrap  justify-content-between '>
                <h3>{profileVN.subTitle}</h3>
              </div>
              <div className='form-group '>
                <Row className='mt-4'>
                  <Col sm='4'>
                    <Label className='form-label'>{profileVN.fields.name}</Label>
                    {!isEdit ? (
                      <div>{data?.name}</div>
                    ) : (
                      <Form.Item
                        name={UserVN.form.name.name}
                        rules={[{ required: true, message: UserVN.form.name.required }]}
                      >
                        <InputAntd
                          placeholder={UserVN.form.name.placeholder}
                          size='large'
                          maxLength={250}
                          onBlur={(e) => form.setFieldValue(UserVN.form.name.name, e.target.value.trim())}
                        />
                      </Form.Item>
                    )}
                  </Col>
                  <Col sm='4'>
                    <Label className='form-label'>{profileVN.fields.phone}</Label>
                    {!isEdit ? (
                      <div>{data?.phone}</div>
                    ) : (
                      <Form.Item
                        name={UserVN.form.phone.name}
                        rules={[
                          { required: true, message: UserVN.form.phone.required },
                          { pattern: REGEX_NUMBER_VIET_NAM, message: UserVN.form.phone.requiredPhone }
                        ]}
                      >
                        <InputAntd
                          placeholder={UserVN.form.phone.placeholder}
                          size='large'
                          maxLength={10}
                          onBlur={(e) => form.setFieldValue(UserVN.form.phone.name, e.target.value.trim())}
                        />
                      </Form.Item>
                    )}
                  </Col>
                  <Col sm='4'>
                    <Label className='form-label'>{profileVN.fields.email}</Label>
                    {!isEdit ? (
                      <div>{data?.email ?? 'admin@vmogroup.com'}</div>
                    ) : (
                      <Form.Item
                        name={UserVN.form.email.name}
                        rules={[
                          { required: true, message: UserVN.form.email.required },
                          { pattern: REGEX_EMAIL, message: UserVN.form.email.requiredEmail }
                        ]}
                      >
                        <InputAntd
                          placeholder={UserVN.form.email.placeholder}
                          maxLength={320}
                          size='large'
                          onBlur={(e) => {
                            const value = e.target.value.trim()
                            if (new RegExp(REGEX_EMAIL).test(value)) {
                              form.setFields([{ name: UserVN.form.email.name, errors: [] }])
                            } else {
                              form.setFields([
                                { name: UserVN.form.email.name, errors: [UserVN.form.email.requiredEmail] }
                              ])
                            }
                            form.setFieldValue(UserVN.form.email.name, value)
                          }}
                        />
                      </Form.Item>
                    )}
                  </Col>
                </Row>
                <Row className='mt-4'>
                  <Col sm='4'>
                    <Label className='form-label'>{profileVN.fields.id}</Label>
                    {!isEdit ? (
                      <div>{data?.employeeCode}</div>
                    ) : (
                      <Form.Item
                        name={UserVN.form.code.name}
                        rules={[{ required: true, message: UserVN.form.code.required }]}
                      >
                        <InputAntd
                          placeholder={UserVN.form.code.placeholder}
                          size='large'
                          maxLength={20}
                          onBlur={(e) => form.setFieldValue(UserVN.form.code.name, e.target.value.trim())}
                        />
                      </Form.Item>
                    )}
                  </Col>
                  <Col sm='4'>
                    <Label className='form-label'>{'Bộ phận'}</Label>
                    {!isEdit ? (
                      <div>{data?.industry?.name}</div>
                    ) : (
                      // <Form.Item
                      //   name={UserVN.form.code.name}
                      //   rules={[{ required: true, message: UserVN.form.code.required }]}
                      // >
                      //   <InputAntd
                      //     placeholder={'Chọn bộ phận'}
                      //     size='large'
                      //     maxLength={20}
                      //     onBlur={(e) => form.setFieldValue(UserVN.form.code.name, e.target.value.trim())}
                      //   />
                      // </Form.Item>
                      <Form.Item name='industry_id'>
                        <FormSelect
                          options={industry?.map((item) => ({
                            value: item.id,
                            label: item.name
                          }))}
                          onChange={() => {
                            // form.setFieldValue('industrySector', undefined)
                          }}
                          placeholder={ProductActivationVN.form.industry.placeholder}
                        />
                      </Form.Item>
                    )}
                  </Col>
                  <Col sm='4'>
                    <Label className='form-label'>{profileVN.fields.position}</Label>
                    <div>{data?.role?.name}</div>
                  </Col>
                </Row>
                <Row className='mt-4'>
                  <Label className='form-label mt-4'>{'Đơn vị'}</Label>
                  <div>Điện lạnh Hoà Phát/{data?.departmentTreeName}</div>
                </Row>
                <Row className='mt-4'>
                  <Label className='form-label mt-4'>{'Khu vực'}</Label>
                  <div>
                    {/* {data?.provinceManagementTreeName} */}
                    <Tree checkable treeData={treeDataZone} disabled={true} height={150} checkedKeys={treeCheck} />
                  </div>
                </Row>
              </div>
            </CardBody>
          </Card>
        </Form>

        <div className='d-flex gap-2 justify-content-end mt-4'>
          {isEdit && (
            <button
              onClick={() => (window.location.href = '/profile')}
              type='button'
              className='btn btn-soft-secondary w-14 '
            >
              Hủy
            </button>
          )}
          <button
            type={'submit'}
            className='btn btn-primary w-14'
            onClick={() => {
              if (isEdit === false) {
                window.location.href = '/profile/edit'
              } else {
                form.submit()
              }
            }}
          >
            {isEdit ? 'Lưu' : 'Chỉnh sửa'}
          </button>
        </div>
      </Col>
      {isGalleryZoom ? (
        <Lightbox
          enableZoom
          mainSrc={data?.image}
          onCloseRequest={() => {
            setisGalleryZoom(false)
          }}
        />
      ) : null}
    </Row>
  )
}

export default UserProfileView
