import { all, fork } from 'redux-saga/effects'

//public
import AuthSaga from './auth/login/saga'
import ProfileSaga from './auth/profile/saga'
import LayoutSaga from './layout/saga'
import dashboardSaga from './dashboard/saga'
import changePasswordSaga from './change-password/saga'
import manageShopSaga from './manage-shop/saga'
import managePortfolioSaga from './manage-portfolio/saga'
import manageProductSaga from './manage-product/saga'
import manageProductActivationSaga from './manage-product-activation/saga'
import manageUnitSaga from './manage-unit/saga'
import managePermissionSaga from './manage-permission/saga'
import manageUserSaga from './manage-user/saga'
import manageProgramSaga from './manage-program/saga'
import manageLocalitySaga from './manage-locality/saga'
import manageAccountClassSaga from './manage-account-class/saga'
import manageAccumulationSaga from './manage-accumulation/saga'
import manageActivationSaga from './manage-activation/saga'
import manageGuestSaga from './manage-guest/saga'
import manageContentSaga from './manage-content/saga'
import manageBanerSaga from './manage-baner/saga'
import programBonusTempSaga from './report-program-bonus-temp/saga'
import BanksSaga from './manage-bank/saga'
import ContactsSaga from './manage-contact/saga'
import HistorysSaga from './manage-history/saga'
import manageNotificationSaga from './manage-notification/saga'
import ReportUserStatisticSaga from './report-user-statistic/saga'
import ReportAccountRankSaga from './report-account-rank/saga'

export default function* rootSaga() {
  yield all([
    //public
    fork(AuthSaga),
    fork(changePasswordSaga),
    fork(ProfileSaga),
    fork(LayoutSaga),
    fork(dashboardSaga),
    fork(manageShopSaga),
    fork(managePortfolioSaga),
    fork(manageProductSaga),
    fork(manageUnitSaga),
    fork(managePermissionSaga),
    fork(manageProductActivationSaga),
    fork(manageUserSaga),
    fork(manageProgramSaga),
    fork(manageLocalitySaga),
    fork(manageAccountClassSaga),
    fork(manageAccumulationSaga),
    fork(manageActivationSaga),
    fork(manageGuestSaga),
    fork(manageContentSaga),
    fork(manageBanerSaga),
    fork(programBonusTempSaga),
    fork(BanksSaga),
    fork(ContactsSaga),
    fork(HistorysSaga),
    fork(manageNotificationSaga),
    fork(ReportUserStatisticSaga),
    fork(ReportAccountRankSaga)
  ])
}
