export const GET_MANAGE_CONTENT = "GET_CONTENTLIST"
export const GET_MANAGE_CONTENT_FAIL = "GET_MANAGE_CONTENT_FAIL"
export const GET_MANAGE_CONTENT_SUCCESS = "GET_MANAGE_CONTENT_SUCCESS"

export const GET_CONTENT_DETAIL = "GET_CONTENT_DETAIL"
export const GET_CONTENT_DETAIL_SUCCESS = "GET_CONTENT_DETAIL_SUCCESS"
export const GET_CONTENT_DETAIL_FAIL = "GET_CONTENT_DETAIL_FAIL"

export const UPDATE_MANAGE_CONTENT = "UPDATE_MANAGE_CONTENT"
export const UPDATE_MANAGE_CONTENT_SUCCESS = "UPDATE_MANAGE_CONTENT_SUCCESS"
export const UPDATE_MANAGE_CONTENT_FAIL = "UPDATE_MANAGE_CONTENT_FAIL"

export const GET_KTVS = "GET_KTV_LIST"
