import React from "react"
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import moment from "moment"

const UnitCode = cell => {
    let code = ''
    if (cell.value < 10) {
        code = `BP00${cell.value}`
    } else if (cell.value >= 10 && cell.value < 100) {
        code = `BP0${cell.value}`
    } else {
        return code = `BP${cell.value}`
    }

    return cell.value ? code : "--"
}

const UnitName = cell => {
    return cell.value ? cell.value : "--"
}

const UnitLevel = cell => {
    return cell.value ? `Điện lạnh Hoà Phát/${cell.value}` : "Điện lạnh Hoà Phát"
}

const UnitUpdateDate = cell => {
    return cell.value ? moment(cell.value).format('DD/MM/YYYY') : "--"
}

export { UnitCode, UnitName, UnitUpdateDate, UnitLevel }
