import { PermissionVN, BankVN } from "constants/vn"
import React, { useEffect, useState } from "react"
import { Col, Label, Modal, ModalBody, Row } from "reactstrap"
import { createSelector } from "reselect"
import { useSelector } from "react-redux"
import { setTypePopupBank, getBankList } from "store/actions"
import { getZoneAPI, getAreaManagementOptionsAPI, postCreateBankAPI, updateBankAPI } from "helpers/backend_helper"
import { useDispatch } from "react-redux"
import { toast } from "react-toastify"
import { Form, Tree, Switch, Divider, Input, Spin, InputNumber } from "antd"
import { FormInput } from "components/Common/form-input/FormInput"
import { FormSelect } from "components/Common/form-select/FormSelect"
import { optionsGeographicalLevel, AREA_TYPE } from "../constants"
import ConfirmModal from "components/Common/ConfirmModal"
import { isEqual } from "lodash"

const AddEditBank = ({ show, onCloseClick, selected, filter, setPage }) => {
    const dispatch = useDispatch()

    const selectBanksState = state => state.BanksReducer
    const BanksProperties = createSelector(selectBanksState, reducer => ({
        type: reducer.type,
        bankDetail: reducer.bankDetail,
    }))
    const { type, bankDetail } = useSelector(BanksProperties)

    const isEdit = type !== "view"
    const [form] = Form.useForm()
    const watchForm = Form.useWatch([], form)
    const [disabledSubmit, setDisabledSubmit] = useState(true)
    const [loading, setLoading] = useState(false)
    const [valueGeographicalLevel, setValueGeographicalLevel] = useState(AREA_TYPE.ZONE)
    const [zone, setZone] = useState()
    const [subZone, setSubZone] = useState()
    const [province, setProvince] = useState()
    const [district, setDistrict] = useState()
    const [onCancel, setOnCancel] = useState(false)
    const [regex, setRegex] = useState({
        regex: /^\b\d{1}\b/,
        message: 'Mã Miền phải gồm 1 ký tự'
    })
    const [initData, setInitData] = useState()

    useEffect(() => {
        if (type == "create") {
        }
    }, [type])

    useEffect(() => {
        if (bankDetail) {
            setInitData({
                [BankVN.form.abbreviation.name]: bankDetail.type,
                [PermissionVN.form.name.name]: bankDetail.name,
            })

           

            form.setFieldsValue({
                [BankVN.form.abbreviation.name]: bankDetail.abbreviation,
                [PermissionVN.form.name.name]: bankDetail.name,

            })
            setValueGeographicalLevel(bankDetail.type)
        }
    }, [bankDetail])

    useEffect(() => {
        form.validateFields({ validateOnly: true }).then(
            () => {
                setDisabledSubmit(false)
            },
            () => {
                setDisabledSubmit(true)
            }
        )
    }, [watchForm])



        const onFinish = async (values) => {
        if (type == "view") {
            dispatch(setTypePopupBank("edit"))
            setDisabledSubmit(false)
            return;
        }
        setLoading(true)
        try {
            let parent_id = null


            const payload = {
                abbreviation: values.abbreviation,
                name: values.name,
            }

            if (type == "create") {
                const res = await postCreateBankAPI({ ...payload })

                if (res) {
                    toast("Thêm mới ngân hàng thành công", { type: "success" })
                    toast("Thêm mới ngân hàng thành công", { type: "success" })
                    onCloseClick()
                    dispatch(getBankList())
                    setPage(1)
                }

            } else {
                const res = await updateBankAPI({ ...payload, id: bankDetail.id })

                if (res) {
                    toast("Cập nhật ngân hàng thành công", { type: "success" })
                    toast("Cập nhật ngân hàng thành công", { type: "success" })
                    onCloseClick()
                    dispatch(getBankList({ ...filter }))
                    dispatch(getBankList({ ...filter }))
                }
            }
        } catch (error) {
            toast(error.response.data.message, { type: "error" })
        }
        setLoading(false)
    }

   
   

    const onClickCancel = () => {
        setOnCancel(false)
        onCloseClick()
    }

    const checkClickCancel = () => {
        if (type != "view") {
            if (type == "create") {
                if (form.isFieldsTouched()) {
                    setOnCancel(true)
                } else {
                    onCloseClick()
                }
            } else {
                if (!isEqual(initData, watchForm)) {
                    setOnCancel(true)
                } else {
                    onCloseClick()
                }
            }
        } else {
            onCloseClick()
        }
    }

    return (
        <Modal
            size="lg"
            isOpen={show}
            toggle={() => {
                checkClickCancel()
            }}
            centered={true}
        >
            <div className="modal-content">
                <ModalBody className="p-4 text-start">
                    <Spin spinning={loading}>
                        <button type="button" onClick={checkClickCancel} className="btn-close position-absolute end-0 top-0" />
                        {type == "view" && <h2>{BankVN.viewModal}</h2>}
                        {type == "create" && <h2>{BankVN.addModal}</h2>}
                        {type == "edit" && <h2>{BankVN.editModal}</h2>}
                        <Form layout="vertical" onFinish={onFinish} form={form}>
                            <div className="form-group">
                                <Row>
                                    <Col>
                                        <Form.Item
                                            label={BankVN.form.name.label}
                                            name={BankVN.form.name.name}
                                            rules={[{ required: true, message: BankVN.form.abbreviation.required }]}
                                        >
                                          <Input
                                                size="large"
                                                label={BankVN.form.name.label}
                                                placeholder={BankVN.form.name.placeholder}
                                                disabled={!isEdit}
                                                maxLength={250}
                                                onBlur={(e) => form.setFieldValue(BankVN.form.name.name, e.target.value.trim())}
                                            />
                                        </Form.Item>
                                    </Col>
                                
                                </Row>
                                <Row>
                                <Col>
                                        <Form.Item
                                            label={BankVN.form.abbreviation.label}
                                            name={BankVN.form.abbreviation.name}
                                            rules={[
                                                { required: true, message: BankVN.form.abbreviation.required },
                                            ]}
                                        >
                                            <Input
                                                size="large"
                                                label={BankVN.form.abbreviation.label}
                                                placeholder={BankVN.form.abbreviation.placeholder}
                                                disabled={!isEdit}
                                                maxLength={50}
                                                onBlur={(e) => form.setFieldValue(BankVN.form.abbreviation.name, e.target.value.trim())}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                             
                            </div>
                            <div className="hstack gap-2 justify-content-center mt-4">
                            {isEdit && (
                                <>
                                    <button type="button" className="btn btn-soft-secondary w-25 " onClick={() => {
                                        checkClickCancel()
                                    }}>
                                        Hủy
                                    </button>

                                    <button
                                        type={"submit"}
                                        className="btn btn-primary w-25"
                                        disabled={!isEdit || disabledSubmit || isEqual(initData, watchForm)}
                                    >
                                        {isEdit ? "Lưu" : "Chỉnh sửa"}
                                    </button>
                                </>
                            )}
                            </div>
                        </Form>
                    </Spin>
                </ModalBody>
            </div >
            <ConfirmModal
                show={onCancel}
                onConfirmClick={onClickCancel}
                onCloseClick={() => setOnCancel(false)}
                title={`Xác nhận`}
                description={`Thay đổi sẽ không được lưu. Bạn có chắc chắn muốn thoát không?`}
            />
        </Modal >
    )
}
export default AddEditBank
