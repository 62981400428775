import React, { useEffect, useMemo, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

//import components

import { getUserList, setTypePopupUser } from 'store/actions'

import { BaseCol, NoCol, DateCol } from './components/ManageAccountClass/ManageAccountCol'

//redux
import { useSelector, useDispatch } from 'react-redux'
import { createSelector } from 'reselect'

import { Col, Row, UncontrolledTooltip, Card, CardBody } from 'reactstrap'
import Spinners from 'components/Common/Spinner'
import { toast } from 'react-toastify'
import TableContainer from 'components/Common/TableContainer'
import { PointHistoryVN, LocalityVN } from 'constants/vn'
import ConfirmModal from 'components/Common/ConfirmModal'
import { getPointHistoryListAPI } from 'helpers/backend_helper'
import { checkIsLocked } from 'helpers/global_helper'
import AddEditLocality from './components/ManageAccountClass/AddEditAccountClass'
import { Form, InputNumber } from 'antd'
import moment from 'moment'

function ManagePointHistory() {
  //meta title
  document.title = 'Manage Point History CMS List | Skote - React Admin & Dashboard Template'

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [isLoading, setLoading] = useState(false)
  const [selected, setSelected] = useState()
  const [filter, setFilter] = useState()
  const [page, setPage] = useState(1)
  const [listHistory, setListHistory] = useState([])
  const [total, setTotal] = useState()

  useEffect(() => {
    handleGetListHistory()
  }, [filter, page])

  const handleGetListHistory = async () => {
    try {
      const res = await getPointHistoryListAPI({ page, ...filter })

      if (res.data) {
        setListHistory(res.data)
      }

      if (res.meta) {
        setTotal(res.meta.totalItems)
      }
    } catch (error) {
      toast(error.response.data.message, { type: 'error' })
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: PointHistoryVN.table.datePoint,
        accessor: 'createdAt',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return (
            <>
              {cellProps.row.original?.isDeleted && (
                <>
                  <i
                    className='mdi mdi-recycle-variant'
                    style={{ marginRight: '4px', color: 'red' }}
                    id={`viewtooltip-bravo-${cellProps.row.original.id}`}
                  />
                  <UncontrolledTooltip
                    trigger='hover'
                    placement='top'
                    target={`viewtooltip-bravo-${cellProps.row.original.id}`}
                  >
                    Dữ liệu đã được thu hồi.
                  </UncontrolledTooltip>
                </>
              )}
              {cellProps.value ? moment(cellProps.value).format('DD-MM-YYYY') : '--'}
            </>
          )
        }
      },
      {
        Header: PointHistoryVN.table.accountType,
        accessor: 'user',
        id: 'userAccountType',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return !cellProps.value ? '--' : cellProps.value.client == 'user' ? 'Cửa hàng' : 'Kỹ thuật viên'
        }
      },
      {
        Header: PointHistoryVN.table.id,
        accessor: 'user',
        id: 'userId',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return !cellProps.value ? '--' : cellProps.value.employeeCode
        }
      },
      {
        Header: PointHistoryVN.table.name,
        // accessor: 'user',
        // id: 'userName',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          if (cellProps.row.original.user.client === 'user') {
            return !cellProps.row.original.user ? '--' : cellProps.row.original.user.shopName
          } else {
            return !cellProps.row.original.user ? '--' : cellProps.row.original.user.name
          }
        }
      },
      {
        Header: PointHistoryVN.table.industry,
        accessor: 'industrySector',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return !cellProps.value ? '--' : cellProps.value.name
        }
      },
      {
        Header: PointHistoryVN.table.products,
        accessor: 'category',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return !cellProps.value ? '--' : cellProps.value.name
        }
      },
      {
        Header: PointHistoryVN.table.model,
        accessor: 'model',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return !cellProps.value ? '--' : cellProps.value.name
        }
      },
      {
        Header: PointHistoryVN.table.serial,
        accessor: 'firstProduct',
        id: 'serial',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return !cellProps.value ? '--' : cellProps.value.serial
        }
      },
      {
        Header: PointHistoryVN.table.point,
        accessor: 'point',
        id: 'productPoint',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return !cellProps.value ? '--' : cellProps.value
        }
      }
    ],
    [page, filter]
  )

  const setGlobalFilterUser = async (data) => {
    setFilter({ ...data, page: 1 })
  }

  const goToPage = (page) => {
    setFilter({ ...filter, page })
    setPage(page)
  }

  return (
    <React.Fragment>
      <div className='page-content'>
        <div className='page-title-box container-fluid'>
          <h4 className=' mb-sm-0 font-size-18 pb-4'>{PointHistoryVN.title}</h4>
          {isLoading ? (
            <Spinners setLoading={setLoading} />
          ) : (
            <Row>
              <Col lg='12'>
                <Card>
                  <CardBody className='border-bottom'>
                    <div className='d-flex align-items-center'>
                      <h5 className='mb-0 card-title flex-grow-1'>{PointHistoryVN.list}</h5>
                    </div>
                  </CardBody>
                  <CardBody>
                    <Form>
                      <TableContainer
                        columns={columns}
                        data={listHistory}
                        isGlobalFilter={true}
                        setGlobalFilterUser={setGlobalFilterUser}
                        isManagePointHistoryGlobalFilter={true}
                        isPagination={true}
                        iscustomPageSizeOptions={false}
                        isShowingPageLength={false}
                        customPageSize={10}
                        placeholderSearch={'Nhập tên KTV/ Cửa hàng, ID'}
                        tableClass='table-bordered align-middle nowrap mt-2'
                        paginationDiv='col-sm-12 col-md-7'
                        pagination='pagination justify-content-end pagination-rounded'
                        useAPIChangePaging
                        gotoPageCustom={goToPage}
                        total={total}
                      />
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </div>
      </div>
    </React.Fragment>
  )
}

export default ManagePointHistory
