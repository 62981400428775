import { PermissionVN, BankVN } from 'constants/vn'
import React, { useEffect, useState } from 'react'
import { Col, Label, Modal, ModalBody, Row } from 'reactstrap'
import { createSelector } from 'reselect'
import { useSelector } from 'react-redux'
import { setTypePopupBank, getBankList, setTypePopupContact, getContactList } from 'store/actions'
import {
  getZoneAPI,
  getAreaManagementOptionsAPI,
  postCreateBankAPI,
  updateBankAPI,
  postCreateContactAPI,
  createContactAPI,
  updateContactAPI
} from 'helpers/backend_helper'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { Form, Tree, Switch, Divider, Input, Spin, InputNumber } from 'antd'
import { FormInput } from 'components/Common/form-input/FormInput'
import { FormSelect } from 'components/Common/form-select/FormSelect'
// import { optionsGeographicalLevel, AREA_TYPE } from '../constants'
import ConfirmModal from 'components/Common/ConfirmModal'
import { isEqual } from 'lodash'
import { AREA_TYPE } from 'pages/ManageLocality/constants'

const addEditContactModal = ({ show, onCloseClick, selected, filter, setPage }) => {
  const dispatch = useDispatch()

  const selectContactsState = (state) => state.ContactsReducer
  const ContactsProperties = createSelector(selectContactsState, (reducer) => ({
    type: reducer.type,
    contactDetail: reducer.contactDetail
  }))
  const { type, contactDetail } = useSelector(ContactsProperties)

  const isEdit = type !== 'view'
  const [form] = Form.useForm()
  const watchForm = Form.useWatch([], form)
  const [disabledSubmit, setDisabledSubmit] = useState(true)
  const [loading, setLoading] = useState(false)
  const [valueGeographicalLevel, setValueGeographicalLevel] = useState(AREA_TYPE.ZONE)
  const [zone, setZone] = useState()
  const [subZone, setSubZone] = useState()
  const [province, setProvince] = useState()
  const [district, setDistrict] = useState()
  const [onCancel, setOnCancel] = useState(false)
  const [regex, setRegex] = useState({
    regex: /^\b\d{1}\b/,
    message: 'Mã Miền phải gồm 1 ký tự'
  })
  const [initData, setInitData] = useState()

  useEffect(() => {
    if (type == 'create') {
    }
  }, [type])

  useEffect(() => {
    if (contactDetail) {
      setInitData({
        id: contactDetail.id,
        email: contactDetail.email,
        phone: contactDetail.phone,
        address: contactDetail.address,
        label: contactDetail.label
      })

      form.setFieldsValue({
        id: contactDetail.id,
        email: contactDetail.email,
        phone: contactDetail.phone,
        address: contactDetail.address,
        label: contactDetail.label
      })
      setValueGeographicalLevel(contactDetail.type)
    }
  }, [contactDetail])

  useEffect(() => {
    form.validateFields({ validateOnly: true }).then(
      () => {
        setDisabledSubmit(false)
      },
      () => {
        setDisabledSubmit(true)
      }
    )
  }, [watchForm])

  const onFinish = async (values) => {
    if (type == 'view') {
      dispatch(setTypePopupContact('edit'))
      setDisabledSubmit(false)
      return
    }
    setLoading(true)
    try {
      const payload = {
        email: values?.email ? values?.email : '',
        phone: values?.phone ? values?.phone : '',
        address: values?.address ? values?.address : '',
        label: values?.label ? values?.label : ''
      }

      if (type == 'create') {
        const res = await createContactAPI({ ...payload })
        if (res) {
          // toast('Thêm mới ngân hàng thành công', { type: 'success' })
          toast('Thêm mới thông tin liên hệ thành công.', { type: 'success' })
          onCloseClick()
          dispatch(getContactList())
          setPage(1)
        }
      } else {
        const res = await updateContactAPI({ ...payload, id: contactDetail.id })

        if (res) {
          // toast('Cập nhật ngân hàng thành công', { type: 'success' })
          toast('Cập nhật thông tin liên hệ thành công', { type: 'success' })
          onCloseClick()
          dispatch(getContactList({ ...filter }))
          dispatch(getContactList({ ...filter }))
        }
      }
    } catch (error) {
      toast(error.response.data.message, { type: 'error' })
    }
    setLoading(false)
  }

  const onClickCancel = () => {
    setOnCancel(false)
    onCloseClick()
  }

  const checkClickCancel = () => {
    if (type != 'view') {
      if (type == 'create') {
        if (form.isFieldsTouched()) {
          setOnCancel(true)
        } else {
          onCloseClick()
        }
      } else {
        if (!isEqual(initData, watchForm)) {
          setOnCancel(true)
        } else {
          onCloseClick()
        }
      }
    } else {
      onCloseClick()
    }
  }

  return (
    <Modal
      size='lg'
      isOpen={show}
      toggle={() => {
        checkClickCancel()
      }}
      centered={true}
    >
      <div className='modal-content'>
        <ModalBody className='p-4 text-start'>
          <Spin spinning={loading}>
            <button type='button' onClick={checkClickCancel} className='btn-close position-absolute end-0 top-0' />
            {type == 'view' && <h2>{'Xem chi tiết thông tin liên hệ'}</h2>}
            {type == 'create' && <h2>{'Thêm mới thông tin liên hệ'}</h2>}
            {type == 'edit' && <h2>{'Chỉnh sửa thông tin liên hệ'}</h2>}
            <Form layout='vertical' onFinish={onFinish} form={form}>
              <div className='form-group'>
                <Row>
                  <Col>
                    <Form.Item
                      label={'Email'}
                      name='email'
                      rules={[{ required: true, message: 'Vui lòng nhập email' }]}
                    >
                      <Input
                        size='large'
                        label={'Email'}
                        placeholder={'Nhập email'}
                        disabled={!isEdit}
                        maxLength={250}
                        onBlur={(e) => form.setFieldValue('email', e.target.value.trim())}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Item
                      label={'Tổng đài tư vấn và hỗ trợ khách hàng'}
                      name='phone'
                      rules={[{ required: true, message: 'Vui lòng nhập SĐT' }]}
                    >
                      <Input
                        size='large'
                        label={'Tổng đài tư vấn và hỗ trợ khách hàng'}
                        placeholder={'Nhập SĐT'}
                        disabled={!isEdit}
                        maxLength={50}
                        onBlur={(e) => form.setFieldValue('phone', e.target.value.trim())}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Item
                      label={'Địa chỉ'}
                      name='address'
                      rules={[{ required: true, message: 'Vui lòng nhập địa chỉ' }]}
                    >
                      <Input
                        size='large'
                        label={'Địa chỉ'}
                        placeholder={'Nhập địa chỉ'}
                        disabled={!isEdit}
                        maxLength={250}
                        onBlur={(e) => form.setFieldValue('address', e.target.value.trim())}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Item
                      label={'Khu vực'}
                      name='label'
                      // rules={[{ required: true, message: 'Vui lòng nhập Khu vực' }]}
                    >
                      <Input
                        size='label'
                        label={'Khu vực'}
                        placeholder={'Nhập Khu vực'}
                        disabled={!isEdit}
                        maxLength={250}
                        onBlur={(e) => form.setFieldValue('label', e.target.value.trim())}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
              <div className='hstack gap-2 justify-content-center mt-4'>
                {isEdit && (
                  <>
                    <button
                      type='button'
                      className='btn btn-soft-secondary w-25 '
                      onClick={() => {
                        checkClickCancel()
                      }}
                    >
                      Hủy
                    </button>

                    <button
                      type={'submit'}
                      className='btn btn-primary w-25'
                      disabled={!isEdit || disabledSubmit || isEqual(initData, watchForm)}
                    >
                      {isEdit ? 'Lưu' : 'Chỉnh sửa'}
                    </button>
                  </>
                )}
              </div>
            </Form>
          </Spin>
        </ModalBody>
      </div>
      <ConfirmModal
        show={onCancel}
        onConfirmClick={onClickCancel}
        onCloseClick={() => setOnCancel(false)}
        title={`Xác nhận`}
        description={`Thay đổi sẽ không được lưu. Bạn có chắc chắn muốn thoát không?`}
      />
    </Modal>
  )
}
export default addEditContactModal
