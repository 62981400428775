// @flow
import {
  CHANGE_LAYOUT,
  CHANGE_LAYOUT_WIDTH,
  CHANGE_SIDEBAR_THEME,
  CHANGE_SIDEBAR_TYPE,
  CHANGE_TOPBAR_THEME,
  SHOW_RIGHT_SIDEBAR,
  CHANGE_SIDEBAR_THEME_IMAGE,
  CHANGE_PRELOADER,
  TOGGLE_LEFTMENU,
  SHOW_SIDEBAR,
  CHANGE_LAYOUT_MODE,
  INIT_APP,
  INIT_APP_SUCCESS,
  SET_FILTER
} from './actionTypes'

//constants
import {
  layoutTypes,
  layoutModeTypes,
  layoutWidthTypes,
  topBarThemeTypes,
  leftBarThemeImageTypes,
  leftSidebarTypes,
  leftSideBarThemeTypes
} from '../../constants/layout'

const INIT_STATE = {
  layoutType: layoutTypes.VERTICAL,
  layoutModeType: layoutModeTypes.LIGHT,
  layoutWidth: layoutWidthTypes.FLUID,
  leftSideBarTheme: leftSideBarThemeTypes.DARK,
  leftSideBarThemeImage: leftBarThemeImageTypes.NONE,
  leftSideBarType: leftSidebarTypes.DEFAULT,
  topbarTheme: topBarThemeTypes.LIGHT,
  isPreloader: false,
  showRightSidebar: false,
  isMobile: false,
  showSidebar: true,
  leftMenu: false,
  province: [],
  district: [],
  ward: [],
  bank: [],
  zone: [],
  subzone: [],
  filter: {
    page: 1
  }
}
const Layout = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CHANGE_LAYOUT:
      return {
        ...state,
        layoutType: action.payload
      }
    case CHANGE_PRELOADER:
      return {
        ...state,
        isPreloader: action.payload
      }

    case CHANGE_LAYOUT_MODE:
      return {
        ...state,
        layoutModeType: action.payload
      }

    case CHANGE_LAYOUT_WIDTH:
      return {
        ...state,
        layoutWidth: action.payload
      }
    case CHANGE_SIDEBAR_THEME:
      return {
        ...state,
        leftSideBarTheme: action.payload
      }
    case CHANGE_SIDEBAR_THEME_IMAGE:
      return {
        ...state,
        leftSideBarThemeImage: action.payload
      }
    case CHANGE_SIDEBAR_TYPE:
      return {
        ...state,
        leftSideBarType: action.payload.sidebarType
      }
    case CHANGE_TOPBAR_THEME:
      return {
        ...state,
        topbarTheme: action.payload
      }
    case SHOW_RIGHT_SIDEBAR:
      return {
        ...state,
        showRightSidebar: action.payload
      }
    case SHOW_SIDEBAR:
      return {
        ...state,
        showSidebar: action.payload
      }
    case TOGGLE_LEFTMENU:
      return {
        ...state,
        leftMenu: action.payload
      }
    case INIT_APP:
      return {
        ...state
      }
    case INIT_APP_SUCCESS:
      return {
        ...state,
        province: action.payload?.province,
        district: action.payload?.district,
        ward: action.payload?.ward,
        bank: action.payload?.bank,
        zone: action.payload?.zone,
        subzone: action.payload?.subzone,
        myLocationManagement: action.payload?.myLocationManagement
      }
    case SET_FILTER:
      return {
        ...state,
        filter: action.payload
      }
    default:
      return state
  }
}

export default Layout
