import React from 'react'
import moment from 'moment'
import { formateDate } from 'helpers/global_helper'
import { toNumber, toString } from 'lodash'
import { FormSelect } from 'components/Common/form-select/FormSelect'
import { Badge } from 'reactstrap'

const BaseCol = (cell) => {
  return cell.value ? cell.value : '--'
}

const TypeCol = (cell) => {
  switch (cell.value) {
    case 'user':
      return 'Cửa hàng'
    case 'technician':
      return 'Kỹ thuật viên'
    default:
      return '--'
  }
}

const priceSplitter = (number) => number && number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')

const NoCol = (cell) => {
  return <span className='text-body fw-bold'>{cell?.row ? cell.row.index + 1 : ''}</span>
}

const DateCol = (cell) => {
  return cell.value ? moment(cell.value).format('DD-MM-YYYY') : '--'
}

const CreateUpdateDuration = (cell) => {
  return cell.value ? formateDate(cell.value.createdAt) + ' - ' + formateDate(cell.value.updatedAt) : '--'
}

const CreateDurationMoment = (cell) => {
  return cell.value ? moment(cell?.value.split('-')[1]).format('DD/MM/YYYY') : '--'
}

const ModelName = (cell) => {
  return cell?.value && cell?.value?.split('-')[1] !== 'null' && cell?.value?.split('-')[1] !== 'undefined'
    ? cell?.value?.split('-')[1]
    : '--'
}

const Serial = (cell) => {
  return cell?.value && cell?.value?.split('-')[1] !== 'null' && cell?.value?.split('-')[1] !== 'undefined'
    ? cell?.value?.split('-')[1]
    : '--'
}

const UserShopName = (cell) => {
  return cell?.value && cell?.value?.split('-')[1] !== 'null' && cell?.value?.split('-')[1] !== 'undefined'
    ? cell?.value?.split('-')[1]
    : '--'
}

const ProgramCode = (cell) => {
  return cell?.value && cell?.value?.split('-')[1] !== 'null' && cell?.value?.split('-')[1] !== 'undefined'
    ? cell?.value?.split('-')[1]
    : '--'
}
const ProgramReward = (cell) => {
  return cell?.value && cell?.value?.split('-')[1] !== 'null' && cell?.value?.split('-')[1] !== 'undefined'
    ? priceSplitter(cell?.value?.split('-')[1])
    : '--'
}

const Type = (cell) => {
  const type = [
    {
      label: 'Cửa hàng',
      value: 'user'
    },
    {
      label: 'Kĩ thuật viên',
      value: 'technician'
    }
  ]

  return cell?.value ? type.find((e) => e.value === cell?.value?.split('-')[1])?.label : '--'
}

const handleSelect = (value, key, prop) => {
  // const findIndex = listTable.findIndex((item) => key === item.key);
  // setFilter(e => ({ ...e, [key]: value }))
}

const ListCode = (cell) => {
  let concatenatedCodes = ''

  // return concatenatedCodes
  for (let i = 0; i < cell?.value?.length; i++) {
    // Concatenate the code of the current object to the string
    concatenatedCodes += cell?.value[i].code
    // Add a comma and space if it's not the last object
    if (i !== cell?.value?.length - 1) {
      concatenatedCodes += '<br />'
    }
  }

  const resultArr = []
  const arr = concatenatedCodes.split(/<br \/>/)
  arr.forEach((item, i) => {
    resultArr.push(<br />)
    resultArr.push(item)
  })
  return <p> {resultArr} </p>
}

const BonusModel = (cell) => {
  return cell.value ? cell?.value.split('-')[1] : '--'

  // return cell?.value ? type.find((e) => e.value === cell?.value.split('-')[1])?.label : '--'
}

const ListStartEndDate = (cell) => {
  let concatenatedDate = ''
  if (cell?.value?.programmingApplyTime == 'each_day') {
    for (let i = 0; i < cell?.value?.programmingApplyDates?.length; i++) {
      // Concatenate the code of the current object to the string
      concatenatedDate += cell?.value ? moment(cell?.value?.programmingApplyDates[i]).format('DD/MM/YYYY') : '--'
      // Add a comma and space if it's not the last object
      if (i !== cell?.value?.programmingApplyDates?.length - 1) {
        concatenatedDate += ', '
      }
    }
  } else {
    concatenatedDate = cell?.value
      ? moment(cell?.value?.programmingStartDate).format('DD/MM/YYYY') +
        ' - ' +
        moment(cell?.value?.programmingEndDate).format('DD/MM/YYYY')
      : '--'
  }

  return <p> {concatenatedDate} </p>
}

const ListReward = (cell) => {
  let concatenatedReward = ''

  for (let i = 0; i < cell?.value?.length; i++) {
    // Concatenate the code of the current object to the string
    concatenatedReward += priceSplitter(cell?.value[i].reward)

    // Add a comma and space if it's not the last object
    if (i !== cell?.value?.length - 1) {
      concatenatedReward += '<br />'
    }
  }

  const resultArr = []
  const arr = concatenatedReward.split(/<br \/>/)
  arr.forEach((item, i) => {
    resultArr.push(<br />)
    resultArr.push(item)
  })
  return <p> {resultArr} </p>
}

const SumBonus = (cell) => {
  return cell?.value && cell?.value?.split('-')[1] !== 'null' && cell?.value?.split('-')[1] !== 'undefined'
    ? priceSplitter(cell?.value?.split('-')[1])
    : '--'
}

const ListProgramingApply = (cell) => {
  const ListProgramingApply = cell?.value
  const list = []
  ListProgramingApply.map((item) => {
    list.push({
      label: item.name,
      value: item.id
    })
  })

  return (
    <FormSelect
      options={list}
      // value={filter.type}
      onChange={(value) => {
        handleSelect(value, 'type', cell)
      }}
    />
  )
}

const StringGrp = (cell) => {
  return cell.value ? cell?.value.split('-')[1] : '--'
}

const NumberGrp = (cell) => {
  return cell?.value ? priceSplitter(cell?.value.split('-')[1]) : '--'
}

const DateFormat = (cell) => {
  return cell?.value ? moment(cell?.value).format('DD-MM-YYYY') : '--'
}

const RewardDate = (cell) => {
  const rewardDate = new Date(cell?.value?.endDate)
  rewardDate.setDate(rewardDate?.getDate() + cell?.value?.finishTimeDisplayDocumentation)
  return rewardDate ? moment(rewardDate).format('DD-MM-YYYY') : '--'
}

const BaseString = (cell) => {
  return cell.value ? cell.value : '--'
}

const BaseMoney = (cell) => {
  return cell.value ? priceSplitter(cell.value) : '--'
}

const RewardStatus = (cell) => {
  switch (cell.value) {
    case 3:
      return <Badge className='badge-soft-danger'>{'Từ chối thưởng tự động'}</Badge>
    case 2:
      return <Badge className='badge-soft-danger'>{'Từ chối thưởng'}</Badge>
    case 1:
      return <Badge className='badge-soft-success'>{'Đã trả thưởng'}</Badge>
    case 0:
      return <Badge className='badge-soft-info'>{'Đã đối soát'}</Badge>
    // default:
    //   return <Badge className='badge-soft-info'>{'Chờ đối soát'}</Badge>
  }
}

export {
  BaseCol,
  NoCol,
  DateCol,
  TypeCol,
  CreateUpdateDuration,
  CreateDurationMoment,
  Type,
  ListCode,
  ListStartEndDate,
  ListReward,
  ListProgramingApply,
  ModelName,
  SumBonus,
  Serial,
  UserShopName,
  ProgramCode,
  ProgramReward,
  StringGrp,
  NumberGrp,
  DateFormat,
  BaseString,
  BaseMoney,
  RewardStatus,
  RewardDate
}
