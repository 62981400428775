import React, { useEffect, useMemo, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

//import components

import { getUserList, setTypePopupUser } from 'store/actions'

import { UserNo, Email, Status, UserID, AccountName, Part, PermissionGroup } from './ManageUserCol'

//redux
import { useSelector, useDispatch } from 'react-redux'
import { createSelector } from 'reselect'

import { Col, Row, UncontrolledTooltip, Card, CardBody } from 'reactstrap'
import Spinners from 'components/Common/Spinner'
import { toast } from 'react-toastify'
import TableContainer from 'components/Common/TableContainer'
import { UserVN } from 'constants/vn'
import ConfirmModal from 'components/Common/ConfirmModal'
import { lockAccountAPI, getRoleListAll } from 'helpers/backend_helper'
import { checkIsLocked, getStorage, setStorage } from 'helpers/global_helper'
import { ADMIN_ACCOUNT_MANAGEMENT_CODE, ROLE_MANAGEMENT_CODE, SCREEN_CODE } from 'constants/permission-code'
import usePermission from 'hooks/usePermission'
import PermissionWrapper from 'components/Common/PermissionWrapper'
import { Space } from 'antd'
import ManageAccumulationFilter from 'pages/ManageAccumulation/components/ManageAccumulationFilter'
import { TableRowSpan } from 'components/Common/TableCustom'
import ManageUserFilter from './components/ManageUserFilter'

function ManageUser() {
  //meta title
  document.title = 'User CMS List | Skote - React Admin & Dashboard Template'
  const screenCode = useMemo(() => SCREEN_CODE.ADMIN_ACCOUNT_MANAGEMENT, [])
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const selectUsersState = (state) => state.UserReducer
  const UsersProperties = createSelector(selectUsersState, (userReducer) => ({
    users: userReducer.users,
    loading: userReducer.loading,
    total: userReducer.total
  }))
  const hasPermission = usePermission({ screenCode, code: ADMIN_ACCOUNT_MANAGEMENT_CODE.ADMIN_DETAIL_ADMIN_ACCOUNT })

  const { users, loading, total } = useSelector(UsersProperties)

  const selectProfileState = (state) => state.Profile
  const ProfileProperties = createSelector(selectProfileState, (profile) => ({
    profile: profile.profile
  }))

  const { profile } = useSelector(ProfileProperties)

  const [isLoading, setLoading] = useState(loading)
  const [showLockAccount, setShowLockAccount] = useState(false)
  const [selected, setSelected] = useState()
  const [filter, setFilter] = useState()
  const [optionPermissionGroup, setOptionPermissionGroup] = useState([])
  const [page, setPage] = useState(1)
  const [pagination, setPagination] = useState({
    pageIndex: page,
    pageSize: 10,
    totalRows: total
  })

  useEffect(() => {
    const filterManageUser = getStorage('filterManageUser')
    dispatch(getUserList({ ...filterManageUser }))
  }, [dispatch])

  useEffect(() => {
    handleGetRoleListAll()
  }, [])

  const handleGetRoleListAll = async () => {
    try {
      const res = await getRoleListAll()

      if (res.data) {
        const list_option = []
        res.data.map((item) => {
          list_option.push({
            value: item.id,
            label: item.name
          })
        })

        setOptionPermissionGroup(list_option)
      }
    } catch (error) {
      toast(error.response.data.message, { type: 'error' })
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: UserVN.table.stt,
        accessor: 'extTransId',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return (page - 1) * 10 + cellProps.row.index + 1
        }
      },
      {
        Header: UserVN.table.id,
        accessor: 'employeeCode',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <UserID {...cellProps} />
        }
      },
      {
        Header: UserVN.table.name,
        accessor: 'name',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <AccountName {...cellProps} />
        }
      },
      {
        Header: UserVN.table.email,
        accessor: 'email',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <Email {...cellProps} />
        }
      },
      {
        Header: UserVN.table.part,
        accessor: 'treeName',
        disableFilters: true,
        hideSort: true,
        disableSortBy: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <Part {...cellProps} />
        }
      },
      {
        Header: UserVN.table.permissionGroup,
        accessor: 'role',
        disableFilters: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <PermissionGroup {...cellProps} />
        }
      },
      {
        Header: UserVN.table.status,
        accessor: 'status',
        disableFilters: true,
        hideSort: true,
        disableSortBy: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <Status {...cellProps} />
        }
      },
      {
        Header: UserVN.table.action,
        accessor: 'action',
        disableFilters: true,
        hideSort: true,
        disableSortBy: true,
        disableFilters: true,
        show: hasPermission,
        Cell: (cellProps) => {
          return (
            <ul className='list-unstyled hstack gap-1 mb-0'>
              <li data-bs-toggle='tooltip' data-bs-placement='top' title='View'>
                <Link
                  to={`/manage-user/view/${cellProps.row.original.id}`}
                  className='btn btn-sm btn-soft-primary'
                  id={`viewtooltip-${cellProps.row.original.id}`}
                >
                  <i className='mdi mdi-eye-outline' />
                  <UncontrolledTooltip
                    trigger='hover'
                    placement='top'
                    target={`viewtooltip-${cellProps.row.original.id}`}
                  >
                    Xem
                  </UncontrolledTooltip>
                </Link>
              </li>
              <PermissionWrapper
                screenCode={screenCode}
                code={ADMIN_ACCOUNT_MANAGEMENT_CODE.ADMIN_UPDATE_ADMIN_ACCOUNT}
              >
                {cellProps.row.original.status !== 'terminated' &&
                  cellProps.row.original.status !== 'pending-removal' && (
                    <li data-bs-toggle='tooltip' data-bs-placement='top' title='View'>
                      <Link
                        to={`/manage-user/edit/${cellProps.row.original.id}`}
                        className='btn btn-sm btn-soft-info'
                        id={`edittooltip-${cellProps.row.original.id}`}
                      >
                        <i className='mdi mdi-pencil-outline' />
                        <UncontrolledTooltip
                          trigger='hover'
                          placement='top'
                          target={`edittooltip-${cellProps.row.original.id}`}
                        >
                          Sửa
                        </UncontrolledTooltip>
                      </Link>
                    </li>
                  )}
              </PermissionWrapper>
            </ul>
          )
        }
      }
    ],
    [page, hasPermission]
  )

  const permissionColumns = useMemo(() => columns.filter((column) => column.show !== false), [columns])

  const setGlobalFilterUser = async (data) => {
    setStorage('filterManageUser', data)
    setFilter(data)
    dispatch(getUserList(data))
    setPage(1)
  }

  const onLockAccount = async () => {
    try {
      const res = await lockAccountAPI({
        id: selected.id,
        status: checkIsLocked(selected.status) ? 'active' : 'inactive'
      })
      if (res) {
        toast(res.message, { type: 'success' })
        setShowLockAccount(false)
      }
    } catch (error) {
      toast(error.response.data.message, { type: 'error' })
    }
  }

  const goToPage = (page) => {
    const data = { ...filter, page: page }
    dispatch(getUserList(data))
    setPage(page)
  }

  const handleResetFilter = () => {
    setFilter({ page: 1 })
    dispatch(getUserList({ page: 1 }))
    setPage(1)
  }

  return (
    <React.Fragment>
      <div className='page-content'>
        <div className='page-title-box container-fluid'>
          <h4 className=' mb-sm-0 font-size-18 pb-4'>{UserVN.title}</h4>
          {isLoading ? (
            <Spinners setLoading={setLoading} />
          ) : (
            <Row>
              <Col lg='12'>
                <Card>
                  <CardBody className='border-bottom'>
                    <div className='d-flex align-items-center'>
                      <h5 className='mb-0 card-title flex-grow-1'>{UserVN.list}</h5>
                      <div className='flex-shrink-0'>
                        <PermissionWrapper
                          screenCode={screenCode}
                          code={ADMIN_ACCOUNT_MANAGEMENT_CODE.ADMIN_CREATE_ADMIN_ACCOUNT}
                        >
                          <button
                            onClick={() => {
                              navigate('/manage-user/create')
                              dispatch(setTypePopupUser('create'))
                            }}
                            className='btn btn-primary'
                          >
                            <i className='mdi mdi-plus me-1' />
                            {UserVN.add}
                          </button>
                        </PermissionWrapper>
                      </div>
                    </div>
                  </CardBody>
                  <CardBody>
                    <Space size='middle' direction='vertical' className='w-100'>
                      <ManageUserFilter
                        handleFilter={setGlobalFilterUser}
                        optionPermissionGroup={optionPermissionGroup}
                        handleResetFilter={handleResetFilter}
                      />
                      <TableRowSpan
                        columns={columns}
                        data={users}
                        pagination={pagination}
                        setPagination={setPagination}
                        gotoPageCustom={goToPage}
                        currentPage={page}
                        total={total}
                        isShowTotal={true}
                      />
                    </Space>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </div>
      </div>
      <ConfirmModal
        show={showLockAccount}
        onConfirmClick={onLockAccount}
        onCloseClick={() => setShowLockAccount(false)}
        description={`Xác nhận khóa tài khoản bởi admin ${profile?.name ?? ''}`}
      />
    </React.Fragment>
  )
}

export default ManageUser
