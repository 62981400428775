export const GET_MANAGE_NOTIFICATION = 'GET_NOTIFICATIONLIST'
export const GET_MANAGE_NOTIFICATION_FAIL = 'GET_MANAGE_NOTIFICATION_FAIL'
export const GET_MANAGE_NOTIFICATION_SUCCESS = 'GET_MANAGE_NOTIFICATION_SUCCESS'

export const GET_NOTIFICATION_DETAIL = 'GET_NOTIFICATION_DETAIL'
export const GET_NOTIFICATION_DETAIL_SUCCESS = 'GET_NOTIFICATION_DETAIL_SUCCESS'
export const GET_NOTIFICATION_DETAIL_FAIL = 'GET_NOTIFICATION_DETAIL_FAIL'

export const UPDATE_MANAGE_NOTIFICATION = 'UPDATE_MANAGE_NOTIFICATION'
export const UPDATE_MANAGE_NOTIFICATION_SUCCESS = 'UPDATE_MANAGE_NOTIFICATION_SUCCESS'
export const UPDATE_MANAGE_NOTIFICATION_FAIL = 'UPDATE_MANAGE_NOTIFICATION_FAIL'

export const GET_USER_ACCOUNT = 'GET_USER_ACCOUNT'
