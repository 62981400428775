export const GET_MANAGE_ACCOUNT_CLASS = "GET_ACCOUNT_CLASS_LIST"
export const GET_MANAGE_ACCOUNT_CLASS_FAIL = "GET_MANAGE_ACCOUNT_CLASS_FAIL"
export const GET_MANAGE_ACCOUNT_CLASS_SUCCESS = "GET_MANAGE_ACCOUNT_CLASS_SUCCESS"

export const GET_ACCOUNT_CLASS_DETAIL = "GET_ACCOUNT_CLASS_DETAIL"
export const GET_ACCOUNT_CLASS_DETAIL_SUCCESS = "GET_ACCOUNT_CLASS_DETAIL_SUCCESS"
export const GET_ACCOUNT_CLASS_DETAIL_FAIL = "GET_ACCOUNT_CLASS_DETAIL_FAIL"

export const UPDATE_MANAGE_ACCOUNT_CLASS = "UPDATE_MANAGE_ACCOUNT_CLASS"
export const UPDATE_MANAGE_ACCOUNT_CLASS_SUCCESS = "UPDATE_MANAGE_ACCOUNT_CLASS_SUCCESS"
export const UPDATE_MANAGE_ACCOUNT_CLASS_FAIL = "UPDATE_MANAGE_ACCOUNT_CLASS_FAIL"

export const SET_TYPE_POPUP = "SET_TYPE_POPUP_ACCOUNT_CLASS"
