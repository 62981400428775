import React from 'react'
import { InputNumber } from 'antd'

export const FormInputNumberCurrency = ({ ...props }) => {
  const { disabled, style, ...rest } = props
  // const [field, meta, helpers] = useField({ name })

  return (
    <InputNumber
      formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
      parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
      placeholder='Chọn'
      style={{ width: '100%', ...style }}
      disabled={disabled}
      size='large'
      {...rest}
    />
  )
}
