import {
    GET_MANAGE_USER,
    GET_MANAGE_USER_FAIL,
    GET_MANAGE_USER_SUCCESS,
    GET_USER_DETAIL,
    GET_USER_DETAIL_SUCCESS,
    GET_USER_DETAIL_FAIL,
    SET_TYPE_POPUP,
} from "./actionTypes"

export const getUserList = params => ({
    type: GET_MANAGE_USER,
    payload: params,
})

export const getManageUserSuccess = data => ({
    type: GET_MANAGE_USER_SUCCESS,
    payload: data,
})

export const getManageUserFail = error => ({
    type: GET_MANAGE_USER_FAIL,
    payload: error,
})

export const getUserDetail = data => ({
    type: GET_USER_DETAIL,
    payload: data,
})

export const getUserDetailSuccess = data => ({
    type: GET_USER_DETAIL_SUCCESS,
    payload: data,
})

export const getUserDetailFail = error => ({
    type: GET_USER_DETAIL_FAIL,
    payload: error,
})

export const setTypePopupUser = params => ({
    type: SET_TYPE_POPUP,
    payload: params,
})
