import React from 'react'
import { Result } from 'antd'
import { getLayout } from 'App'
import VerticalLayout from 'components/VerticalLayout'

export default function NotFound() {
  return (
    <VerticalLayout>
      <div style={{ marginTop: '100px' }}>
        <Result status='404' title='404' subTitle='Sorry, the page you visited does not exist.' />
      </div>
    </VerticalLayout>
  )
}
