import { call, put, takeEvery } from "redux-saga/effects"

import { GET_MANAGE_USER, GET_USER_DETAIL } from "./actionTypes"
import { getManageUserSuccess, getManageUserFail, getUserDetailSuccess } from "./actions"

import { getUserListAPI, getUserDetailsAPI } from "helpers/backend_helper"

function* fetchUserList({ payload: params }) {
    try {
        const response = yield call(getUserListAPI, { ...params })
        yield put(getManageUserSuccess(response))
    } catch (error) {
        yield put(getManageUserFail(error?.data?.message))
    }
}

function* fetchUserDetail({ payload: data }) {
    try {
        const response = yield call(getUserDetailsAPI, { id: data.id, type: data.type })
        yield put(getUserDetailSuccess(response.data))
    } catch (error) {
        console.log(error)
        // yield put(getProductDetailFail(error))
    }
}

function* UsersSaga() {
    yield takeEvery(GET_MANAGE_USER, fetchUserList)
    yield takeEvery(GET_USER_DETAIL, fetchUserDetail)
}

export default UsersSaga
