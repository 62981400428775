export const GET_MANAGE_PROGRAM = "GET_PROGRAMLIST"
export const GET_MANAGE_PROGRAM_FAIL = "GET_MANAGE_PROGRAM_FAIL"
export const GET_MANAGE_PROGRAM_SUCCESS = "GET_MANAGE_PROGRAM_SUCCESS"

export const GET_PROGRAM_DETAIL = "GET_PROGRAM_DETAIL"
export const GET_PROGRAM_DETAIL_SUCCESS = "GET_PROGRAM_DETAIL_SUCCESS"
export const GET_PROGRAM_DETAIL_FAIL = "GET_PROGRAM_DETAIL_FAIL"

export const UPDATE_MANAGE_PROGRAM = "UPDATE_MANAGE_PROGRAM"
export const UPDATE_MANAGE_PROGRAM_SUCCESS = "UPDATE_MANAGE_PROGRAM_SUCCESS"
export const UPDATE_MANAGE_PROGRAM_FAIL = "UPDATE_MANAGE_PROGRAM_FAIL"
