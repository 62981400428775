import { CKEditor } from '@ckeditor/ckeditor5-react'
import React, { useState, useEffect } from 'react'
import Editor from 'ckeditor5-custom-build/build/ckeditor'
const EditorCustom = ({ data, setData, disabled = false }) => {
  const [content, setContent] = useState('')

  useEffect(() => {
    if (data) {
      setContent(data)
    }
  }, [data])

  const editorConfiguration = {
    toolbar: [
      'fontFamily',
      'fontSize',
      'fontColor',
      'bold',
      'italic',
      'link',
      '|',
      'alignment',
      'bulletedList',
      'numberedList',
      'outdent',
      'indent',
      '|',
      'imageInsert',
      'imageUrl',
      'insertTable',
      'undo',
      'redo'
    ],
    image: {
      insert: {
        // This is the default configuration, you do not need to provide
        // this configuration key if the list content and order reflects your needs.
        integrations: ['url']
      }
    },
    fontSize: {
      options: [9, 11, 13, 15, 17, 19, 21, 23, 25, 27]
    }
  }

  return (
    <CKEditor
      disabled={disabled}
      editor={Editor}
      config={editorConfiguration}
      data={content}
      onChange={(event, editor) => {
        const text = editor.getData()
        setData(text)
      }}
    />
  )
}

export default EditorCustom
