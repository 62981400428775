import { PortfolioVN } from "constants/vn"
import React, { useEffect, useMemo, useState } from "react"
import { Col, Label, Modal, ModalBody, Row } from "reactstrap"
import { MAX_LENGTH_NAME_PORTFOLIO, TypePortfolioOptions } from "constants/constants"
import { createSelector } from "reselect"
import { useSelector } from "react-redux"
import { filterDataHaveValue, getParentId } from "helpers/global_helper"
import { getPortfolioList, setTypePopup } from "store/actions"
import { getPortfolioAllAPI, postPortfolioAPI, updatePortfolioAPI } from "helpers/backend_helper"
import { useDispatch } from "react-redux"
import { toast } from "react-toastify"
import { FormSelect } from "components/Common/form-select/FormSelect"
import { Checkbox, Form, Switch } from "antd"
import { FormInput } from "components/Common/form-input/FormInput"
import { isEqual } from "lodash"
import ConfirmModal from "components/Common/ConfirmModal"

const AddEditPortfolio = ({ show, onCloseClick, selected, setPage }) => {
    const dispatch = useDispatch()

    const selectPortfoliosState = state => state.PortfolioReducer
    const PortfoliosProperties = createSelector(selectPortfoliosState, reducer => ({
        type: reducer.type,
        portfolioDetail: reducer.portfolioDetail,
    }))
    const { type, portfolioDetail } = useSelector(PortfoliosProperties)

    const selectLayoutState = state => state.Layout
    const LayoutProperties = createSelector(selectLayoutState, layout => ({
        filter: layout.filter,
    }))
    const { filter } = useSelector(LayoutProperties)

    const isEdit = type !== "view"
    const [form] = Form.useForm()
    const watchType = Form.useWatch("type", form)
    const watchForm = Form.useWatch([], form)
    const [submittable, setSubmittable] = useState()
    const [industry, setIndustry] = useState()
    const [industrySector, setIndustrysector] = useState()
    const [category, setCategory] = useState()
    const [initData, setInitData] = useState({})
    const [onCancel, setOnCancel] = useState(false)

    const data = useMemo(() => {
        if (type !== "create") {
            return portfolioDetail
        } else {
            return undefined
        }
    }, [type, portfolioDetail])

    const mapsData = data => {
        return filterDataHaveValue({
            type: data.type,
            name: data.name,
            industry: data?.industryId ?? undefined,
            industrySector: data?.industrySectorId ?? undefined,
            category: data?.categoryId ?? undefined,
            isAccumulation: data?.isAccumulation ?? undefined,
            isActivated: data?.isActivated ?? undefined,
            requireActivationCode: data?.requireActivationCode ?? undefined,
        })
    }
    const getDataOptions = async () => {
        try {
            const resIndustry = await getPortfolioAllAPI({ type: "industry" })
            const resIndustrysector = await getPortfolioAllAPI({ type: "industrySector" })
            const resCategory = await getPortfolioAllAPI({ type: "category" })
            setIndustry(resIndustry?.data)
            setIndustrysector(resIndustrysector?.data)
            setCategory(resCategory?.data)
        } catch (error) {
            console.log(error)
            // toast(error.response.data.message, { type: "error" })
        }
    }

    useEffect(() => {
        if (show) {
            getDataOptions()
        }
    }, [show])

    useEffect(() => {
        if (data) {
            form.setFieldsValue(mapsData(data))
            setInitData(mapsData(data))
        } else {
            form.resetFields()
        }
    }, [data])

    useEffect(() => {
        form.resetFields()
    }, [show])

    const onFinish = async values => {
        if (type !== "view") {
            try {
                if (type === "edit") {
                    const res = await updatePortfolioAPI({
                        name: values.name,
                        requireActivationCode: values.requireActivationCode,
                        isAccumulation: values.isAccumulation,
                        isActivated: values.isActivated,
                        parentId: getParentId(values),
                        id: selected.id,
                        type: selected.type,
                    })
                    if (res) {
                        toast("Danh mục sản phẩm được cập nhật thành công", { type: "success" })
                        dispatch(getPortfolioList({ ...filter }))
                    }
                }
                if (type === "create") {
                    const res = await postPortfolioAPI({
                        name: values.name,
                        type: values.type,
                        requireActivationCode: values.requireActivationCode,
                        isAccumulation: values.isAccumulation,
                        isActivated: values.isActivated,
                        parentId: getParentId(values),
                    })
                    if (res) {
                        toast("Danh mục sản phẩm được tạo thành công", { type: "success" })
                        dispatch(getPortfolioList())
                        setPage(1)
                    }
                }
                onCloseClick()
            } catch (error) {
                toast(error.response.data.message, { type: "error" })
            }
        } else {
            dispatch(setTypePopup("edit"))
        }
    }

    useEffect(() => {
        form.validateFields({ validateOnly: true }).then(
            () => {
                setSubmittable(true)
            },
            () => {
                setSubmittable(false)
            }
        )
    }, [watchForm])

    const onClickCancel = () => {
        setOnCancel(false)
        onCloseClick()
    }

    const checkClickCancel = () => {
        if (type != "view") {
            if (type == "create") {
                if (form.isFieldsTouched()) {
                    setOnCancel(true)
                } else {
                    onCloseClick()
                }
            } else {
                if (!isEqual(initData, watchForm)) {
                    setOnCancel(true)
                } else {
                    onCloseClick()
                }
            }
        } else {
            onCloseClick()
        }
    }

    return (
        <Modal
            size="lg"
            isOpen={show}
            toggle={() => {
                checkClickCancel()
            }}
            centered={true}
        >
            <div className="modal-content">
                <ModalBody className="p-4 text-start">
                    <button type="button" onClick={checkClickCancel} className="btn-close position-absolute end-0 top-0 m-3" />
                    <h2>{type === "create" ? PortfolioVN.addPortfolio : type === "edit" ? PortfolioVN.editPortfolio : PortfolioVN.viewPortfolio}</h2>
                    <Form onFinish={onFinish} autoComplete="off" form={form} name="addEditPortfolioForm">
                        <div className="form-group mt-4">
                            <Row>
                                <Col sm="6">
                                    <Label className="form-label">
                                        {PortfolioVN.form.type.label}
                                        <a className="text-danger">*</a>
                                    </Label>
                                    <Form.Item name="type" rules={[{ required: true, message: "Vui lòng chọn loại danh mục" }]}>
                                        <FormSelect
                                            options={TypePortfolioOptions.map(item => ({
                                                value: item.value,
                                                label: item.label,
                                            }))}
                                            onChange={() => {
                                                form.setFieldValue("industry", undefined)
                                                form.setFieldValue("industrySector", undefined)
                                                form.setFieldValue("category", undefined)
                                            }}
                                            placeholder="Chọn loại danh mục"
                                            disabled={type != "create"}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col sm="6">
                                    <Label className="form-label">
                                        {PortfolioVN.form.name.label}
                                        <a className="text-danger">*</a>
                                    </Label>
                                    <Form.Item name="name" rules={[{ required: true, message: "Vui lòng nhập tên danh mục" }]}>
                                        <FormInput
                                            placeholder={PortfolioVN.form.name.placeholder}
                                            maxLength={MAX_LENGTH_NAME_PORTFOLIO}
                                            disabled={!isEdit}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            {watchType !== "industry" && watchType !== undefined && (
                                <Row>
                                    <Col sm="6">
                                        <Label className="form-label">
                                            {PortfolioVN.form.branch.label}
                                            <a className="text-danger">*</a>
                                        </Label>
                                        <Form.Item name="industry" rules={[{ required: true, message: "Vui lòng chọn ngành" }]}>
                                            <FormSelect
                                                options={industry?.map(item => ({
                                                    value: item.id,
                                                    label: item.name,
                                                }))}
                                                onChange={() => {
                                                    form.setFieldValue("industrySector", undefined)
                                                    form.setFieldValue("category", undefined)
                                                }}
                                                placeholder="Chọn Ngành"
                                                disabled={!isEdit}
                                            />
                                        </Form.Item>
                                    </Col>
                                    {watchType && watchType !== "industrySector" && (
                                        <Col sm="6">
                                            <Label className="form-label">
                                                {PortfolioVN.form.industry.label}
                                                <a className="text-danger">*</a>
                                            </Label>
                                            <Form.Item name="industrySector" rules={[{ required: true, message: "Vui lòng chọn ngành hàng" }]}>
                                                <FormSelect
                                                    options={industrySector
                                                        ?.filter(is => is.parentId == watchForm.industry)
                                                        ?.map(item => ({
                                                            value: item.id,
                                                            label: item.name,
                                                        }))}
                                                    onChange={() => {
                                                        form.setFieldValue("category", undefined)
                                                    }}
                                                    placeholder="Chọn Ngành hàng"
                                                    disabled={!isEdit || !watchForm.industry}
                                                />
                                            </Form.Item>
                                        </Col>
                                    )}
                                </Row>
                            )}
                            {watchType === "model" && (
                                <Row>
                                    <Col sm="6">
                                        <Label className="form-label">
                                            {PortfolioVN.form.group.label}
                                            <a className="text-danger">*</a>
                                        </Label>
                                        <Form.Item name="category" rules={[{ required: true, message: "Vui lòng chọn nhóm sản phẩm" }]}>
                                            <FormSelect
                                                options={category
                                                    ?.filter(c => c.parentId == watchForm.industrySector)
                                                    ?.map(item => ({
                                                        value: item.id,
                                                        label: item.name,
                                                    }))}
                                                placeholder="Chọn nhóm sản phẩm"
                                                disabled={!isEdit || !watchForm.industrySector}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col sm="2">
                                        <Label className="form-label">{PortfolioVN.form.code.label}</Label>
                                        <Form.Item name="requireActivationCode">
                                            <Switch defaultChecked={false} disabled={!isEdit} />
                                        </Form.Item>
                                    </Col>
                                    <Col sm="4 ">
                                        <Label className="form-label">{PortfolioVN.form.reward}</Label>
                                        <Row>
                                            <Col sm="6">
                                                <Form.Item name="isAccumulation" valuePropName="checked">
                                                    <Checkbox className="fw-normal" disabled={!isEdit}>
                                                        {PortfolioVN.form.accumulate.label}
                                                    </Checkbox>
                                                </Form.Item>
                                            </Col>
                                            <Col sm="6">
                                                <Form.Item name="isActivated" valuePropName="checked">
                                                    <Checkbox disabled={!isEdit} className="fw-normal">
                                                        {PortfolioVN.form.actived.label}
                                                    </Checkbox>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            )}
                        </div>
                        <div className="hstack gap-2 justify-content-center mt-4">
                            <button type="button" className="btn btn-soft-secondary w-25 " onClick={checkClickCancel}>
                                Hủy
                            </button>
                            <button
                                disabled={!!data?.extTransId || (type !== "view" && (!submittable || isEqual(initData, watchForm)))}
                                type={"submit"}
                                className="btn btn-primary w-25"
                                // onClick={() => {
                                //     console.log("hoang")
                                //     if (!isEdit) {
                                //         dispatch(setTypePopup("edit"))
                                //     }
                                // }}
                            >
                                {isEdit ? "Lưu" : "Chỉnh sửa"}
                            </button>
                        </div>
                    </Form>
                </ModalBody>
                <ConfirmModal
                    show={onCancel}
                    onConfirmClick={onClickCancel}
                    onCloseClick={() => setOnCancel(false)}
                    title={`Xác nhận`}
                    description={`Thay đổi sẽ không được lưu. Bạn có chắc chắn muốn thoát không?`}
                />
            </div>
        </Modal>
    )
}
export default AddEditPortfolio
