import { call, put, takeEvery } from 'redux-saga/effects'

// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from './actionTypes'
import { apiError, lockAccount, loginSuccess } from './actions'

//Include Both Helper File with needed methods
import { postJwtLogin, postJwtLogout } from '../../../helpers/backend_helper'
import { parseCookies, removeCookieUtil, setCookieUtil } from 'helpers/global_helper'
import { COOKIE_KEYS_REFRESH_TOKEN, COOKIE_KEYS_TOKEN } from 'constants/constants'

function* loginUser({ payload: { user, history } }) {
  try {
    const response = yield call(postJwtLogin, {
      username: user.username,
      password: user.password,
      remember_me: user.remember
    })
    console.log('remember login', user, response)

    setCookieUtil(COOKIE_KEYS_TOKEN, response.data.token)
    setCookieUtil(COOKIE_KEYS_REFRESH_TOKEN, response.data.refreshToken)
    // localStorage.setItem("authUser", JSON.stringify(response))
    yield put(loginSuccess(response.data))

    history('/')
  } catch (error) {
    if (error?.response?.data?.reason === 'inactive') {
      yield put(lockAccount(true))
    } else {
      yield put(apiError(error.response.data.message))
    }
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    const accessToken = parseCookies(null)[COOKIE_KEYS_TOKEN]
    removeCookieUtil(COOKIE_KEYS_TOKEN)
    removeCookieUtil(COOKIE_KEYS_REFRESH_TOKEN)
    history('/login')
    yield call(postJwtLogout, {
      token: accessToken
    })
  } catch (error) {
    yield put(apiError(error.response.data.message))
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeEvery(LOGOUT_USER, logoutUser)
}

export default authSaga
