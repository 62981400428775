import React from 'react'
import { InputNumber } from 'antd'

export const FormInputNumber = ({ ...props }) => {
  const { disabled, style, ...rest } = props
  // const [field, meta, helpers] = useField({ name })

  return (
    <InputNumber placeholder='Chọn' style={{ width: '100%', ...style }} disabled={disabled} size='large' {...rest} />
  )
}
