import { PermissionVN } from "constants/vn"
import React, { useEffect, useState } from "react"
import { Col, Label, Modal, ModalBody, Row } from "reactstrap"
import { createSelector } from "reselect"
import { useSelector } from "react-redux"
import { getPermissionList, getRoleAll, setTypePopupPermission } from "store/actions"
import { postCreatePermissionAPI, updatePermissionAPI } from "helpers/backend_helper"
import { useDispatch } from "react-redux"
import { toast } from "react-toastify"
import { Form, Tree, Switch, Divider, Input, Spin } from "antd"
import { FormInput } from "components/Common/form-input/FormInput"
const { TextArea } = Input;
import ConfirmModal from "components/Common/ConfirmModal"
import { isEqual } from "lodash"

const AddEditPermission = ({ show, onCloseClick, selected, valueFilter, setPage }) => {
    const dispatch = useDispatch()

    const selectPermissionsState = state => state.PermissionReducer
    const PermissionsProperties = createSelector(selectPermissionsState, reducer => ({
        type: reducer.type,
        permissionDetail: reducer.permissionDetail,
        roles: reducer.roles
    }))
    const { type, permissionDetail, roles } = useSelector(PermissionsProperties)

    const isEdit = type !== "view"
    const [form] = Form.useForm()
    const watchForm = Form.useWatch([], form)

    const [checkedKeys, setCheckedKeys] = useState();
    const [checkedPermission, setCheckedPermission] = useState([]);
    const [autoExpandParent, setAutoExpandParent] = useState(true);
    const [treeData, setTreeDate] = useState()
    const [errTree, setErrTree] = useState(false)
    const [first, setFirst] = useState(true)
    const [switchCheck, setSwitchCheck] = useState(true)
    const [disabledSubmit, setDisabledSubmit] = useState(true)
    const [loading, setLoading] = useState(false)
    const [onCancel, setOnCancel] = useState(false)
    const [initData, setInitData] = useState()

    useEffect(() => {
        dispatch(getRoleAll())
    }, [])

    useEffect(() => {
        if (roles) {
            handleMapData()
        }
    }, [roles])

    useEffect(() => {
        if (!first) {
            handleCheckValidateTree()
        }
    }, [checkedPermission])

    useEffect(() => {
        if (type != "create" && permissionDetail && treeData) {
            form.setFieldsValue({ name: permissionDetail.name, description: permissionDetail.description })
            setSwitchCheck(permissionDetail.allowDuplicateArea)

            let selectedIds = [];

            permissionDetail.permissions.forEach(item => {
                selectedIds = selectedIds.concat(findSelectedIds(item));
            });

            setCheckedPermission(selectedIds)

            let keysWithSelectedPermissionIds = [];

            treeData.forEach(item => {
                keysWithSelectedPermissionIds = keysWithSelectedPermissionIds.concat(findKeysByPermissionIds(item, selectedIds));
            });

            setCheckedKeys(keysWithSelectedPermissionIds)

            setInitData({ name: permissionDetail.name, description: permissionDetail.description, allow_duplicate_area: permissionDetail.allowDuplicateArea, permission: selectedIds })
        }
    }, [permissionDetail, treeData])

    useEffect(() => {
        form.validateFields({ validateOnly: true }).then(
            () => {
                setDisabledSubmit(false)
            },
            () => {
                setDisabledSubmit(true)
            }
        )
    }, [watchForm])

    const handleMapData = () => {
        const new_list = transformData(roles)
        setTreeDate(new_list)
    }

    const transformData = (originalData, parentKey = null) => {
        return originalData.map(item => {
            const transformedItem = {
                title: item.name,
                key: item.id,
                value: item.id,
                id: item.id,
                children: [],
                parentId: item.parentId || null
            };

            if (item.subScreen && item.subScreen.length > 0) {
                transformedItem.children = transformData(item.subScreen, item.id);
            }

            if (item.permission && item.permission.length > 0) {
                transformedItem.children = transformedItem.children.concat(
                    item.permission.map(permission => ({
                        title: permission.name,
                        key: `${item.id}-${permission.id}`,
                        value: `${item.id}-${permission.id}`,
                        id: `${item.id}-${permission.id}`,
                        screenId: permission.screenId || null,
                        permission_id: permission.id
                    }))
                );
            }

            // Add a key for third-level children based on the id of the first item
            transformedItem.children.forEach((child, index) => {
                child.key = parentKey ? `${parentKey}-${child.id}` : `${child.id}-${index}`;
            });

            return transformedItem;
        });
    }

    const onCheck = (checkedKeysValue, checkedNodesValue) => {
        const list_nodes = []
        checkedNodesValue.checkedNodes.map((item) => {
            if (item.permission_id) {
                list_nodes.push(item.permission_id)
            }
        })
        setFirst(false)
        setCheckedPermission(list_nodes)
        setCheckedKeys(checkedKeysValue);
        setErrTree(false)
    };

    const onFinish = async values => {
        if (type == "view") {
            dispatch(setTypePopupPermission("edit"))
            return;
        }
        setLoading(true)
        try {
            const payload = {
                name: values.name,
                description: values.description,
                allow_duplicate_area: switchCheck,
                permission: checkedPermission,
                screen: [],
                sub_screen: [],
            }

            if (type == "create") {
                const res = await postCreatePermissionAPI({ ...payload })

                if (res) {
                    toast("Tạo thành công nhóm quyền", { type: "success" })
                    onCloseClick()
                    dispatch(getPermissionList())
                    setPage(1)
                }
            } else {
                const res = await updatePermissionAPI({ ...payload, id: permissionDetail.id })

                if (res) {
                    toast('Nhóm quyền được cập nhật thành công.', { type: "success" })
                    onCloseClick()
                    dispatch(getPermissionList({ search: valueFilter }))
                }
            }
        } catch (error) {
            toast(error.response.data.message, { type: "error" })
        }
        setLoading(false)
    }

    const handleCheckValidateTree = () => {
        if (type != "view") {
            if (checkedPermission.length != 0) {
                setErrTree(false)
            } else {
                setErrTree(true)
            }
        }
    }

    function findSelectedIds(item) {
        let selectedIds = [];

        if (item.permission && item.permission.length > 0) {
            item.permission.forEach(permission => {
                if (permission.selected === true) {
                    selectedIds.push(permission.id);
                }
            });
        }

        if (item.subScreen && item.subScreen.length > 0) {
            item.subScreen.forEach(subItem => {
                selectedIds = selectedIds.concat(findSelectedIds(subItem));
            });
        }

        return selectedIds;
    }

    function findKeysByPermissionIds(item, selectedIds) {
        let keys = [];

        if (selectedIds.includes(item.permission_id)) {
            keys.push(item.key);
        }

        if (item.children && item.children.length > 0) {
            item.children.forEach(childItem => {
                keys = keys.concat(findKeysByPermissionIds(childItem, selectedIds));
            });
        }

        return keys;
    }

    const onClickCancel = () => {
        setOnCancel(false)
        onCloseClick()
    }

    const checkClickCancel = () => {
        if (type != "view") {
            if (type == "create") {
                if (form.isFieldsTouched()) {
                    setOnCancel(true)
                } else {
                    onCloseClick()
                }
            } else {
                if (!isEqual(initData, { name: watchForm.name, description: watchForm.description, allow_duplicate_area: switchCheck, permission: checkedPermission })) {
                    setOnCancel(true)
                } else {
                    onCloseClick()
                }
            }
        } else {
            onCloseClick()
        }
    }

    return (
        <Modal
            size="lg"
            isOpen={show}
            toggle={() => {
                checkClickCancel()
            }}
            centered={true}
        >
            <div className="modal-content">
                <ModalBody className="p-4 text-start">
                    <Spin spinning={loading}>
                        <button type="button" onClick={checkClickCancel} className="btn-close position-absolute end-0 top-0" />
                        {type == "view" && <h2>{PermissionVN.viewPermission}</h2>}
                        {type == "create" && <h2>{PermissionVN.addPermission}</h2>}
                        {type == "edit" && <h2>{PermissionVN.editPermission}</h2>}
                        <Form layout="vertical" onFinish={onFinish} form={form}>
                            <div className="form-group">
                                <Label className="form-label">
                                    <a className="text-danger">*</a>
                                    {PermissionVN.form.name.label}
                                </Label>
                                <Form.Item
                                    name={PermissionVN.form.name.name}
                                    rules={[{ required: true, message: PermissionVN.form.name.required }]}
                                >
                                    <Input placeholder={PermissionVN.form.name.placeholder} disabled={!isEdit} size="large" maxLength={250} onBlur={(e) => form.setFieldValue(PermissionVN.form.name.name, e.target.value.trim())} />
                                </Form.Item>
                                <Form.Item
                                    name={PermissionVN.form.description.name}
                                    label={PermissionVN.form.description.label}
                                >
                                    {/* <Input placeholder={PermissionVN.form.description.placeholder} disabled={!isEdit} size="large" maxLength={500} onBlur={(e) => form.setFieldValue(PermissionVN.form.description.name, e.target.value.trim())} /> */}
                                    <TextArea
                                        placeholder={PermissionVN.form.description.placeholder} 
                                        disabled={!isEdit}
                                        maxLength={500}
                                        onBlur={(e) => form.setFieldValue(PermissionVN.form.description.name, e.target.value.trim())}
                                        autoSize={{ minRows: 3, maxRows: 5 }}
                                    />
                                </Form.Item>
                                <Label className="form-label">
                                    <a className="text-danger">*</a>
                                    {PermissionVN.form.functionPermission.label}
                                </Label>
                                <Form.Item
                                    name={PermissionVN.form.functionPermission.name}
                                >
                                    <Tree
                                        disabled={!isEdit}
                                        height={250}
                                        checkable
                                        autoExpandParent={autoExpandParent}
                                        onCheck={onCheck}
                                        checkedKeys={checkedKeys}
                                        treeData={treeData}
                                    />
                                    {errTree && <div className="ant-form-item-explain-error">{PermissionVN.form.functionPermission.required}</div>}
                                </Form.Item>
                                <Divider />
                                <Form.Item
                                    name={PermissionVN.form.allow_duplicate_area.name}
                                >
                                    <Row>
                                        <Col>
                                            <Label className="form-label">
                                                {PermissionVN.form.allow_duplicate_area.label}
                                            </Label>
                                        </Col>
                                        <Col className="d-flex justify-content-end">
                                            <Switch disabled={!isEdit} checked={switchCheck} onChange={(e) => setSwitchCheck(e)} />
                                        </Col>
                                    </Row>
                                </Form.Item>
                            </div>
                            <div className="hstack gap-2 justify-content-center mt-4">
                                <button type="button" className="btn btn-soft-secondary w-25 " onClick={checkClickCancel}>
                                    Hủy
                                </button>
                                <button
                                    type={"submit"}
                                    className="btn btn-primary w-25"
                                    disabled={isEdit ? (checkedPermission.length == 0 || disabledSubmit || isEqual(initData, { name: watchForm.name, description: watchForm.description, allow_duplicate_area: switchCheck, permission: checkedPermission })) : false}
                                >
                                    {isEdit ? "Lưu" : "Chỉnh sửa"}
                                </button>
                            </div>
                        </Form>
                    </Spin>
                </ModalBody>
                <ConfirmModal
                    show={onCancel}
                    onConfirmClick={onClickCancel}
                    onCloseClick={() => setOnCancel(false)}
                    title={`Xác nhận`}
                    description={`Thay đổi sẽ không được lưu. Bạn có chắc chắn muốn thoát không?`}
                />
            </div >
        </Modal >
    )
}
export default AddEditPermission
