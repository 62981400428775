import { call, put, takeEvery } from 'redux-saga/effects'

import { GET_MANAGE_UNIT, GET_UNIT_DETAIL, GET_DEPARTMENT_ALL } from './actionTypes'
import {
  getManageUnitSuccess,
  getManageUnitFail,
  getUnitDetailSuccess,
  getDepartmentAllSuccess,
  getDepartmentAllFail
} from './actions'

import { getUnitListAPI, getUnitDetailsAPI, getDepartmentListAPI } from 'helpers/backend_helper'

function* fetchUnitList({ payload: params }) {
  try {
    const response = yield call(getUnitListAPI, { ...params })
    yield put(getManageUnitSuccess(response))
  } catch (error) {
    yield put(getManageUnitFail(error?.data?.message))
  }
}

function* fetchDepartmentList({ payload: params }) {
  try {
    const response = yield call(getDepartmentListAPI, { ...params })
    yield put(getDepartmentAllSuccess(response))
  } catch (error) {
    yield put(getDepartmentAllFail(error?.data?.message))
  }
}

function* fetchUnitDetail({ payload: data }) {
  try {
    const response = yield call(getUnitDetailsAPI, { id: data.id, type: data.type })
    yield put(getUnitDetailSuccess(response.data))
  } catch (error) {
    console.log(error)
    // yield put(getProductDetailFail(error))
  }
}

function* UnitsSaga() {
  yield takeEvery(GET_MANAGE_UNIT, fetchUnitList)
  yield takeEvery(GET_UNIT_DETAIL, fetchUnitDetail)
  yield takeEvery(GET_DEPARTMENT_ALL, fetchDepartmentList)
}

export default UnitsSaga
