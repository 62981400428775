import { call, put, takeEvery } from "redux-saga/effects"

import { GET_MANAGE_PORTFOLIO, GET_PORTFOLIO_DETAIL, GET_KTVS } from "./actionTypes"
import { getManagePortfolioSuccess, getManagePortfolioFail, getPortfolioDetailSuccess } from "./actions"

import { getPortfolioListAPI, getPortfolioDetailsAPI } from "helpers/backend_helper"

function* fetchPortfolioList({ payload: params }) {
    try {
        const response = yield call(getPortfolioListAPI, { ...params })
        yield put(getManagePortfolioSuccess(response))
    } catch (error) {
        yield put(getManagePortfolioFail(error?.data?.message))
    }
}

function* fetchPortfolioDetail({ payload: data }) {
    try {
        const response = yield call(getPortfolioDetailsAPI, { id: data.id, type: data.type })
        yield put(getPortfolioDetailSuccess(response.data))
    } catch (error) {
        console.log(error)
        // yield put(getPortfolioDetailFail(error))
    }
}

function* PortfoliosSaga() {
    yield takeEvery(GET_MANAGE_PORTFOLIO, fetchPortfolioList)
    yield takeEvery(GET_PORTFOLIO_DETAIL, fetchPortfolioDetail)
}

export default PortfoliosSaga
