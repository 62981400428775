import { call, put, takeEvery } from "redux-saga/effects"

import { GET_MANAGE_PROGRAM, GET_PROGRAM_DETAIL } from "./actionTypes"
import { getManageProgramSuccess, getManageProgramFail, getProgramDetailSuccess } from "./actions"

import { getProgramDetailsAPI, getProgramListAPI } from "helpers/backend_helper"

function* fetchProgramsList({ payload: params }) {
    try {
        const response = yield call(getProgramListAPI, params)
        yield put(getManageProgramSuccess(response))
    } catch (error) {
        console.log(error)
        yield put(getManageProgramFail(error?.data?.message))
    }
}

function* fetchProgramDetail({ payload: data }) {
    try {
        const response = yield call(getProgramDetailsAPI, data)
        yield put(getProgramDetailSuccess(response.data))
    } catch (error) {
        console.log(error)
    }
}

function* ProgramsSaga() {
    yield takeEvery(GET_MANAGE_PROGRAM, fetchProgramsList)
    yield takeEvery(GET_PROGRAM_DETAIL, fetchProgramDetail)
}

export default ProgramsSaga
