import {
    GET_MANAGE_BANER,
    GET_MANAGE_BANER_FAIL,
    GET_MANAGE_BANER_SUCCESS,
    GET_BANER_DETAIL,
    GET_BANER_DETAIL_SUCCESS,
    GET_BANER_DETAIL_FAIL,
    GET_KTVS,
} from "./actionTypes"

export const getManageBaner = params => ({
    type: GET_MANAGE_BANER,
    payload: params,
})

// export const getKTVList = params => ({
//     type: GET_KTVS,
//     payload: params,
// })

export const getManageBanerSuccess = jobs => ({
    type: GET_MANAGE_BANER_SUCCESS,
    payload: jobs,
})

export const getManageBanerFail = error => ({
    type: GET_MANAGE_BANER_FAIL,
    payload: error,
})

export const getBanerDetail = data => ({
    type: GET_BANER_DETAIL,
    payload: data,
})

export const getBanerDetailSuccess = job => ({
    type: GET_BANER_DETAIL_SUCCESS,
    payload: job,
})

export const getBanerDetailFail = error => ({
    type: GET_BANER_DETAIL_FAIL,
    payload: error,
})
