import {
    GET_MANAGE_PORTFOLIO,
    GET_MANAGE_PORTFOLIO_FAIL,
    GET_MANAGE_PORTFOLIO_SUCCESS,
    GET_PORTFOLIO_DETAIL,
    GET_PORTFOLIO_DETAIL_SUCCESS,
    GET_PORTFOLIO_DETAIL_FAIL,
    SET_TYPE_POPUP,
} from "./actionTypes"

export const getPortfolioList = params => ({
    type: GET_MANAGE_PORTFOLIO,
    payload: params,
})

export const getManagePortfolioSuccess = data => ({
    type: GET_MANAGE_PORTFOLIO_SUCCESS,
    payload: data,
})

export const getManagePortfolioFail = error => ({
    type: GET_MANAGE_PORTFOLIO_FAIL,
    payload: error,
})

export const getPortfolioDetail = data => ({
    type: GET_PORTFOLIO_DETAIL,
    payload: data,
})

export const getPortfolioDetailSuccess = data => ({
    type: GET_PORTFOLIO_DETAIL_SUCCESS,
    payload: data,
})

export const getPortfolioDetailFail = error => ({
    type: GET_PORTFOLIO_DETAIL_FAIL,
    payload: error,
})

export const setTypePopup = params => ({
    type: SET_TYPE_POPUP,
    payload: params,
})
