import PropsType from 'prop-types'
import { useEffect, useMemo, useState } from 'react'
import { Label, UncontrolledTooltip } from 'reactstrap'
import React from 'react'
import { Row, Col, Space, Flex, Form } from 'antd'

//flatpickr
import { ActivationVN, CommonVN, ProductVN } from 'constants/vn'

import { DatePicker } from 'antd'
import { FormInput } from 'components/Common/form-input/FormInput'
import { FormSelect } from 'components/Common/form-select/FormSelect'
import { MAX_LENGTH_SEARCH, TypeProgramOptions } from 'constants/constants'
import { createSelector } from 'reselect'
import { useSelector } from 'react-redux'
import { FormDateRange } from 'components/Common/form-date-range/FormDateRange'
import { getPortfolioAllAPI } from 'helpers/backend_helper'

const { RangePicker } = DatePicker

ManageExportHistoryFilter.propTypes = {
  handleFilter: PropsType.func,
  handleConfirm: PropsType.func,
  handleResetFilter: PropsType.func,
  handleExport: PropsType.func
}

function ManageExportHistoryFilter({ handleFilter, handleConfirm, handleResetFilter, handleExport }) {
  const selectLayoutState = (state) => state.Layout
  const LayoutProperties = createSelector(selectLayoutState, (app) => ({
    zone: app.zone,
    subzone: app.subzone,
    province: app.province,
    district: app.district,
    ward: app.ward,
    myLocationManagement: app.myLocationManagement
  }))
  const { zone, subzone, province, district, myLocationManagement, ward } = useSelector(LayoutProperties)

  const [filter, setFilter] = useState({})
  const [industry, setIndustry] = useState()
  const [industrySector, setIndustrysector] = useState()
  const [category, setCategory] = useState()
  const [form] = Form.useForm()
  const watchType = Form.useWatch('type', form)
  const watchForm = Form.useWatch([], form)
  const [model, setModel] = useState()

  const onFilterChange = (name, value) =>
    setFilter((prev) => ({
      ...prev,
      [name]: value
    }))

  const ColProps = useMemo(() => ({
    xs: { span: 24 },
    xxl: { span: 4 }
  }))

  const onResetFilter = () =>
    setFilter({
      serial: '',
      model_name: '',
      shop_name: '',
      phone: '',
      zone_ids: undefined,
      sub_zone_ids: undefined,
      province_ids: undefined,
      district_ids: undefined,
      ward_ids: undefined,
      client: undefined,
      start_date1: undefined,
      end_date1: undefined,
      start_date: undefined,
      end_date: undefined,
      industry_ids: undefined,
      industry_sector_ids: undefined,
      category_ids: undefined,
      email: '',
      phone: '',
      search: '',
      label: '',
      type: 'product',
      status: ''
    })

  return (
    <Row gutter={[10, 10]} align='bottom'>
      <Col {...ColProps} className='w-100'>
        <Label className='form-label'>{'Loại'}</Label>
        <FormSelect
          options={[
            {
              label: 'Sản phẩm',
              value: 'product'
            },
            {
              label: 'Danh mục sản phẩm',
              value: 'category'
            },
            {
              label: 'Mã bảo hành',
              value: 'product_activation'
            },
            {
              label: 'Đối soát thưởng',
              value: 'reconcile'
            },

            {
              label: 'Cửa hàng',
              value: 'users'
            },
            {
              label: 'Kỹ thuật viên',
              value: 'technician'
            },
            {
              label: 'Quản lý kích hoạt',
              value: 'activation'
            },
            {
              label: 'Quản lý tích lũy',
              value: 'accumulation'
            },
            {
              label: 'Khách hàng',
              value: 'customer'
            },
            {
              label: 'Báo cáo tạm tính theo sản phẩm',
              value: 'report_by_product'
            },
            {
              label: 'Báo cáo tạm tính theo chương trình',
              value: 'report_by_programming'
            }
          ]}
          value={filter.type}
          onChange={(value) => {
            onFilterChange('type', value)
          }}
          defaultValue={'product'}
        />
      </Col>

      <Col {...ColProps} className='w-100'>
        <Label className='form-label'>{'Trạng thái'}</Label>
        <FormSelect
          options={[
            {
              label: 'Đang xử lý',
              value: 'pending'
            },
            {
              label: 'Thất bại',
              value: 'failed'
            },
            {
              label: 'Hoàn thành',
              value: 'completed'
            }
          ]}
          value={filter.status}
          onChange={(value) => {
            onFilterChange('status', value)
          }}
        />
      </Col>

      <Col {...ColProps}>
        <Label className='form-label'>Thời gian</Label>
        <FormDateRange
          className='w-100'
          size='large'
          format={CommonVN.datePlaceholder.toUpperCase()}
          placeholder={[CommonVN.datePlaceholder, CommonVN.datePlaceholder]}
          value={[filter.start_date, filter.end_date]}
          onChange={(value) => {
            if (value) {
              onFilterChange('start_date', value[0])
              onFilterChange('end_date', value[1])
            } else {
              onFilterChange('start_date', undefined)
              onFilterChange('end_date', undefined)
            }
          }}
        />
      </Col>

      <Col {...ColProps} className='w-100'>
        <Space>
          <button
            type='button'
            className='btn btn-soft-danger'
            onClick={() => {
              onResetFilter()
              handleResetFilter()
            }}
          >
            Xóa tìm kiếm
          </button>

          <button type='button' className='btn btn-primary' onClick={() => handleFilter(filter)}>
            Tìm kiếm
          </button>
        </Space>
      </Col>
    </Row>
  )
}

export default ManageExportHistoryFilter
