import React from "react"
import PropTypes from "prop-types"
import { Link, useNavigate } from "react-router-dom"
import { Row, Col, BreadcrumbItem } from "reactstrap"

const Breadcrumb = props => {
    const navigate = useNavigate()
    return (
        <Row>
            <Col className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                    <div className="d-flex flex-row align-items-center">
                        <i
                            className="mdi mdi-24px mdi-arrow-left me-4 pe-auto"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                navigate(-1)
                            }}
                        ></i>
                        <h4 className="mb-sm-0 font-size-18 ">{props.breadcrumbItem}</h4>
                    </div>
                    <div className="page-title-right">
                        <ol className="breadcrumb m-0">
                            {/* <BreadcrumbItem>
                <Link to="#">{props.title}</Link>
              </BreadcrumbItem>
              <BreadcrumbItem active>
                <Link to="#">{props.breadcrumbItem}</Link>
              </BreadcrumbItem> */}
                        </ol>
                    </div>
                </div>
            </Col>
        </Row>
    )
}

Breadcrumb.propTypes = {
    breadcrumbItem: PropTypes.string,
    title: PropTypes.string,
}

export default Breadcrumb
