import { Button, Form, Radio } from 'antd'
import { FormSelect } from 'components/Common/form-select/FormSelect'
import { MAX_LENGTH_20 } from 'constants/constants'
import { ManageProgramVN } from 'constants/vn'
import React, { useEffect, useMemo } from 'react'
import { Col, Label, Row, Table } from 'reactstrap'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { FormInputNumber } from 'components/Common/form-input-number/FormInputNumber'
import { formatWithCommas } from 'helpers/global_helper'
import { FormInput } from 'components/Common/form-input/FormInput'
import { FormInputNumberCurrency } from 'components/Common/form-input-number/FormInputNumberCurrency'

const ConfigTypeTab = ({ form, watchForm, industry, industrySector, category, model, type, programDetail }) => {
  const isEdit = type !== 'view'
  const numberOptions = Array.from(Array(9)).map((e, i) => ({ value: i + 1, label: i + 1 }))

  // Content product type
  const renderProduct = () => {
    return (
      <div>
        <Row>
          <Col sm='6'>
            {/* <Label className="form-label  me-5">{ManageProgramVN.form.programAccumulation.label}</Label>
                    <Form.Item name="programAccumulation">
                        <Radio.Group options={ManageProgramVN.form.programAccumulation.options} disabled={!isEdit} defaultValue="each_model" />
                    </Form.Item> */}
            <Label className='form-label  me-5'>{ManageProgramVN.form.rewardConditionType.label}</Label>
            <Form.Item name='rewardConditionType'>
              <Radio.Group
                options={ManageProgramVN.form.rewardConditionType.options}
                disabled={!isEdit}
                defaultValue='number'
                onChange={(value) => {
                  if (value.target.value === 'product') {
                    form.setFieldValue('rewardType', 'money_object')
                    form.setFieldValue('productRewardConditions', [{}])
                  }
                }}
              />
            </Form.Item>
          </Col>
          {watchForm?.rewardConditionType === 'product' && (
            <Col sm='6'>
              <Label className='form-label  me-5'>{ManageProgramVN.form.programReward.label}</Label>
              <Form.Item name='rewardType'>
                <Radio.Group
                  className='fw-normal'
                  options={ManageProgramVN.form.programReward.options}
                  defaultValue='money_object'
                  disabled={!isEdit}
                />
              </Form.Item>
            </Col>
          )}
        </Row>
        {watchForm?.rewardConditionType === 'number' && (
          <>
            <Label className='form-label  me-5'>{ManageProgramVN.form.tableConfigNumber}</Label>
            <Col sm='8'>
              <div className='table-responsive'>
                <Table className='table b-0'>
                  <thead className='table-light'>
                    <tr>
                      <th>STT</th>
                      <th>
                        {ManageProgramVN.form.fromQuantity.label}
                        {type !== 'view' && <a className='text-danger'>*</a>}
                      </th>
                      <th>
                        {ManageProgramVN.form.toQuantity.label}
                        {type !== 'view' && <a className='text-danger'>*</a>}
                      </th>
                      <th>
                        {ManageProgramVN.form.reward.label}
                        {type !== 'view' && <a className='text-danger'>*</a>}
                      </th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <Form.List name='products'>
                      {(fields, { add, remove }) => (
                        <>
                          {fields.map(({ key, name, ...restField }, index) => (
                            <>
                              <tr className='position-relative'>
                                <td scope='row' style={{ fontWeight: 600 }}>
                                  <span style={{ marginTop: '12px', display: 'block' }}>{index + 1}</span>
                                </td>
                                <td>
                                  <Form.Item
                                    {...restField}
                                    name={[name, 'fromValue']}
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Vui lòng nhập số lượng'
                                      },
                                      { pattern: /^[1-9]\d*$/, message: 'Vui lòng nhập số nguyên dương' }
                                    ]}
                                    style={{ margin: 0 }}
                                  >
                                    <FormInput
                                      // min={1}
                                      onChange={(e) => {
                                        if (typeof e !== 'string') {
                                          if (Number(e.target.value) > 0) {
                                            form.setFieldValue(
                                              ['products', index, 'toValue'],
                                              Number(e.target.value) + 1
                                            )
                                          }
                                        }
                                      }}
                                      placeholder={ManageProgramVN.form.fromQuantity.placeholder}
                                      disabled={
                                        !isEdit || (index > 0 ? !watchForm?.products?.[index - 1]?.toValue : false)
                                      }
                                    />
                                  </Form.Item>
                                </td>
                                <td>
                                  <Form.Item
                                    {...restField}
                                    name={[name, 'toValue']}
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Vui lòng nhập số lượng'
                                      },
                                      { pattern: /^[1-9]\d*$/, message: 'Vui lòng nhập số nguyên dương' }
                                    ]}
                                    style={{ margin: 0 }}
                                  >
                                    <FormInput
                                      placeholder={ManageProgramVN.form.toQuantity.placeholder}
                                      disabled={
                                        !isEdit || (index > 0 ? !watchForm?.products?.[index - 1]?.toValue : false)
                                      }
                                    />
                                  </Form.Item>
                                </td>
                                <td>
                                  <Form.Item
                                    {...restField}
                                    name={[name, 'money']}
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Vui lòng nhập số tiền'
                                      },
                                      { pattern: /^[1-9]\d*$/, message: 'Vui lòng nhập số nguyên dương' }
                                    ]}
                                    style={{ margin: 0 }}
                                  >
                                    {/* <FormInput
                                      min={1}
                                      placeholder={ManageProgramVN.form.reward.placeholder}
                                      disabled={!isEdit}
                                    /> */}
                                    <FormInputNumberCurrency
                                      min={1}
                                      placeholder={ManageProgramVN.form.reward.placeholder}
                                      disabled={!isEdit}
                                    />
                                  </Form.Item>
                                </td>
                                <td>
                                  {name > 0 && isEdit && (
                                    <MinusCircleOutlined style={{ marginTop: '12px' }} onClick={() => remove(name)} />
                                  )}
                                </td>
                              </tr>
                            </>
                          ))}
                          {isEdit && (
                            <Form.Item>
                              <Button type='dashed' onClick={() => add()} block icon={<PlusOutlined />}></Button>
                            </Form.Item>
                          )}
                        </>
                      )}
                    </Form.List>
                  </tbody>
                </Table>
              </div>
            </Col>
          </>
        )}
        {watchForm?.rewardConditionType === 'product' && (
          <>
            <Label className='form-label  me-5'>{ManageProgramVN.form.tableConfigModel}</Label>
            <Col sm='12'>
              <div className='table-responsive'>
                <Table className='table  mb-0'>
                  <thead className='table-light'>
                    <tr>
                      <th>STT</th>
                      <th>
                        {ManageProgramVN.form.industry.label}
                        {type !== 'view' && <a className='text-danger'>*</a>}
                      </th>
                      <th>
                        {ManageProgramVN.form.industrySector.label}
                        {type !== 'view' && <a className='text-danger'>*</a>}
                      </th>
                      <th>
                        {ManageProgramVN.form.category.label}
                        {type !== 'view' && <a className='text-danger'>*</a>}
                      </th>
                      <th>
                        {ManageProgramVN.form.model.label}
                        {type !== 'view' && <a className='text-danger'>*</a>}
                      </th>
                      {watchForm?.rewardType?.includes('money') && (
                        <th>Tiền thưởng/SP{type !== 'view' && <a className='text-danger'>*</a>}</th>
                      )}
                      {watchForm?.rewardType?.includes('object') && (
                        <>
                          <th>Số lượng hiện vật{type !== 'view' && <a className='text-danger'>*</a>}</th>
                          <th>Hiện vật{type !== 'view' && <a className='text-danger'>*</a>}</th>
                          <th>Đơn giá quy đổi{type !== 'view' && <a className='text-danger'>*</a>}</th>
                        </>
                      )}
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <Form.List name={'productRewardConditions'}>
                      {(subFields, { add, remove }) => {
                        return (
                          <>
                            {subFields.map((subField, index) => {
                              return (
                                <>
                                  <tr className='position-relative'>
                                    <th scope='row'>
                                      <span style={{ marginTop: '12px', display: 'block' }}>{index + 1}</span>
                                    </th>
                                    <td style={{ maxWidth: 200 }}>
                                      <Form.Item
                                        name={[subField.name, 'industryIds']}
                                        rules={[{ required: true, message: 'Vui lòng chọn ngành' }]}
                                      >
                                        <FormSelect
                                          options={industryOptions}
                                          onChange={() => {
                                            form.setFieldValue(
                                              ['productRewardConditions', subField.name, 'industrySectorIds'],
                                              undefined
                                            )
                                            form.setFieldValue(
                                              ['productRewardConditions', subField.name, 'categoryIds'],
                                              undefined
                                            )
                                            form.setFieldValue(
                                              ['productRewardConditions', subField.name, 'modelIds'],
                                              undefined
                                            )
                                          }}
                                          placeholder={ManageProgramVN.form.industry.placeholder}
                                          disabled={!isEdit}
                                        />
                                      </Form.Item>
                                    </td>
                                    <td style={{ maxWidth: 200 }}>
                                      <Form.Item
                                        name={[subField.name, 'industrySectorIds']}
                                        rules={[{ required: true, message: 'Vui lòng chọn ngành hàng' }]}
                                      >
                                        <FormSelect
                                          options={industrySector
                                            ?.filter(
                                              (e) =>
                                                watchForm?.productRewardConditions?.[index]?.industryIds === e.parentId
                                            )
                                            ?.map((e) => ({ label: e.name, value: e.id }))}
                                          onChange={() => {
                                            form.setFieldValue(
                                              ['productRewardConditions', index, 'categoryIds'],
                                              undefined
                                            )
                                            form.setFieldValue(
                                              ['productRewardConditions', index, 'modelIds'],
                                              undefined
                                            )
                                          }}
                                          placeholder={ManageProgramVN.form.industrySector.placeholder}
                                          disabled={!isEdit}
                                        />
                                      </Form.Item>
                                    </td>
                                    <td style={{ maxWidth: 200 }}>
                                      <Form.Item
                                        name={[subField.name, 'categoryIds']}
                                        rules={[{ required: true, message: 'Vui lòng chọn nhóm sản phẩm' }]}
                                      >
                                        <FormSelect
                                          options={category
                                            ?.filter(
                                              (e) =>
                                                watchForm?.productRewardConditions?.[index]?.industrySectorIds ===
                                                e.parentId
                                            )
                                            ?.map((e) => ({ label: e.name, value: e.id }))}
                                          onChange={() => {
                                            form.setFieldValue(
                                              ['productRewardConditions', index, 'modelIds'],
                                              undefined
                                            )
                                          }}
                                          placeholder={ManageProgramVN.form.category.placeholder}
                                          disabled={!isEdit}
                                        />
                                      </Form.Item>
                                    </td>
                                    <td style={{ maxWidth: 250, minWidth: 150 }}>
                                      <Form.Item
                                        name={[subField.name, 'modelIds']}
                                        rules={[{ required: true, message: 'Vui lòng chọn model' }]}
                                      >
                                        <FormSelect
                                          mode='multiple'
                                          showSearch
                                          allowClear
                                          maxTagTextLength={10}
                                          maxTagCount={1}
                                          options={model
                                            ?.filter(
                                              (e) =>
                                                watchForm?.productRewardConditions?.[index]?.categoryIds === e.parentId
                                            )
                                            ?.map((e) => ({ label: e.name, value: e.id }))}
                                          placeholder={ManageProgramVN.form.model.placeholder}
                                          disabled={!isEdit}
                                        />
                                      </Form.Item>
                                    </td>
                                    {watchForm?.rewardType?.includes('money') && (
                                      <td style={{ maxWidth: 200 }}>
                                        <Form.Item
                                          name={[subField.name, 'money']}
                                          rules={[
                                            {
                                              required: true,
                                              message: 'Vui lòng nhập tiền thưởng'
                                            },
                                            { pattern: /^[1-9]\d*$/, message: 'Vui lòng nhập số nguyên dương' }
                                          ]}
                                        >
                                          {/* <FormInput min={1} placeholder='Nhập tiền thưởng' disabled={!isEdit} /> */}
                                          <FormInputNumberCurrency
                                            min={1}
                                            placeholder='Nhập tiền thưởng'
                                            disabled={!isEdit}
                                          />
                                        </Form.Item>
                                      </td>
                                    )}

                                    {watchForm?.rewardType?.includes('object') && (
                                      <>
                                        <td style={{ maxWidth: 200 }}>
                                          <Form.Item
                                            name={[subField.name, 'numberOfArtifact']}
                                            rules={[
                                              {
                                                required: true,
                                                message: 'Vui lòng nhập số lượng'
                                              },
                                              {
                                                pattern: /^(0*?[1-9]\d?|99)$/,
                                                message: 'Vui lòng nhập số nguyên dương nhỏ hơn 100'
                                              }
                                            ]}
                                          >
                                            <FormInput
                                              // min={1}
                                              placeholder='Nhập số lượng'
                                              disabled={!isEdit}
                                            />
                                          </Form.Item>
                                        </td>
                                        <td style={{ maxWidth: 200, minWidth: 150 }}>
                                          <Form.Item
                                            name={[subField.name, 'artifactName']}
                                            rules={[{ required: true, message: 'Vui lòng chọn hiện vật' }]}
                                          >
                                            <FormSelect
                                              mode='tags'
                                              maxLength={1}
                                              maxTagCount={1}
                                              maxTagTextLength={9}
                                              onSelect={(value) => {
                                                if (value) {
                                                  return form.setFieldValue(
                                                    ['productRewardConditions', index, 'artifactName'],
                                                    value
                                                  )
                                                }
                                              }}
                                              placeholder={ManageProgramVN.form.package.object}
                                              options={model?.map((e) => ({
                                                label: e.name,
                                                value: e.id?.toString()
                                              }))}
                                              disabled={!isEdit}
                                            />
                                          </Form.Item>
                                        </td>
                                        <td style={{ maxWidth: 200 }}>
                                          <Form.Item
                                            name={[subField.name, 'convertValue']}
                                            rules={[
                                              {
                                                required: true,
                                                message: 'Vui lòng nhập đơn giá'
                                              },
                                              {
                                                pattern: /^[1-9]\d{0,9}$/,
                                                message: 'Vui lòng nhập số nguyên dương và tối đa 10 chữ số'
                                              }
                                            ]}
                                          >
                                            {/* <FormInput
                                              // min={1}
                                              placeholder='Nhập đơn giá quy đổi'
                                              disabled={!isEdit}
                                            /> */}
                                            <FormInputNumberCurrency
                                              min={1}
                                              placeholder='Nhập đơn giá quy đổi'
                                              disabled={!isEdit}
                                            />
                                          </Form.Item>
                                        </td>
                                      </>
                                    )}

                                    <td>
                                      {subField.name > 0 && isEdit && (
                                        <MinusCircleOutlined
                                          style={{ marginTop: '12px' }}
                                          onClick={() => remove(subField.name)}
                                        />
                                      )}
                                    </td>
                                  </tr>
                                </>
                              )
                            })}
                            {isEdit && (
                              <Form.Item>
                                <Button type='dashed' onClick={() => add()} block icon={<PlusOutlined />}></Button>
                              </Form.Item>
                            )}
                          </>
                        )
                      }}
                    </Form.List>
                  </tbody>
                </Table>
              </div>
            </Col>
          </>
        )}
      </div>
    )
  }
  const initValuePackageItem = {
    money: undefined,
    convertValue: undefined,
    artifactName: undefined,
    numberOfArtifact: undefined,
    modelPackages: [
      {
        modelIds: undefined,
        fromValue: undefined,
        categoryIds: undefined,
        industryIds: undefined,
        industrySectorIds: undefined
      }
    ]
  }
  const convertValueTotal = 2000000
  const industryOptions = useMemo(() => {
    return watchForm?.industry?.map((e) => {
      const _item = industry?.find((i) => i?.id === e)
      // console.log({ _item })
      return {
        label: _item?.name,
        value: _item?.id
      }
    })
  }, [watchForm?.industry, industry])

  // Content  package type
  const renderPackage = () => {
    return (
      <div>
        <Row>
          <Col sm='6'>
            <Label className='form-label'>
              {ManageProgramVN.form.quantityBonus.label}
              <a className='text-danger'>*</a>
            </Label>
            <Form.Item name='numberOfPackage' rules={[{ required: true, message: 'Vui lòng nhập số gói thưởng' }]}>
              <FormSelect
                options={numberOptions}
                onChange={(value) => {
                  const _packages = []
                  Array.from(Array(value).keys()).forEach((e) => _packages.push(initValuePackageItem))
                  form.setFieldValue('packages', _packages)
                  form.setFieldValue('maxNumberOfRewardPackage', undefined)
                  form.setFieldValue('rewardType', watchForm?.rewardType)
                }}
                placeholder={ManageProgramVN.form.quantityBonus.placeholder}
                disabled={!isEdit}
              />
            </Form.Item>
          </Col>
          <Col sm='6'>
            <Label className='form-label'>
              {ManageProgramVN.form.quantityBonusMax.label}
              <a className='text-danger'>*</a>
            </Label>
            <Form.Item name='maxNumberOfRewardPackage' rules={[{ required: true, message: 'Vui lòng chọn Miền' }]}>
              <FormSelect
                placeholder={ManageProgramVN.form.quantityBonusMax.placeholder}
                options={Array.from(Array(watchForm?.numberOfPackage))?.map((e, i) => ({ value: i + 1, label: i + 1 }))}
                disabled={!isEdit}
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.List name='packages'>
          {(fields) =>
            fields.map((field, indexPackage) => (
              <div key={field.key}>
                <div className='d-flex flex-row align-items-center'>
                  <Label className='form-label mt-2  me-4' style={{ width: 60 }}>
                    {`Gói 0${indexPackage + 1}`}
                    <a className='text-danger'>*</a>
                  </Label>
                  <Col sm='2'>
                    <Label className='form-label mt-2  me-4'>
                      Số lần nhận thưởng tối đa/gói
                      <a className='text-danger'>*</a>
                    </Label>
                    <Form.Item
                      name={[field.name, 'maxNumberOfReward']}
                      rules={[
                        { required: true, message: 'Vui lòng nhập số lần nhận thưởng tối đa/gói' },
                        { pattern: /^[1-9]\d*$/, message: 'Vui lòng nhập số nguyên dương' }
                      ]}
                    >
                      <FormInput placeholder={ManageProgramVN.form.package.maxReward} disabled={!isEdit} />
                    </Form.Item>
                  </Col>
                </div>
                <Label className='form-label mt-2  me-4' style={{ width: 60 }}>
                  {`Thưởng`}
                  <a className='text-danger'>*</a>
                </Label>
                <div className='d-flex flex-row'>
                  {(watchForm?.rewardType?.includes('money') || watchForm?.rewardType == undefined) && (
                    <Col sm='2' className='me-2'>
                      <Label className='form-label mt-2  me-4'>
                        Số tiền thưởng/gói
                        <a className='text-danger'>*</a>
                      </Label>
                      <Form.Item
                        name={[field.name, 'money']}
                        rules={[
                          { required: true, message: 'Vui lòng nhập số tiền' },
                          { pattern: /^[1-9]\d*$/, message: 'Vui lòng nhập số nguyên dương' }
                        ]}
                      >
                        {/* <FormInput placeholder={ManageProgramVN.form.package.amountBonus} disabled={!isEdit} /> */}
                        <FormInputNumberCurrency
                          min={1}
                          maxLength={MAX_LENGTH_20}
                          placeholder='Nhập Số tiền thưởng/gói'
                          disabled={!isEdit}
                        />
                      </Form.Item>
                    </Col>
                  )}
                  {(watchForm?.rewardType?.includes('object') || watchForm?.rewardType == undefined) && (
                    <>
                      <Col sm='2' className='me-2'>
                        <Label className='form-label mt-2  me-4'>
                          Số lượng hiện vật
                          <a className='text-danger'>*</a>
                        </Label>
                        <Form.Item
                          name={[field.name, 'numberOfArtifact']}
                          rules={[
                            { required: true, message: 'Vui lòng nhập số lượng' },
                            { pattern: /^(0*?[1-9]\d?|99)$/, message: 'Vui lòng nhập số nguyên dương nhỏ hơn 100' }
                          ]}
                        >
                          <FormInput placeholder={ManageProgramVN.form.package.quantity} disabled={!isEdit} />
                        </Form.Item>
                      </Col>
                      <Col sm='2' className='me-2'>
                        <Label className='form-label mt-2  me-4'>
                          Tên hiện vật
                          <a className='text-danger'>*</a>
                        </Label>
                        <Form.Item
                          name={[field.name, 'artifactName']}
                          rules={[{ required: true, message: 'Vui lòng chọn hiện vật' }]}
                        >
                          <FormSelect
                            mode='tags'
                            maxLength={1}
                            maxTagCount={1}
                            onSelect={(value) => {
                              if (value) {
                                return form.setFieldValue(['packages', indexPackage, 'artifactName'], value)
                              }
                            }}
                            placeholder={ManageProgramVN.form.package.object}
                            options={model?.map((e) => ({ label: e.name, value: e.id }))}
                            disabled={!isEdit}
                          />
                        </Form.Item>
                      </Col>
                      <Col sm='2' className='me-2'>
                        <Label className='form-label mt-2  me-4'>
                          Đơn giá quy đổi
                          <a className='text-danger'>*</a>
                        </Label>
                        <Form.Item
                          name={[field.name, 'convertValue']}
                          rules={[
                            { required: true, message: 'Vui lòng nhập đơn giá quy đổi' },
                            { pattern: /^[1-9]\d{0,9}$/, message: 'Vui lòng nhập số nguyên dương và tối đa 10 chữ số' }
                          ]}
                        >
                          {/* <FormInput placeholder={ManageProgramVN.form.package.price} disabled={!isEdit} /> */}
                          <FormInputNumberCurrency
                            min={1}
                            maxLength={MAX_LENGTH_20}
                            placeholder={ManageProgramVN.form.package.price}
                            disabled={!isEdit}
                          />
                        </Form.Item>
                      </Col>
                      <Col sm='2' className='mt-2'>
                        <Label className='form-label mt-2  me-4'>
                          {/* Số lượng
                          <a className="text-danger">*</a> */}
                          {'Tổng'}
                        </Label>
                        {/* (
                                                {ManageProgramVN.form.package.totalExchange +
                                                    formatWithCommas(
                                                        (watchForm?.packages?.[indexPackage]?.convertValue ?? 0) *
                                                        (watchForm?.packages?.[indexPackage]?.numberOfArtifact ?? 0) +
                                                        (watchForm?.packages?.[indexPackage]?.money ?? 0)
                                                    )}
                                                ) */}
                        <Form.Item>
                          {ManageProgramVN.form.package.totalExchange}
                          {formatWithCommas(
                            (Number(watchForm?.packages?.[indexPackage]?.convertValue) || 0) *
                              (Number(watchForm?.packages?.[indexPackage]?.numberOfArtifact) || 0) +
                              (Number(watchForm?.packages?.[indexPackage]?.money) || 0)
                          )}
                        </Form.Item>
                      </Col>
                    </>
                  )}
                </div>
                <div className='table-responsive'>
                  <Table className='table  mb-0'>
                    <thead className='table-light'>
                      <tr>
                        <th>STT</th>
                        <th>
                          {ManageProgramVN.form.industry.label}
                          {type !== 'view' && <a className='text-danger'>*</a>}
                        </th>
                        <th>
                          {ManageProgramVN.form.industrySector.label}
                          {type !== 'view' && <a className='text-danger'>*</a>}
                        </th>
                        <th>
                          {ManageProgramVN.form.category.label}
                          {type !== 'view' && <a className='text-danger'>*</a>}
                        </th>
                        <th>
                          {ManageProgramVN.form.model.label}
                          {type !== 'view' && <a className='text-danger'>*</a>}
                        </th>
                        <th>Số lượng tối thiểu{type !== 'view' && <a className='text-danger'>*</a>}</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <Form.List name={[field.name, 'modelPackages']}>
                        {(subFields, { add, remove }) => {
                          return (
                            <>
                              {subFields.map((subField, index) => {
                                return (
                                  <>
                                    <tr className='position-relative'>
                                      <th scope='row'>
                                        <span style={{ marginTop: '12px', display: 'block' }}>{index + 1}</span>
                                      </th>
                                      <td style={{ maxWidth: 200 }}>
                                        <Form.Item
                                          name={[subField.name, 'industryIds']}
                                          rules={[{ required: true, message: 'Vui lòng chọn ngành' }]}
                                        >
                                          <FormSelect
                                            options={industryOptions}
                                            onChange={() => {
                                              form.setFieldValue(
                                                [
                                                  'packages',
                                                  indexPackage,
                                                  'modelPackages',
                                                  subField.name,
                                                  'industrySectorIds'
                                                ],
                                                undefined
                                              )
                                              form.setFieldValue(
                                                [
                                                  'packages',
                                                  indexPackage,
                                                  'modelPackages',
                                                  subField.name,
                                                  'categoryIds'
                                                ],
                                                undefined
                                              )
                                              form.setFieldValue(
                                                ['packages', indexPackage, 'modelPackages', subField.name, 'modelIds'],
                                                undefined
                                              )
                                            }}
                                            placeholder={ManageProgramVN.form.industry.placeholder}
                                            disabled={!isEdit}
                                          />
                                        </Form.Item>
                                      </td>
                                      <td style={{ maxWidth: 200 }}>
                                        <Form.Item
                                          name={[subField.name, 'industrySectorIds']}
                                          rules={[{ required: true, message: 'Vui lòng chọn ngành hàng' }]}
                                        >
                                          <FormSelect
                                            options={industrySector
                                              ?.filter(
                                                (e) =>
                                                  watchForm?.packages?.[indexPackage]?.modelPackages?.[index]
                                                    ?.industryIds === e.parentId
                                              )
                                              ?.map((e) => ({ label: e.name, value: e.id }))}
                                            onChange={() => {
                                              form.setFieldValue(
                                                ['packages', indexPackage, 'modelPackages', index, 'categoryIds'],
                                                undefined
                                              )
                                              form.setFieldValue(
                                                ['packages', indexPackage, 'modelPackages', index, 'modelIds'],
                                                undefined
                                              )
                                            }}
                                            placeholder={ManageProgramVN.form.industrySector.placeholder}
                                            disabled={!isEdit}
                                          />
                                        </Form.Item>
                                      </td>
                                      <td style={{ maxWidth: 200 }}>
                                        <Form.Item
                                          name={[subField.name, 'categoryIds']}
                                          rules={[{ required: true, message: 'Vui lòng chọn nhóm sản phẩm' }]}
                                        >
                                          <FormSelect
                                            mode='multiple'
                                            options={category
                                              ?.filter(
                                                (e) =>
                                                  watchForm?.packages?.[indexPackage]?.modelPackages?.[index]
                                                    ?.industrySectorIds === e.parentId
                                              )
                                              ?.map((e) => ({ label: e.name, value: e.id }))}
                                            onChange={() => {
                                              form.setFieldValue(
                                                ['packages', indexPackage, 'modelPackages', index, 'modelIds'],
                                                undefined
                                              )
                                            }}
                                            onSelect={(value) => {
                                              const all = category
                                                ?.filter((e) => watchForm.industrySector?.includes(e.parentId))
                                                ?.map((e) => e.id)
                                              if (value && value === 'all') {
                                                return form.setFieldValue('category', [...all])
                                              }
                                            }}
                                            placeholder={ManageProgramVN.form.category.placeholder}
                                            disabled={!isEdit}
                                          />
                                        </Form.Item>
                                      </td>
                                      <td style={{ maxWidth: 250, minWidth: 150 }}>
                                        <Form.Item
                                          name={[subField.name, 'modelIds']}
                                          rules={[{ required: true, message: 'Vui lòng chọn model' }]}
                                        >
                                          <FormSelect
                                            mode='multiple'
                                            showSearch
                                            allowClear
                                            maxTagTextLength={10}
                                            maxTagCount={2}
                                            options={
                                              model?.filter &&
                                              [{ label: 'Tất cả', value: 'all' }].concat(
                                                model
                                                  ?.filter(
                                                    (e) =>
                                                      watchForm.category?.includes(e.parentId) &&
                                                      watchForm?.packages?.[indexPackage]?.modelPackages?.[
                                                        index
                                                      ]?.categoryIds?.includes(e.parentId)
                                                  )
                                                  ?.map((e) => ({
                                                    label: e.name,
                                                    value: e.id
                                                  }))
                                              )
                                            }
                                            placeholder={'Chọn model'}
                                            disabled={!isEdit}
                                            onSelect={(value) => {
                                              const all = model
                                                ?.filter(
                                                  (e) =>
                                                    watchForm.category?.includes(e.parentId) &&
                                                    watchForm?.packages?.[indexPackage]?.modelPackages?.[
                                                      index
                                                    ]?.categoryIds?.includes(e.parentId)
                                                )
                                                ?.map((e) => e.id)
                                              if (value && value === 'all') {
                                                // return form.setFieldValue('modelIds', [...all])
                                                //set giá trị khi chọn onSelect
                                                return form.setFieldValue(
                                                  ['packages', indexPackage, 'modelPackages', index, 'modelIds'],
                                                  [...all]
                                                )
                                              }
                                            }}
                                          />
                                        </Form.Item>
                                      </td>
                                      <td style={{ maxWidth: 200 }}>
                                        <Form.Item
                                          name={[subField.name, 'fromValue']}
                                          rules={[
                                            {
                                              required: true,
                                              message: 'Vui lòng nhập số lượng'
                                            },
                                            { pattern: /^[1-9]\d*$/, message: 'Vui lòng nhập số nguyên dương' }
                                          ]}
                                        >
                                          <FormInput placeholder='Nhập số lượng' disabled={!isEdit} />
                                        </Form.Item>
                                      </td>
                                      <td>
                                        {subField.name > 0 && isEdit && (
                                          <MinusCircleOutlined
                                            style={{ marginTop: '12px' }}
                                            onClick={() => remove(subField.name)}
                                          />
                                        )}
                                      </td>
                                    </tr>
                                  </>
                                )
                              })}
                              {isEdit && (
                                <Form.Item>
                                  <Button type='dashed' onClick={() => add()} block icon={<PlusOutlined />}></Button>
                                </Form.Item>
                              )}
                            </>
                          )
                        }}
                      </Form.List>
                    </tbody>
                  </Table>
                </div>
              </div>
            ))
          }
        </Form.List>
      </div>
    )
  }

  useEffect(() => {
    if (watchForm?.model && watchForm?.model.length > 0) {
      // const modelRevenue = model?.filter(e => watchForm?.model.includes(e.id))
      form.setFieldValue(
        'unitPrices',
        watchForm?.model?.map((e) => ({
          modelId: e,
          unitPrice:
            programDetail?.programmingModules &&
            programDetail?.programmingModules.find((i) => i?.modelId === e)?.unitPrice
        }))
      )
    }
  }, [watchForm?.model])

  const handleValidateNumberRevenue = () => {
    const data = form.getFieldValue('revenues')
    if (data.length > 0) {
      data.map((item, index) => {
        if (item.fromValue === null) {
          form.setFields([{ name: ['revenues', index, 'fromValue'], errors: ['Vui lòng nhập Từ doanh thu'] }])
        }

        if (item.toValue === null) {
          form.setFields([{ name: ['revenues', index, 'toValue'], errors: ['Vui lòng nhập Đến doanh thu'] }])
        }

        if (item.fromValue !== null && item.toValue !== null) {
          if (item.fromValue >= item.toValue) {
            form.setFields([
              { name: ['revenues', index, 'toValue'], errors: ['Giá trị của Đến doanh thu phải lớn Từ doanh thu'] }
            ])
          } else {
            form.setFields([{ name: ['revenues', index, 'toValue'], errors: undefined }])
          }
        }
      })
    }
  }

  // Content revenue type
  const renderRevenue = () => {
    return (
      <div>
        <Label className='form-label mt-2 '>{ManageProgramVN.form.productProgram}</Label>
        <div className='table-responsive'>
          <Table className='table  mb-0'>
            <thead className='table-light'>
              <tr>
                <th>STT</th>
                <th>{ManageProgramVN.form.industrySector.label}</th>
                <th>{ManageProgramVN.form.category.label}</th>
                <th>{ManageProgramVN.form.model.label}</th>
                <th>Đơn giá sản phẩm{type !== 'view' && <a className='text-danger'>*</a>}</th>
              </tr>
            </thead>
            <tbody>
              <Form.List name={'unitPrices'}>
                {(subFields) => {
                  return (
                    <>
                      {subFields?.map((subField, index) => {
                        const item = model.find((e) => e?.id === watchForm?.unitPrices?.[index]?.modelId)
                        return (
                          <>
                            <tr className='position-relative'>
                              <th scope='row'>{index + 1}</th>

                              <td style={{ maxWidth: 200 }}>
                                {
                                  industrySector.find(
                                    (e) => e?.id === category.find((e) => e?.id === item?.parentId)?.parentId
                                  )?.name
                                }
                              </td>
                              <td style={{ maxWidth: 200 }}>{category.find((e) => e?.id === item?.parentId)?.name}</td>
                              <td style={{ maxWidth: 250 }}>{item?.name}</td>
                              <td style={{ maxWidth: 200 }}>
                                <Form.Item
                                  name={[subField.name, 'unitPrice']}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Vui lòng nhập số lượng'
                                    },
                                    { pattern: /^[1-9]\d*$/, message: 'Vui lòng nhập số nguyên dương' }
                                  ]}
                                >
                                  {/* <FormInput placeholder='Nhập đơn giá sản phẩm' disabled={!isEdit} /> */}
                                  <FormInputNumberCurrency
                                    min={1}
                                    maxLength={MAX_LENGTH_20}
                                    placeholder='Nhập đơn giá sản phẩm'
                                    disabled={!isEdit}
                                  />
                                </Form.Item>
                              </td>
                            </tr>
                          </>
                        )
                      })}
                    </>
                  )
                }}
              </Form.List>
            </tbody>
          </Table>
        </div>
        <Label className='form-label mt-4'>{ManageProgramVN.form.configProfit}</Label>
        <Col sm='8'>
          <div className='table-responsive'>
            <Table className='table  mb-0'>
              <thead className='table-light'>
                <tr>
                  <th>STT</th>
                  <th>
                    Từ doanh thu (VND)
                    {type !== 'view' && <a className='text-danger'>*</a>}
                  </th>
                  <th>
                    Đến doanh thu (VND)
                    {type !== 'view' && <a className='text-danger'>*</a>}
                  </th>
                  <th>
                    Mức hoa hồng (%)
                    {type !== 'view' && <a className='text-danger'>*</a>}
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <Form.List name={'revenues'}>
                  {(subFields, { add, remove }) => {
                    return (
                      <>
                        {subFields.map((subField, index) => {
                          return (
                            <>
                              <tr className='position-relative'>
                                <th scope='row'>
                                  <span style={{ marginTop: '12px', display: 'block' }}>{index + 1}</span>
                                </th>

                                <td style={{ maxWidth: 200 }}>
                                  <Form.Item
                                    name={[subField.name, 'fromValue']}
                                    // rules={[{ required: true, message: "Vui lòng nhập doanh thu" }]}
                                  >
                                    <FormInputNumberCurrency
                                      min={1}
                                      maxLength={MAX_LENGTH_20}
                                      placeholder='Nhập doanh thu'
                                      disabled={!isEdit}
                                      onChange={(e) => {
                                        // handleValidateNumberRevenue()

                                        if (e) {
                                          form.setFieldValue(['revenues', index, 'toValue'], Number(e) + 1)
                                        }
                                      }}
                                    />
                                  </Form.Item>
                                </td>
                                <td style={{ maxWidth: 250 }}>
                                  <Form.Item
                                    name={[subField.name, 'toValue']}
                                    // rules={[{ required: true, message: "Vui lòng nhập doanh thu" }]}
                                  >
                                    <FormInputNumberCurrency
                                      min={1}
                                      maxLength={MAX_LENGTH_20}
                                      placeholder='Nhập doanh thu'
                                      disabled={!isEdit}
                                      onChange={() => handleValidateNumberRevenue()}
                                    />
                                  </Form.Item>
                                </td>
                                <td style={{ maxWidth: 200 }}>
                                  <Form.Item
                                    name={[subField.name, 'commission']}
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Vui lòng nhập % hoa hồng'
                                      },
                                      {
                                        pattern: /^(0*?[1-9]\d?|100)$/,
                                        message: 'Vui lòng nhập số nguyên dương nhỏ hơn 100'
                                      }
                                    ]}
                                  >
                                    <FormInput placeholder='Nhập % hoa hồng' disabled={!isEdit} />
                                  </Form.Item>
                                </td>
                                <td>
                                  {subField.name > 0 && isEdit && (
                                    <MinusCircleOutlined
                                      style={{ marginTop: '12px' }}
                                      onClick={() => remove(subField.name)}
                                    />
                                  )}
                                </td>
                              </tr>
                            </>
                          )
                        })}
                        {isEdit && (
                          <Form.Item>
                            <Button type='dashed' onClick={() => add()} block icon={<PlusOutlined />}></Button>
                          </Form.Item>
                        )}
                      </>
                    )
                  }}
                </Form.List>
              </tbody>
            </Table>
          </div>
        </Col>
      </div>
    )
  }

  return (
    <div>
      <Row>
        <Col sm='6'>
          <Label className='form-label  me-5'>{ManageProgramVN.form.programConfig.label}</Label>
          <Form.Item name='programConfig' rules={[{ required: true }]}>
            <Radio.Group
              rootClassName='fw-normal'
              className='fw-normal'
              options={ManageProgramVN.form.programConfig.options}
              onChange={(value) => {
                if (value.target.value === 'product') {
                  form.setFieldValue('rewardConditionType', 'number')
                  form.setFieldValue('products', [{ fromValue: undefined, toValue: undefined, money: undefined }])
                }
                if (value.target.value === 'revenue') {
                  form.setFieldValue('revenues', [{}])
                }
                if (value.target.value === 'package') {
                  form.setFieldValue('rewardType', 'money_object')
                }
              }}
              optionType='default'
              disabled={!isEdit || watchForm?.type === 'display' || !watchForm?.type}
            />
          </Form.Item>
        </Col>
        {watchForm?.programConfig === 'package' && (
          <Col sm='6'>
            <Label className='form-label  me-5'>{ManageProgramVN.form.programReward.label}</Label>
            <Form.Item name='rewardType'>
              <Radio.Group
                className='fw-normal'
                options={ManageProgramVN.form.programReward.options}
                defaultValue='money_object'
                disabled={!isEdit}
              />
            </Form.Item>
          </Col>
        )}
      </Row>
      {watchForm?.programConfig === 'product' && renderProduct()}
      {watchForm?.programConfig === 'package' && renderPackage()}
      {watchForm?.programConfig === 'revenue' && renderRevenue()}
    </div>
  )
}
export default ConfigTypeTab
