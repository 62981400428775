import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

import { GET_MANAGE_PRODUCT_ACTIVATION, GET_PRODUCT_ACTIVATION_DETAIL, GET_KTVS } from "./actionTypes"
import { getManageProductActivationSuccess, getManageProductActivationFail, getProductActivationDetailSuccess } from "./actions"

import { getProductActivationListAPI, getProductActivationDetailsAPI } from "helpers/backend_helper"

function* fetchProductActivationList({ payload: params }) {
    try {
        const response = yield call(getProductActivationListAPI, { ...params })
        yield put(getManageProductActivationSuccess(response))
    } catch (error) {
        yield put(getManageProductActivationFail(error?.data?.message))
    }
}

function* fetchProductActivationDetail({ payload: data }) {
    try {
        const response = yield call(getProductActivationDetailsAPI, { id: data.id })
        yield put(getProductActivationDetailSuccess(response.data))
    } catch (error) {
        console.log(error)
        // yield put(getProductActivationDetailFail(error))
    }
}

function* ProductActivationsSaga() {
    yield takeLatest(GET_MANAGE_PRODUCT_ACTIVATION, fetchProductActivationList)
    yield takeEvery(GET_PRODUCT_ACTIVATION_DETAIL, fetchProductActivationDetail)
}

export default ProductActivationsSaga
