import { COOKIE_KEYS_TOKEN } from 'constants/constants'
import { parseCookies } from 'helpers/global_helper'
import React from 'react'
import { Navigate } from 'react-router-dom'

const Authmiddleware = (props) => {
  if (!parseCookies(null)[COOKIE_KEYS_TOKEN]) {
    return <Navigate to={{ pathname: '/login', state: { from: props.location } }} />
  }
  return <React.Fragment>{props.children}</React.Fragment>
}

export default Authmiddleware
