export const GET_MANAGE_HISTORY = "GET_HISTORYLIST"
export const GET_MANAGE_HISTORY_FAIL = "GET_MANAGE_HISTORY_FAIL"
export const GET_MANAGE_HISTORY_SUCCESS = "GET_MANAGE_HISTORY_SUCCESS"

export const GET_HISTORY_DETAIL = "GET_HISTORY_DETAIL"
export const GET_HISTORY_DETAIL_SUCCESS = "GET_HISTORY_DETAIL_SUCCESS"
export const GET_HISTORY_DETAIL_FAIL = "GET_HISTORY_DETAIL_FAIL"

export const UPDATE_MANAGE_HISTORY = "UPDATE_MANAGE_HISTORY"
export const UPDATE_MANAGE_HISTORY_SUCCESS = "UPDATE_MANAGE_HISTORY_SUCCESS"
export const UPDATE_MANAGE_HISTORY_FAIL = "UPDATE_MANAGE_HISTORY_FAIL"

export const SET_TYPE_POPUP = "SET_TYPE_POPUP_HISTORY"
