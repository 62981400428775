import {
    GET_MANAGE_PROGRAM,
    GET_MANAGE_PROGRAM_FAIL,
    GET_MANAGE_PROGRAM_SUCCESS,
    GET_PROGRAM_DETAIL,
    GET_PROGRAM_DETAIL_SUCCESS,
    GET_PROGRAM_DETAIL_FAIL,
} from "./actionTypes"

export const getManageProgram = params => ({
    type: GET_MANAGE_PROGRAM,
    payload: params,
})

export const getManageProgramSuccess = jobs => ({
    type: GET_MANAGE_PROGRAM_SUCCESS,
    payload: jobs,
})

export const getManageProgramFail = error => ({
    type: GET_MANAGE_PROGRAM_FAIL,
    payload: error,
})

export const getProgramDetail = data => ({
    type: GET_PROGRAM_DETAIL,
    payload: data,
})

export const getProgramDetailSuccess = job => ({
    type: GET_PROGRAM_DETAIL_SUCCESS,
    payload: job,
})

export const getProgramDetailFail = error => ({
    type: GET_PROGRAM_DETAIL_FAIL,
    payload: error,
})
