import React, { useEffect, useMemo, useState } from 'react'
import {
  Alert,
  Badge,
  Card,
  CardBody,
  Col,
  Container,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
  UncontrolledTooltip
} from 'reactstrap'

//redux
import { useDispatch } from 'react-redux'
import withRouter from 'components/Common/withRouter'

//Import Breadcrumb
import Breadcrumb from '../../components/Common/Breadcrumb'
import { ManageShopVN } from 'constants/vn'
import { Status } from './ManageShopCol'

// Formik validation
import { useFormik } from 'formik'
import * as Yup from 'yup'

// styles
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css'
import { getShopDetail } from 'store/actions'
import { createSelector } from 'reselect'
import { useSelector } from 'react-redux'
import ConfirmModal from 'components/Common/ConfirmModal'
import {
  lockAccountAPI,
  putRequestAPI,
  putShopDetailAPI,
  resetPasswordAPI,
  acceptMemberAPI,
  getUserRankAPI,
  lockShopAccountAPI,
  switchUserToTechnicianAPI
} from 'helpers/backend_helper'
import ImageEmty from 'components/Common/ImageEmty'
import { checkIsLocked, formateDate } from 'helpers/global_helper'
import { toast } from 'react-toastify'
import { REGEX_EMAIL, REGEX_NUMBER } from 'constants/regex'
import {
  IMAGE_RATIO,
  MAX_LENGTH_50,
  MAX_LENGTH_20,
  MAX_LENGTH_ADDRESS,
  MAX_LENGTH_CARDID,
  MAX_LENGTH_EMAIL,
  MAX_LENGTH_NAME,
  MAX_LENGTH_SEARCH,
  MAX_LENGTH_TAXCODE,
  MIN_LENGTH_CARDID
} from 'constants/constants'

import { debounce, isEqual } from 'lodash'
import { Form, Select, Image } from 'antd'
import { FormSelect } from 'components/Common/form-select/FormSelect'
import './shopDetail.scss'
import { FormInput } from 'components/Common/form-input/FormInput'
import moment from 'moment'
import ModalHistoryRequest from './ModalHistoryRequest'
import PermissionWrapper from 'components/Common/PermissionWrapper'
import { USER_ACCOUNT_MANAGEMENT_CODE, SCREEN_CODE } from 'constants/permission-code'

const ShopDetail = (props) => {
  //meta title
  document.title = 'Shop Detail | HPG'

  const screenCode = useMemo(() => SCREEN_CODE.USER_ACCOUNT_MANAGEMENT, [])
  const isEdit = props.router.location.pathname.includes('edit')
  const params = props.router.params
  const navigate = props.router.navigate

  const [form] = Form.useForm()
  const watchForm = Form.useWatch([], form)

  const dispatch = useDispatch()

  const selectShopDetailState = (state) => state.ShopReducer
  const ShopDetailProperties = createSelector(selectShopDetailState, (shop) => ({
    shopDetail: shop.shopDetail
  }))

  const { shopDetail } = useSelector(ShopDetailProperties)

  const selectProfileState = (state) => state.Profile
  const ProfileProperties = createSelector(selectProfileState, (profile) => ({
    profile: profile.profile
  }))

  const { profile } = useSelector(ProfileProperties)

  const selectLayoutState = (state) => state.Layout
  const LayoutProperties = createSelector(selectLayoutState, (app) => ({
    province: app.province,
    district: app.district,
    ward: app.ward,
    bank: app.bank
  }))
  const { province, district, ward, bank } = useSelector(LayoutProperties)

  const [showChangePassword, setShowChangePassword] = useState(false)
  const [showLockAccount, setShowLockAccount] = useState(false)
  const [showReject, setShowReject] = useState(false)
  const [submittable, setSubmittable] = useState(false)
  const [initData, setInitData] = useState()
  const [typePaper, setTypePaper] = useState('idcard')
  const [showApprove, setShowApprove] = useState(false)
  const [onCancel, setOnCancel] = useState(false)
  const [showMember, setShowMember] = useState(false)
  const [userRank, setUserRank] = useState()
  const [showHistoryRequest, setShowHistoryRequest] = useState(false)
  const [showChangeAccount, setShowChangeAccount] = useState(false)

  const closePopup = () => {
    setShowChangePassword(false)
    setShowLockAccount(false)
    setShowReject(false)
    setShowApprove(false)
    setShowApprove(false)
    setShowChangeAccount(false)
  }

  const onLockAccount = async (note) => {
    try {
      const res = await lockShopAccountAPI({
        id: params.id,
        status: checkIsLocked(shopDetail.status) ? 'active' : 'inactive',
        note
      })
      if (res) {
        toast(checkIsLocked(shopDetail.status) ? 'Tài khoản đã được mở khóa' : 'Tài khoản đã bị tạm khóa', {
          type: 'success'
        })
        dispatch(getShopDetail(params.id))
      }
    } catch (error) {
      console.log(error)
      Alert(error.response.data.message)
    }
    closePopup()
  }

  const onChangePassword = async () => {
    try {
      const res = await resetPasswordAPI({ id: params.id })
      if (res) {
        toast('Đặt lại mật khẩu thành công', { type: 'success' })
        dispatch(getShopDetail(params.id))
      }
    } catch (error) {
      console.log(error)
      toast(error.response.data.message, { type: 'error' })
    }
    closePopup()
  }

  const onUpdateRequest = debounce(async (status, note) => {
    try {
      const res = await putRequestAPI({
        user_id: parseInt(params.id, 10),
        status,
        note
      })
      if (res) {
        toast(status == 'approve' ? 'Duyệt yêu cầu cập nhật thành công' : 'Từ chối yêu cầu cập nhật', {
          type: 'success'
        })
        dispatch(getShopDetail(params.id))
      }
    } catch (error) {
      console.log(error)
      toast(error.response.data.message, { type: 'error' })
    }
    closePopup()
  }, 500)

  const mapsData = (data) => {
    setTypePaper(data.documentType ? data.documentType : 'idcard')
    return {
      name: data.name,
      cardId: data.cardId,
      phone: data.phone,
      shopName: data.shopName,
      taxCode: data.taxCode,
      email: data.email,
      provinceId: data.provinceId,
      districtId: data.districtId,
      wardId: data.wardId,
      address: data.address,
      bankId: data.bankId,
      accountName: data.accountName,
      accountNumber: data.accountNumber,
      documentType: data.documentType,
      documentValue: data.documentValue,
      documentType: data.documentType,
      documentValue: data.documentValue
    }
  }

  const onFinish = async (values) => {
    try {
      const res = await putShopDetailAPI({
        ...values,
        wardId: parseInt(values.wardId, 10),
        provinceId: parseInt(values.provinceId, 10),
        districtId: parseInt(values.districtId, 10),
        bankId: parseInt(values.bankId, 10),
        id: parseInt(params.id, 10),
        client: 'user',
        identityImages: shopDetail.identityImages,
        shopImages: shopDetail.shopImages
      })
      if (res) {
        toast('Cập nhật thông tin tài khoản thành công', { type: 'success' })
        form.resetFields()

        dispatch(getShopDetail(params.id))
      }
    } catch (error) {
      toast(error.response.data.message, { type: 'error' })
    }
  }

  useEffect(() => {
    if (shopDetail) {
      setInitData(mapsData(shopDetail))
      form.setFieldsValue(mapsData(shopDetail))
      getRankUser()
    }
  }, [shopDetail])

  useEffect(() => {
    dispatch(getShopDetail(params.id))
  }, [dispatch])
  // console.log(validation.touched, "touched", Object.keys(validation.touched)?.length > 0, validation)

  useEffect(() => {
    form.validateFields({ validateOnly: true }).then(
      () => {
        setSubmittable(true)
      },
      () => {
        setSubmittable(false)
      }
    )
  }, [watchForm])

  const onClickCancel = () => {
    setOnCancel(false)
    navigate(-1)
  }

  const checkClickCancel = () => {
    if (isEdit) {
      if (!isEqual(initData, watchForm)) {
        setOnCancel(true)
      } else {
        navigate(-1)
      }
    } else {
      navigate(-1)
    }
  }

  const onAcceptMember = async () => {
    try {
      const res = await acceptMemberAPI({ user_id: shopDetail.id })
      if (res) {
        toast('Duyệt hội viên thành công.', { type: 'success' })
        dispatch(getShopDetail(params.id))
        setShowMember(false)
      }
    } catch (error) {
      toast(error.response.data.message, { type: 'error' })
    }
  }

  const getRankUser = async () => {
    try {
      const res = await getUserRankAPI({ id: shopDetail?.id })

      if (res) {
        setUserRank(res.data)
      }
    } catch (error) {
      toast(error.response.data.message, { type: 'error' })
    }
  }

  const onChangeAccount = async () => {
    try {
      const res = await switchUserToTechnicianAPI({ id: params.id })
      if (res) {
        toast('Chuyển tài khoản thành công', { type: 'success' })
        dispatch(getShopDetail(params.id))
      }
    } catch (error) {
      console.log(error)
      toast(error.response.data.message, { type: 'error' })
    }
    closePopup()
  }

  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title={ManageShopVN.title} breadcrumbItem={isEdit ? ManageShopVN.edit : ManageShopVN.view} />
          <Row>
            <Col lg='3' className='pb-4 pe-4'>
              <Card>
                <CardBody className='d-flex flex-column align-items-center'>
                  <h4>{ManageShopVN.shop}</h4>
                  <i className='mdi mdi-100px mdi-storefront mt-2 icon-store'></i>
                  <h5
                    className='col-12 text-truncate text-center mt-2'
                    id={`nameshop-tooltip-${shopDetail?.employeeCode}`}
                  >
                    {shopDetail?.shopName}
                  </h5>
                  <UncontrolledTooltip
                    trigger='hover'
                    placement='top'
                    target={`nameshop-tooltip-${shopDetail?.employeeCode}`}
                  >
                    {shopDetail?.shopName}
                  </UncontrolledTooltip>
                  <div className='d-flex flex-row'>
                    <div>{ManageShopVN.ID} &nbsp;</div>
                    <div>{shopDetail?.employeeCode}</div>
                  </div>
                  {userRank &&
                    userRank.length > 0 &&
                    userRank.map((item, index) => {
                      return (
                        <div className='mt-2' style={{ fontWeight: 600 }} key={index}>
                          {item?.point} điểm - {item?.rankName} - {item?.industrySectorName}
                        </div>
                      )
                    })}
                  <div className='d-flex flex-row mt-2'>
                    {shopDetail?.isMember ? (
                      <Badge className='badge-soft-success me-2'>{ManageShopVN.member}</Badge>
                    ) : (
                      <Badge className='badge-soft-warning me-2'>{ManageShopVN.noMember}</Badge>
                    )}
                    <Status value={shopDetail?.status} />
                  </div>
                  <span className='mt-2'>Ngày tạo tài khoản {moment(shopDetail?.createdAt).format('DD/MM/YYYY')}</span>
                  {!shopDetail?.isMember && props.router.location.pathname.includes('view') && (
                    <button
                      onClick={() => setShowMember(true)}
                      className='btn btn-danger mt-2 w-50'
                      disabled={
                        shopDetail?.status === 'terminated' ||
                        shopDetail?.status === 'inactive' ||
                        shopDetail?.status === 'pending-removal'
                      }
                    >
                      Duyệt hội viên
                    </button>
                  )}
                  <PermissionWrapper screenCode={screenCode} code={USER_ACCOUNT_MANAGEMENT_CODE.USERS_RESET_PASSWORD}>
                    <button
                      onClick={() => setShowChangePassword(true)}
                      className='btn btn-danger mt-2 w-50'
                      disabled={
                        shopDetail?.status === 'terminated' ||
                        shopDetail?.status === 'inactive' ||
                        shopDetail?.status === 'pending-removal'
                      }
                    >
                      {ManageShopVN.changePassword}
                    </button>
                  </PermissionWrapper>
                  <PermissionWrapper screenCode={screenCode} code='ADMIN_SET_STATUS'>
                    <button
                      disabled={shopDetail?.status === 'terminated' || shopDetail?.status === 'pending-removal'}
                      onClick={() => setShowLockAccount(true)}
                      className='btn btn-danger mt-2 w-50'
                    >
                      {shopDetail?.status === 'inactive' ? ManageShopVN.openAccount : ManageShopVN.lockAccount}
                    </button>
                  </PermissionWrapper>
                  <button
                    onClick={() => setShowChangeAccount(true)}
                    className='btn btn-danger mt-2 w-50'
                    disabled={shopDetail?.status === 'terminated' || shopDetail?.status === 'pending-removal'}
                  >
                    {'Đổi loại tài khoản'}
                  </button>
                </CardBody>
              </Card>
              <div className='d-flex flex-column align-items-center '>
                <h5>{ManageShopVN.shopImages}</h5>
                <Row className='w-100 '>
                  <Col sm='6' className=' pe-2 p-0 d-flex justify-content-start' style={{ height: 120 }}>
                    {shopDetail?.shopImages?.[0] ? (
                      // <img
                      //     src={shopDetail?.shopImages?.[0]}
                      //     className="rounded-1 "
                      //     alt="shop-image-1"
                      //     height={"100%"}
                      //     width={"100%"}
                      // />
                      <Image
                        src={shopDetail?.shopImages?.[0]}
                        className='rounded-1'
                        alt='shop-image-1'
                        width={'100%'}
                        height={'100%'}
                        style={{ objectFit: 'cover' }}
                      />
                    ) : (
                      <ImageEmty />
                    )}
                  </Col>
                  <Col sm='6' className='ps-2 p-0 d-flex justify-content-start' style={{ height: 120 }}>
                    {shopDetail?.shopImages?.[1] ? (
                      // <img
                      //     src={shopDetail?.shopImages?.[1]}
                      //     className="rounded-1"
                      //     alt="shop-image-2"
                      //     height={"100%"}
                      //     width={"100%"}
                      // />
                      <Image
                        src={shopDetail?.shopImages?.[1]}
                        className='rounded-2'
                        alt='shop-image-2'
                        width={'100%'}
                        height={'100%'}
                        style={{ objectFit: 'cover' }}
                      />
                    ) : (
                      <ImageEmty />
                    )}
                  </Col>
                </Row>
              </div>
              <div className='d-flex flex-column align-items-center pt-4'>
                <h5>{ManageShopVN.indentifications}</h5>
                <Row className='w-100 '>
                  <Col sm='6' className=' pe-2 p-0' style={{ height: 120 }}>
                    {shopDetail?.identityImages?.[0] ? (
                      // <img
                      //     src={shopDetail?.identityImages?.[0]}
                      //     className="rounded-2 "
                      //     height={"100%"}
                      //     width={"100%"}
                      //     alt="id-image-1"
                      // />
                      <Image
                        src={shopDetail?.identityImages?.[0]}
                        className='rounded-1'
                        alt='id-image-1'
                        width={'100%'}
                        height={'100%'}
                        style={{ objectFit: 'cover' }}
                      />
                    ) : (
                      <ImageEmty />
                    )}
                    <div className='text-center mt-2'>{ManageShopVN.font}</div>
                  </Col>
                  <Col sm='6' className='ps-2 p-0' style={{ height: 120 }}>
                    {shopDetail?.identityImages?.[1] ? (
                      // <img
                      //     src={shopDetail?.identityImages?.[1]}
                      //     className="rounded-2"
                      //     alt="id-image-2"
                      //     height={"100%"}
                      //     width={"100%"}
                      // />
                      <Image
                        src={shopDetail?.identityImages?.[1]}
                        className='rounded-2'
                        alt='id-image-2'
                        width={'100%'}
                        height={'100%'}
                        style={{ objectFit: 'cover' }}
                      />
                    ) : (
                      <ImageEmty />
                    )}
                    <div className='text-center mt-2'>{ManageShopVN.back}</div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col lg='9'>
              <Form onFinish={onFinish} autoComplete='off' form={form} name='shopForm'>
                <Card>
                  <CardBody>
                    <div className='d-flex justify-content-between'>
                      <h5>{ManageShopVN.personalInfor.title}</h5>
                      {isEdit && (
                        <div>
                          <button
                            type='button'
                            // disabled={isEqual(initData, watchForm) || !submittable}
                            onClick={debounce((e) => {
                              // e.preventDefault()
                              // e.preventDefault()
                              checkClickCancel()
                            }, 500)}
                            className='btn btn-soft-primary me-2'
                          >
                            {ManageShopVN.cancel}
                          </button>
                          <button
                            disabled={isEqual(initData, watchForm)}
                            type='button'
                            className='btn btn-primary'
                            onClick={debounce(() => {
                              form.submit()
                            }, 500)}
                          >
                            {ManageShopVN.save}
                          </button>
                        </div>
                      )}
                    </div>
                    <div className='form-group '>
                      <Row>
                        <Col sm='4'>
                          <Label className='form-label'>{ManageShopVN.personalInfor.form.name}</Label>
                          <Form.Item name='name' rules={[{ required: true, message: 'Vui lòng nhập họ và tên' }]}>
                            <FormInput
                              placeholder={`Nhập ${ManageShopVN.personalInfor.form.name}`}
                              maxLength={MAX_LENGTH_NAME}
                              disabled={!isEdit}
                            />
                          </Form.Item>
                        </Col>
                        <Col sm='4'>
                          <Label className='form-label'>{ManageShopVN.personalInfor.form.cccd}</Label>
                          <Form.Item
                            name='cardId'
                            rules={[
                              { required: true, message: 'Vui lòng nhập CMND/CCCD' },
                              {
                                type: 'string',
                                min: 12,
                                pattern: new RegExp(REGEX_NUMBER),
                                message: 'Số CMND/CCCD nhập sai định dạng'
                              }
                            ]}
                          >
                            <FormInput
                              placeholder={`Nhập ${ManageShopVN.personalInfor.form.cccd}`}
                              maxLength={MAX_LENGTH_CARDID}
                              disabled={!isEdit}
                            />
                          </Form.Item>
                        </Col>
                        <Col sm='4'>
                          <Label className='form-label'>{ManageShopVN.personalInfor.form.phone}</Label>
                          <Form.Item name='phone'>
                            <FormInput disabled={true} placeholder={`Nhập ${ManageShopVN.personalInfor.form.phone}`} />
                          </Form.Item>
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <h5>{ManageShopVN.shopInfor.title}</h5>
                    <div className='form-group '>
                      <Row className='mt-4'>
                        <Col sm='4'>
                          <Label className='form-label '>{ManageShopVN.shopInfor.form.shopName}</Label>
                          <Form.Item
                            name='shopName'
                            rules={[{ required: true, message: 'Vui lòng nhập tên cửa hàng' }]}
                          >
                            <FormInput
                              placeholder={`Nhập ${ManageShopVN.shopInfor.form.shopName}`}
                              disabled={!isEdit}
                              maxLength={MAX_LENGTH_SEARCH}
                            />
                          </Form.Item>
                        </Col>
                        <Col sm='4'>
                          <Label className='form-label'>{ManageShopVN.shopInfor.form.taxCode}</Label>
                          <Form.Item
                            name='taxCode'
                            rules={[
                              { required: true, message: 'Vui lòng nhập mã số thuế' },
                              {
                                type: 'string',
                                pattern: new RegExp(/^\d{1,13}$/),
                                message: 'Mã số thuế nhập sai định dạng'
                              }
                            ]}
                          >
                            <FormInput
                              name={'taxCode'}
                              maxLength={MAX_LENGTH_TAXCODE}
                              disabled={!isEdit}
                              placeholder={`Nhập ${ManageShopVN.shopInfor.form.taxCode}`}
                            />
                          </Form.Item>
                        </Col>
                        <Col sm='4'>
                          <Label className='form-label'>{ManageShopVN.shopInfor.form.email}</Label>
                          <Form.Item
                            name='email'
                            rules={[
                              { required: true, message: 'Vui lòng nhập email' },
                              { pattern: REGEX_EMAIL, message: 'Email nhập sai định dạng' }
                            ]}
                          >
                            <FormInput
                              maxLength={MAX_LENGTH_EMAIL}
                              placeholder={`Nhập ${ManageShopVN.shopInfor.form.email}`}
                              disabled={!isEdit}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row className='mt-4'>
                        <Col sm='4'>
                          <Label className='form-label '>{ManageShopVN.shopInfor.form.city}</Label>

                          <Form.Item
                            name='provinceId'
                            rules={[{ required: true, message: 'Vui lòng chọn Tỉnh/Thành phố' }]}
                          >
                            <FormSelect
                              options={province?.map((item) => ({
                                value: item.id,
                                label: item.name
                              }))}
                              onChange={(value) => {
                                form.setFieldValue('wardId', undefined)
                                form.setFieldValue('districtId', undefined)
                              }}
                              disabled={!isEdit}
                              placeholder={`Chọn ${ManageShopVN.shopInfor.form.city}`}
                            />
                          </Form.Item>
                        </Col>
                        <Col sm='4'>
                          <Label className='form-label'>{ManageShopVN.shopInfor.form.district}</Label>
                          <Form.Item
                            name='districtId'
                            rules={[{ required: true, message: 'Vui lòng chọn Quận/Huyện' }]}
                          >
                            <FormSelect
                              options={district
                                ?.filter((e) => e?.parentId == watchForm?.provinceId)
                                ?.map((item) => ({
                                  value: item.id,
                                  label: item.name
                                }))}
                              onChange={(value) => {
                                form.setFieldValue('wardId', undefined)
                              }}
                              disabled={!isEdit}
                              placeholder={`Chọn ${ManageShopVN.shopInfor.form.district}`}
                            />
                          </Form.Item>
                        </Col>
                        <Col sm='4'>
                          <Label className='form-label'>{ManageShopVN.shopInfor.form.ward}</Label>
                          <Form.Item name='wardId' rules={[{ required: true, message: 'Vui lòng chọn Phường/Xã' }]}>
                            <FormSelect
                              options={ward
                                ?.filter((e) => e?.parentId == watchForm?.districtId)
                                ?.map((item) => ({
                                  value: item.id,
                                  label: item.name
                                }))}
                              disabled={!isEdit}
                              placeholder={`Chọn ${ManageShopVN.shopInfor.form.ward}`}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Label className='form-label mt-4'>{ManageShopVN.shopInfor.form.address}</Label>
                      <Form.Item name='address' rules={[{ required: true, message: 'Vui lòng nhập địa chỉ cụ thể' }]}>
                        <FormInput
                          disabled={!isEdit}
                          maxLength={MAX_LENGTH_ADDRESS}
                          placeholder={`Nhập ${ManageShopVN.shopInfor.form.address}`}
                        />
                      </Form.Item>
                    </div>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <h5>{ManageShopVN.billingInfor.title}</h5>
                    <Row className='mt-4'>
                      <Col sm='4'>
                        <Label className='form-label'>{ManageShopVN.billingInfor.form.bank}</Label>
                        <Form.Item name='bankId' rules={[{ required: true, message: 'Vui lòng chọn ngân hàng' }]}>
                          <FormSelect
                            options={bank.map((item) => ({
                              value: item.id,
                              label: item.name
                            }))}
                            disabled={!isEdit}
                          />
                        </Form.Item>
                      </Col>
                      <Col sm='4'>
                        <Label className='form-label'>{ManageShopVN.billingInfor.form.accountOwner}</Label>
                        <Form.Item
                          name='accountName'
                          rules={[
                            { required: true, message: 'Vui lòng nhập người thụ hưởng' },
                            { pattern: /^[a-zA-Z\s]+$/, message: 'Người thụ hưởng nhập là chữ không dấu' }
                          ]}
                        >
                          <FormInput
                            maxLength={MAX_LENGTH_50}
                            disabled={!isEdit}
                            placeholder={`Nhập ${ManageShopVN.billingInfor.form.accountOwner}`}
                            onBlur={(e) => form.setFieldValue('accountName', e.target.value.toUpperCase())}
                          />
                        </Form.Item>
                      </Col>
                      <Col sm='4'>
                        <Label className='form-label'>{ManageShopVN.billingInfor.form.accountNumber}</Label>
                        <Form.Item
                          name='accountNumber'
                          rules={[{ required: true, message: 'Vui lòng nhập số tài khoản' }]}
                        >
                          <FormInput
                            maxLength={MAX_LENGTH_20}
                            placeholder={`Nhập ${ManageShopVN.billingInfor.form.accountNumber}`}
                            disabled={!isEdit}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row className='mt-4'>
                      <Col sm='4'>
                        <Label className='form-label'>{ManageShopVN.billingInfor.form.paper}</Label>
                        <Form.Item
                          name='documentType'
                          rules={[{ required: true, message: 'Vui lòng chọn loại giấy tờ xác minh người thụ hưởng' }]}
                        >
                          <FormSelect
                            // value={typePaper}
                            defaultValue={typePaper}
                            options={ManageShopVN.billingInfor.paperOptions}
                            disabled={!isEdit}
                            onChange={(value) => {
                              setTypePaper(value)
                              form.setFieldValue('documentValue', undefined)
                            }}
                          />
                        </Form.Item>
                      </Col>
                      <Col sm='4'>
                        <Label className='form-label'>{typePaper == 'idcard' ? 'CMND/CCCD' : 'Mã số thuế'}</Label>
                        <Form.Item
                          name='documentValue'
                          rules={[
                            {
                              required: true,
                              message: `Vui lòng nhập ${typePaper == 'idcard' ? 'CMND/CCCD' : 'Mã số thuế'}`
                            },
                            {
                              pattern: new RegExp(typePaper == 'idcard' ? /^\d{12}$/ : /^\d{1,13}$/),
                              message: `${typePaper == 'idcard' ? 'CMND/CCCD' : 'Mã số thuế'} nhập sai định dạng`
                            }
                          ]}
                        >
                          <FormInput
                            maxLength={typePaper == 'idcard' ? 12 : 13}
                            disabled={!isEdit}
                            placeholder={`Nhập ${typePaper == 'idcard' ? 'CMND/CCCD' : 'Mã số thuế'}`}
                          />
                        </Form.Item>
                      </Col>
                      <Col sm='4'></Col>
                    </Row>
                  </CardBody>
                </Card>
              </Form>
              <Card>
                <CardBody>
                  <div className='d-flex align-items-center justify-content-between'>
                    <h5 className='d-lfex algin-items-center'>
                      {ManageShopVN.requestChange}
                      <PermissionWrapper
                        screenCode={screenCode}
                        code={USER_ACCOUNT_MANAGEMENT_CODE.ADMIN_SEARCH_UPDATE_HISTORY}
                      >
                        <i
                          onClick={() => setShowHistoryRequest(true)}
                          className='mdi mdi-history'
                          id={`history-request-change`}
                          style={{ marginLeft: '4px', cursor: 'pointer' }}
                        />
                        <UncontrolledTooltip trigger='hover' placement='top' target={`history-request-change`}>
                          Lịch sử thay đổi thông tin
                        </UncontrolledTooltip>
                      </PermissionWrapper>
                    </h5>
                    {shopDetail?.request && (
                      <PermissionWrapper
                        screenCode={screenCode}
                        code={USER_ACCOUNT_MANAGEMENT_CODE.ADMIN_APPROVE_OR_REJECT_PROFILE}
                      >
                        <div>
                          <button
                            disabled={shopDetail?.status === 'inactive' || shopDetail?.status === 'terminated'}
                            onClick={() => setShowReject(true)}
                            className='btn btn-danger me-1'
                          >
                            {ManageShopVN.reject}
                          </button>
                          <button
                            disabled={shopDetail?.status === 'inactive' || shopDetail?.status === 'terminated'}
                            onClick={() => setShowApprove(true)}
                            className='btn btn-success '
                          >
                            {ManageShopVN.accept}
                          </button>
                        </div>
                      </PermissionWrapper>
                    )}
                  </div>
                  {shopDetail?.request ? (
                    <div className='overflow-x-auto' style={{ maxHeight: 200 }}>
                      <div className='fst-italic'>{formateDate(shopDetail?.request?.dateRequest ?? '')}</div>
                      {shopDetail?.request?.dataChange?.map((e) => {
                        console.log('e', e)
                        if (e.name === 'Ảnh CMND/CCCD' || e.name === 'Ảnh Cửa hàng') {
                          return (
                            <Col key={e.name} className=' mt-2 '>
                              <Label className='form-label'>{e.name}</Label>
                              <Row>
                                {e.value?.map((image, index) => {
                                  return (
                                    <img
                                      key={`id-image-request-${index}-${e.name}`}
                                      src={image}
                                      className='rounded-2'
                                      style={{ width: 80 * IMAGE_RATIO, height: 80 }}
                                      alt={`id-image-request-${index}-${e.name}`}
                                    />
                                  )
                                })}
                              </Row>
                            </Col>
                          )
                        } else {
                          return (
                            <Row key={e.name} className=' mt-2 '>
                              <Col sm='3'>
                                <Label className='form-label'>{e.name}</Label>
                              </Col>
                              <Col sm='9'>
                                <div>{e.value}</div>
                              </Col>
                            </Row>
                          )
                        }
                      })}
                    </div>
                  ) : (
                    <div className='fst-italic'>{ManageShopVN.noRequest}</div>
                  )}
                </CardBody>
              </Card>
              {shopDetail?.status === 'inactive' && (
                <Card>
                  <CardBody>
                    <h5>{ManageShopVN.adminChange.title}</h5>
                    <div className='ms-4'>
                      <Row className=' mt-2 '>
                        <Col sm='3'>
                          <Label className='form-label'>{ManageShopVN.adminChange.lockedAt}</Label>
                        </Col>
                        <Col sm='9'>
                          <div>{formateDate(shopDetail.updatedAt)}</div>
                        </Col>
                      </Row>
                      <Row className=' mt-2 '>
                        <Col sm='3'>
                          <Label className='form-label'>{ManageShopVN.adminChange.admin}</Label>
                        </Col>
                        <Col sm='9'>
                          <div>{shopDetail.adminChangeStatus?.email}</div>
                        </Col>
                      </Row>
                      <Row className=' mt-2 '>
                        <Col sm='3'>
                          <Label className='form-label'>{ManageShopVN.adminChange?.note}</Label>
                        </Col>
                        <Col sm='9'>
                          <div>{shopDetail?.noteFromAdmin}</div>
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                </Card>
              )}
            </Col>
          </Row>
          <ConfirmModal
            show={showLockAccount}
            onConfirmClick={onLockAccount}
            onCloseClick={closePopup}
            hasInput={
              shopDetail?.status === 'active' || shopDetail?.status === 'pending' || shopDetail?.status === 'registered'
            }
            title={shopDetail?.status === 'inactive' ? 'Mở khóa tài khoản người dùng' : 'Khóa tài khoản người dùng'}
            description={`Bạn có chắc chắn muốn ${
              shopDetail?.status == 'inactive' ? 'mở khóa' : 'khóa'
            } tài khoản người dùng?`}
          />
          <ConfirmModal
            show={showChangePassword}
            onConfirmClick={onChangePassword}
            onCloseClick={closePopup}
            icon={undefined}
            title='Xác nhận đặt lại mật khẩu'
            description={`Bạn có chắc chắn muốn đặt lại mật khẩu cho người dùng này?\n Mật khẩu mới sẽ được gửi đến số điện thoại của người dùng`}
          />
          <ConfirmModal
            show={showReject}
            onConfirmClick={(note) => onUpdateRequest('reject', note)}
            onCloseClick={closePopup}
            hasInput
            title={'Từ chối cập nhật thông tin'}
            description='Bạn có chắc chắn muốn từ chối cập nhật thông tin cho người dùng?'
          />
          <ConfirmModal
            show={showApprove}
            onConfirmClick={(note) => onUpdateRequest('approve')}
            onCloseClick={closePopup}
            title={'Duyệt cập nhật thông tin'}
            description='Thông tin người dùng sẽ được cập nhật. Bạn có chắc chắn muốn phê duyệt yêu cầu cập nhật thông tin cho người dùng?'
          />
          <ConfirmModal
            show={onCancel}
            onConfirmClick={onClickCancel}
            onCloseClick={() => setOnCancel(false)}
            title={`Xác nhận`}
            description={`Thay đổi sẽ không được lưu. Bạn có chắc chắn muốn thoát không?`}
          />
          <ConfirmModal
            show={showMember}
            onConfirmClick={onAcceptMember}
            onCloseClick={() => setShowMember(false)}
            title='Duyệt hội viên'
            hasIcon={false}
            description={`Xác nhận duyệt hội viên cho tài khoản ${
              shopDetail?.phone ?? ''
            }.\n Bạn có đồng ý duyệt hội viên?`}
          />
          <ConfirmModal
            show={showChangeAccount}
            onConfirmClick={onChangeAccount}
            onCloseClick={closePopup}
            // hasChangeAccountElement={true}
            title={'Đổi tài khoản'}
            description={`Bạn có chắc chắn muốn đổi loại tài khoản?`}
          />
          {showHistoryRequest && (
            <ModalHistoryRequest
              id={params.id}
              show={showHistoryRequest}
              onCloseClick={() => setShowHistoryRequest(false)}
            />
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(ShopDetail)
