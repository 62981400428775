import React, { useState } from 'react'
import { Col, Label } from 'reactstrap'

//flatpickr
import 'flatpickr/dist/themes/material_blue.css'
import { ActivationVN, CommonVN } from 'constants/vn'
import { createSelector } from 'reselect'
import { useSelector } from 'react-redux'
import { FormSelect } from './form-select/FormSelect'
import { DatePicker } from 'antd'
import { FormDateRange } from './form-date-range/FormDateRange'
const { RangePicker } = DatePicker

const ActivationFilter = ({ setGlobalFilter, search, setSearch, isActivationFilter }) => {
  const selectLayoutState = (state) => state.Layout
  const LayoutProperties = createSelector(selectLayoutState, (app) => ({
    zone: app.zone
  }))
  const { zone } = useSelector(LayoutProperties)

  const [filter, setFilter] = useState({})

  const handleSelect = (value, key) => {
    setFilter((e) => ({ ...e, [key]: value }))
  }
  return (
    <React.Fragment>
      {isActivationFilter && (
        <Col xxl={2} className='mt-3' lg={2}>
          <Label className='form-label'>{ActivationVN.filter.type.label}</Label>
          <FormSelect
            options={[
              {
                label: 'Cửa hàng',
                value: 'user'
              },
              {
                label: 'Kĩ thuật viên',
                value: 'technician'
              }
            ]}
            value={filter.client}
            onChange={(value) => {
              handleSelect(value, 'client')
            }}
            placeholder={ActivationVN.filter.type.placeholder}
          />
        </Col>
      )}
      <Col xxl={2} className='mt-3' lg={2}>
        <Label className='form-label'>{ActivationVN.filter.accountType.label}</Label>
        <FormSelect
          options={zone?.map((item) => ({
            value: item.id,
            label: item.name
          }))}
          value={filter.zone_id}
          onChange={(value) => {
            handleSelect(value, 'zone_id')
          }}
          placeholder={ActivationVN.filter.accountType.placeholder}
        />
      </Col>
      <Col xxl={3} className='mt-3' lg={3}>
        <Label className='form-label'>
          {isActivationFilter ? ActivationVN.filter.accumulationTime : 'Thời gian tích lũy'}
        </Label>
        <FormDateRange
          className='w-100'
          size='large'
          format={CommonVN.datePlaceholder.toUpperCase()}
          placeholder={[CommonVN.datePlaceholder, CommonVN.datePlaceholder]}
          value={[filter.start_date, filter.end_date]}
          onChange={(value) => {
            if (value) {
              handleSelect(value[0], 'start_date')
              handleSelect(value[1], 'end_date')
            } else {
              handleSelect(undefined, 'start_date')
              handleSelect(undefined, 'end_date')
            }
          }}
        />
      </Col>
      <Col
        xxl={isActivationFilter ? 3 : 5}
        lg={isActivationFilter ? 5 : 7}
        className='d-flex align-items-end justify-content-end mt-3'
      >
        <div className='mb-xxl-0 me-2'>
          <button
            type='button'
            className='btn btn-soft-danger w-100'
            onClick={() => {
              setFilter({
                zone_id: undefined,
                status: undefined
              })
              setSearch(undefined)
              setGlobalFilter({})
            }}
          >
            Xóa tìm kiếm
          </button>
        </div>
        <div className='mb-xxl-0'>
          <button
            type='button'
            className='btn btn-primary w-100'
            onClick={() => {
              const _filter = !filter
                ? filter
                : Object.fromEntries(
                    Object.entries(filter).filter(([key, value]) => value !== undefined && value !== '')
                  )
              setGlobalFilter({ ..._filter, search })
            }}
          >
            Tìm kiếm
          </button>
        </div>
      </Col>
    </React.Fragment>
  )
}
export default ActivationFilter
