import {
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_API_ERROR,
  CHANGE_PASSWORD_SUCCESS,
} from "./actionTypes"

export const changePassword = (form, history) => {
  return {
    type: CHANGE_PASSWORD,
    payload: { form, history },
  }
}

export const changePasswordSuccess = res => {
  return {
    type: CHANGE_PASSWORD_SUCCESS,
    payload: res,
  }
}

export const changePasswordApiError = error => {
  return {
    type: CHANGE_PASSWORD_API_ERROR,
    payload: error,
  }
}
