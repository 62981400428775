import { StatusAccumulationOptions } from 'constants/constants'
import { AccumulationVN, ManageShopVN } from 'constants/vn'
import { formateDate, formateDateTime } from 'helpers/global_helper'
import React from 'react'
import { Badge, Input, UncontrolledTooltip } from 'reactstrap'
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css'

const Serial = (cell) => {
  return (
    <>
      {cell.row.original?.userRewardIsDeleted && (
        <>
          <i
            className='mdi mdi-recycle-variant'
            style={{ marginRight: '4px', color: 'red' }}
            id={`viewtooltip-bravo-${cell.row.original.id}`}
          />
          <UncontrolledTooltip trigger='hover' placement='top' target={`viewtooltip-bravo-${cell.row.original.id}`}>
            Sản phẩm bị thu hồi
          </UncontrolledTooltip>
        </>
      )}
      <span>{cell.value ? cell.value : '--'}</span>
    </>
  )
}

const AccumulationIndustrySector = (cell) => {
  return cell.value ? cell.value : '--'
}

const AccumulationCategory = (cell) => {
  return cell.value ? cell.value : '--'
}

const AccumulationModel = (cell) => {
  return cell.value ? cell.value : '--'
}

const Shop = (cell) => {
  return (
    <div>
      <p className='m-0'>{cell.value ? cell.value : cell.row.original?.usersName}</p>
      <span>{cell.row.original?.usersPhone}</span>
      <p>
        <span>
          {cell.row.original?.zoneName} - {cell.row.original?.subZoneName} - {cell.row.original?.provinceName} -{' '}
          {cell.row.original?.districtName} - {cell.row.original?.wardName}
        </span>
      </p>
    </div>
  )
}

const Ratio = (cell) => {
  return (
    <div>
      <p className='m-0'>
        <Badge className='badge-soft-success'>
          Hoàn thành: {cell.value ? cell.value : cell.row.original?.totalCompleted}
        </Badge>
      </p>
      <p className='m-0'>
        <Badge className='badge-soft-danger'>
          Thất bại: {cell.value ? cell.value : cell.row.original?.totalFailed}
        </Badge>
      </p>
      <p className='m-0'>
        <Badge className='badge-soft-info'>
          Đang xử lý: {cell.value ? cell.value : cell.row.original?.totalProcessing}
        </Badge>
      </p>
      <p className='m-0'>
        <Badge className='badge-soft-warning'>
          Pending: {cell.value ? cell.value : cell.row.original?.totalPending}
        </Badge>
      </p>
    </div>
  )
}

const Status = (cell) => {
  switch (cell.value) {
    case 'pending':
      return <Badge className='badge-soft-info'>{'Đang xử lý'}</Badge>
    case 'failed':
      return <Badge className='badge-soft-warning'>{'Thất bại'}</Badge>
    case 'completed':
      return <Badge className='badge-soft-success'>{'Hoàn thành'}</Badge>
  }
}

const Zone = (cell) => {
  return cell.value ? cell.value : '--'
}
const ActivatedDate = (cell) => {
  return cell.value ? formateDateTime(cell.value) : '--'
}
const StatusAccumulation = (cell) => {
  switch (cell.value) {
    case 'no_reward':
      return <Badge className='badge-soft-secondary'>{StatusAccumulationOptions[0].label}</Badge>
    case 'waiting_for_control':
      return <Badge className='badge-soft-info'>{StatusAccumulationOptions[1].label}</Badge>
    case 'for_control':
      return <Badge className='badge-soft-success'>{StatusAccumulationOptions[2].label}</Badge>
    case 'payed':
      return <Badge className='badge-soft-warning'>{StatusAccumulationOptions[3].label}</Badge>
  }
}

const StatusImportFile = (cell) => {
  switch (cell.value) {
    case 'pendding':
      return <Badge className='badge-soft-warning'>{'Đang chờ xử lý'}</Badge>
    case 'completed':
      return <Badge className='badge-soft-success'>{'Hoàn thành'}</Badge>
    case 'failed':
      return <Badge className='badge-soft-danger'>{'Thất bại'}</Badge>
  }
}

export {
  AccumulationModel,
  AccumulationCategory,
  Shop,
  Zone,
  AccumulationIndustrySector,
  StatusAccumulation,
  Serial,
  ActivatedDate,
  Ratio,
  StatusImportFile,
  Status
}
