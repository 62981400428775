import PropTypes from 'prop-types'
import React, { Suspense, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import { Routes, Route, useLocation } from 'react-router-dom'
import { layoutTypes } from './constants/layout'
// Import Routes all
import { authProtectedRoutes, publicRoutes } from './routes'

// Import all middleware
import Authmiddleware from './routes/route'

// layouts Format
import VerticalLayout from './components/VerticalLayout/'
import HorizontalLayout from './components/HorizontalLayout/'
import NonAuthLayout from './components/NonAuthLayout'

// Import scss
import './assets/scss/theme.scss'

// Import Firebase Configuration file
// import { initFirebaseBackend } from "./helpers/firebase_helper";

import fakeBackend from './helpers/AuthType/fakeBackend'
import { getProfile, initApp } from 'store/actions'
import { COOKIE_KEYS_REFRESH_TOKEN, COOKIE_KEYS_TOKEN } from 'constants/constants'
import { parseCookies, removeCookieUtil } from 'helpers/global_helper'
import Loading from 'components/Common/Loading'
import permissionRoutes from 'routes/permission-routes'
import Forbidden from 'components/Common/Forbiden'
import NotFound from 'components/Common/NotFound/NotFound'
import { refreshTokenErrorEvent } from 'helpers/api_helper'
import { useNavigate } from 'react-router-dom'

// Activating fake backend
fakeBackend()

// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_APIKEY,
//   authDomain: process.env.REACT_APP_AUTHDOMAIN,
//   databaseURL: process.env.REACT_APP_DATABASEURL,
//   projectId: process.env.REACT_APP_PROJECTID,
//   storageBucket: process.env.REACT_APP_STORAGEBUCKET,
//   messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
//   appId: process.env.REACT_APP_APPID,
//   measurementId: process.env.REACT_APP_MEASUREMENTID,
// };

// init firebase backend
// initFirebaseBackend(firebaseConfig);

export const getLayout = (layoutType) => {
  let Layout = VerticalLayout
  switch (layoutType) {
    case layoutTypes.VERTICAL:
      Layout = VerticalLayout
      break
    case layoutTypes.HORIZONTAL:
      Layout = HorizontalLayout
      break
    default:
      break
  }
  return Layout
}

const selectorProfile = (state) => state.Profile

const profileProperties = createSelector(selectorProfile, ({ profile }) => ({
  permissionUser: profile?.permissions?.map((x) => x.code).filter((item) => permissionRoutes.includes(item))
}))

const App = () => {
  const selectLayoutState = (state) => state.Layout
  const LayoutProperties = createSelector(selectLayoutState, (layout) => ({
    layoutType: layout.layoutType
  }))
  const dispatch = useDispatch()
  const { layoutType } = useSelector(LayoutProperties)
  const Layout = getLayout(layoutType)
  const location = useLocation()
  const allLinks = useMemo(() => authProtectedRoutes.map((item) => item.path), [])
  const [isMatchRoute, setIsMatchRoute] = useState(true)
  const [delay, setDelay] = useState(false)
  const navigate = useNavigate()

  const { permissionUser } = useSelector(profileProperties)

  useEffect(() => {
    dispatch(getProfile())
    if (parseCookies(null)[COOKIE_KEYS_TOKEN]) {
      dispatch(initApp())
      // dispatch(getProfile())
    }
  }, [dispatch, parseCookies(null)[COOKIE_KEYS_TOKEN]])

  useEffect(() => {
    setTimeout(() => {
      setDelay(true)
    }, 1000)

    const clearCookie = () => {
      removeCookieUtil(COOKIE_KEYS_REFRESH_TOKEN)
      removeCookieUtil(COOKIE_KEYS_TOKEN)
      navigate('/login')
    }

    refreshTokenErrorEvent.addEventListener('unauthorizedEvent', clearCookie)
    return () => {
      refreshTokenErrorEvent.removeEventListener('unauthorizedEvent', clearCookie)
    }
  }, [])

  useEffect(() => {
    if (allLinks.includes(location.pathname)) {
      setIsMatchRoute(true)
    } else {
      setIsMatchRoute(false)
    }
  }, [location])

  const permissionRoute = useMemo(() => {
    if (permissionUser === undefined) return []
    return authProtectedRoutes.filter((route) => {
      if (route.permissionCode === undefined) return true
      return permissionUser.includes(route.permissionCode)
    })
  }, [permissionUser])
  const RedirectToGoogleDrive = () => {
    useEffect(() => {
      window.location = 'https://drive.google.com';
    }, []);
  
    return null;
  };
  
  return (
    <React.Fragment>
      <Routes>
        {publicRoutes.map((route, idx) => (
          <Route path={route.path} element={<NonAuthLayout>{route.component}</NonAuthLayout>} key={idx} exact={true} />
        ))}

        {permissionRoute.map((route, idx) => (
          <Route
            path={route.path}
            element={
              <Authmiddleware>
                <Layout>
                  <Suspense fallback={<Loading />}>{route.component}</Suspense>
                </Layout>
              </Authmiddleware>
            }
            key={idx}
            exact={true}
          />
        ))}
        {delay && <Route path='*' element={isMatchRoute ? <Forbidden /> : <NotFound />}></Route>}


      </Routes>
    </React.Fragment>
  )
}

App.propTypes = {
  layout: PropTypes.any
}

export default App
