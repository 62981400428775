import PropsType from 'prop-types'
import { useEffect, useMemo, useState } from 'react'
import { Label, UncontrolledTooltip } from 'reactstrap'
import React from 'react'
import { Row, Col, Space, Flex } from 'antd'

//flatpickr
import { CommonVN } from 'constants/vn'

import { DatePicker } from 'antd'
import { FormInput } from 'components/Common/form-input/FormInput'
import { FormSelect } from 'components/Common/form-select/FormSelect'
import { MAX_LENGTH_SEARCH, TypeProgramOptions } from 'constants/constants'
import { FormDateRange } from 'components/Common/form-date-range/FormDateRange'
import { getPortfolioAllAPI } from 'helpers/backend_helper'

const { RangePicker } = DatePicker

ManageHistoryFilter.propTypes = {
  handleFilter: PropsType.func,
  handleConfirm: PropsType.func,
  handleResetFilter: PropsType.func,
  handleExport: PropsType.func
}

function ManageHistoryFilter({ handleFilter, handleConfirm, handleResetFilter, handleExport }) {
  const [filter, setFilter] = useState({})
  const [model, setModel] = useState()
  const [modelOptions, setModelOptions] = useState()

  const onFilterChange = (name, value) =>
    setFilter((prev) => ({
      ...prev,
      [name]: value
    }))

  const ColProps = useMemo(() => ({
    xs: { span: 24 },
    xxl: { span: 8 }
  }))

  const onResetFilter = () =>
    setFilter({
      search: '',
      filter: undefined,
      status: undefined,
      start_date1: undefined,
      end_date1: undefined,
      model_ids: undefined,
      model_names: undefined,
      type_name: undefined
    })

  const getIndustryDataOptions = async () => {
    try {
      const resModel = await getPortfolioAllAPI({ type: 'model' })
      setModel(resModel?.data)

      if (resModel?.data?.length > 0) {
        setModelOptions(
          [{ label: 'Tất cả', value: 'all' }].concat(
            resModel?.data?.map((item) => ({
              value: item.id,
              label: item.name
            }))
          )
        )
      } else {
        setModelOptions([])
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getIndustryDataOptions()
  }, [])

  return (
    <Row gutter={[10, 10]} align='bottom'>
      <Col {...ColProps} className='w-100'>
        <Label className='form-label'>Nhập Model</Label>
        <FormSelect
          placeholder='Nhập Model'
          options={modelOptions}
          onChange={(value, label) => {
            onFilterChange('model_ids', value)
            onFilterChange(
              'model_names',
              label.map((e) => e.label)
            )
          }}
          onSelect={(value) => {
            const all = model?.map((item) => item.id)
            const all_names = model?.map((item) => item.name)
            if (value && value === 'all') {
              onFilterChange('model_ids', [...all])
              onFilterChange('model_names', [...all_names])
            }
          }}
          value={filter.model_ids}
          mode='multiple'
        />
      </Col>

      <Col {...ColProps} className='w-100'>
        <Label className='form-label'>Nhập Serial</Label>
        <FormInput
          className='w-100'
          maxLength={MAX_LENGTH_SEARCH}
          placeholder='Nhập Serial'
          value={filter?.search || ''}
          name='search'
          id={`input-search-filter`}
          onChange={(e) => onFilterChange('search', e.target.value)}
          onBlur={(e) => onFilterChange('search', e.target.value)}
        />
        <UncontrolledTooltip trigger='hover' placement='top' target={`input-search-filter`}>
          {''}
        </UncontrolledTooltip>
      </Col>

      <Col {...ColProps} className='w-100'>
        <Label className='form-label'>{'Loại'}</Label>
        <FormSelect
          options={[
            {
              label: 'Tích lũy',
              value: 'accumulation'
            },
            {
              label: 'Kích hoạt',
              value: 'activation'
            },
            {
              label: 'Trưng bày',
              value: 'display'
            }
          ]}
          value={filter.type}
          onChange={(value, label) => {
            onFilterChange('type', value)
            onFilterChange('type_name', label.label)
          }}
          placeholder='Tất cả'
        />
      </Col>

      <Col {...ColProps} className='w-100'>
        <Label className='form-label'>Số điện thoại cửa hàng</Label>
        <FormInput
          className='w-100'
          maxLength={MAX_LENGTH_SEARCH}
          placeholder='Nhập SĐT'
          value={filter?.user || ''}
          name='user'
          id={`input-user-filter`}
          onChange={(e) => onFilterChange('user', e.target.value)}
          onBlur={(e) => onFilterChange('user', e.target.value)}
        />
        <UncontrolledTooltip trigger='hover' placement='top' target={`input-user-filter`}>
          {''}
        </UncontrolledTooltip>
      </Col>

      <Col {...ColProps} className='w-100'>
        <Label className='form-label'>Nhập tên nhân viên, SĐT</Label>
        <FormInput
          className='w-100'
          maxLength={MAX_LENGTH_SEARCH}
          placeholder='Tên nhân viên, SĐT'
          value={filter?.updated_by || ''}
          name='updated_by'
          id={`input-updated-by-filter`}
          onChange={(e) => onFilterChange('updated_by', e.target.value)}
          onBlur={(e) => onFilterChange('updated_by', e.target.value)}
        />
        <UncontrolledTooltip trigger='hover' placement='top' target={`input-updated-by-filter`}>
          {''}
        </UncontrolledTooltip>
      </Col>

      <Col {...ColProps} className='w-100'>
        <Label className='form-label'>{'Thời gian thực hiện'}</Label>
        <FormDateRange
          className='w-100'
          size='large'
          format={CommonVN.datePlaceholder.toUpperCase()}
          placeholder={[CommonVN.datePlaceholder, CommonVN.datePlaceholder]}
          value={[filter.start_date1, filter.end_date1]}
          onChange={(value) => {
            if (value) {
              onFilterChange('start_date1', value[0])
              onFilterChange('end_date1', value[1])
            } else {
              onFilterChange('start_date1', undefined)
              onFilterChange('end_date1', undefined)
            }
          }}
        />
      </Col>

      <Col xxl={6} className='w-100'>
        <Space>
          <button
            type='button'
            className='btn btn-soft-danger'
            onClick={() => {
              onResetFilter()
              handleResetFilter()
            }}
          >
            Xóa tìm kiếm
          </button>

          <button type='button' className='btn btn-primary' onClick={() => handleFilter(filter)}>
            Tìm kiếm
          </button>

          <button type='button' className='btn btn-primary' onClick={() => handleExport(filter)}>
            <i className='mdi mdi-database-export me-1 ms-2' />
            Export
          </button>
        </Space>
      </Col>
      {/* <Col flex='1'>
        <Flex gap='small' justify='flex-end'>
        </Flex>
      </Col> */}
    </Row>
  )
}

export default ManageHistoryFilter
