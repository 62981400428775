import React from "react"
import moment from "moment"

const BaseCol = cell => {
  return cell.value ? cell.value : '--';
}

const TypeCol = cell => {
  switch (cell.value) {
    case 'user':
      return 'Cửa hàng'
    case 'technician':
      return 'Kỹ thuật viên'
    default:
      return '--'
  }
}

const NoCol = cell => {
  return (
    <span className="text-body fw-bold">
      {cell?.row ? cell.row.index + 1 : ""}
    </span>
  )
}

const DateCol = cell => {
  return cell.value ? moment(cell.value).format('DD-MM-YYYY') : '--';
}

export { BaseCol, NoCol, DateCol, TypeCol }