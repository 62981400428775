import React, { useState } from "react"
import { Col, Label } from "reactstrap"

//flatpickr
import "flatpickr/dist/themes/material_blue.css"
import { LocalityVN } from "constants/vn"
import { FormSelect } from "./form-select/FormSelect"

const SelectGlobalFilter = ({ setGlobalFilter, search, setSearch, option, titleSearchSelect, placeholderSearchSelect }) => {
    const [filter, setFilter] = useState({})

    const handleSelect = (value, key) => {
        setFilter(e => ({ ...e, [key]: value }))
    }

    return (
        <React.Fragment>
            <Col xxl={2} lg={2} className="mt-3">
                <Label className="form-label">{titleSearchSelect}</Label>
                <FormSelect
                    options={option}
                    value={filter.select}
                    onChange={value => {
                        handleSelect(value, "select")
                    }}
                    placeholder={placeholderSearchSelect}
                />
            </Col>
            <Col xxl={2} lg={2}>
            </Col>
            <Col xxl={2} lg={2}>
            </Col>
            <Col xxl={2} lg={2}>
            </Col>
            <Col xxl={2} lg={4} className="d-flex align-items-end justify-content-end mt-3">
                <div className="mb-xxl-0 me-2">
                    <button
                        type="button"
                        className="btn btn-soft-danger w-100"
                        onClick={() => {
                            setFilter({
                                status: undefined,
                                province_id: undefined,
                            })
                            setSearch("")
                            setGlobalFilter({})
                        }}
                    >
                        Xóa tìm kiếm
                    </button>
                </div>
                <div className="mb-xxl-0">
                    <button
                        type="button"
                        className="btn btn-primary w-100"
                        onClick={() => {
                            const _filter = !filter
                                ? filter
                                : Object.fromEntries(Object.entries(filter).filter(([key, value]) => value !== undefined))
                            setGlobalFilter({ ..._filter, search })
                        }}
                    >
                        Tìm kiếm
                    </button>
                </div>
            </Col>
        </React.Fragment>
    )
}
export default SelectGlobalFilter
