import React from 'react'
import { Badge, UncontrolledTooltip } from 'reactstrap'
import { Link } from 'react-router-dom'
import { ManageContentVN } from 'constants/vn'
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css'
import { formateDate } from 'helpers/global_helper'
import { TypeContentOptions, TypeProgramOptions } from 'constants/constants'
import moment from 'moment'

const ContentNo = (cell) => {
  return (
    <div className='d-flex justify-content-between align-items-center'>
      <div className='text-body fw-bold'>{cell?.row ? cell.row.index + 1 + (cell.page - 1) * 10 : ''}</div>
    </div>
  )
}

const BaseString = (cell) => {
  return cell.value ? cell.value : '--'
}

const Datetime = (cell) => {
  return cell.value ? moment(cell.value).format('DD/MM/YY HH:mm:ss') : '--'
}

const ProvinceName = (cell) => {
  return cell.value ? (
    <div>
      <span
        style={{ maxWidth: 300 }}
        className='d-inline-block text-truncate'
        id={`namecontent-tooltip-${cell.row.original.id}`}
      >
        {cell.value.name}
      </span>
      <UncontrolledTooltip placement='top' target={`namecontent-tooltip-${cell.row.original.id}`}>
        {cell.value.name}
      </UncontrolledTooltip>
    </div>
  ) : (
    '--'
  )
}

const ContentName = (cell) => {
  return cell.value ? (
    <div>
      <span
        style={{ maxWidth: 300 }}
        className='d-inline-block text-truncate'
        id={`namecontent-tooltip-${cell.row.original.id}`}
      >
        {cell.value}
      </span>
      <UncontrolledTooltip placement='top' target={`namecontent-tooltip-${cell.row.original.id}`}>
        {cell.value}
      </UncontrolledTooltip>
    </div>
  ) : (
    '--'
  )
}

const DisplayDate = (cell) => {
  return cell.value ? formateDate(cell.value) + '-' + formateDate(cell.row.original?.endDate) : '--'
}

const UpdatetedDate = (cell) => {
  return cell.value ? formateDate(cell.value) : '--'
}

const Type = (cell) => {
  const type = [
    {
      label: 'Tích lũy',
      value: 'accumulation'
    },
    {
      label: 'Kích hoạt',
      value: 'activation'
    },
    {
      label: 'Trưng bày',
      value: 'display'
    },
    {
      label: 'Tin tức',
      value: 'news'
    },
    {
      label: 'Khác',
      value: 'other'
    }
  ]
  return cell?.value ? type.find((e) => e.value === cell?.value)?.label : '--'
}

const Status = (cell) => {
  switch (cell.value) {
    case 'pending':
      return <Badge className='badge-soft-info'>{'Chưa gửi'}</Badge>
    case 'success':
      return <Badge className='badge-soft-success'>{'Đã gửi'}</Badge>
    case 'cancelled':
      return <Badge className='badge-soft-warning'>{'Đã huỷ'}</Badge>
    case 'failed':
      return <Badge className='badge-soft-danger'>{'Không thành công'}</Badge>
  }
}

const ListReceivers = (cell) => {
  let concatenated = ''

  for (let i = 0; i < cell?.value?.length; i++) {
    // Concatenate the code of the current object to the string
    concatenated += cell?.value[i]?.phone
    concatenated += ' <br />'
    // // Add a comma and space if it's not the last object
    // if (i !== cell?.value[i]?.programmingPackageConfig[packageConfig]?.products?.length - 1) {
    //   concatenatedReward += ' <br />'
    // }
  }

  const resultArr = []
  const arr = concatenated.split(/<br \/>/)
  arr.forEach((item, i) => {
    resultArr.push(<br />)
    resultArr.push(item)
  })
  return <p> {resultArr} </p>
}

const ClientType = (cell) => {
  const type = [
    {
      label: 'Cửa hàng',
      value: 'user'
    },
    {
      label: 'Kĩ thuật viên',
      value: 'technician'
    }
  ]

  return cell?.value ? type.find((e) => e.value === cell?.value)?.label : '--'
}

const ClientName = (cell) => {
  if (cell?.row?.original?.client === 'user') {
    return cell?.row?.original?.shopName ? cell?.row?.original?.shopName : '--'
  } else {
    return cell?.row?.original?.userName ? cell?.row?.original?.userName : '--'
  }
}

const AvgUser = (cell) => {
  return cell.value ? cell.value?.countUser + '/' + cell.value?.countUserLastmonth : '--'
}

const AvgAccumulation = (cell) => {
  return cell.value ? cell.value?.countAccumulation + '/' + cell.value?.countAccumulationLastmonth : '--'
}

const AvgActivation = (cell) => {
  return cell.value ? cell.value?.countActivation + '/' + cell.value?.countActivationLastmonth : '--'
}

export {
  ContentNo,
  ContentName,
  DisplayDate,
  Status,
  UpdatetedDate,
  Type,
  ListReceivers,
  BaseString,
  Datetime,
  ProvinceName,
  ClientType,
  AvgUser,
  AvgAccumulation,
  AvgActivation,
  ClientName
}
