import React, { useState } from 'react'
import { Col, Label } from 'reactstrap'

//flatpickr
import 'flatpickr/dist/themes/material_blue.css'
import { ActivationVN, CommonVN } from 'constants/vn'
import { createSelector } from 'reselect'
import { useSelector } from 'react-redux'
import { FormSelect } from './form-select/FormSelect'
import { DatePicker } from 'antd'
import { StatusProgramOptions, TypeProgramOptions } from 'constants/constants'
import { FormDateRange } from './form-date-range/FormDateRange'

const { RangePicker } = DatePicker

const ProgramBonusTempFilter = ({ setGlobalFilter, search, setSearch, isActivationFilter }) => {
  const selectLayoutState = (state) => state.Layout
  const LayoutProperties = createSelector(selectLayoutState, (app) => ({
    zone: app.zone
  }))
  const { zone } = useSelector(LayoutProperties)

  const [filter, setFilter] = useState({})

  const handleSelect = (value, key) => {
    setFilter((e) => ({ ...e, [key]: value }))
  }

  return (
    <React.Fragment>
      <Col xxl={2} className='mt-3' lg={2}>
        <Label className='form-label'>{'Loại chương trình'}</Label>
        <FormSelect
          options={TypeProgramOptions}
          value={filter.type}
          onChange={(value, label) => {
            handleSelect(value, 'type')
            handleSelect(label.label, 'type_name')
          }}
          placeholder={'Chọn loại chương trình'}
        />
      </Col>
      <Col xxl={3} className='mt-3' lg={3}>
        <Label className='form-label'>
          {isActivationFilter ? ActivationVN.filter.accumulationTime : 'Thời gian áp dụng'}
        </Label>
        <FormDateRange
          className='w-100'
          size='large'
          format={CommonVN.datePlaceholder.toUpperCase()}
          placeholder={[CommonVN.datePlaceholder, CommonVN.datePlaceholder]}
          value={[filter.start_date, filter.end_date]}
          onChange={(value) => {
            if (value) {
              handleSelect(value[0], 'start_date')
              handleSelect(value[1], 'end_date')
            } else {
              handleSelect(undefined, 'start_date')
              handleSelect(undefined, 'end_date')
            }
          }}
        />
      </Col>
      <Col
        xxl={isActivationFilter ? 3 : 5}
        lg={isActivationFilter ? 5 : 7}
        className='d-flex align-items-end justify-content-end mt-3'
      >
        <div className='mb-xxl-0 me-2'>
          <button
            type='button'
            className='btn btn-soft-danger w-100'
            onClick={() => {
              setFilter({
                type: undefined,
                type_name: undefined,
                start_date: undefined,
                end_date: undefined
              })
              setSearch(undefined)
              setGlobalFilter({})
            }}
          >
            Xóa tìm kiếm
          </button>
        </div>
        <div className='mb-xxl-0'>
          <button
            type='button'
            className='btn btn-primary w-100'
            onClick={() => {
              const _filter = !filter
                ? filter
                : Object.fromEntries(
                    Object.entries(filter).filter(([key, value]) => value !== undefined && value !== '')
                  )
              setGlobalFilter({ ..._filter, search })
            }}
          >
            Tìm kiếm
          </button>
        </div>
      </Col>
      <Col xxl={3} className='mt-3' lg={3}>
        <div className='mb-xxl-0'>
          <h5 className='mb-0 card-title flex-grow-1'>{'Danh sách chương trình'}</h5>
        </div>
      </Col>
    </React.Fragment>
  )
}
export default ProgramBonusTempFilter
