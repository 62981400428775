import {
    GET_MANAGE_BANK,
    GET_MANAGE_BANK_FAIL,
    GET_MANAGE_BANK_SUCCESS,
    GET_BANK_DETAIL,
    GET_BANK_DETAIL_SUCCESS,
    GET_BANK_DETAIL_FAIL,
    SET_TYPE_POPUP,
} from "./actionTypes"

export const getBankList = params => ({
    type: GET_MANAGE_BANK,
    payload: params,
})

export const getManageBankSuccess = data => ({
    type: GET_MANAGE_BANK_SUCCESS,
    payload: data,
})

export const getManageBankFail = error => ({
    type: GET_MANAGE_BANK_FAIL,
    payload: error,
})

export const getBankDetail = data => ({
    type: GET_BANK_DETAIL,
    payload: data,
})

export const getBankDetailSuccess = data => ({
    type: GET_BANK_DETAIL_SUCCESS,
    payload: data,
})

export const getBankDetailFail = error => ({
    type: GET_BANK_DETAIL_FAIL,
    payload: error,
})

export const setTypePopupBank = params => ({
    type: SET_TYPE_POPUP,
    payload: params,
})
