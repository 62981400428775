import {
    GET_MANAGE_UNIT,
    GET_MANAGE_UNIT_FAIL,
    GET_MANAGE_UNIT_SUCCESS,
    GET_UNIT_DETAIL,
    GET_UNIT_DETAIL_SUCCESS,
    GET_UNIT_DETAIL_FAIL,
    SET_TYPE_POPUP,
    GET_DEPARTMENT_ALL,
    GET_DEPARTMENT_ALL_FAIL,
    GET_DEPARTMENT_ALL_SUCCESS,
    GET_DEPARTMENT_ALL_LIST,
    SET_FILTERS_UNIT,
} from "./actionTypes"

export const getUnitList = params => ({
    type: GET_MANAGE_UNIT,
    payload: params,
})

export const getManageUnitSuccess = data => ({
    type: GET_MANAGE_UNIT_SUCCESS,
    payload: data,
})

export const getManageUnitFail = error => ({
    type: GET_MANAGE_UNIT_FAIL,
    payload: error,
})

export const getUnitDetail = data => ({
    type: GET_UNIT_DETAIL,
    payload: data,
})

export const getUnitDetailSuccess = data => ({
    type: GET_UNIT_DETAIL_SUCCESS,
    payload: data,
})

export const getUnitDetailFail = error => ({
    type: GET_UNIT_DETAIL_FAIL,
    payload: error,
})

export const setTypePopupUnit = params => ({
    type: SET_TYPE_POPUP,
    payload: params,
})

export const setFiltersUnit = params => ({
    type: SET_FILTERS_UNIT,
    payload: params,
})

export const getDepartmentAll = params => ({
    type: GET_DEPARTMENT_ALL,
    payload: params,
})

export const getDepartmentAllSuccess = params => ({
    type: GET_DEPARTMENT_ALL_SUCCESS,
    payload: params,
})

export const getDepartmentAllFail = params => ({
    type: GET_DEPARTMENT_ALL_FAIL,
    payload: params,
})

export const getDepartmentAllList = params => ({
    type: GET_DEPARTMENT_ALL_LIST,
    payload: params,
})
