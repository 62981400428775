export const GET_MANAGE_USER = "GET_USER_LIST"
export const GET_MANAGE_USER_FAIL = "GET_MANAGE_USER_FAIL"
export const GET_MANAGE_USER_SUCCESS = "GET_MANAGE_USER_SUCCESS"

export const GET_USER_DETAIL = "GET_USER_DETAIL"
export const GET_USER_DETAIL_SUCCESS = "GET_USER_DETAIL_SUCCESS"
export const GET_USER_DETAIL_FAIL = "GET_USER_DETAIL_FAIL"

export const UPDATE_MANAGE_USER = "UPDATE_MANAGE_USER"
export const UPDATE_MANAGE_USER_SUCCESS = "UPDATE_MANAGE_USER_SUCCESS"
export const UPDATE_MANAGE_USER_FAIL = "UPDATE_MANAGE_USER_FAIL"

export const SET_TYPE_POPUP = "SET_TYPE_POPUP_USER"
