export const GET_MANAGE_CONTACT = 'GET_CONTACT_LIST'
export const GET_MANAGE_CONTACT_FAIL = 'GET_MANAGE_CONTACT_FAIL'
export const GET_MANAGE_CONTACT_SUCCESS = 'GET_MANAGE_CONTACT_SUCCESS'

export const GET_CONTACT_DETAIL = 'GET_CONTACT_DETAIL'
export const GET_CONTACT_DETAIL_SUCCESS = 'GET_CONTACT_DETAIL_SUCCESS'
export const GET_CONTACT_DETAIL_FAIL = 'GET_CONTACT_DETAIL_FAIL'

export const UPDATE_MANAGE_CONTACT = 'UPDATE_MANAGE_CONTACT'
export const UPDATE_MANAGE_CONTACT_SUCCESS = 'UPDATE_MANAGE_CONTACT_SUCCESS'
export const UPDATE_MANAGE_CONTACT_FAIL = 'UPDATE_MANAGE_CONTACT_FAIL'

export const SET_TYPE_POPUP = 'SET_TYPE_POPUP_CONTACT'
