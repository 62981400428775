import {
    GET_MANAGE_LOCALITY_FAIL,
    GET_MANAGE_LOCALITY_SUCCESS,
    GET_LOCALITY_DETAIL_FAIL,
    UPDATE_MANAGE_LOCALITY_SUCCESS,
    UPDATE_MANAGE_LOCALITY_FAIL,
    SET_TYPE_POPUP,
    GET_LOCALITY_DETAIL_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
    localitys: [],
    localityDetail: undefined,
    type: "create",
    loading: true,
    total: 0,
}

const UserReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_MANAGE_LOCALITY_SUCCESS:
            return {
                ...state,
                localitys: action.payload.data,
                total: action.payload.meta.totalItems,
                loading: false,
            }

        case GET_MANAGE_LOCALITY_FAIL:
            return {
                ...state,
                error: action.payload,
            }

        case SET_TYPE_POPUP:
            return {
                ...state,
                type: action.payload,
            }
        case GET_LOCALITY_DETAIL_SUCCESS:
            return {
                ...state,
                localityDetail: action.payload,
            }

        case GET_LOCALITY_DETAIL_FAIL:
            return {
                ...state,
                error: action.payload,
            }

        case UPDATE_MANAGE_LOCALITY_SUCCESS:
            return {
                ...state,
                shops: state.shops.map(manageProduct =>
                    manageProduct.id.toString() === action.payload.id.toString() ? { manageProduct, ...action.payload } : manageProduct
                ),
            }

        case UPDATE_MANAGE_LOCALITY_FAIL:
            return {
                ...state,
                error: action.payload,
            }

        default:
            return state
    }
}

export default UserReducer
