import {
  GET_MANAGE_CONTACT_FAIL,
  GET_MANAGE_CONTACT_SUCCESS,
  GET_CONTACT_DETAIL_FAIL,
  UPDATE_MANAGE_CONTACT_SUCCESS,
  UPDATE_MANAGE_CONTACT_FAIL,
  SET_TYPE_POPUP,
  GET_CONTACT_DETAIL_SUCCESS
} from './actionTypes'

const INIT_STATE = {
  contacts: [],
  contactDetail: undefined,
  type: 'create',
  loading: true,
  total: 0
}

const ContactsReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_MANAGE_CONTACT_SUCCESS:
      return {
        ...state,
        contacts: action.payload.data,
        total: action.payload.meta.totalItems,
        loading: false
      }

    case GET_MANAGE_CONTACT_FAIL:
      return {
        ...state,
        error: action.payload
      }

    case SET_TYPE_POPUP:
      return {
        ...state,
        type: action.payload
      }
    case GET_CONTACT_DETAIL_SUCCESS:
      return {
        ...state,
        contactDetail: action.payload
      }

    case GET_CONTACT_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload
      }

    case UPDATE_MANAGE_CONTACT_SUCCESS:
      return {
        ...state,
        shops: state.shops.map((manageProduct) =>
          manageProduct.id.toString() === action.payload.id.toString()
            ? { manageProduct, ...action.payload }
            : manageProduct
        )
      }

    case UPDATE_MANAGE_CONTACT_FAIL:
      return {
        ...state,
        error: action.payload
      }

    default:
      return state
  }
}

export default ContactsReducer
