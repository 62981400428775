import React from "react"
import { useSelector } from "react-redux"
import { createSelector } from "reselect"
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import moment from "moment"
import { Badge, UncontrolledTooltip } from "reactstrap"
import { Tooltip } from "antd"

const PermissionNo = cell => {
    const selectLayoutState = state => state.Layout
    const LayoutProperties = createSelector(selectLayoutState, layout => ({
        filter: layout.filter,
    }))
    const { filter } = useSelector(LayoutProperties)

    return <div className="text-body fw-bold">{cell?.row ? cell.row.index + 1 + (filter.page - 1) * 10 : ""}</div>
}

const PermissionRightsGroup = cell => {
    return <span className="fw-bold">{cell.value ? cell.value : "--"}</span>
}

const PermissionDescribe = cell => {
    return cell.value ? (
        <div>
            {/* <span style={{ maxWidth: 300 }} className="d-inline-block text-truncate" id={`nameshop-tooltip-${cell.row.original.employeeCode}`}>
                {cell.value}
            </span>
            <UncontrolledTooltip trigger="hover" placement="top" target={`nameshop-tooltip-${cell.row.original.employeeCode}`}>
                {cell.value}
            </UncontrolledTooltip> */}
            <Tooltip title={cell.value}>
                <span style={{ maxWidth: 300 }} className="d-inline-block text-truncate">
                    {cell.value}
                </span>
            </Tooltip>
        </div>
    ) : (
        "--"
    )
}

const PermissionUpdateDate = cell => {
    return cell.value ? moment(cell.value).format("DD/MM/YYYY") : "--"
}

export { PermissionNo, PermissionRightsGroup, PermissionDescribe, PermissionUpdateDate }
