import React, { useEffect, useMemo, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

//import components

import { setTypePopupUser } from 'store/actions'

import { BaseCol, NoCol, DateCol, TypeCol } from './components/ManageAccountClass/ManageAccountCol'

//redux
import { Col, Row, UncontrolledTooltip, Card, CardBody, Label } from 'reactstrap'
import Spinners from 'components/Common/Spinner'
import { toast } from 'react-toastify'
import TableContainer from 'components/Common/TableContainer'
import { ClassStructureVN } from 'constants/vn'
import ConfirmModal from 'components/Common/ConfirmModal'
import { getRankConfigListAPI, getPortfolioAllAPI, deleteRankConfigAPI } from 'helpers/backend_helper'
import { FormSelect } from 'components/Common/form-select/FormSelect'
import { OptionTypeAccount } from './constants/index'
import { RANK_CONFIG_MANAGEMENT_CODE, SCREEN_CODE } from 'constants/permission-code'
import PermissionWrapper from 'components/Common/PermissionWrapper'
import { CreateDurationMoment } from 'pages/ReportTemporaryReconciliation/components/ManageAccountClass/ManageAccountCol'
import { getStorage, setStorage } from 'helpers/global_helper'

function ManageClassStructure() {
  //meta title
  document.title = 'Manage Account Class CMS List | Skote - React Admin & Dashboard Template'
  const screenCode = useMemo(() => SCREEN_CODE.RANK_CONFIG_MANAGEMENT, [])

  const navigator = useNavigate()

  const [isLoading, setLoading] = useState(false)
  const [filter, setFilter] = useState()
  const [listRankConfig, setListRankConfig] = useState([])
  const [total, setTotal] = useState()
  const [selected, setSelected] = useState()
  const [showDelete, setShowDelete] = useState(false)
  const [options, setOptions] = useState([])
  const [page, setPage] = useState(1)

  useEffect(() => {
    handleGetList()
  }, [filter])

  useEffect(() => {
    handleGetOptions()
  }, [])

  const handleGetList = async () => {
    try {
      const filterManageClassStructure = getStorage('ManageClassStructure')
      // setFilter({ filterManageClassStructure })

      const res = await getRankConfigListAPI(filterManageClassStructure)

      if (res.data) {
        setListRankConfig(res.data)
      }

      if (res.meta) {
        setTotal(res.meta.totalItems)
      }
    } catch (error) {
      toast(error.response.data.message, { type: 'error' })
    }
  }

  const handleGetOptions = async () => {
    try {
      const res = await getPortfolioAllAPI({ type: 'industrySector' })

      if (res.data) {
        const list = []
        res.data.map((item) => {
          list.push({
            label: item.name,
            value: item.id
          })
        })

        setOptions(list)
      }
    } catch (error) {
      toast(error.response.data.message, { type: 'error' })
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: ClassStructureVN.table.stt,
        accessor: 'stt',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return (page - 1) * 10 + cellProps.row.index + 1
        }
      },
      {
        Header: ClassStructureVN.table.accountType,
        accessor: 'client',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <TypeCol {...cellProps} />
        }
      },
      {
        Header: ClassStructureVN.table.industry,
        accessor: 'industrySector',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return cellProps.row.original.industrySector ? cellProps.row.original.industrySector.name : '--'
        }
      },
      {
        Header: 'Miền',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          const locations = cellProps.row.original.locations
          const Arr = Array.from(new Set(locations?.map((el) => el.zoneName)))

          if (Arr.length > 3) {
            return (
              <>
                <p id={`manage-class-structure-zoneName-${cellProps.row.original.id}`}>
                  {locations ? Arr.slice(0, 3).join(', ') + '...' : '--'}
                </p>
                <UncontrolledTooltip
                  trigger='hover'
                  placement='top'
                  target={`manage-class-structure-zoneName-${cellProps.row.original.id}`}
                >
                  {Arr.join(', ')}
                </UncontrolledTooltip>
              </>
            )
          } else {
            return locations ? Arr.join(', ') : '--'
          }
        }
      },

      {
        Header: 'Vùng',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          const locations = cellProps.row.original.locations
          const Arr = Array.from(new Set(locations?.map((el) => el.subZoneName)))

          if (Arr.length > 3) {
            return (
              <>
                <p id={`manage-class-structure-subZoneName-${cellProps.row.original.id}`}>
                  {locations ? Arr.slice(0, 3).join(', ') + '...' : '--'}
                </p>
                <UncontrolledTooltip
                  trigger='hover'
                  placement='top'
                  target={`manage-class-structure-subZoneName-${cellProps.row.original.id}`}
                >
                  {Arr.join(', ')}
                </UncontrolledTooltip>
              </>
            )
          } else {
            return locations ? Arr.join(', ') : '--'
          }
        }
      },
      {
        Header: 'Tỉnh/ Thành phố',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          const locations = cellProps.row.original.locations
          const Arr = Array.from(new Set(locations?.map((el) => el.provinceName)))

          if (Arr.length > 3) {
            return (
              <>
                <p id={`manage-class-structure-provinceName-${cellProps.row.original.id}`}>
                  {locations ? Arr.slice(0, 3).join(', ') + '...' : '--'}
                </p>
                <UncontrolledTooltip
                  trigger='hover'
                  placement='top'
                  target={`manage-class-structure-provinceName-${cellProps.row.original.id}`}
                >
                  {Arr.join(', ')}
                </UncontrolledTooltip>
              </>
            )
          } else {
            return locations ? Arr.join(', ') : '--'
          }
        }
      },
      {
        Header: 'Quận/ Huyện',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          const locations = cellProps.row.original.locations
          const Arr = Array.from(new Set(locations?.map((el) => el.districtName)))

          if (Arr.length > 3) {
            return (
              <>
                <p id={`manage-class-structure-districtName-${cellProps.row.original.id}`}>
                  {locations ? Arr.slice(0, 3).join(', ') + '...' : '--'}
                </p>
                <UncontrolledTooltip
                  trigger='hover'
                  placement='top'
                  target={`manage-class-structure-districtName-${cellProps.row.original.id}`}
                >
                  {Arr.join(', ')}
                </UncontrolledTooltip>
              </>
            )
          } else {
            return locations ? Arr.join(', ') : '--'
          }
        }
      },
      {
        Header: ClassStructureVN.table.updateDate,
        accessor: 'updatedAt',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <DateCol {...cellProps} />
        }
      },

      {
        Header: 'Thời gian hạng',
        // accessor: 'updatedAt',
        accessor: (cell) => {
          return `${cell?.id}!@#$${cell?.startDate}!@#$${cell?.endDate}`
        },
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <CreateDurationMoment {...cellProps} />
        }
      },
      {
        Header: ClassStructureVN.table.action,
        accessor: 'action',
        disableFilters: true,
        hideSort: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <ul className='list-unstyled hstack gap-1 mb-0'>
              <PermissionWrapper screenCode={screenCode} code={RANK_CONFIG_MANAGEMENT_CODE.RANKS_DETAIL_RANK_CONFIG}>
                <li data-bs-toggle='tooltip' data-bs-placement='top' title='View'>
                  <Link
                    to={`/manage-class-structure/view/${cellProps.row.original.uuid}`}
                    className='btn btn-sm btn-soft-primary'
                    id={`viewtooltip-${cellProps.row.original.id}`}
                  >
                    <i className='mdi mdi-eye-outline' />
                    <UncontrolledTooltip placement='top' target={`viewtooltip-${cellProps.row.original.id}`}>
                      Xem
                    </UncontrolledTooltip>
                  </Link>
                </li>
              </PermissionWrapper>

              <PermissionWrapper
                screenCode={screenCode}
                code={[
                  RANK_CONFIG_MANAGEMENT_CODE.RANKS_UPDATE_RANK_CONFIG,
                  RANK_CONFIG_MANAGEMENT_CODE.RANKS_DETAIL_RANK_CONFIG
                ]}
              >
                {cellProps.row.original.status !== 'terminated' &&
                  cellProps.row.original.status !== 'pending-removal' && (
                    <li data-bs-toggle='tooltip' data-bs-placement='top' title='View'>
                      <Link
                        to={
                          cellProps.row.original?.status === 'prepare'
                            ? `/manage-class-structure/edit/${cellProps.row.original.uuid}`
                            : '#'
                        }
                        className={`btn btn-sm btn-soft-info ${
                          cellProps.row.original?.status === 'prepare' ? '' : 'opacity-50'
                        }`}
                        id={`edittooltip-${cellProps.row.original.id}`}
                      >
                        <i className='mdi mdi-pencil-outline' />
                        <UncontrolledTooltip placement='top' target={`edittooltip-${cellProps.row.original.id}`}>
                          Sửa
                        </UncontrolledTooltip>
                      </Link>
                    </li>
                  )}
              </PermissionWrapper>
              <PermissionWrapper screenCode={screenCode} code={RANK_CONFIG_MANAGEMENT_CODE.RANKS_DELETE_RANK_CONFIG}>
                <li>
                  <button
                    className={`btn btn-sm btn-soft-danger ${
                      cellProps.row.original?.status === 'prepare' ? '' : 'opacity-50'
                    }`}
                    onClick={() => {
                      if (cellProps.row.original?.status === 'prepare') {
                        const item = cellProps.row.original
                        setShowDelete(true)
                        setSelected(item)
                      }
                    }}
                    id={`deletetooltip-${cellProps.row.original.uuid}`}
                  >
                    <i className='mdi mdi-delete-outline' />
                    <UncontrolledTooltip placement='top' target={`deletetooltip-${cellProps.row.original.uuid}`}>
                      Xoá
                    </UncontrolledTooltip>
                  </button>
                </li>
              </PermissionWrapper>
            </ul>
          )
        }
      }
    ],
    [page]
  )

  const setGlobalFilterUser = async (data) => {
    setStorage('ManageClassStructure', data)
    setFilter(data)
    setPage(1)
  }

  const onClickDelete = async () => {
    try {
      const res = await deleteRankConfigAPI({ id: selected.uuid })

      if (res) {
        toast('Xóa cơ cấu hạng tài khoản thành công', { type: 'success' })
        refresh()
        setShowDelete(false)
        setSelected()
      }
    } catch (error) {
      toast(error.response.data.message, { type: 'error' })
    }
  }

  const goToPage = (page) => {
    setFilter({ ...filter, page })
    setPage(page)
  }

  const refresh = () => {
    handleGetList()
  }

  return (
    <React.Fragment>
      <div className='page-content'>
        <div className='page-title-box container-fluid'>
          <h4 className=' mb-sm-0 font-size-18 pb-4'>{ClassStructureVN.title}</h4>
          {isLoading ? (
            <Spinners setLoading={setLoading} />
          ) : (
            <Row>
              <Col lg='12'>
                <Card>
                  <CardBody className='border-bottom'>
                    <div className='d-flex align-items-center'>
                      <h5 className='mb-0 card-title flex-grow-1'>{ClassStructureVN.list}</h5>
                      <div className='flex-shrink-0'>
                        <PermissionWrapper
                          screenCode={screenCode}
                          code={RANK_CONFIG_MANAGEMENT_CODE.RANKS_CREATE_RANK_CONFIG}
                        >
                          <button
                            onClick={() => {
                              navigator('/manage-class-structure/create')
                            }}
                            className='btn btn-primary'
                          >
                            <i className='mdi mdi-plus me-1' />
                            {ClassStructureVN.add}
                          </button>
                        </PermissionWrapper>
                      </div>
                    </div>
                  </CardBody>
                  <CardBody>
                    <TableContainer
                      columns={columns}
                      data={listRankConfig}
                      isGlobalFilter={true}
                      setGlobalFilterUser={setGlobalFilterUser}
                      isManageClassStructureGlobalFilter={true}
                      optionPermissionGroup={options}
                      isSearchInput={false}
                      titleSearchSelect={ClassStructureVN.filters.accoutType.title}
                      placeholderSearchSelect={ClassStructureVN.filters.accoutType.placeholder}
                      isPagination={true}
                      iscustomPageSizeOptions={false}
                      isShowingPageLength={false}
                      customPageSize={10}
                      tableClass='table-bordered align-middle nowrap mt-2'
                      paginationDiv='col-sm-12 col-md-7'
                      pagination='pagination justify-content-end pagination-rounded'
                      useAPIChangePaging
                      gotoPageCustom={goToPage}
                      total={total}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </div>
      </div>
      <ConfirmModal
        show={showDelete}
        icon={
          <div className='avatar-title rounded-circle bg-danger text-light bg-opacity-60 font-size-20 rounded-3'>
            <i className='mdi mdi-delete-outline'></i>
          </div>
        }
        onConfirmClick={onClickDelete}
        onCloseClick={() => setShowDelete(false)}
        title={`Xác nhận xóa cơ cấu hạng tài khoản`}
        description={`Bạn có chắc chắn muốn xóa cơ cấu hạng tài khoản?`}
      />
    </React.Fragment>
  )
}

export default ManageClassStructure
