import { call, put, takeEvery } from "redux-saga/effects"

import { GET_MANAGE_PRODUCT, GET_PRODUCT_DETAIL, GET_KTVS } from "./actionTypes"
import { getManageProductSuccess, getManageProductFail, getProductDetailSuccess } from "./actions"

import { getProductListAPI, getProductDetailsAPI } from "helpers/backend_helper"

function* fetchProductList({ payload: params }) {
    try {
        const response = yield call(getProductListAPI, { ...params })
        yield put(getManageProductSuccess(response))
    } catch (error) {
        yield put(getManageProductFail(error?.data?.message))
    }
}

function* fetchProductDetail({ payload: data }) {
    try {
        const response = yield call(getProductDetailsAPI, { id: data.id })
        yield put(getProductDetailSuccess(response.data))
    } catch (error) {
        console.log(error)
        // yield put(getProductDetailFail(error))
    }
}

function* ProductsSaga() {
    yield takeEvery(GET_MANAGE_PRODUCT, fetchProductList)
    yield takeEvery(GET_PRODUCT_DETAIL, fetchProductDetail)
}

export default ProductsSaga
