import React, { useEffect, useState } from "react"

import { Row, Col, Alert, Container, FormFeedback, Label, Card, CardBody } from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import withRouter from "components/Common/withRouter"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"

// import images
import { changePasswordVN } from "constants/vn"
import { changePassword } from "store/change-password/actions"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { REGEX_PASSWORD } from "constants/regex"
import { FormInput } from "components/Common/form-input/FormInput"
import { Form, Input } from "antd"
import { sha256Password } from "helpers/global_helper"

const ChangePassword = props => {
    //meta title
    document.title = "Change password | HPG"

    const dispatch = useDispatch()
    const selectChangePasswordState = state => state.changePassword
    const ChangePasswordProperties = createSelector(selectChangePasswordState, changePassword => ({
        error: changePassword.error,
    }))
    const { error } = useSelector(ChangePasswordProperties)

    const onFinish = values => {
        const payload = {
            oldPassword: sha256Password(values.oldPassword),
            password: sha256Password(values.password),
            confirmPassword: sha256Password(values.confirmPassword),
        }
        dispatch(changePassword({ ...payload }, props.router.navigate))
    }

    const [form] = Form.useForm()
    const watchForm = Form.useWatch([], form)
    const [submittable, setSubmittable] = useState(false)

    useEffect(() => {
        form.validateFields({ validateOnly: true }).then(
            () => {
                setSubmittable(true)
            },
            () => {
                setSubmittable(false)
            }
        )
    }, [watchForm])

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumbs title={"Menu"} breadcrumbItem={changePasswordVN.title} />
                    <Card>
                        <CardBody>
                            <Row className="justify-content-center">
                                <Col md={8} lg={7} xl={6}>
                                    <div className="p-2">
                                        <Form onFinish={onFinish} autoComplete="off" form={form} name="changePasswordForm">
                                            {error ? <Alert color="danger">{error}</Alert> : null}

                                            <div className="mb-3" key={changePasswordVN.inputDetails[0].name}>
                                                <Label className="form-label">{changePasswordVN.inputDetails[0].title}</Label>
                                                <Form.Item
                                                    name={changePasswordVN.inputDetails[0].name}
                                                    rules={[{ required: true, message: `Vui lòng nhập mật khẩu hiện tại` }]}
                                                >
                                                    <Input.Password
                                                        size="large"
                                                        name={changePasswordVN.inputDetails[0].name}
                                                        placeholder={changePasswordVN.inputDetails[0].placeholder}
                                                        maxLength={changePasswordVN.inputDetails[0]?.maxLength}
                                                    />
                                                </Form.Item>
                                            </div>
                                            <div className="mb-3" key={changePasswordVN.inputDetails[1].name}>
                                                <Label className="form-label">{changePasswordVN.inputDetails[1].title}</Label>
                                                <Form.Item
                                                    name={changePasswordVN.inputDetails[1].name}
                                                    rules={[
                                                        { required: true, message: `Vui lòng nhập mật khẩu mới` },
                                                        { pattern: REGEX_PASSWORD, message: "Mật khẩu mới sai định dạng. Vui lòng thử lại" },
                                                    ]}
                                                >
                                                    <Input.Password
                                                        size="large"
                                                        name={changePasswordVN.inputDetails[1].name}
                                                        placeholder={changePasswordVN.inputDetails[1].placeholder}
                                                        maxLength={changePasswordVN.inputDetails[1]?.maxLength}
                                                        onChange={e => form.validateFields(["confirmPassword"])}
                                                    />
                                                </Form.Item>
                                            </div>
                                            <div className="mb-3" key={changePasswordVN.inputDetails[2].name}>
                                                <Label className="form-label">{changePasswordVN.inputDetails[2].title}</Label>
                                                <Form.Item
                                                    name={changePasswordVN.inputDetails[2].name}
                                                    rules={[
                                                        { required: true, message: `Vui lòng nhập lại mật khẩu mới` },
                                                        ({ getFieldValue }) => ({
                                                            validator(_, value) {
                                                                if (!value || getFieldValue("password") === value) {
                                                                    return Promise.resolve()
                                                                }
                                                                return Promise.reject(new Error("Mật khẩu mới và nhập lại mật khẩu không khớp"))
                                                            },
                                                        }),
                                                    ]}
                                                >
                                                    <Input.Password
                                                        size="large"
                                                        name={changePasswordVN.inputDetails[2].name}
                                                        placeholder={changePasswordVN.inputDetails[2].placeholder}
                                                        maxLength={changePasswordVN.inputDetails[2]?.maxLength}
                                                    />
                                                </Form.Item>
                                            </div>
                                            <div>
                                                <p>{changePasswordVN.rule.rule1}</p>
                                                <p>{changePasswordVN.rule.rule2}</p>
                                                <p>{changePasswordVN.rule.rule3}</p>
                                                <p>{changePasswordVN.rule.rule4}</p>
                                            </div>
                                            <div className="mt-3 d-flex flex-wrap gap-4 justify-content-center">
                                                <button
                                                    className="btn btn-outline-primary w-25 "
                                                    type="button"
                                                    onClick={() => {
                                                        props.router.navigate(-1)
                                                    }}
                                                >
                                                    {changePasswordVN.button.cancel.title}
                                                </button>
                                                <button className="btn btn-primary btn-block w-25" type="submit" disabled={!submittable}>
                                                    {changePasswordVN.button.changePassword.title}
                                                </button>
                                            </div>
                                        </Form>
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default withRouter(ChangePassword)
