import React, { useEffect, useMemo, useState } from 'react'

//import components

import { BaseCol, NoCol, DateCol } from './components/ManageAccountClass/ManageAccountCol'

import { Col, Row, UncontrolledTooltip, Card, CardBody } from 'reactstrap'
import Spinners from 'components/Common/Spinner'
import { toast } from 'react-toastify'
import TableContainer from 'components/Common/TableContainer'
import { ClassConfigVN, LocalityVN } from 'constants/vn'
import ConfirmModal from 'components/Common/ConfirmModal'
import { getPointConfigListAPI, updatePointConfigAPI } from 'helpers/backend_helper'
import { Form, Input } from 'antd'
import PermissionWrapper from 'components/Common/PermissionWrapper'
import { POINT_CONFIG_MANAGEMENT_CODE, SCREEN_CODE } from 'constants/permission-code'
import { useNavigate } from 'react-router-dom'
import { getStorage, setStorage } from 'helpers/global_helper'

function ManageClassConfig() {
  //meta title
  document.title = 'Manage Point Config CMS List | Skote - React Admin & Dashboard Template'
  const screenCode = useMemo(() => SCREEN_CODE.POINT_CONFIG_MANAGEMENT, [])
  const navigate = useNavigate()

  const [isLoading, setLoading] = useState(false)
  const [filter, setFilter] = useState()
  const [edit, setEdit] = useState(false)
  const [listTable, setListTable] = useState([])
  const [total, setTotal] = useState()

  const [form] = Form.useForm()
  const watchForm = Form.useWatch([], form)
  const [disabledSubmit, setDisabledSubmit] = useState(true)
  const [page, setPage] = useState(1)
  const [listDataChange, setListDataChange] = useState([])

  useEffect(() => {
    handleGetListTable()
  }, [filter])

  useEffect(() => {
    if (edit == true) {
      listTable.map((item, index) => {
        form.setFieldsValue({
          [item.id]: item.point
        })
      })
    }
  }, [edit])

  useEffect(() => {
    if (edit == true) {
      listTable.map((item, index) => {
        form.setFieldsValue({
          [item.id]: item.point
        })
      })
    }
    listDataChange.map((item, index) => {
      form.setFieldsValue({
        [item.id]: item.point
      })

      handleCheckPoint(item.id, item.point)
    })
  }, [listTable])

  useEffect(() => {
    const data = form.getFieldsError()
    const check = []
    data.map((item) => {
      if (item.errors.length > 0) {
        check.push(false)
      } else {
        check.push(true)
      }
    })

    if (check.includes(false)) {
      setDisabledSubmit(true)
    } else {
      setDisabledSubmit(false)
    }
  }, [watchForm])

  const handleGetListTable = async () => {
    try {
      const filterManagePointConfig = getStorage('filterManagePointConfig')

      const res = await getPointConfigListAPI(filterManagePointConfig)

      if (res.data) {
        setListTable(res.data)
      }

      if (res.meta) {
        setTotal(res.meta.totalItems)
      }
    } catch (error) {
      toast(error.response.data.message, { type: 'error' })
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: ClassConfigVN.table.stt,
        accessor: 'stt',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return (page - 1) * 10 + cellProps.row.index + 1
        }
      },
      {
        Header: ClassConfigVN.table.branch,
        accessor: 'industry',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return cellProps.value ? cellProps.value.name : '--'
        }
      },
      {
        Header: ClassConfigVN.table.industry,
        accessor: 'industrySector',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return cellProps.value ? cellProps.value.name : '--'
        }
      },
      {
        Header: ClassConfigVN.table.products,
        accessor: 'category',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return cellProps.value ? cellProps.value.name : '--'
        }
      },
      {
        Header: ClassConfigVN.table.model,
        accessor: 'name',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return cellProps.value ? cellProps.value : '--'
        }
      },
      {
        Header: ClassConfigVN.table.point,
        accessor: 'point',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          if (edit == false) {
            return cellProps.value ? cellProps.value : 0
          } else {
            return (
              <Form.Item name={`${cellProps.row.original.id}`}>
                <Input
                  onChange={(e) => handleCheckPoint(cellProps.row.original.id, e.target.value)}
                  style={{ width: '100px' }}
                  maxLength={5}
                  className='mb-0'
                />
              </Form.Item>
            )
          }
        }
      }
    ],
    [edit, listTable]
  )

  const setGlobalFilterUser = async (data) => {
    setStorage('filterManagePointConfig', data)
    setFilter(data)
    setPage(1)
  }

  const goToPage = (page) => {
    setFilter({ ...filter, page })
    // setEdit(false)
    setPage(page)
  }

  const handleCheckPoint = (id, value) => {
    if (value == null) {
      form.setFields([{ name: `${id}`, errors: ['Vui lòng nhập điểm'] }])
    } else if (handleCheckNguyen(value) == false) {
      form.setFields([{ name: `${id}`, errors: ['Điểm là số nguyên dương.'] }])
    } else {
      form.setFields([{ name: `${id}`, errors: null }])
    }
    const data = form.getFieldsValue()
    const list = [...listDataChange]
    listTable.map((item, index) => {
      if (item.point != data[item.id]) {
        const find = list.find((x) => x.id == item.id)

        if (find) {
          find.point = data[item.id]
        } else {
          list.push({
            id: item.id,
            point: data[item.id]
          })
        }
      }
    })
    setListDataChange(list)
  }

  const handleSave = async () => {
    const check = []
    listDataChange.map((item) => {
      if (item.point == '' || item.point == null) {
        check.push(false)
      } else {
        check.push(true)
      }
    })

    if (check.includes(false)) {
      return toast('Vui lòng nhập đẩy đủ các điểm ở các trang.', { type: 'error' })
    }

    try {
      const res = await updatePointConfigAPI({ tasks: listDataChange })

      if (res) {
        toast('Cập nhật thành công điểm.', { type: 'success' })
        setEdit(false)
        setListDataChange([])
        handleGetListTable()
      }
    } catch (error) {
      toast(error.response.data.message, { type: 'error' })
    }
  }

  const handleCheckNguyen = (value) => {
    if (Number(value) > 0 && Number.isInteger(Number(value))) {
      return true
    } else {
      return false
    }
  }

  return (
    <React.Fragment>
      <div className='page-content'>
        <div className='page-title-box container-fluid'>
          <h4 className=' mb-sm-0 font-size-18 pb-4'>
            {edit && 'Chỉnh sửa'} {ClassConfigVN.title}
          </h4>
          {isLoading ? (
            <Spinners setLoading={setLoading} />
          ) : (
            <Row>
              <Col lg='12'>
                <Card>
                  <CardBody className='border-bottom'>
                    <div className='d-flex align-items-center'>
                      <h5 className='mb-0 card-title flex-grow-1'>{ClassConfigVN.list}</h5>
                      <PermissionWrapper
                        screenCode={screenCode}
                        code={POINT_CONFIG_MANAGEMENT_CODE.RANK_UPDATE_POINT_CONFIG}
                      >
                        <div className='flex-shrink-0'>
                          {edit == false ? (
                            <button
                              // onClick={() => {
                              //   setEdit(true)
                              // }}
                              onClick={() => {
                                navigate('/manage-point-config-by-time')
                              }}
                              className='btn btn-primary'
                            >
                              <i className='mdi mdi-pencil-outline me-1' />
                              {'Cơ cấu điểm theo thời gian'}
                            </button>
                          ) : (
                            <div className='d-flex gap-2 justify-content-center mt-1'>
                              <button
                                onClick={() => setEdit(false)}
                                type='button'
                                className='btn btn-soft-secondary w-14 '
                              >
                                Hủy
                              </button>
                              <button
                                type={'submit'}
                                className='btn btn-primary w-14'
                                onClick={() => handleSave()}
                                disabled={disabledSubmit}
                              >
                                {'Lưu'}
                              </button>
                            </div>
                          )}
                        </div>
                      </PermissionWrapper>
                    </div>
                  </CardBody>
                  <CardBody>
                    <Form form={form}>
                      <TableContainer
                        columns={columns}
                        data={listTable}
                        isGlobalFilter={true}
                        setGlobalFilterUser={setGlobalFilterUser}
                        isManageClassConfigGlobalFilter={true}
                        isPagination={true}
                        iscustomPageSizeOptions={false}
                        isShowingPageLength={false}
                        customPageSize={10}
                        placeholderSearch={ClassConfigVN.filters.search.placeholder}
                        tableClass='table-bordered align-middle nowrap mt-2'
                        paginationDiv='col-sm-12 col-md-7'
                        pagination='pagination justify-content-end pagination-rounded'
                        useAPIChangePaging
                        gotoPageCustom={goToPage}
                        total={total}
                        // isSearchInput={false}
                      />
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </div>
      </div>
    </React.Fragment>
  )
}

export default ManageClassConfig
