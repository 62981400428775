import React from "react"
import { Badge, UncontrolledTooltip } from "reactstrap"
import { Link } from "react-router-dom"
import { ManageProgramVN } from "constants/vn"
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import { formateDate } from "helpers/global_helper"
import { StatusProgramOptions, typeAccountProgramOptions, TypeProgramOptions } from "constants/constants"

const ProgramNo = cell => {
    return (
        // <Link to="#" className="text-body fw-bold">
        //     {cell?.row ? cell.row.index + 1 : ""}
        // </Link>
        cell.value ? cell.value : "--"
    )
}
const ProgramType = cell => {
    return cell.value ? TypeProgramOptions.find(e => e.value === cell.value).label : "--"
}

const ApplyDate = cell => {
    return cell.value
        ? cell.row.original.applyTime === "range_time"
            ? formateDate(cell.value) + "-" + formateDate(cell.row.original.endDate)
            : cell.row.original?.applyDates?.map(e => formateDate(e)).join(",")
        : "--"
}

const ProgramName = cell => {
    return cell.value ? (
        <div>
            <span style={{ maxWidth: 300 }} className="" id={`nameprogram-tooltip-${cell.row.original.id}`}>
                {cell.value}
            </span>
            <UncontrolledTooltip trigger="hover" placement="top" target={`nameprogram-tooltip-${cell.row.original.id}`}>
                {cell.value}
            </UncontrolledTooltip>
        </div>
    ) : (
        "--"
    )
}

const ApplyClientType = cell => {
    const typeAccount = cell.value?.map(e => typeAccountProgramOptions.find(o => o.value === e).label)
    return cell.value ? typeAccount?.join(", ") : "--"
}

const ConfigType = cell => {
    return cell.value ? ManageProgramVN.form.programConfig.options.find(e => e.value === cell.value).label : "--"
}

const Status = cell => {
    switch (cell.value) {
        case "draft":
            return <Badge className="badge-soft-success">{StatusProgramOptions[0].label}</Badge>
        case "pending":
            return <Badge className="badge-soft-warning">{StatusProgramOptions[1].label}</Badge>
        case "active":
            return <Badge className="badge-soft-info">{StatusProgramOptions[2].label}</Badge>
        case "rejected":
            return <Badge className="badge-soft-black">{StatusProgramOptions[3].label}</Badge>
        case "pause":
            return <Badge className="badge-soft-error">{StatusProgramOptions[4].label}</Badge>
    }
}

export { ApplyClientType, ConfigType, ProgramNo, ProgramType, ApplyDate, ProgramName, Status }
