export const GET_MANAGE_SHOP = "GET_SHOPLIST"
export const GET_MANAGE_SHOP_FAIL = "GET_MANAGE_SHOP_FAIL"
export const GET_MANAGE_SHOP_SUCCESS = "GET_MANAGE_SHOP_SUCCESS"

export const GET_SHOP_DETAIL = "GET_SHOP_DETAIL"
export const GET_SHOP_DETAIL_SUCCESS = "GET_SHOP_DETAIL_SUCCESS"
export const GET_SHOP_DETAIL_FAIL = "GET_SHOP_DETAIL_FAIL"

export const UPDATE_MANAGE_SHOP = "UPDATE_MANAGE_SHOP"
export const UPDATE_MANAGE_SHOP_SUCCESS = "UPDATE_MANAGE_SHOP_SUCCESS"
export const UPDATE_MANAGE_SHOP_FAIL = "UPDATE_MANAGE_SHOP_FAIL"

export const GET_KTVS = "GET_KTV_LIST"
