import { Input } from 'antd'
import { MAX_LENGTH_ADDRESS } from 'constants/constants'
import { debounce } from 'lodash'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Modal, ModalBody } from 'reactstrap'

const ConfirmModal = ({
  show,
  onConfirmClick,
  onCloseClick,
  icon,
  title = 'Xác nhận khóa',
  description = '',
  hasInput = false,
  hasChangeAccountElement = false,
  hasIcon = true,
  buttonConfirmText = 'Xác nhận',
  showOneButton = false
}) => {
  const [reason, setReason] = useState('')

  const onChangeReason = (value) => {
    setReason(value.target.value)
  }
  return (
    <Modal size='md' isOpen={show} toggle={onCloseClick} centered={true}>
      <div className='modal-content'>
        <ModalBody className='p-4 text-center'>
          <button type='button' onClick={onCloseClick} className='btn-close position-absolute end-0 top-0 m-3'></button>
          {hasIcon && (
            <div className='avatar-sm mb-4 mx-auto'>
              {icon ? (
                icon
              ) : (
                <div className='avatar-title rounded-circle bg-primary text-primary bg-opacity-10 font-size-20 rounded-3'>
                  <i className='mdi mdi-lock'></i>
                </div>
              )}
            </div>
          )}

          <h3>{title}</h3>
          <p className='text-muted font-size-16 mb-4 text-wrap'>{description}</p>
          {hasInput && (
            <div>
              <Input.TextArea
                className='mb-4'
                placeholder='Nhập lý do'
                maxLength={MAX_LENGTH_ADDRESS}
                onChange={onChangeReason}
                autoSize={{ minRows: 2, maxRows: 5 }}
              />
            </div>
          )}

          {hasChangeAccountElement && (
            <div>
              <Input.TextArea
                className='mb-4'
                placeholder='Nhập lý dooooooo'
                maxLength={MAX_LENGTH_ADDRESS}
                onChange={onChangeReason}
                autoSize={{ minRows: 2, maxRows: 5 }}
              />
            </div>
          )}

          <div className='hstack gap-2 justify-content-center mb-0'>
            {!showOneButton && (
              <button
                type='button'
                className='btn btn-soft-secondary w-25 '
                onClick={() => {
                  setReason(undefined)
                  onCloseClick()
                }}
              >
                Hủy
              </button>
            )}
            <button
              disabled={hasInput && !reason}
              type='button'
              className='btn btn-primary w-25'
              onClick={debounce(() => {
                onConfirmClick(reason)
                setReason(undefined)
              }, 500)}
            >
              {buttonConfirmText}
            </button>
          </div>
        </ModalBody>
      </div>
    </Modal>
  )
}

ConfirmModal.propTypes = {
  onCloseClick: PropTypes.func,
  onConfirmClick: PropTypes.func,
  show: PropTypes.any,
  icon: PropTypes.any,
  title: PropTypes.any,
  description: PropTypes.any,
  hasInput: PropTypes.any,
  hasIcon: PropTypes.any,
  buttonConfirmText: PropTypes.string
}

export default ConfirmModal
