import React, { useEffect, useMemo, useState } from 'react'
import { getReportUserStatistic as onGetReportUserStatistic } from 'store/actions'
import { AvgAccumulation, AvgActivation, AvgUser, ClientType, ProvinceName } from './ReportUserStatisticCol'
import { useDispatch, useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import { Space } from 'antd'
import ConfirmModal from 'components/Common/ConfirmModal'
import Spinners from 'components/Common/Spinner'
import { TableRowSpan } from 'components/Common/TableCustom'
import { deleteContentAPI, exportReportUserStatisticAPI, patchStatusContentAPI } from 'helpers/backend_helper'
import fileDownload from 'js-file-download'
import moment from 'moment'
import { toast } from 'react-toastify'
import { Card, CardBody, Col, Row } from 'reactstrap'
import ReportUserStatisticFilter from './components/ReportUserStatisticFilter'

function ReportUserStatistic() {
  //meta title
  document.title = 'Content List | HPG'

  const dispatch = useDispatch()

  const selectReportUserStatisticState = (state) => state.ReportUserStatisticReducer
  const ReportProperties = createSelector(selectReportUserStatisticState, (reportReducer) => ({
    notifications: reportReducer.notifications,
    loading: reportReducer.loading,
    total: reportReducer.total
  }))

  const { notifications, loading, total } = useSelector(ReportProperties)

  const [isLoading, setLoading] = useState(loading)
  const [showDelete, setShowDelete] = useState(false)
  const [showPause, setShowPause] = useState(false)
  const [showPlay, setShowPlay] = useState(false)
  const [selected, setSelected] = useState()
  const [filter, setFilter] = useState()
  const [page, setPage] = useState(1)
  const [month, setMonth] = useState(new Date().getMonth() + 1)

  const [pagination, setPagination] = useState({
    pageIndex: page,
    pageSize: 10,
    totalRows: total
  })

  useEffect(() => {
    dispatch(onGetReportUserStatistic({ month: month, page: 1 }))
  }, [dispatch])

  const columns = useMemo(
    () => [
      {
        Header: 'Miền',
        accessor: 'zone',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <ProvinceName {...cellProps} />
        }
      },
      {
        Header: 'Vùng',
        accessor: 'subZone',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <ProvinceName {...cellProps} />
        }
      },
      {
        Header: 'Tỉnh/ Thành phố',
        accessor: 'province',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <ProvinceName {...cellProps} />
        }
      },
      {
        Header: 'Quận/ Huyện',
        accessor: 'district',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <ProvinceName {...cellProps} />
        }
      },
      {
        Header: 'Loại tài khoản',
        accessor: 'client',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <ClientType {...cellProps} />
        }
      },
      {
        Header: 'Số lượng tham gia vào app',
        accessor: 'countUser',
        disableFilters: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return cellProps.value
        }
      },
      {
        Header: 'So sánh số lượng tham gia vào app với tháng trước',
        accessor: (cell) => {
          return cell
        },
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <AvgUser {...cellProps} />
        }
      },
      {
        Header: 'So sánh số lượng sản phẩm đã tích luỹ với tháng trước',
        accessor: (cell) => {
          return cell
        },
        disableFilters: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <AvgAccumulation {...cellProps} />
        }
      },
      {
        Header: 'So sánh số lượng sản phẩm đã kích hoạt với tháng trước',
        accessor: (cell) => {
          return cell
        },
        disableFilters: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <AvgActivation {...cellProps} />
        }
      }
    ],
    [filter, page, notifications]
  )

  const onDelete = async () => {
    try {
      const res = await deleteContentAPI({
        id: selected.id
      })
      if (res) {
        toast('Xóa bài viết thành công', { type: 'success' })
        setShowDelete(false)
      }
    } catch (error) {
      console.log(error)
      toast(error.response.data.message, { type: 'error' })
    }
  }

  const onUpdateRequest = async (status, note) => {
    try {
      const res = await patchStatusContentAPI({
        id: selected.id,
        status,
        node: note
      })
      if (res) {
        toast(status === 'pause' ? 'Tạm ngưng bài viết thành công' : 'Hiển thị lại bài viết thành công', {
          type: 'success'
        })
        dispatch(onGetReportUserStatistic({ ...filter, page: page }))
      }
    } catch (error) {
      console.log(error)
      toast(error.response.data.message, { type: 'error' })
    }
    closePopup()
  }

  const closePopup = () => {
    setShowPause(false)
    setShowPlay(false)
  }
  const setGlobalFilterUser = async (data) => {
    if (data?.month === undefined || data?.month === null || data?.month === '') {
      data.month = month
    }
    setFilter({ ...data, page: 1 })
    dispatch(onGetReportUserStatistic(data))
    dispatch(
      onGetReportUserStatistic({
        ...data,
        meta: {
          Miền: data?.zone_names,
          Vùng: data?.sub_zone_names,
          'Tỉnh/ thành phố': data?.search,
          'Quận/ huyện': data?.district_names,
          'Loại tài khoản': data?.client_name,
          'Thời gian': data?.month_name
        }
      })
    )
    setPage(1)
  }

  const goToPage = (page) => {
    if (filter?.month === undefined || filter?.month === null || filter?.month === '') {
      setFilter({ ...filter, page: page })
      setPage(page)
      dispatch(onGetReportUserStatistic({ ...filter, month: month, page: page }))
    } else {
      setFilter({ ...filter, page: page })
      setPage(page)
      dispatch(onGetReportUserStatistic({ ...filter, page: page }))
    }
  }

  const handleResetFilter = () => {
    dispatch(onGetReportUserStatistic({ month: month, page: 1 }))
    setPage(1)
  }

  const handleExport = async (filterValue) => {
    if (filterValue?.month === undefined || filterValue?.month === null || filterValue?.month === '') {
      filterValue.month = month
    }

    const res = await exportReportUserStatisticAPI(filterValue)
    fileDownload(res, `${moment().format('yyMMDD')}_Thong ke tai khoan app.xlsx`)
  }

  return (
    <React.Fragment>
      <div className='page-content'>
        <div className='page-title-box container-fluid'>
          {filter?.month ? (
            <h4 className=' mb-sm-0 font-size-18 pb-4'>
              {'Thống kê các tài khoản app theo tháng ' + filter?.month + ' năm ' + new Date().getFullYear()}
            </h4>
          ) : (
            <h4 className=' mb-sm-0 font-size-18 pb-4'>
              {'Thống kê các tài khoản app theo tháng ' + month + ' năm ' + new Date().getFullYear()}
            </h4>
          )}

          {isLoading ? (
            <Spinners setLoading={setLoading} />
          ) : (
            <Row>
              <Col lg='12'>
                <Card>
                  <CardBody>
                    <Space size='middle' direction='vertical' className='w-100'>
                      <ReportUserStatisticFilter
                        handleFilter={setGlobalFilterUser}
                        handleResetFilter={handleResetFilter}
                        handleExport={handleExport}
                      />
                      <div className='mb-xxl-0 me-3 mt-2'>
                        <h5 className='mb-0 card-title flex-grow-1'>{'Danh sách báo cáo'}</h5>
                      </div>
                      <TableRowSpan
                        columns={columns}
                        data={notifications}
                        pagination={pagination}
                        setPagination={setPagination}
                        gotoPageCustom={goToPage}
                        currentPage={page}
                        total={total}
                      />
                    </Space>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </div>
      </div>
      <ConfirmModal
        show={showDelete}
        icon={
          <div className='avatar-title rounded-circle bg-danger text-light bg-opacity-60 font-size-20 rounded-3'>
            <i className='mdi mdi-delete-outline'></i>
          </div>
        }
        onConfirmClick={onDelete}
        onCloseClick={() => setShowDelete(false)}
        title='Xác nhận xóa bài viết'
        description='Bài viết đã xóa không thể khôi phục. Bạn có chắc muốn xóa bài viết?'
      />
      <ConfirmModal
        show={showPause}
        icon={
          <div className='avatar-title rounded-circle bg-danger text-light bg-opacity-60 font-size-20 rounded-3'>
            <i className='mdi mdi-delete-outline'></i>
          </div>
        }
        hasInput
        onConfirmClick={(note) => {
          onUpdateRequest('pause', note)
        }}
        onCloseClick={() => setShowPause(false)}
        title='Xác nhận tạm ngưng bài viết'
        description={`Bài viết sẽ không còn được hiển thị ở các module liên quan trên Mobile App. Bạn có chắc chắn muốn tạm ngưng hiển thị bài viết?`}
      />
      <ConfirmModal
        show={showPlay}
        onConfirmClick={(note) => {
          onUpdateRequest('active')
        }}
        onCloseClick={() => setShowPlay(false)}
        // hasInput
        title={'Xác nhận hiển thị lại bài viết'}
        description='Bài viết sẽ được hiển thị ở các module liên quan trên Mobile App. Bạn có chắc chắn muốn hiển thị lại bài viết?'
      />
    </React.Fragment>
  )
}

export default ReportUserStatistic
