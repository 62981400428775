import React, { useEffect, useMemo, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

//import components

import {
  getBankList,
  setTypePopupBank,
  getBankDetail,
  getBankDetailSuccess,
  getContactList,
  setTypePopupContact,
  getContactDetail,
  getContactDetailSuccess
} from 'store/actions'

// import { Name, Abbreviation } from '././ManageBankCol'

//redux
import { useSelector, useDispatch } from 'react-redux'
import { createSelector } from 'reselect'

import { Col, Row, UncontrolledTooltip, Card, CardBody } from 'reactstrap'
import Spinners from 'components/Common/Spinner'
import { toast } from 'react-toastify'
import TableContainer from 'components/Common/TableContainer'
// import { BankVN } from 'constants/vn'
import ConfirmModal from 'components/Common/ConfirmModal'
import { deleteBankAPI, deleteContactAPI } from 'helpers/backend_helper'
import AddEditContact from './components/addEditContactModal'
// import { AREA_MANAGEMENT_CODE, SCREEN_CODE } from 'constants/permission-code'
import PermissionWrapper from 'components/Common/PermissionWrapper'
import { Space } from 'antd'
import { BankVN } from 'constants/vn'
import { AREA_MANAGEMENT_CODE, SCREEN_CODE } from 'constants/permission-code'
import { TableRowSpan } from 'components/Common/TableCustom'
import ManageContactFilter from './components/ManageContactFilter'

function ManageContacts() {
  //meta title
  document.title = 'Manage Contacts CMS List'
  const screenCode = useMemo(() => SCREEN_CODE.AREA_MANAGEMENT, [])

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const selectContactsState = (state) => state.ContactsReducer
  const ContactsProperties = createSelector(selectContactsState, (contactReducer) => ({
    contacts: contactReducer.contacts,
    loading: contactReducer.loading,
    total: contactReducer.total
  }))

  const { contacts, loading, total } = useSelector(ContactsProperties)

  const [isLoading, setLoading] = useState(loading)
  const [onDelete, setOnDelete] = useState(false)
  const [selected, setSelected] = useState()
  const [filter, setFilter] = useState()
  const [openModal, setOpenModal] = useState(false)
  const [page, setPage] = useState(1)
  const [pagination, setPagination] = useState({
    pageIndex: page,
    pageSize: 10,
    totalRows: total
  })

  useEffect(() => {
    dispatch(getContactList())
  }, [dispatch])

  const columns = useMemo(
    () => [
      {
        Header: 'Email',
        accessor: 'email',
        filterable: true,
        disableSortBy: true,
        hideSort: true
      },
      {
        Header: 'Tổng đài tư vấn và hỗ trợ khách hàng',
        accessor: 'phone',
        filterable: true,
        hideSort: true
      },
      {
        Header: 'Điạ chỉ',
        accessor: 'address',
        filterable: true,
        disableSortBy: true,
        hideSort: true
      },
      {
        Header: 'Khu vực',
        accessor: 'label',
        filterable: true,
        disableSortBy: true,
        hideSort: true
      },
      {
        Header: BankVN.table.action,
        accessor: 'action',
        disableFilters: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return (
            <ul className='list-unstyled hstack gap-1 mb-0'>
              <PermissionWrapper screenCode={screenCode} code={AREA_MANAGEMENT_CODE.ADMIN_DETAIL_AREA}>
                <li data-bs-toggle='tooltip' data-bs-placement='top' title='View'>
                  <Link
                    to={`#`}
                    onClick={() => {
                      setOpenModal(true)
                      dispatch(setTypePopupContact('view'))
                      dispatch(getContactDetail({ id: cellProps.row.original.id, type: cellProps.row.original.type }))
                    }}
                    className='btn btn-sm btn-soft-primary'
                    id={`viewtooltip-${cellProps.row.original.id}`}
                  >
                    <i className='mdi mdi-eye-outline' />
                    <UncontrolledTooltip
                      trigger='hover'
                      placement='top'
                      target={`viewtooltip-${cellProps.row.original.id}`}
                    >
                      Xem
                    </UncontrolledTooltip>
                  </Link>
                </li>
              </PermissionWrapper>
              <PermissionWrapper
                screenCode={screenCode}
                code={[AREA_MANAGEMENT_CODE.ADMIN_DETAIL_AREA, AREA_MANAGEMENT_CODE.ADMIN_UPDATE_AREA]}
              >
                <li data-bs-toggle='tooltip' data-bs-placement='top' title='View'>
                  <Link
                    to={`#`}
                    onClick={() => {
                      setOpenModal(true)
                      dispatch(setTypePopupContact('edit'))
                      dispatch(getContactDetail({ id: cellProps.row.original.id, type: cellProps.row.original.type }))
                    }}
                    className='btn btn-sm btn-soft-info'
                    id={`edittooltip-${cellProps.row.original.id}`}
                  >
                    <i className='mdi mdi-pencil-outline' />
                    <UncontrolledTooltip
                      trigger='hover'
                      placement='top'
                      target={`edittooltip-${cellProps.row.original.id}`}
                    >
                      Sửa
                    </UncontrolledTooltip>
                  </Link>
                </li>
              </PermissionWrapper>
              <PermissionWrapper screenCode={screenCode} code={AREA_MANAGEMENT_CODE.ADMIN_DELETE_AREA}>
                <li>
                  <button
                    className={`btn btn-sm btn-soft-danger ${!!cellProps.row.original?.extTransId && 'opacity-50'}`}
                    onClick={() => {
                      setOnDelete(true)
                      setSelected(cellProps.row.original)
                    }}
                    id={`deletetooltip-${cellProps.row.original.code}`}
                  >
                    <i className='mdi mdi-delete-outline' />
                    <UncontrolledTooltip
                      trigger='hover'
                      placement='top'
                      target={`deletetooltip-${cellProps.row.original.code}`}
                    >
                      Xóa
                    </UncontrolledTooltip>
                  </button>
                </li>
              </PermissionWrapper>
            </ul>
          )
        }
      }
    ],
    []
  )

  const setGlobalFilterUser = async (data) => {
    setFilter(data)
    dispatch(getContactList(data))
    setPage(1)
  }

  const onClickDelete = async () => {
    try {
      const res = await deleteContactAPI({ id: selected.id, type: selected.type })
      if (res) {
        toast('Xoá thông tin liên hệ thành công', { type: 'success' })
        dispatch(getContactList(filter))
        setOnDelete(false)
      }
    } catch (error) {
      toast.error(error.response.data.message, { type: 'error' })
    }
  }

  const goToPage = (page) => {
    dispatch(getContactList({ ...filter, page }))
    setFilter({ ...filter, page })
    setPage(page)
  }

  const handleResetFilter = () => {
    dispatch(getContactList({ page: 1 }))
    setFilter({ page: 1 })
  }

  return (
    <React.Fragment>
      <div className='page-content'>
        <div className='page-title-box container-fluid'>
          <h4 className=' mb-sm-0 font-size-18 pb-4'>{'Quản lý thông tin liên hệ'}</h4>
          {isLoading ? (
            <Spinners setLoading={setLoading} />
          ) : (
            <Row>
              <Col lg='12'>
                <Card>
                  <CardBody className='border-bottom'>
                    <div className='d-flex align-items-center'>
                      <h5 className='mb-0 card-title flex-grow-1'>{'Thông tin liên hệ và hỗ trợ'}</h5>
                      <div className='flex-shrink-0'>
                        <PermissionWrapper screenCode={screenCode} code={AREA_MANAGEMENT_CODE.ADMIN_CREATE_AREA}>
                          <button
                            onClick={() => {
                              setOpenModal(true)
                              dispatch(setTypePopupContact('create'))
                            }}
                            className='btn btn-primary'
                          >
                            <i className='mdi mdi-plus me-1' />
                            {'Thêm mới liên hệ'}
                          </button>
                        </PermissionWrapper>
                      </div>
                    </div>
                  </CardBody>
                  <CardBody>
                    <Space size='middle' direction='vertical' className='w-100'>
                      <ManageContactFilter handleFilter={setGlobalFilterUser} handleResetFilter={handleResetFilter} />
                      <TableRowSpan
                        columns={columns}
                        data={contacts}
                        pagination={pagination}
                        setPagination={setPagination}
                        gotoPageCustom={goToPage}
                        currentPage={page}
                        total={total}
                        isShowTotal={true}
                      />
                    </Space>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </div>
      </div>
      {openModal && (
        <AddEditContact
          show={openModal}
          filter={filter}
          onCloseClick={() => {
            setOpenModal(false)
            dispatch(getContactDetailSuccess(null))
          }}
          setPage={setPage}
        />
      )}
      <ConfirmModal
        show={onDelete}
        icon={
          <div className='avatar-title rounded-circle bg-danger text-light bg-opacity-60 font-size-20 rounded-3'>
            <i className='mdi mdi-delete-outline'></i>
          </div>
        }
        onConfirmClick={onClickDelete}
        onCloseClick={() => setOnDelete(false)}
        title={`Xác nhận xóa thông tin liên hệ `}
        description={`Bạn có chắc chắn muốn xóa thông tin liên hệ ?`}
      />
    </React.Fragment>
  )
}

export default ManageContacts
