import PropsType from 'prop-types'
import { useMemo, useState } from 'react'
import { Label, UncontrolledTooltip } from 'reactstrap'
import React from 'react'
import { Row, Col, Space, Flex } from 'antd'

//flatpickr
import { ActivationVN, CommonVN, UserVN } from 'constants/vn'

import { DatePicker } from 'antd'
import { FormInput } from 'components/Common/form-input/FormInput'
import { FormSelect } from 'components/Common/form-select/FormSelect'
import { MAX_LENGTH_SEARCH, TypeProgramOptions } from 'constants/constants'
import { createSelector } from 'reselect'
import { useSelector } from 'react-redux'

const { RangePicker } = DatePicker

ManageUserFilter.propTypes = {
  handleFilter: PropsType.func,
  handleConfirm: PropsType.func,
  handleResetFilter: PropsType.func,
  handleExport: PropsType.func
}

function ManageUserFilter({ handleFilter, handleConfirm, handleResetFilter, handleExport, optionPermissionGroup }) {
  const selectLayoutState = (state) => state.Layout
  const LayoutProperties = createSelector(selectLayoutState, (app) => ({
    zone: app.zone
  }))
  const { zone } = useSelector(LayoutProperties)

  const [filter, setFilter] = useState({})
  const onFilterChange = (name, value) =>
    setFilter((prev) => ({
      ...prev,
      [name]: value
    }))

  const ColProps = useMemo(() => ({
    xs: { span: 24 },
    xxl: { span: 4 }
  }))

  const onResetFilter = () =>
    setFilter({
      name: '',
      employee_code: '',
      email: '',
      role_id: undefined,
      status: undefined
    })

  return (
    <Row gutter={[10, 10]} align='bottom'>
      <Col {...ColProps} className='w-100'>
        <Label className='form-label'>Nhập Tên</Label>
        <FormInput
          className='w-100'
          maxLength={MAX_LENGTH_SEARCH}
          placeholder='Nhập tên'
          value={filter?.name || ''}
          name='name'
          id={`input-name-filter`}
          onChange={(e) => onFilterChange('name', e.target.value)}
          onBlur={(e) => onFilterChange('name', e.target.value)}
        />
        <UncontrolledTooltip trigger='hover' placement='top' target={`input-name-filter`}>
          {''}
        </UncontrolledTooltip>
      </Col>

      <Col {...ColProps} className='w-100'>
        <Label className='form-label'>Nhập Mã nhân viên</Label>
        <FormInput
          className='w-100'
          maxLength={MAX_LENGTH_SEARCH}
          placeholder='Nhập Mã nhân viên'
          value={filter?.employee_code || ''}
          name='employee_code'
          id={`input-employee-code-filter`}
          onChange={(e) => onFilterChange('employee_code', e.target.value)}
          onBlur={(e) => onFilterChange('employee_code', e.target.value)}
        />
        <UncontrolledTooltip trigger='hover' placement='top' target={`input-employee-code-filter`}>
          {''}
        </UncontrolledTooltip>
      </Col>

      <Col {...ColProps} className='w-100'>
        <Label className='form-label'>Nhập Email</Label>
        <FormInput
          className='w-100'
          maxLength={MAX_LENGTH_SEARCH}
          placeholder='Nhập Email'
          value={filter?.email || ''}
          name='email'
          id={`input-email-filter`}
          onChange={(e) => onFilterChange('email', e.target.value)}
          onBlur={(e) => onFilterChange('email', e.target.value)}
        />
        <UncontrolledTooltip trigger='hover' placement='top' target={`input-email-filter`}>
          {''}
        </UncontrolledTooltip>
      </Col>

      <Col {...ColProps} className='w-100'>
        <Label className='form-label'>{UserVN.filters.permissionGroup.title}</Label>
        <FormSelect
          options={optionPermissionGroup}
          value={filter?.role_id}
          onChange={(value) => {
            onFilterChange('role_id', value)
          }}
          placeholder={UserVN.filters.permissionGroup.placeholder}
          // listHeight={325}
        />
      </Col>

      <Col {...ColProps} className='w-100'>
        <Label className='form-label'>{UserVN.filters.status.title}</Label>
        <FormSelect
          options={UserVN.filters.status.statusOptions?.map((item) => ({
            value: item.value,
            label: item.label
          }))}
          value={filter.status}
          onChange={(value) => {
            onFilterChange('status', value)
          }}
          placeholder={UserVN.filters.status.placeholder}
        />
      </Col>

      <Col xxl={4} className='w-100'>
        <Space>
          <button
            type='button'
            className='btn btn-soft-danger'
            onClick={() => {
              onResetFilter()
              handleResetFilter()
            }}
          >
            Xóa tìm kiếm
          </button>

          <button type='button' className='btn btn-primary' onClick={() => handleFilter(filter)}>
            Tìm kiếm
          </button>
        </Space>
      </Col>
      {/* <Col flex='1'>
        <Flex gap='small' justify='flex-end'>
        </Flex>
      </Col> */}
    </Row>
  )
}

export default ManageUserFilter
