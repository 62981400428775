import { call, put, takeEvery } from 'redux-saga/effects'

import { GET_MANAGE_CONTENT, GET_CONTENT_DETAIL, GET_MANAGE_NOTIFICATION, GET_NOTIFICATION_DETAIL } from './actionTypes'
import { getManageNotificationSuccess, getManageNotificationFail, getNotificationDetailSuccess } from './actions'

import {
  getContentDetailAPI,
  getContentsAPI,
  getKTVsAPI,
  getNotificationDetailAPI,
  getNotificationsAPI,
  getUserAccountsAPI
} from 'helpers/backend_helper'

function* fetchNotificationsList({ payload: params }) {
  try {
    const response = yield call(getNotificationsAPI, params)
    yield put(getManageNotificationSuccess(response))
  } catch (error) {
    yield put(getManageNotificationFail(error?.data?.message))
  }
}

function* fetchNotificationDetail({ payload: data }) {
  try {
    const response = yield call(getNotificationDetailAPI, { id: data })
    yield put(getNotificationDetailSuccess(response.data))
  } catch (error) {
    yield put(getManageNotificationFail(error?.data?.message))
  }
}

// function* fetchUserAccountsList({ payload: params }) {
//   try {
//     const response = yield call(getUserAccountsAPI, params)
//     yield put(getNotificationDetailSuccess(response))
//   } catch (error) {
//     yield put(getNotificationDetailSuccess(error?.data?.message))
//   }
// }

function* NotificationsSaga() {
  yield takeEvery(GET_MANAGE_NOTIFICATION, fetchNotificationsList)
  // yield takeEvery(GET_USER_ACCOUNT, fetchUserAccountsList)
  yield takeEvery(GET_NOTIFICATION_DETAIL, fetchNotificationDetail)
}

export default NotificationsSaga
