import { call, put, takeEvery } from "redux-saga/effects"

import { GET_MANAGE_CONTENT, GET_CONTENT_DETAIL } from "./actionTypes"
import { getManageContentSuccess, getManageContentFail, getContentDetailSuccess } from "./actions"

import { getContentDetailAPI, getContentsAPI } from "helpers/backend_helper"

function* fetchContentsList({ payload: params }) {
    try {
        const response = yield call(getContentsAPI, params)
        yield put(getManageContentSuccess(response))
    } catch (error) {
        yield put(getManageContentFail(error?.data?.message))
    }
}

function* fetchContentDetail({ payload: data }) {
    try {
        const response = yield call(getContentDetailAPI, { id: data })
        yield put(getContentDetailSuccess(response.data))
    } catch (error) {
        // console.log(error)
        yield put(getManageContentFail(error?.data?.message))
    }
}

// function* fetchKTVsList({ payload: params }) {
//     try {
//         const response = yield call(getKTVsAPI, params)
//         yield put(getManageContentSuccess(response?.data))
//     } catch (error) {
//         yield put(getManageContentFail(error?.data?.message))
//     }
// }

function* ContentsSaga() {
    yield takeEvery(GET_MANAGE_CONTENT, fetchContentsList)
    // yield takeEvery(GET_KTVS, fetchKTVsList)
    yield takeEvery(GET_CONTENT_DETAIL, fetchContentDetail)
}

export default ContentsSaga
