export const STATUS = {
    SUCCESS: "success",
    FAILED: "failed",
}

const requestHandler = fn => async params => {
    try {
        if (typeof fn !== "function") {
            throw new Error("need function to call api")
        }
        const response = await fn(params)
        return { status: STATUS.SUCCESS, response }
    } catch (error) {
        return { status: STATUS.FAILED, error }
    }
}

export default requestHandler
