import React, { useEffect, useState } from 'react'
import { Col, Label, Row } from 'reactstrap'

//flatpickr
import 'flatpickr/dist/themes/material_blue.css'
import { CommonVN, ManageContentVN, ProductVN } from 'constants/vn'
import { FormSelect } from './form-select/FormSelect'
import { AREA_TYPE } from 'pages/ManageLocality/constants'
import { getZoneAPI, getAreaManagementOptionsAPI } from 'helpers/backend_helper'
import { DatePicker } from 'antd'
import { createSelector } from 'reselect'
import { useSelector } from 'react-redux'
import { TypeContentOptions } from 'constants/constants'
import { FormDateRange } from './form-date-range/FormDateRange'
const { RangePicker } = DatePicker
const ContentGlobalFilter = ({ setGlobalFilter, search, setSearch }) => {
  const [filter, setFilter] = useState({})
  const [selectedZone, setSelectedZone] = useState()
  const selectLayoutState = (state) => state.Layout
  const LayoutProperties = createSelector(selectLayoutState, (app) => ({
    zone: app.zone,
    subzone: app.subzone,
    province: app.province,
    district: app.district,
    ward: app.ward,
    myLocationManagement: app.myLocationManagement
  }))
  const { zone, subzone, province, district, ward, myLocationManagement } = useSelector(LayoutProperties)

  // useEffect(() => {
  //   handleGetAllZone()
  // }, [])

  // useEffect(() => {
  //   if (selectedZone) {
  //     handleGetSubZone()
  //   }
  // }, [selectedZone])

  const handleSelect = (value, key) => {
    setFilter((e) => ({ ...e, [key]: value }))
  }

  const lstDistrict = myLocationManagement

  ////Lấy danh sách ward
  // Get all unique parentId values from lstDistrict
  const wardParentIds = myLocationManagement?.map((district) => district.id)

  //Từ wardParentIds, lấy ra những ward có id trong wardParentIds
  const lstWard = ward.filter((ward) => wardParentIds.includes(ward.parentId))

  ////Lấy danh sách province
  // Get all unique parentId values from lstDistrict
  const districtParentIds = lstDistrict?.map((district) => district.parentId)
  //Từ districtParentIds, lấy ra những district có id trong districtParentIds
  const lstProvionce = province.filter((province) => districtParentIds.includes(province.id))

  ////Lấy danh sách subZone
  // Get all unique subZoneId(parentId) values from lstProvionce
  const provinceParentIds = lstProvionce?.map((province) => province.subZoneId)
  //Từ provinceParentIds, lấy ra những subZone có id trong provinceParentIds
  const lstSubZone = subzone?.filter((subZone) => provinceParentIds.includes(subZone.id))

  ////Lấy danh sách zone
  // Get all unique zoneId(parentId) values from lstProvionce
  const subZoneParentIds = lstProvionce?.map((province) => province.zoneId) // trong lstProvionce có lưu zoneId
  //Từ provinceParentIds, lấy ra những subZone có id trong provinceParentIds
  const lstZone = zone.filter((zone) => subZoneParentIds.includes(zone.id))

  // const handleGetAllZone = async () => {
  //   const res = await getZoneAPI()

  //   if (res.data) {
  //     res.data.map((item) => {
  //       setZone((e) => [...e, { value: item.id, label: item.name }])
  //     })
  //   }
  // }

  // const handleGetSubZone = async () => {
  //   const res = await getAreaManagementOptionsAPI({ type: AREA_TYPE.SUBZONE, parent_ids: selectedZone })

  //   if (res.data) {
  //     const list_data = res.data.map((item) => {
  //       return {
  //         value: item.id,
  //         label: item.name
  //       }
  //     })

  //     setSubZone(list_data)
  //   }
  // }

  return (
    <React.Fragment>
      <Col xxl={4} lg={4} className='mt-3'>
        <Label className='form-label'>{ManageContentVN.filters.zone.title}</Label>
        <FormSelect
          options={[{ label: 'Tất cả', value: 'all' }].concat(
            lstZone?.map((item) => ({
              value: item.id,
              label: item.name
            }))
          )}
          value={filter.zone_ids}
          onChange={(value) => {
            handleSelect(value, 'zone_ids')
            handleSelect([], 'sub_zone_ids')
            handleSelect([], 'province_ids')
            handleSelect([], 'district_ids')
            handleSelect([], 'ward_ids')
          }}
          onSelect={(value) => {
            const all = lstZone?.map((item) => item.id)
            if (value && value === 'all') {
              return handleSelect([...all], 'zone_ids')
            }
          }}
          placeholder={ProductVN.filter.zone.placeholder}
          mode='multiple'
        />
      </Col>
      <Col xxl={4} lg={4} className='mt-3'>
        <Label className='form-label'>{ManageContentVN.filters.subzone.title}</Label>
        <FormSelect
          options={
            lstSubZone?.filter((e) => filter.zone_ids?.includes(e.zoneId))?.length > 0
              ? [{ label: 'Tất cả', value: 'all' }].concat(
                  lstSubZone
                    ?.filter((e) => filter.zone_ids?.includes(e.zoneId))
                    ?.map((item) => ({
                      value: item.id,
                      label: item.name
                    }))
                )
              : []
          }
          value={filter.sub_zone_ids}
          onChange={(value) => {
            handleSelect(value, 'sub_zone_ids')
            handleSelect([], 'province_ids')
            handleSelect([], 'district_ids')
            handleSelect([], 'ward_ids')
          }}
          onSelect={(value) => {
            const all = lstSubZone?.filter((e) => filter.zone_ids?.includes(e.zoneId))?.map((item) => item.id)
            if (value && value === 'all') {
              return handleSelect([...all], 'sub_zone_ids')
            }
          }}
          placeholder={ProductVN.filter.zone.placeholder}
          mode='multiple'
        />
      </Col>

      <Col xxl={4} lg={4} className='mt-3'>
        <Label className='form-label'>{ManageContentVN.filters.city.title}</Label>
        <FormSelect
          options={
            lstProvionce?.filter((e) => filter.sub_zone_ids?.includes(e.subZoneId))?.length > 0
              ? [{ label: 'Tất cả', value: 'all' }].concat(
                  lstProvionce
                    ?.filter((e) => filter.sub_zone_ids?.includes(e.subZoneId))
                    ?.map((item) => ({
                      value: item.id,
                      label: item.name
                    }))
                )
              : []
          }
          value={filter.province_ids}
          onChange={(value) => {
            handleSelect(value, 'province_ids')
            handleSelect([], 'district_ids')
            handleSelect([], 'ward_ids')
          }}
          onSelect={(value) => {
            const all = lstProvionce?.filter((e) => filter.sub_zone_ids?.includes(e.subZoneId))?.map((item) => item.id)
            if (value && value === 'all') {
              return handleSelect([...all], 'province_ids')
            }
          }}
          placeholder='Tất cả'
          mode='multiple'
        />
      </Col>
      <Col xxl={4} lg={4} className='mt-3'>
        <Label className='form-label'>{ManageContentVN.filters.type.title}</Label>
        <FormSelect
          options={[
            {
              label: 'Tích lũy',
              value: 'accumulation'
            },
            {
              label: 'Kích hoạt',
              value: 'activation'
            },
            {
              label: 'Trưng bày',
              value: 'display'
            },
            {
              label: 'Tin tức',
              value: 'news'
            },
            {
              label: 'Khác',
              value: 'other'
            }
          ]}
          value={filter.type}
          onChange={(value) => {
            handleSelect(value, 'type')
          }}
          placeholder='Tất cả'
        />
      </Col>

      <Col xxl={4} lg={4} className='mt-3'>
        <Label className='form-label'>{ManageContentVN.filters.status.title}</Label>
        <FormSelect
          options={TypeContentOptions}
          value={filter.status}
          onChange={(value) => {
            handleSelect(value, 'status')
          }}
          placeholder='Tất cả'
        />
      </Col>

      <Col xxl={4} className='mt-3' lg={4}>
        <Label className='form-label'>{'Thời gian hiển thị'}</Label>
        <FormDateRange
          className='w-100'
          size='large'
          format={CommonVN.datePlaceholder.toUpperCase()}
          placeholder={[CommonVN.datePlaceholder, CommonVN.datePlaceholder]}
          value={[filter.start_date, filter.end_date]}
          onChange={(value) => {
            if (value) {
              handleSelect(value[0], 'start_date')
              handleSelect(value[1], 'end_date')
            } else {
              handleSelect(undefined, 'start_date')
              handleSelect(undefined, 'end_date')
            }
          }}
        />
      </Col>
      <Col className='d-flex align-items-end mt-3 '>
        <div className='mb-xxl-0 me-2'>
          <button
            type='button'
            className='btn btn-soft-danger w-100'
            onClick={() => {
              setFilter({
                status: undefined,
                province_id: undefined,
                zone_id: undefined,
                sub_zone_id: undefined,
                start_date: undefined,
                end_date: undefined,
                type: undefined
              })
              setSearch('')
              setGlobalFilter({})
            }}
          >
            Xóa tìm kiếm
          </button>
        </div>
        <div className='mb-xxl-0'>
          <button
            type='button'
            className='btn btn-primary w-100'
            onClick={() => {
              const _filter = !filter
                ? filter
                : Object.fromEntries(Object.entries(filter).filter(([key, value]) => value !== undefined))
              setGlobalFilter({ ..._filter, search })
            }}
          >
            Tìm kiếm
          </button>
        </div>
      </Col>
    </React.Fragment>
  )
}
export default ContentGlobalFilter
