import { call, put, takeEvery } from "redux-saga/effects"

import { GET_MANAGE_BANK, GET_BANK_DETAIL } from "./actionTypes"
import { getManageBankSuccess, getManageBankFail, getBankDetailSuccess } from "./actions"

import { getBankListAPI, getBankDetailsAPI } from "helpers/backend_helper"

function* fetchBankList({ payload: params }) {
    try {
        const response = yield call(getBankListAPI, { ...params })
        console.log(response)
        yield put(getManageBankSuccess(response))
    } catch (error) {
        yield put(getManageBankFail(error?.data?.message))
    }
}

function* fetchBankDetail({ payload: data }) {
    try {
        const response = yield call(getBankDetailsAPI, { id: data.id, type: data.type })
        yield put(getBankDetailSuccess(response.data))
    } catch (error) {
        console.log(error)
        // yield put(getProductDetailFail(error))
    }
}

function* BanksSaga() {
    yield takeEvery(GET_MANAGE_BANK, fetchBankList)
    yield takeEvery(GET_BANK_DETAIL, fetchBankDetail)
}

export default BanksSaga
