import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Alert, Badge, Card, CardBody, Col, Container, Input, Label, Row, UncontrolledTooltip } from 'reactstrap'

//redux
import { useDispatch } from 'react-redux'
import withRouter from 'components/Common/withRouter'

//Import Breadcrumb
import Breadcrumb from '../../components/Common/Breadcrumb'
import { ManageContentVN } from 'constants/vn'

// styles
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css'
import { getContentDetail, getProgramByAreaAndRewardType, getReconcileProgramByArea } from 'store/actions'
import { createSelector } from 'reselect'
import { useSelector } from 'react-redux'
import style from './style.module.scss'

import {
  putContentDetailAPI,
  postContentAPI,
  getProgramListAPI,
  getProgramDetailsAPI,
  getRankAllAPI,
  postImage,
  patchStatusContentAPI,
  confirmReconcileByPackageAPI,
  exportProgrammingByAreaAndRewardTypeAPI,
  importFileAPI,
  exportFileError,
  downloadTemplateReportAPI,
  checkUploadFileAPI,
  checkExportFileAPI
} from 'helpers/backend_helper'
import { checkFileSizeValid, checkIsLocked, formateDate } from 'helpers/global_helper'
import { toast } from 'react-toastify'
import { MAX_LENGTH_NAME, typeAccountProgramOptions, TypeContentOptions, TypeProgramOptions } from 'constants/constants'

import { debounce, isEqual } from 'lodash'
import { Form, Image, Upload, Button, Spin, Tooltip, Space } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import { FormSelect } from 'components/Common/form-select/FormSelect'
import { FormInput } from 'components/Common/form-input/FormInput'

import { FormDateRange } from 'components/Common/form-date-range/FormDateRange'
import dayjs from 'dayjs'

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import ConfirmModal from 'components/Common/ConfirmModal'
import moment from 'moment'
import EditorCustom from 'components/Common/EditorCustom/EditorCustom'

import TableContainer from 'components/Common/TableContainer'
import * as url from 'helpers/url_helper'
import Spinners from 'components/Common/Spinner'
import { Name, NumberGrp, ReportCode, StringGrp, TotalCompleted, TotalUser } from './ReconcileProgramByAreaCol'
import { Link, Navigate } from 'react-router-dom'
import {
  BonusModel,
  Date,
  ListCheckBox,
  ListDate,
  ListModel,
  ListMoney,
  ListReasonFormInput,
  ListSerial,
  Model,
  Money,
  Phone,
  Serial,
  Status,
  TotalMoney,
  Type
} from './ProgramByAreaAndRewardTypeCol'
import { TableRowSpan } from 'components/Common/TableCustom'
import { ImageGalleryModal } from 'pages/RecordPayment/components'
import Checkbox from 'antd/es/checkbox/Checkbox'
import ProgramByAreaAndRewardTypeFilter from './components/ProgramByAreaAndRewardTypeFilter'
import fileDownload from 'js-file-download'
import ImportModal from 'components/Common/ImportModal'
import { GrString } from 'pages/ReportTemporaryReconciliation/components/ManageAccountClass/ManageAccountCol'

const DisplayProgramByAreaAndRewardType = (props) => {
  //meta title
  document.title = 'Content Detail | HPG'
  const params = props.router.params
  const stringUrl = params.id
  // Initialize an empty object to store key-value stringUrl
  const paramsFromUrl = {}

  // Split the string by '&' to get individual key-value pairs
  const urlSplit = stringUrl.split('&')

  // Loop through each key-value pair
  urlSplit.forEach((pair) => {
    // Split each property by '=' to separate key and value
    const [key, value] = pair.split('=')
    // Store the key-value pair in the params object
    paramsFromUrl[key] = value
  })

  const provinceId = paramsFromUrl['province_id']
  const id = paramsFromUrl['id']

  const [form] = Form.useForm()
  const watchForm = Form.useWatch([], form)
  const dispatch = useDispatch()
  // const [flatData, setFlatData] = useState()

  const programByAreaAndRewardTypeState = (state) => state.ProgramBonusTempReducer
  const ContentDetailProperties = createSelector(programByAreaAndRewardTypeState, (contentReducer) => ({
    contents: contentReducer.programByAreaAndRewardTypes,
    loading: contentReducer.loading,
    total: contentReducer.total,
    value: contentReducer.value
  }))
  const { contents, loading, value, total } = useSelector(ContentDetailProperties)
  const selectProfileState = (state) => state.Profile
  const ProfileProperties = createSelector(selectProfileState, (profile) => ({
    profile: profile.profile
  }))
  const { profile } = useSelector(ProfileProperties)
  const selectLayoutState = (state) => state.Layout
  const LayoutProperties = createSelector(selectLayoutState, (app) => ({
    province: app.province,
    district: app.district,
    ward: app.ward,
    zone: app.zone,
    subzone: app.subzone
  }))

  const { province, district, ward, zone, subzone } = useSelector(LayoutProperties)
  const [showReject, setShowReject] = useState(false)
  const [submittable, setSubmittable] = useState(false)
  const [initData, setInitData] = useState()
  const [showApprove, setShowApprove] = useState(false)
  const [editorState, setEditorState] = useState('')
  const [imageUrl, setImageUrl] = useState()
  const [programDetail, setProgramDetail] = useState({})
  const [onCancel, setOnCancel] = useState(false)
  const [showPause, setShowPause] = useState(false)
  const [showPlay, setShowPlay] = useState(false)
  //   const [loading, setLoading] = useState(true)
  const [programOptions, setProgramOptions] = useState([])
  const [rank, setRank] = useState([])
  const [filter, setFilter] = useState()
  const [page, setPage] = useState(1)
  const [isLoading, setLoading] = useState(loading)
  const [showModal, setShowModal] = useState(false)
  const [photos, setPhotos] = useState()
  const [flatData, setFlatData] = useState([])
  const [constanceFlatData, setconstanceFlatData] = useState([])
  const [tasks, setTasks] = useState([])
  const [checked, setChecked] = useState(false)
  const [valueFilter, setValueFilter] = useState()
  const [descriptionState, setDescriptionState] = useState({})
  const [pagination, setPagination] = useState({
    pageIndex: page,
    pageSize: 10,
    totalRows: total
  })
  const [showImport, setShowImport] = useState(false)
  const [isLoadingImport, setLoadingImport] = useState(false)

  useEffect(() => {
    console.log('descriptionStateUseEffect', descriptionState)
  }, [descriptionState])

  useEffect(() => {
    // setTasks(tasks)
    console.log('tasksUseEffect', tasks)
  }, [tasks])

  useEffect(() => {
    // Flatten the products and push them to an array
    const flatDataPrograms = []
    const flatDataModels = []

    //flat data theo products
    let stt = 0
    contents.forEach((item) => {
      stt += 1
      item.packages.forEach((pkg) => {
        pkg.programmingPackageConfig.forEach((config) => {
          config.products.forEach((product) => {
            const flattenedItemProduct = {
              stt: stt,
              id: item.id, //client id
              name: item?.shopName ? item.shopName : item.name, // tên tài khoản
              phone: item.phone, //số điện thoại
              client: item.client, //loại tài khoản
              packageId: pkg.id,
              images: item.images,
              agentName: item.agentName,
              packageCreatedAt: pkg.createdAt,
              packageUpdatedAt: pkg.updatedAt,
              packageProgrammingId: pkg.programmingId,
              fromValue: config.fromValue, // số lượng tối thiểu
              packageToValue: pkg.toValue,
              money: pkg.money,
              packageCommission: pkg.commission,
              packageConvertValue: pkg.convertValue,
              packageArtifactName: pkg.artifactName,
              packageNumberOfArtifact: pkg.numberOfArtifact,
              packageIndex: pkg.packageIndex, // số gói
              packageMaxNumberOfReward: pkg.maxNumberOfReward,
              total: pkg.total, // thưởng
              packageNumberOfTimesClaimable: pkg.numberOfTimesClaimable,
              productId: product.id,
              productType: product.type,
              productModelId: product.modelId,
              productName: product.name,
              productSerial: product.serial,
              createdAt: product.createdAt, // ngaày quét
              product_modelId: product.model.id,
              product_modelName: product.model.name,
              product_modelCode: product.model.code,
              productUserId: product.userId,
              productFirstProductId: product.firstProductId,
              groupIndex: config.groupIndex,
              uuid: product.uuid,
              model_modelName: '',
              checked: checked,
              description: '',
              displayDocumentationTemplate: value.displayDocumentationTemplate,
              industrySectorName: product?.industrySector?.name,
              industrySectorId: product?.industrySector?.id,
              industrySectorCode: product?.industrySector?.code,
              industrySectorParentId: product?.industrySector?.parentId,
              categoryName: product?.category?.name,
              categoryId: product?.category?.id,
              categoryCode: product?.category?.code,
              categoryParentId: product?.category?.parentId
            }
            flatDataPrograms.push(flattenedItemProduct)
          })
        })
      })
    })

    //flat data theo models
    contents.forEach((item) => {
      item.packages.forEach((pkg) => {
        pkg.programmingPackageConfig.forEach((config) => {
          config.models.forEach((model) => {
            // Flatten model data
            const flattenedItemModel = {
              modelId: model.id,
              modelCreatedAt: model.createdAt,
              modelUpdatedAt: model.updatedAt,
              modelProgrammingId: model.programmingId,
              modelProgrammingProductConfigId: model.programmingProductConfigId,
              modelModelId: model.modelId,
              modelFromValue: model.fromValue,
              modelGroupIndex: model.groupIndex,
              model_modelId: model.model.id,
              model_modelName: model.model.name,
              model_modelCode: model.model.code,
              groupIndex: config.groupIndex,
              packageId: pkg.id,
              id: item.id //client id
            }
            flatDataModels.push(flattenedItemModel)
          })
        })
      })
    })

    const groupedModels = flatDataModels.reduce((acc, item) => {
      const existingGroup = acc.find(
        (group) => group.groupIndex === item.groupIndex && group.packageId === item.packageId && group.id === item.id
      )
      if (existingGroup) {
        existingGroup.modelNames.push(item.model_modelName)
      } else {
        acc.push({
          groupIndex: item.groupIndex,
          packageId: item.packageId,
          id: item.id,
          modelNames: [item.model_modelName]
        })
      }
      return acc
    }, [])

    // Converting groupedModels object into an array
    const groupedModelsArray = Object.values(groupedModels)

    const concatenatedNames = groupedModelsArray?.modelNames?.reduce((acc, name, index) => {
      if (index === 0) {
        return name
      } else {
        return acc + ', ' + name
      }
    }, '')

    //gán group vào flat data theo products
    flatDataPrograms.forEach((program) => {
      groupedModelsArray.forEach((model) => {
        if (
          program.groupIndex === model.groupIndex &&
          program.packageId === model.packageId &&
          program.id === model.id
        ) {
          program.model_modelName = model.modelNames
        }
      })
    })

    console.log(flatDataPrograms)

    setFlatData(flatDataPrograms)
    setconstanceFlatData(flatDataPrograms)
  }, [contents])

  const curProvince = province?.find((item) => item?.id == provinceId)

  const closeModal = () => setShowModal(false)
  const columns = useMemo(
    () => [
      {
        Header: 'STT',
        accessor: 'stt',
        filterable: true,
        disableSortBy: true,
        enableRowSpan: true,
        hideSort: true,
        Cell: (cellProps) => {
          return (page - 1) * 10 + cellProps.row.index + 1
        }
      },
      // {
      //   Header: 'Hình ảnh',
      //   accessor: 'images',
      //   Cell: (cellProps) => (
      //     <a
      //       onClick={() => {
      //         setPhotos(cellProps.value)
      //         setShowModal(true)
      //       }}
      //       className={style['text-gallery']}
      //     >
      //       Ảnh trưng bày
      //     </a>
      //   ),
      //   enableRowSpan: true
      // },
      {
        Header: 'Hình ảnh',
        accessor: (cell) => {
          return `${cell.id}-!@#$%&${cell.images}-!@#$%&${cell.displayDocumentationTemplate}`
        },
        Cell: (cellProps) => (
          <>
            <div>
              <a
                onClick={() => {
                  setPhotos(cellProps?.value?.split('-!@#$%&')[1].split(','))
                  setShowModal(true)
                }}
                className={style['text-gallery']}
              >
                Ảnh trưng bày
              </a>
            </div>
            <div>
              <a
                className={style['text-gallery']}
                href={`${cellProps?.value?.split('-!@#$%&')[2]}`}
                target='_blank'
                rel='noopener noreferrer'
              >
                BB Đăng ký
              </a>
            </div>
          </>
        ),
        enableRowSpan: true
      },
      {
        Header: 'Tên cửa hàng/KTV',
        accessor: 'name',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        enableRowSpan: true,

        Cell: (cellProps) => {
          return <Name {...cellProps} />
        }
      },
      {
        Header: 'SĐT',
        accessor: 'phone',
        enableRowSpan: true,
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <Phone {...cellProps} />
        }
      },
      {
        Header: 'NPP',
        accessor: 'agentName',
        enableRowSpan: true,
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <Name {...cellProps} />
        }
      },
      {
        Header: 'Gói',
        accessor: (cell) => {
          return `${cell.id}-Gói ${cell.packageIndex}`
        },
        enableRowSpan: true,
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <StringGrp {...cellProps} />
        }
      },
      {
        Header: 'Thưởng',
        enableRowSpan: true,
        accessor: 'money',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <Money {...cellProps} />
        }
      },
      {
        Header: 'Model tính thưởng',
        accessor: (cell) => {
          return `${cell.id}-${cell.model_modelName}`
        },
        enableRowSpan: true,
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <BonusModel {...cellProps} />
        }
      },
      {
        Header: 'Số lượng tối thiểu',
        // accessor: 'configFromValue',
        accessor: (cell) => {
          return `${cell.id}-${cell.fromValue}`
        },
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <NumberGrp {...cellProps} />
        }
      },
      {
        Header: 'Serial',
        accessor: 'productSerial',
        filterable: true,
        disableSortBy: true,
        hideSort: true,

        Cell: (cellProps) => {
          return <Serial {...cellProps} />
        }
      },
      {
        Header: 'Model',
        accessor: (cell) => {
          return `${cell.id}-${cell.product_modelName}`
        },
        // enableRowSpan: true,
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <StringGrp {...cellProps} />
        }
      },
      {
        Header: 'Nhóm sản phẩm',
        // accessor: 'modelName',
        accessor: (cell) => {
          return `${cell.id}-${cell.categoryName}`
        },
        // enableRowSpan: true,
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <GrString {...cellProps} />
        }
      },
      {
        Header: 'Ngành hàng',
        accessor: (cell) => {
          return `${cell.id}-${cell.industrySectorName}`
        },
        // enableRowSpan: true,
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <GrString {...cellProps} />
        }
      },
      {
        Header: 'Ngày quét',
        accessor: 'createdAt',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <Date {...cellProps} />
        }
      },
      {
        Header: 'Trạng thái',
        accessor: 'status',
        disableFilters: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <Badge className='badge-soft-info'>{'Chờ đối soát'}</Badge>
        }
      },
      {
        Header: 'Từ chối',
        accessor: (cell) => {
          return `${cell.productId},${cell.id},${cell.packageId},${cell.groupIndex},${cell.uuid},${cell.checked},${cell.description}`
        },
        Cell: (cellProps) => {
          return (
            <Checkbox
              // checked={JSON.parse(cellProps?.value?.split(',')[3])}
              checked={JSON.parse(cellProps?.value?.split(',')[5])}
              onChange={() => handleUpdateData(cellProps.value, cellProps)}
            />
          )
        }
      },
      {
        Header: 'Lý do từ chối',
        accessor: (cell) => {
          return `${cell.productId},${cell.id},${cell.packageId},${cell.groupIndex},${cell.uuid},${cell.checked},${cell.description}`
        },
        Cell: (cellProps) => {
          return (
            <FormInput
              maxLength={50}
              // disabled={!JSON.parse(cellProps?.value?.split(',')[3])}
              value={descriptionState[cellProps.row.id] || ''}
              disabled={!JSON.parse(cellProps?.value?.split(',')[5])}
              onChange={(e) => {
                handleUpdateDescription(cellProps.value, e.target.value, cellProps.row.id)
              }}
              onBlur={(e) => {
                setValueFilter(e.target.value.trim())
              }}
            />
          )
        }
      }
    ],
    [filter, page, contents, flatData, tasks, checked, descriptionState]
  )

  useEffect(() => {
    if (id) {
      dispatch(getProgramByAreaAndRewardType({ location_id: provinceId, id: id }))
    }
  }, [dispatch])

  const setGlobalFilterUser = async (data) => {
    setFilter({ ...data, sort_field: '', sort_order: '' })
    dispatch(
      getProgramByAreaAndRewardType({
        ...data,
        location_id: provinceId,
        id: id,
        sort_field: '',
        sort_order: ''
      })
    )
    setPage(1)
  }

  const handleFilter = (filterValue) => {
    // setFilter({ ...filterValue, sort_field: '', sort_order: '' })

    const keyWord = filterValue?.search?.toLowerCase() || ''
    const phone = filterValue?.phone?.toLowerCase() || ''
    const serial = filterValue?.serial?.toLowerCase() || ''

    if (filterValue.search.length > 0) {
      const filteredResults = constanceFlatData.filter(
        (obj) =>
          obj.name.toLowerCase().includes(keyWord) &&
          obj.phone.toLowerCase().includes(phone) &&
          obj.productSerial.toLowerCase().includes(serial)
      )

      setFlatData(filteredResults)
    } else {
      dispatch(
        getProgramByAreaAndRewardType({
          ...filter,
          location_id: provinceId,
          id: id,
          sort_field: '',
          sort_order: ''
        })
      )
    }
    setPage(1)
  }

  const handleSort = (sortBy) => {
    if (sortBy.length > 0) {
      setFilter({ ...filter, sort_field: sortBy[0].id, sort_order: sortBy[0].desc ? 'desc' : 'asc' })
      dispatch(
        getProgramByAreaAndRewardType({
          ...filter,
          location_id: provinceId,
          id: id,
          sort_field: sortBy[0].id,
          sort_order: sortBy[0].desc ? 'desc' : 'asc'
        })
      )
    } else {
      setFilter({ ...filter, sort_field: '', sort_order: '' })
      dispatch(
        getProgramByAreaAndRewardType({
          ...filter,
          location_id: provinceId,
          id: id,
          sort_field: '',
          sort_order: ''
        })
      )
    }
  }

  const goToPage = (page) => {
    setFilter({ ...filter, page: page })
    setPage(page)
    dispatch(getProgramByAreaAndRewardType({ ...filter, location_id: provinceId, id: id, page: page }))
  }

  const handleUpdateData = useCallback(
    (cellProp, rowdata) => {
      const cellPropvalue = cellProp?.split(',')

      //lấy giá trị của row đang được checked hiệnt tại
      const currentRow = flatData.filter(
        (item) =>
          item.productId == Number(cellPropvalue[0]) &&
          item.id == Number(cellPropvalue[1]) &&
          item.packageId == Number(cellPropvalue[2]) &&
          item.groupIndex == Number(cellPropvalue[3]) &&
          item.uuid.includes(cellPropvalue[4])
      )

      //update lại giá trị checked của flatData
      flatData.forEach((item) => {
        if (
          item.productId == Number(cellPropvalue[0]) &&
          item.id == Number(cellPropvalue[1]) &&
          item.packageId == Number(cellPropvalue[2]) &&
          item.groupIndex == Number(cellPropvalue[3]) &&
          item.uuid.includes(cellPropvalue[4])
        ) {
          item.checked = !JSON.parse(currentRow[0]?.checked)
        }
      })

      //xoá hoặc thêm vào tasks
      if (JSON.parse(currentRow[0]?.checked) == true) {
        setTasks((prevTasks) => [
          ...prevTasks,
          {
            product_id: Number(cellPropvalue[0]),
            user_id: Number(cellPropvalue[1]),
            product_config_id: Number(cellPropvalue[2]),
            group_index: Number(cellPropvalue[3]),
            uuid: cellPropvalue[4],
            description: currentRow[0]?.description
          }
        ])
      } else {
        setTasks((prevTasks) => {
          return prevTasks.filter(
            (item) =>
              item.product_id !== Number(cellPropvalue[0]) ||
              item.user_id !== Number(cellPropvalue[1]) ||
              item.product_config_id !== Number(cellPropvalue[2]) ||
              item.group_index !== Number(cellPropvalue[3]) ||
              item.uuid !== cellPropvalue[4]
          )
        })
      }
    },
    [tasks, flatData, checked]
  )

  const handleUpdateDescription = useCallback(
    (cellProp, data, rowdata) => {
      setDescriptionState({ ...descriptionState, [rowdata]: data })

      const cellPropvalue = cellProp?.split(',')

      //update lại giá trị description của flatData
      flatData.forEach((item) => {
        if (
          item.productId == Number(cellPropvalue[0]) &&
          item.id == Number(cellPropvalue[1]) &&
          item.packageId == Number(cellPropvalue[2]) &&
          item.groupIndex == Number(cellPropvalue[3]) &&
          item.uuid.includes(cellPropvalue[4])
        ) {
          item.description = data
        }
      })

      const existedTask = tasks.filter(
        (item) =>
          item.product_id == Number(cellPropvalue[0]) &&
          item.user_id == Number(cellPropvalue[1]) &&
          item.product_config_id == Number(cellPropvalue[2]) &&
          item.group_index == Number(cellPropvalue[3]) &&
          item.uuid == cellPropvalue[4]
      )
      if (existedTask.length > 0) {
        existedTask.forEach((item) => {
          item.description = data
        })
      }
      console.log('flatData_handleUpdateDescription', flatData)
      console.log('tasks_handleUpdateDescription', tasks)
    },
    [tasks, flatData, descriptionState]
  )

  const handleConfirm = async () => {
    try {
      const res = await confirmReconcileByPackageAPI({ id: id, province_id: provinceId, tasks: tasks })

      if (res) {
        toast('Xác nhận đối soát thành công', { type: 'success' })
        // this.props.history.goBack()
      }
    } catch (error) {
      toast(error.response.data.message, { type: 'error' })
    }
  }

  const handleResetFilter = () => {
    dispatch(
      getProgramByAreaAndRewardType({
        ...filter,
        location_id: provinceId,
        id: id,
        sort_field: '',
        sort_order: ''
      })
    )
    setPage(1)
  }

  const handleClickExport = async (filterValue) => {
    setLoading(true)
    try {
      const res = await exportProgrammingByAreaAndRewardTypeAPI({
        search: filterValue,
        location_id: provinceId,
        id: id
      })
      if (res?.data?.success === false) {
        toast(res?.message, { type: 'error' })
      } else {
        toast('Hệ thống đang xử lý. Tệp của bạn đã được gửi tới email ' + profile?.email + ' trong vài phút', {
          type: 'success'
        })
      }
    } catch (error) {
      toast(error.response.data.message, { type: 'error' })
    }
    setLoading(false)
  }

  // const handleCheckExport = async (data) => {
  //   try {
  //     const res = await checkExportFileAPI(data)

  //     if (res?.data?.status === 'pending') {
  //       setTimeout(() => {
  //         handleCheckExport(data)
  //       }, 2000)
  //     } else {
  //       const nameFile = `${moment().format('yyMMDD')}_Doi soat theo goi san pham.xlsx`
  //       const link = document.createElement('a')
  //       link.href = res?.data?.fileName
  //       link.setAttribute('download', nameFile)
  //       document.body.appendChild(link)
  //       link.click()
  //       toast('Export file thành công!', { type: 'success' })
  //     }
  //   } catch (error) {
  //     toast(error.response.data.message, { type: 'error' })
  //     setLoadingImport(false)
  //   }
  // }

  const handleImport = async () => {
    setShowImport(true)
  }

  const uploadFile = async (files) => {
    setLoadingImport(true)
    let formData = new FormData()
    formData.append('file', files[0])
    formData.append('location_id', provinceId)
    formData.append('id', id)
    formData.append('type', 'reconcile_by_display')

    try {
      const res = await importFileAPI(url.IMPORT_RECONCILE, formData)
      if (res) {
        handleCheckUpload(res.data.data)
      }
    } catch (error) {
      toast(error.response.data.message, { type: 'error' })
      setLoadingImport(false)
    }
  }

  const handleCheckUpload = async (data) => {
    try {
      const res = await checkUploadFileAPI(data)

      if (!res.data.queueFinish) {
        setTimeout(() => {
          handleCheckUpload(data)
        }, 2000)
      } else {
        if (res.data.countFailed > 0) {
          // handleExportFileError(data)
          setShowImport(false)
          setLoadingImport(false)
        }
        toast('Hệ thống đang xử lý . Vui lòng kiểm tra thông tin tại màn hình Lịch sử', { type: 'success' })
        dispatch(
          getProgramByAreaAndRewardType({
            ...filter,
            location_id: provinceId,
            id: id,
            sort_field: '',
            sort_order: ''
          })
        )
        setPage(1)
        setLoadingImport(false)
        setShowImport(false)
      }
    } catch (error) {
      toast(error.response.data.message, { type: 'error' })
      setLoadingImport(false)
    }
  }

  const handleExportFileError = async (data) => {
    try {
      const res = await exportFileError(data)

      if (res) {
        const nameFile = `${moment().format('yyMMDD')}_FileError.xlsx`
        const url = window.URL.createObjectURL(new Blob([res]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', nameFile) //or any other extension
        document.body.appendChild(link)
        link.click()
      }
    } catch (error) {
      toast(error.response.data.message, { type: 'error' })
      setShowImport(false)
      setLoadingImport(false)
    }
  }

  const handleClickDownloadTemplate = async () => {
    try {
      const res = await downloadTemplateReportAPI({ location_id: provinceId, id: id, type: 'reconcile_by_display' })
      const nameFile = `Bieu_mau_import_Doi_soat_thuong_trung_bay.xlsx`
      const url = window.URL.createObjectURL(new Blob([res]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', nameFile) //or any other extension
      document.body.appendChild(link)
      link.click()
      toast('Tải biểu mẫu thành công!', { type: 'success' })
    } catch (error) {
      toast(error.response.data.message, { type: 'error' })
    }
  }

  return (
    <>
      <div className='page-content'>
        <div className='page-title-box container-fluid'>
          {/* <h4 className=' mb-sm-0 font-size-18 pb-4'>Đối soát thưởng</h4> */}
          <Breadcrumb title={'Đối soát thưởng'} breadcrumbItem={'Đối soát thưởng'} />

          {isLoading ? (
            <Spinners setLoading={setLoading} />
          ) : (
            <Row>
              <Col lg='12'>
                <Card>
                  <CardBody>
                    <Space size='middle' direction='vertical' className='w-100'>
                      <ProgramByAreaAndRewardTypeFilter
                        handleFilter={handleFilter}
                        handleConfirm={handleConfirm}
                        handleResetFilter={handleResetFilter}
                        handleExport={handleClickExport}
                        handleImport={handleImport}
                      />
                      <div className='d-flex flex-row '>
                        <div className='mb-xxl-0 me-3 mt-2'>
                          <h5 className='mb-0 card-title flex-grow-1'>{'Danh sách báo cáo'}</h5>
                        </div>
                        <div className='mb-xxl-0'>
                          <button className='btn btn-info btn-rounded me-2'>{value?.code}</button>
                        </div>
                        {/* <div className='mb-xxl-0'>
                          <button className='btn btn-info btn-rounded me-2'>{curProvince?.name}</button>
                        </div> */}
                      </div>
                      <TableRowSpan
                        columns={columns}
                        data={flatData}
                        pagination={pagination}
                        setPagination={setPagination}
                        gotoPageCustom={goToPage}
                        currentPage={page}
                        total={total}
                        isShowTotal={true}
                      />
                    </Space>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </div>
      </div>
      {showModal && <ImageGalleryModal photos={photos} show={showModal} onClose={closeModal} />}
      {showImport && (
        <ImportModal
          onCloseClick={() => {
            setShowImport(false)
          }}
          show={showImport}
          onConfirmClick={uploadFile}
          loading={isLoadingImport}
          title={'Import đối soát'}
          onDownloadClick={handleClickDownloadTemplate}
        />
      )}
    </>
  )
}

export default withRouter(DisplayProgramByAreaAndRewardType)
