import {
    GET_MANAGE_SHOP,
    GET_MANAGE_SHOP_FAIL,
    GET_MANAGE_SHOP_SUCCESS,
    GET_SHOP_DETAIL,
    GET_SHOP_DETAIL_SUCCESS,
    GET_SHOP_DETAIL_FAIL,
    GET_KTVS,
} from "./actionTypes"

export const getManageShop = params => ({
    type: GET_MANAGE_SHOP,
    payload: params,
})

export const getKTVList = params => ({
    type: GET_KTVS,
    payload: params,
})

export const getManageShopSuccess = jobs => ({
    type: GET_MANAGE_SHOP_SUCCESS,
    payload: jobs,
})

export const getManageShopFail = error => ({
    type: GET_MANAGE_SHOP_FAIL,
    payload: error,
})

export const getShopDetail = data => ({
    type: GET_SHOP_DETAIL,
    payload: data,
})

export const getShopDetailSuccess = job => ({
    type: GET_SHOP_DETAIL_SUCCESS,
    payload: job,
})

export const getShopDetailFail = error => ({
    type: GET_SHOP_DETAIL_FAIL,
    payload: error,
})
