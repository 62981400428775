import React, { useCallback, useEffect, useMemo, useState } from 'react'

//import components

import {
  BaseCol,
  NoCol,
  DateCol,
  CreateUpdateDuration,
  CreateUpdateDurationMoment,
  ListCode,
  Type,
  ListStartEndDate,
  ListReward,
  ListProgramingApply,
  ModelName,
  SumBonus,
  CreateDurationMoment,
  Serial,
  UserShopName,
  ProgramCode,
  ProgramReward,
  StringGrp,
  NumberGrp,
  DateFormat,
  BaseMoney,
  BaseString,
  RewardStatus,
  RewardDate
} from './components/ManageAccountCol'

import { Col, Row, UncontrolledTooltip, Card, CardBody, Badge } from 'reactstrap'
import Spinners from 'components/Common/Spinner'
import { toast } from 'react-toastify'
import TableContainer from 'components/Common/TableContainer'
import { ClassConfigVN, LocalityVN, ManageProgramVN } from 'constants/vn'
import ConfirmModal from 'components/Common/ConfirmModal'
import {
  checkExportFileAPI,
  checkUploadFileAPI,
  confirmAccountingReconcileByPackageAPI,
  confirmAccountingReconcileByProductAPI,
  confirmReconcileByProductAPI,
  downloadTemplateReconcileAccountingAPI,
  downloadTemplateReportAPI,
  exportByProductAPI,
  exportFileError,
  exportReportAccountingProgrammingByRewardTypeAPI,
  getPointConfigListAPI,
  getProductBonusTempListAPI,
  getReportAccountingSearchProgramingByRewardTypeAPI,
  importFileAPI,
  updatePointConfigAPI
} from 'helpers/backend_helper'
import { Checkbox, Form, Input, Space } from 'antd'
import { FormSelect } from 'components/Common/form-select/FormSelect'
import { StatusProgramOptions, TypeProgramOptions } from 'constants/constants'
import { formateDate } from 'helpers/global_helper'
import fileDownload from 'js-file-download'
import moment from 'moment'
import { TableRowSpan } from 'components/Common/TableCustom'
import withRouter from 'components/Common/withRouter'
import { FormInput } from 'components/Common/form-input/FormInput'
import ProductBonusTempListFilter from 'pages/ReportTemporaryReconciliation/components/ProductBonusTempListFilter'
import ProgramByAreaAndRewardTypeFilter from 'pages/ProgramBonusTemp/components/ProgramByAreaAndRewardTypeFilter'
import ConfirmProgramByRewardTypeFilter from './components/ConfirmProgramByRewardTypeFilter'
import { ImageGalleryModal } from 'pages/RecordPayment/components'
import style from './components/style.module.scss'
import { ConcileStatus } from 'pages/ProgramBonusTemp/ProgramByAreaAndRewardTypeCol'
import Breadcrumb from 'components/Common/Breadcrumb'
import * as url from 'helpers/url_helper'
import ImportModal from 'components/Common/ImportModal'
import { createSelector } from 'reselect'
import { useSelector } from 'react-redux'

// import ProductBonusTempListFilter from './components/ProductBonusTempListFilter'

const DisplayProgramByRewardType = (props) => {
  document.title = 'Manage Point Config CMS List | Skote - React Admin & Dashboard Template'
  const id = props.router.params

  const [isLoading, setLoading] = useState(false)
  const [filter, setFilter] = useState({ id })
  const [edit, setEdit] = useState(true)
  const [listTable, setListTable] = useState([])
  const [total, setTotal] = useState()
  const [showModal, setShowModal] = useState(false)
  const [photos, setPhotos] = useState()

  const [form] = Form.useForm()
  const watchForm = Form.useWatch([], form)
  const [disabledSubmit, setDisabledSubmit] = useState(true)
  const [page, setPage] = useState(1)
  const [listDataChange, setListDataChange] = useState([])
  const [tasks, setTasks] = useState([])
  const [flatData, setFlatData] = useState([])
  const [constanceFlatData, setconstanceFlatData] = useState([])
  const [checked, setChecked] = useState(false)
  const [value, setValue] = useState()
  const [pagination, setPagination] = useState({
    pageIndex: page,
    pageSize: 10,
    totalRows: total
  })
  const [descriptionState, setDescriptionState] = useState({})
  const [showImport, setShowImport] = useState(false)
  const [isLoadingImport, setLoadingImport] = useState(false)

  const selectProfileState = (state) => state.Profile
  const ProfileProperties = createSelector(selectProfileState, (profile) => ({
    profile: profile.profile
  }))
  const { profile } = useSelector(ProfileProperties)

  useEffect(() => {
    console.log('tasksUseEffect', tasks)
  }, [tasks])

  useEffect(() => {
    console.log('descriptionStateUseEffect', descriptionState)
  }, [descriptionState])

  useEffect(() => {
    // Flatten the products and push them to an array
    const flatDataPrograms = []
    const flatDataModels = []

    let stt = 0
    listTable.forEach((item) => {
      stt += 1
      let sttItemIndexGrp = 0
      item.packages.forEach((pkg) => {
        pkg.programmingPackageConfig.forEach((config) => {
          config.products.forEach((product, config) => {
            sttItemIndexGrp += 1
            const flattenedItemProduct = {
              stt: stt,
              id: item.id, //client id
              name: item.name, // tên tài khoản
              phone: item.phone, //số điện thoại
              client: item.client, //loại tài khoản
              packageId: pkg.id,
              images: item.images,
              agentName: item.agentName,
              packageCreatedAt: pkg.createdAt,
              packageUpdatedAt: pkg.updatedAt,
              packageProgrammingId: pkg.programmingId,
              fromValue: config.fromValue, // số lượng tối thiểu
              packageToValue: pkg.toValue,
              money: pkg.money,
              packageCommission: pkg.commission,
              packageConvertValue: pkg.convertValue,
              packageArtifactName: pkg.artifactName,
              packageNumberOfArtifact: pkg.numberOfArtifact,
              packageIndex: pkg.packageIndex, // số gói
              packageMaxNumberOfReward: pkg.maxNumberOfReward,
              total: pkg.total, // thưởng
              numberOfTimesClaimable: pkg.numberOfTimesClaimable,
              productId: product.id,
              productType: product.type,
              productModelId: product.modelId,
              productName: product.name,
              productSerial: product.serial,
              createdAt: product.createdAt, // ngaày quét
              product_modelId: product.model.id,
              product_modelName: product.model.name,
              product_modelCode: product.model.code,
              productUserId: product.userId,
              productFirstProductId: product.firstProductId,
              groupIndex: config.groupIndex,
              uuid: product.uuid,
              model_modelName: '',
              checked: checked,
              description: '',
              sttItemIndexGrp: sttItemIndexGrp,
              status: product.status,
              endDate: value?.endDate,
              finishTimeDisplayDocumentation: value?.finishTimeDisplayDocumentation,
              displayDocumentationTemplate: value?.displayDocumentationTemplate
            }
            flatDataPrograms.push(flattenedItemProduct)
          })
        })
      })
    })

    //flat data theo models
    listTable.forEach((item) => {
      item.packages.forEach((pkg) => {
        pkg.programmingPackageConfig.forEach((config) => {
          config.models.forEach((model) => {
            // Flatten model data
            const flattenedItemModel = {
              modelId: model.id,
              modelCreatedAt: model.createdAt,
              modelUpdatedAt: model.updatedAt,
              modelProgrammingId: model.programmingId,
              modelProgrammingProductConfigId: model.programmingProductConfigId,
              modelModelId: model.modelId,
              modelFromValue: model.fromValue,
              modelGroupIndex: model.groupIndex,
              model_modelId: model.model.id,
              model_modelName: model.model.name,
              model_modelCode: model.model.code,
              groupIndex: config.groupIndex,
              packageId: pkg.id,
              id: item.id //client id
            }
            flatDataModels.push(flattenedItemModel)
          })
        })
      })
    })

    // //group
    const groupedModels = flatDataModels.reduce((acc, item) => {
      const existingGroup = acc.find(
        (group) => group.groupIndex === item.groupIndex && group.packageId === item.packageId && group.id === item.id
      )
      if (existingGroup) {
        existingGroup.modelNames.push(item.model_modelName)
      } else {
        acc.push({
          groupIndex: item.groupIndex,
          packageId: item.packageId,
          id: item.id,
          modelNames: [item.model_modelName]
        })
      }
      return acc
    }, [])

    // Converting groupedModels object into an array
    const groupedModelsArray = Object.values(groupedModels)

    //gán group vào flat data theo products
    flatDataPrograms.forEach((program) => {
      groupedModelsArray.forEach((model) => {
        if (
          program.groupIndex === model.groupIndex &&
          program.packageId === model.packageId &&
          program.id === model.id
        ) {
          program.model_modelName = model.modelNames
        }
      })
    })

    console.log(flatDataPrograms, 'flatDataPrograms')

    setFlatData(flatDataPrograms)
    setconstanceFlatData(flatDataPrograms)
  }, [listTable])

  useEffect(() => {
    handleGetListTable()
  }, [filter])

  useEffect(() => {
    if (edit == true) {
      listTable.map((item, index) => {
        form.setFieldsValue({
          [item.id]: item.point
        })
      })
    }
  }, [edit])

  useEffect(() => {
    if (edit == true) {
      listTable.map((item, index) => {
        form.setFieldsValue({
          [item.id]: item.point
        })
      })
    }
    listDataChange.map((item, index) => {
      form.setFieldsValue({
        [item.id]: item.point
      })

      handleCheckPoint(item.id, item.point)
    })
  }, [listTable])

  useEffect(() => {
    const data = form.getFieldsError()
    const check = []
    data.map((item) => {
      if (item.errors.length > 0) {
        check.push(false)
      } else {
        check.push(true)
      }
    })

    if (check.includes(false)) {
      setDisabledSubmit(true)
    } else {
      setDisabledSubmit(false)
    }
  }, [watchForm])

  const handleGetListTable = async () => {
    try {
      const res = await getReportAccountingSearchProgramingByRewardTypeAPI({ id: id.id, page: page })

      if (res.data) {
        setListTable(res.data)
      }

      if (res.meta) {
        setTotal(res.meta.totalItems)
      }

      if (res.value) {
        setValue(res.value)
      }
    } catch (error) {
      toast(error.response.data.message, { type: 'error' })
    }
  }

  console.log('', flatData)
  // console.log('calculateTotalReward', calculateTotalReward)

  const columns = useMemo(
    () => [
      {
        Header: 'STT',
        accessor: 'stt',
        filterable: true,
        disableSortBy: true,
        enableRowSpan: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <BaseString {...cellProps} />
        }
      },
      // {
      //   Header: 'Hình ảnh',
      //   accessor: 'images',
      //   Cell: (cellProps) => (
      //     <a
      //       onClick={() => {
      //         setPhotos(cellProps.value)
      //         setShowModal(true)
      //       }}
      //       className={style['text-gallery']}
      //     >
      //       Ảnh trưng bày
      //     </a>
      //   ),
      //   enableRowSpan: true
      // },
      {
        Header: 'Hình ảnh',
        accessor: (cell) => {
          return `${cell.id}-!@#$%&${cell.images}-!@#$%&${cell.displayDocumentationTemplate}`
        },
        Cell: (cellProps) => (
          <>
            <div>
              <a
                onClick={() => {
                  setPhotos(cellProps?.value?.split('-!@#$%&')[1].split(','))
                  setShowModal(true)
                }}
                className={style['text-gallery']}
              >
                Ảnh trưng bày
              </a>
            </div>
            <div>
              <a
                className={style['text-gallery']}
                href={`${cellProps?.value?.split('-!@#$%&')[2]}`}
                target='_blank'
                rel='noopener noreferrer'
              >
                BB Đăng ký
              </a>
            </div>
          </>
        ),
        enableRowSpan: true
      },
      {
        Header: 'Loại TK',
        accessor: (cell) => {
          return `${cell.id}-${cell.client}`
        },
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        enableRowSpan: true,

        Cell: (cellProps) => {
          return <Type {...cellProps} />
        }
      },
      {
        Header: 'Tên tài khoản',
        accessor: 'name',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        enableRowSpan: true,

        Cell: (cellProps) => {
          return <BaseString {...cellProps} />
        }
      },
      {
        Header: 'Số điện thoại',
        // accessor: 'phone',
        accessor: (cell) => {
          return `${cell.id}-${cell.phone}`
        },
        enableRowSpan: true,
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <StringGrp {...cellProps} />
        }
      },
      {
        Header: 'Gói',
        accessor: (cell) => {
          return `${cell.id}-Gói ${cell.packageIndex}`
        },
        enableRowSpan: true,
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <StringGrp {...cellProps} />
        }
      },
      {
        Header: 'Số gói',
        accessor: (cell) => {
          return `${cell.id}-${cell.numberOfTimesClaimable}`
        },
        enableRowSpan: true,
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <StringGrp {...cellProps} />
        }
      },
      {
        Header: 'Thưởng',
        enableRowSpan: true,
        // accessor: 'total',
        accessor: (cell) => {
          return `${cell.id}-${cell.total}`
        },
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <NumberGrp {...cellProps} />
        }
      },
      {
        Header: 'Model tính thưởng',
        accessor: (cell) => {
          return `${cell.id}-${cell.model_modelName}`
        },
        enableRowSpan: true,
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <StringGrp {...cellProps} />
        }
      },
      {
        Header: 'Số lượng tối thiểu',
        enableRowSpan: true,
        accessor: (cell) => {
          return `${cell.groupIndex}-${cell.fromValue}`
        },
        filterable: true,

        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <NumberGrp {...cellProps} />
        }
      },
      {
        Header: 'Serial',
        accessor: 'productSerial',
        filterable: true,
        disableSortBy: true,
        hideSort: true,

        Cell: (cellProps) => {
          return <BaseString {...cellProps} />
        }
      },
      {
        Header: 'Ngày xét thưởng',
        // accessor: 'createdAt',
        accessor: (cell) => {
          return cell
        },
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <RewardDate {...cellProps} />
        }
      },
      {
        Header: 'Trạng thái',
        accessor: 'status',
        disableFilters: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <RewardStatus {...cellProps} />
        }
      },
      {
        Header: 'Từ chối',
        accessor: (cell) => {
          return cell
        },
        enableRowSpan: true,
        Cell: (cellProps) => {
          if (cellProps.value.sttItemIndexGrp === 1) {
            return (
              <Checkbox
                checked={JSON.parse(cellProps?.value?.checked)}
                onChange={() => handleUpdateData(cellProps.value, cellProps)}
              />
            )
          } else {
            return <></>
          }
        }
      },
      {
        Header: 'Lý do từ chối',
        accessor: (cell) => {
          return cell
        },
        // accessor: accessor,
        Cell: (cellProps) => {
          if (cellProps.value.sttItemIndexGrp === 1) {
            return (
              <FormInput
                maxLength={50}
                value={descriptionState[cellProps.row.id] || ''}
                disabled={!JSON.parse(cellProps?.value?.checked)}
                onChange={(e) => {
                  handleUpdateDescription(cellProps.value, e.target.value, cellProps.row.id)
                }}
                onBlur={(e) => {
                  // setValueFilter(e.target.value.trim())
                }}
              />
            )
          } else {
            return <></>
          }
        }
      }
    ],
    [filter, page, listTable, flatData, tasks, checked, descriptionState]
  )

  const handleCheckPoint = (id, value) => {
    if (value == null) {
      form.setFields([{ name: `${id}`, errors: ['Vui lòng nhập điểm'] }])
    } else if (handleCheckNguyen(value) == false) {
      form.setFields([{ name: `${id}`, errors: ['Điểm là số nguyên dương.'] }])
    } else {
      form.setFields([{ name: `${id}`, errors: null }])
    }
    const data = form.getFieldsValue()
    const list = [...listDataChange]
    listTable.map((item, index) => {
      if (item.point != data[item.id]) {
        const find = list.find((x) => x.id == item.id)
        if (find) {
          find.point = data[item.id]
        } else {
          list.push({
            id: item.id,
            point: data[item.id]
          })
        }
      }
    })
    setListDataChange(list)
  }

  const handleSave = async () => {
    const check = []
    listDataChange.map((item) => {
      if (item.point == '' || item.point == null) {
        check.push(false)
      } else {
        check.push(true)
      }
    })

    if (check.includes(false)) {
      return toast('Vui lòng nhập đẩy đủ các điểm ở các trang.', { type: 'error' })
    }

    try {
      const res = await updatePointConfigAPI({ tasks: listDataChange })

      if (res) {
        toast('Cập nhật thành công điểm.', { type: 'success' })
        setEdit(false)
        setListDataChange([])
        handleGetListTable()
      }
    } catch (error) {
      toast(error.response.data.message, { type: 'error' })
    }
  }

  const handleCheckNguyen = (value) => {
    if (Number(value) > 0 && Number.isInteger(Number(value))) {
      return true
    } else {
      return false
    }
  }

  const handleClickExport = async () => {
    const res = await exportByProductAPI({ ...filter })
    fileDownload(res, `${moment().format('yyMMDD')}_Tam tinh thuong theo san pham.xlsx`)
  }

  const handleFilter = (filterValue) => {
    // setFilter({ ...filterValue, sort_field: '', sort_order: '' })
    const keyWord = filterValue?.search?.toLowerCase() || ''
    const phone = filterValue?.phone?.toLowerCase() || ''
    const serial = filterValue?.serial?.toLowerCase() || ''

    if (filterValue.search.length > 0) {
      const filteredResults = constanceFlatData.filter(
        (obj) =>
          obj.name.toLowerCase().includes(keyWord) &&
          obj.phone.toLowerCase().includes(phone) &&
          obj.productSerial.toLowerCase().includes(serial)
      )

      setFlatData(filteredResults)
    } else {
      handleGetListTable({ sort_field: '', sort_order: '' })
    }
    setPage(1)
  }

  const HandleSetDescription = (cell, description) => {
    setDescriptionState({ ...descriptionState, [cell]: description })
  }

  const handleUpdateData = useCallback(
    (cellPropvalue, rowdata) => {
      console.log(cellPropvalue, 'cellPropvalue')
      console.log(rowdata, 'rowdata')

      //Lấy các giá trị có cùng userId trong flatdata
      let itemsInFlatData = flatData.filter((item) => item.id === cellPropvalue.id)

      //update lại giá trị checked của flatData nếu trùng userId của row được chọn
      flatData.forEach((item) => {
        if (item.id == Number(cellPropvalue.id)) {
          item.checked = !JSON.parse(cellPropvalue?.checked)
        }
      })

      //xoá hoặc thêm vào tasks list có cùng userId tìm được
      if (JSON.parse(cellPropvalue?.checked) == true) {
        itemsInFlatData.forEach((itm) => {
          //   return `${cell.productId},${cell.id},${cell.packageId},${cell.groupIndex},${cell.uuid},${cell.checked}`
          setTasks((prevTasks) => [
            ...prevTasks,
            {
              product_id: itm.productId,
              user_id: itm.id,
              product_config_id: itm.packageId,
              group_index: itm.groupIndex,
              uuid: itm.uuid,
              description: itm.description
            }
          ])
        })
      } else {
        setTasks((prevTasks) => {
          return prevTasks.filter((item) => item.user_id !== Number(cellPropvalue.id))
        })
      }
    },
    [tasks, flatData, checked]
  )

  const handleUpdateDescription = useCallback(
    (cellPropvalue, data, rowdata) => {
      setDescriptionState({ ...descriptionState, [rowdata]: data })

      //Lấy các giá trị có cùng userId trong flatdata
      let itemsInFlatData = flatData.filter((item) => item.id === cellPropvalue.id)

      //update lại giá trị description của flatData nếu trùng id
      flatData.forEach((item) => {
        if (
          // item.productId == Number(cellPropvalue[0]) &&
          item.id == Number(cellPropvalue.id)
        ) {
          item.description = data
        }
      })

      //Cập nhật lại description vào task nếu trùng userid
      const existedTask = tasks.filter((item) => item.user_id == Number(cellPropvalue.id))
      if (existedTask.length > 0) {
        existedTask.forEach((item) => {
          item.description = data
        })
      }
      console.log('flatData_handleUpdateDescription', flatData)
      console.log('tasks_handleUpdateDescription', tasks)
    },
    [tasks, flatData, descriptionState]
  )

  const handleConfirm = async () => {
    try {
      const res = await confirmAccountingReconcileByPackageAPI({ id: id.id, tasks: tasks })

      if (res) {
        toast('Xác nhận trả thưởng thành công', { type: 'success' })
      }
    } catch (error) {
      toast(error.response.data.message, { type: 'error' })
    }
  }

  const closeModal = () => setShowModal(false)

  const goToPage = (page) => {
    setPage(page)
    handleGetListTable({ ...filter, page })
    setFilter({ ...filter, page })
  }

  const handleResetFilter = () => {
    handleGetListTable({ sort_field: '', sort_order: '' })
    setPage(1)
  }

  const handleExport = async (filterValue) => {
    setLoading(true)
    try {
      const res = await exportReportAccountingProgrammingByRewardTypeAPI({
        id: id.id
      })
      if (res?.data?.success === false) {
        toast(res?.message, { type: 'error' })
      } else {
        toast('Hệ thống đang xử lý. Tệp của bạn đã được gửi tới email ' + profile?.email + ' trong vài phút', {
          type: 'success'
        })
      }
    } catch (error) {
      toast(error.response.data.message, { type: 'error' })
    }
    setLoading(false)
  }

  // const handleCheckExport = async (data) => {
  //   try {
  //     const res = await checkExportFileAPI(data)

  //     if (res?.data?.status === 'pending') {
  //       setTimeout(() => {
  //         handleCheckExport(data)
  //       }, 2000)
  //     } else {
  //       const nameFile = `${moment().format('yyMMDD')}_Tra thuong trung bay.xlsx`
  //       const link = document.createElement('a')
  //       link.href = res?.data?.fileName
  //       link.setAttribute('download', nameFile)
  //       document.body.appendChild(link)
  //       link.click()
  //       toast('Export file thành công!', { type: 'success' })
  //     }
  //   } catch (error) {
  //     toast(error.response.data.message, { type: 'error' })
  //   }
  // }

  const handleImport = async () => {
    setShowImport(true)
  }

  const uploadFile = async (files) => {
    setLoadingImport(true)
    let formData = new FormData()
    formData.append('file', files[0])
    formData.append('id', id.id)
    formData.append('type', 'reconcile_accounting_by_display')

    try {
      const res = await importFileAPI(url.IMPORT_RECONCILE_ACCOUNTING, formData)
      if (res) {
        handleCheckUpload(res.data.data)
      }
    } catch (error) {
      toast(error.response.data.message, { type: 'error' })
      setLoadingImport(false)
    }
  }

  const handleCheckUpload = async (data) => {
    try {
      const res = await checkUploadFileAPI(data)

      if (!res.data.queueFinish) {
        setTimeout(() => {
          handleCheckUpload(data)
        }, 2000)
      } else {
        if (res.data.countFailed > 0) {
          // handleExportFileError(data)
          setShowImport(false)
          setLoadingImport(false)
        }
        toast('Hệ thống đang xử lý . Vui lòng kiểm tra thông tin tại màn hình Lịch sử', { type: 'success' })
        handleGetListTable({ sort_field: '', sort_order: '' })
        setPage(1)
        setLoadingImport(false)
        setShowImport(false)
      }
    } catch (error) {
      toast(error.response.data.message, { type: 'error' })
      setLoadingImport(false)
    }
  }

  const handleExportFileError = async (data) => {
    try {
      const res = await exportFileError(data)

      if (res) {
        const nameFile = `${moment().format('yyMMDD')}_FileError.xlsx`
        const url = window.URL.createObjectURL(new Blob([res]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', nameFile) //or any other extension
        document.body.appendChild(link)
        link.click()
      }
    } catch (error) {
      toast(error.response.data.message, { type: 'error' })
      setShowImport(false)
      setLoadingImport(false)
    }
  }

  const handleClickDownloadTemplate = async () => {
    try {
      const res = await downloadTemplateReconcileAccountingAPI({
        id: id.id,
        type: 'reconcile_accounting_by_display'
      })
      const nameFile = `Bieu_mau_import_xac_nhan_tra_thuong_trung_bay.xlsx`
      const url = window.URL.createObjectURL(new Blob([res]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', nameFile) //or any other extension
      document.body.appendChild(link)
      link.click()
      toast('Tải biểu mẫu thành công!', { type: 'success' })
    } catch (error) {
      toast(error.response.data.message, { type: 'error' })
    }
  }

  return (
    <div className='page-content'>
      <div className='page-title-box container-fluid'>
        {/* <h4 className=' mb-sm-0 font-size-18 pb-4'>Ghi nhận thanh toán</h4> */}
        <Breadcrumb title={'Ghi nhận thanh toán'} breadcrumbItem={'Ghi nhận thanh toán'} />
        {isLoading ? (
          <Spinners setLoading={setLoading} />
        ) : (
          <Row>
            <Col lg='12'>
              <Card>
                <CardBody>
                  <Space size='middle' direction='vertical' className='w-100'>
                    <ConfirmProgramByRewardTypeFilter
                      handleFilter={handleFilter}
                      handleConfirm={handleConfirm}
                      handleResetFilter={handleResetFilter}
                      handleExport={handleExport}
                      handleImport={handleImport}
                    />
                    <div className='mb-xxl-0 me-3 mt-2'>
                      <h5 className='mb-0 card-title flex-grow-1'>{'Danh sách báo cáo'}</h5>
                    </div>
                    <div className='d-flex flex-row '>
                      <div className='mb-xxl-0'>
                        <button className='btn btn-info btn-rounded me-2 my-2'>{value?.code}</button>
                      </div>
                      <div className='mb-xxl-0'>
                        {value?.provinces?.map((item, index) => (
                          <button className='btn btn-light btn-rounded me-2 my-2' key={index} value={item?.id}>
                            {item?.name}
                          </button>
                        ))}
                      </div>
                    </div>
                    <TableRowSpan
                      columns={columns}
                      data={flatData}
                      pagination={pagination}
                      setPagination={setPagination}
                      gotoPageCustom={goToPage}
                      currentPage={page}
                      total={total}
                    />
                  </Space>
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
      </div>
      {showModal && <ImageGalleryModal photos={photos} show={showModal} onClose={closeModal} />}
      {showImport && (
        <ImportModal
          onCloseClick={() => {
            setShowImport(false)
          }}
          show={showImport}
          onConfirmClick={uploadFile}
          loading={isLoadingImport}
          title={'Import xác nhận trả thưởng'}
          onDownloadClick={handleClickDownloadTemplate}
        />
      )}
    </div>
  )
}

export default withRouter(DisplayProgramByRewardType)
