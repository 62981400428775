import React from 'react'
import { Badge, UncontrolledTooltip } from 'reactstrap'
import { Link } from 'react-router-dom'
import { ManageContentVN } from 'constants/vn'
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css'
import { formateDate } from 'helpers/global_helper'
import { TypeContentOptions, TypeProgramOptions } from 'constants/constants'
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'
import { Select, Tooltip } from 'antd'
import { FormSelect } from 'components/Common/form-select/FormSelect'

const ContentNo = (cell) => {
  return (
    <div className='d-flex justify-content-between align-items-center'>
      <div className='text-body fw-bold'>{cell?.row ? cell.row.index + 1 + (cell.page - 1) * 10 : ''}</div>
    </div>
  )
}

const BaseString = (cell) => {
  return cell.value ? cell.value : '--'
}

const Datetime = (cell) => {
  return cell.value ? moment(cell.value).format('DD/MM/YY HH:mm:ss') : '--'
}

const ContentName = (cell) => {
  return cell.value ? (
    <div>
      <span
        style={{ maxWidth: 300 }}
        className='d-inline-block text-truncate'
        id={`namecontent-tooltip-${cell.row.original.id}`}
      >
        {cell.value}
      </span>
      {/* <UncontrolledTooltip placement='top' target={`namecontent-tooltip-${cell.row.original.id}`}>
        {cell.value}
      </UncontrolledTooltip> */}
    </div>
  ) : (
    '--'
  )
}

const DisplayDate = (cell) => {
  return cell.value ? formateDate(cell.value) + '-' + formateDate(cell.row.original?.endDate) : '--'
}

const UpdatetedDate = (cell) => {
  return cell.value ? formateDate(cell.value) : '--'
}

const Type = (cell) => {
  const type = [
    {
      label: 'Tích lũy',
      value: 'accumulation'
    },
    {
      label: 'Kích hoạt',
      value: 'activation'
    },
    {
      label: 'Trưng bày',
      value: 'display'
    },
    {
      label: 'Tin tức',
      value: 'news'
    },
    {
      label: 'Khác',
      value: 'other'
    }
  ]
  return cell?.value ? type.find((e) => e.value === cell?.value)?.label : '--'
}

const Status = (cell) => {
  switch (cell.value) {
    case 'pending':
      return <Badge className='badge-soft-info'>{'Chưa gửi'}</Badge>
    case 'success':
      return <Badge className='badge-soft-success'>{'Đã gửi'}</Badge>
    case 'cancelled':
      return <Badge className='badge-soft-warning'>{'Đã huỷ'}</Badge>
    case 'failed':
      return <Badge className='badge-soft-danger'>{'Không thành công'}</Badge>
  }
}

const ListReceivers = (cell) => {
  // debugger
  let concatenated = ''
  let concatenated_ToolTip = ''

  const key = uuidv4()
  const id = cell.row.id

  const listOption = [{ label: 'Tất cả', value: 'all' }].concat(
    cell?.value?.map((e) => ({ label: e.phone, value: e.id }))
  )

  // Lấy 3 phần tử đầu của mảng
  let resultString = cell?.value.slice(0, 3)

  //lấy 10 phần tử đầu của mảng để tooltip
  let resultToolTipString = cell?.value.slice(0, 10)

  //thêm thẻ xuống dòng
  for (let i = 0; i < resultString?.length; i++) {
    concatenated += cell?.value[i]?.phone
    concatenated += ' <br />'
  }

  //thêm thẻ xuống dòng tooltip
  for (let i = 0; i < resultToolTipString?.length; i++) {
    concatenated_ToolTip += cell?.value[i]?.phone
    concatenated_ToolTip += ' <br />'
  }

  // Kiểm tra nếu có nhiều hơn 3 phần tử thì thêm dấu '...'
  if (cell?.value.length > 3) {
    concatenated += ' ...  <br />'
  }

  const resultArr = []
  const arr = concatenated.split(/<br \/>/)
  arr.forEach((item, i) => {
    resultArr.push(<br />)
    resultArr.push(item)
  })

  // Kiểm tra nếu có nhiều hơn 10 phần tử thì thêm dấu '...'
  if (cell?.value.length > 10) {
    concatenated_ToolTip += ' ...  <br />'
  }

  const resultArr_ToolTip = []
  const arrToolTip = concatenated_ToolTip.split(/<br \/>/)
  arrToolTip.forEach((item, i) => {
    resultArr_ToolTip.push(<br />)
    resultArr_ToolTip.push(item)
  })

  return (
    <>
      <p id={`bonus-Model-${id}`}>{resultArr ? resultArr : '--'}</p>
      <UncontrolledTooltip trigger='hover' placement='top' target={`bonus-Model-${id}`}>
        {resultArr_ToolTip}
      </UncontrolledTooltip>
    </>
  )
}

export { ContentNo, ContentName, DisplayDate, Status, UpdatetedDate, Type, ListReceivers, BaseString, Datetime }
