import { call, put, takeEvery } from "redux-saga/effects"

import { GET_MANAGE_PERMISSION, GET_PERMISSION_DETAIL, GET_ROLE_ALL } from "./actionTypes"
import { getManagePermissionSuccess, getManagePermissionFail, getPermissionDetailSuccess, getRoleAllSuccess, getRoleAllFail } from "./actions"

import { getPermissionListAPI, getPermissionDetailsAPI, getRoleListAPI } from "helpers/backend_helper"

function* fetchPermissionList({ payload: params }) {
    try {
        const response = yield call(getPermissionListAPI, { ...params })
        yield put(getManagePermissionSuccess(response))
    } catch (error) {
        yield put(getManagePermissionFail(error?.data?.message))
    }
}

function* fetchRoleList({ payload: params }) {
    try {
        const response = yield call(getRoleListAPI, { ...params })
        yield put(getRoleAllSuccess(response))
    } catch (error) {
        yield put(getRoleAllFail(error?.data?.message))
    }
}

function* fetchPermissionDetail({ payload: data }) {
    try {
        const response = yield call(getPermissionDetailsAPI, { id: data.id, type: data.type })
        yield put(getPermissionDetailSuccess(response.data))
    } catch (error) {
        console.log(error)
        // yield put(getProductDetailFail(error))
    }
}

function* PermissionsSaga() {
    yield takeEvery(GET_MANAGE_PERMISSION, fetchPermissionList)
    yield takeEvery(GET_PERMISSION_DETAIL, fetchPermissionDetail)
    yield takeEvery(GET_ROLE_ALL, fetchRoleList)
}

export default PermissionsSaga
