import React, { useState } from 'react'
import { Col, Label } from 'reactstrap'

//flatpickr
import 'flatpickr/dist/themes/material_blue.css'
import { ActivationVN, CommonVN } from 'constants/vn'
import { createSelector } from 'reselect'
import { useSelector } from 'react-redux'
import { FormSelect } from './form-select/FormSelect'
import { DatePicker } from 'antd'
import { StatusProgramOptions, TypeProgramOptions } from 'constants/constants'

const ReconcileProgramByAreaFilter = ({ setGlobalFilter, search, setSearch, isActivationFilter, programingCode }) => {
  const selectLayoutState = (state) => state.Layout
  const LayoutProperties = createSelector(selectLayoutState, (app) => ({
    zone: app.zone,
    province: app.province,
    district: app.district,
    ward: app.ward,
    bank: app.bank
  }))
  const { zone, province } = useSelector(LayoutProperties)

  const [filter, setFilter] = useState({})

  const handleSelect = (value, key) => {
    setFilter((e) => ({ ...e, [key]: value }))
  }

  return (
    <React.Fragment>
      <Col xxl={2} className='mt-3' lg={2}>
        <Label className='form-label'>{'Tỉnh thành phố'}</Label>
        <FormSelect
          options={province?.map((item) => ({
            value: item.id,
            label: item.name
          }))}
          onChange={(value) => {
            handleSelect(value, 'province_id')
          }}
          placeholder={`Chọn tỉnh thành phố`}
        />
      </Col>
      <Col
        xxl={isActivationFilter ? 4 : 8}
        lg={isActivationFilter ? 10 : 2}
        className='d-flex align-items-end justify-content-end mt-3'
      >
        <div className='mb-xxl-0 me-2'>
          <button
            type='button'
            className='btn btn-soft-danger w-100'
            onClick={() => {
              setFilter({
                province_id: undefined
              })
              setSearch(undefined)
              setGlobalFilter({})
            }}
          >
            Xóa tìm kiếm
          </button>
        </div>
        <div className='mb-xxl-0'>
          <button
            type='button'
            className='btn btn-primary w-100'
            onClick={() => {
              const _filter = !filter
                ? filter
                : Object.fromEntries(
                    Object.entries(filter).filter(([key, value]) => value !== undefined && value !== '')
                  )
              setGlobalFilter({ ..._filter, search })
            }}
          >
            Tìm kiếm
          </button>
        </div>
      </Col>

      <Col xxl={8} className='mt-3' lg={10}>
        <div className='d-flex flex-row '>
          <div className='mb-xxl-0 me-3 mt-2'>
            <h5 className='mb-0 card-title flex-grow-1'>{'Danh sách báo cáo theo khu vực'}</h5>
          </div>
          <div className='mb-xxl-0'>
            {/* <h5>{programingCode}</h5> */}
            <button className='btn btn-info btn-rounded me-2'>{programingCode}</button>
          </div>
        </div>
      </Col>
    </React.Fragment>
  )
}
export default ReconcileProgramByAreaFilter
