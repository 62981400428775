import React, { useEffect, useMemo, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

//import components

import {
  getBankList,
  setTypePopupBank,
  getBankDetail,
  getBankDetailSuccess,
  getContactList,
  setTypePopupContact,
  getContactDetail,
  getContactDetailSuccess
} from 'store/actions'

// import { Name, Abbreviation } from '././ManageBankCol'

//redux
import { useSelector, useDispatch } from 'react-redux'
import { createSelector } from 'reselect'

import { Col, Row, UncontrolledTooltip, Card, CardBody } from 'reactstrap'
import Spinners from 'components/Common/Spinner'
import { toast } from 'react-toastify'
import TableContainer from 'components/Common/TableContainer'
import ConfirmModal from 'components/Common/ConfirmModal'
import {
  checkUploadFileAPI,
  deleteBankAPI,
  deleteContactAPI,
  exportFileError,
  getImportHistoryListAPI
} from 'helpers/backend_helper'
import AddEditContact from './components/addEditContactModal'
import PermissionWrapper from 'components/Common/PermissionWrapper'
import { Space } from 'antd'
import { BankVN } from 'constants/vn'
import { AREA_MANAGEMENT_CODE, SCREEN_CODE } from 'constants/permission-code'
import { TableRowSpan } from 'components/Common/TableCustom'
import ManageImportHistoryFilter from './components/ManageImportHistoryFilter'
import { formateDateTime } from 'helpers/global_helper'
import { Ratio } from 'pages/ManageAccumulation/ManageAccumulationCol'
import fileDownload from 'js-file-download'
import moment from 'moment'

function ManageImportHistory() {
  //meta title
  document.title = 'Manage Contacts CMS List'
  const screenCode = useMemo(() => SCREEN_CODE.AREA_MANAGEMENT, [])

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const selectContactsState = (state) => state.ContactsReducer
  const ContactsProperties = createSelector(selectContactsState, (contactReducer) => ({
    contacts: contactReducer.contacts,
    loading: contactReducer.loading
    // total: contactReducer.total
  }))

  const { contacts, loading } = useSelector(ContactsProperties)

  const [isLoading, setLoading] = useState(loading)
  const [onDelete, setOnDelete] = useState(false)
  const [selected, setSelected] = useState()
  const [filter, setFilter] = useState()
  const [openModal, setOpenModal] = useState(false)
  const [page, setPage] = useState(1)

  const [listTable, setListTable] = useState([])
  const [total, setTotal] = useState()
  const [type, setType] = useState('product')

  const [pagination, setPagination] = useState({
    pageIndex: page,
    pageSize: 10,
    totalRows: total
  })

  useEffect(() => {
    handleGetListTable()
  }, [filter])

  const handleGetListTable = async () => {
    try {
      const res = await getImportHistoryListAPI({
        search: filter?.search ? filter?.search : null,
        type: filter?.type ? filter?.type : type,
        sort_field: 'created_at',
        page
      })

      if (res.data) {
        setListTable(res.data)
      }

      if (res.meta) {
        setTotal(res.meta.totalItems)
      }
    } catch (error) {
      toast(error?.response?.data?.message, { type: 'error' })
    }
  }

  const handleDownloadFileError = async (id, type) => {
    try {
      const res = await exportFileError({ id: id, type: type })

      if (res) {
        const nameFile = `${moment().format('yyMMDD')}_FileError.xlsx`
        const url = window.URL.createObjectURL(new Blob([res]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', nameFile) //or any other extension
        document.body.appendChild(link)
        link.click()
      }
    } catch (error) {
      toast(error.response.data.message, { type: 'error' })
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: 'STT',
        accessor: 'stt',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return (page - 1) * 10 + cellProps.row.index + 1
        }
      },
      {
        Header: 'Tên thư mục',
        accessor: 'fileName',
        filterable: true,
        disableSortBy: true,
        hideSort: true
      },
      {
        Header: 'Tạo bởi',
        accessor: 'createdByUser.name',
        filterable: true,
        hideSort: true
      },
      {
        Header: 'Ngày tạo',
        accessor: 'createdAt',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return cellProps.value ? formateDateTime(cellProps.value) : '--'
        }
      },
      {
        Header: 'Tỷ lệ',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <Ratio {...cellProps} />
        }
      },
      {
        Header: 'File lỗi',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          if (
            cellProps?.row?.original?.totalFailed > 0 &&
            cellProps?.row?.original?.totalProcessing == 0 &&
            cellProps?.row?.original?.totalPending == 0
          ) {
            return (
              <>
                <button
                  className='btn btn-danger'
                  onClick={() => handleDownloadFileError(cellProps?.row?.original?.id, cellProps.row.original.models)}
                >
                  <i className='mdi mdi-download ' />
                  Download file lỗi
                </button>
              </>
            )
          } else {
            return <></>
          }
        }
      },
      {
        Header: BankVN.table.action,
        accessor: 'action',
        disableFilters: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return (
            <ul className='list-unstyled hstack gap-1 mb-0'>
              <PermissionWrapper screenCode={screenCode} code={AREA_MANAGEMENT_CODE.ADMIN_DETAIL_AREA}>
                <li data-bs-toggle='tooltip' data-bs-placement='top' title='View'>
                  <Link
                    to={`/manage-import-history/id=${cellProps.row.original.id}&type=${cellProps.row.original.models}`}
                    className='btn btn-sm btn-soft-primary'
                    id={`viewtooltip-${cellProps.row.original.id}`}
                  >
                    <i className='mdi mdi-eye-outline' />
                    <UncontrolledTooltip
                      trigger='hover'
                      placement='top'
                      target={`viewtooltip-${cellProps.row.original.id}`}
                    >
                      Xem
                    </UncontrolledTooltip>
                  </Link>
                </li>
              </PermissionWrapper>
            </ul>
          )
        }
      }
    ],
    []
  )

  const setGlobalFilterUser = async (data) => {
    setFilter(data)
    dispatch(getContactList(data))
    setPage(1)
  }

  const onClickDelete = async () => {
    try {
      const res = await deleteContactAPI({ id: selected.id, type: selected.type })
      if (res) {
        toast('Xoá thông tin liên hệ thành công', { type: 'success' })
        dispatch(getContactList(filter))
        setOnDelete(false)
      }
    } catch (error) {
      toast.error(error.response.data.message, { type: 'error' })
    }
  }

  const goToPage = (page) => {
    setPage(page)
    handleGetListTable({ ...filter, page })
    setFilter({ ...filter, page })
  }

  const handleResetFilter = () => {
    dispatch(getContactList({ page: 1 }))
    setFilter({ page: 1 })
  }

  return (
    <React.Fragment>
      <div className='page-content'>
        <div className='page-title-box container-fluid'>
          <h4 className=' mb-sm-0 font-size-18 pb-4'>{'Lịch sử'}</h4>
          {isLoading ? (
            <Spinners setLoading={setLoading} />
          ) : (
            <Row>
              <Col lg='12'>
                <Card>
                  <CardBody>
                    <Space size='middle' direction='vertical' className='w-100'>
                      <ManageImportHistoryFilter
                        handleFilter={setGlobalFilterUser}
                        handleResetFilter={handleResetFilter}
                      />
                      <TableRowSpan
                        columns={columns}
                        data={listTable}
                        pagination={pagination}
                        setPagination={setPagination}
                        gotoPageCustom={goToPage}
                        currentPage={page}
                        total={total}
                        isShowTotal={true}
                      />
                    </Space>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </div>
      </div>
      {openModal && (
        <AddEditContact
          show={openModal}
          filter={filter}
          onCloseClick={() => {
            setOpenModal(false)
            dispatch(getContactDetailSuccess(null))
          }}
          setPage={setPage}
        />
      )}
      <ConfirmModal
        show={onDelete}
        icon={
          <div className='avatar-title rounded-circle bg-danger text-light bg-opacity-60 font-size-20 rounded-3'>
            <i className='mdi mdi-delete-outline'></i>
          </div>
        }
        onConfirmClick={onClickDelete}
        onCloseClick={() => setOnDelete(false)}
        title={`Xác nhận xóa thông tin liên hệ `}
        description={`Bạn có chắc chắn muốn xóa thông tin liên hệ ?`}
      />
    </React.Fragment>
  )
}

export default ManageImportHistory
