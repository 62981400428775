export const GET_MANAGE_PORTFOLIO = "GET_PORTFOLIOLIST"
export const GET_MANAGE_PORTFOLIO_FAIL = "GET_MANAGE_PORTFOLIO_FAIL"
export const GET_MANAGE_PORTFOLIO_SUCCESS = "GET_MANAGE_PORTFOLIO_SUCCESS"

export const GET_PORTFOLIO_DETAIL = "GET_PORTFOLIO_DETAIL"
export const GET_PORTFOLIO_DETAIL_SUCCESS = "GET_PORTFOLIO_DETAIL_SUCCESS"
export const GET_PORTFOLIO_DETAIL_FAIL = "GET_PORTFOLIO_DETAIL_FAIL"

export const UPDATE_MANAGE_PORTFOLIO = "UPDATE_MANAGE_PORTFOLIO"
export const UPDATE_MANAGE_PORTFOLIO_SUCCESS = "UPDATE_MANAGE_PORTFOLIO_SUCCESS"
export const UPDATE_MANAGE_PORTFOLIO_FAIL = "UPDATE_MANAGE_PORTFOLIO_FAIL"

export const SET_TYPE_POPUP = "SET_TYPE_POPUP"
