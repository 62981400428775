import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Alert, Badge, Card, CardBody, Col, Container, Input, Label, Row, UncontrolledTooltip } from 'reactstrap'

//redux
import { useDispatch } from 'react-redux'
import withRouter from 'components/Common/withRouter'

//Import Breadcrumb
import Breadcrumb from '../../components/Common/Breadcrumb'
import { ManageContentVN } from 'constants/vn'

// styles
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css'
import { getContentDetail, getProgramByAreaAndRewardType, getReconcileProgramByArea } from 'store/actions'
import { createSelector } from 'reselect'
import { useSelector } from 'react-redux'
import {
  putContentDetailAPI,
  postContentAPI,
  getProgramListAPI,
  getProgramDetailsAPI,
  getRankAllAPI,
  postImage,
  patchStatusContentAPI,
  confirmReconcileByProductAPI,
  confirmReconcileByPackageAPI,
  exportProgrammingByAreaAndRewardTypeAPI,
  checkExportFileAPI
} from 'helpers/backend_helper'
import { checkFileSizeValid, checkIsLocked, formateDate } from 'helpers/global_helper'
import { toast } from 'react-toastify'
import { MAX_LENGTH_NAME, typeAccountProgramOptions, TypeContentOptions, TypeProgramOptions } from 'constants/constants'

import { debounce, isEqual } from 'lodash'
import { Form, Image, Upload, Button, Spin, Tooltip, Space } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import { FormSelect } from 'components/Common/form-select/FormSelect'
import { FormInput } from 'components/Common/form-input/FormInput'

import { FormDateRange } from 'components/Common/form-date-range/FormDateRange'
import dayjs from 'dayjs'

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import ConfirmModal from 'components/Common/ConfirmModal'
import moment from 'moment'
import EditorCustom from 'components/Common/EditorCustom/EditorCustom'

import TableContainer from 'components/Common/TableContainer'
import Spinners from 'components/Common/Spinner'
import { Name, ReportCode, StringGrp, TotalCompleted, TotalUser } from './ReconcileProgramByAreaCol'
import { Link } from 'react-router-dom'
import {
  BonusModel,
  ConcileStatus,
  Date,
  Description,
  ListCheckBox,
  ListDate,
  ListModel,
  ListMoney,
  ListReasonFormInput,
  ListSerial,
  MinimumAmount,
  Model,
  Money,
  Phone,
  PhoneGrp,
  Serial,
  Status,
  TotalMoney,
  Type
} from './ProgramByAreaAndRewardTypeCol'
import { TableRowSpan } from 'components/Common/TableCustom'
import { ImageGalleryModal } from 'pages/RecordPayment/components'
import Checkbox from 'antd/es/checkbox/Checkbox'
import ProgramByAreaAndRewardTypeFilter from './components/ProgramByAreaAndRewardTypeFilter'
import ProgramByAreaAndRewardTypeDisplayFilter from './components/ProgramByAreaAndRewardTypeDisplayFilter'
import { BaseString } from 'pages/Reward-verify/components/ManageAccountCol'
import fileDownload from 'js-file-download'
import { GrString } from 'pages/ReportTemporaryReconciliation/components/ManageAccountClass/ManageAccountCol'

const PackageProgramByAreaAndRewardTypeDisplay = (props) => {
  //meta title
  document.title = 'Content Detail | HPG'
  const params = props.router.params
  const stringUrl = params.id
  // Initialize an empty object to store key-value stringUrl
  const paramsFromUrl = {}

  // Split the string by '&' to get individual key-value pairs
  const urlSplit = stringUrl.split('&')

  // Loop through each key-value pair
  urlSplit.forEach((pair) => {
    // Split each property by '=' to separate key and value
    const [key, value] = pair.split('=')
    // Store the key-value pair in the params object
    paramsFromUrl[key] = value
  })

  const provinceId = paramsFromUrl['province_id']
  const id = paramsFromUrl['id']

  const [form] = Form.useForm()
  const watchForm = Form.useWatch([], form)
  const dispatch = useDispatch()
  // const [flatData, setFlatData] = useState()
  const [checked, setChecked] = useState(false)

  const programByAreaAndRewardTypeState = (state) => state.ProgramBonusTempReducer
  const ContentDetailProperties = createSelector(programByAreaAndRewardTypeState, (contentReducer) => ({
    contents: contentReducer.programByAreaAndRewardTypes,
    loading: contentReducer.loading,
    total: contentReducer.total,
    value: contentReducer.value
  }))
  const { contents, loading, value, total } = useSelector(ContentDetailProperties)
  const selectProfileState = (state) => state.Profile
  const ProfileProperties = createSelector(selectProfileState, (profile) => ({
    profile: profile.profile
  }))
  const { profile } = useSelector(ProfileProperties)
  const selectLayoutState = (state) => state.Layout
  const LayoutProperties = createSelector(selectLayoutState, (app) => ({
    province: app.province,
    district: app.district,
    ward: app.ward,
    zone: app.zone,
    subzone: app.subzone
  }))

  const { province, district, ward, zone, subzone } = useSelector(LayoutProperties)
  const [showReject, setShowReject] = useState(false)
  const [submittable, setSubmittable] = useState(false)
  const [initData, setInitData] = useState()
  const [showApprove, setShowApprove] = useState(false)
  const [editorState, setEditorState] = useState('')
  const [imageUrl, setImageUrl] = useState()
  const [programDetail, setProgramDetail] = useState({})
  const [onCancel, setOnCancel] = useState(false)
  const [showPause, setShowPause] = useState(false)
  const [showPlay, setShowPlay] = useState(false)
  //   const [loading, setLoading] = useState(true)
  const [programOptions, setProgramOptions] = useState([])
  const [rank, setRank] = useState([])
  const [filter, setFilter] = useState()
  const [page, setPage] = useState(1)
  const [isLoading, setLoading] = useState(loading)
  const [queryParams, setQueryParams] = useState({
    search: '',
    sortBy: []
  })
  const [valueFilter, setValueFilter] = useState()
  const [tasks, setTasks] = useState([])
  const [constanceFlatData, setconstanceFlatData] = useState([])
  const [flatData, setFlatData] = useState([])
  const [pagination, setPagination] = useState({
    pageIndex: page,
    pageSize: 10,
    totalRows: total
  })

  useEffect(() => {
    // setTasks(tasks)
    console.log('tasksUseEffect', tasks)
  }, [tasks])

  useEffect(() => {
    // Flatten the products and push them to an array
    const flatDataPrograms = []
    const flatDataModels = []

    //flat data theo products
    let stt = 0
    let rownum = 0
    contents.forEach((item) => {
      stt += 1
      item.packages.forEach((pkg) => {
        pkg.programmingPackageConfig.forEach((config) => {
          config.products.forEach((product) => {
            const flattenedItemProduct = {
              stt: stt,
              id: item.id, //client id
              name: item?.shopName ? item.shopName : item.name, // tên tài khoản
              phone: item.phone, //số điện thoại
              client: item.client, //loại tài khoản
              packageId: pkg.id,
              packageCreatedAt: pkg.createdAt,
              packageUpdatedAt: pkg.updatedAt,
              packageProgrammingId: pkg.programmingId,
              fromValue: config.fromValue, // số lượng tối thiểu
              packageToValue: pkg.toValue,
              packageMoney: pkg.money,
              packageCommission: pkg.commission,
              packageConvertValue: pkg.convertValue,
              packageArtifactName: pkg.artifactName,
              packageNumberOfArtifact: pkg.numberOfArtifact,
              packageIndex: pkg.packageIndex, // số gói
              packageMaxNumberOfReward: pkg.maxNumberOfReward,
              total: pkg.total, // thưởng
              numberOfTimesClaimable: pkg.numberOfTimesClaimable,
              productId: product.id,
              productType: product.type,
              productModelId: product.modelId,
              productName: product.name,
              productSerial: product.serial,
              createdAt: product.createdAt, // ngaày quét
              product_modelId: product.model.id,
              product_modelName: product.model.name,
              product_modelCode: product.model.code,
              productUserId: product.userId,
              productFirstProductId: product.firstProductId,
              groupIndex: config.groupIndex,
              uuid: product.uuid,
              model_modelName: '',
              checked: checked,
              description: product.description,
              status: product.status,
              industrySectorName: product?.industrySector?.name,
              industrySectorId: product?.industrySector?.id,
              industrySectorCode: product?.industrySector?.code,
              industrySectorParentId: product?.industrySector?.parentId,
              categoryName: product?.category?.name,
              categoryId: product?.category?.id,
              categoryCode: product?.category?.code,
              categoryParentId: product?.category?.parentId
            }
            flatDataPrograms.push(flattenedItemProduct)
          })
        })
      })
    })

    //flat data theo models
    contents.forEach((item) => {
      item.packages.forEach((pkg) => {
        pkg.programmingPackageConfig.forEach((config) => {
          config.models.forEach((model) => {
            // Flatten model data
            const flattenedItemModel = {
              modelId: model.id,
              modelCreatedAt: model.createdAt,
              modelUpdatedAt: model.updatedAt,
              modelProgrammingId: model.programmingId,
              modelProgrammingProductConfigId: model.programmingProductConfigId,
              modelModelId: model.modelId,
              modelFromValue: model.fromValue,
              modelGroupIndex: model.groupIndex,
              model_modelId: model.model.id,
              model_modelName: model.model.name,
              model_modelCode: model.model.code,
              groupIndex: config.groupIndex,
              packageId: pkg.id,
              id: item.id //client id
            }
            flatDataModels.push(flattenedItemModel)
          })
        })
      })
    })

    const groupedModels = flatDataModels.reduce((acc, item) => {
      const existingGroup = acc.find(
        (group) => group.groupIndex === item.groupIndex && group.packageId === item.packageId && group.id === item.id
      )
      if (existingGroup) {
        existingGroup.modelNames.push(item.model_modelName)
      } else {
        acc.push({
          groupIndex: item.groupIndex,
          packageId: item.packageId,
          id: item.id,
          modelNames: [item.model_modelName]
        })
      }
      return acc
    }, [])

    // Converting groupedModels object into an array
    const groupedModelsArray = Object.values(groupedModels)

    //gán group vào flat data theo products
    flatDataPrograms.forEach((program) => {
      groupedModelsArray.forEach((model) => {
        if (
          program.groupIndex === model.groupIndex &&
          program.packageId === model.packageId &&
          program.id === model.id
        ) {
          program.model_modelName = model.modelNames
        }
      })
    })

    console.log(flatDataPrograms)

    setFlatData(flatDataPrograms)
    setconstanceFlatData(flatDataPrograms)
  }, [contents])

  const curProvince = province?.find((item) => item?.id == provinceId)

  const columns = useMemo(
    () => [
      {
        Header: 'STT',
        accessor: 'stt',
        filterable: true,
        disableSortBy: true,
        enableRowSpan: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <BaseString {...cellProps} />
        }
      },
      {
        Header: 'Loại TK',
        accessor: (cell) => {
          return `${cell.id}-${cell.client}`
        },
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        enableRowSpan: true,

        Cell: (cellProps) => {
          return <Type {...cellProps} />
        }
      },
      {
        Header: 'Tên cửa hàng/KTV',
        accessor: 'name',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        enableRowSpan: true,

        Cell: (cellProps) => {
          return <Name {...cellProps} />
        }
      },
      {
        Header: 'SĐT',
        // accessor: 'phone',
        accessor: (cell) => {
          return `${cell.id}-${cell.phone}`
        },
        enableRowSpan: true,
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <PhoneGrp {...cellProps} />
        }
      },
      {
        Header: 'Gói',
        accessor: (cell) => {
          return `${cell.id}-Gói ${cell.packageIndex}`
        },
        enableRowSpan: true,
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <StringGrp {...cellProps} />
        }
      },
      {
        Header: 'Số gói',
        accessor: (cell) => {
          return `${cell.id}-${cell.numberOfTimesClaimable}`
        },
        enableRowSpan: true,
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <PhoneGrp {...cellProps} />
        }
      },
      {
        Header: 'Thưởng',
        enableRowSpan: true,
        accessor: 'total',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <Money {...cellProps} />
        }
      },
      {
        Header: 'Model tính thưởng',
        accessor: (cell) => {
          return `${cell.id}-${cell.model_modelName}`
        },
        enableRowSpan: true,
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <BonusModel {...cellProps} />
        }
      },
      {
        Header: 'Nhóm sản phẩm',
        // accessor: 'modelName',
        accessor: (cell) => {
          return `${cell.id}-${cell.categoryName}`
        },
        // enableRowSpan: true,
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <GrString {...cellProps} />
        }
      },
      {
        Header: 'Ngành hàng',
        accessor: (cell) => {
          return `${cell.id}-${cell.industrySectorName}`
        },
        // enableRowSpan: true,
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <GrString {...cellProps} />
        }
      },
      {
        Header: 'Số lượng tối thiểu',
        enableRowSpan: true,
        accessor: (cell) => {
          return `${cell.id}-${cell.fromValue}`
        },
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <MinimumAmount {...cellProps} />
        }
      },
      {
        Header: 'Serial',
        // accessor: 'serial',
        accessor: 'productSerial',
        filterable: true,
        disableSortBy: true,
        hideSort: true,

        Cell: (cellProps) => {
          return <Serial {...cellProps} />
        }
      },
      {
        Header: 'Model',
        accessor: (cell) => {
          return `${cell.id}-${cell.product_modelName}`
        },

        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <BonusModel {...cellProps} />
        }
      },
      {
        Header: 'Ngày quét',
        accessor: 'createdAt',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <Date {...cellProps} />
        }
      },
      {
        Header: 'Trạng thái',
        accessor: 'status',
        disableFilters: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <ConcileStatus {...cellProps} />
        }
      },
      {
        Header: 'Lý do từ chối',
        accessor: 'description',
        disableFilters: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <Description {...cellProps} />
        }
      }
    ],
    [filter, page, contents, flatData]
  )

  useEffect(() => {
    if (id) {
      dispatch(getProgramByAreaAndRewardType({ location_id: provinceId, id: id, viewonly: 'true' }))
    }
  }, [dispatch])

  const setGlobalFilterUser = async (data) => {
    setFilter({ ...data, sort_field: '', sort_order: '' })
    dispatch(
      getProgramByAreaAndRewardType({
        ...data,
        location_id: provinceId,
        id: id,
        sort_field: '',
        sort_order: ''
      })
    )
    setPage(1)
  }

  const handleFilter = (filterValue) => {
    if (filterValue) {
      // biến tìm kiếm để gán vào flatData
      var filtered
      // Filter by status value
      const keyWord = filterValue?.search?.toLowerCase() || ''
      const phone = filterValue?.phone?.toLowerCase() || ''
      const serial = filterValue?.serial?.toLowerCase() || ''

      if (filterValue?.status > -1) {
        if (filterValue?.status === 0) {
          filtered = constanceFlatData?.filter(
            (obj) =>
              obj?.status === 0 &&
              obj?.name?.toLowerCase().includes(keyWord) &&
              obj?.phone?.toLowerCase().includes(phone) &&
              obj?.productSerial?.toLowerCase().includes(serial)
          )
        } else {
          filtered = constanceFlatData?.filter(
            (obj) =>
              obj?.status == filterValue?.status &&
              obj?.name?.toLowerCase().includes(keyWord) &&
              obj?.phone?.toLowerCase().includes(phone) &&
              obj?.productSerial?.toLowerCase().includes(serial)
          )
        }
      } else {
        filtered = constanceFlatData?.filter(
          (obj) =>
            obj?.name?.toLowerCase().includes(keyWord) &&
            obj?.phone?.toLowerCase().includes(phone) &&
            obj?.productSerial?.toLowerCase().includes(serial)
        )
      }

      setFlatData(filtered)
    } else {
      dispatch(
        getProgramByAreaAndRewardType({
          ...filter,
          location_id: provinceId,
          id: id,
          viewonly: 'true',
          sort_field: '',
          sort_order: ''
        })
      )
    }
    setPage(1)
  }

  const handleSort = (sortBy) => {
    if (sortBy.length > 0) {
      setFilter({ ...filter, sort_field: sortBy[0].id, sort_order: sortBy[0].desc ? 'desc' : 'asc' })
      dispatch(
        getProgramByAreaAndRewardType({
          ...filter,
          location_id: provinceId,
          id: id,
          viewonly: 'true',
          sort_field: sortBy[0].id,
          sort_order: sortBy[0].desc ? 'desc' : 'asc'
        })
      )
    } else {
      setFilter({ ...filter, sort_field: '', sort_order: '' })
      dispatch(
        getProgramByAreaAndRewardType({
          ...filter,
          location_id: provinceId,
          id: id,
          viewonly: 'true',
          sort_field: '',
          sort_order: ''
        })
      )
    }
  }

  const goToPage = (page) => {
    setFilter({ ...filter, page: page })
    setPage(page)
    dispatch(
      getProgramByAreaAndRewardType({
        ...filter,
        location_id: provinceId,
        id: id,
        viewonly: 'true',
        sort_field: '',
        sort_order: '',
        page: page
      })
    )
  }

  const handleClickExport = async (filterValue) => {
    setLoading(true)
    try {
      const res = await exportProgrammingByAreaAndRewardTypeAPI({
        search: filterValue,
        location_id: provinceId,
        id: id,
        viewonly: 'true'
      })
      if (res?.data?.success === false) {
        toast(res?.message, { type: 'error' })
      } else {
        toast('Hệ thống đang xử lý. Tệp của bạn đã được gửi tới email ' + profile?.email + ' trong vài phút', {
          type: 'success'
        })
      }
    } catch (error) {
      toast(error.response.data.message, { type: 'error' })
    }
    setLoading(false)
  }

  // const handleCheckExport = async (data) => {
  //   try {
  //     const res = await checkExportFileAPI(data)

  //     if (res?.data?.status === 'pending') {
  //       setTimeout(() => {
  //         handleCheckExport(data)
  //       }, 2000)
  //     } else {
  //       const nameFile = `${moment().format('yyMMDD')}_Doi soat theo san pham.xlsx`
  //       const link = document.createElement('a')
  //       link.href = res?.data?.fileName
  //       link.setAttribute('download', nameFile)
  //       document.body.appendChild(link)
  //       link.click()
  //       toast('Export file thành công!', { type: 'success' })
  //     }
  //   } catch (error) {
  //     toast(error.response.data.message, { type: 'error' })
  //     setLoadingImport(false)
  //   }
  // }

  const handleResetFilter = () => {
    dispatch(
      getProgramByAreaAndRewardType({
        ...filter,
        location_id: provinceId,
        id: id,
        viewonly: 'true',
        sort_field: '',
        sort_order: ''
      })
    )
    setPage(1)
  }

  return (
    <>
      <div className='page-content'>
        <div className='page-title-box container-fluid'>
          <Breadcrumb title={'Đối soát thưởng'} breadcrumbItem={'Đối soát thưởng'} />
          {isLoading ? (
            <Spinners setLoading={setLoading} />
          ) : (
            <Row>
              <Col lg='12'>
                <Card>
                  <CardBody>
                    <Space size='middle' direction='vertical' className='w-100'>
                      <ProgramByAreaAndRewardTypeDisplayFilter
                        handleFilter={handleFilter}
                        handleExport={handleClickExport}
                        handleResetFilter={handleResetFilter}
                      />
                      <div className='d-flex flex-row '>
                        <div className='mb-xxl-0 me-3 mt-2'>
                          <h5 className='mb-0 card-title flex-grow-1'>{'Danh sách báo cáo'}</h5>
                        </div>
                        <div className='mb-xxl-0'>
                          {/* <h5>{programingCode}</h5> */}
                          <button className='btn btn-info btn-rounded me-2'>{value?.code}</button>
                        </div>
                        {/* <div className='mb-xxl-0'>
                          <button className='btn btn-info btn-rounded me-2'>{curProvince?.name}</button>
                        </div> */}
                      </div>
                      <TableRowSpan
                        columns={columns}
                        data={flatData}
                        pagination={pagination}
                        setPagination={setPagination}
                        gotoPageCustom={goToPage}
                        currentPage={page}
                        total={total}
                        isShowTotal={true}
                      />
                    </Space>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </div>
      </div>
      {/* {showModal && <ImageGalleryModal photos={photos} show={showModal} onClose={closeModal} />} */}
    </>
  )
}

export default withRouter(PackageProgramByAreaAndRewardTypeDisplay)
