import {
    GET_MANAGE_PROGRAM_FAIL,
    GET_MANAGE_PROGRAM_SUCCESS,
    GET_PROGRAM_DETAIL_SUCCESS,
    GET_PROGRAM_DETAIL_FAIL,
    UPDATE_MANAGE_PROGRAM_SUCCESS,
    UPDATE_MANAGE_PROGRAM_FAIL,
} from "./actionTypes"

const INIT_STATE = {
    programs: [],
    programDetail: undefined,
    error: {},
    manageProgramApply: [],
    loading: true,
    total: 0,
}

const ProgramReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_MANAGE_PROGRAM_SUCCESS:
            return {
                ...state,
                programs: action.payload.data,
                total: action.payload.meta.totalItems,
                loading: true,
            }

        case GET_MANAGE_PROGRAM_FAIL:
            return {
                ...state,
                error: action.payload,
            }

        case GET_PROGRAM_DETAIL_SUCCESS:
            return {
                ...state,
                programDetail: action.payload,
            }

        case GET_PROGRAM_DETAIL_FAIL:
            return {
                ...state,
                error: action.payload,
            }

        case UPDATE_MANAGE_PROGRAM_SUCCESS:
            return {
                ...state,
                programs: state.programs.map(manageProgram =>
                    manageProgram.id.toString() === action.payload.id.toString() ? { manageProgram, ...action.payload } : manageProgram
                ),
            }

        case UPDATE_MANAGE_PROGRAM_FAIL:
            return {
                ...state,
                error: action.payload,
            }

        default:
            return state
    }
}

export default ProgramReducer
