export const GET_MANAGE_PERMISSION = "GET_PERMISSION_LIST"
export const GET_MANAGE_PERMISSION_FAIL = "GET_MANAGE_PERMISSION_FAIL"
export const GET_MANAGE_PERMISSION_SUCCESS = "GET_MANAGE_PERMISSION_SUCCESS"

export const GET_PERMISSION_DETAIL = "GET_PERMISSION_DETAIL"
export const GET_PERMISSION_DETAIL_SUCCESS = "GET_PERMISSION_DETAIL_SUCCESS"
export const GET_PERMISSION_DETAIL_FAIL = "GET_PERMISSION_DETAIL_FAIL"

export const UPDATE_MANAGE_PERMISSION = "UPDATE_MANAGE_PERMISSION"
export const UPDATE_MANAGE_PERMISSION_SUCCESS = "UPDATE_MANAGE_PERMISSION_SUCCESS"
export const UPDATE_MANAGE_PERMISSION_FAIL = "UPDATE_MANAGE_PERMISSION_FAIL"

export const SET_TYPE_POPUP = "SET_TYPE_POPUP_PERMISSION"

export const GET_ROLE_ALL = "GET_ROLE_ALL"
export const GET_ROLE_ALL_FAIL = "GET_ROLE_ALL_FAIL"
export const GET_ROLE_ALL_SUCCESS = "GET_ROLE_ALL_SUCCESS"
export const GET_ROLE_ALL_LIST = "GET_ROLE_ALL_LIST"
