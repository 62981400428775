import {
    GET_MANAGE_PORTFOLIO_FAIL,
    GET_MANAGE_PORTFOLIO_SUCCESS,
    GET_PORTFOLIO_DETAIL_FAIL,
    UPDATE_MANAGE_PORTFOLIO_SUCCESS,
    UPDATE_MANAGE_PORTFOLIO_FAIL,
    SET_TYPE_POPUP,
    GET_PORTFOLIO_DETAIL_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
    portfolios: [],
    portfolioDetail: undefined,
    type: "create",
    total: 0,
    loading: true,
}

const PortfolioReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_MANAGE_PORTFOLIO_SUCCESS:
            return {
                ...state,
                portfolios: action.payload.data,
                total: action.payload.meta.totalItems,
                loading: true,
            }

        case GET_MANAGE_PORTFOLIO_FAIL:
            return {
                ...state,
                error: action.payload,
            }

        case SET_TYPE_POPUP:
            return {
                ...state,
                type: action.payload,
            }
        case GET_PORTFOLIO_DETAIL_SUCCESS:
            return {
                ...state,
                portfolioDetail: action.payload,
            }

        case GET_PORTFOLIO_DETAIL_FAIL:
            return {
                ...state,
                error: action.payload,
            }

        case UPDATE_MANAGE_PORTFOLIO_SUCCESS:
            return {
                ...state,
                shops: state.shops.map(managePortfolio =>
                    managePortfolio.id.toString() === action.payload.id.toString() ? { managePortfolio, ...action.payload } : managePortfolio
                ),
            }

        case UPDATE_MANAGE_PORTFOLIO_FAIL:
            return {
                ...state,
                error: action.payload,
            }

        default:
            return state
    }
}

export default PortfolioReducer
