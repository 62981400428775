import {
    GET_MANAGE_CONTENT_FAIL,
    GET_MANAGE_CONTENT_SUCCESS,
    GET_CONTENT_DETAIL_SUCCESS,
    GET_CONTENT_DETAIL_FAIL,
    UPDATE_MANAGE_CONTENT_SUCCESS,
    UPDATE_MANAGE_CONTENT_FAIL,
} from "./actionTypes"

const INIT_STATE = {
    contents: [],
    contentDetail: undefined,
    error: {},
    manageContentApply: [],
    loading: true,
    total: 0,
}

const ContentReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_MANAGE_CONTENT_SUCCESS:
            return {
                ...state,
                contents: action.payload.data,
                total: action.payload.meta.totalItems,
                loading: true,
            }

        case GET_MANAGE_CONTENT_FAIL:
            return {
                ...state,
                error: action.payload,
            }

        case GET_CONTENT_DETAIL_SUCCESS:
            return {
                ...state,
                contentDetail: action.payload,
            }

        case GET_CONTENT_DETAIL_FAIL:
            return {
                ...state,
                error: action.payload,
            }

        case UPDATE_MANAGE_CONTENT_SUCCESS:
            return {
                ...state,
                contents: state.contents.map(manageContent =>
                    manageContent.id.toString() === action.payload.id.toString() ? { manageContent, ...action.payload } : manageContent
                ),
            }

        case UPDATE_MANAGE_CONTENT_FAIL:
            return {
                ...state,
                error: action.payload,
            }

        default:
            return state
    }
}

export default ContentReducer
