import {
    GET_MANAGE_PRODUCT_ACTIVATION_FAIL,
    GET_MANAGE_PRODUCT_ACTIVATION_SUCCESS,
    GET_PRODUCT_ACTIVATION_DETAIL_FAIL,
    UPDATE_MANAGE_PRODUCT_ACTIVATION_SUCCESS,
    UPDATE_MANAGE_PRODUCT_ACTIVATION_FAIL,
    SET_TYPE_POPUP,
    GET_PRODUCT_ACTIVATION_DETAIL_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
    productActivations: [],
    productActivationDetail: undefined,
    type: "create",
    loading: true,
    total: 0,
}

const ProductActivationReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_MANAGE_PRODUCT_ACTIVATION_SUCCESS:
            return {
                ...state,
                productActivations: action.payload.data,
                total: action.payload.meta.totalItems,
                loading: true,
            }

        case GET_MANAGE_PRODUCT_ACTIVATION_FAIL:
            return {
                ...state,
                error: action.payload,
            }

        case SET_TYPE_POPUP:
            return {
                ...state,
                type: action.payload,
            }
        case GET_PRODUCT_ACTIVATION_DETAIL_SUCCESS:
            return {
                ...state,
                productActivationDetail: action.payload,
            }

        case GET_PRODUCT_ACTIVATION_DETAIL_FAIL:
            return {
                ...state,
                error: action.payload,
            }

        case UPDATE_MANAGE_PRODUCT_ACTIVATION_SUCCESS:
            return {
                ...state,
                shops: state.shops.map(manageProductActivation =>
                    manageProductActivation.id.toString() === action.payload.id.toString()
                        ? { manageProductActivation, ...action.payload }
                        : manageProductActivation
                ),
            }

        case UPDATE_MANAGE_PRODUCT_ACTIVATION_FAIL:
            return {
                ...state,
                error: action.payload,
            }

        default:
            return state
    }
}

export default ProductActivationReducer
