import React, { useState } from 'react'
import { Col, Label } from 'reactstrap'

//flatpickr
import 'flatpickr/dist/themes/material_blue.css'
import { CommonVN, ManageProgramVN } from 'constants/vn'
import { FormSelect } from './form-select/FormSelect'
import { DatePicker } from 'antd'
import { StatusProgramOptions, TypeProgramOptions } from 'constants/constants'
import { FormDateRange } from './form-date-range/FormDateRange'

const { RangePicker } = DatePicker

const ProgramFilter = ({ setGlobalFilter, search, setSearch }) => {
  const [filter, setFilter] = useState({})

  const handleSelect = (value, key) => {
    setFilter((e) => ({ ...e, [key]: value }))
  }

  return (
    <React.Fragment>
      <Col xxl={2} className='mt-3' lg={2}>
        <Label className='form-label'>{ManageProgramVN.filter.type.title}</Label>
        <FormSelect
          options={TypeProgramOptions}
          value={filter.type}
          onChange={(value) => {
            handleSelect(value, 'type')
          }}
          placeholder={ManageProgramVN.filter.type.placeholder}
        />
      </Col>
      <Col xxl={3} className='mt-3' lg={3}>
        <Label className='form-label'>{ManageProgramVN.filter.applyDate.title}</Label>
        <FormDateRange
          className='w-100'
          size='large'
          format={CommonVN.datePlaceholder.toUpperCase()}
          placeholder={[CommonVN.datePlaceholder, CommonVN.datePlaceholder]}
          value={[filter.start_date, filter.end_date]}
          onChange={(value) => {
            if (value) {
              handleSelect(value[0], 'start_date')
              handleSelect(value[1], 'end_date')
            } else {
              handleSelect(undefined, 'start_date')
              handleSelect(undefined, 'end_date')
            }
          }}
        />
      </Col>
      <Col xxl={2} className='mt-3' lg={2}>
        <Label className='form-label'>{ManageProgramVN.filter.status.title}</Label>
        <FormSelect
          options={StatusProgramOptions}
          value={filter.status}
          onChange={(value) => {
            handleSelect(value, 'status')
          }}
          placeholder={ManageProgramVN.filter.status.placeholder}
        />
      </Col>
      <Col xxl={3} lg={5} className='d-flex align-items-end justify-content-end mt-3 '>
        <div className='mb-3 mb-xxl-0 me-2'>
          <button
            type='button'
            className='btn btn-soft-danger w-100'
            onClick={() => {
              setFilter({
                type: undefined,
                status: undefined,
                start_date: undefined,
                end_date: undefined
              })
              setSearch(undefined)
              setGlobalFilter({})
            }}
          >
            Xóa tìm kiếm
          </button>
        </div>
        <div className='mb-3 mb-xxl-0'>
          <button
            type='button'
            className='btn btn-primary w-100'
            onClick={() => {
              const _filter = !filter
                ? filter
                : Object.fromEntries(Object.entries(filter).filter(([key, value]) => value !== undefined))

              setGlobalFilter({
                ..._filter,
                search,
                start_date: _filter?.start_date?.format('YYYY-MM-DD'),
                end_date: _filter?.end_date?.format('YYYY-MM-DD')
              })
            }}
          >
            Tìm kiếm
          </button>
        </div>
      </Col>
    </React.Fragment>
  )
}
export default ProgramFilter
