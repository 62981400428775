export const GET_PROGRAM_BONUS_TEMP = 'GET_PROGRAM_BONUS_TEMPLIST'
export const GET_PROGRAM_BONUS_TEMP_FAIL = 'GET_PROGRAM_BONUS_TEMP_FAIL'
export const GET_PROGRAM_BONUS_TEMP_SUCCESS = 'GET_PROGRAM_BONUS_TEMP_SUCCESS'

export const GET_RECONCILE_PROGRAM_BY_AREA = 'GET_RECONCILE_PROGRAM_BY_AREA'
export const GET_RECONCILE_PROGRAM_BY_AREA_SUCCESS = 'GET_RECONCILE_PROGRAM_BY_AREA_SUCCESS'
export const GET_RECONCILE_PROGRAM_BY_AREA_FAIL = 'GET_RECONCILE_PROGRAM_BY_AREA_FAIL'

export const GET_PROGRAM_BY_AREA_AND_REWARD_TYPE = 'GET_PROGRAM_BY_AREA_AND_REWARD_TYPE'
export const GET_PROGRAM_BY_AREA_AND_REWARD_TYPE_SUCCESS = 'GET_PROGRAM_BY_AREA_AND_REWARD_TYPE_SUCCESS'
export const GET_PROGRAM_BY_AREA_AND_REWARD_TYPE_FAIL = 'GET_PROGRAM_BY_AREA_AND_REWARD_TYPE_FAIL'

// export const UPDATE_MANAGE_ACTIVATION = 'UPDATE_MANAGE_ACTIVATION'
// export const UPDATE_MANAGE_ACTIVATION_SUCCESS = 'UPDATE_MANAGE_ACTIVATION_SUCCESS'
// export const UPDATE_MANAGE_ACTIVATION_FAIL = 'UPDATE_MANAGE_ACTIVATION_FAIL'

// export const SET_TYPE_POPUP = "SET_TYPE_POPUP_ACTIVATION"
