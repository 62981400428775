export const GET_MANAGE_PRODUCT = "GET_PRODUCTLIST"
export const GET_MANAGE_PRODUCT_FAIL = "GET_MANAGE_PRODUCT_FAIL"
export const GET_MANAGE_PRODUCT_SUCCESS = "GET_MANAGE_PRODUCT_SUCCESS"

export const GET_PRODUCT_DETAIL = "GET_PRODUCT_DETAIL"
export const GET_PRODUCT_DETAIL_SUCCESS = "GET_PRODUCT_DETAIL_SUCCESS"
export const GET_PRODUCT_DETAIL_FAIL = "GET_PRODUCT_DETAIL_FAIL"

export const UPDATE_MANAGE_PRODUCT = "UPDATE_MANAGE_PRODUCT"
export const UPDATE_MANAGE_PRODUCT_SUCCESS = "UPDATE_MANAGE_PRODUCT_SUCCESS"
export const UPDATE_MANAGE_PRODUCT_FAIL = "UPDATE_MANAGE_PRODUCT_FAIL"

export const SET_TYPE_POPUP = "SET_TYPE_POPUP_PRODUCT"
