import { takeEvery, fork, put, all, call } from 'redux-saga/effects'

// Login Redux States
import { EDIT_PROFILE } from './actionTypes'
import { profileSuccess, profileError } from './actions'

//Include Both Helper File with needed methods
import { getProfileAPI, putProfileAPI } from '../../../helpers/backend_helper'

import { GET_PROFILE } from './actionTypes'

function* editProfile({ payload: { user } }) {
  try {
    const response = yield call(putProfileAPI, user)
    yield put(profileSuccess(response.data))
  } catch (error) {
    yield put(profileError(error.response.data.message))
  }
}

function* getProfile() {
  try {
    const response = yield call(getProfileAPI)
    console.log('get profile', response)

    yield put(profileSuccess(response.data))
  } catch (error) {
    yield put(profileError(error.response.data.message))
  }
}
export function* watchProfile() {
  yield takeEvery(EDIT_PROFILE, editProfile)
  yield takeEvery(GET_PROFILE, getProfile)
}

function* ProfileSaga() {
  yield all([fork(watchProfile)])
}

export default ProfileSaga
