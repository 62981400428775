import {
  GET_MANAGE_NOTIFICATION,
  GET_MANAGE_NOTIFICATION_FAIL,
  GET_MANAGE_NOTIFICATION_SUCCESS,
  GET_NOTIFICATION_DETAIL,
  GET_NOTIFICATION_DETAIL_SUCCESS,
  GET_NOTIFICATION_DETAIL_FAIL,
  GET_USER_ACCOUNT
} from './actionTypes'

export const getManageNotification = (params) => ({
  type: GET_MANAGE_NOTIFICATION,
  payload: params
})

export const getUserAccountList = (params) => ({
  type: GET_USER_ACCOUNT,
  payload: params
})

export const getManageNotificationSuccess = (jobs) => ({
  type: GET_MANAGE_NOTIFICATION_SUCCESS,
  payload: jobs
})

export const getManageNotificationFail = (error) => ({
  type: GET_MANAGE_NOTIFICATION_FAIL,
  payload: error
})

export const getNotificationDetail = (data) => ({
  type: GET_NOTIFICATION_DETAIL,
  payload: data
})

export const getNotificationDetailSuccess = (job) => ({
  type: GET_NOTIFICATION_DETAIL_SUCCESS,
  payload: job
})

export const getNotificationDetailFail = (error) => ({
  type: GET_NOTIFICATION_DETAIL_FAIL,
  payload: error
})
