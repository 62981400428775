import {
    GET_MANAGE_LOCALITY,
    GET_MANAGE_LOCALITY_FAIL,
    GET_MANAGE_LOCALITY_SUCCESS,
    GET_LOCALITY_DETAIL,
    GET_LOCALITY_DETAIL_SUCCESS,
    GET_LOCALITY_DETAIL_FAIL,
    SET_TYPE_POPUP,
} from "./actionTypes"

export const getLocalityList = params => ({
    type: GET_MANAGE_LOCALITY,
    payload: params,
})

export const getManageLocalitySuccess = data => ({
    type: GET_MANAGE_LOCALITY_SUCCESS,
    payload: data,
})

export const getManageLocalityFail = error => ({
    type: GET_MANAGE_LOCALITY_FAIL,
    payload: error,
})

export const getLocalityDetail = data => ({
    type: GET_LOCALITY_DETAIL,
    payload: data,
})

export const getLocalityDetailSuccess = data => ({
    type: GET_LOCALITY_DETAIL_SUCCESS,
    payload: data,
})

export const getLocalityDetailFail = error => ({
    type: GET_LOCALITY_DETAIL_FAIL,
    payload: error,
})

export const setTypePopupLocality = params => ({
    type: SET_TYPE_POPUP,
    payload: params,
})
