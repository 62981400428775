import { call, put, takeEvery } from 'redux-saga/effects'

import {
  GET_MANAGE_CONTENT,
  GET_CONTENT_DETAIL,
  GET_REPORT_USER_STATISTIC,
  GET_NOTIFICATION_DETAIL,
  GET_REPORT_ACCOUNT_RANK
} from './actionTypes'
import {
  getReportUserStatisticSuccess,
  getReportUserStatisticFail,
  getReportAccountRankSuccess,
  getReportAccountRankFail
} from './actions'

import { getReportAccountRankAPI } from 'helpers/backend_helper'

function* fetchReportAccountRankList({ payload: params }) {
  try {
    const response = yield call(getReportAccountRankAPI, params)
    yield put(getReportAccountRankSuccess(response))
  } catch (error) {
    yield put(getReportAccountRankFail(error?.data?.message))
  }
}

function* ReportAccountRankSaga() {
  yield takeEvery(GET_REPORT_ACCOUNT_RANK, fetchReportAccountRankList)
}

export default ReportAccountRankSaga
