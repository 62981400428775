export const SCREEN_CODE = {
  // Quản lý người dùng
  USER_ACCOUNT_MANAGEMENT: 'USER_ACCOUNT_MANAGEMENT',

  // Tải khoản kĩ thuật viên
  TECHNICIAN_ACCOUNT_MANAGEMENT: 'TECHNICIAN_ACCOUNT_MANAGEMENT',

  // Quản lý sản phẩm
  PRODUCT: 'PRODUCT',
  // Quản lý mục sản phẩm
  CATEGORY_MANAGEMENT: 'CATEGORY_MANAGEMENT',
  // Quản lý sẩm phẩm
  PRODUCT_MANAGEMENT: 'PRODUCT_MANAGEMENT',
  // Quản lý mã bảo hành
  PRODUCT_ACTIVATION_MANAGEMENT: 'PRODUCT_ACTIVATION_MANAGEMENT',
  ADMINS: 'ADMINS',
  DEPARTMENT_MANAGEMENT: 'DEPARTMENT_MANAGEMENT',
  ROLE_MANAGEMENT: 'ROLE_MANAGEMENT',
  ADMIN_ACCOUNT_MANAGEMENT: 'ADMIN_ACCOUNT_MANAGEMENT',
  PROGRAMING_MANAGEMENT: 'PROGRAMING_MANAGEMENT',
  AREA_MANAGEMENT: 'AREA_MANAGEMENT',
  RANKS: 'RANKS',

  RANK_MANAGEMENT: 'RANK_MANAGEMENT',
  RANK_CONFIG_MANAGEMENT: 'RANK_CONFIG_MANAGEMENT',
  POINT_CONFIG_MANAGEMENT: 'POINT_CONFIG_MANAGEMENT',
  POINT_HISTORY_MANAGEMENT: 'POINT_HISTORY_MANAGEMENT',
  ACCUMULATED_MANAGEMENT: 'ACCUMULATED_MANAGEMENT',
  ACTIVATED_MANAGEMENT: 'ACTIVATED_MANAGEMENT',
  CUSTOMER_MANAGEMENT: 'CUSTOMER_MANAGEMENT',
  ANNOUNCE_MANAGEMENT: 'ANNOUNCE_MANAGEMENT',
  BANNER_MANAGEMENT: 'BANNER_MANAGEMENT'
}

export const USER_ACCOUNT_MANAGEMENT_CODE = {
  USERS_RESET_PASSWORD: 'USERS_RESET_PASSWORD',
  ADMIN_GET_USER_PROFILE: 'ADMIN_GET_USER_PROFILE',
  ADMIN_UPDATE_USER_PROFILE: 'ADMIN_UPDATE_USER_PROFILE',
  ADMIN_APPROVE_OR_REJECT_PROFILE: 'ADMIN_APPROVE_OR_REJECT_PROFILE',
  ADMIN_APPROVE_USER_MEMBER: 'ADMIN_APPROVE_USER_MEMBER',
  ADMIN_SEARCH_LIST_USER: 'ADMIN_SEARCH_LIST_USER',
  ADMIN_EXPORT_LIST_USER: 'ADMIN_EXPORT_LIST_USER',
  ADMIN_SEARCH_UPDATE_HISTORY: 'ADMIN_SEARCH_UPDATE_HISTORY'
}

export const TECHNICIAN_ACCOUNT_MANAGEMENT_CODE = {
  TECHNICIAN_RESET_PASSWORD: 'TECHNICIAN_RESET_PASSWORD',
  TECHNICIAN_SET_STATUS: 'TECHNICIAN_SET_STATUS',
  TECHNICIAN_GET_USER_PROFILE: 'TECHNICIAN_GET_USER_PROFILE',
  TECHNICIAN_UPDATE_USER_PROFILE: 'TECHNICIAN_UPDATE_USER_PROFILE',
  TECHNICIAN_APPROVE_OR_REJECT_PROFILE: 'TECHNICIAN_APPROVE_OR_REJECT_PROFILE',
  TECHNICIAN_SEARCH_LIST_USER: 'TECHNICIAN_SEARCH_LIST_USER',
  TECHNICIAN_EXPORT_LIST_USER: 'TECHNICIAN_EXPORT_LIST_USER'
}

export const CATEGORY_MANAGEMENT_CODE = {
  PRODUCT_IMPORT_CATEGORY: 'PRODUCT_IMPORT_CATEGORY',
  PRODUCT_SEARCH_CATEGORY: 'PRODUCT_SEARCH_CATEGORY',
  PRODUCT_CREATE_CATEGORY: 'PRODUCT_CREATE_CATEGORY',
  PRODUCT_DETAIL_CATEGORY: 'PRODUCT_DETAIL_CATEGORY',
  PRODUCT_UPDATE_CATEGORY: 'PRODUCT_UPDATE_CATEGORY',
  PRODUCT_CHANGE_STATUS_CATEGORY: 'PRODUCT_CHANGE_STATUS_CATEGORY',
  PRODUCT_DELETE_CATEGORY: 'PRODUCT_DELETE_CATEGORY',
  PRODUCT_EXPORT_CATEGORY: 'PRODUCT_EXPORT_CATEGORY'
}

export const PRODUCT_MANAGEMENT_CODE = {
  PRODUCT_IMPORT_PRODUCT: 'PRODUCT_IMPORT_PRODUCT',
  PRODUCT_CREATE_PRODUCT: 'PRODUCT_CREATE_PRODUCT',
  PRODUCT_DETAIL_PRODUCT: 'PRODUCT_DETAIL_PRODUCT',
  PRODUCT_SEARCH_PRODUCT: 'PRODUCT_SEARCH_PRODUCT',
  PRODUCT_UPDATE_PRODUCT: 'PRODUCT_UPDATE_PRODUCT',
  PRODUCT_DELETE_PRODUCT: 'PRODUCT_DELETE_PRODUCT',
  PRODUCT_EXPORT_PRODUCT: 'PRODUCT_EXPORT_PRODUCT'
}

export const PRODUCT_ACTIVATION_MANAGEMENT_CODE = {
  PRODUCT_IMPORT_PRODUCT_ACTIVATION: 'PRODUCT_IMPORT_PRODUCT_ACTIVATION',
  PRODUCT_CREATE_PRODUCT_ACTIVATION: 'PRODUCT_CREATE_PRODUCT_ACTIVATION',
  PRODUCT_SEARCH_PRODUCT_ACTIVATION: 'PRODUCT_SEARCH_PRODUCT_ACTIVATION',
  PRODUCT_EXPORT_PRODUCT_ACTIVATION: 'PRODUCT_EXPORT_PRODUCT_ACTIVATION',
  PRODUCT_DELETE_PRODUCT_ACTIVATION: 'PRODUCT_DELETE_PRODUCT_ACTIVATION',
  PRODUCT_DETAIL_PRODUCT_ACTIVATION: 'PRODUCT_DETAIL_PRODUCT_ACTIVATION',
  PRODUCT_CHANGE_STATUS_PRODUCT_ACTIVATION: 'PRODUCT_CHANGE_STATUS_PRODUCT_ACTIVATION',
  PRODUCT_UPDATE_PRODUCT_ACTIVATION: 'PRODUCT_UPDATE_PRODUCT_ACTIVATION'
}

export const DEPARTMENT_MANAGEMENT_CODE = {
  ADMIN_SEARCH_DEPARTMENT: 'ADMIN_SEARCH_DEPARTMENT',
  ADMIN_CREATE_DEPARTMENT: 'ADMIN_CREATE_DEPARTMENT',
  ADMIN_DETAIL_DEPARTMENT: 'ADMIN_DETAIL_DEPARTMENT',
  ADMIN_UPDATE_DEPARTMENT: 'ADMIN_UPDATE_DEPARTMENT',
  ADMIN_CHANGE_STATUS_DEPARTMENT: 'ADMIN_CHANGE_STATUS_DEPARTMENT',
  ADMIN_DELETE_DEPARTMENT: 'ADMIN_DELETE_DEPARTMENT'
}

export const ROLE_MANAGEMENT_CODE = {
  ADMIN_CREATE_ROLE: 'ADMIN_CREATE_ROLE',
  ADMIN_SEARCH_ROLE: 'ADMIN_SEARCH_ROLE',
  ADMIN_DETAIL_ROLE: 'ADMIN_DETAIL_ROLE',
  ADMIN_UPDATE_ROLE: 'ADMIN_UPDATE_ROLE',
  ADMIN_CHANGE_STATUS_ROLE: 'ADMIN_CHANGE_STATUS_ROLE',
  ADMIN_DELETE_ROLE: 'ADMIN_DELETE_ROLE'
}

export const ADMIN_ACCOUNT_MANAGEMENT_CODE = {
  ADMIN_RESET_PASSWORD: 'ADMIN_RESET_PASSWORD',
  ADMIN_CREATE_ADMIN_ACCOUNT: 'ADMIN_CREATE_ADMIN_ACCOUNT',
  ADMIN_DETAIL_ADMIN_ACCOUNT: 'ADMIN_DETAIL_ADMIN_ACCOUNT',
  ADMIN_UPDATE_ADMIN_ACCOUNT: 'ADMIN_UPDATE_ADMIN_ACCOUNT',
  ADMIN_CHANGE_STATUS_ADMIN_ACCOUNT: 'ADMIN_CHANGE_STATUS_ADMIN_ACCOUNT',
  ADMIN_DELETE_ADMIN_ACCOUNT: 'ADMIN_DELETE_ADMIN_ACCOUNT',
  ADMIN_SEARCH_LIST_ADMIN_ACCOUNT: 'ADMIN_SEARCH_LIST_ADMIN_ACCOUNT'
}

export const PROGRAMING_MANAGEMENT_CODE = {
  ADMIN_CREATE_PROGRAMMING: 'ADMIN_CREATE_PROGRAMMING',
  ADMIN_SEARCH_PROGRAMMING: 'ADMIN_SEARCH_PROGRAMMING',
  ADMIN_DETAIL_PROGRAMMING: 'ADMIN_DETAIL_PROGRAMMING',
  ADMIN_UPDATE_PROGRAMMING: 'ADMIN_UPDATE_PROGRAMMING',
  ADMIN_CHANGE_STATUS_PROGRAMMING: 'ADMIN_CHANGE_STATUS_PROGRAMMING'
}

export const AREA_MANAGEMENT_CODE = {
  ADMIN_CREATE_AREA: 'ADMIN_CREATE_AREA',
  ADMIN_DETAIL_AREA: 'ADMIN_DETAIL_AREA',
  ADMIN_UPDATE_AREA: 'ADMIN_UPDATE_AREA',
  ADMIN_GET_LIST_AREA: 'ADMIN_GET_LIST_AREA',
  ADMIN_CHANGE_STATUS_AREA: 'ADMIN_CHANGE_STATUS_AREA',
  ADMIN_DELETE_AREA: 'ADMIN_DELETE_AREA'
}
export const RANK_MANAGEMENT_CODE = {
  RANKS_SEARCH_RANK: 'RANKS_SEARCH_RANK',
  RANKS_CREATE_RANK: 'RANKS_CREATE_RANK',
  RANKS_DETAIL_RANK: 'RANKS_DETAIL_RANK',
  RANKS_UPDATE_RANK: 'RANKS_UPDATE_RANK',
  RANKS_DELETE_RANK: 'RANKS_DELETE_RANK'
}

export const RANK_CONFIG_MANAGEMENT_CODE = {
  RANKS_SEARCH_RANK_CONFIG: 'RANKS_SEARCH_RANK_CONFIG',
  RANKS_CREATE_RANK_CONFIG: 'RANKS_CREATE_RANK_CONFIG',
  RANKS_DETAIL_RANK_CONFIG: 'RANKS_DETAIL_RANK_CONFIG',
  RANKS_UPDATE_RANK_CONFIG: 'RANKS_UPDATE_RANK_CONFIG',
  RANKS_DELETE_RANK_CONFIG: 'RANKS_DELETE_RANK_CONFIG'
}

export const POINT_CONFIG_MANAGEMENT_CODE = {
  RANK_SEARCH_POINT_CONFIG: 'RANK_SEARCH_POINT_CONFIG',
  RANK_UPDATE_POINT_CONFIG: 'RANK_UPDATE_POINT_CONFIG'
}

export const RANK_HISTORY_MANAGEMENT_CODE = {
  RANK_HISTORY: 'RANK_HISTORY'
}

export const POINT_HISTORY_MANAGEMENT_CODE = {
  POINT_HISTORY_MANAGEMENT: 'POINT_HISTORY_MANAGEMENT'
}

export const ACCUMULATED_MANAGEMENT_CODE = {
  ADMIN_SEARCH_ACCUMULATED: 'ADMIN_SEARCH_ACCUMULATED',
  ADMIN_EXPORT_ACCUMULATED: 'ADMIN_EXPORT_ACCUMULATED',
  ADMIN_DELETE_USER_REWARD_ACCUMULATED: 'ADMIN_DELETE_USER_REWARD_ACCUMULATED'
}

export const CUSTOMER_MANAGEMENT_CODE = {
  ADMIN_SEARCH_CUSTOMER: 'ADMIN_SEARCH_CUSTOMER',
  ADMIN_EXPORT_CUSTOMER: 'ADMIN_EXPORT_CUSTOMER',
  ADMIN_DETAIL_CUSTOMER: 'ADMIN_DETAIL_CUSTOMER',
  ADMIN_DELETE_CUSTOMER: 'ADMIN_DELETE_CUSTOMER',
  ADMIN_UPDATE_CUSTOMER: 'ADMIN_UPDATE_CUSTOMER'
}

export const ANNOUNCE_MANAGEMENT_CODE = {
  ADMIN_CREATE_ANNOUNCE: 'ADMIN_CREATE_ANNOUNCE',
  ADMIN_UPDATE_ANNOUNCE: 'ADMIN_UPDATE_ANNOUNCE',
  ADMIN_SEARCH_ANNOUNCE: 'ADMIN_SEARCH_ANNOUNCE',
  ADMIN_CHANGE_STATUS_ANNOUNCE: 'ADMIN_CHANGE_STATUS_ANNOUNCE',
  ADMIN_DETAIL_ANNOUNCE: 'ADMIN_DETAIL_ANNOUNCE',
  ADMIN_DELETE_ANNOUNCE: 'ADMIN_DELETE_ANNOUNCE'
}

export const BANNER_MANAGEMENT_CODE = {
  ADMIN_SEARCH_BANNER: 'ADMIN_SEARCH_BANNER',
  ADMIN_CREATE_BANNER: 'ADMIN_CREATE_BANNER',
  ADMIN_UPDATE_BANNER: 'ADMIN_UPDATE_BANNER',
  ADMIN_DELETE_BANNER: 'ADMIN_DELETE_BANNER',
  ADMIN_DETAIL_BANNER: 'ADMIN_DETAIL_BANNER'
}

//Quản lý kích hoạt --ACTIVATED_MANAGEMENT
export const ACTIVATED_MANAGEMENT_CODE = {
  ADMIN_SEARCH_ACTIVATED: 'ADMIN_SEARCH_ACTIVATED',
  ADMIN_EXPORT_ACTIVATED: 'ADMIN_EXPORT_ACTIVATED'
}

//Quản lý lịch sử thay đổi tích luỹ kích hoạt --HISTORY_USER_REWARD_MANAGEMENT
export const HISTORY_USER_REWARD_MANAGEMENT_CODE = {
  ACTIVATION_SEARCH_HISTORY_CHANGE: 'ACTIVATION_SEARCH_HISTORY_CHANGE',
  ACTIVATION_EXPORT_HISTORY_CHANGE: 'ACTIVATION_EXPORT_HISTORY_CHANGE'
}

export const BANK_MANAGEMENT_CODE = {
  BANK_SEARCH_BANK: 'BANK_SEARCH_BANK',
  BANK_CREATE_BANK: 'BANK_CREATE_BANK',
  BANK_UPDATE_BANK: 'BANK_UPDATE_BANK',
  BANK_DELETE_BANK: 'BANK_DELETE_BANK',
  BANK_DETAIL_BANK: 'BANK_DETAIL_BANK'
}

export const CONTACT_MANAGEMENT_CODE = {
  CONTACT_SEARCH_CONTACT: 'CONTACT_SEARCH_CONTACT',
  CONTACT_CREATE_CONTACT: 'CONTACT_CREATE_CONTACT',
  CONTACT_UPDATE_CONTACT: 'CONTACT_UPDATE_CONTACT',
  CONTACT_DELETE_CONTACT: 'CONTACT_DELETE_CONTACT',
  CONTACT_DETAIL_CONTACT: 'CONTACT_DETAIL_CONTACT'
}

export const REPORT_SMS_MANAGEMENT_CODE = {
  REPORT_SMS_STATISTIC_SEARCH: 'REPORT_SMS_STATISTIC_SEARCH',
  REPORT_SMS_STATISTIC_EXPORT: 'REPORT_SMS_STATISTIC_EXPORT'
}

export const SYSTEM_NOTIFICATION_MANAGEMENT_CODE = {
  SYSTEM_NOTIFICATION_SEARCH: 'SYSTEM_NOTIFICATION_SEARCH',
  SYSTEM_NOTIFICATION_CREATE: 'SYSTEM_NOTIFICATION_CREATE',
  SYSTEM_NOTIFICATION_UPDATE: 'SYSTEM_NOTIFICATION_UPDATE',
  SYSTEM_NOTIFICATION_DELETE: 'SYSTEM_NOTIFICATION_DELETE',
  SYSTEM_NOTIFICATION_DETAIL: 'SYSTEM_NOTIFICATION_DETAIL'
}

//Báo cáo thống kê hoạt động
export const REPORT_STATISTIC_MANAGEMENT_CODE = {
  REPORT_STATISTIC_USER_ACTIVE_BY_AREAR: 'REPORT_STATISTIC_USER_ACTIVE_BY_AREAR',
  REPORT_EXPORT_STATISTIC_USER_ACTIVE_BY_AREAR: 'REPORT_EXPORT_STATISTIC_USER_ACTIVE_BY_AREAR'
}

//Báo cáo xếp hạng tài khoản
export const REPORT_RANK_ACCOUNT_MANAGEMENT_CODE = {
  REPORT_ACCOUNT_RANK_TYPE_ACCUMULATION: 'REPORT_ACCOUNT_RANK_TYPE_ACCUMULATION',
  REPORT_EXPORT_ACCOUNT_RANK_TYPE_ACCUMULATION: 'REPORT_EXPORT_ACCOUNT_RANK_TYPE_ACCUMULATION'
}

//Tạm tính thưởng theo sản phẩm
export const REPORT_BY_PRODUCT_CODE = {
  REPORT_SEARCH_BY_PRODUCT: 'REPORT_SEARCH_BY_PRODUCT',
  REPORT_EXPORT_BY_PRODUCT: 'REPORT_EXPORT_BY_PRODUCT'
}

//Tạm tính thưởng theo chương trình
export const REPORT_RECONCILE_CODE = {
  REPORT_SEACH_BY_PROGRAMMING: 'REPORT_SEACH_BY_PROGRAMMING',
  REPORT_EXPORT_BY_PROGRAMMING: 'REPORT_EXPORT_BY_PROGRAMMING'
}

// Log kế toán
export const REPORT_RECONCILE_ACCOUNTING_CODE = {
  REPORT_ACCOUNTING_SEARCH_BY_PROGRAMMING: 'REPORT_ACCOUNTING_SEARCH_BY_PROGRAMMING'
}

export const screenConfig = [
  {
    code: SCREEN_CODE.USER_ACCOUNT_MANAGEMENT,
    name: 'Tài khoản cửa hàng'
  },
  {
    code: SCREEN_CODE.TECHNICIAN_ACCOUNT_MANAGEMENT,
    name: 'Tài khoản kỹ thuật viên'
  },

  {
    code: SCREEN_CODE.PRODUCT,
    name: 'Quản lý sản phẩm'
  },
  {
    code: SCREEN_CODE.CATEGORY_MANAGEMENT,
    name: 'Quản lý danh mục sản phẩm',
    parent_code: SCREEN_CODE.PRODUCT
  },
  {
    code: SCREEN_CODE.PRODUCT_MANAGEMENT,
    name: 'Quản lý sản phẩm',
    parent_code: SCREEN_CODE.PRODUCT
  },
  {
    code: SCREEN_CODE.PRODUCT_ACTIVATION_MANAGEMENT,
    name: 'Quản lý mã bảo hành',
    parent_code: SCREEN_CODE.PRODUCT
  },

  {
    code: SCREEN_CODE.ADMINS,
    name: 'Quản lý người dùng CMS'
  },

  {
    code: SCREEN_CODE.DEPARTMENT_MANAGEMENT,
    name: 'Quản lý đơn vị',
    parent_code: SCREEN_CODE.ADMINS
  },
  {
    code: SCREEN_CODE.ROLE_MANAGEMENT,
    name: 'Quản lý phân quyền',
    parent_code: SCREEN_CODE.ADMINS
  },
  {
    code: SCREEN_CODE.ADMIN_ACCOUNT_MANAGEMENT,
    name: 'Quản lý người dùng',
    parent_code: SCREEN_CODE.ADMINS
  },
  {
    code: SCREEN_CODE.PROGRAMING_MANAGEMENT,
    name: 'Xây dựng chương trình'
  },
  {
    code: SCREEN_CODE.AREA_MANAGEMENT,
    name: 'Quản lý địa bàn'
  },
  {
    code: SCREEN_CODE.RANKS,
    name: 'Quản lý hạng'
  },

  {
    code: SCREEN_CODE.RANK_MANAGEMENT,
    name: 'Quản lý loại hạng',
    parent_code: SCREEN_CODE.RANKS
  },
  {
    code: SCREEN_CODE.RANK_CONFIG_MANAGEMENT,
    name: 'Cơ cấu hạng',
    parent_code: SCREEN_CODE.RANKS
  },
  {
    code: SCREEN_CODE.POINT_CONFIG_MANAGEMENT,
    name: 'Cấu hình điểm',
    parent_code: SCREEN_CODE.RANKS
  },
  {
    code: SCREEN_CODE.POINT_HISTORY_MANAGEMENT,
    name: 'Lịch sử tích điểm',
    parent_code: SCREEN_CODE.POINT_HISTORY_MANAGEMENT
  },
  {
    code: SCREEN_CODE.ACCUMULATED_MANAGEMENT,
    name: 'Quản lý tích lũy'
  },

  {
    code: SCREEN_CODE.ACTIVATED_MANAGEMENT,
    name: 'Quản lý kích hoạt'
  },
  {
    code: SCREEN_CODE.CUSTOMER_MANAGEMENT,
    name: 'Quản lý khách hàng'
  },
  {
    code: SCREEN_CODE.ANNOUNCE_MANAGEMENT,
    name: 'Quản lý bài viết'
  },
  {
    code: SCREEN_CODE.BANNER_MANAGEMENT,
    name: 'Quản lý banner'
  }
]
