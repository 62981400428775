import React from "react"
import { Badge, UncontrolledTooltip } from "reactstrap"
import { Link } from "react-router-dom"
import { ManageContentVN } from "constants/vn"
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import { formateDate } from "helpers/global_helper"

const Priority = cell => {
    return cell.value ? cell.value : "--"
}

const AccountName = cell => {
    return cell.value ? cell.value : "--"
}

const AnnounceName = cell => {
    return cell.value ? cell.value.name : "--"
}

const AnnounceDuration = cell => {
    return cell.value ? formateDate(cell.value.startDate) + " - " + formateDate(cell.value.endDate) : "--"
}

const AnnounceImage = cell => {
    return cell.value.image ? (
        <img
            key={`id-image-announce-${cell.value.id}-${cell.value.name}`}
            src={cell.value.image}
            className="rounded-2"
            style={{ width: 150, height: 80 }}
            alt={`id-image-announce-${cell.value.id}-${cell.value.name}`}
        />
    ) : (
        ""
    )
}

const StatusAccount = cell => {
    return cell.value ? "Hội viên" : "Chưa là hội viên"
}

const Status = cell => {
    switch (cell.value) {
        case "pending":
            return <Badge className="badge-soft-primary">{ManageContentVN.statusOptions[0].label}</Badge>
        case "active":
            return <Badge className="badge-soft-success">{ManageContentVN.statusOptions[1].label}</Badge>
        case "inactive":
            return <Badge className="badge-soft-warning">{ManageContentVN.statusOptions[2].label}</Badge>
        case "terminated":
            return <Badge className="badge-soft-danger">{ManageContentVN.statusOptions[3].label}</Badge>
        case "registered":
            return <Badge className="badge-soft-success">{ManageContentVN.statusOptions[1].label}</Badge>
        case "pending-removal":
            return <Badge className="badge-soft-secondary">{ManageContentVN.statusOptions[4].label}</Badge>
    }
}

export { Priority, AnnounceName, AnnounceDuration, AnnounceImage, AccountName, Status, StatusAccount }
