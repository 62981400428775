import {
    GET_MANAGE_GUEST,
    GET_MANAGE_GUEST_FAIL,
    GET_MANAGE_GUEST_SUCCESS,
    GET_GUEST_DETAIL,
    GET_GUEST_DETAIL_SUCCESS,
    GET_GUEST_DETAIL_FAIL,
    SET_TYPE_POPUP,
} from "./actionTypes"

export const getGuestList = params => ({
    type: GET_MANAGE_GUEST,
    payload: params,
})

export const getManageGuestSuccess = data => ({
    type: GET_MANAGE_GUEST_SUCCESS,
    payload: data,
})

export const getManageGuestFail = error => ({
    type: GET_MANAGE_GUEST_FAIL,
    payload: error,
})

export const getGuestDetail = data => ({
    type: GET_GUEST_DETAIL,
    payload: data,
})

export const getGuestDetailSuccess = data => ({
    type: GET_GUEST_DETAIL_SUCCESS,
    payload: data,
})

export const getGuestDetailFail = error => ({
    type: GET_GUEST_DETAIL_FAIL,
    payload: error,
})

export const setTypePopupGuest = params => ({
    type: SET_TYPE_POPUP,
    payload: params,
})
