import React, { useEffect, useMemo, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

//import components

import { getUserList, setTypePopupUser } from 'store/actions'

import { BaseCol, NoCol, DateCol, TypeCol } from './components/ManageAccountClass/ManageAccountCol'

//redux
import { useSelector, useDispatch } from 'react-redux'
import { createSelector } from 'reselect'

import { Col, Row, UncontrolledTooltip, Card, CardBody } from 'reactstrap'
import Spinners from 'components/Common/Spinner'
import { toast } from 'react-toastify'
import TableContainer from 'components/Common/TableContainer'
import { AccountClassVN } from 'constants/vn'
import ConfirmModal from 'components/Common/ConfirmModal'
import { getRankListAPI, deleteRankAPI } from 'helpers/backend_helper'
import AddEditLocality from './components/ManageAccountClass/AddEditAccountClass'
import { OptionTypeAccount } from './constants/index'
import { RANK_MANAGEMENT_CODE, SCREEN_CODE } from 'constants/permission-code'
import PermissionWrapper from 'components/Common/PermissionWrapper'

function ManageAccountClass() {
  //meta title
  document.title = 'Manage Account Class CMS List | Skote - React Admin & Dashboard Template'
  const screenCode = useMemo(() => SCREEN_CODE.RANK_MANAGEMENT, [])

  const [isLoading, setLoading] = useState(false)
  const [selected, setSelected] = useState()
  const [filter, setFilter] = useState()
  const [openModal, setOpenModal] = useState(false)
  const [listRank, setListRank] = useState([])
  const [type, setType] = useState('create')
  const [showDelete, setShowDelete] = useState(false)
  const [total, setTotal] = useState()
  const [page, setPage] = useState(1)

  useEffect(() => {
    handleGetListRank()
  }, [filter])

  const handleGetListRank = async () => {
    try {
      const res = await getRankListAPI(filter)
      if (res.data) {
        setListRank(res.data)
      }

      if (res.meta) {
        setTotal(res.meta.totalItems)
      }
    } catch (error) {
      toast(error.response.data.message, { type: 'error' })
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: AccountClassVN.table.stt,
        accessor: 'stt',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return (page - 1) * 10 + cellProps.row.index + 1
        }
      },
      {
        Header: AccountClassVN.table.name,
        accessor: 'name',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <BaseCol {...cellProps} />
        }
      },
      {
        Header: AccountClassVN.table.accountType,
        accessor: 'client',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <TypeCol {...cellProps} />
        }
      },
      {
        Header: AccountClassVN.table.updateDate,
        accessor: 'updatedAt',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <DateCol {...cellProps} />
        }
      },
      {
        Header: AccountClassVN.table.action,
        accessor: 'action',
        disableFilters: true,
        hideSort: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <ul className='list-unstyled hstack gap-1 mb-0'>
              {/* <li data-bs-toggle="tooltip" data-bs-placement="top" title="View">
                                <Link
                                    to={`#`}
                                    className="btn btn-sm btn-soft-primary"
                                    id={`viewtooltip-${cellProps.row.original.id}`}
                                    onClick={() => {
                                        setOpenModal(true)
                                        setType("view")
                                        setSelected(cellProps.row.original)
                                    }}
                                >
                                    <i className="mdi mdi-eye-outline" />
                                    <UncontrolledTooltip trigger="hover" placement="top" target={`viewtooltip-${cellProps.row.original.id}`}>
                                        Xem
                                    </UncontrolledTooltip>
                                </Link>
                            </li> */}
              <PermissionWrapper screenCode={screenCode} code={RANK_MANAGEMENT_CODE.RANKS_UPDATE_RANK}>
                <li data-bs-toggle='tooltip' data-bs-placement='top' title='View'>
                  <Link
                    to={`#`}
                    className='btn btn-sm btn-soft-info'
                    id={`edittooltip-${cellProps.row.original.id}`}
                    onClick={() => {
                      setOpenModal(true)
                      setType('edit')
                      setSelected(cellProps.row.original)
                    }}
                  >
                    <i className='mdi mdi-pencil-outline' />
                    <UncontrolledTooltip
                      trigger='hover'
                      placement='top'
                      target={`edittooltip-${cellProps.row.original.id}`}
                    >
                      Sửa
                    </UncontrolledTooltip>
                  </Link>
                </li>
              </PermissionWrapper>
              <PermissionWrapper screenCode={screenCode} code={RANK_MANAGEMENT_CODE.RANKS_DELETE_RANK}>
                <li>
                  <button
                    // disabled={!!cellProps.row.original?.extTransId}
                    className={`btn btn-sm btn-soft-danger ${!!cellProps.row.original?.extTransId && 'opacity-50'}`}
                    onClick={() => {
                      const item = cellProps.row.original
                      setShowDelete(true)
                      setSelected(item)
                    }}
                    id={`deletetooltip-${cellProps.row.original.code}`}
                  >
                    <i className='mdi mdi-delete-outline' />
                    <UncontrolledTooltip
                      trigger='hover'
                      placement='top'
                      target={`deletetooltip-${cellProps.row.original.code}`}
                    >
                      Xoá
                    </UncontrolledTooltip>
                  </button>
                </li>
              </PermissionWrapper>
            </ul>
          )
        }
      }
    ],
    [page]
  )

  const setGlobalFilterUser = async (data) => {
    setFilter({ search: data.search, client: data.select, page: 1 })
    setPage(1)
  }

  const refresh = (type) => {
    if (type == 'create') {
      setFilter({ search: '', client: undefined, page: 1 })
      setPage(1)
    } else {
      handleGetListRank({ ...filter, page })
    }
  }

  const onClickDelete = async () => {
    try {
      const res = await deleteRankAPI({ id: selected.id })

      if (res) {
        toast('Xóa thành công loại hạng', { type: 'success' })
        refresh()
        setShowDelete(false)
        setSelected()
      }
    } catch (error) {
      toast(error.response.data.message, { type: 'error' })
    }
  }

  const goToPage = (page) => {
    setFilter({ ...filter, page })
    setPage(page)
  }

  return (
    <React.Fragment>
      <div className='page-content'>
        <div className='page-title-box container-fluid'>
          <h4 className=' mb-sm-0 font-size-18 pb-4'>{AccountClassVN.title}</h4>
          {isLoading ? (
            <Spinners setLoading={setLoading} />
          ) : (
            <Row>
              <Col lg='12'>
                <Card>
                  <CardBody className='border-bottom'>
                    <div className='d-flex align-items-center'>
                      <h5 className='mb-0 card-title flex-grow-1'>{AccountClassVN.list}</h5>
                      <div className='flex-shrink-0'>
                        <PermissionWrapper screenCode={screenCode} code={RANK_MANAGEMENT_CODE.RANKS_CREATE_RANK}>
                          <button
                            onClick={() => {
                              setOpenModal(true)
                              setType('create')
                            }}
                            className='btn btn-primary'
                          >
                            <i className='mdi mdi-plus me-1' />
                            {AccountClassVN.add}
                          </button>
                        </PermissionWrapper>
                      </div>
                    </div>
                  </CardBody>
                  <CardBody>
                    <TableContainer
                      columns={columns}
                      data={listRank}
                      isGlobalFilter={true}
                      setGlobalFilterUser={setGlobalFilterUser}
                      optionPermissionGroup={OptionTypeAccount}
                      isSearchSelect={true}
                      titleSearchSelect={AccountClassVN.filters.accoutType.title}
                      placeholderSearchSelect={AccountClassVN.filters.accoutType.placeholder}
                      isPagination={true}
                      iscustomPageSizeOptions={false}
                      isShowingPageLength={false}
                      customPageSize={10}
                      placeholderSearch={AccountClassVN.filters.search.placeholder}
                      tableClass='table-bordered align-middle nowrap mt-2'
                      paginationDiv='col-sm-12 col-md-7'
                      pagination='pagination justify-content-end pagination-rounded'
                      useAPIChangePaging
                      currentPage={page}
                      gotoPageCustom={goToPage}
                      total={total}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </div>
      </div>
      {openModal && (
        <AddEditLocality
          show={openModal}
          type={type}
          selected={selected}
          onCloseClick={() => setOpenModal(false)}
          refresh={refresh}
        />
      )}
      <ConfirmModal
        show={showDelete}
        icon={
          <div className='avatar-title rounded-circle bg-danger text-light bg-opacity-60 font-size-20 rounded-3'>
            <i className='mdi mdi-delete-outline'></i>
          </div>
        }
        onConfirmClick={onClickDelete}
        onCloseClick={() => setShowDelete(false)}
        title={`Xác nhận xóa hạng tài khoản`}
        description={`Bạn có chắc chắn muốn xóa hạng tài khoản?`}
      />
    </React.Fragment>
  )
}

export default ManageAccountClass
