import {
  ACCUMULATED_MANAGEMENT_CODE,
  ADMIN_ACCOUNT_MANAGEMENT_CODE,
  ANNOUNCE_MANAGEMENT_CODE,
  AREA_MANAGEMENT_CODE,
  BANNER_MANAGEMENT_CODE,
  CATEGORY_MANAGEMENT_CODE,
  CUSTOMER_MANAGEMENT_CODE,
  DEPARTMENT_MANAGEMENT_CODE,
  POINT_CONFIG_MANAGEMENT_CODE,
  POINT_HISTORY_MANAGEMENT_CODE,
  PRODUCT_ACTIVATION_MANAGEMENT_CODE,
  PRODUCT_MANAGEMENT_CODE,
  PROGRAMING_MANAGEMENT_CODE,
  RANK_CONFIG_MANAGEMENT_CODE,
  RANK_MANAGEMENT_CODE,
  ROLE_MANAGEMENT_CODE,
  TECHNICIAN_ACCOUNT_MANAGEMENT_CODE,
  USER_ACCOUNT_MANAGEMENT_CODE,
  BANK_MANAGEMENT_CODE,
  REPORT_SMS_MANAGEMENT_CODE,
  SYSTEM_NOTIFICATION_MANAGEMENT_CODE,
  REPORT_BY_PRODUCT_CODE,
  REPORT_RECONCILE_CODE,
  REPORT_RECONCILE_ACCOUNTING_CODE,
  REPORT_STATISTIC_MANAGEMENT_CODE,
  REPORT_RANK_ACCOUNT_MANAGEMENT_CODE
} from 'constants/permission-code'

const permissionRoutes = [
  USER_ACCOUNT_MANAGEMENT_CODE.ADMIN_SEARCH_LIST_USER,
  TECHNICIAN_ACCOUNT_MANAGEMENT_CODE.TECHNICIAN_SEARCH_LIST_USER,
  CATEGORY_MANAGEMENT_CODE.PRODUCT_SEARCH_CATEGORY,
  PRODUCT_MANAGEMENT_CODE.PRODUCT_SEARCH_PRODUCT,
  PRODUCT_ACTIVATION_MANAGEMENT_CODE.PRODUCT_SEARCH_PRODUCT_ACTIVATION,
  DEPARTMENT_MANAGEMENT_CODE.ADMIN_SEARCH_DEPARTMENT,
  ROLE_MANAGEMENT_CODE.ADMIN_SEARCH_ROLE,
  PROGRAMING_MANAGEMENT_CODE.ADMIN_SEARCH_PROGRAMMING,
  AREA_MANAGEMENT_CODE.ADMIN_GET_LIST_AREA,
  RANK_MANAGEMENT_CODE.RANKS_SEARCH_RANK,
  RANK_CONFIG_MANAGEMENT_CODE.RANKS_SEARCH_RANK_CONFIG,
  POINT_CONFIG_MANAGEMENT_CODE.RANK_SEARCH_POINT_CONFIG,
  POINT_HISTORY_MANAGEMENT_CODE.POINT_HISTORY_MANAGEMENT,
  ADMIN_ACCOUNT_MANAGEMENT_CODE.ADMIN_SEARCH_LIST_ADMIN_ACCOUNT,
  ACCUMULATED_MANAGEMENT_CODE.ADMIN_SEARCH_ACCUMULATED,
  CUSTOMER_MANAGEMENT_CODE.ADMIN_SEARCH_CUSTOMER,
  ANNOUNCE_MANAGEMENT_CODE.ADMIN_SEARCH_ANNOUNCE,
  BANNER_MANAGEMENT_CODE.ADMIN_SEARCH_BANNER,
  BANK_MANAGEMENT_CODE.BANK_SEARCH_BANK,
  REPORT_SMS_MANAGEMENT_CODE.REPORT_SMS_STATISTIC_SEARCH,
  SYSTEM_NOTIFICATION_MANAGEMENT_CODE.SYSTEM_NOTIFICATION_SEARCH,
  REPORT_BY_PRODUCT_CODE.REPORT_SEARCH_BY_PRODUCT,
  REPORT_RECONCILE_CODE.REPORT_SEACH_BY_PROGRAMMING,
  REPORT_RECONCILE_ACCOUNTING_CODE.REPORT_ACCOUNTING_SEARCH_BY_PROGRAMMING,
  REPORT_STATISTIC_MANAGEMENT_CODE.REPORT_STATISTIC_USER_ACTIVE_BY_AREAR,
  REPORT_RANK_ACCOUNT_MANAGEMENT_CODE.REPORT_ACCOUNT_RANK_TYPE_ACCUMULATION
]

export default permissionRoutes
