import React from 'react'
import { Badge, UncontrolledTooltip } from 'reactstrap'
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css'
import { formateDate } from 'helpers/global_helper'

const priceSplitter = (number) => number && number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')

const ReportCode = (cell) => {
  return cell.value ? cell.value : '--'
}

const Name = (cell) => {
  return cell.value ? cell?.value : '--'
}

const TotalUser = (cell) => {
  return cell.row.original ? priceSplitter(cell.row.original?.totalUser) : '--'
}

const TotalCompleted = (cell) => {
  return cell.row.original ? priceSplitter(cell.row.original?.totalCompleted) : '--'
}

const StringGrp = (cell) => {
  return cell.value ? cell?.value.split('-')[1] : '--'
}

const NumberGrp = (cell) => {
  return cell?.value ? priceSplitter(cell?.value.split('-')[1]) : '--'
}

export { ReportCode, Name, TotalUser, TotalCompleted, StringGrp, NumberGrp }
