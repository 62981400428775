import PropsType from 'prop-types'
import { useMemo, useState } from 'react'
import { Label, UncontrolledTooltip } from 'reactstrap'
import React from 'react'
import { Row, Col, Space, Flex } from 'antd'

//flatpickr
import { CommonVN } from 'constants/vn'

import { DatePicker } from 'antd'
import { FormInput } from 'components/Common/form-input/FormInput'
import { FormSelect } from 'components/Common/form-select/FormSelect'
import { MAX_LENGTH_SEARCH, TypeProgramOptions } from 'constants/constants'
import { FormDateRange } from 'components/Common/form-date-range/FormDateRange'

const { RangePicker } = DatePicker

RecordPaymentFilter.propTypes = {
  handleFilter: PropsType.func
}

function RecordPaymentFilter({ handleFilter }) {
  const [filter, setFilter] = useState({
    search: '',
    status: '',
    time: []
  })

  const onFilterChange = (name, value) =>
    setFilter((prev) => ({
      ...prev,
      [name]: value
    }))

  const ColProps = useMemo(() => ({
    xs: { span: 24 },
    xxl: { span: 4 }
  }))

  const onResetFilter = () => setFilter({ search: '', status: '', time: [] })

  return (
    <Row gutter={[10, 10]} align='bottom'>
      <Col {...ColProps} className='w-100'>
        <Label className='form-label'>Nhập từ khóa tìm kiếm</Label>
        <FormInput
          className='w-100'
          maxLength={MAX_LENGTH_SEARCH}
          placeholder='Nhập tên tài khoản, SĐT'
          value={filter?.search || ''}
          name='search'
          id={`input-filter`}
          onChange={(e) => onFilterChange('search', e.target.value)}
          onBlur={(e) => onFilterChange('search', e.target.value)}
        />
        <UncontrolledTooltip trigger='hover' placement='top' target={`input-filter`}>
          {''}
        </UncontrolledTooltip>
      </Col>

      <Col {...ColProps}>
        <Label className='form-label'>Thời gian xét thưởng</Label>
        <FormDateRange
          className='w-100'
          size='large'
          name='timeApplied'
          format={CommonVN.datePlaceholder.toUpperCase()}
          placeholder={[CommonVN.datePlaceholder, CommonVN.datePlaceholder]}
          value={filter?.time || []}
          onChange={(value) => onFilterChange('timeApplied', value)}
        />
      </Col>
      <Col {...ColProps}>
        <Label className='form-label'>Trạng thái đối soát</Label>
        <FormSelect
          options={TypeProgramOptions}
          value={filter?.status || ''}
          name='programType'
          onChange={(value) => onFilterChange('programType', value)}
          placeholder='Chọn loại chương trình'
        />
      </Col>
      <Col {...ColProps}>
        <Space>
          <button type='button' className='btn btn-soft-danger' onClick={onResetFilter}>
            Xóa tìm kiếm
          </button>

          <button type='button' className='btn btn-primary' onClick={() => handleFilter(filter)}>
            Tìm kiếm
          </button>
        </Space>
      </Col>
      <Col flex='1'>
        <Flex gap='small' justify={{ xs: 'start', xxl: 'end' }}>
          <button type='button' className='button-import-export' onClick={onResetFilter}>
            <i className='mdi mdi-arrow-collapse-down' />
            Import
          </button>

          <button type='button' className='button-import-export'>
            <i className='mdi mdi-arrow-collapse-up' />
            Export
          </button>

          <button type='button' className='button-primary'>
            <i className='mdi mdi-content-save-outline' />
            Xác nhận đối soát
          </button>
        </Flex>
      </Col>
    </Row>
  )
}

export default RecordPaymentFilter
