export const GET_MANAGE_GUEST = "GET_GUEST_LIST"
export const GET_MANAGE_GUEST_FAIL = "GET_MANAGE_GUEST_FAIL"
export const GET_MANAGE_GUEST_SUCCESS = "GET_MANAGE_GUEST_SUCCESS"

export const GET_GUEST_DETAIL = "GET_GUEST_DETAIL"
export const GET_GUEST_DETAIL_SUCCESS = "GET_GUEST_DETAIL_SUCCESS"
export const GET_GUEST_DETAIL_FAIL = "GET_GUEST_DETAIL_FAIL"

export const UPDATE_MANAGE_GUEST = "UPDATE_MANAGE_GUEST"
export const UPDATE_MANAGE_GUEST_SUCCESS = "UPDATE_MANAGE_GUEST_SUCCESS"
export const UPDATE_MANAGE_GUEST_FAIL = "UPDATE_MANAGE_GUEST_FAIL"

export const SET_TYPE_POPUP = "SET_TYPE_POPUP_GUEST"
