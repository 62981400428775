import { UnitVN } from 'constants/vn'
import React, { useEffect, useState } from 'react'
import { Col, Modal, ModalBody, Row } from 'reactstrap'
import { Form, TreeSelect, Input, Spin } from 'antd'
import { createSelector } from 'reselect'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { getDepartmentAll, getDepartmentAllList, getUnitList, setTypePopupUnit, setFiltersUnit } from 'store/actions'
import { postCreateUnitAPI, updateUnitAPI } from 'helpers/backend_helper'
import { FormInput } from 'components/Common/form-input/FormInput'
import { MAX_LENGTH_50 } from 'constants/constants'
import ConfirmModal from 'components/Common/ConfirmModal'
import { isEqual } from 'lodash'

const AddEditUnit = ({ show, onCloseClick, setTreeExpandProd, handleSearch, setPage }) => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()

  const selectUnitsState = (state) => state.UnitReducer
  const UnitsProperties = createSelector(selectUnitsState, (reducer) => ({
    type: reducer.type,
    unitDetail: reducer.unitDetail,
    department_list: reducer.department_list,
    departments: reducer.departments,
    filters: reducer.filters
  }))
  const { type, unitDetail, departments, department_list, filters } = useSelector(UnitsProperties)
  const isEdit = type !== 'view'

  const [value, setValue] = useState(0)
  const [treeExpand, setTreeExpand] = useState()
  const [valueName, setValueName] = useState()
  const [loading, setLoading] = useState(false)
  const [onCancel, setOnCancel] = useState(false)
  const [initData, setInitData] = useState()

  useEffect(() => {
    handleDataTreeData()
  }, [departments])

  useEffect(() => {
    if (type != 'create' && unitDetail) {
      form.setFieldsValue({
        parent_id: unitDetail.parentId ? `Điện lạnh Hoà Phát/${unitDetail.treeName}` : 0,
        name: unitDetail.name
      })
      setValue(unitDetail.parentId ? `Điện lạnh Hoà Phát/${unitDetail.treeName}` : 0)
      setValueName(unitDetail.name)
      setInitData({
        parent_id: unitDetail.parentId ? `Điện lạnh Hoà Phát/${unitDetail.treeName}` : 0,
        name: unitDetail.name
      })
    }
  }, [unitDetail])

  const handleDataTreeData = () => {
    if (treeExpand) {
      const key_split = treeExpand.split('-')
      const list = departments.map((item) => {
        // if (item.id == unitDetail.parentId) {
        //     form.setFieldValue("parent_id", unitDetail.parentId)
        //     setValue(unitDetail.parentId)
        // }

        return {
          id: item.id,
          title: item.name,
          key: `${treeExpand}-${item.id}`,
          value: `${treeExpand}-${item.id}`,
          isLeaf: !item.haveChildren,
          selectable: key_split.length <= 1
        }
      })
      const update = updateTreeData(department_list, treeExpand, list)
      dispatch(getDepartmentAllList(update))
    }
  }

  const updateTreeData = (list, key, children) => {
    return list.map((node) => {
      if (node.key === key) {
        return {
          ...node,
          children
        }
      }
      if (node.children) {
        return {
          ...node,
          children: updateTreeData(node.children, key, children)
        }
      }
      return node
    })
  }

  const onLoadData = ({ key, children }) => {
    let key_expand
    const key_split = key.split('-')
    if (key_split.length > 1) {
      key_expand = key_split[key_split.length - 1]
    } else {
      key_expand = key
    }
    return new Promise((resolve) => {
      if (children) {
        resolve()
        return
      }

      dispatch(getDepartmentAll({ parent_id: key_expand }))
      setTreeExpand(key)
      resolve()
    })
  }

  const onChange = (newValue) => {
    setValue(newValue)
  }

  const handleSubmit = async (value) => {
    if (type == 'view') {
      dispatch(setTypePopupUnit('edit'))
      return
    }
    setLoading(true)
    try {
      let parent_id
      const split = value.parent_id.toString().split('-')
      if (split.length > 1) {
        parent_id = split[split.length - 1]
      } else {
        parent_id = value.parent_id
      }
      if (type == 'create') {
        const res = await postCreateUnitAPI({ parent_id: parent_id, name: value.name })

        if (res) {
          // const list = addChildren(department_list, value.parent_id, {
          //     id: res.data.id,
          //     title: value.name,
          //     key: `${value.parent_id}-${res.data.id}`,
          //     value: `${value.parent_id}-${res.data.id}`,
          //     isLeaf: split.length > 1,
          //     selectable: !(split.length > 1)
          // })
          // console.log('list', list)
          toast('Đơn vị bộ phận được tạo mới thành công', { type: 'success' })
          onCloseClick()
          dispatch(getUnitList())
          dispatch(getDepartmentAll())
          dispatch(getDepartmentAllList([]))
          setTreeExpandProd()
          setPage(1)
        }
      } else {
        const res = await updateUnitAPI({ id: unitDetail.id, parent_id: parent_id, name: value.name })

        if (res) {
          // let list = []
          // if (unitDetail.parentId != parent_id) {
          //     list = updateValueByIdChangeParent(department_list, unitDetail.parentId, parent_id, value.parent_id, unitDetail.id, value.name, split)
          // } else {
          //     list = updateValueById(department_list, unitDetail.id, value.name)
          // }
          toast('Đơn vị bộ phận được cập nhật thành công', { type: 'success' })
          onCloseClick()
          dispatch(getUnitList({ ...filters }))
          dispatch(getDepartmentAll())
          dispatch(getDepartmentAllList([]))
          setTreeExpandProd()
        }
      }
    } catch (error) {
      toast(error.response.data.message, { type: 'error' })
    }
    setLoading(false)
  }

  const updateValueById = (data, targetId, newValue) => {
    const data_new = [...data]
    for (let i = 0; i < data_new.length; i++) {
      if (data_new[i].id === targetId) {
        data_new[i].title = newValue
        break
      } else if (data_new[i].children) {
        updateValueById(data_new[i].children, targetId, newValue)
      }
    }
    return data_new
  }

  const updateValueByIdChangeParent = (data, parentIdOld, parentIdNew, parentKeyNew, targetId, newValue, split) => {
    const data_new = [...data]

    const data_removed = removeChildById(data_new, parentIdOld, targetId)
    const data_added = addChildren(data_removed, parentKeyNew, {
      id: unitDetail.id,
      title: newValue,
      key: `${parentKeyNew}-${unitDetail.id}`,
      value: `${parentKeyNew}-${unitDetail.id}`,
      isLeaf: split.length > 2,
      selectable: split.length <= 2
    })

    return data_added
  }

  const addChildren = (data, key, item_new) => {
    const data_new = [...data]

    //HP
    data_new.map((itemLevel1) => {
      if (itemLevel1.key == key) {
        if (itemLevel1.children) {
          itemLevel1.children = [{ ...item_new }, ...itemLevel1.children]
        } else {
          itemLevel1 = { ...itemLevel1, children: [{ ...item_new }] }
        }
      }
      // Cap1
      if (itemLevel1.children) {
        itemLevel1.children.map((itemLevel2) => {
          if (itemLevel2.key == key) {
            if (itemLevel2.children) {
              itemLevel2.children = [{ ...item_new }, ...itemLevel2.children]
            } else {
              itemLevel2 = { ...itemLevel2, children: [{ ...item_new }] }
            }
          }
          //Cap2
          if (itemLevel2.children) {
            itemLevel2.children.map((itemLevel3) => {
              if (itemLevel3.key == key) {
                if (itemLevel3.children) {
                  itemLevel3.children = [{ ...item_new }, ...itemLevel3.children]
                } else {
                  itemLevel3 = { ...itemLevel3, children: [{ ...item_new }] }
                }
              }
              //Cap3
              if (itemLevel3.children) {
                itemLevel3.children.map((itemLevel4) => {
                  if (itemLevel4.key == key) {
                    if (itemLevel4.children) {
                      itemLevel4.children = [{ ...item_new }, ...itemLevel4.children]
                    } else {
                      itemLevel4 = { ...itemLevel4, children: [{ ...item_new }] }
                    }
                  }
                })
              }
            })
          }
        })
      }
    })

    return data_new
  }

  function removeChildById(data, id, childIdToRemove) {
    const data_new = [...data]

    //HP
    data_new.map((itemLevel1) => {
      if (itemLevel1.id == id) {
        if (itemLevel1.children) {
          itemLevel1.children = itemLevel1.children.filter((child) => child.id !== childIdToRemove)
          return
        }
      }
      // Cap1
      if (itemLevel1.children) {
        itemLevel1.children.map((itemLevel2) => {
          if (itemLevel2.id == id) {
            if (itemLevel2.children) {
              itemLevel2.children = itemLevel2.children.filter((child) => child.id !== childIdToRemove)
              return
            }
          }
          //Cap2
          if (itemLevel2.children) {
            itemLevel2.children.map((itemLevel3) => {
              if (itemLevel3.id == id) {
                if (itemLevel3.children) {
                  itemLevel3.children = itemLevel3.children.filter((child) => child.id !== childIdToRemove)
                  return
                }
              }
              //Cap3
              if (itemLevel3.children) {
                itemLevel3.children.map((itemLevel4) => {
                  if (itemLevel4.id == id) {
                    if (itemLevel4.children) {
                      itemLevel4.children = itemLevel4.children.filter((child) => child.id !== childIdToRemove)
                      return
                    }
                  }
                })
              }
            })
          }
        })
      }
    })

    return data_new
  }

  const onClickCancel = () => {
    setOnCancel(false)
    onCloseClick()
  }

  const checkClickCancel = () => {
    if (type != 'view') {
      if (type == 'create') {
        if (form.isFieldsTouched()) {
          setOnCancel(true)
        } else {
          onCloseClick()
        }
      } else {
        if (!isEqual(initData, { parent_id: value, name: valueName })) {
          setOnCancel(true)
        } else {
          onCloseClick()
        }
      }
    } else {
      onCloseClick()
    }
  }

  return (
    <Modal
      size='lg'
      isOpen={show}
      toggle={() => {
        checkClickCancel()
      }}
      centered={true}
    >
      <div className='modal-content'>
        <ModalBody className='p-4 text-start'>
          <Spin spinning={loading}>
            <button type='button' onClick={checkClickCancel} className='btn-close position-absolute end-0 top-0' />
            {type == 'view' && <h2>{UnitVN.viewUnit}</h2>}
            {type == 'create' && <h2>{UnitVN.addUnit}</h2>}
            {type == 'edit' && <h2>{UnitVN.editUnit}</h2>}
            <Form className='form-horizontal' form={form} layout='vertical' onFinish={handleSubmit}>
              <div className='form-group'>
                <Row className='mt-4'>
                  <Col sm='6'>
                    <Form.Item
                      name='parent_id'
                      label={UnitVN.form.unitTree.label}
                      rules={[{ required: true, message: UnitVN.form.unitTree.required }]}
                    >
                      <TreeSelect
                        treeDataSimpleMode
                        style={{ width: '100%' }}
                        size='large'
                        value={value}
                        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                        placeholder={UnitVN.form.unitTree.placeholder}
                        onChange={onChange}
                        loadData={onLoadData}
                        treeData={department_list}
                        disabled={!isEdit}
                        treeDefaultExpandedKeys={['0']}
                      />
                    </Form.Item>
                  </Col>
                  <Col sm='6'>
                    <Form.Item
                      name='name'
                      label={UnitVN.form.name.label}
                      rules={[{ required: true, message: UnitVN.form.name.required }]}
                    >
                      <Input
                        onChange={(e) => setValueName(e.target.value)}
                        value={valueName}
                        maxLength={50}
                        disabled={!isEdit}
                        size='large'
                        placeholder={UnitVN.form.name.placeholder}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
              <div className='hstack gap-2 justify-content-center mt-4'>
                <button type='button' className='btn btn-soft-secondary w-25 ' onClick={checkClickCancel}>
                  Hủy
                </button>
                <button
                  disabled={
                    isEdit
                      ? value == null || !valueName || isEqual(initData, { parent_id: value, name: valueName })
                      : false
                  }
                  type='submit'
                  className='btn btn-primary w-25'
                >
                  {isEdit ? 'Lưu' : 'Chỉnh sửa'}
                </button>
              </div>
            </Form>
          </Spin>
        </ModalBody>
        <ConfirmModal
          show={onCancel}
          onConfirmClick={onClickCancel}
          onCloseClick={() => setOnCancel(false)}
          title={`Xác nhận`}
          description={`Thay đổi sẽ không được lưu. Bạn có chắc chắn muốn thoát không?`}
        />
      </div>
    </Modal>
  )
}
export default AddEditUnit
