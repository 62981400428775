import {
  GET_MANAGE_CONTACT,
  GET_MANAGE_CONTACT_FAIL,
  GET_MANAGE_CONTACT_SUCCESS,
  GET_CONTACT_DETAIL,
  GET_CONTACT_DETAIL_SUCCESS,
  GET_CONTACT_DETAIL_FAIL,
  SET_TYPE_POPUP
} from './actionTypes'

export const getContactList = (params) => ({
  type: GET_MANAGE_CONTACT,
  payload: params
})

export const getManageContactSuccess = (data) => ({
  type: GET_MANAGE_CONTACT_SUCCESS,
  payload: data
})

export const getManageContactFail = (error) => ({
  type: GET_MANAGE_CONTACT_FAIL,
  payload: error
})

export const getContactDetail = (data) => ({
  type: GET_CONTACT_DETAIL,
  payload: data
})

export const getContactDetailSuccess = (data) => ({
  type: GET_CONTACT_DETAIL_SUCCESS,
  payload: data
})

export const getContactDetailFail = (error) => ({
  type: GET_CONTACT_DETAIL_FAIL,
  payload: error
})

export const setTypePopupContact = (params) => ({
  type: SET_TYPE_POPUP,
  payload: params
})
