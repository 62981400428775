import React from 'react'
import { Badge, UncontrolledTooltip } from 'reactstrap'
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css'
import { formateDate } from 'helpers/global_helper'
import moment from 'moment'
import { CheckBox } from 'pages/Dashboard/LatestTranactionCol'
import Checkbox from 'antd/es/checkbox/Checkbox'
import { FormInput } from 'components/Common/form-input/FormInput'
import { v4 as uuidv4 } from 'uuid'

const priceSplitter = (number) => number && number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')

const ReportCode = (cell) => {
  return cell.value ? cell.value : '--'
}

const Name = (cell) => {
  return cell.value ? cell.value : '--'
}

const TotalUser = (cell) => {
  return cell.row.original ? priceSplitter(cell.row.original?.totalUser) : '--'
}

const TotalCompleted = (cell) => {
  return cell.row.original ? priceSplitter(cell.row.original?.totalCompleted) : '--'
}

const Type = (cell) => {
  const type = [
    {
      label: 'Cửa hàng',
      value: 'user'
    },
    {
      label: 'Kĩ thuật viên',
      value: 'technician'
    }
  ]

  return cell?.value ? type.find((e) => e.value === cell?.value.split('-')[1])?.label : '--'
}

const TotalReward = (cell) => {
  return cell?.value ? priceSplitter(cell?.value.split('-')[1]) : '--'
}

const BonusModel = (cell) => {
  const key = uuidv4()
  const array = cell?.value
    .split('-')[1]
    .split(',')
    .map((item) => item.trim())

  // Lấy 3 phần tử đầu của mảng
  let resultString = array.slice(0, 3).join(',')

  // Kiểm tra nếu có nhiều hơn 3 phần tử thì thêm dấu '...'
  if (array.length > 3) {
    resultString += ', ...'
  }

  return (
    <>
      <p id={`bonus-Model-${key}`}>{resultString ? resultString : '--'}</p>
      <UncontrolledTooltip trigger='hover' placement='top' target={`bonus-Model-${key}`}>
        {cell.value ? cell?.value.split('-')[1].toString() : '--'}
      </UncontrolledTooltip>
    </>
  )
}

const Checked = (cell) => {
  let checked = cell?.value.split('-')[1]
  let key = cell?.value.split('-')[0]

  console.log('checked', checked)
  return <Checkbox key={key} checked={JSON.parse(checked)} onChange={(e) => console.log('onClick', checked)} />
}

const MinimumAmount = (cell) => {
  return cell.value ? cell?.value.split('-')[1] : '--'
}

const Phone = (cell) => {
  return cell.value ? cell.value : '--'
}

const PhoneGrp = (cell) => {
  return cell.value ? cell?.value.split('-')[1] : '--'
}

const Description = (cell) => {
  return cell.value
}

const TotalMoney = (cell) => {
  let sum = 0

  for (let i = 0; i < cell?.value?.length; i++) {
    sum += cell?.value[i].money
  }
  return <p> {priceSplitter(sum)} </p>
}

const ListSerial = (cell) => {
  let concatenatedReward = ''

  for (let i = 0; i < cell?.value?.length; i++) {
    for (let packageConfig = 0; packageConfig < cell?.value[i]?.programmingPackageConfig?.length; packageConfig++) {
      for (
        let products = 0;
        products < cell?.value[i]?.programmingPackageConfig[packageConfig]?.products?.length;
        products++
      ) {
        // Concatenate the code of the current object to the string
        concatenatedReward += priceSplitter(
          cell?.value[i]?.programmingPackageConfig[packageConfig]?.products[products]?.serial
        )
        concatenatedReward += ' <br />'
      }
    }
  }

  const resultArr = []
  const arr = concatenatedReward.split(/<br \/>/)
  arr.forEach((item, i) => {
    resultArr.push(<br />)
    resultArr.push(item)
  })
  return <p> {resultArr} </p>
}

const Serial = (cell) => {
  return cell.value ? cell.value : '--'
}

const Model = (cell) => {
  return cell.value ? cell.value : '--'
}

const Date = (cell) => {
  return cell?.value ? moment(cell?.value).format('DD-MM-YYYY') : '--'
}

const Money = (cell) => {
  return cell?.value ? priceSplitter(cell?.value) : '--'
}

const MoneyGrp = (cell) => {
  return cell?.value ? priceSplitter(cell?.value.split('-')[1]) : '--'
}

const Total = (cell) => {
  return cell?.value ? priceSplitter(cell?.value.split('-')[1]) : '--'
}

const ListModel = (cell) => {
  let concatenatedReward = ''

  for (let i = 0; i < cell?.value?.length; i++) {
    for (let packageConfig = 0; packageConfig < cell?.value[i]?.programmingPackageConfig?.length; packageConfig++) {
      for (
        let products = 0;
        products < cell?.value[i]?.programmingPackageConfig[packageConfig]?.models?.length;
        products++
      ) {
        // Concatenate the code of the current object to the string
        concatenatedReward += priceSplitter(
          cell?.value[i]?.programmingPackageConfig[packageConfig]?.models[products]?.model.code
        )
        concatenatedReward += ' <br />'
        // // Add a comma and space if it's not the last object
        // if (i !== cell?.value[i]?.programmingPackageConfig[packageConfig]?.products?.length - 1) {
        //   concatenatedReward += ' <br />'
        // }
      }
    }
  }

  const resultArr = []
  const arr = concatenatedReward.split(/<br \/>/)
  arr.forEach((item, i) => {
    resultArr.push(<br />)
    resultArr.push(item)
  })
  return <p> {resultArr} </p>
}

const ListDate = (cell) => {
  let concatenatedReward = ''

  for (let i = 0; i < cell?.value?.length; i++) {
    for (let packageConfig = 0; packageConfig < cell?.value[i]?.programmingPackageConfig?.length; packageConfig++) {
      for (
        let products = 0;
        products < cell?.value[i]?.programmingPackageConfig[packageConfig]?.products?.length;
        products++
      ) {
        // Concatenate the code of the current object to the string
        concatenatedReward += cell?.value
          ? moment(cell?.value[i]?.programmingPackageConfig[packageConfig]?.products[products]?.createdAt).format(
              'DD-MM-YYYY'
            )
          : '--'
        concatenatedReward += ' <br />'
        // // Add a comma and space if it's not the last object
        // if (i !== cell?.value[i]?.programmingPackageConfig[packageConfig]?.products?.length - 1) {
        //   concatenatedReward += ' <br />'
        // }
      }
    }
  }

  const resultArr = []
  const arr = concatenatedReward.split(/<br \/>/)
  arr.forEach((item, i) => {
    resultArr.push(<br />)
    resultArr.push(item)
  })
  return <p> {resultArr} </p>
}

const ListMoney = (cell) => {
  let concatenatedReward = ''

  for (let i = 0; i < cell?.value?.length; i++) {
    // Concatenate the code of the current object to the string
    concatenatedReward += priceSplitter(cell?.value[i]?.money)
    concatenatedReward += ' <br />'
  }

  const resultArr = []
  const arr = concatenatedReward.split(/<br \/>/)
  arr.forEach((item, i) => {
    resultArr.push(<br />)
    resultArr.push(item)
  })
  return <p> {resultArr} </p>
}

const Status = (cell) => {
  switch (cell.value) {
    case 'pending':
      return <Badge className='badge-soft-info'>{'Tạm tính'}</Badge>
    case 'processing':
      return <Badge className='badge-soft-warning'>{'Đang đối soát'}</Badge>
    case 'completed':
      return <Badge className='badge-soft-success'>{'Đã đối soát'}</Badge>
  }
}

const ConcileStatus = (cell) => {
  switch (cell.value) {
    case 3:
      return <Badge className='badge-soft-danger'>{'Từ chối tự động'}</Badge>
    case 2:
      return <Badge className='badge-soft-danger'>{'Từ chối'}</Badge>
    case 1:
      return <Badge className='badge-soft-success'>{'Đã đối soát'}</Badge>
    case 0:
      return <Badge className='badge-soft-success'>{'Chờ đối soát'}</Badge>
    default:
      return <Badge className='badge-soft-info'>{'Chờ đối soát'}</Badge>
  }
}

const ListCheckBox = (cell) => {
  let concatenatedReward = ''
  const resultArr = []

  for (let i = 0; i < cell?.value?.length; i++) {
    for (let packageConfig = 0; packageConfig < cell?.value[i]?.programmingPackageConfig?.length; packageConfig++) {
      for (
        let products = 0;
        products < cell?.value[i]?.programmingPackageConfig[packageConfig]?.products?.length;
        products++
      ) {
        resultArr.push(
          <div>
            <Checkbox />
          </div>
        )
      }
    }
  }
  return <div>{resultArr}</div>
}

const ListReasonFormInput = (cell) => {
  let concatenatedReward = ''
  const resultArr = []

  for (let i = 0; i < cell?.value?.length; i++) {
    for (let packageConfig = 0; packageConfig < cell?.value[i]?.programmingPackageConfig?.length; packageConfig++) {
      for (
        let products = 0;
        products < cell?.value[i]?.programmingPackageConfig[packageConfig]?.products?.length;
        products++
      ) {
        resultArr.push(
          <div>
            <FormInput className='w-100' placeholder='Nhập lý do từ chối' name='reason' />
          </div>
        )
      }
    }
  }
  return <div>{resultArr}</div>
}

export {
  ReportCode,
  Name,
  TotalUser,
  TotalCompleted,
  Type,
  Phone,
  TotalMoney,
  ListSerial,
  ListModel,
  ListDate,
  ListMoney,
  Status,
  ListCheckBox,
  ListReasonFormInput,
  Serial,
  Model,
  Date,
  Money,
  BonusModel,
  MinimumAmount,
  Checked,
  Description,
  TotalReward,
  Total,
  PhoneGrp,
  ConcileStatus,
  MoneyGrp
}
