import { ProductVN } from "constants/vn"
import React from "react"
import { Badge, Input, UncontrolledTooltip } from "reactstrap"
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"

const ProductNo = cell => {
    return (
        <div className="d-flex justify-content-between  align-items-center">
            {cell?.value == "active" && <i className="mdi mdi-18px mdi-database-import" />}
            {cell?.value == "inactive" && (
                <>
                    <i style={{ color: 'red' }} className="mdi mdi-18px mdi-database-import" id={`viewtooltip-bravo-${cell.row.original.id}`} />
                    <UncontrolledTooltip trigger="hover" placement="top" target={`viewtooltip-bravo-${cell.row.original.id}`}>
                        Dữ liệu đã được Bravo thu hồi.
                    </UncontrolledTooltip>
                </>
            )}
            <div className="text-body fw-bold">{cell?.row ? cell.row.index + 1 + (cell.page - 1) * 10 : ""}</div>
        </div>
    )
}

const ProductIndustry = cell => {
    return cell.value ? cell.value.name : "--"
}

const ProductIndustrySector = cell => {
    return cell.value ? cell.value.name : "--"
}

const ProductCategory = cell => {
    return cell.value ? cell.value.name : "--"
}

const ProductModel = cell => {
    return cell.value ? cell.value.name : "--"
}
const Serial = cell => {
    return cell.value ? cell.value : "--"
}

const WarrantyCode = cell => {
    return cell.value ? cell.value : "--"
}

const Channel = cell => {
    return cell.value ? cell.value.name.charAt(0).toUpperCase() + cell.value.name.slice(1) : "--"
}

const Zone = cell => {
    return cell.value ? cell.value.name : "--"
}
const StatusProduct = cell => {
    if (cell?.value == "activated") {
        return <Badge className="badge-soft-info">{ProductVN.active}</Badge>
    }

    if (cell?.value == "accumulated") {
        return <Badge className="badge-soft-success">{ProductVN.accumulated}</Badge>
    }

    if (cell?.value == "inactive") {
        return <Badge className="badge-soft-danger">{ProductVN.recall}</Badge>
    }

    if (cell?.value == "active") {
        return <Badge className="badge-soft-warning">{ProductVN.inActive}</Badge>
    }
}

export { ProductModel, ProductCategory, ProductIndustrySector, ProductNo, ProductIndustry, StatusProduct, Zone, Channel, WarrantyCode, Serial }
