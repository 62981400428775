import React, { useCallback, useEffect, useMemo, useState } from 'react'

//import components

import {
  BaseCol,
  NoCol,
  DateCol,
  CreateUpdateDuration,
  CreateUpdateDurationMoment,
  ListCode,
  Type,
  ListStartEndDate,
  ListReward,
  ListProgramingApply,
  ModelName,
  SumBonus,
  CreateDurationMoment,
  Serial,
  UserShopName,
  ProgramCode,
  ProgramReward,
  StringGrp,
  NumberGrp,
  DateFormat,
  BaseMoney,
  RewardStatus
} from './components/ManageAccountCol'

import { Col, Row, UncontrolledTooltip, Card, CardBody, Badge } from 'reactstrap'
import Spinners from 'components/Common/Spinner'
import { toast } from 'react-toastify'
import TableContainer from 'components/Common/TableContainer'
import { ClassConfigVN, LocalityVN, ManageProgramVN } from 'constants/vn'
import ConfirmModal from 'components/Common/ConfirmModal'
import {
  checkExportFileAPI,
  checkUploadFileAPI,
  confirmAccountingReconcileByProductAPI,
  confirmReconcileByProductAPI,
  downloadTemplateReconcileAccountingAPI,
  exportByProductAPI,
  exportFileError,
  exportReportAccountingProgrammingByRewardTypeAPI,
  getPointConfigListAPI,
  getProductBonusTempListAPI,
  getReportAccountingSearchProgramingByRewardTypeAPI,
  importFileAPI,
  updatePointConfigAPI
} from 'helpers/backend_helper'
import { Checkbox, Form, Input, Space } from 'antd'
import { FormSelect } from 'components/Common/form-select/FormSelect'
import { StatusProgramOptions, TypeProgramOptions } from 'constants/constants'
import { formateDate } from 'helpers/global_helper'
import fileDownload from 'js-file-download'
import moment from 'moment'
import { TableRowSpan } from 'components/Common/TableCustom'
import withRouter from 'components/Common/withRouter'
import { FormInput } from 'components/Common/form-input/FormInput'
import ProductBonusTempListFilter from 'pages/ReportTemporaryReconciliation/components/ProductBonusTempListFilter'
import ProgramByAreaAndRewardTypeFilter from 'pages/ProgramBonusTemp/components/ProgramByAreaAndRewardTypeFilter'
import ConfirmProgramByRewardTypeFilter from './components/ConfirmProgramByRewardTypeFilter'
import { ConcileStatus } from 'pages/ProgramBonusTemp/ProgramByAreaAndRewardTypeCol'
import Breadcrumb from 'components/Common/Breadcrumb'
import * as url from 'helpers/url_helper'
import ImportModal from 'components/Common/ImportModal'
import { createSelector } from 'reselect'
import { useSelector } from 'react-redux'
// import ProductBonusTempListFilter from './components/ProductBonusTempListFilter'

const ProgramByRewardType = (props) => {
  document.title = 'Manage Point Config CMS List | Skote - React Admin & Dashboard Template'
  const id = props.router.params

  const [isLoading, setLoading] = useState(false)
  const [filter, setFilter] = useState({ id })
  const [edit, setEdit] = useState(true)
  const [listTable, setListTable] = useState([])
  const [total, setTotal] = useState()

  const [form] = Form.useForm()
  const watchForm = Form.useWatch([], form)
  const [disabledSubmit, setDisabledSubmit] = useState(true)
  const [page, setPage] = useState(1)
  const [listDataChange, setListDataChange] = useState([])
  const [tasks, setTasks] = useState([])
  const [flatData, setFlatData] = useState([])
  const [constanceFlatData, setconstanceFlatData] = useState([])
  const [checked, setChecked] = useState(false)
  const [value, setValue] = useState()
  const [pagination, setPagination] = useState({
    pageIndex: page,
    pageSize: 10,
    totalRows: total
  })
  const [descriptionState, setDescriptionState] = useState({})
  const [showImport, setShowImport] = useState(false)
  const [isLoadingImport, setLoadingImport] = useState(false)

  const selectProfileState = (state) => state.Profile
  const ProfileProperties = createSelector(selectProfileState, (profile) => ({
    profile: profile.profile
  }))
  const { profile } = useSelector(ProfileProperties)

  useEffect(() => {
    console.log('tasksUseEffect', tasks)
  }, [tasks])

  useEffect(() => {
    console.log('descriptionStateUseEffect', descriptionState)
  }, [descriptionState])

  useEffect(() => {
    // Flatten the products and push them to an array
    const flatDatas = []
    const totals = {}
    listTable?.forEach((clientData) => {
      let total = 0
      clientData.products?.forEach((product) => {
        const flattenedProduct = {
          id: clientData?.id,
          clientType: clientData?.client,
          clientName: clientData?.name,
          clientPhone: clientData?.phone,
          totalReward: clientData?.reward,
          reward: product?.reward,
          productId: product?.id,
          productType: product?.type,
          productModelId: product?.modelId,
          productName: product?.name,
          productSerial: product?.serial,
          productPrice: product?.price,
          productCreatedAt: product?.createdAt,
          productUserId: product?.userId,
          productFirstProductId: product?.firstProductId,
          product_model_id: product?.model?.id,
          product_model_code: product?.model?.code,
          product_model_name: product?.model?.name,
          uuid: product?.uuid,
          checked: checked,
          description: '',
          status: product?.status,
          endDate: value?.endDate,
          finishTimeDisplayDocumentation: value?.finishTimeDisplayDocumentation
        }
        total += product?.price
        totals[clientData.id] = total
        flatDatas.push(flattenedProduct)
      })
    })
    setFlatData(flatDatas)
    setconstanceFlatData(flatDatas)

    console.log('flatDataUseEffect', flatData)
  }, [listTable])

  useEffect(() => {
    handleGetListTable()
  }, [filter])

  useEffect(() => {
    if (edit == true) {
      listTable.map((item, index) => {
        form.setFieldsValue({
          [item.id]: item.point
        })
      })
    }
  }, [edit])

  useEffect(() => {
    if (edit == true) {
      listTable.map((item, index) => {
        form.setFieldsValue({
          [item.id]: item.point
        })
      })
    }
    listDataChange.map((item, index) => {
      form.setFieldsValue({
        [item.id]: item.point
      })

      handleCheckPoint(item.id, item.point)
    })
  }, [listTable])

  useEffect(() => {
    const data = form.getFieldsError()
    const check = []
    data.map((item) => {
      if (item.errors.length > 0) {
        check.push(false)
      } else {
        check.push(true)
      }
    })

    if (check.includes(false)) {
      setDisabledSubmit(true)
    } else {
      setDisabledSubmit(false)
    }
  }, [watchForm])

  const handleGetListTable = async () => {
    try {
      const res = await getReportAccountingSearchProgramingByRewardTypeAPI({ id: id.id, page: page })

      if (res.data) {
        setListTable(res.data)
      }

      if (res.meta) {
        setTotal(res.meta.totalItems)
      }

      if (res.value) {
        setValue(res.value)
      }
    } catch (error) {
      toast(error.response.data.message, { type: 'error' })
    }
  }

  const goToPage = (page) => {
    setPage(page)
    handleGetListTable({ ...filter, page })
    setFilter({ ...filter, page })
  }

  console.log('', flatData)
  // console.log('calculateTotalReward', calculateTotalReward)

  const columns = useMemo(
    () => [
      {
        Header: 'STT',
        accessor: 'stt',
        filterable: true,
        disableSortBy: true,
        // enableRowSpan: true,
        hideSort: true,
        Cell: (cellProps) => {
          return (page - 1) * 10 + cellProps.row.index + 1
        }
      },
      {
        Header: 'Loại TK',
        accessor: (cell) => {
          // console.log(cell)
          return `${cell.id}-${cell.clientType}`
        },
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        enableRowSpan: true,
        Cell: (cellProps) => {
          return <Type {...cellProps} />
        }
      },
      {
        Header: 'Tên tài khoản',
        accessor: (cell) => {
          return `${cell.id}-${cell.clientName}`
        },
        enableRowSpan: true,
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <UserShopName {...cellProps} />
        }
      },
      {
        Header: 'SĐT',
        // accessor: 'clientPhone',
        accessor: (cell) => {
          return `${cell.id}-${cell.clientPhone}`
        },
        enableRowSpan: true,
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <StringGrp {...cellProps} />
        }
      },
      {
        Header: 'Tổng tiền',
        enableRowSpan: true,
        accessor: (cell) => {
          // console.log(cell)
          return `${cell.id}-${cell.totalReward}`
        },
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <NumberGrp {...cellProps} />
        }
      },
      {
        Header: 'Serial',
        accessor: 'productSerial',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <BaseCol {...cellProps} />
        }
      },
      {
        Header: 'Model',
        accessor: 'product_model_name',
        // enableRowSpan: true,
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <BaseCol {...cellProps} />
        }
      },
      {
        Header: 'Ngày xét thưởng',
        accessor: 'endDate',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <DateFormat {...cellProps} />
        }
      },
      {
        Header: 'Tạm tính',
        accessor: 'reward',
        filterable: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          return <BaseMoney {...cellProps} />
        }
      },
      {
        Header: 'Trạng thái',
        accessor: 'status',
        disableFilters: true,
        disableSortBy: true,
        hideSort: true,
        Cell: (cellProps) => {
          // return <Badge className='badge-soft-info'>{'Chờ đối soát'}</Badge>
          return <RewardStatus {...cellProps} />
        }
      },
      {
        Header: 'Từ chối',
        accessor: (cell) => {
          return `${cell.id},${cell.productId},${cell.uuid},${cell.checked}`
        },
        Cell: (cellProps) => {
          return (
            <Checkbox
              checked={JSON.parse(cellProps?.value?.split(',')[3])}
              onChange={() => handleUpdateData(cellProps.value, cellProps)}
            />
          )
        }
      },
      {
        Header: 'Lý do từ chối',
        accessor: (cell) => {
          return `${cell.id},${cell.productId},${cell.uuid},${cell.checked}`
        },
        Cell: (cellProps) => {
          return (
            <FormInput
              maxLength={50}
              value={descriptionState[cellProps.row.id] || ''}
              disabled={!JSON.parse(cellProps?.value?.split(',')[3])}
              onChange={(e) => {
                handleUpdateDescription(cellProps.value, e.target.value)
                HandleSetDescription(cellProps.row.id, e.target.value.trim())
              }}
              onBlur={(e) => {
                HandleSetDescription(cellProps.row.id, e.target.value.trim())
              }}
            />
          )
        }
      }
    ],
    [edit, listTable, tasks, flatData, checked, descriptionState]
  )

  const handleCheckPoint = (id, value) => {
    if (value == null) {
      form.setFields([{ name: `${id}`, errors: ['Vui lòng nhập điểm'] }])
    } else if (handleCheckNguyen(value) == false) {
      form.setFields([{ name: `${id}`, errors: ['Điểm là số nguyên dương.'] }])
    } else {
      form.setFields([{ name: `${id}`, errors: null }])
    }
    const data = form.getFieldsValue()
    const list = [...listDataChange]
    listTable.map((item, index) => {
      if (item.point != data[item.id]) {
        const find = list.find((x) => x.id == item.id)
        if (find) {
          find.point = data[item.id]
        } else {
          list.push({
            id: item.id,
            point: data[item.id]
          })
        }
      }
    })
    setListDataChange(list)
  }

  const handleSave = async () => {
    const check = []
    listDataChange.map((item) => {
      if (item.point == '' || item.point == null) {
        check.push(false)
      } else {
        check.push(true)
      }
    })

    if (check.includes(false)) {
      return toast('Vui lòng nhập đẩy đủ các điểm ở các trang.', { type: 'error' })
    }

    try {
      const res = await updatePointConfigAPI({ tasks: listDataChange })

      if (res) {
        toast('Cập nhật thành công điểm.', { type: 'success' })
        setEdit(false)
        setListDataChange([])
        handleGetListTable()
      }
    } catch (error) {
      toast(error.response.data.message, { type: 'error' })
    }
  }

  const handleCheckNguyen = (value) => {
    if (Number(value) > 0 && Number.isInteger(Number(value))) {
      return true
    } else {
      return false
    }
  }

  const handleClickExport = async () => {
    const res = await exportByProductAPI({ ...filter })
    fileDownload(res, `${moment().format('yyMMDD')}_Tam tinh thuong theo san pham.xlsx`)
  }

  const handleFilter = (filterValue) => {
    // setFilter({ ...filterValue, sort_field: '', sort_order: '' })
    const keyWord = filterValue?.search?.toLowerCase() || ''
    const phone = filterValue?.phone?.toLowerCase() || ''
    const serial = filterValue?.serial?.toLowerCase() || ''

    if (filterValue.search.length > 0) {
      const filteredResults = constanceFlatData.filter(
        (obj) =>
          obj.clientName.toLowerCase().includes(keyWord) &&
          obj.clientPhone.toLowerCase().includes(phone) &&
          obj.productSerial.toLowerCase().includes(serial)
      )

      setFlatData(filteredResults)
    } else {
      handleGetListTable({ sort_field: '', sort_order: '' })
    }
    setPage(1)
  }

  const HandleSetDescription = (cell, description) => {
    setDescriptionState({ ...descriptionState, [cell]: description })
  }

  const handleUpdateData = useCallback(
    (cellProp, rowdata) => {
      const cellPropvalue = cellProp?.split(',')

      //lấy giá trị hiện tại được check trong flatData
      const currentRow = flatData.filter(
        (item) =>
          item.uuid.includes(cellPropvalue[2]) &&
          item.id == Number(cellPropvalue[0]) &&
          item.productId == Number(cellPropvalue[1])
      )

      //update lại giá trị checked của flatData
      flatData.forEach((item) => {
        if (
          item.id == Number(cellPropvalue[0]) &&
          item.productId == Number(cellPropvalue[1]) &&
          item.uuid == cellPropvalue[2]
        ) {
          item.checked = !JSON.parse(currentRow[0]?.checked)
        }
      })
      //xoá hoặc thêm vào tasks
      if (JSON.parse(currentRow[0]?.checked) == true) {
        setTasks((prevTasks) => [
          ...prevTasks,
          {
            user_id: Number(cellPropvalue[0]),
            product_id: Number(cellPropvalue[1]),
            uuid: cellPropvalue[2],
            description: currentRow[0]?.description
          }
        ])
      } else {
        setTasks((prevTasks) => {
          return prevTasks.filter(
            (item) =>
              item.uuid !== cellPropvalue[2] ||
              item.user_id !== Number(cellPropvalue[0]) ||
              item.product_id !== Number(cellPropvalue[1])
          )
        })
      }
    },
    [tasks, flatData, checked]
  )

  const handleUpdateDescription = useCallback(
    (cellProp, data) => {
      const cellPropvalue = cellProp?.split(',')
      const currentRow = flatData.filter(
        (item) =>
          item.uuid.includes(cellPropvalue[2]) &&
          item.id == Number(cellPropvalue[0]) &&
          item.productId == Number(cellPropvalue[1])
      )

      //update lại giá trị description của flatData
      flatData?.forEach((item) => {
        if (
          item.id == Number(cellPropvalue[0]) &&
          item.productId == Number(cellPropvalue[1]) &&
          item.uuid == cellPropvalue[2]
        ) {
          item.description = data
        }
      })

      const existedTask = tasks.filter(
        (item) =>
          item.user_id == Number(cellPropvalue[0]) &&
          item.product_id == Number(cellPropvalue[1]) &&
          item.uuid == cellPropvalue[2]
      )
      if (existedTask.length > 0) {
        existedTask.forEach((item) => {
          item.description = data
        })
      }
      console.log('flatData_handleUpdateDescription', flatData)
      console.log('tasks_handleUpdateDescription', tasks)
    },
    [tasks, flatData]
  )

  const handleConfirm = async () => {
    try {
      const res = await confirmAccountingReconcileByProductAPI({ id: id.id, tasks: tasks })

      if (res) {
        toast('Xác nhận trả thưởng thành công', { type: 'success' })
      }
    } catch (error) {
      toast(error.response.data.message, { type: 'error' })
    }
  }

  const handleResetFilter = () => {
    handleGetListTable({ sort_field: '', sort_order: '' })
    setPage(1)
  }

  const handleExport = async (filterValue) => {
    setLoading(true)
    try {
      const res = await exportReportAccountingProgrammingByRewardTypeAPI({
        id: id.id
      })
      if (res?.data?.success === false) {
        toast(res?.message, { type: 'error' })
      } else {
        toast('Hệ thống đang xử lý. Tệp của bạn đã được gửi tới email ' + profile?.email + ' trong vài phút', {
          type: 'success'
        })
      }
    } catch (error) {
      toast(error.response.data.message, { type: 'error' })
    }
    setLoading(false)
  }

  // const handleCheckExport = async (data) => {
  //   try {
  //     const res = await checkExportFileAPI(data)

  //     if (res?.data?.status === 'pending') {
  //       setTimeout(() => {
  //         handleCheckExport(data)
  //       }, 2000)
  //     } else {
  //       const nameFile = `${moment().format('yyMMDD')}_Doi soat theo goi san pham.xlsx`
  //       const link = document.createElement('a')
  //       link.href = res?.data?.fileName
  //       link.setAttribute('download', nameFile)
  //       document.body.appendChild(link)
  //       link.click()
  //       toast('Export file thành công!', { type: 'success' })
  //     }
  //   } catch (error) {
  //     toast(error.response.data.message, { type: 'error' })
  //   }
  // }

  const handleImport = async () => {
    setShowImport(true)
  }

  const uploadFile = async (files) => {
    setLoadingImport(true)
    let formData = new FormData()
    let type = ''
    if (value?.configType === 'product') {
      type = 'reconcile_accounting_by_product'
    } else if (value?.configType === 'revenue') {
      type = 'reconcile_accounting_by_revenue'
    }

    formData.append('file', files[0])
    formData.append('id', id.id)
    formData.append('type', type)

    try {
      const res = await importFileAPI(url.IMPORT_RECONCILE_ACCOUNTING, formData)
      if (res) {
        handleCheckUpload(res.data.data)
      }
    } catch (error) {
      toast(error.response.data.message, { type: 'error' })
      setLoadingImport(false)
    }
  }

  const handleCheckUpload = async (data) => {
    try {
      const res = await checkUploadFileAPI(data)

      if (!res.data.queueFinish) {
        setTimeout(() => {
          handleCheckUpload(data)
        }, 2000)
      } else {
        if (res.data.countFailed > 0) {
          // handleExportFileError(data)
          setShowImport(false)
          setLoadingImport(false)
        }
        toast('Hệ thống đang xử lý . Vui lòng kiểm tra thông tin tại màn hình Lịch sử', { type: 'success' })
        handleGetListTable({ sort_field: '', sort_order: '' })
        setPage(1)
        setLoadingImport(false)
        setShowImport(false)
      }
    } catch (error) {
      toast(error.response.data.message, { type: 'error' })
      setLoadingImport(false)
    }
  }

  const handleExportFileError = async (data) => {
    try {
      const res = await exportFileError(data)

      if (res) {
        const nameFile = `${moment().format('yyMMDD')}_FileError.xlsx`
        const url = window.URL.createObjectURL(new Blob([res]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', nameFile) //or any other extension
        document.body.appendChild(link)
        link.click()
      }
    } catch (error) {
      toast(error.response.data.message, { type: 'error' })
      setShowImport(false)
      setLoadingImport(false)
    }
  }

  const handleClickDownloadTemplate = async () => {
    try {
      let type = ''
      if (value?.configType === 'product') {
        type = 'reconcile_accounting_by_product'
      } else if (value?.configType === 'revenue') {
        type = 'reconcile_accounting_by_revenue'
      }

      const res = await downloadTemplateReconcileAccountingAPI({
        id: id.id,
        type: type
      })
      const nameFile = `Bieu_mau_import_xac_nhan_tra_thuong.xlsx`
      const url = window.URL.createObjectURL(new Blob([res]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', nameFile) //or any other extension
      document.body.appendChild(link)
      link.click()
      toast('Tải biểu mẫu thành công!', { type: 'success' })
    } catch (error) {
      toast(error.response.data.message, { type: 'error' })
    }
  }

  return (
    <div className='page-content'>
      <div className='page-title-box container-fluid'>
        {/* <h4 className=' mb-sm-0 font-size-18 pb-4'>Ghi nhận thanh toán</h4> */}
        <Breadcrumb title={'Ghi nhận thanh toán'} breadcrumbItem={'Ghi nhận thanh toán'} />
        {isLoading ? (
          <Spinners setLoading={setLoading} />
        ) : (
          <Row>
            <Col lg='12'>
              <Card>
                <CardBody>
                  <Space size='middle' direction='vertical' className='w-100'>
                    <ConfirmProgramByRewardTypeFilter
                      handleFilter={handleFilter}
                      handleConfirm={handleConfirm}
                      handleResetFilter={handleResetFilter}
                      handleExport={handleExport}
                      handleImport={handleImport}
                    />
                    <div className='mb-xxl-0 me-3 mt-2'>
                      <h5 className='mb-0 card-title flex-grow-1'>{'Danh sách báo cáo'}</h5>
                    </div>
                    <div className='d-flex flex-row '>
                      <div className='mb-xxl-0'>
                        <button className='btn btn-info btn-rounded me-2 my-2'>{value?.code}</button>
                      </div>
                      <div className='mb-xxl-0'>
                        {value?.provinces?.map((item, index) => (
                          <button className='btn btn-light btn-rounded me-2 my-2' key={index} value={item?.id}>
                            {item?.name}
                          </button>
                        ))}
                      </div>
                    </div>
                    <TableRowSpan
                      columns={columns}
                      data={flatData}
                      pagination={pagination}
                      setPagination={setPagination}
                      gotoPageCustom={goToPage}
                      currentPage={page}
                      total={total}
                      isShowTotal={true}
                    />
                  </Space>
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
      </div>
      {showImport && (
        <ImportModal
          onCloseClick={() => {
            setShowImport(false)
          }}
          show={showImport}
          onConfirmClick={uploadFile}
          loading={isLoadingImport}
          title={'Import xác nhận trả thưởng'}
          onDownloadClick={handleClickDownloadTemplate}
        />
      )}
    </div>
  )
}

export default withRouter(ProgramByRewardType)
