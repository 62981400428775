export const GET_MANAGE_UNIT = "GET_UNIT_LIST"
export const GET_MANAGE_UNIT_FAIL = "GET_MANAGE_UNIT_FAIL"
export const GET_MANAGE_UNIT_SUCCESS = "GET_MANAGE_UNIT_SUCCESS"

export const GET_UNIT_DETAIL = "GET_UNIT_DETAIL"
export const GET_UNIT_DETAIL_SUCCESS = "GET_UNIT_DETAIL_SUCCESS"
export const GET_UNIT_DETAIL_FAIL = "GET_UNIT_DETAIL_FAIL"

export const UPDATE_MANAGE_UNIT = "UPDATE_MANAGE_UNIT"
export const UPDATE_MANAGE_UNIT_SUCCESS = "UPDATE_MANAGE_UNIT_SUCCESS"
export const UPDATE_MANAGE_UNIT_FAIL = "UPDATE_MANAGE_UNIT_FAIL"

export const SET_TYPE_POPUP = "SET_TYPE_POPUP_UNIT"
export const SET_FILTERS_UNIT = "SET_FILTERS_UNIT"

export const GET_DEPARTMENT_ALL = "GET_DEPARTMENT_ALL"
export const GET_DEPARTMENT_ALL_FAIL = "GET_DEPARTMENT_ALL_FAIL"
export const GET_DEPARTMENT_ALL_SUCCESS = "GET_DEPARTMENT_ALL_SUCCESS"
export const GET_DEPARTMENT_ALL_LIST = "GET_DEPARTMENT_ALL_LIST"
