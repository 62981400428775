import React from "react"
import { Badge, UncontrolledTooltip } from "reactstrap"
import { Link } from "react-router-dom"
import { ManageKTVVN } from "constants/vn"
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import { formateDate } from "helpers/global_helper"

const KTVNo = cell => {
    return (
        <Link to="#" className="text-body fw-bold">
            {cell?.row ? cell.row.index + 1 : ""}
        </Link>
    )
}

const KTVID = cell => {
    return cell.value ? cell.value : "--"
}

const AccountName = cell => {
    return cell.value ? (
        <div>
            <span style={{ maxWidth: 300 }} className="d-inline-block text-truncate" id={`nameshop-tooltip-${cell.row.original.employeeCode}`}>
                {cell.value}
            </span>
            <UncontrolledTooltip trigger="hover" placement="top" target={`nameshop-tooltip-${cell.row.original.employeeCode}`}>
                {cell.value}
            </UncontrolledTooltip>
        </div>
    ) : (
        "--"
    )
}

const Phone = cell => {
    return cell.value ? cell.value : "--"
}

const Location = cell => {
    return cell.value ? cell.value : "--"
}

const Email = cell => {
    return cell.value ? cell.value : "--"
}

const CreatedDate = cell => {
    return cell.value ? formateDate(cell.value) : "--"
}

const Status = cell => {
    switch (cell.value) {
        case "pending":
            return <Badge className="badge-soft-primary">{ManageKTVVN.statusOptions[0].label}</Badge>
        case "active":
            return <Badge className="badge-soft-success">{ManageKTVVN.statusOptions[1].label}</Badge>
        case "inactive":
            return <Badge className="badge-soft-warning">{ManageKTVVN.statusOptions[2].label}</Badge>
        case "terminated":
            return <Badge className="badge-soft-secondary">{ManageKTVVN.statusOptions[3].label}</Badge>
        case "pending-removal":
            return <Badge className="badge-soft-danger">{ManageKTVVN.statusOptions[4].label}</Badge>
    }
}

export { KTVNo, KTVID, AccountName, Location, Email, Status, CreatedDate, Phone }
