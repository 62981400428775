import {
    GET_MANAGE_SHOP_FAIL,
    GET_MANAGE_SHOP_SUCCESS,
    GET_SHOP_DETAIL_SUCCESS,
    GET_SHOP_DETAIL_FAIL,
    UPDATE_MANAGE_SHOP_SUCCESS,
    UPDATE_MANAGE_SHOP_FAIL,
} from "./actionTypes"

const INIT_STATE = {
    shops: [],
    shopDetail: undefined,
    error: {},
    manageShopApply: [],
    loading: true,
    total: 0,
}

const ShopReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_MANAGE_SHOP_SUCCESS:
            return {
                ...state,
                shops: action.payload.data,
                total: action.payload.meta.totalItems,
                loading: true,
            }

        case GET_MANAGE_SHOP_FAIL:
            return {
                ...state,
                error: action.payload,
            }

        case GET_SHOP_DETAIL_SUCCESS:
            return {
                ...state,
                shopDetail: action.payload,
            }

        case GET_SHOP_DETAIL_FAIL:
            return {
                ...state,
                error: action.payload,
            }

        case UPDATE_MANAGE_SHOP_SUCCESS:
            return {
                ...state,
                shops: state.shops.map(manageShop =>
                    manageShop.id.toString() === action.payload.id.toString() ? { manageShop, ...action.payload } : manageShop
                ),
            }

        case UPDATE_MANAGE_SHOP_FAIL:
            return {
                ...state,
                error: action.payload,
            }

        default:
            return state
    }
}

export default ShopReducer
