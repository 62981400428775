import React, { useEffect, useState, useLayoutEffect, useRef, useMemo } from 'react'
import { Card, CardBody, Col, Container, Input, Label, Row } from 'reactstrap'
import './styles.scss'

//redux
import { useDispatch } from 'react-redux'
import withRouter from 'components/Common/withRouter'

//Import Breadcrumb
import Breadcrumb from '../../components/Common/Breadcrumb'
import { ProductActivationVN, UnitVN, UserVN } from 'constants/vn'
import { Status } from './ManageUserCol'

// styles
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css'
import { setTypePopupUser } from 'store/actions'
import { createSelector } from 'reselect'
import { useSelector } from 'react-redux'
import ConfirmModal from 'components/Common/ConfirmModal'
import {
  getRoleListAll,
  getDepartmentListAPI,
  getZoneListAll,
  getSubZoneListAll,
  getProvinceListAll,
  postImage,
  postCreateUserAPI,
  checkRoleManagement,
  getUserDetailsAPI,
  postLockAccountAPI,
  putChangePasswordAccountAPI,
  updateUserAPI,
  getDistrictListAll,
  getWardListAll,
  getPortfolioAllAPI
} from 'helpers/backend_helper'
import { checkFileSizeValid } from 'helpers/global_helper'
import { toast } from 'react-toastify'
import { REGEX_EMAIL, REGEX_NUMBER, REGEX_NUMBER_VIET_NAM } from 'constants/regex'
import '../ManageShop/shopDetail.scss'
import { Form, Select, Tree, Input as InputAntd, Spin, Image } from 'antd'
import { FormSelect } from 'components/Common/form-select/FormSelect'
const { TextArea } = InputAntd
import { isEqual } from 'lodash'
import { USER_ACCOUNT_MANAGEMENT_CODE, SCREEN_CODE } from 'constants/permission-code'
import PermissionWrapper from 'components/Common/PermissionWrapper/PermissionWrapper'

const UserAddEditDetail = (props) => {
  //meta title
  document.title = 'KTV Detail | HPG'

  const params = props.router.params
  const navigate = props.router.navigate

  const [form] = Form.useForm()
  const watchForm = Form.useWatch([], form)

  const dispatch = useDispatch()
  const screenCode = useMemo(() => SCREEN_CODE.USER_ACCOUNT_MANAGEMENT, [])

  const selectUserDetailState = (state) => state.UserReducer
  const UserDetailProperties = createSelector(selectUserDetailState, (user) => ({
    type: user.type
  }))
  const { type } = useSelector(UserDetailProperties)

  //get profile
  const selectProfileState = (state) => state.Profile
  const ProfileProperties = createSelector(selectProfileState, (profile) => ({
    error: profile.error,
    success: profile.success,
    profile: profile.profile
  }))

  const { profile } = useSelector(ProfileProperties)

  const [showSubmitCancel, setShowSubmitCancel] = useState(false)
  const [showChangePassword, setShowChangePassword] = useState(false)
  const [showLockAccount, setShowLockAccount] = useState(false)
  const [valueNote, setValueNote] = useState()

  const [optionPermissionGroup, setOptionPermissionGroup] = useState([])
  const [checkRole, setCheckRole] = useState()

  // tree data department
  const [treeDataDepartment, setTreeDataDepartment] = useState([])
  const [treeExpand, setTreeExpand] = useState(0)
  const [treeSelect, setTreeSelect] = useState()

  // tree data zone
  const [treeDataZone, setTreeDataZone] = useState([])
  const [treeCheck, setTreeCheck] = useState([])
  const [treeCheckId, setTreeCheckId] = useState([])

  // upload
  const [loading, setLoading] = useState(false)
  const [imageUrl, setImageUrl] = useState()

  const [errPart, setErrPart] = useState(false)
  const [errManagementArea, setErrManagementArea] = useState(false)

  const [disabledSubmit, setDisabledSubmit] = useState(true)
  const [showChangeRole, setShowChangeRole] = useState(false)
  const [nodeSelect, setNodeSelect] = useState()
  const [industry, setIndustry] = useState()
  const [initData, setInitData] = useState()

  // Detail
  const [userDetail, setUserDetail] = useState()
  const isEdit = type != 'view' ? true : false

  const uploadRef = useRef()

  useEffect(() => {
    handleGetRoleListAll()
    handleGetZoneListAll()
  }, [])

  useLayoutEffect(() => {
    let parent_id_call
    try {
      const split = treeExpand.split('-')
      parent_id_call = split[split.length - 1]
    } catch (error) {
      parent_id_call = treeExpand
    }
    handleGetDepartmentList(parent_id_call)
  }, [treeExpand])

  useEffect(() => {
    form.validateFields({ validateOnly: true }).then(
      () => {
        setDisabledSubmit(false)
      },
      () => {
        setDisabledSubmit(true)
      }
    )
  }, [watchForm])

  useEffect(() => {
    const split = props.router.location.pathname.split('/')

    if (split[2] != 'create') {
      handleGetUserDetail()
    }
    dispatch(setTypePopupUser(split[2]))
  }, [])

  useEffect(() => {
    getDataOptions()
  }, [])

  const getDataOptions = async () => {
    try {
      const resIndustry = await getPortfolioAllAPI({ type: 'industry' })
      setIndustry(resIndustry?.data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (userDetail) {
      form.setFieldsValue({
        name: userDetail.name,
        phone: userDetail.phone,
        email: userDetail.email,
        code: userDetail.employeeCode,
        permissionGroup: userDetail.roleId,
        industry_id: userDetail?.industry?.id
      })

      setImageUrl(userDetail.image)

      const part_key = getDepartmentKeys(userDetail.department)
      setTreeSelect(part_key.join('-'))

      handleGetCheckRoleManagement(userDetail.roleId, userDetail.id)

      const detailProvinceId = userDetail?.locationManagement?.map((item) => item.location.id)
      const province_key = getKeysByProvinceIds(treeDataZone, detailProvinceId)
      setTreeCheck(province_key)

      setTreeCheckId(detailProvinceId)

      setInitData({
        name: userDetail.name,
        phone: userDetail.phone,
        email: userDetail.email,
        code: userDetail.employeeCode,
        permissionGroup: userDetail.roleId,
        part: part_key.join('-'),
        managementArea: province_key,
        industry_id: userDetail?.industry?.id
      })
    }
  }, [userDetail, treeDataZone])

  const handleGetUserDetail = async () => {
    setLoading(true)
    try {
      const res = await getUserDetailsAPI({ id: params.id })

      if (res.data) {
        setUserDetail(res.data)
      }
    } catch (error) {
      toast(error.response.data.message, { type: 'error' })
    }
    setLoading(false)
  }

  const handleGetRoleListAll = async () => {
    try {
      const res = await getRoleListAll()

      if (res.data) {
        const list_option = []
        res.data.map((item) => {
          list_option.push({
            value: item.id,
            label: item.name
          })
        })

        setOptionPermissionGroup(list_option)
      }
    } catch (error) {
      toast(error.response.data.message, { type: 'error' })
    }
  }

  const handleGetCheckRoleManagement = async (value, id = null) => {
    const res = await checkRoleManagement({
      role_id: value,
      id: id
    })

    if (!res.meta.allowDuplicateArea) {
      if (res.data.length) {
        const list_data = updateTitles(treeDataZone, res.data)
      }
    }
    setCheckRole(res)
  }

  function updateTitles(data1, data2) {
    // Lặp qua mảng data2
    data2.forEach((entry2) => {
      const provinceIdToMatch = entry2.provinceId

      // Tìm kiếm province tương ứng trong data1
      const provinceToUpdate = findProvinceById(data1, provinceIdToMatch)

      // Nếu tìm thấy province, thay đổi title
      if (provinceToUpdate) {
        provinceToUpdate.title = <span style={{ background: '#bbedf4' }}>{provinceToUpdate.title}</span>
      }
    })

    return data1
  }

  // Hàm tìm province theo province_id
  function findProvinceById(data, targetId) {
    for (const entry of data) {
      if (entry.children) {
        // Nếu có children, đệ quy để tìm trong children
        const foundInChildren = findProvinceById(entry.children, targetId)
        if (foundInChildren) {
          return foundInChildren
        }
      }

      // Kiểm tra province_id
      if (entry.province_id === targetId) {
        return entry
      }
    }

    return null // Trả về null nếu không tìm thấy
  }

  function getKeysByProvinceIds(data1, locationIds) {
    const keys = []

    data1.map((item_zone) => {
      if (item_zone.children) {
        item_zone.children.map((item_subZone) => {
          if (item_subZone.children) {
            item_subZone.children.map((item_province) => {
              if (item_province.children) {
                item_province.children.map((item_district) => {
                  locationIds?.map((item_id) => {
                    if (item_id == item_district.district_id) {
                      keys.push(item_district.key)
                    }
                  })
                })
              }
            })
          }
        })
      }
    })
    return keys
  }

  const handleGetDepartmentList = async (parent_id) => {
    if (parent_id === 0) {
      try {
        const res = await getDepartmentListAPI({ parent_id: parent_id })

        const filterData = res?.data?.filter((e) => e.id == profile?.industry?.id)

        if (filterData) {
          if (treeExpand) {
            const key_split = treeExpand.split('')
            const list = filterData.map((item) => {
              return {
                id: item.id,
                title: item.name,
                key: `${treeExpand}-${item.id}`,
                value: `${treeExpand}-${item.id}`,
                isLeaf: !item.haveChildren,
                selectable: !item.haveChildren
              }
            })
            const update = updateTreeData(treeDataDepartment, treeExpand, list)
            setTreeDataDepartment(update)
          } else {
            const list = filterData.map((item) => {
              return {
                id: item.id,
                title: item.name,
                key: `${item.id}`,
                value: `${item.id}`,
                isLeaf: !item.haveChildren,
                selectable: !item.haveChildren
              }
            })
            setTreeDataDepartment([
              {
                id: 0,
                title: (
                  <span style={{ fontFamily: 'Avenir' }} className='mb-0 card-title flex-grow-1 font-weight-bold'>
                    {UnitVN.tree}
                  </span>
                ),
                key: '0',
                value: '0',
                selectable: false,
                children: [...list]
              }
            ])
          }
        }
      } catch (error) {
        if (error.response) {
          toast(error.response.data.message, { type: 'error' })
        } else {
          toast('Có lỗi xảy ra, vui lòng thử lại!', { type: 'error' })
        }
      }
    } else {
      try {
        const res = await getDepartmentListAPI({ parent_id: parent_id })

        if (res.data) {
          if (treeExpand) {
            const key_split = treeExpand.split('')
            const list = res.data.map((item) => {
              return {
                id: item.id,
                title: item.name,
                key: `${treeExpand}-${item.id}`,
                value: `${treeExpand}-${item.id}`,
                isLeaf: !item.haveChildren,
                selectable: !item.haveChildren
              }
            })
            const update = updateTreeData(treeDataDepartment, treeExpand, list)
            setTreeDataDepartment(update)
          } else {
            const list = res.data.map((item) => {
              return {
                id: item.id,
                title: item.name,
                key: `${item.id}`,
                value: `${item.id}`,
                isLeaf: !item.haveChildren,
                selectable: !item.haveChildren
              }
            })
            setTreeDataDepartment([
              {
                id: 0,
                title: (
                  <span style={{ fontFamily: 'Avenir' }} className='mb-0 card-title flex-grow-1 font-weight-bold'>
                    {UnitVN.tree}
                  </span>
                ),
                key: '0',
                value: '0',
                selectable: false,
                children: [...list]
              }
            ])
          }
        }
      } catch (error) {
        if (error.response) {
          toast(error.response.data.message, { type: 'error' })
        } else {
          toast('Có lỗi xảy ra, vui lòng thử lại!', { type: 'error' })
        }
      }
    }
  }

  const onLoadData = ({ key, children }) => {
    let key_expand
    const key_split = key.split('-')
    if (key_split.length > 1) {
      key_expand = key_split[key_split.length - 1]
    } else {
      key_expand = key
    }
    return new Promise((resolve) => {
      if (children) {
        resolve()
        return
      }
      setTreeExpand(key)
      resolve()
    })
  }

  const updateTreeData = (list, key, children) => {
    return list.map((node) => {
      if (node.key === key) {
        return {
          ...node,
          children
        }
      }
      if (node.children) {
        return {
          ...node,
          children: updateTreeData(node.children, key, children)
        }
      }
      return node
    })
  }

  const getDepartmentKeys = (department, keys = []) => {
    if (department && department.id) {
      keys.unshift(department.id) // Thêm id của department vào đầu mảng keys
      if (department.parent) {
        getDepartmentKeys(department.parent, keys) // Gọi đệ quy để lấy keys của parent department
      }
    }
    return keys
  }

  // func zone
  const handleGetZoneListAll = async () => {
    try {
      const res = await getZoneListAll()
      const list_data = []

      const processZoneItem = (item) => ({
        title: item.name,
        key: item.id,
        children: [],
        selectable: false
      })

      const processSubZoneItem = (item) => ({
        title: item.name,
        key: `${item.zoneId}-${item.id}`,
        children: [],
        selectable: false
      })

      const processProvinceItem = (item) => ({
        title: item.name,
        key: `${item.zoneId}-${item.subZoneId}-${item.id}`,
        province_id: item.id,
        children: [],
        selectable: false
      })

      const processDistrictItem = (item) => ({
        title: item.name,
        key: `${item.zoneId}-${item.subZoneId}-${item.provinceId}-${item.id}`,
        district_id: item.id,
        children: [],
        selectable: false
      })

      const processWardItem = (item) => ({
        title: item.name,
        key: `${item.zoneId}-${item.subZoneId}-${item.provinceId}-${item.districtId}-${item.id}`,
        ward_id: item.id,
        selectable: false
        // children: []
      })

      if (res.data) {
        res.data.forEach((item_zone) => {
          list_data.push(processZoneItem(item_zone))
        })

        const resSubZone = await getSubZoneListAll()

        if (resSubZone.data) {
          resSubZone.data.forEach((item_subZone) => {
            const index_zone = list_data.findIndex((item) => item.key === item_subZone.zoneId)

            if (index_zone >= 0) {
              list_data[index_zone].children.push(processSubZoneItem(item_subZone))
            }
          })

          const resProvince = await getProvinceListAll()

          if (resProvince.data) {
            resProvince.data.forEach((item_province) => {
              const index_zone = list_data.findIndex((item) => item.key === item_province.zoneId)
              const index_sub_zone = list_data[index_zone]?.children.findIndex(
                (item) => item.key === `${item_province.zoneId}-${item_province.subZoneId}`
              )

              if (index_zone >= 0 && index_sub_zone >= 0) {
                list_data[index_zone].children[index_sub_zone].children.push(processProvinceItem(item_province))
              }
            })

            const resDistrict = await getDistrictListAll()

            if (resDistrict.data) {
              const districtList = resDistrict.data.map((district) => {
                const province = resProvince.data.find((prov) => prov.id === district.parentId)
                return {
                  ...district,
                  zoneId: province?.zoneId,
                  subZoneId: province?.subZoneId,
                  provinceId: province?.id
                }
              })

              districtList?.forEach((item_district) => {
                const index_zone = list_data.findIndex((item) => item.key === item_district?.zoneId)

                const index_sub_zone = list_data[index_zone]?.children.findIndex(
                  (item) => item.key === `${item_district?.zoneId}-${item_district?.subZoneId}`
                )
                const index_province = list_data[index_zone]?.children[index_sub_zone]?.children.findIndex(
                  (item) =>
                    item.key === `${item_district?.zoneId}-${item_district?.subZoneId}-${item_district?.provinceId}`
                )

                if (index_zone >= 0 && index_sub_zone >= 0 && index_province >= 0) {
                  list_data[index_zone].children[index_sub_zone].children[index_province].children.push(
                    processDistrictItem(item_district)
                  )
                }
              })
            }
          }
        }
      }
      setTreeDataZone(list_data)
    } catch (error) {
      toast(error.response.data.message, { type: 'error' })
    }
  }

  // func upload
  const hanleFileChange = (e) => {
    setLoading(true)
    const file = e?.target?.files[0]
    const fileValid = checkFileSizeValid(file)
    if (fileValid) {
      handleAcceptedFiles(file)
    } else {
      toast('Ảnh vượt quá dung lượng cho phép (5MB). Vui lòng chọn ảnh khác', {
        type: 'error'
      })
    }
  }

  const handleAcceptedFiles = async (file, name) => {
    let formData = new FormData()
    formData.append('file', file)

    try {
      const res = await postImage(formData)
      if (res) {
        setImageUrl(res.data.data)
        toast(res.message, { type: 'success' })
      }
    } catch (error) {
      toast(error.response.data.message, { type: 'error' })
    }

    setLoading(false)
  }

  const handleValidatePart = (value) => {
    if (value) {
      setErrPart(false)
    } else {
      setErrPart(true)
    }
  }

  const handleChangeManagement = (value, checkedNodesValue) => {
    if (value.length) {
      setErrManagementArea(false)
    } else {
      setErrManagementArea(true)
    }

    const list_data = []
    if (!checkRole?.meta.allowDuplicateArea && checkedNodesValue.checked) {
      if (checkRole.data.length) {
        if (checkedNodesValue.node.children) {
          checkedNodesValue.node.children.map((item_children) => {
            const find = checkRole.data.find((item_find) => item_find.provinceId == item_children.province_id)

            if (find) {
              setShowChangeRole(true)
            }
          })
        } else {
          const find = checkRole.data.find((item_find) => item_find.provinceId == checkedNodesValue.node.province_id)

          if (find) {
            setShowChangeRole(true)
          }
        }
      }
    }

    if (checkedNodesValue.checkedNodes) {
      checkedNodesValue.checkedNodes.map((item) => {
        if (item.district_id) {
          list_data.push(item.district_id)
        }
      })
    }
    setNodeSelect(checkedNodesValue.node)
    setTreeCheckId(list_data)
  }

  const onFinish = async (value) => {
    if (type == 'view') {
      return
    }
    setLoading(true)
    const split = treeSelect.split('-')
    const department_id = split[split.length - 1]

    const payload = {
      image: imageUrl ? imageUrl.path : '',
      name: value.name,
      phone: value.phone,
      email: value.email,
      employee_code: value.code,
      role_id: value.permissionGroup,
      department_id: department_id,
      location_management: treeCheckId
    }

    try {
      if (type == 'create') {
        const res = await postCreateUserAPI({ ...payload })

        if (res) {
          toast('Tài khoản người dùng hệ thống được tạo mới thành công', { type: 'success' })
          navigate('/manage-user')
        }
      } else {
        const res = await updateUserAPI({ ...payload, id: params.id })

        if (res) {
          toast('Thông tin tài khoản được cập nhật thành công', { type: 'success' })
          navigate('/manage-user')
        }
      }
    } catch (error) {
      toast(error.response.data.message, { type: 'error' })
    }

    setLoading(false)
  }

  const onCloseChangeRole = () => {
    if (nodeSelect.children) {
      const list_data = []

      nodeSelect.children.map((item) => {
        const find = checkRole.data.find((item_find) => item_find.provinceId == item.province_id)

        if (!find) {
          list_data.push(item.key)
        }
      })
      setTreeCheck(list_data)
    } else {
      const list_data = treeCheck.filter((item) => item != nodeSelect.key)
      setTreeCheck(list_data)
    }
    setShowChangeRole(false)
  }

  // handle modal
  const onLockAccount = async () => {
    setLoading(true)
    try {
      if (userDetail?.status == 'active' && !valueNote) {
        form.setFields([{ name: 'note', errors: ['Vui lòng nhập lí do'] }])
        setLoading(false)
        return
      }
      const res = await postLockAccountAPI({
        id: params.id,
        note: userDetail?.status == 'active' ? valueNote : '',
        status: userDetail?.status == 'active' ? 'inactive' : 'active'
      })

      if (res) {
        toast(userDetail?.status == 'active' ? 'Khóa thành công tài khoản' : 'Mở khóa thành công tài khoản', {
          type: 'success'
        })
        setShowLockAccount(false)
        handleGetUserDetail()
        setValueNote()
        form.setFieldValue('note', '')
      }
    } catch (error) {
      toast(error.response.data.message, { type: 'error' })
    }
    setLoading(false)
  }

  const onChangePassword = async () => {
    try {
      const res = await putChangePasswordAccountAPI({ id: params.id })

      if (res) {
        toast('Đặt lại mật khẩu thành công', { type: 'success' })
        setShowChangePassword(false)
      }
    } catch (error) {
      toast(error.response.data.message, { type: 'error' })
    }
  }

  const onCheckCancel = () => {
    if (type != 'view') {
      if (type == 'create') {
        if (form.isFieldsTouched()) {
          setShowSubmitCancel(true)
        } else {
          navigate('/manage-user')
        }
      } else {
        if (
          !isEqual(initData, {
            name: watchForm.name,
            phone: watchForm.phone,
            email: watchForm.email,
            code: watchForm.code,
            permissionGroup: watchForm.permissionGroup,
            part: treeSelect,
            managementArea: treeCheck
          })
        ) {
          setShowSubmitCancel(true)
        } else {
          navigate('/manage-user')
        }
      }
    } else {
      navigate('/manage-user')
    }
  }

  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb
            title={UserVN.title}
            breadcrumbItem={type == 'create' ? UserVN.addTitle : type == 'edit' ? UserVN.editTitle : UserVN.viewTitle}
          />
          <Spin spinning={loading}>
            <Row>
              <Col lg='3' className='pb-4 pe-4'>
                <Card>
                  <CardBody className='d-flex flex-column align-items-center'>
                    <div className='user-avatar' style={{ position: 'relative' }}>
                      {imageUrl ? (
                        <>
                          {/* <img src={imageUrl.pre ? imageUrl.pre + imageUrl.path : imageUrl} alt="avatar" style={{ width: '160px', height: '160px', borderRadius: '50%', objectFit: 'cover' }} /> */}
                          <Image
                            // width={200}
                            src={imageUrl.pre ? imageUrl.pre + imageUrl.path : imageUrl}
                            alt='avatar'
                            style={{ width: '160px', height: '160px', borderRadius: '50%', objectFit: 'cover' }}
                          />
                        </>
                      ) : (
                        <div
                          className='d-flex justify-content-center align-items-center rounded-circle bg-info text-white'
                          style={{ width: '160px', height: '160px', borderRadius: '50%' }}
                        >
                          <i className='bx bxs-user font-size-50 '></i>
                        </div>
                      )}
                      {isEdit && <i onClick={() => uploadRef.current.click()} className='bx bx-edit-alt'></i>}
                      {isEdit && <i onClick={() => setImageUrl()} className='bx bx-trash'></i>}
                    </div>
                    <input
                      id='exampleFile'
                      type='file'
                      ref={uploadRef}
                      accept='image/png, image/jpeg, image/jpg, image/jpe, image/hiec, image/heif'
                      onChange={hanleFileChange}
                      className='upload-avatar'
                      style={{ display: 'none' }}
                    />
                    {type != 'create' && (
                      <div className='mt-4'>
                        <Label className='form-label text-center' style={{ display: 'block' }}>
                          {userDetail?.name}
                        </Label>
                        <div>{userDetail?.email}</div>
                        <div className='d-flex justify-content-center mt-2'>
                          <Status value={userDetail?.status} />
                        </div>
                      </div>
                    )}
                    {type != 'create' && (
                      <>
                        {userDetail?.status === 'active' && (
                          <button onClick={() => setShowChangePassword(true)} className='btn btn-danger mt-4 w-50'>
                            {UserVN.changePassword}
                          </button>
                        )}
                        <button onClick={() => setShowLockAccount(true)} className='btn btn-danger mt-2 w-50'>
                          {userDetail?.status === 'inactive' ? UserVN.openAccount : UserVN.lockAccount}
                        </button>
                      </>
                    )}
                  </CardBody>
                </Card>
              </Col>
              <Col lg='9'>
                <Form layout='vertical' onFinish={onFinish} autoComplete='off' form={form} name='UserForm'>
                  <Card>
                    <CardBody>
                      <div className='d-flex justify-content-between'>
                        <h5>{UserVN.form.title}</h5>
                      </div>
                      <div className='form-group'>
                        <Row>
                          <Col sm='4'>
                            <Label className='form-label'>
                              {UserVN.form.name.label}
                              <a className='text-danger'>*</a>
                            </Label>
                            <Form.Item
                              name={UserVN.form.name.name}
                              rules={[{ required: true, message: UserVN.form.name.required }]}
                            >
                              <InputAntd
                                placeholder={UserVN.form.name.placeholder}
                                disabled={!isEdit}
                                size='large'
                                maxLength={250}
                                onBlur={(e) => form.setFieldValue(UserVN.form.name.name, e.target.value.trim())}
                              />
                            </Form.Item>
                          </Col>
                          <Col sm='4'>
                            <Label className='form-label'>
                              {UserVN.form.phone.label}
                              <a className='text-danger'> *</a>
                            </Label>
                            <Form.Item
                              name={UserVN.form.phone.name}
                              rules={[
                                { required: true, message: UserVN.form.phone.required },
                                { pattern: REGEX_NUMBER_VIET_NAM, message: UserVN.form.phone.requiredPhone }
                              ]}
                            >
                              <InputAntd
                                placeholder={UserVN.form.phone.placeholder}
                                disabled={!isEdit}
                                size='large'
                                maxLength={10}
                                onBlur={(e) => form.setFieldValue(UserVN.form.phone.name, e.target.value.trim())}
                              />
                            </Form.Item>
                          </Col>
                          <Col sm='4'>
                            <Label className='form-label'>
                              {UserVN.form.email.label}
                              <a className='text-danger'>*</a>
                            </Label>
                            <Form.Item
                              name={UserVN.form.email.name}
                              rules={[
                                { required: true, message: UserVN.form.email.required },
                                { pattern: REGEX_EMAIL, message: UserVN.form.email.requiredEmail }
                              ]}
                            >
                              <InputAntd
                                placeholder={UserVN.form.email.placeholder}
                                disabled={!isEdit || type == 'edit'}
                                maxLength={320}
                                size='large'
                                onBlur={(e) => {
                                  const value = e.target.value.trim()
                                  if (new RegExp(REGEX_EMAIL).test(value)) {
                                    form.setFields([{ name: UserVN.form.email.name, errors: [] }])
                                  } else {
                                    form.setFields([
                                      { name: UserVN.form.email.name, errors: [UserVN.form.email.requiredEmail] }
                                    ])
                                  }
                                  form.setFieldValue(UserVN.form.email.name, value)
                                }}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm='4'>
                            <Label className='form-label'>
                              {UserVN.form.code.label}
                              <a className='text-danger'>*</a>
                            </Label>
                            <Form.Item
                              name={UserVN.form.code.name}
                              rules={[{ required: true, message: UserVN.form.code.required }]}
                            >
                              <InputAntd
                                placeholder={UserVN.form.code.placeholder}
                                disabled={!isEdit}
                                size='large'
                                maxLength={20}
                                onBlur={(e) => form.setFieldValue(UserVN.form.code.name, e.target.value.trim())}
                              />
                            </Form.Item>
                          </Col>
                          <Col sm='4'>
                            <Label className='form-label'>{'Bộ phận'}</Label>

                            <Form.Item name='industry_id'>
                              <FormSelect
                                options={industry?.map((item) => ({
                                  value: item.id,
                                  label: item.name
                                }))}
                                // onChange={() => {
                                // form.setFieldValue('industrySector', undefined)
                                // }}
                                disabled={!isEdit}
                                placeholder={'Chọn bộ phận'}
                              />
                            </Form.Item>
                          </Col>
                          <Col sm='4'>
                            <Label className='form-label'>
                              {UserVN.form.permissionGroup.label}
                              <a className='text-danger'>*</a>
                            </Label>
                            <Form.Item
                              name={UserVN.form.permissionGroup.name}
                              rules={[{ required: true, message: UserVN.form.permissionGroup.required }]}
                            >
                              <FormSelect
                                placeholder={UserVN.form.permissionGroup.placeholder}
                                disabled={!isEdit}
                                onChange={(e) => handleGetCheckRoleManagement(e)}
                                size='large'
                                options={optionPermissionGroup}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm='8'>
                            <Label className='form-label'>
                              {'Đơn vị'}
                              <a className='text-danger'>*</a>
                            </Label>
                            {isEdit ? (
                              <Form.Item name={UserVN.form.part.name}>
                                <Tree
                                  defaultExpandedKeys={['0']}
                                  treeData={treeDataDepartment}
                                  disabled={!isEdit}
                                  // showLine={true}
                                  showIcon={true}
                                  height={150}
                                  loadData={onLoadData}
                                  selectedKeys={[treeSelect]}
                                  onSelect={(e) => {
                                    setTreeSelect(e[0])
                                    handleValidatePart(e[0])
                                  }}
                                />
                              </Form.Item>
                            ) : (
                              <div className='mb-4'>Điện lạnh Hoà Phát/{userDetail?.departmentTreeName}</div>
                            )}
                            {errPart && <div style={{ color: '#ff4d4f' }}>{UserVN.form.part.required}</div>}
                          </Col>
                        </Row>
                        <Row>
                          <Col sm='8'>
                            <Label className='form-label'>
                              {UserVN.form.managementArea.label}
                              <a className='text-danger'>*</a>
                            </Label>
                            {isEdit ? (
                              <Form.Item name={UserVN.form.managementArea.name}>
                                <Tree
                                  checkable
                                  treeData={treeDataZone}
                                  disabled={!isEdit || !form.getFieldValue(UserVN.form.permissionGroup.name)}
                                  height={150}
                                  checkedKeys={treeCheck}
                                  onCheck={(checkedKeysValue, checkedNodesValue) => {
                                    setTreeCheck(checkedKeysValue)
                                    handleChangeManagement(checkedKeysValue, checkedNodesValue)
                                  }}
                                />
                              </Form.Item>
                            ) : (
                              <Tree
                                checkable
                                treeData={treeDataZone}
                                disabled={isEdit || form.getFieldValue(UserVN.form.permissionGroup.name)}
                                height={150}
                                checkedKeys={treeCheck}
                                onCheck={(checkedKeysValue, checkedNodesValue) => {
                                  setTreeCheck(checkedKeysValue)
                                  handleChangeManagement(checkedKeysValue, checkedNodesValue)
                                }}
                              />
                            )}
                            {errManagementArea && (
                              <div style={{ color: '#ff4d4f' }}>{UserVN.form.managementArea.required}</div>
                            )}
                          </Col>
                        </Row>
                      </div>
                    </CardBody>
                  </Card>
                </Form>
                {userDetail?.status === 'inactive' && (
                  <Card>
                    <CardBody>
                      <Label className='form-label'>Lý do khóa</Label>
                      <div>{userDetail.noteFromAdmin}</div>
                    </CardBody>
                  </Card>
                )}
                <div className='d-flex gap-2 justify-content-end mt-4'>
                  {isEdit && (
                    <button onClick={() => onCheckCancel()} type='button' className='btn btn-soft-secondary w-14 '>
                      Hủy
                    </button>
                  )}
                  <button
                    type={'submit'}
                    className='btn btn-primary w-14'
                    onClick={() => {
                      if (type == 'view') {
                        navigate(`/manage-user/edit/${params.id}`)
                        dispatch(setTypePopupUser('edit'))
                      } else {
                        form.submit()
                      }
                    }}
                    disabled={
                      isEdit
                        ? disabledSubmit ||
                          !treeSelect ||
                          treeCheckId.length == 0 ||
                          isEqual(initData, {
                            name: watchForm.name,
                            phone: watchForm.phone,
                            email: watchForm.email,
                            code: watchForm.code,
                            permissionGroup: watchForm.permissionGroup,
                            part: treeSelect,
                            managementArea: treeCheck
                          })
                        : false
                    }
                  >
                    {isEdit ? 'Lưu' : 'Chỉnh sửa'}
                  </button>
                </div>
              </Col>
            </Row>
          </Spin>
          <ConfirmModal
            show={showLockAccount}
            onConfirmClick={onLockAccount}
            onCloseClick={() => {
              setShowLockAccount(false)
              setValueNote('')
              form.setFieldValue('note', '')
            }}
            title={userDetail?.status === 'active' ? 'Khóa tài khoản người dùng' : 'Mở khóa tài khoản người dùng'}
            description={
              <Spin spinning={loading}>
                <div>
                  {userDetail?.status == 'active'
                    ? 'Bạn có chắc chắn muốn khóa tài khoản người dùng?'
                    : 'Bạn có chắc chắn muốn mở khóa tài khoản người dùng?'}
                </div>
                {userDetail?.status == 'active' && (
                  <Form form={form}>
                    <Form.Item name='note' rules={[{ required: true, message: 'Vui lòng nhập lí do' }]}>
                      <TextArea
                        value={valueNote}
                        onChange={(e) => setValueNote(e.target.value)}
                        placeholder='Nhập lí do'
                        maxLength={500}
                        autoSize={{ minRows: 3, maxRows: 5 }}
                      />
                    </Form.Item>
                  </Form>
                )}
              </Spin>
            }
          />
          <ConfirmModal
            show={showChangePassword}
            onConfirmClick={onChangePassword}
            onCloseClick={() => setShowChangePassword(false)}
            icon={undefined}
            title='Xác nhận đặt lại mật khẩu'
            description={`Bạn có chắc chắn muốn đặt lại mật khẩu cho người dùng này?`}
          />
          {/* <ConfirmModal
                        show={showReject}
                        onConfirmClick={note => {
                            onUpdateRequest("reject", note)
                        }}
                        onCloseClick={closePopup}
                        hasInput
                        title={"Từ chối cập nhật thông tin"}
                        description=""
                    /> */}
          <ConfirmModal
            show={showChangeRole}
            icon={
              <div
                className='avatar-title rounded-circle text-light bg-opacity-60 font-size-20 rounded-3'
                style={{ background: '#f1b44c' }}
              >
                <i className='bx bx-error'></i>
              </div>
            }
            onConfirmClick={() => setShowChangeRole(false)}
            onCloseClick={() => onCloseChangeRole()}
            title='Xác nhận chuyển quyền quản lý khu vực'
            description={`Khu vực quản lý bị trùng với người dùng khác. Bạn có muốn chuyển quyền quản lý khu vực cho người dùng này`}
          />
          <ConfirmModal
            show={showSubmitCancel}
            onConfirmClick={() => navigate('/manage-user')}
            onCloseClick={() => setShowSubmitCancel(false)}
            icon={undefined}
            title='Xác nhận hủy'
            description={`Thay đổi sẽ không được lưu. Bạn có chắc chắn muốn thoát không?`}
          />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(UserAddEditDetail)
