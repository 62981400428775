import React, { useEffect, useState } from 'react'
import { Col, Label, Row } from 'reactstrap'

//flatpickr
import 'flatpickr/dist/themes/material_blue.css'
import { ActivationVN, CommonVN, LocalityVN, ManageContentVN } from 'constants/vn'
import { DatePicker } from 'antd'
import { FormDateRange } from './form-date-range/FormDateRange'
const { RangePicker } = DatePicker
const BanerGlobalFilter = ({ setGlobalFilter, search, setSearch }) => {
  const [filter, setFilter] = useState({})

  const handleSelect = (value, key) => {
    setFilter((e) => ({ ...e, [key]: value }))
  }

  return (
    <React.Fragment>
      <Col xxl={3} className='mt-3' lg={5}>
        <Label className='form-label'>{'Thời gian hiển thị'}</Label>
        <FormDateRange
          className='w-100'
          size='large'
          format={CommonVN.datePlaceholder.toUpperCase()}
          placeholder={[CommonVN.datePlaceholder, CommonVN.datePlaceholder]}
          value={[filter.start_date, filter.end_date]}
          onChange={(value) => {
            if (value) {
              handleSelect(value[0], 'start_date')
              handleSelect(value[1], 'end_date')
            } else {
              handleSelect(undefined, 'start_date')
              handleSelect(undefined, 'end_date')
            }
          }}
        />
      </Col>
      <Col xxl={7} lg={5} className='d-flex align-items-end justify-content-end mt-3 '>
        <div className='mb-xxl-0 me-2'>
          <button
            type='button'
            className='btn btn-soft-danger w-100'
            onClick={() => {
              setFilter({
                start_date: undefined,
                end_date: undefined
              })
              setSearch('')
              setGlobalFilter({})
            }}
          >
            Xóa tìm kiếm
          </button>
        </div>
        <div className='mb-xxl-0'>
          <button
            type='button'
            className='btn btn-primary w-100'
            onClick={() => {
              const _filter = !filter
                ? filter
                : Object.fromEntries(Object.entries(filter).filter(([key, value]) => value !== undefined))
              setGlobalFilter({ ..._filter, search })
            }}
          >
            Tìm kiếm
          </button>
        </div>
      </Col>
    </React.Fragment>
  )
}
export default BanerGlobalFilter
