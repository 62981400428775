export const GET_MANAGE_PRODUCT_ACTIVATION = "GET_PRODUCT_ACTIVATIONLIST"
export const GET_MANAGE_PRODUCT_ACTIVATION_FAIL = "GET_MANAGE_PRODUCT_ACTIVATION_FAIL"
export const GET_MANAGE_PRODUCT_ACTIVATION_SUCCESS = "GET_MANAGE_PRODUCT_ACTIVATION_SUCCESS"

export const GET_PRODUCT_ACTIVATION_DETAIL = "GET_PRODUCT_ACTIVATION_DETAIL"
export const GET_PRODUCT_ACTIVATION_DETAIL_SUCCESS = "GET_PRODUCT_ACTIVATION_DETAIL_SUCCESS"
export const GET_PRODUCT_ACTIVATION_DETAIL_FAIL = "GET_PRODUCT_ACTIVATION_DETAIL_FAIL"

export const UPDATE_MANAGE_PRODUCT_ACTIVATION = "UPDATE_MANAGE_PRODUCT_ACTIVATION"
export const UPDATE_MANAGE_PRODUCT_ACTIVATION_SUCCESS = "UPDATE_MANAGE_PRODUCT_ACTIVATION_SUCCESS"
export const UPDATE_MANAGE_PRODUCT_ACTIVATION_FAIL = "UPDATE_MANAGE_PRODUCT_ACTIVATION_FAIL"

export const SET_TYPE_POPUP = "SET_TYPE_POPUP_PRODUCT_ACTIVATION"
