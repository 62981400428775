import { FORMAT_DATE_BE } from 'constants/constants'
import dayjs from 'dayjs'

export const convertData = (data) => {
  const conditionForm = {
    type: data?.type,
    name: data?.name,
    is_apply_parallel: data?.applyParallel,
    apply_time: data?.applyTime,
    start_date: data?.applyTime === 'range_time' ? data?.applyDates?.[0] : undefined,
    end_date: data?.applyTime === 'range_time' ? data?.applyDates?.[1] : undefined,
    apply_dates: data?.applyTime === 'each_day' ? data?.applyDates : undefined,
    model_ids: data?.model,
    category_ids: data?.category,
    industry_sector_ids: data?.industrySector,
    industry_ids: data?.industry,
    channel_ids: data?.channel,
    warehouse_zone_ids: data?.warehouseZone,
    warehouse_start_date: data?.warehouseDate?.[0],
    warehouse_end_date: data?.warehouseDate?.[1],
    apply_client_types: data?.applyClientType,
    rank_ids: data?.rank,
    account_start_time: data?.createAtAccount?.[0],
    account_end_time: data?.createAtAccount?.[1],
    zone_ids: data?.zone,
    sub_zone_ids: data?.subzone,
    province_ids: data?.province,
    district_ids: data?.district,
    ward_ids: data?.ward,
    finish_time_display_documentation: data?.timeComplete,
    display_documentation_template: data?.file,
    config_type: data?.programConfig,
    programming_apply: data?.parallelProgram
  }
  let configForm
  if (data?.programConfig === 'product') {
    configForm = {
      reward_type: data?.rewardType ?? 'money',
      // accumulation_type: data?.programAccumulation,
      rewardConditionType: data?.rewardConditionType,
      products: data?.rewardConditionType === 'number' ? data?.products : undefined,
      productRewardConditions:
        data?.rewardConditionType === 'product'
          ? data?.productRewardConditions?.map((e) => ({
              money: e?.money,
              convert_value: e?.convertValue,
              artifact_name: e?.artifactName?.toString(),
              number_of_artifact: e?.numberOfArtifact,
              model_ids: e?.modelIds,
              //   category_ids: [e?.categoryIds],
              category_ids: e?.categoryIds === undefined || e?.categoryIds === null ? [] : e?.categoryIds,
              //   industry_sector_ids: [e?.industrySectorIds],
              industry_sector_ids:
                e?.industrySectorIds === undefined || e?.industrySectorIds === null ? [] : [e?.industrySectorIds],

                
                
              industry_ids: e?.industryIds === undefined || e?.industryIds === null ? [] : [e?.industryIds],
              from_value: e?.fromValue
            }))
          : undefined
    }
  }
  if (data?.programConfig === 'package') {
    configForm = {
      reward_type: data?.rewardType,
      number_of_package: data?.numberOfPackage,
      max_number_of_reward_package: data?.maxNumberOfRewardPackage,
      packages: data?.packages?.map((e) => ({
        money: e?.money,
        convert_value: e?.convertValue,
        max_number_of_reward: e?.maxNumberOfReward,
        artifact_name: e?.artifactName?.toString(),
        number_of_artifact: e?.numberOfArtifact,
        model_packages: e?.modelPackages?.map((m) => ({
          model_ids: m?.modelIds,
          //   category_ids: [m?.categoryIds],
          category_ids: m?.categoryIds === null || m?.categoryIds === undefined ? [] : m?.categoryIds,
          //   industry_sector_ids: [m?.industrySectorIds],
          industry_sector_ids:
            m?.industrySectorIds === null || m?.industrySectorIds === undefined ? [] : [m?.industrySectorIds],
          //   industry_ids: [m?.industryIds],
          industry_ids: m?.industryIds === null || m?.industryIds === undefined ? [] : [m?.industryIds],
          from_value: m?.fromValue
        }))
      }))
    }
  }
  if (data?.programConfig === 'revenue') {
    configForm = {
      reward_type: 'money',
      revenues: data?.revenues,
      unitPrices: data?.unitPrices
    }
  }

  return { ...conditionForm, ...configForm }
}

export const mapsData = (data) => {
  return {
    type: data?.type,
    name: data?.name,
    applyParallel: data?.isApplyParallel,
    applyTime: data?.applyTime,
    applyDates:
      data?.applyTime &&
      (data?.startDate || data?.applyDates) &&
      (data?.applyTime === 'range_time' ? [dayjs(data?.startDate), dayjs(data?.endDate)] : data?.applyDates),
    model: data?.models?.map((e) => e.id),
    category: data?.categories?.map((e) => e.id),
    industrySector: data?.industrySectors?.map((e) => e.id),
    industry: data?.industries?.map((e) => e.id),
    channel: data?.channels?.map((e) => e.id),
    warehouseZone: data?.warehouseZones?.map((e) => e.id),
    warehouseDate: data?.warehouseStartDate && [dayjs(data?.warehouseStartDate), dayjs(data?.warehouseEndDate)],
    applyClientType: data?.applyClientTypes ?? undefined,
    rank: data?.ranks?.map((e) => e.id),
    createAtAccount: data?.accountEndTime && [dayjs(data?.accountStartTime), dayjs(data?.accountEndTime)],
    zone: data?.zones?.map((e) => e.id),
    subzone: data?.subZones?.map((e) => e.id),
    province: data?.provinces?.map((e) => e.id),
    district: data?.districts?.map((e) => e.id),
    ward: data?.wards?.map((e) => e.id),
    timeComplete: data?.finishTimeDisplayDocumentation,
    file: data?.displayDocumentationTemplate,
    programConfig: data?.configType,
    programAccumulation: data?.accumulationType,
    products: data?.products,
    rewardType: data?.rewardType ?? 'money_object',
    rewardConditionType: data?.rewardConditionType,
    productRewardConditions: data?.productRewardConditions?.map((e) => ({
      money: e?.money,
      convertValue: e?.convertValue,
      artifactName: e?.artifactName?.toString(),
      numberOfArtifact: e?.numberOfArtifact,
      modelIds: e?.modelIds,
      categoryIds: e?.categories?.[0]?.id,
      industrySectorIds: e?.industrySectors?.[0]?.id,
      industryIds: e?.industries?.[0]?.id
    })),
    numberOfPackage: data?.numberOfPackage,
    minNumberOfPackage: data?.minNumberOfPackage,
    packages: data?.packages?.map((e) => ({
      money: e?.money,
      convertValue: e?.convertValue,
      artifactName: e?.artifactName?.toString(),
      numberOfArtifact: e?.numberOfArtifact,
      maxNumberOfReward: e?.maxNumberOfReward,
      modelPackages: e?.modelPackages?.map((m) => ({
        modelIds: m?.models?.map((e) => e?.id),
        // categoryIds: m?.categories?.[0]?.id,
        categoryIds: m?.categories?.map((e) => e?.id),
        industrySectorIds: m?.industrySectors?.[0]?.id,
        industryIds: m?.industries?.[0]?.id,
        fromValue: m?.fromValue
      }))
    })),
    revenues: data?.programmingRevenues,
    unitPrices: data?.programmingModules,
    maxNumberOfRewardPackage: data?.maxNumberOfRewardPackage
  }
}
