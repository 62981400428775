import { PermissionVN, LocalityVN } from "constants/vn"
import React, { useEffect, useState } from "react"
import { Col, Label, Modal, ModalBody, Row } from "reactstrap"
import { createSelector } from "reselect"
import { useSelector } from "react-redux"
import { setTypePopupLocality, getLocalityList } from "store/actions"
import { getZoneAPI, getAreaManagementOptionsAPI, postCreateLocalityAPI, updateLocalityAPI } from "helpers/backend_helper"
import { useDispatch } from "react-redux"
import { toast } from "react-toastify"
import { Form, Tree, Switch, Divider, Input, Spin, InputNumber } from "antd"
import { FormInput } from "components/Common/form-input/FormInput"
import { FormSelect } from "components/Common/form-select/FormSelect"
import { optionsGeographicalLevel, AREA_TYPE } from "../constants"
import ConfirmModal from "components/Common/ConfirmModal"
import { isEqual } from "lodash"

const AddEditLocality = ({ show, onCloseClick, selected, filter, setPage }) => {
    const dispatch = useDispatch()

    const selectLocalitysState = state => state.LocalityReducer
    const LocalitysProperties = createSelector(selectLocalitysState, reducer => ({
        type: reducer.type,
        localityDetail: reducer.localityDetail,
    }))
    const { type, localityDetail } = useSelector(LocalitysProperties)

    const isEdit = type !== "view"
    const [form] = Form.useForm()
    const watchForm = Form.useWatch([], form)
    const [disabledSubmit, setDisabledSubmit] = useState(true)
    const [loading, setLoading] = useState(false)
    const [valueGeographicalLevel, setValueGeographicalLevel] = useState(AREA_TYPE.ZONE)
    const [zone, setZone] = useState()
    const [subZone, setSubZone] = useState()
    const [province, setProvince] = useState()
    const [district, setDistrict] = useState()
    const [onCancel, setOnCancel] = useState(false)
    const [regex, setRegex] = useState({
        regex: /^\b\d{1}\b/,
        message: 'Mã Miền phải gồm 1 ký tự'
    })
    const [initData, setInitData] = useState()

    useEffect(() => {
        if (type == "create") {
            form.setFieldValue(LocalityVN.form.geographicalLevel.name, AREA_TYPE.ZONE)
        }
    }, [type])

    useEffect(() => {
        if (localityDetail) {
            if (localityDetail.type == AREA_TYPE.ZONE) {
                setInitData({
                    [LocalityVN.form.geographicalLevel.name]: localityDetail.type,
                    [LocalityVN.form.code.name]: handleFormat(localityDetail.code, convertLocationTypeToMaxLength(localityDetail.type)),
                    [PermissionVN.form.name.name]: localityDetail.name,
                })
            }

            if (localityDetail.type == AREA_TYPE.SUBZONE) {
                handleGetSubZone(localityDetail.zoneId)

                setInitData({
                    [LocalityVN.form.geographicalLevel.name]: localityDetail.type,
                    [LocalityVN.form.code.name]: handleFormat(localityDetail.code, convertLocationTypeToMaxLength(localityDetail.type)),
                    [PermissionVN.form.name.name]: localityDetail.name,
                    [LocalityVN.form.domain.name]: localityDetail?.zoneId,
                })
            }

            if (localityDetail.type == AREA_TYPE.PROVINCE) {
                handleGetSubZone(localityDetail.zoneId)
                handleGetProvince(localityDetail.subZoneId)

                setInitData({
                    [LocalityVN.form.geographicalLevel.name]: localityDetail.type,
                    [LocalityVN.form.code.name]: handleFormat(localityDetail.code, convertLocationTypeToMaxLength(localityDetail.type)),
                    [PermissionVN.form.name.name]: localityDetail.name,
                    [LocalityVN.form.domain.name]: localityDetail?.zoneId,
                    [LocalityVN.form.zone.name]: localityDetail?.subZoneId,
                })
            }

            if (localityDetail.type == AREA_TYPE.DISTRICT) {
                handleGetSubZone(localityDetail.zoneId)
                handleGetProvince(localityDetail.subZoneId)
                handleGetDistrict(localityDetail.provinceId)

                setInitData({
                    [LocalityVN.form.geographicalLevel.name]: localityDetail.type,
                    [LocalityVN.form.code.name]: handleFormat(localityDetail.code, convertLocationTypeToMaxLength(localityDetail.type)),
                    [PermissionVN.form.name.name]: localityDetail.name,
                    [LocalityVN.form.domain.name]: localityDetail?.zoneId,
                    [LocalityVN.form.zone.name]: localityDetail?.subZoneId,
                    [LocalityVN.form.city.name]: localityDetail?.provinceId,
                })
            }

            if (localityDetail.type == AREA_TYPE.WARD) {
                handleGetSubZone(localityDetail.zoneId)
                handleGetProvince(localityDetail.subZoneId)
                handleGetDistrict(localityDetail.provinceId)

                setInitData({
                    [LocalityVN.form.geographicalLevel.name]: localityDetail.type,
                    [LocalityVN.form.code.name]: handleFormat(localityDetail.code, convertLocationTypeToMaxLength(localityDetail.type)),
                    [PermissionVN.form.name.name]: localityDetail.name,
                    [LocalityVN.form.domain.name]: localityDetail?.zoneId,
                    [LocalityVN.form.zone.name]: localityDetail?.subZoneId,
                    [LocalityVN.form.city.name]: localityDetail?.provinceId,
                    [LocalityVN.form.district.name]: localityDetail?.districtId,
                })
            }

            form.setFieldsValue({
                [LocalityVN.form.geographicalLevel.name]: localityDetail.type,
                [LocalityVN.form.code.name]: handleFormat(localityDetail.code, convertLocationTypeToMaxLength(localityDetail.type)),
                [PermissionVN.form.name.name]: localityDetail.name,
                [LocalityVN.form.domain.name]: localityDetail?.zoneId,
                [LocalityVN.form.zone.name]: localityDetail?.subZoneId,
                [LocalityVN.form.city.name]: localityDetail?.provinceId,
                [LocalityVN.form.district.name]: localityDetail?.districtId,
            })
            setValueGeographicalLevel(localityDetail.type)
        }
    }, [localityDetail])

    useEffect(() => {
        form.validateFields({ validateOnly: true }).then(
            () => {
                setDisabledSubmit(false)
            },
            () => {
                setDisabledSubmit(true)
            }
        )
    }, [watchForm])

    useEffect(() => {
        if (valueGeographicalLevel != AREA_TYPE.ZONE) {
            handleGetZone()
        }
        handleCheckRegex()
        handleCheckRegex()
    }, [valueGeographicalLevel])

    const handleCheckRegex = () => {
        switch (valueGeographicalLevel) {
            case AREA_TYPE.ZONE:
                setRegex({
                    regex: /^\b\d{1}\b/,
                    message: 'Mã Miền phải gồm 1 chữ số'
                })
                break;
            case AREA_TYPE.SUBZONE:
                setRegex({
                    regex: /^\b\d{2}\b/,
                    message: 'Mã Vùng phải gồm 2 chữ số'
                })
                break;
            case AREA_TYPE.PROVINCE:
                setRegex({
                    regex: /^\b\d{2}\b/,
                    message: 'Mã Tỉnh/Thành phố phải gồm 2 chữ số'
                })
                break;
            case AREA_TYPE.DISTRICT:
                setRegex({
                    regex: /^\b\d{3}\b/,
                    message: 'Mã Quận/Huyện phải gồm 3 chữ số'
                })
                break;
            case AREA_TYPE.WARD:
                setRegex({
                    regex: /^\b\d{5}\b/,
                    message: 'Mã Phường/Xã phải gồm 5 chữ số'
                })
                break;
        }
    }

    const handleGetZone = async () => {
        try {
            const res = await getZoneAPI()

            if (res.data) {
                const list_data = res.data.map((item) => {
                    return {
                        value: item.id,
                        label: item.name
                    }
                })

                setZone(list_data)
            }
        } catch (error) {
            toast(error.response.data.message, { type: "error" })
        }
    }

    const handleGetSubZone = async (id) => {
        try {
            const res = await getAreaManagementOptionsAPI({ type: AREA_TYPE.SUBZONE, parent_id: id })

            if (res.data) {
                const list_data = res.data.map((item) => {
                    return {
                        value: item.id,
                        label: item.name
                    }
                })

                setSubZone(list_data)
            }
        } catch (error) {
            toast(error.response.data.message, { type: "error" })
        }
    }

    const handleGetProvince = async (id) => {
        try {
            const res = await getAreaManagementOptionsAPI({ type: AREA_TYPE.PROVINCE, parent_id: id })

            if (res.data) {
                const list_data = res.data.map((item) => {
                    return {
                        value: item.id,
                        label: item.name
                    }
                })

                setProvince(list_data)
            }
        } catch (error) {
            toast(error.response.data.message, { type: "error" })
        }
    }

    const handleGetDistrict = async (id) => {
        try {
            const res = await getAreaManagementOptionsAPI({ type: AREA_TYPE.DISTRICT, parent_id: id })

            if (res.data) {
                const list_data = res.data.map((item) => {
                    return {
                        value: item.id,
                        label: item.name
                    }
                })

                setDistrict(list_data)
            }
        } catch (error) {
            toast(error.response.data.message, { type: "error" })
        }
    }

    const onFinish = async (values) => {
        if (type == "view") {
            dispatch(setTypePopupLocality("edit"))
            setDisabledSubmit(false)
            return;
        }
        setLoading(true)
        try {
            let parent_id = null
            switch (valueGeographicalLevel) {
                case AREA_TYPE.ZONE:
                    parent_id = null
                    break;
                case AREA_TYPE.SUBZONE:
                    parent_id = values.domain
                    break;
                case AREA_TYPE.PROVINCE:
                    parent_id = values.zone
                    break;
                case AREA_TYPE.DISTRICT:
                    parent_id = values.city
                    break;
                case AREA_TYPE.WARD:
                    parent_id = values.district
                    break;
            }

            const payload = {
                code: values.code,
                name: values.name,
                type: valueGeographicalLevel,
                parent_id: parent_id,
            }

            if (type == "create") {
                const res = await postCreateLocalityAPI({ ...payload })

                if (res) {
                    toast("Thêm mới địa bàn thành công", { type: "success" })
                    toast("Thêm mới địa bàn thành công", { type: "success" })
                    onCloseClick()
                    dispatch(getLocalityList())
                    setPage(1)
                }

            } else {
                const res = await updateLocalityAPI({ ...payload, id: localityDetail.id })

                if (res) {
                    toast("Cập nhật địa bàn thành công", { type: "success" })
                    toast("Cập nhật địa bàn thành công", { type: "success" })
                    onCloseClick()
                    dispatch(getLocalityList({ ...filter }))
                    dispatch(getLocalityList({ ...filter }))
                }
            }
        } catch (error) {
            toast(error.response.data.message, { type: "error" })
        }
        setLoading(false)
    }

    const convertLocationTypeToMaxLength = (type) => {
        switch (type) {
            case 'zone':
                return 1;
            case 'subzone':
            case 'province':
                return 2;
            case 'district':
                return 3;
            case 'ward':
                return 5;
            default:
                return 1;
        }
    }
    const handleFormat = (value, viewLength) => {
        return (value).toLocaleString('en-US', { minimumIntegerDigits: viewLength, useGrouping: false })
    }

    const onClickCancel = () => {
        setOnCancel(false)
        onCloseClick()
    }

    const checkClickCancel = () => {
        if (type != "view") {
            if (type == "create") {
                if (form.isFieldsTouched()) {
                    setOnCancel(true)
                } else {
                    onCloseClick()
                }
            } else {
                if (!isEqual(initData, watchForm)) {
                    setOnCancel(true)
                } else {
                    onCloseClick()
                }
            }
        } else {
            onCloseClick()
        }
    }

    return (
        <Modal
            size="lg"
            isOpen={show}
            toggle={() => {
                checkClickCancel()
            }}
            centered={true}
        >
            <div className="modal-content">
                <ModalBody className="p-4 text-start">
                    <Spin spinning={loading}>
                        <button type="button" onClick={checkClickCancel} className="btn-close position-absolute end-0 top-0" />
                        {type == "view" && <h2>{LocalityVN.viewModal}</h2>}
                        {type == "create" && <h2>{LocalityVN.addModal}</h2>}
                        {type == "edit" && <h2>{LocalityVN.editModal}</h2>}
                        <Form layout="vertical" onFinish={onFinish} form={form}>
                            <div className="form-group">
                                <Row>
                                    <Col>
                                        <Form.Item
                                            label={LocalityVN.form.geographicalLevel.label}
                                            name={LocalityVN.form.geographicalLevel.name}
                                            rules={[{ required: true, message: LocalityVN.form.geographicalLevel.required }]}
                                        >
                                            <FormSelect
                                                label={LocalityVN.form.geographicalLevel.label}
                                                placeholder={LocalityVN.form.geographicalLevel.placeholder}
                                                disabled={!isEdit || type == "edit"}
                                                options={optionsGeographicalLevel}
                                                onChange={(value) => {
                                                    setValueGeographicalLevel(value)
                                                    form.setFieldValue('code', undefined)
                                                    form.setFields([{ name: 'code', errors: null }])
                                                }}
                                                value={valueGeographicalLevel}
                                                defaultValue={AREA_TYPE.ZONE}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col>
                                        <Form.Item
                                            label={LocalityVN.form.code.label}
                                            name={LocalityVN.form.code.name}
                                            rules={[
                                                { required: true, message: LocalityVN.form.code.required },
                                                { pattern: regex.regex, message: regex.message }
                                            ]}
                                        >
                                            <Input
                                                size="large"
                                                label={LocalityVN.form.code.label}
                                                placeholder={LocalityVN.form.code.placeholder}
                                                disabled={!isEdit}
                                                maxLength={10}
                                                onBlur={(e) => form.setFieldValue(LocalityVN.form.code.name, e.target.value.trim())}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Form.Item
                                    label={LocalityVN.form.name.label}
                                    name={LocalityVN.form.name.name}
                                    rules={[{ required: true, message: LocalityVN.form.name.required }]}
                                >
                                    <Input placeholder={LocalityVN.form.name.placeholder} disabled={!isEdit} size="large" maxLength={50} onBlur={(e) => form.setFieldValue(PermissionVN.form.name.name, e.target.value.trim())} />
                                </Form.Item>
                                <Row>
                                    <Col>
                                        {(valueGeographicalLevel == AREA_TYPE.SUBZONE || valueGeographicalLevel == AREA_TYPE.PROVINCE || valueGeographicalLevel == AREA_TYPE.DISTRICT || valueGeographicalLevel == AREA_TYPE.WARD) && (
                                            <Form.Item
                                                label={LocalityVN.form.domain.label}
                                                name={LocalityVN.form.domain.name}
                                                rules={[{ required: true, message: LocalityVN.form.domain.required }]}
                                            >
                                                <FormSelect
                                                    label={LocalityVN.form.domain.label}
                                                    placeholder={LocalityVN.form.domain.placeholder}
                                                    disabled={!isEdit}
                                                    options={zone}
                                                    onChange={(value) => {
                                                        handleGetSubZone(value)
                                                        form.setFieldsValue(
                                                            { [LocalityVN.form.zone.name]: undefined },
                                                            { [LocalityVN.form.city.name]: undefined },
                                                            { [LocalityVN.form.district.name]: undefined },
                                                        )
                                                    }}
                                                />
                                            </Form.Item>
                                        )}
                                    </Col>
                                    <Col>
                                        {(valueGeographicalLevel == AREA_TYPE.PROVINCE || valueGeographicalLevel == AREA_TYPE.DISTRICT || valueGeographicalLevel == AREA_TYPE.WARD) && (
                                            <Form.Item
                                                label={LocalityVN.form.zone.label}
                                                name={LocalityVN.form.zone.name}
                                                rules={[{ required: true, message: LocalityVN.form.zone.required }]}
                                            >
                                                <FormSelect
                                                    label={LocalityVN.form.zone.label}
                                                    placeholder={LocalityVN.form.zone.placeholder}
                                                    disabled={!isEdit || !form.getFieldValue(LocalityVN.form.domain.name)}
                                                    options={subZone}
                                                    onChange={(value) => {
                                                        handleGetProvince(value)
                                                        form.setFieldsValue(
                                                            { [LocalityVN.form.city.name]: undefined },
                                                            { [LocalityVN.form.district.name]: undefined },
                                                        )
                                                    }}
                                                />
                                            </Form.Item>
                                        )}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        {(valueGeographicalLevel == AREA_TYPE.DISTRICT || valueGeographicalLevel == AREA_TYPE.WARD) && (
                                            <Form.Item
                                                label={LocalityVN.form.city.label}
                                                name={LocalityVN.form.city.name}
                                                rules={[{ required: true, message: LocalityVN.form.city.required }]}
                                            >
                                                <FormSelect
                                                    label={LocalityVN.form.city.label}
                                                    placeholder={LocalityVN.form.city.placeholder}
                                                    disabled={!isEdit || !form.getFieldValue(LocalityVN.form.zone.name)}
                                                    options={province}
                                                    onChange={(value) => {
                                                        handleGetDistrict(value)
                                                        form.setFieldsValue(
                                                            { [LocalityVN.form.district.name]: undefined },
                                                        )
                                                    }}
                                                />
                                            </Form.Item>
                                        )}
                                    </Col>
                                    <Col>
                                        {(valueGeographicalLevel == AREA_TYPE.WARD) && (
                                            <Form.Item
                                                label={LocalityVN.form.district.label}
                                                name={LocalityVN.form.district.name}
                                                rules={[{ required: true, message: LocalityVN.form.district.required }]}
                                            >
                                                <FormSelect
                                                    label={LocalityVN.form.district.label}
                                                    placeholder={LocalityVN.form.district.placeholder}
                                                    disabled={!isEdit || !form.getFieldValue(LocalityVN.form.city.name)}
                                                    options={district}
                                                />
                                            </Form.Item>
                                        )}
                                    </Col>
                                </Row>
                            </div>
                            <div className="hstack gap-2 justify-content-center mt-4">
                                <button type="button" className="btn btn-soft-secondary w-25 " onClick={() => {
                                    checkClickCancel()
                                }}>
                                    Hủy
                                </button>

                                <button
                                    type={"submit"}
                                    className="btn btn-primary w-25"
                                    disabled={!isEdit || disabledSubmit || isEqual(initData, watchForm)}
                                >
                                    {isEdit ? "Lưu" : "Chỉnh sửa"}
                                </button>
                            </div>
                        </Form>
                    </Spin>
                </ModalBody>
            </div >
            <ConfirmModal
                show={onCancel}
                onConfirmClick={onClickCancel}
                onCloseClick={() => setOnCancel(false)}
                title={`Xác nhận`}
                description={`Thay đổi sẽ không được lưu. Bạn có chắc chắn muốn thoát không?`}
            />
        </Modal >
    )
}
export default AddEditLocality
