import React, { useState } from "react"
import { Col, Label } from "reactstrap"

//flatpickr
import "flatpickr/dist/themes/material_blue.css"
import { UserVN } from "constants/vn"
import { createSelector } from "reselect"
import { useSelector } from "react-redux"
import { FormSelect } from "./form-select/FormSelect"

const UserListGlobalFilter = ({ setGlobalFilter, search, setSearch, optionPermissionGroup }) => {
    const selectLayoutState = state => state.Layout
    const LayoutProperties = createSelector(selectLayoutState, app => ({
        province: app.province,
        district: app.district,
        ward: app.ward,
    }))
    const { province } = useSelector(LayoutProperties)

    const [filter, setFilter] = useState({})

    const handleSelect = (value, key) => {
        setFilter(e => ({ ...e, [key]: value }))
    }

    return (
        <React.Fragment>
            <Col xxl={2} lg={2} className="mt-3">
                <Label className="form-label">{UserVN.filters.permissionGroup.title}</Label>
                <FormSelect
                    options={optionPermissionGroup}
                    value={filter.role_id}
                    onChange={value => {
                        handleSelect(value, "role_id")
                    }}
                    placeholder={UserVN.filters.permissionGroup.placeholder}
                    listHeight={325}
                />
            </Col>
            <Col xxl={2} lg={2} className="mt-3">
                <Label className="form-label">{UserVN.filters.status.title}</Label>
                <FormSelect
                    options={UserVN.filters.status.statusOptions?.map(item => ({
                        value: item.value,
                        label: item.label,
                    }))}
                    value={filter.status}
                    onChange={value => {
                        handleSelect(value, "status")
                    }}
                    placeholder={UserVN.filters.status.placeholder}
                />
            </Col>
            <Col xxl={2} lg={2}>
            </Col>
            <Col xxl={4} lg={6} className="d-flex align-items-end justify-content-end  ">
                <div className="mb-xxl-0 me-2">
                    <button
                        type="button"
                        className="btn btn-soft-danger w-100"
                        onClick={() => {
                            setFilter({
                                status: undefined,
                                // zone_id: "all",
                                // sub_zone_id: "all",
                                province_id: undefined,
                            })
                            setSearch("")
                            setGlobalFilter({})
                        }}
                    >
                        Xóa tìm kiếm
                    </button>
                </div>
                <div className="mb-xxl-0">
                    <button
                        type="button"
                        className="btn btn-primary w-100"
                        onClick={() => {
                            const _filter = !filter
                                ? filter
                                : Object.fromEntries(Object.entries(filter).filter(([key, value]) => value !== undefined))
                            setGlobalFilter({ ..._filter, search })
                        }}
                    >
                        Tìm kiếm
                    </button>
                </div>
            </Col>
        </React.Fragment>
    )
}
export default UserListGlobalFilter
